<template>
  <!-- 目的地ピンアイコン -->
  <img
    src="@/assets/Icon_Map_Place.svg"
    class="absolute positionPin translate-x-[-50%] translate-y-[-115%] w-11 h-11 z-10 pointer-events-none"
  />
  <!-- 戻るボタン -->
  <div
    class="circle-back-button z-10 mt-safe-area flex justify-center items-center"
    @click="goToBackPage()"
  >
    <img class="h-6 w-6" src="@/assets/Icon_Left_grayV2.svg" />
  </div>
  <div class="absolute bottom-0 w-full pb-safe-area mb-[25px]">
    <!-- 地点設定パネル -->
    <div class="relative"></div>
    <!-- カード -->
    <ConfirmDestinationCard
      :destinationAddress="destinationData.address"
      :isFetching="isGettingDestination"
      @click-go-there="setDestination()"
    />
  </div>
</template>
<script>
import ConfirmDestinationCard from '@/components/molecules/ConfirmDestinationCard.vue'
import Util from '@/mixins/util.js'

// 目的地情報初期値
const INITIAL_DESTINATION_INFO = {
  lat: '',
  lon: '',
  address: '',
}

const DestinationSetting = {
  name: 'DestinationSetting',
  mixins: [Util],
  components: {
    ConfirmDestinationCard,
  },
  data() {
    return {
      isMovingMap: false, // マップ操作中フラグ
      isDispatchArea: false, // 配車可能判定
      destinationData: structuredClone(INITIAL_DESTINATION_INFO), // 目的地情報
    }
  },
  created() {
    // フッター部を非表示にする
    this.$store.commit('updateShowFooter', false)
    // マップ操作関連のイベントリスナー追加
    window.addEventListener(
      this.$config.CUSTOM_EVENT_HANDLER.MOVING_MAP,
      this.movingMapSetting
    )
    window.addEventListener(
      this.$config.CUSTOM_EVENT_HANDLER.STOP_MAP,
      this.stopMapSetting
    )
    // 中心座標を設定する
    this.setCenterOffset()
  },
  mounted() {
    this.init()
  },
  beforeUnmount() {
    // フッター部を表示する
    this.$store.commit('updateShowFooter', true)
    // マップ操作関連のイベントリスナー削除
    window.removeEventListener(
      this.$config.CUSTOM_EVENT_HANDLER.MOVING_MAP,
      this.movingMapSetting
    )
    window.removeEventListener(
      this.$config.CUSTOM_EVENT_HANDLER.STOP_MAP,
      this.stopMapSetting
    )
    // 中心座標を戻す
    this.removeCenterOffset()
  },
  computed: {
    /**
     * 緯度経度が設定されているか確認
     */
    isCompletedGetDestination() {
      return (
        !this.isNull(this.destinationData.lat) &&
        !this.isNull(this.destinationData.lon)
      )
    },
    /**
     * 目的地取得中判定処理
     */
    isGettingDestination() {
      // 地図移動中、または緯度経度が設定されていない場合、目的地取得中と見なす
      return this.isMovingMap || !this.isCompletedGetDestination
    },
  },
  methods: {
    /**
     * 目的地情報の初期化を行う
     */
    init() {
      // 表示完了後に初期化を実施する
      const mapCenter =
        this.$root.$refs.routeMap.$data.frozen.mapRelated.map.getCenter()
      // 目的地の設定がある場合はその緯度・経度を使用
      this.getDestination(mapCenter.lat, mapCenter.lng)
    },
    /**
     * マップ停止時の処理
     */
    stopMapSetting() {
      // マップが表示されているか
      if (!this.$root.$refs.routeMap.$data.frozen.mapRelated.map) {
        return
      }
      // マップの中心座標取得
      const currentLatLon =
        this.$root.$refs.routeMap.$data.frozen.mapRelated.map.getCenter()
      // 目的地を検索
      this.getDestination(currentLatLon.lat, currentLatLon.lng)
      // 地図移動なしと見なす
      this.isMovingMap = false
    },
    /**
     * 渡された緯度・経度から目的地を検索
     * @param {String} lat 緯度
     * @param {String} lon 経度
     */
    getDestination(lat, lon) {
      // 成功時コールバック
      const success = (result) => {
        // マップが移動中の場合は最新の住所を取りに行くため処理中断
        if (this.isMovingMap) {
          return
        }
        // 取得データを元に呼び元で処理実行
        this.destinationData.address = result.address
        this.destinationData.lat = lat
        this.destinationData.lon = lon
      }
      // 失敗時コールバック
      const failed = () => {
        // 初期値に戻す
        this.destinationData = {address: '', lat: lat, lon: lon}
      }

      // エラー時コールバック
      const error = (e) => {
        // 初期値に戻す
        this.destinationData = {address: '', lat: lat, lon: lon}
        throw e
      }

      // 逆ジオコーディング住所検索実行
      this.$store.dispatch('RouteStore/getAddressByCoord', {
        success: success,
        failed: failed,
        error: error,
        lat: lat,
        lon: lon,
      })
    },
    /**
     * マップ移動中の処理
     */
    movingMapSetting() {
      // マップ操作中、読み込み中フラグをtrueに更新
      this.isMovingMap = true
      // 住所の初期化
      this.destinationData = structuredClone(INITIAL_DESTINATION_INFO)
    },
    /**
     * 前の画面に戻る
     */
    goToBackPage() {
      // ルート検索Top画面へ戻る
      this.$router.push({
        name: this.$config.DISPLAY_SEARCH_TOP,
        params: {
          isKeepMapMode: true,
        },
      })
    },
    /**
     * 目的地を設定
     */
    setDestination() {
      // 検索条件の初期化
      this.$store.commit('initializeSearchConditions')

      // 出発地点設定処理
      // ここで検索をする場合、事前に現在地を設定する必要があるため
      this.$store.commit('updateSearchConditions', {
        key: this.$config.SPOT_TYPE_START,
        value: {
          name: '現在地',
          address: '',
          coord: '',
        },
      })
      // 到着地点設定処理
      this.$store.commit('updateSearchConditions', {
        key: this.$config.SPOT_TYPE_GOAL,
        value: {
          name: this.destinationData.address,
          address: this.destinationData.address,
          coord: `${this.destinationData.lat},${this.destinationData.lon}`,
          category: {},
        },
      })
      // 最適画面に遷移
      this.$router.push({name: this.$config.DISPLAY_ROUTE_BY_OPTIMAL})
    },

    /**
     * 地図の中心座標の位置を画面用に調整
     */
    setCenterOffset() {
      const paddingBottom = 230 // カード下margin(25px) + カード高(171px) + アイコンサイズで現在地が重なるよう調整
      // 地図の中心座標の位置を調整
      this.$root.$refs.routeMap.$data.frozen.mapRelated.map.setCenterOffset(
        // eslint-disable-next-line no-undef, no-magic-numbers
        new mapscript.value.Point(0, -(paddingBottom / 2))
      )
    },
    /**
     * 地図の中心座標の位置を遷移元の内容に戻す
     */
    removeCenterOffset() {
      // 地図の中心座標の位置を調整
      this.$root.$refs.routeMap.$data.frozen.mapRelated.map.setCenterOffset(
        // eslint-disable-next-line no-undef, no-magic-numbers
        new mapscript.value.Point(0, 0)
      )
    },
  },
}
export default DestinationSetting
</script>
<style scoped>
.positionPin {
  /* 高さの設定内訳：画面の半分 - (下部のカード＋余白/2) + 目的地アイコンのピン位置を重ねる為の調整*/
  top: calc(50% - 102px);
  left: calc(50%);
}
</style>
