<template>
  <div class="pt-4">
    <!-- 経路表示コンポーネント(Lv2) -->
    <DisplaySearchSpots
      class="px-4 box-border"
      :start="getConditionsStartName"
      :via="getConditionsViaInfo"
      :goal="getConditionsGoalName"
      @display-via-spot="$emit('display-via-spot')"
    />
    <!-- 間の横線 -->
    <div class="h-[1px] bg-gray300 w-full my-4" />
    <div class="flex">
      <!-- 日付表示コンポーネント(Lv1) -->
      <div class="flex-1 ml-4 w-fit">
        <DateTime
          :targetTime="getConditionsTime"
          :timeType="getConditionsTimeType"
          @click="$emit('display-calendar-set-time')"
        />
      </div>
      <!-- 絞り込み -->
      <div
        v-if="isShowOptions"
        class="flex h-4 items-center ml-auto mr-4 mb-4"
        @click="$emit('click-options')"
      >
        <!-- 間の縦線 -->
        <div class="w-[1px] h-4 bg-gray300 w-full mx-2.5" />
        <img class="h-4 w-4" src="@/assets/route/Icon_TrainBus.svg" />
        <span class="ml-2 text-W4 text-[13px] leading-[13px] text-center">
          絞り込み
        </span>
        <img src="@/assets/Icon_down_gray.svg" class="ml-1 h-2.5 w-2.5" />
      </div>
    </div>
  </div>
</template>
<script>
import DateTime from '@/components/atoms/DateTime.vue'
import DisplaySearchSpots from '@/components/molecules/DisplaySearchSpots.vue'

const DisplaySpotAndTime = {
  name: 'DisplaySpotAndTime',
  components: {DateTime, DisplaySearchSpots},
  emits: ['display-calendar-set-time', 'display-via-spot', 'click-options'],
  data() {
    return {}
  },
  props: {
    // 一覧画面用Storeの検索条件データ
    searchConditions: {
      type: Object,
    },
    // 検索条件の表示フラグ
    isShowOptions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * 出発地点名を取得する
     * @return {String} 出発地点名
     */
    getConditionsStartName() {
      return this.searchConditions.start.name
    },
    /**
     * 経由地情報を取得する
     * @return {Array} 経由地の地点情報
     */
    getConditionsViaInfo() {
      return this.searchConditions.via
    },
    /**
     * 目的地点名を取得する
     * @return {String} 目的地点名
     */
    getConditionsGoalName() {
      return this.searchConditions.goal.name
    },
    /**
     * 日時情報を取得する
     * @return {String} 日時情報
     */
    getConditionsTime() {
      return this.searchConditions.targetTime
    },
    /**
     * 出発/到着情報を取得する
     * @return {Map} 出発/到着情報(id,name)
     */
    getConditionsTimeType() {
      return this.searchConditions.timeType
    },
  },
  methods: {},
}
export default DisplaySpotAndTime
</script>
<style scoped></style>
