import config from '@/const/const.js'
import util from './util.js'
import log from 'loglevel'
const constant = config.constant

const BASE = {
  spotName: '', // スポット名
  address: '', // 住所
  buildingName: '', // マンション名、号室
  coord: '', //座標
}
export default {
  methods: {
    /**
     * お気に入り地点の登録情報取得
     * @returns
     */
    getFavoriteSpotList() {
      return util.methods.getLocalStorage(
        constant.FAVORITE_SPOT_LIST_STORAGE_KEY
      )
    },
    /**
     * お気に入り地点の登録一覧に追加
     * @param {Object} data
     * @param {String} key
     * @param {String} id
     */
    addFavoriteSpotList(data, key, id) {
      // デフォルトで空文字をセット
      // favoriteの場合は後続処理で追加または更新したオブジェクトのIDに書き換える
      let dataId = ''

      // keyのバリデーションチェック
      if (
        key != constant.FAVORITE_SPOT_HOME &&
        key != constant.FAVORITE_SPOT_WORK &&
        key != constant.FAVORITE_SPOT
      ) {
        // 許容しないKeyを受け取った場合はエラーを返却する
        throw new Error('Not Valid Key')
      }

      let target = this.getFavoriteSpotList()
      if (!target) {
        // 存在しない場合は初期値を設定
        target = {
          home: BASE,
          work: BASE,
          favorite: [],
        }
      }
      // 存在する場合、keyに基づく値を更新
      if (key == constant.FAVORITE_SPOT) {
        if (id === '' || id == null) {
          // 追加登録：お気に入りの場合は配列後ろに追加
          data.id = String(Date.now())
          target[constant.FAVORITE_SPOT].push(data)

          // 登録がしたオブジェクトのIDにtargetIDを書き換える
          dataId = data.id
        } else {
          // 更新：すでに登録済データを更新する場合
          target[constant.FAVORITE_SPOT] = target[constant.FAVORITE_SPOT].map(
            (storageData) => {
              data.id = id
              if (storageData.id == id) {
                // 更新するオブジェクトのIDにtargetKeyを書き換える
                dataId = data.id
                return data
              }
              return storageData
            }
          )
        }
      } else {
        // お気に入り以外の場合は更新
        target[key] = data
      }

      util.methods.setLocalStorage(
        constant.FAVORITE_SPOT_LIST_STORAGE_KEY,
        target
      )
      return {commitData: target, dataId: dataId}
    },
    /**
     * お気に入り地点の登録一覧から削除
     * @param {*} data
     * @param {*} key
     * @returns
     */
    deleteFavoriteSpotList(data, key) {
      let target = this.getFavoriteSpotList()
      switch (key) {
        case constant.FAVORITE_SPOT_HOME:
        case constant.FAVORITE_SPOT_WORK:
          // 対象のkeyの値を初期化
          target[key] = BASE
          break
        case constant.FAVORITE_SPOT:
          // IDに一致する項目を除外
          target[key] = target[key].filter((value) => {
            return value.id != data.id
          })
          break
        default:
          break
      }
      util.methods.setLocalStorage(
        constant.FAVORITE_SPOT_LIST_STORAGE_KEY,
        target
      )
      return target
    },
    /**
     * アプリ内情報取得
     * @returns アプリ情報
     */
    getAppInfo() {
      return util.methods.getLocalStorage(constant.APP_INFO_KEY)
    },
    /**
     * アプリ情報更新
     * @param {Context} vm コンテキスト
     * @param {*} data 更新データ
     * @param {*} key 更新対象
     * @returns 更新後のユーザー情報
     */
    async updateAppInfo(vm, data, key) {
      let appInfo
      let iconData
      if (key == null) {
        // 更新対象の指定がなければ一括更新
        appInfo = {
          name: data.name,
          size: data.size,
        }
        iconData = data.icon
      } else {
        // 更新対象の指定があれば、その対象にのみ更新
        const storageData = this.getAppInfo()
        const defaultData = {
          name: '',
          size: constant.USER_ICON_SIZE_LIST.MIDDLE.KEY,
        }
        appInfo = util.methods.isNull(storageData) ? defaultData : storageData
        switch (key) {
          case 'icon':
            // 対象がアイコンの場合
            iconData = data
            break
          case 'size':
            // サイズの場合
            appInfo[key] = data
            break
          case 'name':
            // nameの場合
            appInfo[key] = data
            break
          default:
            break
        }
      }
      // アイコン以外のユーザー情報をストレージに格納
      util.methods.setLocalStorage(constant.APP_INFO_KEY, appInfo)
      if (iconData) {
        // アイコンの更新があればDBに格納する
        // 格納は処理完了を待たない
        await this.updateUserIcon(vm, iconData)
      } else {
        // 画像更新がない場合は保存している画像を取得
        iconData = await this.getUserIcon(vm).catch(() => null)
      }
      // アイコンデータをユーザー情報に追加
      appInfo.icon = iconData
      return appInfo
    },

    /**
     * ユーザーアイコン更新処理
     * @param {Context} vm コンテキスト
     * @param {*} data 保存する画像のデータ
     * @returns 保存結果
     */
    async updateUserIcon(vm, data) {
      let result = null
      try {
        const db = vm.$store.state.IndexedDbStore.db
        // DBのトランザクションを取得
        var store = db
          .transaction(constant.USER_TABLE_NAME, 'readwrite')
          .objectStore(constant.USER_TABLE_NAME)
        // 画像をDBに保存
        result = await store.put({
          userIconKey: constant.USER_ICON_KEY,
          data: data,
        })
      } catch (e) {
        // エラー時は何もしない
        log.debug('Indexed DB error:', e)
      }
      return result
    },
    /**
     * DBからユーザアイコン取得処理
     * @param {Context} vm コンテキスト
     * @returns アイコンデータ
     */
    async getUserIcon(vm) {
      const db = vm.$store.state.IndexedDbStore.db
      return new Promise((resolve, reject) => {
        // 読み込み専用でトランザクションを取得
        var trans = db.transaction(constant.USER_TABLE_NAME, 'readonly')
        var store = trans.objectStore(constant.USER_TABLE_NAME)
        // DBからアイコンを取得
        var getReq = store.get(constant.USER_ICON_KEY)
        getReq.onerror = function (event) {
          reject(event)
        }
        getReq.onsuccess = function (event) {
          // 成功時処理
          const result = event.target.result
          resolve(result && result.data ? result.data : null)
        }
      })
    },
  },
}
