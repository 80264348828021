<template>
  <div
    class="flex w-full justify-around items-center bg-white h-[47px] px-5 sticky top-0 border-b-[1px] border-gray300 z-10"
  >
    <div class="w-4">
      <img
        v-if="isShowPrevious"
        src="@/assets/IconLeftBlack.svg"
        class="h-4"
        @click="clickPrevious()"
      />
    </div>
    <div class="text-W6 flex-1 leading-[15px] text-[16px]">
      {{ headerTitle }}
    </div>
    <div class="w-4">
      <img
        v-if="isShowClose"
        src="@/assets/IconCloseBlack.svg"
        class="h-4"
        @click="clickClose()"
      />
    </div>
  </div>
</template>
<script>
/**
 * ヘッダーのコンポーネント
 */
const AppHeader = {
  name: 'AppHeader',
  emits: ['clickPrevious', 'clickClose'],
  props: {
    /**
     * 戻るボタン表示フラグ
     */
    isShowPrevious: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * タイトル
     */
    headerTitle: {
      type: String,
      required: true,
    },
    /**
     * 閉じるボタン表示フラグ
     */
    isShowClose: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    /**
     * 戻るアイコン押下処理
     */
    clickPrevious() {
      this.$emit('clickPrevious')
    },
    /**
     * 閉じるアイコン押下処理
     */
    clickClose() {
      this.$emit('clickClose')
    },
  },
}
export default AppHeader
</script>
