<template>
  <div v-show="isShowFooter" :style="footerStyle"></div>
</template>
<script>
const FooterMargin = {
  name: 'FooterMargin',
  props: {
    // セーフエリアの高さを使用するか
    isUseSafeArea: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isShowFooter() {
      return this.$store.state.isShowFooter && !this.$store.state.isShowKeyboard
    },
    footerStyle() {
      const footerHeight = this.$config.FOOTER_HEIGHT
      if (this.isUseSafeArea) {
        const bottomSafeAreaHeight = this.$store.state.bottomSafeAreaHeight
        return {
          height: `${footerHeight + bottomSafeAreaHeight}px`,
        }
      } else {
        return {
          height: `${footerHeight}px`,
        }
      }
    },
  },
}
export default FooterMargin
</script>
