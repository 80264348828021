<template>
  <div class="fixed inset-0">
    <div class="circle-back-button z-10 mt-safe-area" @click="gotoBackPage()">
      <img class="h-4 w-4 mt-2.5 mx-auto" src="@/assets/Icon_Left_gray.svg" />
    </div>
    <!-- ボディ -->
    <div class="h-full w-full relative">
      <ReturnPortMap
        ref="returnPortMap"
        :portList="portList"
        @click-research="getPortList()"
        @click-card="clickCard($event)"
        @change-center-zoomlevel="updateCoordAndZoomlevel($event)"
      />
    </div>
    <!-- 時間外、休止中ポップアップ -->
    <Modal
      v-if="isNotOpeningPortModalFlg"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="pt-9 px-5 pb-6">
        <!-- テキスト -->
        <div class="pb-4 text-W5 text-[17px] leading-[25.5px]">
          <span>ポートが休止中</span>
          <br />
          <span>または営業時間外です。</span>
        </div>
        <!-- ボタン -->
        <div class="max-w-[164px] m-auto">
          <TheButton
            text="OK"
            class="text-W6"
            @click-button="closeNotOpeningPortModal()"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import ArrangementUtil from '@/mixins/arrangementUtil.js'
import ReturnPortMap from '@/components/molecules/arrangement/ReturnPortMap.vue'
import Util from '@/mixins/util.js'
import TheButton from '@/components/atoms/TheButton.vue'
import Modal from '@/components/Modal.vue'

/**
 * ポート選択(マップ)画面
 * ＜概要＞
 * 現在地にあるポート情報をマップに表示し、ポート情報の再取得ができる
 */
const BicycleReservationReturnPortOnMap = {
  name: 'BicycleReservationReturnPortOnMap',
  mixins: [Util, ArrangementUtil],
  components: {
    ReturnPortMap,
    Modal,
    TheButton,
  },
  data() {
    return {
      isNotOpeningPortModalFlg: false, // ポート休止中、営業時間外ポップアップフラグ
      mapZoomLevel: this.$config.MAP_ZOOMLEVEL.ZOOM_200_M, // mapのZoomLevel
      coord: {}, // mapの座標
    }
  },
  created() {
    // 座標初期化
    this.initCoord()
    // ポート情報を取得する
    this.getPortList()
  },
  computed: {
    /**
     * 現在地
     */
    currentPosition() {
      return this.$store.state.currentPosition
    },
    /**
     * ポート一覧
     */
    portList() {
      return this.$store.state.MobilityReservationStore.bicycle.parkInfo
    },
    /**
     * 自転車情報一覧
     */
    parkDetailInfo() {
      return this.$store.state.MobilityReservationStore.bicycle.parkDetailInfo
    },
  },
  methods: {
    /**
     * 座標の初期化（現在地が取れている場合は現在地、ない場合は東京駅を設定）
     */
    initCoord() {
      this.coord = this.createPositionForQuery(this.currentPosition)
    },
    /**
     * マップの更新に応じて、座標とズームレベルを更新
     * @param {Object} mapInfo マップ情報
     * @param {String} mapInfo.coord 座標
     * @param {String} mapInfo.zoomLevel ズームレベル
     */
    updateCoordAndZoomlevel(mapInfo) {
      this.coord = mapInfo.coord
      // ズームレベルを整数に丸めて(小数点以下四捨五入して)取得する
      this.mapZoomLevel = Math.round(mapInfo.zoomLevel)
    },
    /**
     * ヘッダー戻るボタン押下処理
     */
    gotoBackPage() {
      this.$router.push({
        name: this.$config.DISPLAY_BICYCLE_RESERVATION_CONFIRMED,
      })
    },
    /**
     * 吹き出しのカードクリック処理
     * @param {*} 選択ポート情報
     */
    clickCard(selectedPort) {
      const parkId = selectedPort.parkId

      // ポートが利用不可能な場合はポップアップを表示して処理中断
      if (this.isPortNotOperating(selectedPort)) {
        this.isNotOpeningPortModalFlg = true
        return
      }

      const vm = this
      this.$store.commit('startLoading')
      // 地図情報を取得する
      const success = () => {
        vm.$store.commit('endLoading')
        // 自転車返却マップ画面に遷移する
        vm.$router.push({
          name: this.$config.DISPLAY_BICYCLE_RESERVATION_RETURN_PORT_DETAIL,
        })
      }
      // ポート情報（駐輪自転車）を取得する
      this.$store.dispatch(
        'MobilityReservationStore/getBicycleParkDetailInfoList',
        {
          success,
          parkId,
        }
      )
    },
    /**
     * ポートが利用不可能であるか判定
     * @param {*} selectedPort 選択されたポート
     * @returns ポートが利用不可能か
     */
    isPortNotOperating(selectedPort) {
      // 必要項目抜き出し
      const start = selectedPort.startTime
      const end = selectedPort.endTime
      const parkStatus = selectedPort.parkStatus

      // ポートが運用中以外、営業時間外の場合はtrue
      return (
        this.isPortPausedOrParking(parkStatus) ||
        !this.isOpeningPort(start, end)
      )
    },
    /**
     * ポート一覧取得処理
     */
    getPortList() {
      // ローディング開始
      this.$store.commit('startLoading')
      const vm = this
      new Promise((resolve) => {
        // 成功時
        const success = () => {
          vm.$store.commit('endLoading')
          resolve()
        }

        // ポート情報APIコンテンツID指定一覧取得API実行
        vm.$store.dispatch('MobilityReservationStore/getPortInfo', {
          success,
          currentPosition: this.coord,
          zoomLevel: this.mapZoomLevel,
        })
      })
    },
    /**
     * ポート利用不可ポップアップのOK押下時処理
     */
    closeNotOpeningPortModal() {
      // ポップアップを閉じる
      this.isNotOpeningPortModalFlg = false
      // 最新のポート一覧を再取得
      this.getPortList()
    },
  },
}
export default BicycleReservationReturnPortOnMap
</script>
<style scoped></style>
