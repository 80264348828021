<template>
  <div
    class="flex w-full items-center bg-white h-[48px] px-5 sticky top-0 z-10"
  >
    <div class="mt-4 w-4">
      <img src="@/assets/IconLeftBlack.svg" @click="gotoMenu()" />
    </div>
  </div>
  <div class="px-6 text-left">
    <div>
      <img
        src="@/assets/claims/TokiomarineLogo.png"
        class="h-[156px] mt-6 mx-auto"
      />
    </div>
    <div class="mt-5 text-W4 text-[14px] leading-[21px]">
      このお手続きは、記名被保険者（お車の所有者）にて実施ください。弊社担当者からご案内している13桁の受付番号をご入力ください。
    </div>
    <div class="mt-5 text-W7 text-[13px] leading-none">受付番号</div>
    <TextFormWithValidation
      class="mt-2"
      ref="ClaimReceiptNumber"
      inputClass="bg-gray200 border-none"
      placeholder="13桁の半角数字"
      errorMessage="13桁の半角数字を入力してください。"
      maxlength="13"
      :text="claimReceiptNumber"
      :validationType="['required', 'claimReceiptNumber']"
      @input-text="claimReceiptNumber = $event"
    />
    <TheButton
      class="mt-5"
      text="保険金請求画面へ"
      :isDisabled="isEntryError()"
      @click-button="jumpToClaimTopPage()"
    />
    <div
      class="mt-6 px-[16px] py-[11.5px] bg-gray200 text-W4 text-gray500 leading-[21px]"
    >
      <!-- 自動フォーマットで改行が意図せず行われ不要なスペースが入ってしまうので値として実装 -->
      <span>
        {{
          'こちらは東京海上日動の契約者様専用サービスページとなります。サービス内容に関する詳細は'
        }}
      </span>
      <span
        class="url-link text-blue underline"
        @click="openUrlWithAnotherTab()"
      >
        {{ 'こちら' }}
      </span>
    </div>
  </div>
  <Modal
    v-if="isInsuranceClaimLoginErrorPopup"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="px-5 pt-9 pb-6">
      <div class="text-W5 text-[17px] mb-3">
        {{ insuranceClaimLoginErrorPopup.title }}
      </div>
      <div class="text-W3 text-[15px] pb-5 whitespace-pre-wrap">
        {{ insuranceClaimLoginErrorPopup.detail }}
      </div>
      <div
        class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
        @click="isInsuranceClaimLoginErrorPopup = false"
      >
        OK
      </div>
    </div>
  </Modal>
</template>
<script>
import Header from '@/components/organisms/Header.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import TextFormWithValidation from '@/components/molecules/TextFormWithValidation.vue'
import Modal from '@/components/Modal.vue'
import Util from '@/mixins/util'

const InsuranceClaimsLogin = {
  name: 'InsuranceClaimsLogin',
  components: {
    Header,
    TheButton,
    TextFormWithValidation,
    Modal,
  },
  data() {
    return {
      // 受付番号
      claimReceiptNumber: '',
      // 事故請求ログインエラーポップアップ表示フラグ
      isInsuranceClaimLoginErrorPopup: false,
      // 事故請求ログインエラーポップアップの表示内容
      insuranceClaimLoginErrorPopup: {
        title: '該当する受付番号が見つかりません。',
        detail:
          '入力内容をご確認ください。\n過去の請求内容については、担当者にお問い合わせください。',
      },
    }
  },
  methods: {
    /**
     * メニュー画面に遷移
     */
    gotoMenu() {
      this.$router.push({name: this.$config.DISPLAY_MENU_TOP})
    },
    /**
     * バリデーションでエラーがあるかチェック
     */
    isEntryError() {
      if (Util.methods.isNull(this.$refs.ClaimReceiptNumber)) {
        return true
      }
      return this.$refs.ClaimReceiptNumber.isInvalid
    },
    /**
     * 保険金請求画面へ遷移する際に行う処理
     */
    jumpToClaimTopPage() {
      // 成功時
      const success = () => {
        this.$store.commit('endLoading')
        this.$router.push({name: this.$config.DISPLAY_INSURANCE_CLAIMS_TOP})
      }

      // 失敗時
      const failed = (errorMessage) => {
        // 事故請求ログイン失敗ポップアップ表示
        switch (errorMessage) {
          // 該当の受付番号が見つからないエラーの場合
          case this.$config.INSURANCE_CLAIM_LOGIN_ERROR_MESSAGE.NOT_FOUND_CLAIM:
            this.insuranceClaimLoginErrorPopup = {
              title: '該当する受付番号が見つかりません。',
              detail:
                '入力内容をご確認ください。\n過去の請求内容については、担当者にお問い合わせください。',
            }
            break
          // それ以外のエラーの場合
          default:
            throw new Error(
              `insuranceClaimLogin failure (errorMessage: ${errorMessage})`
            )
        }
        this.$store.commit('endLoading')
        this.isInsuranceClaimLoginErrorPopup = true
      }

      this.$store.commit('startLoading')

      this.$store.dispatch('ClaimStore/insuranceClaimLogin', {
        claimReceiptNumber: this.claimReceiptNumber,
        success,
        failed,
      })
    },
    /**
     * 指定したURLを別タブで開く
     */
    openUrlWithAnotherTab() {
      window.open(
        process.env.VUE_APP_NAMO_CONTRACTOR_HP_URL,
        '_blank',
        'noreferrer'
      )
    },
  },
}
export default InsuranceClaimsLogin
</script>
<style scoped>
.url-link::after {
  content: '';
  display: inline-block;
  background-image: url('../../assets/IconLink.svg');
  width: 12px;
  height: 12px;
  margin-left: 4px;
  vertical-align: middle;
}
</style>
