<template>
  <!-- 登録・ログイン促進画面 -->
  <div class="h-full w-full flex flex-flow-col fixed top-0">
    <!-- 青枠 -->
    <div class="flex flex-col w-full bg-blue full-display pt-safe-area">
      <div class="mt-8">
        <div class="text-W6 text-[32px] leading-[54px] text-center text-white">
          乗りもの手配機能
        </div>
        <div class="text-W4 text-[15px] leading-[26px] text-center text-white">
          アカウント登録でタクシーや自転車の
          <br />
          手配ができるようになります！
        </div>
      </div>
      <div class="flex justify-center items-end mt-4 mx-4">
        <img
          src="@/assets/setup/Arrangement.png"
          class="h-full w-full aspect-[340/353]"
        />
      </div>
    </div>
    <!-- 白枠 -->
    <div class="bg-white">
      <!-- 乗り物手配補足メッセージ -->
      <img
        class="h-[13px] mx-auto mt-8"
        src="@/assets/setup/ArrangementMessage.png"
      />
      <!-- ボタン -->
      <div class="mx-6 mb-20 mt-3">
        <TheButton
          text="新規登録"
          class="mb-3"
          @click-button="saveStoreAndJump(signup)"
        />
        <TheButton
          text="ログイン"
          class="the-button-secondary"
          @click-button="saveStoreAndJump(login)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import TheButton from '@/components/atoms/TheButton.vue'
import {signup, login} from '@/auth/AuthorizationInteractor.js'
import Util from '@/mixins/util.js'

const SignupForMobility = {
  name: 'SignupForMobility',
  components: {TheButton},
  mixins: [Util],
  mounted() {
    localStorage.removeItem(this.$config.USER_SETUP_COMPLETED)
  },
  methods: {
    /**
     * IndexedDbにStoreを保存する処理
     */
    saveStoreAndJump(method) {
      // StoreデータのIndexedDBの設定
      const storeDbSettings = {
        tableName: this.$config.STORE_TABLE_NAME,
        storeName: this.$config.STORE_TABLE_NAME,
        keyPath: this.$config.STORE_KEY,
      }

      // IndexedDBに保存するStoreオブジェクトを作成
      const obj = {
        root: this.$store.state,
        route: this.$store.state.RouteStore,
        transfer: this.$store.state.TransferStore,
        timeTable: this.$store.state.TimeTableStore,
        menu: this.$store.state.MenuStore,
        mobility: this.$store.state.MobilityReservationStore,
      }

      // データ追加処理を呼び出し
      this.$store.dispatch('IndexedDbStore/addData', {
        success: method,
        setting: storeDbSettings,
        key: this.$config.STORE_KEY,
        data: JSON.stringify(obj),
      })
    },
    /**
     * アカウント登録
     */
    async signup() {
      // ローディング画像を開く
      this.$store.commit('startLoading')
      await signup()
    },
    /**
     * ログイン
     */
    async login() {
      // ローディング画像を開く
      this.$store.commit('startLoading')
      await login()
    },
  },
}
export default SignupForMobility
</script>
<style scoped>
.flex-flow-col {
  flex-flow: column;
}
.full-display {
  background: linear-gradient(#61c7f7, #009ce5);
}
</style>
