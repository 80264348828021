<template>
  <div class="h-full overflow-y-auto flex flex-col">
    <Header
      class="shrink-0"
      headerTitle="請求手続き"
      :isShowPrevious="true"
      @clickPrevious="gotoInsuranceClaimTop()"
    />
    <div class="bg-white mx-6 my-5">
      <!-- 注意事項 -->
      <div>
        <div class="text-W7 text-[18px] leading-[27px]">必ずご確認ください</div>
        <div class="text-W4 text-[14px] leading-[21px] text-left mt-5">
          一度請求を確定すると、変更や追加はできません。以下の内容で請求してよろしいですか？
        </div>
      </div>
      <!-- 請求金額 -->
      <div class="mt-5">
        <div class="mb-2 text-left text-W7 text-[13px] leading-[19.5px]">
          請求金額
        </div>
        <BaseBox
          class="bg-white !rounded-[10px] border border-solid border-gray300 p-4"
        >
          <template v-slot:explanation>
            <div class="flex justify-between items-center">
              <div class="text-W4 text-[13px] leading-[18px] text-gray500">
                合計
              </div>
              <div class="text-W7 text-[22px] leading-[27px]">
                {{ `¥${totalSelectedClaimAmount}` }}
              </div>
            </div>
          </template>
        </BaseBox>
      </div>
    </div>
    <!-- 請求内容の内訳 -->
    <div class="bg-blue200 flex-1 px-5 pt-5 pb-[123px]">
      <div class="text-W7 text-[18px] leading-none mb-3">請求内容の内訳</div>
      <div>
        <template v-for="(claim, index) in selectedClaims" :key="claim.itemId">
          <!-- 日付 -->
          <div v-if="isShowDate(index)" class="w-full mt-5 first:mt-3">
            <div class="text-W7 text-[16px] text-left leading-none">
              {{ getFormatYearDateSlash(claim.detail.usedDate) }}
            </div>
          </div>
          <!-- カード -->
          <ClaimCard :claim="claim" :isNeedCheckAndEdit="false" class="mt-2" />
        </template>
      </div>
    </div>
    <!-- 請求内容を確定する -->
    <div
      class="fixed bg-white h-[72px] w-screen bottom-0 pt-4 px-6 rounded-t-[20px] shadow-panel"
    >
      <TheButton
        class="w-full"
        text="請求内容を確定する"
        @click-button="onClickConfirmClaims()"
      />
    </div>
  </div>
</template>
<script>
import Header from '@/components/organisms/Header.vue'
import Util from '@/mixins/util'
import BaseBox from '@/components/atoms/BaseBox.vue'
import ClaimCard from '@/components/molecules/claims/ClaimCard.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import NativeUtil from '@/mixins/nativeUtil'

const InsuranceClaimConfirmed = {
  name: 'InsuranceClaimConfirmed',
  components: {
    Header,
    BaseBox,
    ClaimCard,
    TheButton,
  },
  mixins: [Util, NativeUtil],
  computed: {
    /**
     * 選択済みの請求情報リスト
     */
    selectedClaims() {
      const claims = this.$store.state.ClaimStore.claims
      const selectedClaimItemIds =
        this.$store.state.ClaimStore.selectedClaimItemIds
      return claims.filter((claim) => {
        return selectedClaimItemIds.includes(claim.itemId)
      })
    },
    /**
     * 選択された請求の合計金額算出
     */
    totalSelectedClaimAmount() {
      return this.addCommaForInteger(
        this.$store.getters['ClaimStore/totalSelectedClaimAmount']
      )
    },
  },
  created() {
    // webViewの場合はFG復帰時の生体認証失敗処理を定義
    if (this.isWebView()) {
      this.defineResumeBioAuthFailedMethod()
    }
  },
  beforeUnmount() {
    // webViewの場合は生体認証失敗処理定義をwindowから削除
    if (this.isWebView()) {
      delete window.resumeBioAuthFailed
    }
  },
  methods: {
    /**
     * 請求TOP画面に戻る
     */
    gotoInsuranceClaimTop() {
      this.$router.push({name: this.$config.DISPLAY_INSURANCE_CLAIMS_TOP})
    },
    /**
     * 日付を表示するか
     * @param index  請求のインデックス
     * @return {Boolean} true:日付を表示する
     */
    isShowDate(index) {
      if (index === this.$config.ZERO) {
        return true
      }
      const usedDate = this.getFormatYearDateSlash(
        this.selectedClaims[index].detail.usedDate
      )
      const prevUsedDate = this.getFormatYearDateSlash(
        // eslint-disable-next-line no-magic-numbers
        this.selectedClaims[index - 1].detail.usedDate
      )
      return usedDate != prevUsedDate
    },
    /**
     * 請求確定ボタン押下時の処理
     */
    onClickConfirmClaims() {
      const vm = this

      // 成功時
      const success = () => {
        vm.$store.commit('endLoading')
        vm.$router.push({name: vm.$config.DISPLAY_INSURANCE_CLAIMS_TOP})
      }

      vm.$store.commit('startLoading')

      vm.$store.dispatch('ClaimStore/createLinkClaims', {
        success: success,
      })
    },
    /**
     * Storeを初期化し、メニューTOP画面に遷移する
     */
    goToMenuTopWithStoreInit() {
      this.$router.push({name: this.$config.DISPLAY_MENU_TOP})
      // 請求データの初期化
      this.$store.commit('ClaimStore/updateClaims', [])
      this.$store.commit('ClaimStore/updateSelectedClaimItemIds', [])
      this.$store.commit('ClaimStore/updateClaimReceiptNumber', '')
      this.$store.commit('ClaimStore/initIncidentInfo')
    },
    /**
     * FG復帰時の生体認証失敗処理をwindowに定義
     */
    defineResumeBioAuthFailedMethod() {
      window.resumeBioAuthFailed = () => {
        // メニューTOP画面に遷移
        this.goToMenuTopWithStoreInit()
      }
    },
  },
}
export default InsuranceClaimConfirmed
</script>
<style scoped></style>
