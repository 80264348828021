<template>
  <div class="w-full h-full divide-y divide-gray300 pb-1">
    <!-- お支払い方法未登録 -->
    <div
      v-if="!isRegisteredCreditCards()"
      class="h-full flex flex-col justify-between"
      data-name="payment-method-not-registered"
    >
      <div class="mx-5 mt-[75px]">
        <div class="text-W7 text-[20px]">お支払い方法を登録しませんか？</div>
        <div class="mt-4 text-W4 text-[13px]">
          お支払い方法の登録で、
          <br />
          タクシーやシェアバイクの手配が可能になります。
        </div>
      </div>

      <div
        class="w-[262px] h-[126px] self-center flex-grow mt-5 mb-[130px] flex"
      >
        <img src="@/assets/payment/taxi.svg" alt="" />
      </div>

      <div class="mx-5 mb-6">
        <TheButton
          text="お支払い方法を登録する"
          @click-button="$emit('click-register-payment-method')"
        />
        <div
          class="text-W4 text-[11px] text-gray400 mt-2 text-left leading-none"
        >
          ※乗り物手配はお支払い方法の登録が必要です。
        </div>
      </div>
    </div>

    <!-- お支払い方法登録済 -->
    <div v-else data-name="payment-method-registered" class="mp-[5px]">
      <!-- 支払い方法 -->
      <div class="ml-5">
        <PaymentMethod
          data-name="payment-item"
          v-for="card in cardList"
          :key="card.cardSeq"
          :cardNumber="card.cardNumber"
          :expire="card.expire"
          :isShowNextIcon="isShowNextIcon"
          :isShowDeleteIcon="isShowDeleteIcon"
          @click-payment-method-next="
            $emit('click-payment-method-next', card.cardSeq)
          "
          @click-payment-method-delete="
            $emit('click-payment-method-delete', card.cardSeq)
          "
        />
      </div>

      <!-- 支払い方法追加 -->
      <div
        v-if="isRegistrableCreditCards()"
        data-name="add-payment-method"
        class="pt-5 flex items-center mx-5"
        @click="$emit('click-register-payment-method')"
      >
        <div class="plus"></div>
        <div class="ml-2 text-W7 text-primary text-[15px] leading-[15px]">
          お支払い方法を追加する
        </div>
      </div>

      <!-- 支払い方法追加不可 -->
      <div
        v-else
        data-name="registration-limit"
        class="mt-6 bg-gray200 rounded-[8px] text-left p-4 mx-5"
      >
        <div class="flex items-center">
          <IconInstructions class="w-4 h-4 mr-2 mb-[2px]" />
          <div class="text-W7 text-[13px] leading-[19.5px]">
            カードの登録枚数が上限に達しています。
          </div>
        </div>
        <div class="text-W4 text-3 leading-[18px] text-gray500 mt-1">
          新しいカードを追加したい場合は、登録済みのカードを削除してください。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethod from '@/components/atoms/PaymentMethod.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import IconInstructions from '@/components/icons/common/IconInstructions.vue'

const LIMIT_CARD_LENGTH = 5

const PaymentMethodList = {
  name: 'PaymentMethodList',
  emits: [
    'click-payment-method-next',
    'click-register-payment-method',
    'click-payment-method-delete',
  ],
  components: {
    PaymentMethod,
    TheButton,
    IconInstructions,
  },
  props: {
    // カードリスト(オブジェクト配列)
    cardList: {
      type: Array,
      default: () => [],
    },
    // 「>」アイコン表示フラグ
    isShowNextIcon: {
      type: Boolean,
      default: false,
    },
    // 「x」ボタン表示フラグ
    isShowDeleteIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * クレジットカード情報を登録済か
     * @returns {Boolean} クレジットカード情報登録済フラグ
     */
    isRegisteredCreditCards() {
      // eslint-disable-next-line no-magic-numbers
      return 0 < this.cardList.length
    },
    /**
     * クレジットカード情報追加可能か（クレジットカード情報は最大5件登録可能）
     * @returns {Boolean} クレジットカード情報追加可能フラグ
     */
    isRegistrableCreditCards() {
      return this.cardList.length < LIMIT_CARD_LENGTH
    },
  },
}
export default PaymentMethodList
</script>

<style scoped>
.plus {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #0072ff;
  position: relative;
}
.plus::before,
.plus::after {
  content: '';
  position: absolute;
  display: block;
  height: 1px;
  width: 8px;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.plus::after {
  transform: rotate(90deg);
}
</style>
