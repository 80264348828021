<template>
  <div
    class="w-full py-3 px-4 rounded-[24px] relative border-[0.5px] border-solid border-gray400"
    :class="inputClass"
  >
    <!-- 選択日付表示 -->
    <div
      v-if="isNotNullSelectedDate"
      class="w-full text-[15px] text-W4 text-left"
      :class="textColor()"
    >
      <span>{{ formattedSelectedDate }}</span>
    </div>
    <!-- プレースホルダー表示 -->
    <div v-else class="w-full text-[15px] text-W4 text-gray400 text-left">
      <span>{{ placeholder }}</span>
    </div>
    <!-- 日付 -->
    <input
      v-model="selectedDate"
      ref="actualform"
      class="datetimeform absolute"
      type="date"
      :disabled="isDisabled"
    />
  </div>
</template>
<script>
/**
 * フォーム用日付選択コンポーネント
 */
import Util from '@/mixins/util'
const DateFormComponent = {
  name: 'DateForm',
  mixins: [Util],
  props: {
    // 初期表示日時
    defaultDate: {
      type: String,
      default: '',
    },
    // プレイスホルダー
    placeholder: {
      type: String,
      default: '',
    },
    // 活性状態
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // フォームに付与するクラス
    inputClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      innerSelectedDate: null,
    }
  },
  created() {
    this.initDate()
  },
  computed: {
    /**
     * スラッシュ区切りの年月日にして返却
     */
    formattedSelectedDate() {
      return this.getFormatYearDateSlash(this.innerSelectedDate)
    },
    /**
     * 選択している値がnullか空文字でないか判定
     */
    isNotNullSelectedDate() {
      return !this.isNull(this.innerSelectedDate)
    },
    /**
     * 選択された日時の文字列
     */
    selectedDate: {
      get() {
        return this.innerSelectedDate
      },
      set(value) {
        this.innerSelectedDate = value
        const returnDate = this.isNotNullSelectedDate
          ? this.innerSelectedDate
          : ''
        this.$emit('input-text', returnDate)
      },
    },
  },
  methods: {
    /**
     * 日付を初期化する処理
     */
    initDate() {
      // 渡ってきた値をスラッシュ区切り、nullか空文字の場合は空文字
      this.innerSelectedDate = this.isNull(this.defaultDate)
        ? ''
        : this.defaultDate
    },
    /**
     * テキスト色を活性状態によって返却する処理
     */
    textColor() {
      return this.isDisabled ? 'text-gray400' : 'text-gray600'
    },
  },
}
export default DateFormComponent
</script>
<style scoped>
.datetimepicker {
  position: relative;
}

.datetimeform {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0px;
  left: 0px;
}

.datetimeform::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
