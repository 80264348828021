<template>
  <div class="overflow-y-auto scroll-bar-none h-full">
    <Header
      headerTitle="メール送信完了"
      :isShowPrevious="true"
      @clickPrevious="logout()"
    />
    <div class="px-6 py-8">
      <div>
        <img src="@/assets/Email.svg" class="w-[88px] h-[57px] m-auto" />
        <div class="text-W7 leading-[21px] mb-3 mt-6">
          <div class="text-[18px]">メールをご確認ください</div>
          <div class="text-[14px]">(有効期限 : 5日間)</div>
        </div>
        <div class="mt-3 text-W4 text-[14px] leading-[21px] text-left">
          ご入力いただいたメールアドレスに、アカウント登録のメールをお送りしました。
          <br />
          次の手順をご確認のうえ、操作をしてください。
        </div>
      </div>
      <!-- Step1 -->
      <div class="mt-6 p-6 border-[1px] border-gray300 rounded">
        <div class="text-W7 text-[18px] leading-[18px]">
          <div class="text-blue mb-3">STEP1</div>
          <div>アカウント登録を完了する</div>
        </div>
        <div class="my-4 border-b-[1px] border-gray300"></div>
        <div class="text-W4 text-[14px] leading-[21px] text-left">
          メールに記載の「登録を完了する」ボタンを押して、お手続きを進めてください。
        </div>
      </div>
      <!-- Step2 -->
      <div class="mt-4 p-6 border-[1px] border-gray300 rounded">
        <div class="text-W7 text-[18px] leading-[18px]">
          <div class="text-blue mb-3">STEP2</div>
          <div class="mb-4">ログインする</div>
        </div>
        <TheButton text="ログインへ進む" @click-button="login()" />
      </div>
      <!-- メールアドレスを誤った場合 -->
      <div class="mt-8">
        <div class="text-W7 text-[18px] leading-[21px]">
          メールアドレスを誤った場合
        </div>
        <div class="mt-3 mb-4 text-W4 text-[14px] leading-[21px] text-left">
          前画面に戻り、正しいメールアドレスを入力してメール送信してください。
        </div>
        <TheButton
          text="メールアドレスを再入力する"
          class="the-button-secondary"
          @click-button="signup()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/organisms/Header.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import {login, signup} from '@/auth/AuthorizationInteractor.js'
import auth from '@/auth/createAuth/index.js'
const SetupEmailVerified = {
  name: 'SetupEmailVerified',
  components: {Header, TheButton},
  methods: {
    /**
     * ログインする
     */
    async login() {
      // ローディング画像を開く
      this.$store.commit('startLoading')
      await login()
    },
    /**
     * サインアップする
     */
    async signup() {
      // ローディング画像を開く
      this.$store.commit('startLoading')
      await signup()
    },
    /**
     * ログアウトする
     */
    async logout() {
      // ローディング画像を開く
      this.$store.commit('startLoading')
      await auth.logout({returnTo: window.location.origin})
    },
  },
}
export default SetupEmailVerified
</script>
<style scooped></style>
