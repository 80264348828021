import axios from 'axios'
import {getAccessToken} from '@/auth/AuthorizationInteractor.js'
import {constant} from '@/const/const.js'

const TIMEOUT_SEC = 23000
export const CancelToken = axios.CancelToken

// エラーハンドル
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // 閉局中エラーの場合
    if (
      error.response?.data?.statusCode ===
      constant.API_STATUS_CODE_SERVICE_UNAVAILABLE
    ) {
      throw error
    }

    //タイムアウトの場合
    const isTimeout = error.code === 'ECONNABORTED'
    if (isTimeout) {
      throw new Error('timeOut')
    }

    // 認証が必要なAPIで認証されていない場合
    if (error.response?.status === constant.API_STATUS_CODE_UNAUTHORIZED) {
      // Navitimeの401レスポンスにはエラーにパラメータが入ってくる
      // 入ってきていない場合はAuth0のアクセストークン取得時のエラーのためinvalidAccessTokenを投げる
      if (!error.response.data.body?.param) {
        throw new Error('invalidAccessToken')
      }
    }

    // 複数端末ログインによるセッション切れの場合
    if (error.response?.status === constant.API_STATUS_CODE_FORBIDDEN) {
      // アクセストークン無効時と同様のエラーをthrowする
      throw new Error('invalidAccessToken')
    }

    // APIキャンセルの場合
    if (axios.isCancel(error)) {
      throw new Error('Cancel')
    }

    //ネットワークエラーの場合
    if (!error.response) {
      throw new Error('networkError')
    }

    return error.response
  }
)

/**
 * GETメソッド
 * @param {*} url
 * @param {*} body
 * @param {*} options needsAccessToken、timeout および source の連想配列
 * @returns
 */
export const get = async (url, query, options) => {
  const formatedOptions = formatOptions(options)
  const headers = await createHeader(formatedOptions.needsAccessToken)
  let res
  res = await axios.get(`${process.env.VUE_APP_BASE_URL}${url}`, {
    params: {...query},
    headers,
    timeout: formatedOptions.timeout,
    cancelToken: formatedOptions.source.token,
  })
  return res.data
}

/**
 * POSTメソッド
 * @param {*} url
 * @param {*} body
 * @param {*} options needsAccessToken、timeout および source の連想配列
 * @returns
 */
export const post = (url, body, options) => {
  const formatedOptions = formatOptions(options)
  return executeAxios(
    axios.post,
    url,
    body,
    formatedOptions.needsAccessToken,
    formatedOptions.timeout,
    formatedOptions.source.token
  )
}

/**
 * PUTメソッド
 * @param {*} url
 * @param {*} body
 * @param {*} options needsAccessToken、timeout および source の連想配列
 * @returns
 */
export const put = (url, body, options) => {
  const formatedOptions = formatOptions(options)
  return executeAxios(
    axios.put,
    url,
    body,
    formatedOptions.needsAccessToken,
    formatedOptions.timeout,
    formatedOptions.source.token
  )
}

/**
 * DELETEメソッド
 * @param {*} url
 * @param {*} body
 * @param {*} options needsAccessToken、timeout および source の連想配列
 * @returns
 */
export const del = async (url, body, options) => {
  const formatedOptions = formatOptions(options)
  const headers = await createHeader(formatedOptions.needsAccessToken)
  const res = await axios.delete(
    `${process.env.VUE_APP_BASE_URL}${url}`,
    {
      headers: headers,
      data: body,
    },
    {
      timeout: formatedOptions.timeout,
      cancelToken: formatedOptions.source.token,
    }
  )
  return res.data
}

/**
 * Axiosメソッドの実行関数
 * @param {*} func メソッド対象
 * @param {*} url URL
 * @param {*} body ボディのコンテンツ
 * @param {*} needsAccessToken アクセストークン付与有無
 * @param {*} timeout タイムアウト
 * @param {*} cancelToken キャンセル用トークン
 * @returns 結果
 */
const executeAxios = async (
  func,
  url,
  body,
  needsAccessToken,
  timeout,
  cancelToken
) => {
  const headers = await createHeader(needsAccessToken)
  let res

  res = await func(
    `${process.env.VUE_APP_BASE_URL}${url}`,
    {...body},
    {headers, timeout: timeout, cancelToken: cancelToken}
  )
  return res.data
}

/**
 * ヘッダー生成
 * @param {*} accessToken アクセストークン
 * @returns ヘッダー情報
 */
const createHeader = async (needsAccessToken) => {
  let header = {
    Accept: 'application/json,text/plain',
  }
  if (needsAccessToken) {
    try {
      const accessToken = await getAccessToken()
      if (accessToken) header['authorizationToken'] = `Bearer ${accessToken}`
    } catch (e) {
      if (e.message === constant.AUTH0_LOGIN_REQUIRED_ERROR) {
        throw new Error('invalidAccessToken')
      } else if (e.message === constant.AUTH0_FAILED_TO_FETCH) {
        throw new Error('networkError')
      } else {
        throw new Error()
      }
    }
  }
  return header
}
/**
 * options情報の整形を行う。
 * 指定のないものは初期値を設定する。
 * @param {*} options 呼び出し時に受け取ったoptions情報
 * @returns 整形後options情報
 */
function formatOptions(options) {
  return {
    needsAccessToken: getNeedsAccessToken(options),
    timeout: getTimeout(options),
    source: getSource(options),
  }
}
/**
 * オプション情報からaccsessToken付与有無を返却する
 * [初期値]undefined
 * @param {*} options 呼び出し時に受け取ったoptions情報
 * @returns accsessToken付与有無
 */
function getNeedsAccessToken(options) {
  return options != null && options.needsAccessToken != null
    ? options.needsAccessToken
    : undefined
}
/**
 * オプション情報からtimeoutを返却する
 * [初期値]23000
 * @param {*} options 呼び出し時に受け取ったoptions情報
 * @returns timeout
 */
function getTimeout(options) {
  return options != null && options.timeout != null
    ? options.timeout
    : TIMEOUT_SEC
}
/**
 * オプション情報からsourceを返却する
 * [初期値]CancelToken.source()
 * @param {*} options 呼び出し時に受け取ったoptions情報
 * @returns source
 */
function getSource(options) {
  return options != null && options.source != null
    ? options.source
    : CancelToken.source()
}
