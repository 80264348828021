import nativeUtil from '@/mixins/nativeUtil'
import config from '@/const/const.js'
const constant = config.constant

/**
 * 現在地の座標を取得する
 * @returns {Object} 緯度経度
 */
export async function getCurrentPosition() {
  if (nativeUtil.methods.isFlutterWebView()) {
    // Flutterの場合はnativeの位置情報取得を利用する
    const position = await nativeUtil.methods.getCurrentPosition()
    if (position) {
      if (position.isError) {
        throw new Error(position.errorMessage)
      } else {
        return position
      }
    }
  }
  // Flutterから取得できない場合はブラウザの位置情報取得を利用する
  const position = await getCurrentPositionBrowser()
  return {lat: position.coords.latitude, lon: position.coords.longitude}
}

/**
 * GeolocationAPIで取得する
 * @returns {Object} 位置情報
 */
async function getCurrentPositionBrowser() {
  return new Promise((resolve, reject) => {
    const success = (position) => {
      resolve(position)
    }
    const failed = (error) => {
      switch (error.code) {
        case constant.POSITION_PERMISSION_DENIED:
          reject(new Error('位置情報の利用が許可されていません'))
          break
        case constant.POSITION_UNAVAILABLE:
          reject(new Error('現在位置が取得できませんでした'))
          break
        case constant.POSITION_TIMEOUT:
          reject(new Error('タイムアウトになりました'))
          break
        default:
          reject(new Error('現在位置が取得できませんでした'))
          break
      }
    }
    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    }
    navigator.geolocation.getCurrentPosition(success, failed, options)
  })
}

/**
 * 現在地情報定期取得
 * @param success:成功時関数
 * @param failed:失敗時関数
 * @returns WatchId
 */
export async function setWatchPositionHandler(success, failed) {
  if (nativeUtil.methods.isFlutterWebView()) {
    // Flutterの場合はnativeの位置情報監視を利用する
    const result = await nativeUtil.methods.watchPosition((position) => {
      if (position.isError) {
        failed(new Error(position.errorMessage))
      } else {
        success({
          coords: {latitude: position.lat, longitude: position.lon},
        })
      }
    })
    if (result) {
      return
    }
  }
  // Flutterから取得できない場合はブラウザの位置情報取得を利用する
  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 5000,
  }
  return navigator.geolocation.watchPosition(
    //取得成功
    (position) => {
      success(position)
    },
    //取得失敗
    (error) => {
      failed(error)
    },
    options
  )
}
