<template>
  <!-- 時刻表方面選択画面 -->
  <div>
    <div class="fixed-app flex flex-col">
      <!-- ヘッダー部 -->
      <div id="time-table-direction" class="bg-white z-[500]">
        <div class="flex text-center w-full items-center h-[45px] px-3">
          <div class="w-full text-left">
            <img
              src="@/assets/Icon_Left_gray.svg"
              class="h-4"
              @click="goToBackPage()"
            />
          </div>
          <div class="text-W6 leading-[15px] text-[15px] w-full">方面選択</div>
          <div class="w-full"></div>
        </div>
        <!-- 線 -->
        <div class="h-[0.5px] w-full bg-gray300" />
      </div>
      <!-- 駅名 -->
      <div class="overflow-y-auto scroll-bar-none flex-1">
        <div
          class="text-W4 leading-[14px] text-[14px] w-full bg-gray200 text-left"
        >
          <div class="p-3">
            {{ selectStation.name }}
          </div>
        </div>
        <div class="pb-4">
          <div
            class="mx-3 pt-5 pb-2"
            v-for="link in displayLinks"
            :key="link.id"
          >
            <TimeTableDirectionCard
              :link="link"
              @click-direction="clickDirection"
            ></TimeTableDirectionCard>
          </div>
        </div>
        <FooterMargin />
      </div>
    </div>
    <!-- お気に入り登録済みポップアップ -->
    <Modal
      v-if="isRegisteredFavoriteTimetable"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="pt-9 px-5 pb-6">
        <div class="text-W5 text-[17px] leading-[21.5px] mb-4">
          <span>この時刻表はすでに登録済みです。</span>
        </div>
        <TheButton
          text="OK"
          :isDisabled="false"
          @click-button="isRegisteredFavoriteTimetable = false"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
/**
 * 時刻表方面選択画面
 * ＜概要＞
 * 時刻表を取得し、各路線と方面を表示する。
 * 方面を選択することで、時刻表詳細画面へ遷移する。
 */
import TimeTableDirectionCard from '@/components/timeTable/TimeTableDirectionCard.vue'
import timeTableUtil from '@/mixins/timeTableUtil'
import Modal from '@/components/Modal.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import dayjs from 'dayjs'
import Util from '@/mixins/util'
import FooterMargin from '@/components/organisms/FooterMargin.vue'

const TimeTableDirectionPage = {
  name: 'TimeTableDirectionPage',
  components: {TimeTableDirectionCard, Modal, TheButton, FooterMargin},
  mixins: [Util, timeTableUtil],
  data() {
    return {
      selectStation: this.$store.state.TimeTableStore.selectTimeTableStation,
      // 更新対象のお気に入り情報
      targetData: this.$store.state.TimeTableStore.targetFavoriteData,
      // お気に入り登録済みフラグ
      isRegisteredFavoriteTimetable: false,
    }
  },
  created() {},
  computed: {
    /**
     * 方面情報一覧として表示する路線情報一覧を返却
     * ※方面情報(上り下り)が存在しない場合は表示しない
     */
    displayLinks() {
      return this.selectStation.links.filter((link) => {
        // 方面が少なくとも一つ存在するかどうか
        const isExistDirection =
          link.direction.up != '' || link.direction.down != ''
        return !!link && isExistDirection
      })
    },
    /**
     * アプリ内IDの確認
     * 更新時：付与済のIDを返す
     * 登録時；新規採番
     */
    getTimeTableSystemId() {
      return this.targetData?.id || Date.now()
    },
  },
  methods: {
    /**
     * 前の画面に遷移
     */
    goToBackPage() {
      // ストアに保持している検索結果を初期化
      this.$store.commit('TimeTableStore/initTimeTableState')
      // 時刻表Top画面に遷移
      this.$router.push({name: this.$config.DISPLAY_TIME_TABLE_TOP})
    },
    /**
     * 選択した方面における、時刻表一覧情報を取得する。
     * @param {Object} event タップされた更新用データ
     */
    clickDirection(event) {
      this.$store.commit('startLoading')
      const selectLink = event

      // 選択した路線の時刻表情報を取得
      const startTime = dayjs().format('YYYY-MM-DDTHH:mm:ss')
      this.$store.dispatch('TimeTableStore/getTimeTables', {
        success: (result) => {
          this.$store.commit('endLoading')
          this.getTimeTablesSuccess(result, selectLink, startTime)
        },
        failed: () => {
          this.$store.commit('endLoading')
          throw new Error()
        },
        error: (e) => {
          this.$store.commit('endLoading')
          throw e
        },
        nodeId: this.selectStation.nodeId,
        linkId: selectLink.linkId,
        startTime: startTime,
      })
    },
    /**
     * 時刻表情報取得の成功時処理
     * 各種(Store/IndexedDB/Storage)保存処理と画面遷移処理を行う
     * @param {Object} result 時刻表取得結果
     * @param {Object} link 路線情報
     * @param {String} startTime 時刻表取得時間
     */
    getTimeTablesSuccess(result, link, startTime) {
      // お気に入りデータを整形
      const favoriteData = {
        id: this.getTimeTableSystemId, // アプリ内ID
        node: this.selectStation.nodeId, // ノードID
        name: this.selectStation.name, // 駅名
        link: result.link.id, // 路線ID
        linkName: result.link.name, // 路線名
        type: link.stationType, // モビリティ種別
        operation: link.direction, // 上り/下り
        directionName: result.link.direction[link.direction], // 方面名
        getTime: this.getNow(), // 時刻表を取得した時間
      }

      // お気に入りに追加
      if (this.isRegisteredFavorite(favoriteData)) {
        // 登録済みポップアップを展開する
        this.isRegisteredFavoriteTimetable = true
      } else {
        // お気に入り一覧更新(Storage/Store)
        const updateData = this.isExistFavoriteData(this.targetData)
          ? this.updateFavoriteTimeTable(favoriteData, this.targetData)
          : this.addFavoriteTimeTable(favoriteData)
        this.$store.commit('TimeTableStore/updateFavoriteTimetable', updateData)

        // 時刻表データ更新(DB/Store)
        const ope = this.moldingOperations(favoriteData, result.link, startTime)
        this.updateOperationsToDbAndStore(ope)

        // 時刻表Top画面に遷移
        this.goToBackPage()
      }
    },
    /**
     * お気に入りに登録済みの時刻表情報かどうか
     * @param {Object} event タップされた更新用データ
     */
    isRegisteredFavorite(target) {
      // 現時点のお気に入り一覧
      const favoriteList = this.$store.state.TimeTableStore.favoriteTimeTables
      // 条件に満たすものが存在すれば、登録済みであること
      return favoriteList.some((favorite) => {
        return this.checkExistDiagram(favorite, target)
      })
    },
  },
}
export default TimeTableDirectionPage
</script>

<style scoped></style>
