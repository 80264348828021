<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 14">
    <g
      id="グループ_24420"
      data-name="グループ 24420"
      transform="translate(-260 -333)"
    >
      <rect
        id="長方形_8117"
        data-name="長方形 8117"
        width="30"
        height="14"
        rx="7"
        transform="translate(260 333)"
        fill="#d11f2e"
      />
      <text
        id="不足"
        transform="translate(265 344)"
        fill="#fff"
        font-size="10"
        font-family="HiraginoSans-W5, Hiragino Sans"
        letter-spacing="0.02em"
      >
        <tspan x="0" y="0">不足</tspan>
      </text>
    </g>
  </svg>
</template>
