/**
 * Auth処理の実行関数を管理
 */
const callbackList = []

/**
 * 初期化時に各処理の保持を行う
 * @param {*} callback
 */
function registerCallback(callback) {
  if (!callbackList.includes(callback)) {
    callbackList.push(callback)
  }
}

/**
 * アクセストークンを取得
 * @returns アクセストークン
 */
async function getAccessToken() {
  for (const callback of callbackList) {
    if (callback.getAccessToken) {
      return await callback.getAccessToken()
    }
  }
}

/**
 * ログイン状態であるかどうか
 * @returns ログイン状態
 */
async function isAuthenticated() {
  for (const callback of callbackList) {
    if (callback.isAuthenticated) {
      return await callback.isAuthenticated()
    }
  }
}

/**
 * メール認証済みであるかどうか
 * @returns メール認証状態
 */
async function isEmailVerified() {
  for (const callback of callbackList) {
    if (callback.isEmailVerified) {
      return await callback.isEmailVerified()
    }
  }
}

/**
 * ログアウトを行う
 * @param {boolean} force 強制ログアウトを行うかフラグ
 */
async function logout({force = false} = {}) {
  for (const callback of callbackList) {
    if (callback.logout) {
      return await callback.logout({force})
    }
  }
}

/**
 * ログインを行う
 */
async function login() {
  for (const callback of callbackList) {
    if (callback.login) {
      return await callback.login()
    }
  }
}

/**
 * 新規登録を行う
 */
async function signup() {
  for (const callback of callbackList) {
    if (callback.signup) {
      return await callback.signup()
    }
  }
}

/**
 * Auth0の登録メールアドレスを取得
 */
function getAuth0EmailAddress() {
  for (const callback of callbackList) {
    if (callback.getAuth0EmailAddress) {
      return callback.getAuth0EmailAddress()
    }
  }
}

export {
  registerCallback,
  getAccessToken,
  isAuthenticated,
  isEmailVerified,
  logout,
  login,
  signup,
  getAuth0EmailAddress,
}
