// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/Icon_Delete_Btn.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-form[data-v-65ebe78c]{display:flex;flex-direction:row}.inputtext[data-v-65ebe78c]{height:38.33px;border-radius:20px;outline:none;line-height:20px;font-size:15px;padding-left:16px;padding-right:12px;width:100%;-webkit-appearance:none;-moz-appearance:none;appearance:none;color:#1a1c21;font-weight:400;transition:padding-right .01s}.inputText[data-v-65ebe78c]::-moz-placeholder{color:#979ca1;font-weight:300}.inputText[data-v-65ebe78c]::placeholder{color:#979ca1;font-weight:300}.inputtext[data-v-65ebe78c]:focus{padding-right:40px}input[data-v-65ebe78c]{-webkit-opacity:1}input[type=search][data-v-65ebe78c]::-webkit-search-cancel-button{-webkit-appearance:none}input[type=search][data-v-65ebe78c]:focus::-webkit-search-cancel-button{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:16px;background-position:50%;height:38.33px;width:36px;padding-right:0;position:absolute;right:0}.closeicon[data-v-65ebe78c]{width:32px;margin:auto}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
