<template>
  <div
    class="pl-3 w-full flex items-center justify-between rounded-[30px]"
    :class="isSuicaLinked ? '' : 'active:bg-blue200'"
  >
    <!-- Suica残高を表示 -->
    <div v-if="isSuicaLinked" class="flex flex-col">
      <div class="flex justify-between items-center">
        <span class="mr-1 text-gray400 text-W4 text-[11px]">Suica残高</span>
        <span v-if="isShowReadCardFailed">
          <InsufficientTag class="h-3 mr-1" />
        </span>
      </div>
      <div
        v-if="isReadCardCompleted"
        :class="getBalanceAddClass()"
        class="flex items-center"
      >
        {{ balanceText }}
      </div>
      <BaseLoading v-else />
    </div>
    <div v-else class="flex items-center">
      <span class="text-[12px] text-primary text-W5 mr-[1px]">
        Suica残高を表示
      </span>
    </div>
    <div
      class="flex justify-center items-center h-9 w-9 rounded-[30px]"
      :class="isSuicaLinked ? 'active:bg-blue200' : ''"
      @click="isSuicaLinked ? clickCharge() : ''"
    >
      <!-- チャージアイコン表示 -->
      <img class="h-4 w-4" src="@/assets/PlusCircleBlue.svg" />
    </div>
  </div>
</template>

<script>
import Util from '@/mixins/util'
import suica from '@/utils/suicaNativeUtil'
import InsufficientTag from '@/components/icons/suica/InsufficientTag.vue'
import BaseLoading from '@/components/atoms/BaseLoading.vue'

const SuicaBalance = {
  name: 'SuicaBalance',
  components: {
    InsufficientTag,
    BaseLoading,
  },
  props: {
    /**
     * Suica残高
     */
    balance: {
      type: Number,
      required: true,
    },
    /**
     * 残高取得が完了したか
     */
    isReadCardCompleted: {
      type: Boolean,
      default: false,
    },
    /**
     * 残高取得失敗したか
     */
    isReadCardFailed: {
      type: Boolean,
      default: false,
    },
    /**
     * 残高が不足したか
     */
    isBalanceFallingShort: {
      type: Boolean,
      default: false,
    },
    /**
     * 連携済みか
     */
    isSuicaLinked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * 残高不足表示にするか判定
     * 残高取得が完了していないか、残高取得に失敗した場合は残高不足表示を行わない
     */
    isShowReadCardFailed() {
      return (
        this.isReadCardCompleted &&
        !this.isReadCardFailed &&
        this.isBalanceFallingShort
      )
    },
    /**
     * Suica残高部分に表示するテキスト
     */
    balanceText() {
      // 残高取得に失敗
      if (this.isReadCardFailed) {
        return '取得できませんでした'
      }
      return `¥${Util.methods.addCommaForInteger(this.balance)}`
    },
  },
  methods: {
    /**
     * 残高にあてるクラスを取得する
     */
    getBalanceAddClass() {
      if (this.isReadCardFailed) {
        return 'text-gray400 text-W4 text-[11px] is-read-card-failed'
      }
      const balanceFallingShortClass = 'text-danger300 text-W5 text-[13px]'
      const balanceSuccessShortClass = 'text-black text-W5 text-[13px]'
      return this.isShowReadCardFailed
        ? balanceFallingShortClass
        : balanceSuccessShortClass
    },
    /**
     * チャージリンク押下処理
     */
    async clickCharge() {
      // Suicaアプリを開く
      await suica.openSuicaApp()
    },
  },
}
export default SuicaBalance
</script>
<style scoped>
.is-read-card-failed span {
  color: #979ca1;
}
</style>
