<template>
  <div>
    <!-- フォーム部分 -->
    <TextFormV2
      :text="text"
      :inputmode="inputmode"
      :placeholder="placeholder"
      :isBackButton="false"
      :maxlength="maxlength"
      :inputClass="formColorClass"
      :isDisabled="isDisabled"
      @input-text="$emit('input-text', $event)"
      @blur="validateConvertedNumber()"
    />
    <!-- エラーメッセージ部分 -->
    <div
      v-show="showErrorMessage"
      class="mt-2 text-left text-danger300 text-W2 leading-[13px]"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import TextFormV2 from '@/components/TextFormV2.vue'
import {useVuelidate} from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import CustomValidationRules from '@/mixins/customValidationRulesUtil.js'
import Util from '@/mixins/util.js'

const TextFormWithValidation = {
  name: 'TextFormWithValidation',
  mixins: [CustomValidationRules, Util],
  components: {TextFormV2},
  setup() {
    return {v$: useVuelidate()}
  },
  props: {
    // 最大文字数
    maxlength: {
      type: String,
      default: '-1',
    },
    // 入力テキスト
    text: {
      type: String,
      required: true,
    },
    // input要素で表示するキーボードの指定
    inputmode: {
      type: String,
      default: 'text',
    },
    // プレースホルダーのテキスト
    placeholder: {
      type: String,
      default: '入力',
    },
    // エラー時のテキスト
    errorMessage: {
      type: String,
      default: '',
    },
    // バリデーションの種類
    validationType: {
      type: Array,
      required: true,
    },
    // フォームに付与するクラス
    inputClass: {
      type: String,
      default: '',
    },
    // テキストコンポーネントの操作可否状態
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // 入力値のエラーフラグ
    isError: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * バリデーションルールの付与
   */
  validations() {
    return {
      text: this.validationList(),
    }
  },
  emits: ['input-text', 'update:isError'],
  computed: {
    /**
     * 入力値のエラーフラグ
     */
    isErrorFlg: {
      get() {
        return this.isError
      },
      set(value) {
        this.$emit('update:isError', value)
      },
    },
    /**
     * フォームに付与するクラスの切り替え（エラー時 : デフォルト時）
     */
    formColorClass() {
      const errorClass = '!border-danger300 bg-danger100'
      return this.isErrorFlg ? errorClass : this.inputClass
    },
    /**
     * 入力文字がバリデーションエラーであるかどうか
     */
    isInvalid() {
      return this.v$.text.$invalid
    },
    /**
     * エラーメッセージを表示するか
     */
    showErrorMessage() {
      return this.errorMessage && this.isErrorFlg
    },
  },
  methods: {
    /**
     * 付与するバリデーションを設定し返却する
     * @returns {Object} チェックを行うバリデーション
     */
    validationList() {
      let validation = {}

      // 必須チェック付与
      if (this.validationType.includes('required')) {
        validation.required = required
      }
      // メールアドレスチェック付与
      if (this.validationType.includes('email')) {
        validation.email = this.email
      }
      // 電話番号チェック付与
      if (this.validationType.includes('tel')) {
        validation.tel = this.tel
      }
      // クレジットカード番号チェック付与
      if (this.validationType.includes('creditCardNumber')) {
        validation.creditCardNumber = this.creditCardNumber
      }
      // クレジットカード有効期限(月)チェック付与
      if (this.validationType.includes('creditCardExpireMonth')) {
        validation.creditCardExpireMonth = this.creditCardExpireMonth
      }
      // クレジットカード有効期限(年)チェック付与
      if (this.validationType.includes('creditCardExpireYear')) {
        validation.creditCardExpireYear = this.creditCardExpireYear
      }
      // クレジットカードセキュリティコードチェック付与
      if (this.validationType.includes('creditCardSecurityCode')) {
        validation.creditCardSecurityCode = this.creditCardSecurityCode
      }

      return validation
    },
    /**
     * フォーカスアウト時、全角数値を半角数値に変換してバリデーションを行う処理
     */
    async validateConvertedNumber() {
      // 半角数値に修正
      const parsedVal = this.getConvertedHankakuNumber(this.text)
      // 親に修正を通知
      this.$emit('input-text', parsedVal)

      // バリデーション結果取得
      const validationResult = await this.v$.text.$validate()
      // エラーがなければtrueが返ってくるので、falseをエラーフラグに詰める
      this.isErrorFlg = !validationResult
    },
  },
}
export default TextFormWithValidation
</script>
<style scoped></style>
