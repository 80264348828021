<!-- 地点検索TOPコンポーネント -->
<template>
  <div class="!pointer-events-none">
    <!-- 未決済 注意文言 -->
    <div
      v-if="!isReservedOrUsingOfMobility && isPaymentFailing"
      class="flex w-full mt-2 border-danger300 pointer-events-auto"
      id="search-top-payment-message"
    >
      <div
        class="flex items-center w-full bg-danger100 mx-5 p-3 border-[1px] border-solid border-danger300 rounded-[8px]"
        @click="onClickPaymentCaution()"
      >
        <img src="@/assets/Icon_Caution.svg" class="h-4 w-4 mr-2" />
        <div class="text-W4 text-[13px] leading-[13px] text-danger300 mr-auto">
          未決済のご利用があります。
        </div>
        <img src="@/assets/Icon_Right_gray.svg" class="h-3 w-3 self-center" />
      </div>
    </div>
    <!-- バナー -->
    <BaseBanner
      v-show="isShowBanner"
      class="mt-4"
      :url="bannerInfo.url"
      :pictureSrc="bannerInfo.src"
      :pageName="bannerInfo.pageName"
      position="left"
      @clickClose="closeBanner()"
    />
  </div>
  <!-- 検索フォーム -->
  <div
    ref="panelContents"
    class="absolute w-full bottom-0 !pointer-events-none"
  >
    <div class="flex mx-5 mb-2">
      <div
        class="h-[52px] w-[52px] bg-white shadow-normal rounded-[8px] flex items-center justify-center pointer-events-auto"
        @click="selectWeatherBox()"
      >
        <!-- 天気取得失敗時はリロード表示 -->
        <img
          v-if="judgeWeatherFetchStatus == weatherFetchStatus.FAILED"
          class="h-6 w-6"
          src="@/assets/ReloadIcon.svg"
        />
        <!-- 未取得の場合はローディング -->
        <BaseLoading
          width="16"
          v-else-if="judgeWeatherFetchStatus == weatherFetchStatus.LOADING"
        />
        <!-- 取得済みの場合は天気表示 -->
        <div
          v-else-if="judgeWeatherFetchStatus == weatherFetchStatus.FETCHED"
          class="flex items-center justify-center"
        >
          <!-- レイアウト調整のためダミー要素 -->
          <div class="mr-1 w-2" />
          <div class="flex flex-col justify-center items-center">
            <img
              class="h-4 w-4"
              :src="weatherIcon(currentDailyWeatherInfo.code)"
            />
            <div class="text-W7 text-[11px] leading-[11px] mt-1">
              <span class="text-gray600">
                {{ `${Math.round(currentDailyWeatherInfo.temperature)}` }}
              </span>
              <span class="text-gray500">℃</span>
            </div>
          </div>
          <img class="ml-1 h-2 w-2" src="@/assets/Icon_Right_gray.svg" />
        </div>
      </div>
    </div>
    <BaseCard
      class="mx-2 pointer-events-auto"
      :style="{'margin-bottom': searchMarginBottomHeight}"
      roundedValue="[8px]"
    >
      <div class="px-1.5 py-[6.5px] flex">
        <!--
          下記input要素をタップすることで、Suggestコンポーネントの入力フォームにフォーカスする
          ※Mobile Safariではinput要素に対する操作でなければ入力フォームにフォーカスさせることができない
        -->
        <img src="@/assets/Icon_Route_Glasses_black.svg" class="w-4 h-4" />
        <div class="flex-1 relative">
          <div class="absolute w-full left-0 h-[15px] pl-2 text-left">
            <div class="text-W4 text-[15px] leading-[15px] text-gray600">
              行きたい場所を入力
            </div>
          </div>
          <input
            type="text"
            class="absolute w-full left-0 opacity-0"
            readonly
            @click="isShowSuggest = true"
          />
        </div>
      </div>
    </BaseCard>
    <SearchTopSuggest
      :panelSpaceHeight="panelSpaceHeight"
      :isShowSuggest="isShowSuggest"
      @click-back-button="isShowSuggest = false"
      @select-spot="gotoRouteByOptimal($event)"
      @select-history-route="gotoRouteByOptimal($event, 'routeHistory')"
    ></SearchTopSuggest>
    <Teleport to="#app-page">
      <WeatherModal
        v-if="weatherInfoModalFlg"
        title="現在地の天気"
        :observatory="currentSpotWeather.observatory"
        :daily="currentSpotWeather.daily"
        :weekly="currentSpotWeather.weekly"
        @closeModal="weatherInfoModalFlg = false"
      />
    </Teleport>
  </div>
</template>
<script>
/**
 * 地点検索TOPコンポーネント
 * ＜概要＞
 * ・TOP画面のパネル部に最初に表示するコンポーネント
 * ・経由地の有無や数によって表示内容や高さが動的に変化する
 */
import Util from '@/mixins/util'
import BaseCard from '@/components/atoms/BaseCard.vue'
import SearchTopSuggest from '@/components/organisms/searchRoute/SearchTopSuggest.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import * as mobilityUtil from '@/utils/mobilityUtil'
import BaseBanner from '@/components/atoms/BaseBanner.vue'
import * as WeatherUtil from '@/utils/weatherUtil.js'
import WeatherModal from '@/modals/weatherModal.vue'
import BaseLoading from '@/components/atoms/BaseLoading.vue'
import NativeUtil from '@/mixins/nativeUtil'
import {INSURANCE_BANNER_FOR_SEARCH_TOP} from '@/utils/bannerUtil'

const WEATHER_FETCH_STATUS = {
  LOADING: 0,
  FETCHED: 1,
  FAILED: 2,
}
const SearchTop = {
  name: 'SearchTop',
  components: {
    BaseCard,
    SearchTopSuggest,
    FooterMargin,
    BaseBanner,
    WeatherModal,
    BaseLoading,
  },
  mixins: [Util],
  data() {
    return {
      isShowSuggest: this.$store.state.isShowSuggestFlg, // 地点検索表示フラグ
      isShowBanner: this.$store.state.isShowInsuranceServiceBanner, // バナー表示フラグ
      isFetchWeatherFailed: false, // 天気情報取得失敗フラグ
      weatherInfoModalFlg: false, // 天気モーダル表示フラグ
    }
  },
  created() {
    // 地点検索コンポーネントの表示フラグを初期化
    this.$store.commit('updateIsShowSuggestFlg', false)

    //ルート検索条件/結果をクリアする
    this.$store.commit('RouteStore/resetConditionAndResult')
    // 検索条件の初期化
    this.$store.commit('initializeSearchConditions')
    // 保持していたルート描画データを初期化
    this.$store.commit('clearDrawRouteData')

    // 初回のルート検索実施フラグの初期化
    this.$store.commit('updateSearchRoute', false)
    this.$store.commit('RouteStore/updateIsCompleteSearchRoute', false)

    // マップ長押しのイベントハンドラー
    window.addEventListener(
      this.$config.CUSTOM_EVENT_HANDLER.LONG_PRESS_MAP,
      this.onMapLongPress
    )
  },
  mounted() {
    this.initRouteMap()
    // 天気取得実行&アプリ表示時のイベントリスナーに追加
    NativeUtil.methods.addResumeEvent(this.fetchSpotWeather)
    this.fetchSpotWeather()
  },
  beforeUnmount() {
    NativeUtil.methods.removeResumeEvent(this.fetchSpotWeather)
    // マップ操作関連のイベントリスナー削除
    window.removeEventListener(
      this.$config.CUSTOM_EVENT_HANDLER.LONG_PRESS_MAP,
      this.onMapLongPress
    )
  },
  computed: {
    /**
     * 利用中のモビリティ種別
     */
    usingMobilityType() {
      return this.$store.getters[
        'MobilityReservationStore/reservedOrUsingOfMobilityType'
      ]
    },
    /**
     * 「予約中」または「利用中」のモビリティがあるかどうか
     */
    isReservedOrUsingOfMobility() {
      return this.usingMobilityType !== this.$config.USING_MOBILITY_TYPE.NONE
    },
    /**
     * 決済が失敗状態であるかどうか
     * @return 決済失敗有無
     */
    isPaymentFailing() {
      return mobilityUtil.determiningPaymentFailing(
        this.$store.state.CreditCardsStore.paymentStatus
      )
    },
    /**
     * パネルの上部スペース
     */
    panelSpaceHeight() {
      return this.$store.state.topSafeAreaHeight
    },
    /**
     * 地点検索コンポーネント下部マージンの高さ
     */
    searchMarginBottomHeight() {
      const baseMarginBottom = 31
      return `${baseMarginBottom + this.$config.FOOTER_HEIGHT}px`
    },
    /**
     * 表示するバナー情報
     * @returns ジャンプ先、画像パス
     */
    bannerInfo() {
      return INSURANCE_BANNER_FOR_SEARCH_TOP
    },
    /**
     * 現在地点の天気情報
     */
    currentSpotWeather() {
      return this.$store.state.RouteStore.weather.currentSpot
    },
    /**
     * 時間毎の天気情報の中から直近情報を返却する
     * @returns 現在時刻に最も近い天気情報
     */
    currentDailyWeatherInfo() {
      return this.currentSpotWeather.daily[0] // eslint-disable-line no-magic-numbers
    },
    /**
     * 現在地点の天気情報が取得済みかどうか
     */
    isNotFetchedWeather() {
      // Storeの現在地における天気情報が初期値の場合、未取得と判断する
      return this.currentSpotWeather.daily.length === 0 // eslint-disable-line no-magic-numbers
    },
    /**
     * 天気取得ステータス一覧
     */
    weatherFetchStatus() {
      return WEATHER_FETCH_STATUS
    },
    /**
     * 天気取得ステータス判定
     */
    judgeWeatherFetchStatus() {
      if (this.isFetchWeatherFailed) return WEATHER_FETCH_STATUS.FAILED

      if (this.isNotFetchedWeather) return WEATHER_FETCH_STATUS.LOADING

      return WEATHER_FETCH_STATUS.FETCHED
    },
  },
  methods: {
    /**
     * ルートマップの初期化処理
     */
    initRouteMap() {
      // マップ生成前の場合は何もしない
      if (!this.$root.$refs.routeMap?.$data?.frozen.mapRelated.map) return

      // 検索した地点のアイコンを親側で削除
      this.$root.$refs.routeMap.clearSearchSpotMarkers()

      // 前画面からモードを引き継ぐか
      if (!this.$route.params?.isKeepMapMode) {
        this.$root.$refs.routeMap.changeNorthUpMode(true)
      }
    },
    /**
     * ルート検索の設定を行い、最適画面に遷移する
     * @param {Object} spot 地点情報もしくはルート情報
     * @param {String} target 保存対象のデータ名
     */
    gotoRouteByOptimal(spot, target = '') {
      // 検索条件の初期化
      this.$store.commit('initializeSearchConditions')
      if (target == 'routeHistory') {
        // 出発地点設定処理
        this.$store.commit('updateSearchConditions', {
          key: this.$config.SPOT_TYPE_START,
          value: spot.start,
        })
        // 出発地点設定処理
        this.$store.commit('updateSearchConditions', {
          key: this.$config.SPOT_TYPE_VIA,
          value: spot.via,
        })
        // 到着地点設定処理
        this.$store.commit('updateSearchConditions', {
          key: this.$config.SPOT_TYPE_GOAL,
          value: spot.goal,
        })
      } else {
        // 出発地点設定処理
        this.$store.commit('updateSearchConditions', {
          key: this.$config.SPOT_TYPE_START,
          value: {
            name: '現在地',
            address: '',
            coord: '',
          },
        })
        // 到着地点設定処理
        this.$store.commit('updateSearchConditions', {
          key: this.$config.SPOT_TYPE_GOAL,
          value: spot,
        })
      }
      // 最適画面に遷移
      this.$router.push({name: 'RouteByOptimal'})
    },
    /**
     * 保険サービスのバナーを閉じる
     */
    closeBanner() {
      this.isShowBanner = false
      this.$store.commit('updateIsShowInsuranceServiceBanner', false)
    },
    /**
     * 決算警告メッセージクリック処理
     */
    onClickPaymentCaution() {
      // 現在のタブの表示ページ情報を保持しておく
      this.storeCurrentTabInformation()
      // 乗り物手配Top画面へ遷移させる
      this.$router.push({name: this.$config.DISPLAY_ARRANGEMENT_TOP})
      // ストアのタブIDを更新
      this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_ARRANGEMENT)
      // ストレージのタブIDを更新
      this.setLocalStorage(
        this.$config.FOOTER_TAB,
        this.$config.FOOTER_ID_ARRANGEMENT
      )
    },

    /**
     * 現在のタブの表示ページ情報を保持する
     */
    storeCurrentTabInformation() {
      const footerName = this.$config.FOOTER_LIST.find((footer) => {
        return footer.id == this.$store.state.selectedFooterTab
      }).name
      let page = {key: footerName, value: this.$route.name}
      this.$store.commit('updateCurrentPage', page)
    },
    /**
     * 現在地に戻る
     */
    goBackCurrentPosition() {
      this.$root.$refs.routeMap?.currentSpotFit?.()
    },
    /**
     * マップを長押しした際に目的地設定画面へ遷移
     */
    onMapLongPress() {
      // マップ生成前の場合は何もしない
      if (!this.$root.$refs.routeMap?.$data?.frozen.mapRelated.map) return
      // マップの中心を取得
      const mapCenter =
        this.$root.$refs.routeMap?.$data?.frozen.mapRelated.map.getCenter()
      // 遷移前に中心を保持
      this.$store.commit('updateMapCenter', mapCenter)
      // 目的地設定画面へ遷移する
      this.$router.push({name: this.$config.DISPLAY_DESTINATION_SETTING})
    },
    /**
     * 天気情報を取得しStoreへ反映を行う
     */
    async fetchSpotWeather() {
      const {lat, lon} = this.$store.state.currentPosition
      if (lat == null || lon == null) {
        // 位置情報未取得の場合は取得失敗時同様に扱う
        // 天気取得失敗フラグを立てる
        this.isFetchWeatherFailed = true
        return
      }
      const success = (result) => {
        // 更新処理
        this.$store.commit('RouteStore/updateWeather', {
          target: 'currentSpot',
          daily: result.daily,
          weekly: result.weekly,
          observatory: result.observatory.name,
        })
      }
      const failed = () => {
        // 天気が未取得の状態の場合のみ、天気取得失敗フラグを立てる
        // 取得済みの場合は表示上変化させない(リロード表示させない)
        if (this.isNotFetchedWeather) {
          this.isFetchWeatherFailed = true
        }
      }
      this.$store.dispatch('RouteStore/getWeather', {
        success: success,
        failed: failed,
        error: failed,
        lat: lat,
        lon: lon,
      })
    },
    /**
     * 天気アイコン取得
     * @param {String} code 天気コード
     * @returns 天気アイコン
     */
    weatherIcon(code) {
      return WeatherUtil.getWeatherIcon(code)
    },
    /**
     * 天気情報選択時処理
     * ・ローディング中：なにもしない
     * ・取得失敗時：再取得
     * ・取得成功時：天気モーダル表示
     */
    selectWeatherBox() {
      switch (this.judgeWeatherFetchStatus) {
        case WEATHER_FETCH_STATUS.FAILED: // 失敗時(リロードボタン時)は天気再取得を行う
          this.isFetchWeatherFailed = false
          this.fetchSpotWeather()
          break
        case WEATHER_FETCH_STATUS.FETCHED: // 成功時(天気表示時)は天気モーダルを表示する
          this.weatherInfoModalFlg = true
          break
        case WEATHER_FETCH_STATUS.LOADING: // ローディング中は何もしない
        default:
          break
      }
    },
  },
}
export default SearchTop
</script>
<style scoped></style>
