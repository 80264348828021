<template>
  <div id="RouteSearchSpots" class="relative">
    <div class="route-info text-W2 text-left ml-0.5 flex-1 truncate">
      <!-- 出発地 -->
      <SpotName id="spot-start" :spotName="start" type="start" />
      <WeatherInfo
        v-if="existWeather(weather.start)"
        class="ml-5 mt-1"
        :weather="weather.start"
        weatherModalTitle="本日の出発地の天気"
      />
      <div
        v-if="existVia"
        class="ml-5 my-4 text-W4 text-[12px] leading-3 text-primary h-3"
      >
        <!-- 経由地の表示・非表示切り替え -->
        <div class="flex">
          <img
            :src="require(`@/assets/${showViaIcon}`)"
            class="h-2.5 mx-[1px]"
          />
          <span class="ml-1.5 leading-3" @click="changeShowVia">
            {{ showViaMessage }}
          </span>
        </div>
      </div>
      <!-- 経由地 -->
      <div v-show="showVia">
        <div class="first:mt-0 mt-2" v-for="spot in via" :key="spot.id">
          <SpotName :spotName="spot.name" type="via" />
        </div>
      </div>
      <!-- 目的地 -->
      <SpotName id="spot-goal" class="mt-4" :spotName="goal" type="goal" />
      <WeatherInfo
        v-if="existWeather(weather.goal)"
        class="ml-5 mt-1"
        :weather="weather.goal"
        weatherModalTitle="本日の目的地の天気"
      />
    </div>
    <div id="route-border" class="route-border" />
  </div>
</template>

<script>
/**
 * 経路表示コンポーネント
 * ＜概要＞
 * 出発地、経由地、目的地を表示する
 * 経由地は、あれば表示する
 * 経由地の表示・非表示の切り替えは、出発地と目的地の間に表示する
 */
import SpotName from '@/components/atoms/SpotName.vue'
import WeatherInfo from '@/components/molecules/WeatherInfo.vue'
const RouteSearchSpots = {
  name: 'RouteSearchSpots',
  components: {SpotName, WeatherInfo},
  props: {
    /**
     * 出発地点名
     */
    start: {
      type: String,
      require: true,
    },
    /**
     * 経由地点名
     */
    via: {
      type: Array,
      require: false,
      default: () => {
        return []
      },
    },
    /**
     * 目的地点名
     */
    goal: {
      type: String,
      require: true,
    },
    /**
     * 天気情報
     */
    weather: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      // 経由地表示フラグ
      showVia: false,
    }
  },
  mounted() {
    this.setRouteBorderHeight()
  },
  updated() {
    this.setRouteBorderHeight()
  },
  computed: {
    /**
     * 経由地表示・非表示の画像を切り替える
     * @returns 経由地開閉のアイコン名
     */
    showViaIcon() {
      return this.showVia ? 'Icon_topV2.svg' : 'Icon_downV2.svg'
    },
    /**
     * 経由地表示・非表示時のメッセージを切り替える
     * @returns 経由地開閉のテキスト
     */
    showViaMessage() {
      return this.showVia ? '経由地を非表示' : '経由地を表示'
    },
    /**
     * 経由地情報があるか
     * @return true：経由地がある
     *         false：経由地がない
     */
    existVia() {
      return this.via.length !== 0 // eslint-disable-line no-magic-numbers
    },
  },
  methods: {
    /**
     * 経由地表示フラグを切り替える
     */
    changeShowVia() {
      this.showVia = !this.showVia
    },
    /**
     * ルート経路左側の縦線高を算出し設定
     */
    setRouteBorderHeight() {
      // 要素を取得
      const spotStart = document.getElementById('spot-start')
      const spotGoal = document.getElementById('spot-goal')
      const border = document.getElementById('route-border')
      // 出発地~到着地の要素間の高さを算出
      const spotStartY = spotStart.getBoundingClientRect().y
      const spotGoalY = spotGoal.getBoundingClientRect().y
      const spotDiff = spotGoalY - spotStartY
      // CSS変数に算出した高さを設定
      border.style.setProperty('--borderHeight', `${spotDiff}px`)
    },
    /**
     * 天気情報が存在するかどうか (時間毎の天気が存在するかで判断)
     * @param {Object} spotWeather 天気情報
     * @returns 天気情報の存在有無
     */
    existWeather(spotWeather) {
      return spotWeather.daily.length > 0 // eslint-disable-line no-magic-numbers
    },
  },
}
export default RouteSearchSpots
</script>

<style scoped>
/* ルート経路情報 */
.route-info {
  position: relative;
  z-index: 1;
  transform: translateZ(1px);
}
/* ルート経路左側の線 */
.route-border {
  --borderHeight: 100px;
  position: absolute;
  left: 7px;
  top: 3px;
  border-right: 1px dashed #979ca1;
  height: var(--borderHeight);
}
</style>
