/**
 * メニュー用Storeモジュール
 */
import * as Axios from '@/service/AxiosService'
import config from '@/const/const.js'
const constant = config.constant

export default {
  namespaced: true,
  state: {
    // お気に入り地点情報
    favoriteSpots: {
      // 自宅
      home: {
        spotName: '', // スポット名
        address: '', // 住所
        buildingName: '', // マンション名、号室
        coord: '', //座標
      },
      // 会社/学校
      work: {
        spotName: '', // スポット名
        address: '', // 住所
        buildingName: '', // マンション名、号室
        coord: '', //座標
      },
      // お気に入り地点
      favorite: [],
    },
  },
  getters: {},
  mutations: {
    /**
     * お気に入り地点 更新処理
     * @param {*} state
     * @param {*} obj
     */
    updateFavoriteSpots(state, obj) {
      state.favoriteSpots = obj
    },
    /**
     * Storeの復元
     * @param {*} state Storeオブジェクト
     * @param {*} value IndexedDBに格納されていたデータ
     */
    restoreStore(state, value) {
      state.favoriteSpots = value.favoriteSpots
    },
  },
  actions: {
    /**
     * Navitimeの時刻表ライセンス情報取得
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     */
    async getNavitimeTimetableLicense(_, {success, failed, error}) {
      return Axios.get('timetableLicense')
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
  },
}
