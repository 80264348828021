<template>
  <div>
    <!-- 乗り換え結果画面 -->
    <div class="fixed top-0 w-full h-full bg-gray200 flex flex-col">
      <!-- ヘッダー部 -->
      <div id="header-area" class="header relative pt-safe-area shadow-normal">
        <div class="flex pt-4 pb-5 pr-4 pl-3">
          <!-- 戻るアイコン -->
          <div
            id="backButton"
            class="circle top-4 left-3 mr-4 shadow-normal"
            @click="goToBackPage()"
          >
            <img
              class="h-4 w-4 mt-2.5 mx-auto"
              src="@/assets/Icon_Left_gray.svg"
            />
          </div>
          <!-- 経路情報 -->
          <div
            class="flex-1 w-full h-full rounded-[20px] bg-white shadow-normal"
          >
            <DisplaySpotAndTime
              :searchConditions="transferConditions"
              :isShowOptions="true"
              @click-options="clickSelectMobility"
              @display-calendar-set-time="isShowCalenderSelectModal = true"
            />
          </div>
        </div>
      </div>
      <!-- ボディ部 -->
      <div
        class="overflow-y-auto scroll-bar-none w-full flex-1"
        id="routeByTrainBusContents"
      >
        <!-- コンテンツ部（ルートがある場合） -->
        <div v-if="isTransferSearchResult">
          <!-- カード -->
          <div class="py-[18px] px-6 h-full">
            <MobilityTrainBusCard
              class="mb-3 last:mb-0"
              v-for="(route, index) in transferSearchResult"
              :key="index"
              :routeNo="route.routeNo"
              :routeInfo="route"
              :isNoTransfersCount="route.transitCount === 0"
              :isFirstTag="isRouteFastTime(route)"
              :isLowCostTag="isOptimalRoute('summaryFare', route.summaryFare)"
              @click-train-bus-card="clickTransferCard(route)"
            />
          </div>
          <FooterMargin />
        </div>
        <!-- コンテンツ部（ルートが一つも存在しない場合） -->
        <div v-else class="mt-16">
          <div class="text-W2 text-[13px] leading-[13px] text-gray500">
            ルートがみつかりませんでした
          </div>
          <div class="mt-4 flex justify-center">
            <img :src="errorSrc" class="h-[140px] w-[140px]" />
          </div>
        </div>
      </div>
    </div>
    <!-- 移動手段絞り込みポップアップ -->
    <Modal
      class="modal"
      v-if="showMobilityRadioButton"
      :isShowCloseButton="true"
      modalPaddingBottom="8px"
      @disPlayModal="closeSelectedRadioButton"
    >
      <div class="pt-7 ml-7 text-W7 text-[13px] leading-[13px] text-left">
        移動手段の絞り込み
      </div>
      <MobilityRadioButton
        class="ml-4 mr-5 mt-2"
        v-model:selectMobilityList="mobilityList"
      ></MobilityRadioButton>
      <div class="mt-6 pb-4 px-4">
        <TheButton text="設定を完了" @click-button="completeSetting" />
      </div>
    </Modal>
    <!-- 時刻設定ポップアップ -->
    <Modal
      class="modal"
      v-if="isShowCalenderSelectModal"
      @disPlayModal="isShowCalenderSelectModal = false"
      modalPaddingBottom="8px"
    >
      <div id="select-datetime-modal">
        <CalendarSelectForm
          @completeDate="completeDate"
          :initialDateTime="transferConditions.targetTime"
          :initialTargetType="transferConditions.timeType"
          :isShowFirstLastTrain="true"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import Util from '@/mixins/util'
import MobilityTrainBusCard from '@/components/MobilityTrainBusCard.vue'
import DisplaySpotAndTime from '@/components/organisms/DisplaySpotAndTime.vue'
import MobilityRadioButton from '@/components/molecules/MobilityRadioButton.vue'
import Modal from '@/components/Modal.vue'
import deepcopy from 'deepcopy'
import TheButton from '@/components/atoms/TheButton.vue'
import CalendarSelectForm from '@/components/CalendarSelectForm.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'

// 出発・到着情報
const TIME_TYPE = {
  START: 1,
  GOAL: 2,
}
const TransferResult = {
  name: 'TransferResult',
  components: {
    MobilityTrainBusCard,
    DisplaySpotAndTime,
    MobilityRadioButton,
    Modal,
    TheButton,
    CalendarSelectForm,
    FooterMargin,
  },
  mixins: [Util],
  data() {
    return {
      selectedMobilityList: deepcopy(
        this.$store.state.TransferStore.transferConditions.transferOptions
      ), // 編集用の移動手段リスト
      showMobilityRadioButton: false, // ポップアップ表示フラグ
      isShowCalenderSelectModal: false, //時刻設定ポップアップ表示フラグ
      errorSrc: this.getErrorAssetsSrc(), // 検索失敗時の画像パス
    }
  },
  created() {},
  computed: {
    /**
     * 乗り換え経路情報取得
     */
    transferConditions() {
      return this.$store.state.TransferStore.transferConditions
    },
    /**
     * 移動手段リスト
     */
    mobilityList: {
      /**
       * 各乗り物のid、名称、選択状態を取得する
       * @returns {Array} 各乗り物のid、名称、選択状態
       */
      get() {
        let arr = []
        for (let key in this.selectedMobilityList) {
          const obj = {
            id: key,
            title: this.$config.SELECT_MOBILITY[key],
            isValid: this.selectedMobilityList[key],
          }
          arr.push(obj)
        }
        return arr
      },
      /**
       * 各乗り物のid、名称、選択状態を更新する
       * @param {Array} value 各乗り物のid、名称、選択状態
       */
      set(value) {
        let arr = {}
        for (let mobility of value) {
          arr[mobility.id] = mobility.isValid
        }
        this.selectedMobilityList = arr
      },
    },
    /**
     * 検索結果取得
     */
    transferSearchResult() {
      return this.$store.state.TransferStore.transferSearchResult
    },
    /**
     * 全ルート結果から到着時間を抽出し、リストにする
     * @return {Array} 到着時間のリスト
     */
    getGoalTimeList() {
      let list = []
      this.transferSearchResult.forEach((result) => {
        list.push(this.getGoalTime(result))
      })
      return list
    },
    /**
     * 乗り換え検索結果が存在するかどうか
     */
    isTransferSearchResult() {
      return (
        !this.isNull(this.transferSearchResult) &&
        this.transferSearchResult.length !== 0 // eslint-disable-line no-magic-numbers
      )
    },
  },
  methods: {
    /**
     * ルート内で最短時間かどうかを返却
     * 出発検索:到着時刻が早いデータにラベルを付与
     * 到着検索:総移動時間が短いデータにラベルを付与
     * @param {Object} value 比較対象データ
     * @return {Boolean} 一番早い到着時間かどうか
     */
    isRouteFastTime(value) {
      if (this.transferConditions.timeType.id === TIME_TYPE.START) {
        // 検索結果にある全ての到着時間と比較して一番早い時間のデータにラベルをつける
        const goalTime = this.getGoalTime(value)
        return this.isMinTime(goalTime)
      } else {
        return this.isMinObject(
          this.transferSearchResult,
          'summaryTime',
          value.summaryTime
        )
      }
    },
    /**
     * 到着時間を取得する
     * 対象データ
     * @param {Object} resultData routeNoごとのルートデータ
     * @return {String} 到着時間
     */
    getGoalTime(resultData) {
      const lastIndexAdjustment = 1
      let list = resultData.sections.filter(
        (section) => section.type === 'move'
      )
      return list[list.length - lastIndexAdjustment].toTime
    },
    /**
     * 対象データの到着時間が一番早いデータであるかを返却する
     * @param {Object} target 比較対象の到着時間
     * @return {Boolean} 検索結果データの中で到着時間が早いデータ
     */
    isMinTime(target) {
      return (
        this.getGoalTimeList.find((obj) => {
          return obj < target
        }) === undefined
      )
    },
    /**
     * 前の画面に遷移
     */
    goToBackPage() {
      // ストアに保持している検索結果を削除
      this.$store.commit('TransferStore/updateTransferSearchResult', [])
      // 乗り換え案内TOP画面に遷移
      this.$router.push({name: 'TransferTop'})
    },
    /* 日付選択完了時処理
     * 検索条件格納用Storeを更新後、再検索処理を実行する
     * @param {String} dateTime 検索条件：日時
     * @param {Object} targetType 検索条件：出発 or 到着 or 始発 or 終電
     */
    completeDate(dateTime, targetType) {
      // 乗り換え検索時に使用する時間を保持
      this.$store.commit('TransferStore/updateTransferConditions', {
        key: 'targetTime',
        value: dateTime,
      })
      // 検索条件に反映
      this.$store.commit('TransferStore/updateTransferConditions', {
        key: 'timeType',
        value: targetType,
      })
      // ポップアップを非表示にする
      this.isShowCalenderSelectModal = false

      // 再検索
      this.transferResearch()
    },
    /**
     * 絞り込みのクリックイベント
     */
    clickSelectMobility() {
      this.showMobilityRadioButton = true
    },
    /**
     * 絞り込みポップアップ画面から再検索処理
     * 設定を完了ボタンのクリックイベント
     */
    completeSetting() {
      // 編集用の移動手段リストをStoreにコミット
      this.$store.commit('TransferStore/updateTransferOptions', {
        key: 'transferOptions',
        value: this.selectedMobilityList,
      })

      // ポップアップを閉じる
      this.showMobilityRadioButton = false

      // 再検索
      this.transferResearch()
    },
    /**
     * ポップアップの設定を完了ボタン以外のクリックイベント
     */
    closeSelectedRadioButton() {
      // Storeの値を編集用の移動手段リストに反映
      this.selectedMobilityList = this.transferConditions.transferOptions

      // ポップアップを閉じる
      this.showMobilityRadioButton = false
    },
    /**
     * 最適ルートかどうかを返却
     * ・key=summaryFare：乗車金額
     * @param {String} key 検索対象のキー
     * @param {String | Number} value 比較対象の値
     * @return {Boolean} 最適ルートかどうか
     */
    isOptimalRoute(key, value) {
      return this.isMinObject(this.transferSearchResult, key, value)
    },
    /**
     * 乗り換えカード押下時処理
     * @param {*} route 選択した乗り換えカード情報
     */
    clickTransferCard(route) {
      let tempRoute = deepcopy(route)
      // sectionsのデータをNavitimeのbasis_routeに必要なデータのみの形に成型する
      tempRoute.basisRoute = this.getBasisRoute(route.sections)

      this.$store.commit(
        'TransferStore/updateSelectedTransferResult',
        tempRoute
      )

      // eslint-disable-next-line no-magic-numbers
      if (tempRoute.basisRoute.length === 0) {
        // basisRouteが存在しない場合、前後ルート検索を実行せずにルート詳細画面に遷移
        this.$router.push({name: 'TransferDetail'})
        return
      }

      this.$store.commit('startLoading')
      const vm = this
      const success = (result) => {
        vm.$store.commit('endLoading')
        // 検索結果をStoreに保持
        if (result) {
          vm.$store.commit('TransferStore/updateTransferSlideResult', result)
        }
        // ルート詳細画面に遷移
        vm.$router.push({name: 'TransferDetail'})
      }
      const failed = () => {
        vm.$store.commit('endLoading')
        // 前後情報の取得に失敗しているが、エラー表示はしない
        // ルート詳細画面に遷移
        vm.$router.push({name: 'TransferDetail'})
      }
      // 前後ルート検索
      // 仕様により、success,failed,error全て同じ挙動でOK
      this.$store.dispatch('TransferStore/getRouteSlide', {
        success: success,
        failed: failed,
      })
    },
    /**
     * 乗換情報の再検索処理
     * 更新されたstore情報(TransferConditions)を使って再検索を実行する
     * 成功時:検索結果用storeにデータを保持
     * 失敗時:検索結果用storeにデータに初期値を設定
     */
    transferResearch() {
      const vm = this
      // API処理 成功時
      const success = (result) => {
        // 検索結果の保持
        vm.$store.commit('TransferStore/updateTransferSearchResult', result)
        vm.$store.commit('endLoading')
      }

      // API処理 失敗時
      const failed = () => {
        vm.$store.commit('TransferStore/updateTransferSearchResult', [])
        vm.$store.commit('endLoading')
        throw new Error()
      }

      // API処理 失敗時
      const error = (e) => {
        // Storeをリセットする
        vm.$store.commit('TransferStore/updateTransferSearchResult', [])
        vm.$store.commit('endLoading')
        throw e
      }

      // 再検索処理
      vm.$store.commit('startLoading')
      vm.$store.dispatch('TransferStore/getRouteTotal', {
        success: success,
        failed: failed,
        error: error,
      })
    },
  },
}
export default TransferResult
</script>
<style scoped>
.header {
  background: linear-gradient(#61c7f7, #009ce5);
}
.circle {
  height: 36px;
  width: 36px;
  line-height: 36px;
  background: white;
  border-radius: 50%;
}
.modal {
  transform: translateZ(1px);
}
</style>
