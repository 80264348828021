import {constant} from '@/const/const.js'
import {isEmpty} from '@/utils/utils'

// TODO:サーバから取得
// 検索Top画面用の保険バナー情報
export const INSURANCE_BANNER_FOR_SEARCH_TOP = {
  pageName: '各種保険サービス',
  src: 'banner_insurance.png',
  url: process.env.VUE_APP_NAMO_INSURANCE_SERVICE_URL,
}
// メニューTop画面用の保険バナー情報
export const INSURANCE_BANNER_FOR_MENU_TOP = {
  pageName: '各種保険サービス',
  src: 'banner_insurance_menu.png',
  url: process.env.VUE_APP_NAMO_INSURANCE_SERVICE_URL,
}

// バナーデータ（優先）
// TODO:サーバから取得
const BANNER_PRIORITY_DATA = [
  {
    IMG_URL: 'banner_golf_insurance.png',
    CATEGORY_CODE: ['0102001001', '0102001002', '0102001004', '0208003001'],
    LINK_URL: process.env.VUE_APP_TMNF_GOLF_INSURANCE_URL,
    LINK_NAME: 'ゴルフ保険',
    PRIORITY: 1,
  },
  {
    IMG_URL: 'banner_airports_insurance.png',
    CATEGORY_CODE: ['0802002001'],
    LINK_URL: process.env.VUE_APP_TMNF_AIRPORTS_INSURANCE_URL,
    LINK_NAME: '海外旅行保険',
    PRIORITY: 2,
  },
]

// バナーデータ（モビリティ別）
// TODO:サーバから取得
const BANNER_DEFAULT_DATA = [
  {
    KEY: constant.MOBILITY.CAR,
    IMG_URL: 'banner_tyoinori_car_insurance.png',
    LINK_URL: process.env.VUE_APP_TMNF_TYOINORI_CAR_INSURANCE_URL,
    LINK_NAME: 'ちょいのり車保険',
  },
  {
    KEY: constant.MOBILITY.BICYCLE,
    IMG_URL: 'banner_bicycle_insurance.png',
    LINK_URL: process.env.VUE_APP_TMNF_BICYCLE_INSURANCE_URL,
    LINK_NAME: '自転車保険',
  },
]

/**
 * 検索条件を元にモビリティ別でバナーを設定
 * @param {*} searchConditions 検索条件
 * @param {String} mobility モビリティ
 * @returns バナー情報
 */
export function getMobilityBannerInfo(searchCondition, mobility) {
  // 検索条件から優先して表示したいバナーがあれば、それを返却
  const spotBanner = getPriorityBanner(searchCondition)
  if (!isEmpty(spotBanner)) return spotBanner

  // モビリティで表示したいバナーがあればそれを返却
  const mobilityBanner = BANNER_DEFAULT_DATA.find(
    (item) => item.KEY == mobility
  )
  return mobilityBanner || {}
}

/**
 * スポットから優先で設定するバナー情報を取得
 * @param {*} searchCondition 検索条件
 * @returns バナーの情報
 */
function getPriorityBanner(searchCondition) {
  // 指定したカテゴリーから優先バナー情報を取得
  const priorityBannerInfo = (categoryCode) => {
    return BANNER_PRIORITY_DATA.find((item) =>
      item.CATEGORY_CODE.includes(categoryCode)
    )
  }

  const goalBanner = priorityBannerInfo(searchCondition.goal.category?.code)
  // 目的地で優先バナー情報が取得出来ている場合は返却
  if (goalBanner !== undefined) return goalBanner

  const bannerList = []
  searchCondition.via.forEach((v) => {
    const viaBanner = priorityBannerInfo(v.category?.code)
    // 経由地で優先バナー情報が取得出来ている場合は返却
    if (viaBanner !== undefined) bannerList.push(viaBanner)
  })

  // 経由地で優先バナーが取得出来ない場合は終了
  if (isEmpty(bannerList)) return {}

  // 優先度順(昇順)に並び替え
  const bannerListPrioritySorted = bannerList.sort(
    (a, b) => a.PRIORITY - b.PRIORITY
  )

  // 最優先で表示するべきバナー情報(先頭)を返却
  return bannerListPrioritySorted[0] // eslint-disable-line no-magic-numbers
}
