<template>
  <div class="flex">
    <div class="w-3" />
    <div
      class="flex-1 px-2.5 py-2.5 bg-white w-full dynamic-height"
      :class="`${boxShadow} ${rounded}`"
    >
      <slot />
    </div>
    <div class="w-3" />
  </div>
</template>
<script>
const BaseCard = {
  name: 'BaseCard',
  props: {
    // 影スタイル値
    boxShadowValue: {
      type: String,
      require: false,
      default: 'normal',
    },
    // 角丸スタイル値
    roundedValue: {
      type: String,
      require: false,
      default: '[10px]',
    },
  },
  computed: {
    /**
     * カードに付与する影スタイル
     */
    boxShadow() {
      return 'shadow-' + this.boxShadowValue
    },
    /**
     * カードに付与する角丸スタイル
     */
    rounded() {
      return 'rounded-' + this.roundedValue
    },
  },
}
export default BaseCard
</script>
<style scoped></style>
