<template>
  <div class="flex flex-col">
    <div
      class="flex items-center active:bg-blue100 py-3 suggest-col"
      v-for="spot in spots"
      :key="spot.key"
      @click="$emit('click-suggest', spot)"
    >
      <div><IconSpot class="m-2 w-4 h-4 self-center" /></div>
      <div class="text-left ml-2">
        <div class="text-W4 text-[16px] leading-[19.5px]">
          {{ spot.name }}
        </div>
        <div class="text-W2 text-[13px] text-gray500 leading-[16.9px] mt-1">
          {{ getDistanceUnit(spot.distance) }}{{ spot.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSpot from '@/components/icons/common/IconSpot.vue'
import Util from '@/mixins/util'

const SuggestList = {
  name: 'SuggestList',
  mixins: [Util],
  components: {
    IconSpot,
  },
  props: {
    spots: Array,
  },
  emits: ['click-suggest'],
  methods: {
    /**
     * 距離表示処理(非表示含む)
     * 現在地が未取得の場合は、nullで入る
     * @param {number|null} distance 距離
     * @returns 変換後の距離情報(距離の単位とスペース表記あり)
     */
    getDistanceUnit(distance) {
      // 距離がなければ非表示
      if (distance == null) return

      // 1000m未満
      if (!this.isKilo(distance)) return `${distance}m・`

      // 「XXXkm」の表現判定は共通処理にて
      // 下2桁で四捨五入を行うために99950を境界値として設定
      const [dPointZero, dPointOne, hundredKilo] = [0, 1, 99950] // eslint-disable-line no-magic-numbers
      if (hundredKilo <= distance) {
        // XXXkmを表現
        return `${this.roundAndConvertToKilo(distance, dPointZero)}km・`
      } else {
        // 1.0~99.9kmを表現
        return `${Number(
          this.roundAndConvertToKilo(distance, dPointOne)
        ).toFixed(dPointOne)}km・`
      }
    },
  },
}
export default SuggestList
</script>
<style scoped></style>
