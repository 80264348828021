import auth from '@/auth/createAuth/index.js'

const decodeAccessToken = (token) => {
  // eslint-disable-next-line no-magic-numbers
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(decodeURIComponent(escape(window.atob(base64))))
}

// Time out 30回でタイムアウト
// 100ms おきに value の中身を確認
const TRY_COUNT = 30
const TRY_INTERVAL_MS = 100
async function waitForLoading(tryCount = TRY_COUNT) {
  return new Promise((resolve, reject) => {
    if (!auth.isLoading.value) {
      resolve()
    } else {
      // eslint-disable-next-line no-magic-numbers
      if (tryCount > 0) {
        tryCount--
        setTimeout(() => {
          waitForLoading(tryCount)
            .then(() => {
              resolve()
            })
            .catch((e) => {
              reject(e)
            })
        }, TRY_INTERVAL_MS)
      } else {
        reject(new Error('Time out'))
      }
    }
  })
}

export {decodeAccessToken, waitForLoading}
