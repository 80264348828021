<template>
  <div
    name="BaseRadioButton"
    class="flex items-center my-5 px-4 py-3 border rounded-[10px]"
    :class="borderColor()"
    @click="$emit('update:modelValue', itemKey)"
  >
    <!-- デフォルトラジオボタン非表示のためラベルで括る -->
    <label class="radio-button">
      <input type="radio" :value="itemKey" :checked="itemKey === modelValue" />
      <span class="flex items-center radio-text"></span>
    </label>
    <!-- ラベル表示 -->
    <span class="ml-4 text-W4 text-[14px] text-left" :class="textColor()">
      {{ label }}
    </span>
  </div>
</template>
<script>
const BaseRadioButton = {
  name: 'BaseRadioButton',
  props: {
    // ボタンの識別子
    itemKey: {
      type: String,
      default: '',
    },
    // ボタンの右側に表示するラベル
    label: {
      type: String,
      default: '',
    },
    // v-modelに紐づく値
    modelValue: {
      type: String,
      default: '',
    },
  },
  methods: {
    /**
     * 枠線の色を返す
     */
    borderColor() {
      return this.itemKey === this.modelValue ? 'border-blue' : 'border-gray400'
    },
    /**
     * ラベルの色を返す
     */
    textColor() {
      return this.itemKey === this.modelValue ? 'text-gray600' : 'text-gray500'
    },
  },
}
export default BaseRadioButton
</script>
<style scoped>
.radio-button input {
  display: none; /* デフォルトラジオボタン非表示 */
}
.radio-button input + .radio-text::before {
  background-image: url(@/assets/RadioOff.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  position: relative;
  width: 16px;
  height: 16px;
}
.radio-button input:checked + .radio-text::before {
  background-image: url(@/assets/RadioOn.svg);
}
</style>
