<template>
  <div class="h-full overflow-y-auto">
    <!-- ヘッダー -->
    <Header
      headerTitle="お支払い方法"
      :isShowPrevious="true"
      @clickPrevious="goToBackPage()"
    />
    <!-- 支払い方法リスト -->
    <div class="bg-white contents-height">
      <PaymentMethodList
        :cardList="cardList"
        :isShowNextIcon="true"
        @click-payment-method-next="saveSelectedCreditCard($event)"
        @click-register-payment-method="onClickRegisterPaymentMethod()"
      />
    </div>
  </div>
</template>

<script>
import Header from '@/components/organisms/Header.vue'
import PaymentMethodList from '@/components/molecules/PaymentMethodList.vue'

const PaymentMethodSelect = {
  name: 'PaymentMethodSelect',
  components: {
    Header,
    PaymentMethodList,
  },
  data() {
    return {
      // 表示形式に整形されたカード情報の配列
      cardList: this.$store.getters['CreditCardsStore/displayCardList'],
    }
  },
  methods: {
    /**
     * 選択したカードを一時保持領域に格納し、前の画面に遷移する
     */
    saveSelectedCreditCard(selectedCardSeq) {
      // 選択したカードを一時的なクレカ選択保持領域に格納
      this.$store.commit(
        'CreditCardsStore/updateTemporarySelectedCardSeq',
        selectedCardSeq
      )
      // 前の画面に戻る
      this.goToBackPage()
    },
    /**
     * 前のページに遷移する
     */
    goToBackPage() {
      // 遷移元を取得
      const displayName =
        this.$store.state.CreditCardsStore.screenTransitionSource.displayName
      // 遷移元をクリア
      this.$store.commit('CreditCardsStore/updateScreenTransitionSource', {})

      // 遷移元へ戻る
      this.$router.push({name: displayName})
    },
    /**
     * お支払い情報登録画面に遷移する
     */
    onClickRegisterPaymentMethod() {
      this.$router.push({name: this.$config.DISPLAY_REGISTER_CREDIT_CARD})
    },
  },
}
export default PaymentMethodSelect
</script>

<style scoped>
.contents-height {
  height: calc(100% - 48px);
}
</style>
