<template>
  <div v-show="0 < co2List.length" class="px-5 pt-6">
    <div class="text-W6 text-[13px] text-left mb-4">CO2の排出量</div>

    <div class="flex">
      <div class="flex-1 mr-4" ref="mobilityTitle">
        <div
          class="flex mb-2 last:mb-0 w-max"
          v-for="opt in co2List"
          :key="opt.rank"
        >
          <!-- 順位 -->
          <div
            class="w-5 h-5 mr-2 text-W6 text-[12px] leading-[20px] rounded-[10px]"
            :class="getRankColor(opt.rank)"
          >
            {{ opt.rank }}
          </div>
          <!-- モビリティアイコン -->
          <img
            id="icon"
            class="w-4 h-4 my-0.5"
            :src="require(`@/assets/route/${opt.icon[0]}`)"
          />
          <!-- モビリティアイコン（自転車） -->
          <img
            v-if="1 < opt.icon.length"
            class="w-4 h-4 ml-1 my-0.5"
            :src="require(`@/assets/route/${opt.icon[1]}`)"
          />
          <!-- モビリティ種別名 -->
          <div
            class="text-W4 text-[13px] leading-[13px] my-[3.5px] text-left ml-2"
          >
            {{ opt.name }}
          </div>
        </div>
      </div>
      <div class="flex-1 border-l border-gray400">
        <div class="flex mb-2 last:mb-0" v-for="opt in co2List" :key="opt.rank">
          <!-- 棒グラフ（横） -->
          <div
            class="h-1 my-2 bg-blue500 rounded-[4px] dynamic-width"
            :style="getGraphWidth(opt.value)"
          ></div>
          <!-- CO2排出量 -->
          <div
            class="text-W4 text-[13px] leading-[13px] pl-2 pt-[3px] mb-1"
            ref="co2Val"
          >
            {{ opt.value }}
          </div>
          <div
            class="text-W4 text-[12px] leading-[12px] pl-0.5 py-1"
            ref="co2Kg"
          >
            kg
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Co2EmissionsGraph = {
  name: 'Co2EmissionsGraph',
  components: {},
  data() {
    return {
      noCoveredWidth: 0, // グラフ外の幅
    }
  },
  props: {
    // モビリティ別CO2排出量のリスト
    co2List: {
      type: Array,
      require: true,
    },
  },
  mounted() {
    this.setNoCoveredWidth()
  },
  updated() {
    this.setNoCoveredWidth()
  },
  computed: {
    /**
     * 最大の排出量を取得する
     */
    getMaxEmissions() {
      return Math.max.apply(
        null,
        this.co2List.map((o) => {
          if (typeof o.value == 'string') {
            // 桁数のカンマが含まれている（値が1,000以上の場合）と数値として扱えないので取り除く
            return o.value.replace(/,/g, '')
          }
          return o.value
        })
      )
    },
  },
  methods: {
    /**
     * 棒グラフ外の横幅を算出し設定
     */
    setNoCoveredWidth() {
      // モビリティ名及び順位の幅取得
      const mobilityTitleWidth = this.calcMaxWidthByRef(
        this.$refs.mobilityTitle
      )

      // 排出量の数値とKgの幅取得
      const co2ValueWidth = this.calcMaxWidthByRef(this.$refs.co2Val)
      const co2KgWidth = this.calcMaxWidthByRef(this.$refs.co2Kg)

      const bothEndSpace = 40 // 両端の余白
      const titleRightSpace = 16 // タイトル右の余白

      // 左側のタイトル幅 + CO2排出量の数値の幅 + Kgの幅 + 余白(両端の余白 + タイトル右の余白)
      this.noCoveredWidth =
        mobilityTitleWidth +
        co2ValueWidth +
        co2KgWidth +
        (bothEndSpace + titleRightSpace)
    },
    /**
     * 要素の幅を返却する
     * (複数の要素がある場合は、最大の幅を返却)
     * ※存在しない場合は初期値0を返却
     * @param {*} targetRef 対象のRef要素
     * @return 横幅の値
     */
    calcMaxWidthByRef(targetRef) {
      if (targetRef == null) {
        // 存在しない場合は初期値(0)を返却
        return 0 // eslint-disable-line no-magic-numbers
      }

      if (Array.isArray(targetRef)) {
        // 複数の場合は、一番大きい幅を返却
        const co2ValueList = targetRef.map((ref) => {
          return ref.clientWidth
        })
        return Math.max(...co2ValueList)
      } else {
        // 単一の場合は幅をそのまま返却
        return targetRef.clientWidth
      }
    },
    /**
     * 棒グラフの長さ（幅）を設定する
     * 算出式：(モビリティごとのCO2排出量 / 最大のCO2排出量) * (デバイスの画面幅 - グラフ外の固定幅)
     * @param {String} value CO2排出量
     * @return {String} 各モビリティの排出量を棒グラフの長さに変換
     */
    getGraphWidth(value) {
      if (typeof value == 'string') {
        // 桁数のカンマが含まれている（値が1,000以上の場合）と数値として扱えないので取り除く
        value = value.replace(/,/g, '')
      }
      // 割合をパーセントにするための係数
      const coefficient = 100
      return {'--width': `${(value / this.getMaxEmissions) * coefficient}%`}
    },
    /**
     * 順位ごとでCSSを適用する
     * 1位は'bg-warning300'、それ以外は'bg-gray300'
     * @param {Number} rank 親から渡ってきた順位データ
     * @return {String} 適用するCSSのクラス
     */
    getRankColor(rank) {
      return rank === 1 ? 'bg-warning300' : 'bg-gray300' // eslint-disable-line no-magic-numbers
    },
  },
}
export default Co2EmissionsGraph
</script>

<style scoped>
/* 棒グラフの長さ（幅）設定用 */
.dynamic-width {
  --width: 50vw;
  width: var(--width);
}
</style>
