<template>
  <svg viewBox="0 0 16 16">
    <path
      d="M21.022,21.95l-5.844-5.844a4.9,4.9,0,0,1-1.556.9,5.5,5.5,0,0,1-1.889.322,5.533,5.533,0,0,1-4.067-1.667A5.481,5.481,0,0,1,6,11.639,5.481,5.481,0,0,1,7.667,7.617,5.507,5.507,0,0,1,11.711,5.95a5.444,5.444,0,0,1,4.011,1.667,5.687,5.687,0,0,1,1.344,5.867,5.641,5.641,0,0,1-.933,1.667L22,20.972Zm-9.311-5.956a4.159,4.159,0,0,0,3.067-1.278,4.214,4.214,0,0,0,1.267-3.078,4.214,4.214,0,0,0-1.267-3.078,4.159,4.159,0,0,0-3.067-1.278,4.363,4.363,0,0,0-4.378,4.356,4.363,4.363,0,0,0,4.378,4.356Z"
      transform="translate(-6 -5.95)"
      fill="#22262c"
      class="icon-search-path"
    />
  </svg>
</template>
