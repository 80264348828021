<template>
  <div class="text-form">
    <div v-if="isBackButton" class="closeicon">
      <img
        src="@/assets/Icon_Left_gray.svg"
        class="h-4"
        @click="$emit('click-back-button')"
      />
    </div>
    <input
      id="textFormInput"
      ref="textFormInput"
      class="text-w4 inputtext bg-white border-[0.5px] border-solid border-gray400 scroll-my-7 relative"
      :class="inputClass"
      :type="type"
      :placeholder="placeholder"
      :value="editingText"
      v-model.trim="editingText"
      :maxlength="maxlength"
      :disabled="isDisabled"
      autocomplete="off"
      @input="$emit('input-text', editingText)"
      @focus="onFocus"
      @blur="onBlur"
      @keypress.enter="$emit('enter', editingText)"
    />
  </div>
</template>

<script>
const TextForm = {
  name: 'TextForm',
  props: {
    maxlength: {
      type: String,
      default: '-1',
    },
    text: String,
    placeholder: {
      type: String,
      default: '入力',
    },
    isBackButton: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'search',
    },
    inputClass: {
      type: String,
      default: '',
    },
    // inputタグの操作可否状態
    isDisabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  emits: ['click-back-button', 'input-text', 'enter', 'focus', 'blur'],
  data() {
    return {
      editingText: this.text, // 入力中のテキスト
      hasFocus: false, // フォーカスを持っているかフラグ
    }
  },
  watch: {
    text(newText) {
      this.editingText = newText
      this.$emit('input-text', this.editingText)
    },
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    /**
     * Inputタグにフォーカスを当てる
     */
    focusInputForm() {
      this.$refs.textFormInput.focus()
    },
    /**
     * Inputタグのフォーカスを外す
     * @param {Number} waitTime 処理待ち時間(ミリ秒)
     */
    async blurInputForm(waitTime) {
      this.$refs.textFormInput.blur()

      const zero = 0
      if (Number.isInteger(waitTime) && zero < waitTime) {
        // 整数の指定があった場合、処理を待機させる
        await this.wait(waitTime)
      }
    },
    /**
     * 処理待ち
     * @param {Number} waitTime 処理待ち時間(ミリ秒)
     */
    wait(waitTime) {
      return new Promise((resolve) => {
        setTimeout(resolve, waitTime)
      })
    },
    /**
     * リセット
     */
    reset() {
      this.editingText = ''
    },
    /**
     * フォーカスが当たった時に発火されるイベント
     */
    onFocus() {
      // テキストフィールドがスクロール外にある場合、表示させる
      this.$refs.textFormInput.scrollIntoView({block: 'nearest'})
      this.hasFocus = true
      this.$emit('focus')
    },
    /**
     * フォーカスが外れた時に発火されるイベント
     */
    onBlur() {
      this.hasFocus = false
      this.$emit('blur')
    },
    /**
     * Windowリサイズ時に発火されるイベント
     */
    onWindowResize() {
      // ソフトウェアキーボードがアニメーションにより徐々に表示される場合、キーボードにより入力欄が隠れてしまう場合があるため対策
      if (this.hasFocus) {
        this.$refs.textFormInput.scrollIntoView({block: 'nearest'})
      }
    },
  },
}
export default TextForm
</script>
<style scoped>
.text-form {
  display: flex;
  flex-direction: row;
}
.inputtext {
  height: 38.33px;
  border-radius: 20px;
  outline: none;
  line-height: 20px;
  font-size: 15px;
  padding-left: 16px;
  padding-right: 12px;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  color: #1a1c21;
  font-weight: 400;
  transition: padding-right 0.01s;
}
.inputText::placeholder {
  color: #979ca1;
  font-weight: 300;
}
.inputtext:focus {
  padding-right: 40px;
}

/* iOS用の共通設定 */
input {
  -webkit-opacity: 1; /* opacityを固定（disable時の切り替わり防止） */
}

/*  input type="search" 共通設定  */
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none; /* デフォルトのサーチキャンセルボタンを非表示 */
}
input[type='search']:focus::-webkit-search-cancel-button {
  background-image: url(../assets/Icon_Delete_Btn.svg);
  background-repeat: no-repeat;
  background-size: 16px; /* キャンセルボタンのサイズ */
  background-position: center;
  height: 38.33px;
  width: 36px;
  padding-right: 0px;
  position: absolute;
  right: 0;
}
.closeicon {
  width: 32px;
  margin: auto;
}
</style>
