<template>
  <div id="SetupUserInfoFinished" class="h-full flex flex-col">
    <!-- ヘッダー -->
    <Header
      headerTitle="登録完了"
      :isShowPrevious="false"
      :isShowClose="false"
      class="h-[49px]"
    />
    <div class="grow">
      <div class="w-[54%] mt-20 mx-auto bg-white">
        <img ref="imageDone" src="@/assets/setup/done.gif" class="w-full" />
      </div>
    </div>
    <div class="text-W7 text-[20px]">さあ、はじめましょう</div>
    <div v-if="isShowTrip" class="mt-4 text-W4 text-[15px]">
      Have a good trip!
    </div>
    <div class="mt-10 mb-6 mx-5">
      <TheButton text="ホームへ" class="h-[47px]" @click-button="toLoad()" />
    </div>
  </div>
</template>

<script>
/**
 * ユーザー情報登録完了画面
 */
import Header from '@/components/organisms/Header.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Util from '@/mixins/util'
import Setup from '@/views/setup/setup.js'
import LoginUtil from '@/mixins/loginUtil.js'

const SetupUserInfoFinished = {
  name: 'SetupUserInfoFinished',
  components: {
    Header,
    TheButton,
  },
  mixins: [Util, Setup, LoginUtil],
  data() {
    return {
      isShowTrip: false,
    }
  },
  mounted() {
    requestAnimationFrame(() => {
      const delayMilliseconds = 400
      setTimeout(() => {
        this.isShowTrip = true
      }, delayMilliseconds)

      // gifアニメーションを再度動かすために詰め直す
      this.$refs.imageDone.src = this.$refs.imageDone.getAttribute('src')
    })
  },
  methods: {
    /**
     * 画面遷移する
     */
    toLoad() {
      // アプリ内情報の設定状況によって処理を分ける
      if (this.isSetAppInfo()) {
        // 画面遷移
        this.initData()
      } else {
        // 設定されていない場合は「ようこそ画面」へ遷移
        this.$router.push({name: this.$config.DISPLAY_SETUP_WELCOME})
      }
    },
  },
}
export default SetupUserInfoFinished
</script>
