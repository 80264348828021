<template>
  <div class="h-full">
    <!-- ユーザー作成エラーモーダル -->
    <Modal
      v-if="isShowCreateUserErrorModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="center px-5 pt-9 pb-6">
        <div class="text-W5 text-[17px] mb-3">アカウント登録完了</div>
        <div class="text-W3 text-[15px] pb-5">
          利用開始後、ログインしてください。
        </div>
        <div
          class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
          @click="errorProcess()"
        >
          OK
        </div>
      </div>
    </Modal>
    <!-- ユーザー取得エラーモーダル -->
    <Modal
      v-if="isShowGetUserErrorModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="center px-5 pt-9 pb-6">
        <div class="text-W5 text-[17px] mb-3">エラー</div>
        <div class="text-W3 text-[15px] pb-5">
          ユーザー情報の取得に失敗しました。
          <br />
          もう一度ログインしてください。
        </div>
        <div
          class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
          @click="errorProcess()"
        >
          OK
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import Setup from '@/views/setup/setup.js'
import LoginUtil from '@/mixins/loginUtil.js'
import Util from '@/mixins/util.js'
import {getAccessToken, logout, login} from '@/auth/AuthorizationInteractor.js'
import menuUtil from '@/mixins/menuUtil'
import NativeUtil from '@/mixins/nativeUtil.js'

const AUTH0_STATUS = {
  LOGIN: 0,
  EMAIL_NO_VERIFIED: 1,
  NO_LOGIN: 2,
}

const LoadApp = {
  name: 'LoadApp',
  components: {
    Modal,
  },
  mixins: [Setup, LoginUtil, NativeUtil, menuUtil, Util],
  data() {
    return {
      isShowCreateUserErrorModal: false,
      isShowGetUserErrorModal: false,
    }
  },
  async created() {
    this.$store.commit('startLoading')
    // IndexedDBのセットアップ
    await this.setupIndexedDb()
    // デバイストークン取得完了後の処理
    const getDeviceTokenCompletion = () => {
      // 状態に応じた起動処理を行う
      this.distributeDestination()
    }
    // Nativeの情報取得
    this.setupNativeInfo()
    // デバイストークン取得処理
    this.getDeviceToken(getDeviceTokenCompletion)
  },
  beforeUnmount() {
    this.$store.commit('endLoading')
  },
  watch: {
    isShowErrorModalForLoadPage(value) {
      // エラーポップアップが表示されたらローディングを止める
      if (value) this.$store.commit('endLoading')
    },
  },
  computed: {
    /**
     * ロード画面のエラーモーダルが表示されているかどうか
     */
    isShowErrorModalForLoadPage() {
      return this.isShowCreateUserErrorModal || this.isShowGetUserErrorModal
    },
    /**
     * ログイン状態であるか
     */
    isLogin() {
      return this.$store.state.user.auth.isLogin
    },
    /**
     * ログイン設定
     */
    loginMethod() {
      return this.$store.state.user.loginMethod
    },
  },
  methods: {
    /**
     * IndexedDbのセットアップを行う
     */
    async setupIndexedDb() {
      // IndexedDB作成処理
      await this.$store.dispatch('IndexedDbStore/createDatabase')
    },
    /**
     * 起動時の振り分け処理
     * [ログイン時] 必要データを読み込み、遷移する
     * [メール未承認時] 「メール送信完了画面」に遷移する
     * [未ログイン時] 「ようこそ画面」に遷移する
     */
    async distributeDestination() {
      const status = await this.getAuth0Status()
      switch (status) {
        case AUTH0_STATUS.LOGIN:
          // ログイン時
          this.loginProcess()
          break
        case AUTH0_STATUS.EMAIL_NO_VERIFIED:
          // メール未承認の場合、「メール送信完了画面」に遷移する
          this.goToSetupEmailVerified()
          break
        case AUTH0_STATUS.NO_LOGIN:
          // 未ログイン時
          await this.noLoginProcess()
          break
        default:
        // 何もしない
      }
    },
    /**
     * 未ログイン時の処理
     */
    async noLoginProcess() {
      // 強制再ログイン直後は、ログイン画面に遷移させる
      const isAuthInStorage = this.getLocalStorage(this.$config.FORCE_RE_LOGIN)
      if (isAuthInStorage) {
        localStorage.removeItem(this.$config.FORCE_RE_LOGIN)
        await login()
        return
      }
      if (this.isSetAppInfo()) {
        // ２回目以降起動の場合
        this.initData()
      } else {
        // 初回起動の場合、「ようこそ画面」に遷移する
        this.goToWelcome()
      }
    },
    /**
     * createUser、getUserエラー時の処理
     */
    errorProcess() {
      // Welcome画面にて設定モーダル表示判定用のフラグを設定
      this.setLocalStorage(this.$config.USER_ACCOUNT_DEFECT, true)
      logout()
    },
    /**
     * ログイン時の処理
     */
    async loginProcess() {
      const isRedirectAuthLoginPage = this.$route.query?.loggedIn === 'true'
      if (isRedirectAuthLoginPage) {
        // Auth0でログインした場合、Namoログイン(セッション更新))を行ってからログイン時処理を行う
        try {
          await this.$store.dispatch('login')
        } catch {
          // エラーモーダルを表示し、処理終了
          this.isShowGetUserErrorModal = true
          return
        }
      }

      let isFirstUserSetup = true
      // 初回ログインかどうか判定
      try {
        isFirstUserSetup = await this.isFirstUserSetup()
      } catch (e) {
        // セッション判定NGの場合はエラーをスローし、共通セッション切れエラーモーダルを表示させる
        if (e?.message?.includes('invalidAccessToken')) {
          throw e
        }
        this.isShowGetUserErrorModal = true
        return
      }

      // 初回ログインの場合はユーザー作成
      try {
        if (isFirstUserSetup) {
          // ユーザー登録
          await this.createUser()
        }
      } catch {
        this.isShowCreateUserErrorModal = true
        return
      }

      // モビリティ手配情報取得
      try {
        await this.getMobilityInfo()
      } catch {
        this.isShowGetUserErrorModal = true
        return
      }

      // ログイン設定取得
      const loginMethod = this.getLoginMethod()
      const isNotSetLoginMethod = this.isNull(loginMethod)

      // ログイン方法の設定状況によって処理を分ける
      if (isNotSetLoginMethod) {
        // 設定されていなければ「ログイン方法設定画面」へ遷移
        this.goToSetupLoginMethod()
        return
      } else {
        // 設定されていればStoreに保存
        this.$store.commit('updateLoginMethod', loginMethod)
      }

      // ユーザー情報登録判定
      if (this.isNotRegisterUserInfo()) {
        this.$router.push({name: this.$config.DISPLAY_SETUP_USER_INFO})
        return
      }

      // アプリ内情報の設定状況によって処理を分ける
      if (this.isSetAppInfo()) {
        // 設定されている場合はそのまま遷移
        this.loginByEachSetting()
      } else {
        // 設定されていない場合は「ようこそ画面」へ遷移
        this.goToWelcome()
      }
    },
    /**
     * 初回セットアップ実施済みかどうか
     */
    async isFirstUserSetup() {
      // 一度でもユーザーセットアップが完了していればユーザー情報取得の結果を待たない
      if (localStorage.getItem(this.$config.USER_SETUP_COMPLETED)) {
        this.getUser()
        return false
      }
      return this.getUser()
        .then(() => {
          return false
        })
        .catch((e) => {
          if (e.body?.param === this.$config.USER_ACCOUNT_IS_NOT_REGISTERED) {
            return true
          }
          throw e
        })
    },
    /**
     * ユーザー情報設定をおこなっているかどうか
     */
    isNotRegisterUserInfo() {
      if (localStorage.getItem(this.$config.USER_SETUP_COMPLETED)) {
        return false
      }
      const user = this.$store.state.user.info
      const isNotCompleted =
        user.myoji == '' &&
        user.na == '' &&
        user.birthday == '' &&
        user.address == '' &&
        user.tel == '' &&
        user.email == ''
      if (isNotCompleted) {
        return true
      } else {
        localStorage.setItem(this.$config.USER_SETUP_COMPLETED, true)
        return false
      }
    },
    /**
     * Auth0のログインステータスを取得
     * @return {Number} ステータス(ログイン:0, メール未承認:1, 未ログイン:2)
     */
    async getAuth0Status() {
      try {
        const accessToken = await getAccessToken()
        if (accessToken) {
          // ログイン済み
          this.$store.commit('updateUserAuthInfo', {
            isLogin: true,
          })
          return AUTH0_STATUS.LOGIN
        } else {
          // 未ログイン
          return AUTH0_STATUS.NO_LOGIN
        }
      } catch (e) {
        if (this.$config.AUTH0_NO_EMAIL_VERIFIED_ERROR == e.message) {
          // メール未認証(未ログイン)
          return AUTH0_STATUS.EMAIL_NO_VERIFIED
        } else {
          // 上記以外のエラーの場合は、未ログイン判定
          return AUTH0_STATUS.NO_LOGIN
        }
      }
    },
    /**
     * メール送信完了画面に遷移
     */
    goToSetupEmailVerified() {
      this.$router.push({name: this.$config.DISPLAY_SETUP_EMAIL_VERIFIED})
    },
    /**
     * ようこそ画面に遷移
     */
    goToWelcome() {
      this.$router.push({name: this.$config.DISPLAY_SETUP_WELCOME})
    },
    /**
     * ログイン設定画面に遷移
     */
    goToSetupLoginMethod() {
      this.$router.push({name: this.$config.DISPLAY_SETUP_LOGIN_METHOD})
    },
    /**
     * 各ユーザーのログイン設定に応じて認証
     */
    loginByEachSetting() {
      // 各キーを準備
      const AUTO_AUTH_KEY = this.$config.LOGIN_SETTINGS[0].KEY // eslint-disable-line no-magic-numbers
      const DEVICE_AUTH_KEY = this.$config.LOGIN_SETTINGS[1].KEY // eslint-disable-line no-magic-numbers
      const EMAIL_PW_AUTH_KEY = this.$config.LOGIN_SETTINGS[2].KEY // eslint-disable-line no-magic-numbers

      // ログイン方法ごとに処理分け
      switch (this.loginMethod) {
        // スマホの解除方法の場合は生体認証
        case DEVICE_AUTH_KEY:
          this.doBioAuth()
          break
        // メアド、パスワードの場合はログイン画面に飛ばす
        case EMAIL_PW_AUTH_KEY:
          this.doEmailAndPwAuth()
          break
        // 自動認証
        case AUTO_AUTH_KEY:
          this.doAutoAuth()
          break
        default:
      }
    },
    /**
     * 生体認証を行う
     */
    doBioAuth() {
      // 成功時はそのままロード処理
      const success = () => {
        this.initData()
      }
      // 失敗時はログアウト
      const failed = async () => {
        await logout()
      }

      // 生体認証実行
      this.defineGetBioAuthResultMethod(success, failed)
      this.linkNativeCallback('bioAuth')
    },
    /**
     * メールアドレスとパスワード認証を行う
     */
    async doEmailAndPwAuth() {
      // ログインを介して入ってきたかどうかをqueryParameterで判断する
      const loggedIn = this.$route.query.loggedIn
      if (loggedIn == null) {
        // ログインを介していない場合は、強制的にログアウトさせる
        this.setLocalStorage(this.$config.FORCE_RE_LOGIN, true)
        await logout()
      } else {
        // ログインを介してきた場合は、そのまま利用可能
        this.initData()
      }
    },
    /**
     * 自動認証を行う
     */
    async doAutoAuth() {
      // ログイン状態の場合はそのままロード処理
      if (this.isLogin) {
        this.initData()
      } else {
        // ログイン状態でない場合はログアウト
        await logout()
      }
    },
    /**
     * Native情報の初期取得
     */
    async setupNativeInfo() {
      window.addEventListener('resize-keyboard', (event) => {
        this.$store.commit('updateKeyboardHeight', event.detail)
      })
      window.addEventListener('resize-safe-area', (event) => {
        const detail = event.detail
        this.$store.commit('updateSafeAreaSize', detail)
        const style = document.querySelector(':root').style
        if (detail.topSafeAreaHeight) {
          style.setProperty(
            '--top-safe-area-height',
            `${detail.topSafeAreaHeight}px`
          )
        }
        if (detail.bottomSafeAreaHeight) {
          style.setProperty(
            '--bottom-safe-area-height',
            `${detail.bottomSafeAreaHeight}px`
          )
        }
      })
      window.addEventListener('change-native-info', (event) => {
        this.$store.commit('updateNativeInfo', event.detail)
      })
      await this.watchNativeInfo()
    },
  },
}
export default LoadApp
</script>
<style scoped></style>
