/**
 * 乗り換え検索用Storeモジュール
 */
import * as Axios from '@/service/AxiosService'
import dayjs from 'dayjs'
import Util from '@/mixins/util'
import config from '@/const/const.js'
import deepcopy from 'deepcopy'
const constant = config.constant

// 検索オプションの初期値
const DEFAULT_TRANSFER_OPTIONS = {
  isSuperexpressTrain: true, //新幹線
  isUltraexpressTrain: true, //有料特急
  isHighwayBus: true, //高速バス
  isDomesticFlight: true, //飛行機
  isLocalBus: true, //路線/連絡バス
  isFerry: true, //フェリー
}

// 乗り換え検索条件の初期値
const DEFAULT_TRANSFER_CONDITIONS = {
  start: {name: '', address: '', nodeId: '', ruby: '', type: ''}, // 出発位置
  via: [], // 経由地
  goal: {name: '', address: '', nodeId: '', ruby: '', type: ''}, // 到着位置
  timeType: {id: 1, name: '出発'}, // 出発到着情報
  targetTime: Util.methods.getNow(), // 検索時間
  // 検索オプション
  transferOptions: deepcopy(DEFAULT_TRANSFER_OPTIONS),
}

// 駅/バス停名検索用のキャンセルソース
let getStationInfoCancelSource = null

export default {
  namespaced: true,
  state: {
    transferConditions: deepcopy(DEFAULT_TRANSFER_CONDITIONS),
    transferSearchResult: [], // 乗り換え検索結果
    selectedTransferResult: null, // 乗り換え詳細 選択情報
    transferSlideResult: null, // 前後ルート検索結果
  },
  getters: {},
  mutations: {
    /**
     * 乗り換え検索結果更新
     */
    updateTransferSearchResult(state, obj) {
      state.transferSearchResult = obj
    },
    /**
     * 乗り換え検索 選択ルート情報更新
     */
    updateSelectedTransferResult(state, obj) {
      state.selectedTransferResult = obj
    },
    /**
     * 前後ルート情報更新
     */
    updateTransferSlideResult(state, obj) {
      state.transferSlideResult = obj
    },
    /**
     * 検索情報を更新する
     * @param {*} state
     * @param {*} obj
     */
    updateTransferConditions(state, obj) {
      if (obj.key) {
        if (obj.key == 'transferConditions') {
          // 検索オブションまるごと更新の場合
          state.transferConditions = obj.value
        } else {
          state.transferConditions[obj.key] = obj.value
        }
      }
    },
    /**
     * 検索オプションを更新する
     * @param {*} state
     * @param {*} obj
     */
    updateTransferOptions(state, obj) {
      // 検索オブションまるごと更新の場合
      state.transferConditions.transferOptions = obj.value
    },
    /**
     * 経由地枠の追加
     * @param {Object} viaData 空の経由地情報
     */
    addVia(state, viaData) {
      state.transferConditions.via.push(viaData)
    },
    /**
     * 経由地枠の削除
     * @param {Object} target 削除対象の経由地情報のID
     */
    deleteVia(state, target) {
      let viaSetData = state.transferConditions.via
      state.transferConditions.via = viaSetData.filter((viaData) => {
        return viaData.id !== target
      })
    },
    /**
     * 出発地と到着地を交換する
     * @param {*} state
     */
    exchangeSpots(state) {
      let buf = state.transferConditions.start
      state.transferConditions.start = state.transferConditions.goal
      state.transferConditions.goal = buf
    },
    /**
     * Storeの復元
     * @param {*} state Storeオブジェクト
     * @param {*} value IndexedDBに格納されていたデータ
     */
    restoreStore(state, value) {
      state.transferConditions = value.transferConditions
      state.transferSearchResult = value.transferSearchResult
      state.selectedTransferResult = value.selectedTransferResult
      state.transferSlideResult = value.transferSlideResult
    },
    /**
     * 乗換案内の検索条件/結果を初期化
     * @param {*} state Storeオブジェクト
     */
    resetConditionAndResult(state) {
      // 各情報を初期化する
      let newConditions = structuredClone(DEFAULT_TRANSFER_CONDITIONS)
      // 現在の時間で再設定
      newConditions.targetTime = Util.methods.getNow()
      // 初期化する場合
      state.transferConditions = newConditions
      state.transferSearchResult = []
      state.selectedTransferResult = null
      state.transferSlideResult = null
    },
  },
  actions: {
    /**
     * 駅/バス停名検索API
     * @param {*} state このstoreのstate
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     * @param {Function} cancel キャンセル時コールバック関数
     * @param {String} word 検索をするワード
     */
    /* eslint-disable no-unused-vars */
    getStationInfo({state}, {success, failed, error, cancel, word}) {
      // 重複している処理をキャンセルし、新しいCancelSourceを発行
      getStationInfoCancelSource = Util.methods.resetCancelSource(
        getStationInfoCancelSource
      )
      // 駅名に紐づく駅情報を取得
      return Axios.get(
        'transportNodeByWord',
        {word: word},
        {source: getStationInfoCancelSource}
      )
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (e.message === 'Cancel') {
              if (cancel) {
                cancel()
              }
              return
            }

            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /* eslint-enable no-unused-vars */
    /**
     * トータルルート検索API
     * @param {*} state このstoreのstate
     * @param {Function} success 成功コールバック
     * @param {Function} failed 失敗コールバック
     * @param {Function} error エラーコールバック
     */
    getRouteTotal({state}, {success, failed, error}) {
      // パラメータ生成.
      const condition = state.transferConditions
      let param = {
        startName: condition.start.name,
        startAddress: condition.start.address,
        startNodeId: condition.start.nodeId,
        goalName: condition.goal.name,
        goalAddress: condition.goal.address,
        goalNodeId: condition.goal.nodeId,
        date: condition.targetTime,
        timeType: condition.timeType.id,
        isSuperexpressTrain: condition.transferOptions.isSuperexpressTrain,
        isUltraexpressTrain: condition.transferOptions.isUltraexpressTrain,
        isHighwayBus: condition.transferOptions.isHighwayBus,
        isDomesticFlight: condition.transferOptions.isDomesticFlight,
        isLocalBus: condition.transferOptions.isLocalBus,
        isFerry: condition.transferOptions.isFerry,
      }

      const FIRST = 1
      for (let i = 0; i < condition.via.length; i++) {
        param['via' + (i + FIRST) + 'Name'] = condition.via[i].name || undefined
        param['via' + (i + FIRST) + 'Address'] =
          condition.via[i].address || undefined
        param['via' + (i + FIRST) + 'NodeId'] =
          condition.via[i].nodeId || undefined
      }

      // 地点情報取得
      return Axios.get('routeTotal', param)
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * トータルルート前後検索API
     * @param {*} state このstoreのstate
     * @param {Function} success 成功コールバック
     * @param {Function} failed 失敗コールバック
     * @param {Function} error エラーコールバック
     */
    getRouteSlide({state}, {success, failed, error}) {
      // パラメータ生成
      const route = state.selectedTransferResult
      const sections = route.sections
      const startPoint = sections[0] // eslint-disable-line no-magic-numbers
      const sectionLength = sections.length
      const goalPoint = sections[sectionLength - 1] // eslint-disable-line no-magic-numbers

      // transportが存在する最初のmoveの番号を検索する
      const targetMoveIndex = sections.findIndex((section) => {
        return section.transport != null
      })

      // transportを持つものが存在しないまたは0番目の要素にtransportをもつものが存在する場合エラーを返す
      // eslint-disable-next-line no-magic-numbers
      if (targetMoveIndex < 1) {
        if (error) {
          error()
          return
        }
        throw new Error()
      }

      // transportが存在する最初のmoveを取得
      const targetMove = sections[targetMoveIndex]

      // transportが存在する最初のmoveの一つ前の要素を取得
      // eslint-disable-next-line no-magic-numbers
      const previousTargetMove = sections[targetMoveIndex - 1]

      const req = {
        start: startPoint.nodeId,
        goal: goalPoint.nodeId,
        startTime: dayjs(targetMove.fromTime) // eslint-disable-line no-magic-numbers
          .format('YYYY-MM-DDTHH:mm:ss'),
        trainId: targetMove.transport.trainId,
        startNode: previousTargetMove.nodeId,
        sections: JSON.stringify(route.basisRoute),
      }

      // トータルルート前後検索
      return Axios.get('routeSlide', req)
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
  },
}
