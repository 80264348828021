<template>
  <!-- 画像トリミングポップアップ -->
  <Modal
    id="trimming-icon-modal"
    v-if="isShowTrimmingIconModal"
    class="modal"
    @disPlayModal="isNoAddressPopupFlg = false"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="text-W5 text-[17px] leading-[17px] pt-9">あなたのアイコン</div>
    <div class="mx-5 pt-4 pb-6">
      <!-- トリミング画像（縦長画像の場合、ボタンが押せなくなるケースがあるため、幅・高さを設定） -->
      <img id="cropping-image" class="w-64 h-64" />
    </div>
    <!-- ボタン -->
    <div class="flex justify-center px-5 pb-6 items-center">
      <div
        class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
        @click="clickCancelButton()"
      >
        {{ SecondaryButtonText }}
      </div>
      <div
        class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
        @click="save()"
      >
        {{ primaryButtonText }}
      </div>
    </div>
  </Modal>
  <!-- ファイル選択ダイアログ（非表示） -->
  <input
    type="file"
    id="icon-file"
    value=""
    class="hidden"
    accept="image/*"
    @change="selectedIconFile"
  />
  <UploadErrorModal v-model="isShowUploadErrorModal" />
</template>

<script>
import {loadImageFile} from '@/utils/imageUtil'
import Cropper from 'cropperjs'
import Modal from '@/components/Modal.vue'
import UploadErrorModal from '@/components/UploadErrorModal.vue'
import log from 'loglevel'
const TrimmingIconModal = {
  name: 'TrimmingIconModal',
  props: {
    // プライマリーボタンの名称
    primaryButtonText: {
      type: String,
      required: true,
    },
    // セカンダリーボタンの名称
    SecondaryButtonText: {
      type: String,
      required: true,
    },
  },
  emits: ['selected-file', 'click-secondary-button', 'click-primary-button'],
  components: {Modal, UploadErrorModal},
  data() {
    return {
      isShowTrimmingIconModal: false,
      isShowUploadErrorModal: false,
    }
  },
  mounted() {},
  methods: {
    /**
     * トリミング開始処理
     */
    startTrimming() {
      document.getElementById('icon-file').click()
    },
    /**
     * 画像アイコンファイル選択処理
     */
    async selectedIconFile(value) {
      if (this.$config.ZERO < value.target.files.length) {
        const firstIndex = 0
        // 選択したファイルを画像トリミングポップアップのプレビューに設定
        await this.showTrimmingTargetImage(value.target.files[firstIndex])
        // 同じファイルを選択した場合に発火しなくなるため初期化
        value.target.value = ''

        // 画像選択が完了したことを親に通知
        this.$emit('selected-file')
      }
    },
    /**
     * トリミング対象の画像を表示する
     */
    async showTrimmingTargetImage(selectedFile) {
      try {
        const imageSrc = await loadImageFile(selectedFile)
        // 画像トリミングポップアップの表示
        this.isShowTrimmingIconModal = true

        const vm = this
        vm.$nextTick(async () => {
          const croppingImage = document.getElementById('cropping-image')
          croppingImage.src = imageSrc
          vm.cropper = new Cropper(croppingImage, {
            aspectRatio: 1, // eslint-disable-line no-magic-numbers
            viewMode: 3, // eslint-disable-line no-magic-numbers
            cropBoxResizable: false,
            cropBoxMovable: false,
            dragMode: 'move',
            guides: false,
            center: false,
            highlight: false,
            rotatable: false,
            checkOrientation: false,
            toggleDragModeOnDblclick: false,
            restore: false,
            autoCrop: 1, // eslint-disable-line no-magic-numbers
            autoCropArea: 1,
            // imgのHeightとWidthに合わせる（正方形にするといい感じに表示される）。
            minCropBoxHeight: 100, // eslint-disable-line no-magic-numbers
            minCropBoxWidth: 100, // eslint-disable-line no-magic-numbers
          })
        })
      } catch (e) {
        // 画像ファイル読み込み失敗時のエラーモーダルを表示する
        log.debug('画像ファイル読み込み失敗', e)
        this.isShowTrimmingIconModal = false
        this.isShowUploadErrorModal = true
      }
    },
    /**
     * トリミングした画像を保存する
     */
    save() {
      const vm = this
      vm.isTrimmingIconModal = false
      // eslint-disable-next-line no-magic-numbers
      const iconSize = 120 * (window.devicePixelRatio || 1)
      // トリミング後の画像を引数のサイズ(px)で取得する
      let croppedCanvas = vm.cropper.getCroppedCanvas({
        width: iconSize,
        height: iconSize,
      })
      // トリミング後の画像を円形に切り出す
      vm.getRoundedCanvas(croppedCanvas).toBlob((blob) => {
        if (blob == null) {
          return
        }

        vm.cropper.destroy()

        var reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = function () {
          var base64data = reader.result
          vm.$emit('click-primary-button', base64data)
        }
      })
    },
    /**
     * 画像トリミングポップアップのキャンセルボタン押下処理
     */
    clickCancelButton() {
      // トリミング情報をリセット
      document.getElementById('icon-file').value = ''
      this.userIcon = ''
      this.cropper.destroy()
      this.cropper = null
      this.$emit('click-secondary-button')
    },
    /**
     * 円形画像トリミング処理
     * @param {*} sourceCanvas トリミング対象の画像
     */
    getRoundedCanvas(sourceCanvas) {
      // トリミング用のキャンバスを用意する
      let canvas = document.createElement('canvas')
      if (canvas !== undefined) {
        let context = canvas.getContext('2d') // 2D画像描画用のキャンバスオブジェクトを取得
        let width = sourceCanvas.width
        let height = sourceCanvas.height

        canvas.width = width
        canvas.height = height

        if (context !== null) {
          // 対象画像を滑らかに拡大する
          context.imageSmoothingEnabled = true
          // 対象画像を描画する
          context.drawImage(sourceCanvas, 0, 0, width, height) // eslint-disable-line no-magic-numbers
          // 合成方法の指定（円形の画像に切り出す設定にする）
          context.globalCompositeOperation = 'destination-in'

          context.beginPath()
          // 合成用の円弧を作成する(円の中心座標X、円の中心座標Y、半径、開始ラジアン角、終了ラジアン角、反時計回り)
          context.arc(
            width / 2, // eslint-disable-line no-magic-numbers
            height / 2, // eslint-disable-line no-magic-numbers
            Math.min(width, height) / 2, // eslint-disable-line no-magic-numbers
            0, // eslint-disable-line no-magic-numbers
            2 * Math.PI, // eslint-disable-line no-magic-numbers
            true
          )
          // 円形に切り出す
          context.fill()
        }
      }
      return canvas
    },
  },
}
export default TrimmingIconModal
</script>
<style scoped>
@import 'cropperjs/dist/cropper.min.css';
#trimming-icon-modal ::v-deep(.cropper-view-box),
#trimming-icon-modal ::v-deep(.cropper-face) {
  border-radius: 50%;
  cursor: grab;
  outline: initial;
}
#trimming-icon-modal ::v-deep(.cropper-face:active) {
  cursor: grabbing;
}
</style>
