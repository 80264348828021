import ReturnResponseError from '@/errors/ReturnResponseError'

/**
 * Flutter側に定義されたメソッドを呼び出す
 * @param {string} id 連携データの分類ID
 * @param {*} data 連携するデータ
 * @returns {*} Nativeから返ってきた値
 */
async function callFlutterHandler(id, data) {
  const FLUTTER_MAX_TRY_COUNT = 5
  const INTERVAL_TIME = 500
  const handlerName = `suica_${id}`
  let result = null
  for (let i = FLUTTER_MAX_TRY_COUNT; i; i--) {
    try {
      // AndroidとiOSで定義されているfunction名が異なるようなので両方試みる
      if (window.flutter_inappwebview.callHandler) {
        result = await window.flutter_inappwebview.callHandler(
          handlerName,
          data
        )
        break
      } else if (window.flutter_inappwebview._callHandler) {
        result = await window.flutter_inappwebview._callHandler(
          handlerName,
          data
        )
        break
      } else {
        throw new Error('Native call error')
      }
    } catch (e) {
      // ページ表示直後など、Flutter側の準備が間に合わなかった場合に例外が発生するため、時間をおいて再試行する
      await new Promise((resolve) => setTimeout(resolve, INTERVAL_TIME))
    }
  }
  if (result) {
    if (result.success) {
      // 成功していた場合はデータを返す
      return result.data
    } else {
      // 失敗していた場合は例外を投げる
      throw new ReturnResponseError(result.message, {data: result.data})
    }
  } else {
    // 指定回試みて成功しなければエラーを投げる
    throw new Error('Native call error')
  }
}

/**
 * Suica連携
 */
export async function linkSuica() {
  return await callFlutterHandler('linkSuica')
}

/**
 * Suica連携解除
 */
export async function unlinkSuica() {
  return await callFlutterHandler('unlinkSuica')
}

/**
 * Suicaカード情報取得
 * @return {Object} カード情報を返す
 */
export async function getCardInfo() {
  return await callFlutterHandler('cardInfo')
}

/**
 * Suica連携済
 * @return {boolean} 連携済あればtrue
 */
export async function isLinked() {
  return await callFlutterHandler('isLinked')
}

/**
 * Suicaアプリを起動する
 */
export async function openSuicaApp() {
  return await callFlutterHandler('openSuicaApp')
}

export default {
  linkSuica,
  unlinkSuica,
  getCardInfo,
  isLinked,
  openSuicaApp,
}
