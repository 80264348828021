<template>
  <div>
    <!-- カードコンポーネント -->
    <RouteDetailCard
      :pointName="getPointName"
      :prevToTime="getPrevToTime"
      :isFirstMove="isFirstMoveSection"
      :gateway="getGateway"
      :moveSection="moveSection"
      :startPlatform="getStartPlatform"
      :spotWeather="spotWeather"
      :isNoTransferRequired="isNoTransferRequired()"
    />
    <!-- カード以外部分 -->
    <!-- 徒歩の場合 -->
    <div v-if="isWalkSection" class="flex py-4 relative my-auto">
      <!-- アイコン -->
      <div class="absolute left-[-34px] h-[34px] pt-[7px] bg-gray200">
        <img src="@/assets/MobilityWalk.svg" class="w-5 h-5" />
      </div>
      <div class="ml-6 flex">
        <!-- 時間表記 -->
        <div class="my-auto text-W4 text-[14px] leading-[14px]">
          <span class="mr-0.5">徒歩</span>
          <span v-if="isExistHour" class="mr-0.5">
            <span>{{ timeHour }}時間</span>
          </span>
          <span>{{ timeMinute }}分</span>
        </div>
        <!-- ナビボタン -->
        <img
          src="@/assets/route/Icon_Navi.png"
          class="rounded-[8px] w-13 h-[29px] ml-3"
          @click="onClickNavi()"
          alt="ナビ"
        />
      </div>
    </div>
    <!-- 徒歩以外の場合は途中表示 -->
    <div v-else-if="isExistCallingAt" class="relative flex my-auto">
      <!-- 縦線上の表示 -->
      <div
        v-if="!isShowCallingAt"
        class="absolute left-[-28px] w-2 py-[5px] my-[5px] bg-gray200"
      >
        <div class="rounded-[50%] w-2 h-2" :style="getLineColor" />
        <div class="rounded-[50%] w-2 h-2 mt-1" :style="getLineColor" />
        <div class="rounded-[50%] w-2 h-2 mt-1" :style="getLineColor" />
      </div>
      <!-- 駅数＆駅名表示 -->
      <div class="ml-6 py-5">
        <div
          class="flex items-center mb-1 last:mb-0"
          @click="isShowCallingAt = !isShowCallingAt"
        >
          <img
            v-if="isShowCallingAt"
            src="@/assets/Icon_topV2.svg"
            class="h-[10px] w-[10px]"
          />
          <img
            v-else
            src="@/assets/Icon_downV2.svg"
            class="h-[10px] w-[10px]"
          />
          <div class="ml-2 text-W5 text-primary leading-[13px] text-[13px]">
            {{ callingAt.length }}駅
          </div>
        </div>
        <div v-show="isShowCallingAt">
          <div
            v-for="station in callingAt"
            :key="station.node_id"
            class="flex items-baseline mt-3 ml-[18px] relative"
          >
            <div
              class="absolute left-[-68px] rounded-[50%] w-1 h-1 bg-white my-1"
            />
            <div class="text-W4 text-gray500 leading-3">
              {{ station.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  徒歩セクションでもなく、途中駅もない場合 -->
    <div v-else class="h-3" />
  </div>
</template>
<script>
import Util from '@/mixins/util'
import RouteDetailCard from '@/components/molecules/searchRoute/RouteDetailCard.vue'
import {isNoNextTransferRequired} from '@/utils/transferUtil'

const RouteDetailSection = {
  name: 'RouteDetailSection',
  mixins: [Util],
  components: {RouteDetailCard},
  props: {
    // moveセクション情報
    moveSection: {
      type: Object,
    },
    // ひとつ前のmoveセクション情報
    prevMoveSection: {
      type: Object,
    },
    // ひとつ前にpointセクション情報
    prevPointSection: {
      type: Object,
    },
    // ひとつ次のpointセクション情報
    nextPointSection: {
      type: Object,
    },
    // 天気情報
    spotWeather: {
      type: Object,
    },
  },
  emits: ['updateRouteMove', 'clickNavi'],
  data() {
    return {
      // 途中駅情報を表示するフラグ
      isShowCallingAt: false,
    }
  },
  created() {},
  mounted() {},

  updated() {
    // DOMの変更を親に通知
    this.$emit('updateRouteMove')
  },
  computed: {
    /**
     * 電車経路の区間駅情報
     */
    callingAt() {
      if (this.moveSection.transport && this.moveSection.transport.callingAt) {
        return this.moveSection.transport.callingAt
      }
      return []
    },
    /**
     * 区間駅の有無
     * @return {Boolean} true:あり false:なし
     */
    isExistCallingAt() {
      return 0 < this.callingAt.length // eslint-disable-line no-magic-numbers
    },
    /**
     * 徒歩セクションかどうかの判定
     * @return {Boolean} true:徒歩セクション, false:徒歩以外
     */
    isWalkSection() {
      return (
        !this.isNull(this.moveSection) &&
        this.moveSection.move == this.$config.MOVE.WALK
      )
    },
    /**
     * 移動時間(h)の値を算出
     */
    timeHour() {
      const time = this.moveSection.time
      const hour = 60
      return Math.floor(time / hour)
    },
    /**
     * 移動時間(m)の値を算出
     */
    timeMinute() {
      const time = this.moveSection.time
      const hour = 60
      return time % hour
    },
    /**
     * 時間の単位が存在するかどうか
     */
    isExistHour() {
      return 0 < this.timeHour // eslint-disable-line no-magic-numbers
    },
    /**
     * 線色の取得
     */
    getLineColor() {
      return {
        backgroundColor:
          this.moveSection.color || this.$config.COLOR.GENERAL_MOBILITY,
      }
    },
    /**
     * ポイントの名称を取得
     */
    getPointName() {
      return this.prevPointSection.spotName || this.prevPointSection.name
    },
    /**
     * 最初のmoveセクションかどうか
     */
    isFirstMoveSection() {
      return this.isNull(this.prevMoveSection)
    },
    /**
     * ひとつ前のmoveセクションの到着時間
     */
    getPrevToTime() {
      if (
        this.prevMoveSection &&
        this.prevMoveSection.move != this.$config.MOVE.WALK
      ) {
        return this.prevMoveSection.toTime
      }
      return null
    },
    /**
     * 出口情報の取得
     */
    getGateway() {
      return this.prevPointSection.gateway
    },
    /**
     * 出発プラットフォーム情報
     */
    getStartPlatform() {
      return this.prevPointSection.startPlatform
    },
  },
  methods: {
    /**
     * 乗換が不要かどうかを判定
     * @return {Boolean} 乗換不要判定
     */
    isNoTransferRequired() {
      const prevMoveSection = this.prevMoveSection
      const pointSection = this.prevPointSection
      const nextMoveSection = this.moveSection
      return isNoNextTransferRequired(
        prevMoveSection,
        pointSection,
        nextMoveSection
      )
    },
    /**
     * ナビボタンクリック時処理
     */
    onClickNavi() {
      this.$emit('clickNavi')
    },
  },
}
export default RouteDetailSection
</script>
<style></style>
