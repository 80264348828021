<template>
  <div id="bicycleReservationTop" class="fixed-app flex flex-col">
    <!-- ヘッダー -->
    <Header
      headerTitle="自転車予約TOP"
      :isShowClose="true"
      @clickClose="clickClose(true)"
      ref="arrangementHeader"
    />
    <div class="overflow-y-auto scroll-bar-none flex-1">
      <!-- 白枠 -->
      <div class="w-full bg-gray200">
        <!-- バイクシェア説明画像 -->
        <img src="@/assets/arrangementExplanation/bikeShareExplanation.png" />
      </div>
      <div class="mx-6">
        <!-- バイクシェアサービスについての説明 -->
        <div class="text-left my-4 text-W4 text-[14px] leading-[21px]">
          バイクシェアサービスは、乗りたい時に借りて、行きたい場所で返すことができる自転車のシェア（共有）サービスです。
        </div>
        <!-- 「地図から予約する」ボタン -->
        <TheButton
          text="地図から予約する"
          blankSpace="3"
          class="text-center mb-8"
          @click-button="moveBicycleReservationFromMap()"
        />
        <!-- 自転車の乗り入れについて -->
        <div class="mt-8 mb-5">
          <div class="text-left text-W7 text-[18px] leading-[18px] mb-3">
            自転車の乗り入れについて
          </div>
          <div class="text-left text-W4 text-[14px]">
            エリアを跨いでの自転車の乗り入れ（返却）はできませんので、ご注意ください。
          </div>
        </div>
        <!-- 提供エリア紹介サイト遷移用ボタン -->
        <TheButton
          text="提供エリアを見る"
          appendImgPath="common/Icon_Target_Blank.svg"
          class="the-button-secondary text-center mb-8"
          @click-button="openServiceAreaSite()"
        />
        <!-- もっと知りたい -->
        <div class="mt-8 mb-5">
          <div class="text-left text-W7 text-[18px] leading-[18px] mb-3">
            もっと知りたい
          </div>
          <div class="text-left text-W4 text-[14px]">
            バイクシェアサービスについてのさらに詳しい情報は以下のサイトからご覧ください。
          </div>
        </div>
        <!-- バイクシェアのサイト遷移用ボタン -->
        <TheButton
          text="バイクシェアのサイトへ"
          appendImgPath="common/Icon_Target_Blank.svg"
          class="the-button-secondary text-center mb-8"
          @click-button="openShareCycleSite()"
        />
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 自転車予約TOP画面コンポーネント
 */
import Header from '@/components/organisms/Header.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Util from '@/mixins/util'
import ArrangementUtil from '@/mixins/arrangementUtil.js'

const BicycleReservationTop = {
  name: 'BicycleReservationTop',
  components: {Header, TheButton},
  mixins: [Util, ArrangementUtil],
  created() {},
  computed: {
    /**
     * 現在地の取得
     */
    currentPosition() {
      return this.$store.state.currentPosition
    },
  },
  methods: {
    /**
     * ポート表示用マップ画面への遷移処理
     */
    moveBicycleReservationFromMap() {
      this.$router.push({name: 'BicycleReservationPortOnMap'})
    },
    /**
     * シェアサイクルのサイトを展開する
     * 別途ブラウザを立ち上げる
     */
    openShareCycleSite() {
      window.open(
        process.env.VUE_APP_DOCOMO_BIKE_SHARE_HP_URL,
        '_blank',
        'noreferrer'
      )
    },
    /**
     * シェアサイクルの提供エリア紹介サイトを展開する
     * 別途ブラウザを立ち上げる
     */
    openServiceAreaSite() {
      window.open(
        process.env.VUE_APP_DOCOMO_BIKE_SHARE_SERVICE_AREA_URL,
        '_blank',
        'noreferrer'
      )
    },
  },
}
export default BicycleReservationTop
</script>
<style scoped></style>
