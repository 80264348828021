<template>
  <div>
    <div v-if="inputType == 'date'">
      <div class="flex datetimepicker">
        <div v-if="!dateDisabled" class="w-full text-[19px] text-W6 medium">
          <span :class="this.fontColor()">{{ formatedDateWeekday }}</span>
        </div>
        <div v-else class="w-full text-[19px] text-W6">
          <span class="text-gray400">{{ '-' }}</span>
        </div>
        <input
          v-model="selectedDate"
          ref="actualform"
          class="datetimeform absolute"
          type="date"
          :disabled="dateDisabled"
          @input="inputSelectedDate()"
          @focus="foucusDateTimePicker()"
        />
        <img src="@/assets/Icon_down_gray.svg" class="w-2.5 ml-3" />
      </div>
    </div>
    <div v-else-if="inputType == 'time'">
      <div class="flex datetimepicker">
        <div v-if="!dateDisabled" class="w-12 text-[19px] text-W6 medium">
          <span :class="this.fontColor()">
            {{ formatedTime }}
          </span>
        </div>
        <div v-else class="w-12 text-[19px] text-W6 mr-[22px]">
          <span :class="fontColor()">{{ inputTimeType }}</span>
        </div>
        <input
          v-model="selectedTime"
          ref="actualform"
          class="datetimeform absolute"
          type="time"
          :disabled="dateDisabled"
          @input="inputSelectedTime()"
          @focus="foucusDateTimePicker()"
        />
        <img
          v-show="isStartGoal"
          src="@/assets/Icon_down_gray.svg"
          class="w-2.5 ml-3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/mixins/util'

const InputDateTimeForm = {
  name: 'InputDateTimeForm',
  components: {},
  emits: ['change-datetime'],
  mixins: [Util],
  props: {
    items: Array,
    defaultDateTime: {
      type: String,
      default: null,
    },
    dateDisabled: {
      type: Boolean,
      default: false,
    },
    validationResult: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: 'date',
    },
    inputTimeType: {
      type: String,
      default: '-',
    },
  },
  data() {
    return {
      selectedDate: null,
      selectedTime: null,
      selectedDateTime: null,
      focusedDate: null,
      focusedTime: null,
      validationError: false,
    }
  },
  created() {
    if (this.defaultDateTime) {
      // 初期日時が指定されていたらその値で選択済みにする
      this.selectedDateTime = this.getFotmatYearDateTime(this.defaultDateTime)
      this.selectedDate = this.getFormatYearDate(this.defaultDateTime)
      this.selectedTime = this.getFormatTimeToHHmm(this.defaultDateTime)
    }
  },
  methods: {
    /**
     * 年月日曜日と時刻を結合する
     */
    conbineDateTime() {
      // ※注意 日付と時刻の間に'T'をつけないとiosでフォーマットできません
      this.selectedDateTime = this.getFotmatYearDateTime(
        this.selectedDate + 'T' + this.selectedTime
      )
    },
    /**
     * 選択された日付でインスタンス変数を更新
     */
    inputSelectedDate() {
      // iOSで日時選択ピッカーのリセット押下時、selectedDateが空文字となる
      // selectedDateをピッカー内の表示に合わせてピッカー表示時点の設定値とする
      this.selectedDate =
        this.selectedDate && !this.isEmpty(this.selectedDate)
          ? this.selectedDate
          : this.focusedDate
      this.conbineDateTime()
    },
    /**
     * 選択された時刻でインスタンス変数を更新
     */
    inputSelectedTime() {
      // iOSで日時選択ピッカーのリセット押下時、selectedTimeが空文字となる
      // selectedTimeをピッカー内の表示に合わせてピッカー表示時点の設定値とする
      this.selectedTime =
        this.selectedTime && !this.isEmpty(this.selectedTime)
          ? this.selectedTime
          : this.focusedTime
      this.conbineDateTime()
    },
    /**
     * ピッカー表示時の日時を保持
     */
    foucusDateTimePicker() {
      this.focusedDate = this.selectedDate
      this.focusedTime = this.selectedTime
    },
    fontColor() {
      if (this.validationError) {
        return 'validationError text-red300 '
      } else {
        if (this.selectedDateTime) {
          return 'selected text-gray500'
        } else {
          return 'notselect text-gray400'
        }
      }
    },
  },
  watch: {
    /**
     * 親に日時変更を通知
     */
    selectedDateTime: function (val) {
      this.$emit('change-datetime', this.getNow(new Date(val)))
    },
    /**
     * 日時の入力チェック情報の更新
     */
    validationResult: function (val) {
      this.validationError = val
    },
    /**
     * 初期日時が変更されたタイミングで、日時関連の各インスタンス変数を更新する
     */
    defaultDateTime: function (val) {
      this.selectedDateTime = this.getFotmatYearDateTime(val)
      this.selectedDate = this.getFormatYearDate(val)
      this.selectedTime = this.getFormatTimeToHHmm(val)
    },
  },
  computed: {
    /**
     * 日時を「YYYY/M/D (曜日) 」にフォーマットして返却する
     * 未選択時は現在日時で返す
     */
    formatedDateWeekday() {
      let targetDate = this.selectedDateTime
        ? new Date(this.selectedDateTime)
        : new Date()
      return this.getFormatDayOfWeek(targetDate)
    },
    /**
     * 日時を「HH:mm 」にフォーマットして返却する
     * 未選択時は現在日時で返す
     */
    formatedTime() {
      let targetTime = this.selectedDateTime
        ? new Date(this.selectedDateTime)
        : new Date()
      return this.getFormatTime(targetTime)
    },
    /**
     * 出発か到着が選択されているかを判断
     * @return true：出発、到着
     *         false：始発、終電、-
     */
    isStartGoal() {
      return (
        this.inputTimeType ==
          // eslint-disable-next-line no-magic-numbers
          this.$config.TIME_TYPE_LIST[this.$config.TIME_TYPE_ID.START - 1]
            .name ||
        this.inputTimeType ==
          this.$config.TIME_TYPE_LIST[this.$config.TIME_TYPE_ID.GOAL - 1].name // eslint-disable-line no-magic-numbers
      )
    },
  },
}
export default InputDateTimeForm
</script>
<style scoped>
.datetimepicker {
  position: relative;
  box-sizing: border-box;
  position: relative;
}

.datetimeform {
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.datetimeform::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
