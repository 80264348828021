<template>
  <div
    class="translateZ fixed bg-white w-full dynamic-height pb-safe-area flex flex-col"
    :class="{'shadow-panel': isShowShadow}"
    :style="additionalStyle"
  >
    <div ref="header">
      <div class="pt-2 pb-3" ref="top">
        <img v-if="isPanelIcon" class="m-auto panel-icon" />
      </div>
      <slot name="header" />
    </div>
    <div
      ref="body"
      class="scroll-bar-none flex-1"
      :id="contentId"
      :style="bodyStyle"
    >
      <slot name="body" />
    </div>
  </div>
</template>
<script>
const BasePanel = {
  name: 'BasePanel',
  props: {
    // パネル上部の余白の高さ
    spaceHeight: {
      type: Number,
    },
    // 垂直方向のスクロールを有効にするか
    isOverflowYAuto: {
      type: Boolean,
      default: true,
    },
    // パネル上部の引っ掻きアイコンを表示するか
    isPanelIcon: {
      type: Boolean,
      default: false,
    },
    // フッターを表示するか
    isShowFooter: {
      type: Boolean,
      default: false,
    },
    // パネル上部の角丸表示をするか
    isRoundedTop: {
      type: Boolean,
      default: true,
    },
    // ボディ部のID
    contentId: {
      type: String,
      default: '',
    },
    // フッターエリアの背景色
    footerBgColor: {
      type: String,
      default: 'bg-white',
    },
    // 影を表示するか
    isShowShadow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /**
     * over-frowの設定値を算出
     */
    overflowY() {
      return this.isOverflowYAuto ? 'auto' : 'unset'
    },
    /**
     * パネルの高さを算出
     * 算出式：デバイスの画面サイズ - 余白の高さ
     */
    height() {
      return this.spaceHeight !== undefined
        ? `calc(100vh - ${this.spaceHeight}px)`
        : undefined
    },
    /**
     * パネルに付与するスタイル
     */
    additionalStyle() {
      return {
        height: this.height,
        // 以下、フッター表示有無で切り替え
        'z-index': this.isShowFooter ? '10' : '2000',
        bottom: 0,
        'border-top-left-radius': this.isRoundedTop ? '20px' : '0px',
        'border-top-right-radius': this.isRoundedTop ? '20px' : '0px',
      }
    },
    /**
     * ボディ部に付与するスタイル
     */
    bodyStyle() {
      return {
        'overflow-y': this.overflowY,
      }
    },
  },
}
export default BasePanel
</script>
<style scoped>
.translateZ {
  transform: translateZ(1px);
}
.panel-icon {
  width: 32px;
  height: 4px;
  background: #979ca1 0% 0% no-repeat padding-box;
  border-radius: 6px;
}
</style>
