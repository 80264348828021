<template>
  <!-- 初回起動時ログイン画面 -->
  <div class="h-full flex flex-flow-col">
    <!-- 青枠 -->
    <div class="flex flex-col w-full bg-blue full-display">
      <div class="mt-8">
        <div class="text-W6 text-[36px] leading-[61px] text-center text-white">
          ようこそ
        </div>
        <div class="text-W4 text-[15px] leading-[26px] text-center text-white">
          アカウント登録でタクシーや自転車の
          <br />
          手配ができるようになります！
        </div>
      </div>
      <div class="flex justify-center items-end mt-4 mx-4">
        <img src="@/assets/setup/Welcome.png" class="h-full w-full" />
      </div>
    </div>
    <!-- 白枠 -->
    <div class="h-[197px] bg-white">
      <!-- ボタン -->
      <div class="mx-6 mb-9 mt-10">
        <TheButton
          text="今すぐ使う"
          class="mb-3"
          @click-button="skipAndUse()"
        />
        <TheButton
          text="新規登録・ログイン"
          class="the-button-secondary"
          @click-button="signup()"
        />
        <!-- アカウント登録補足メッセージ -->
        <img class="h-[13px] mx-auto mt-2" src="@/assets/setup/Message.svg" />
      </div>
    </div>
    <!-- 初回起動時ユーザー名設定ポップアップ -->
    <Modal
      v-if="isShowUserNameModal"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="pt-8 pb-4 mx-5">
        <div class="text-W5 text-[17px] leading-[25.5px]">
          NAMOへようこそ！
          <br />
          ニックネームを教えてください。
          <br />
          アプリ内で表示されます。
        </div>
        <!-- ユーザー名入力枠 -->
        <TextForm
          class="searchText mt-4"
          :inputClass="setInputColor"
          :isBackButton="false"
          :text="userName"
          placeholder="ニックネームを入力"
          maxlength="10"
          @input-text="setUserName"
          ref="textForm"
        />
        <div
          v-if="isOverUserName"
          class="mt-2 text-W2 text-[13px] leading-[13px] text-danger300"
        >
          全角5文字/半角10文字以内で入力してください。
        </div>
        <!-- ボタン -->
        <div class="flex justify-center mt-4 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="onClickUserNameSkip()"
          >
            スキップ
          </div>
          <div
            class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
            :class="disableButtonStyle"
            @click="onClickUserNameRegister()"
          >
            登録
          </div>
        </div>
      </div>
    </Modal>
    <!-- 初回起動時画像設定ポップアップ -->
    <Modal
      v-if="isShowIconSettingModal"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="pt-9 pb-6 mx-5">
        <div class="w-[60px] h-[60px] mx-auto">
          <img src="@/assets/Icon_Dummy_User.svg" />
        </div>
        <div class="text-W5 text-[17px] leading-[25.5px] mt-3 mb-[-4.25px]">
          あなたのアイコンに好きな画像を設定しましょう。
        </div>
        <div class="text-W3 text-[15px] leading-[23px] mt-3 mb-[-4px]">
          画像はメニュータブからいつでも変更できます。
        </div>
        <!-- ボタン -->
        <div class="flex justify-center mt-6 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="onClickIconSkip()"
          >
            スキップ
          </div>
          <div
            class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
            @click="onClickRegisterIcon()"
          >
            設定
          </div>
        </div>
      </div>
    </Modal>
    <!-- 画像トリミングポップアップ -->
    <TrimmingIconModal
      v-if="isTrimmingIconModal"
      primaryButtonText="次へ"
      SecondaryButtonText="キャンセル"
      @click-primary-button="onClickIconSave"
      @click-secondary-button="onClickIconCancel"
      @selected-file="onSelectedFile"
      ref="trimmingModal"
    />
    <!-- アイコンサイズ設定モーダル -->
    <Modal
      v-if="isSettingIconSizeModal"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-6">
        <!-- タイトル -->
        <div class="text-W5 text-[17px] leading-[17px]">サイズ選択</div>
        <!-- サイズ設定 -->
        <div class="px-8 pt-4 flex flex-col items-center">
          <!-- イメージ画像 -->
          <img :src="iconSizeImagePicture" width="227" height="227" />
          <!-- サイズ設定ラジオボタン -->
          <div class="w-full flex justify-between max-w-[227px]">
            <BaseRadioButtonV2
              v-for="userIcon in userIconSizeList"
              :key="userIcon.KEY"
              :itemKey="userIcon.KEY"
              :label="userIcon.LABEL"
              v-model="selectedUserIconSize"
            />
          </div>
        </div>
        <!-- ボタン -->
        <div class="flex justify-center mt-4 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="onClickSettingIconSizeCancel()"
          >
            キャンセル
          </div>
          <div
            class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
            @click="onClickSettingIconSizeOk()"
          >
            OK
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import TheButton from '@/components/atoms/TheButton.vue'
import Modal from '@/components/Modal.vue'
import TextForm from '@/components/TextForm.vue'
import TrimmingIconModal from '@/components/TrimmingIconModal.vue'
import {signup} from '@/auth/AuthorizationInteractor.js'
import Setup from '@/views/setup/setup.js'
import Util from '@/mixins/util.js'
import LoginUtil from '@/mixins/loginUtil.js'
import NativeUtil from '@/mixins/nativeUtil'
import BaseRadioButtonV2 from '@/components/atoms/BaseRadioButtonV2.vue'

const GUEST_USER = 'ゲスト'

const LoginWelcome = {
  name: 'LoginWelcome',
  components: {
    TheButton,
    Modal,
    TextForm,
    TrimmingIconModal,
    BaseRadioButtonV2,
  },
  mixins: [Setup, Util, LoginUtil, NativeUtil],
  data() {
    return {
      isShowUserNameModal: false, // ユーザー名設定ポップアップ表示フラグ
      isShowIconSettingModal: false, // 画像設定ポップアップ表示フラグ
      isTrimmingIconModal: false, // 画像トリミングポップアップ表示フラグ
      isSettingIconSizeModal: false, // 画像サイズ変更ポップアップ
      userName: '', // ユーザー名
      userIconSizeList: Object.values(this.$config.USER_ICON_SIZE_LIST), // ユーザーアイコンサイズ一覧
      selectedUserIconData: '', // 一時保存用の変更後アイコン画像
      selectedUserIconSize: this.$config.USER_ICON_SIZE_LIST.MIDDLE.KEY, // 一時保存用の変更後アイコンサイズ(初期値:中)
    }
  },
  computed: {
    /**
     * アイコンサイズのイメージ画像
     * @return イメージ画像
     */
    iconSizeImagePicture() {
      let iconName = ''
      switch (this.selectedUserIconSize) {
        case this.$config.USER_ICON_SIZE_LIST.SMALL.KEY:
          iconName = 'Image_Login_Auto_small.png'
          break
        case this.$config.USER_ICON_SIZE_LIST.MIDDLE.KEY:
          iconName = 'Image_Login_Auto_middle.png'
          break
        case this.$config.USER_ICON_SIZE_LIST.LARGE.KEY:
          iconName = 'Image_Login_Auto_large.png'
          break
        default:
      }
      return require('@/assets/' + iconName)
    },
    /**
     * 初回起動時ユーザー名設定ポップアップの登録ボタンのスタイル
     */
    disableButtonStyle() {
      return this.userName == '' || this.isOverUserName
        ? 'opacity-40 pointer-events-none'
        : ''
    },
    /**
     * 初回起動時ユーザー名の入力バイト数超過チェック
     * @return {boolean} true:バイト数超過
     */
    isOverUserName() {
      return (
        this.$config.MAX_USER_NAME_LENGTH <
        this.calculationByteOfInputChar(this.userName)
      )
    },
    /**
     * 初回起動時ユーザー名の入力欄のクラス属性設定
     */
    setInputColor() {
      // 文字超過時、赤枠赤背景にする
      return this.isOverUserName ? '!border-danger300 bg-danger100' : ''
    },
    /**
     * Authログイン状態
     */
    isLogin() {
      return this.$store.state.user.auth.isLogin
    },
  },
  created() {
    // ログイン後に遷移してきたか判定
    this.judgeJumpedAfterLogin()
  },
  methods: {
    /**
     * アカウント登録
     */
    async signup() {
      // ローディング画像を開く
      this.$store.commit('startLoading')
      await signup()
    },
    /**
     * スキップして使用押下処理
     */
    skipAndUse() {
      const isNotSetAppInfo = !this.isSetAppInfo()

      // アプリ内情報がない場合は設定ポップアップ表示
      if (isNotSetAppInfo) {
        this.isShowUserNameModal = true
      } else {
        // 既にアプリ内情報が存在する場合はそのままロード処理
        this.initData()
      }
    },
    /**
     * ログイン後に遷移してきたかを判定し、モーダルを表示する
     */
    judgeJumpedAfterLogin() {
      // アプリ内情報が既に作成されていないか判定
      const isNotSetAppInfo = !this.isSetAppInfo()

      // ログインしており、アプリ内情報が作成されていない場合はモーダル表示
      if (this.isLogin && isNotSetAppInfo) {
        this.isShowUserNameModal = true
        return
      }

      // ユーザー取得・作成に失敗して遷移してきた場合はモーダル表示
      const isJumpedAfterError = this.getLocalStorage(
        this.$config.USER_ACCOUNT_DEFECT
      )
      if (isJumpedAfterError) {
        this.isShowUserNameModal = true
      }
    },
    /**
     * 初回起動時ユーザー名設定ポップアップで入力された名前を設定
     * @param {String} value 入力された名前
     */
    setUserName(value) {
      this.userName = value
    },
    /**
     * 初回起動時ユーザー名ポップアップのスキップボタン押下処理
     */
    onClickUserNameSkip() {
      this.userName = GUEST_USER

      // 初回起動時ユーザー名ポップアップを閉じる
      this.isShowUserNameModal = false

      // 初回起動時画像設定ポップアップを表示する
      this.isShowIconSettingModal = true
    },
    /**
     * 初回起動時ユーザー名ポップアップの登録ボタン押下処理
     */
    onClickUserNameRegister() {
      if (!this.userName) {
        this.userName = GUEST_USER
      }
      // 初回起動時ユーザー名ポップアップを閉じる
      this.isShowUserNameModal = false

      // 初回起動時画像設定ポップアップを表示する
      this.isShowIconSettingModal = true
    },
    /**
     * 初回起動時画像設定ポップアップのスキップボタン押下処理
     */
    onClickIconSkip() {
      // 初回起動時画像設定ポップアップを閉じる
      this.isShowIconSettingModal = false

      // アプリ内情報登録処理
      this.registerAppInfo(
        this.userName,
        this.selectedUserIconData,
        this.selectedUserIconSize
      )
    },
    /**
     * 初回起動時画像設定ポップアップの設定ボタン押下処理
     */
    async onClickRegisterIcon() {
      const isGranted = await this.checkCameraPermission()
      if (isGranted) {
        this.isTrimmingIconModal = true
        // ファイル選択ダイアログを表示する
        this.$nextTick(() => {
          // 表示完了後にトリミング開始を呼び出し
          this.$refs.trimmingModal.startTrimming()
        })
      }
    },
    /**
     * ファイル選択時処理
     */
    onSelectedFile() {
      // 初回起動時画像設定ポップアップを閉じる
      this.isShowIconSettingModal = false
    },
    /**
     * 画像保存ボタン押下時処理
     */
    onClickIconSave(data) {
      this.selectedUserIconData = data
      // 画像トリミングポップアップを閉じ画像サイズ設定ポップアップを表示する
      this.isTrimmingIconModal = false
      this.isSettingIconSizeModal = true
    },
    /**
     * 画像トリミングポップアップのキャンセルボタン押下処理
     */
    onClickIconCancel() {
      // 画像トリミングポップアップを閉じる
      this.isTrimmingIconModal = false

      // 初回起動時画像設定ポップアップの表示
      this.isShowIconSettingModal = true
    },
    /**
     * アイコンサイズ設定モーダルのキャンセルボタン押下処理
     */
    onClickSettingIconSizeCancel() {
      // 保持していた画像を破棄
      this.selectedUserIconData = ''
      // アイコンサイズのロールバック(中)
      this.selectedUserIconSize = this.$config.USER_ICON_SIZE_LIST.MIDDLE.KEY
      // モーダルを閉じる
      this.isSettingIconSizeModal = false
      // 初回起動時画像設定ポップアップの表示
      this.isShowIconSettingModal = true
    },
    /**
     * アイコンサイズ設定モーダルのOKボタン押下処理
     */
    onClickSettingIconSizeOk() {
      // アプリ内情報登録処理
      this.registerAppInfo(
        this.userName,
        this.selectedUserIconData,
        this.selectedUserIconSize
      )
    },
  },
}
export default LoginWelcome
</script>
<style scoped>
.flex-flow-col {
  flex-flow: column;
}
.full-display {
  background: linear-gradient(#61c7f7, #009ce5);
}
</style>
