<template>
  <div
    name="BaseRadioButton"
    class="flex items-center my-6"
    :class="[isDisabled && 'disabled-button']"
    @click="$emit('update:modelValue', itemKey)"
  >
    <!-- デフォルトラジオボタン非表示のためラベルで括る -->
    <label class="radio-button">
      <input
        type="radio"
        :value="itemKey"
        :checked="isSelected"
        :disabled="isDisabled"
      />
      <span class="flex items-center radio-text"></span>
    </label>
    <!-- ラベル表示 -->
    <span
      class="ml-2 text-W4 text-[15px] text-left leading-[19px]"
      :class="isSelected ? 'text-gray600' : 'text-gray500'"
    >
      {{ label }}
    </span>
  </div>
</template>
<script>
const BaseRadioButton = {
  name: 'BaseRadioButton',
  props: {
    // ボタンの識別子
    itemKey: {
      type: String,
      default: '',
    },
    // ボタンの右側に表示するラベル
    label: {
      type: String,
      default: '',
    },
    // v-modelに紐づく値
    modelValue: {
      type: String,
      default: '',
    },
    // ラジオボタンコンポーネントの操作可否状態
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * 選択したラジオボタンか判定する
     */
    isSelected() {
      if (this.isDisabled) {
        return false
      }
      return this.modelValue === this.itemKey
    },
  },
}
export default BaseRadioButton
</script>
<style scoped>
.disabled-button {
  opacity: 0.4;
}
.radio-button input {
  display: none; /* デフォルトラジオボタン非表示 */
}
.radio-button input + .radio-text {
  display: block;
  border: 1px solid #6b7075;
  box-sizing: border-box;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.radio-button input:checked + .radio-text {
  border-color: #0072ff;
  position: relative;
}
.radio-button input:checked + .radio-text::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 8px;
  width: 8px;
  background: #0072ff;
  border-radius: 50%;
  margin: auto;
}
</style>
