<template>
  <div class="fixed-app flex flex-col">
    <!-- ヘッダー -->
    <Header
      ref="header"
      :isShowPrevious="true"
      headerTitle="手配内容確認"
      :isShowClose="true"
      @clickPrevious="goToBackPage()"
      @clickClose="clickClose(true)"
    />
    <div class="w-full flex-1 relative">
      <!-- マップ -->
      <BaseMap
        ref="BaseMap"
        mapElementId="taxiReservationConfirmMap"
        :isTouchMap="false"
        :mapPaddingPx="46"
        :copyrightBottomSpace="18"
        @finish-generate-map="finishGenerateMap()"
      />
      <div
        class="absolute bg-white rounded-t-[10px] inset-x-0 bottom-0 h-[10px] shadow"
      ></div>
    </div>
    <div
      ref="confirmationCard"
      class="bg-white px-4 pb-6 pt-2"
      :class="{'z-10': !isShowArrangeFareDetail}"
    >
      <div class="mb-4 text-W7 text-[15px] leading-[26px] text-left">
        この内容で手配します。よろしいですか？
      </div>
      <div class="w-full flex flex-col mb-4 border-y border-gray300">
        <div class="divide-y border-gray300">
          <!-- 目安料金 -->
          <div class="h-10 flex">
            <div
              class="flex items-center justify-center bg-gray200 basis-[77px] text-W5 text-[13px] text-gray500"
            >
              目安料金
            </div>
            <div class="ml-3 flex items-center">
              <div class="flex items-baseline">
                <span
                  v-if="isSetEstimation"
                  class="text-W7 text-[14px] leading-[21px]"
                >
                  約
                </span>
                <span class="text-W7" :class="estimatedAmountTextClass">
                  {{ estimatedAmountText }}
                </span>
                <span
                  v-if="isSetEstimation"
                  class="text-W7 text-[14px] leading-[21px]"
                >
                  円
                </span>
                <span
                  class="ml-2 text-W3 text-[13px] text-gray600 leading-[13px]"
                >
                  +
                </span>
                <div
                  class="flex items-center px-2 py-[2px] bg-white rounded-[17px]"
                  :class="isShowArrangeFareDetail ? 'translateZ-7000' : ''"
                  @click="isShowArrangeFareDetail = !isShowArrangeFareDetail"
                >
                  <span class="text-W3 text-[13px] text-gray600 leading-[21px]">
                    手配料金
                  </span>
                  <img
                    src="@/assets/QuestionCircleGray.svg"
                    class="w-4 h-4 ml-1"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 移動時間 -->
          <div class="h-10 flex border-gray700">
            <div
              class="flex items-center justify-center bg-gray200 w-[77px] text-W5 text-[13px] text-gray500"
            >
              移動時間
            </div>
            <div class="ml-3 flex items-center">
              <div class="flex items-baseline">
                <span
                  v-if="isSetEstimation"
                  class="text-W7 text-[14px] leading-[21px]"
                >
                  約
                </span>
                <span v-if="isExistHour">
                  <span class="text-W7 text-[18px]">
                    {{ timeHour }}
                  </span>
                  <span class="text-W7 text-[14px] leading-[21px]">時間</span>
                </span>
                <span class="text-W7 text-[18px]">
                  {{ timeMinute }}
                </span>
                <span
                  v-if="isSetEstimation"
                  class="text-W7 text-[14px] leading-[21px]"
                >
                  分
                </span>
              </div>
            </div>
          </div>
          <!-- 手配開始 -->
          <div class="h-[53px] flex border-gray700">
            <div
              class="flex items-center justify-center bg-gray200 w-[77px] text-W5 text-[13px] text-gray500"
            >
              手配開始
            </div>
            <div class="ml-3 py-4 flex items-center">
              <div class="flex-1">
                <div class="flex items-center leading-[21px]">
                  <span class="text-W7 text-[14px]">今すぐ</span>
                  <div v-if="existArrivalTime" class="items-center">
                    <span
                      v-if="isSameEstimatedArrivalTime"
                      class="text-W4 text-[13px]"
                    >
                      （約{{ estimatedArrivalTime.minWaitTime }}分で乗車）
                    </span>
                    <span v-else class="text-W4 text-[13px]">
                      （約{{ estimatedArrivalTime.minWaitTime }}~{{
                        estimatedArrivalTime.maxWaitTime
                      }}分で乗車）
                    </span>
                  </div>
                </div>
                <div class="flex items-center text-[11px] leading-[17.6px]">
                  <span class="text-W4 text-gray400 mr-px">※</span>
                  <span class="text-W4 text-gray400">
                    時間指定の予約はできません
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TheButton
        class="mb-2"
        text="お支払い方法の設定へすすむ"
        @click-button="onClickCallTaxi()"
      />
      <div class="text-W4 text-[11px] leading-[21px] text-center text-gray400">
        料金は降車時に確定します
      </div>
    </div>
    <!-- 手配料金説明 -->
    <div
      v-if="isShowArrangeFareDetail"
      id="overlay"
      class="overlay"
      @click="isShowArrangeFareDetail = false"
    >
      <div class="overlay-layer">
        <div class="w-full mx-6 mb-[252px]">
          <div class="flex justify-end w-full pb-2">
            <img src="@/assets/Icon_Close_wh.svg" class="h-4 w-4" />
          </div>
          <div class="p-6 rounded-[20px] bg-white text-left" @click="stopEvent">
            <div class="text-W7 text-[13px] leading-[19.5px] mb-3">
              手配料金について
            </div>
            <div class="text-W4 text-gray500 text-[12px] leading-[18px]">
              <div class="flex mb-2">
                <div class="w-5">1.</div>
                <div class="flex-1">
                  タクシー会社規定の迎車料金がかかります。
                </div>
              </div>
              <div class="flex mb-2">
                <div class="w-5">2.</div>
                <div class="flex-1">
                  タクシー会社に応じてアプリ手配料100円がかかります。
                  <div
                    class="inline-flex items-center"
                    @click="openGoTaxiAppArrangementFeeSite()"
                  >
                    <span class="text-blue text-W5">料金一覧はこちら</span>
                    <img
                      src="@/assets/Icon_Right_blue.svg"
                      class="inline h-[14px] w-[14px]"
                    />
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="w-5">3.</div>
                <div class="flex-1">
                  地図上の経路や料金は実際とは異なる場合があります。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArrangementUtil from '@/mixins/arrangementUtil'
import Header from '@/components/organisms/Header.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import BaseMap from '@/components/atoms/BaseMap.vue'
import Util from '@/mixins/util'

const EMPTY_NUM = 0
const MINUTE_OF_HOUR = 60
const SECOND_OF_HOUR = 3600

const PICKUP_ICON_SIZE = {
  height: 46,
  width: 36,
}
const DROP_ICON_SIZE = {
  height: 44,
  width: 44,
}

const TaxiReservationConfirm = {
  name: 'TaxiReservationConfirm',
  components: {Header, TheButton, BaseMap},
  mixins: [ArrangementUtil, Util],
  data() {
    return {
      estimatedResult: {}, // 見積もり結果
      glMarkers: [], // 乗車地・目的地用のアイコンリスト
      isShowArrangeFareDetail: false,
      estimatedArrivalTime:
        this.$store.state.MobilityReservationStore.taxi.estimatedArrival, // 到着予想時間
    }
  },
  computed: {
    /**
     * ルート情報を返す
     */
    routeInfo() {
      return this.$store.state.MobilityReservationStore.taxi.routeInfo
    },
    /**
     * 乗車地の住所
     */
    pickUp() {
      return this.routeInfo.pickUp
    },
    /**
     * 目的地の住所
     */
    dropOff() {
      return this.routeInfo.dropOff
    },
    /**
     * 見積もりがあるかを判定
     */
    isSetEstimation() {
      return !this.isEmpty(this.estimatedResult)
    },
    /**
     * 表示する見積もり金額テキスト
     */
    estimatedAmountText() {
      return this.isSetEstimation
        ? this.addCommaForInteger(this.estimatedResult.amount)
        : 'メーター料金'
    },
    /**
     * 表示する見積もり金額テキストに付与するクラス
     */
    estimatedAmountTextClass() {
      return this.isSetEstimation ? 'text-[18px]' : 'text-[14px]'
    },
    /**
     * 総時間(秒)から時間(h)を算出
     */
    timeHour() {
      return this.isSetEstimation
        ? Math.floor(this.estimatedResult.duration / SECOND_OF_HOUR)
        : EMPTY_NUM
    },
    /**
     * 総時間(秒)から分(mm)を算出
     */
    timeMinute() {
      return this.isSetEstimation
        ? Math.floor(
            (this.estimatedResult.duration % SECOND_OF_HOUR) / MINUTE_OF_HOUR
          )
        : '-'
    },
    /**
     * 総時間(秒)から算出した時間(h)が存在するか
     */
    isExistHour() {
      return EMPTY_NUM < this.timeHour
    },
    /**
     * 目的地が指定されているかどうか
     */
    isSettingDropOff() {
      return this.dropOff.lat != '' && this.dropOff.lon != ''
    },
    /**
     * 到着予想時間が同じであるかどうか
     */
    isSameEstimatedArrivalTime() {
      return (
        this.estimatedArrivalTime.minWaitTime ===
        this.estimatedArrivalTime.maxWaitTime
      )
    },
    /**
     * 迎車見込み時間が存在するかどうか
     */
    existArrivalTime() {
      return (
        this.estimatedArrivalTime.maxWaitTime !== null &&
        this.estimatedArrivalTime.minWaitTime !== null
      )
    },
  },
  created() {
    this.getTaxiEstimations()
  },
  methods: {
    /**
     * マップ生成後の処理
     */
    finishGenerateMap() {
      // 乗車地と目的地にアイコンを配置
      this.setPickupAndDropOffIcon()
      // 乗車地と目的地が収まるようマップを移動
      this.fitPickupAndDropOffSpot()
    },
    /**
     * 乗車地及び目的地のアイコンをマップに配置する
     */
    setPickupAndDropOffIcon() {
      let iconList = []
      // 乗車地用のアイコン情報を生成&追加
      const pickUpIcon = {
        name: 'pick up',
        lat: Number(this.pickUp.lat),
        lon: Number(this.pickUp.lon),
        size: PICKUP_ICON_SIZE,
        icon: 'arrangementIcon/taxi/IconPickUpPin.svg',
      }
      iconList.push(pickUpIcon)

      // 目的地の指定がある場合のみ、目的地用のアイコン情報を生成&追加
      if (this.isSettingDropOff) {
        const dropOffIcon = {
          name: 'drop off',
          lat: Number(this.dropOff.lat),
          lon: Number(this.dropOff.lon),
          size: DROP_ICON_SIZE,
          icon: 'Icon_Map_Place.svg',
        }
        iconList.push(dropOffIcon)
      }

      // 作成したアイコン情報からGLMarkerを生成し、描画
      this.glMarkers = this.$refs.BaseMap.generateGlMarkers(iconList)
      this.$refs.BaseMap.addGLMarkers(this.glMarkers)
    },
    /**
     * 乗車地と目的地に合わせて画面を移動
     */
    fitPickupAndDropOffSpot() {
      const pickUpSpot = {
        lat: Number(this.pickUp.lat),
        lon: Number(this.pickUp.lon),
      }

      if (this.isSettingDropOff) {
        // 目的地の指定があれば、乗車地と目的地が収まる縮尺・中心地にマップを移動
        const dropOffSpot = {
          lat: Number(this.dropOff.lat),
          lon: Number(this.dropOff.lon),
        }
        const spotList = [pickUpSpot, dropOffSpot]
        this.$refs.BaseMap.fitMultipleLatLon(spotList)
      } else {
        // 目的地の指定がない場合、乗車地を中心・固定縮尺でマップ移動
        this.$refs.BaseMap.callFitWithLatLon(pickUpSpot.lat, pickUpSpot.lon)
      }
    },
    /**
     * 戻るボタン押下処理
     */
    goToBackPage() {
      // 到着予想時間を初期化
      this.$store.commit('MobilityReservationStore/initEstimatedArrivalTime')
      this.$router.push({name: this.$config.DISPLAY_TAXI_RESERVATION_TOP})
    },
    /**
     * タクシー見積もり処理
     */
    getTaxiEstimations() {
      // 目的地指定がない場合は、何も行わない
      if (!this.isSettingDropOff) return

      const vm = this

      // 成功時はメモリに結果を格納
      const success = (result) => {
        this.estimatedResult = result
        this.$store.commit('endLoading')
      }

      this.$store.commit('startLoading')
      // タクシー予約見積もりAPI実行
      vm.$store.dispatch('MobilityReservationStore/getTaxiEstimations', {
        success: success,
        pickUp: this.pickUp,
        dropOff: this.dropOff,
      })
    },
    /**
     * クリックイベントの伝搬を停止
     */
    stopEvent(e) {
      e.stopPropagation()
    },
    /**
     * GoTaxiのアプリ手配料一覧ページにジャンプ
     */
    openGoTaxiAppArrangementFeeSite() {
      window.open(
        process.env.VUE_APP_GO_TAXI_APP_ARRANGEMENT_FEE_URL,
        '_blank',
        'noreferrer'
      )
    },
    /**
     * お支払い方法の設定へすすむボタン押下処理
     */
    onClickCallTaxi() {
      this.$router.push({
        name: this.$config.DISPLAY_TAXI_RESERVATION_PAYMENT_CONFIRM,
      })
    },
  },
}
export default TaxiReservationConfirm
</script>
<style scoped>
.overlay {
  position: fixed;
  z-index: 5000;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 5000px);
  transform: translate3d(0, 0, 5000px);
}
.overlay-layer {
  display: flex;
  align-items: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1c2133;
  backdrop-filter: blur(3px) brightness(81%);
}
.translateZ-6000 {
  transform: translateZ(6000px);
}
.translateZ-7000 {
  z-index: 7000;
  transform: translateZ(7000px);
}
.shadow {
  filter: drop-shadow(0 -3px 8px #00000029);
}
</style>
