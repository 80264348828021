<template>
  <OverlayComponent
    v-show="isShowOverlay"
    class="overscroll-contain"
    :isEnableCloseOutsideArea="true"
    :isShowCloseButton="false"
    :blurEnabled="blurEnabled"
    @close-overlay="isShowPanel = false"
  >
    <template v-slot:body>
      <Transition @after-leave="closePanel()">
        <div
          v-if="isShowPanel"
          class="select-message-panel"
          ref="selectMessagePanel"
        >
          <div class="px-5 py-4 relative border-b border-gray300">
            <div class="text-[15px] text-W7">
              <slot name="header"></slot>
            </div>
            <div v-if="isShowCloseIcon" class="header-close-icon">
              <img
                src="@/assets/common/Icon_Close_Black.svg"
                class="h-6 w-6 ml-auto"
                @click="isShowPanel = false"
              />
            </div>
          </div>
          <slot name="body"></slot>
        </div>
      </Transition>
    </template>
  </OverlayComponent>
</template>

<script>
import OverlayComponent from '@/components/Overlay.vue'

const VariableHeightPanel = {
  name: 'VariableHeightPanel',
  components: {OverlayComponent},
  emits: ['closePanel'],
  props: {
    // パネルの表示フラグ
    isShow: Boolean,
    // パネルの閉じるボタン表示フラグ
    isShowCloseIcon: {
      type: Boolean,
      default: false,
    },
    // スライドインアニメーションを開始する高さ
    slideInStartPosition: {
      type: String,
      default: '-50%',
    },
    // 背景ボカシの有無
    blurEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowOverlay: false, // オーバーレイの表示フラグ
      isShowPanel: false, // プルアップパネルの表示フラグ
    }
  },
  methods: {
    /**
     * プルアップパネルを閉じる時のメソッド
     */
    closePanel() {
      // 後ろの画面を開放
      this.isShowOverlay = false
      document.body.style.overflow = null
      this.$emit('closePanel')
    },
  },
  watch: {
    isShow(value) {
      if (value) {
        // 後ろの画面をロック
        document.body.style.overflow = 'hidden'
        this.isShowPanel = true
        this.isShowOverlay = true
      } else {
        this.isShowPanel = false
      }
    },
  },
}

export default VariableHeightPanel
</script>

<style scoped>
.header-close-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 20px;
}
.v-enter-active {
  animation: slide-in 0.3s;
}
.v-leave-active {
  animation: slide-in 0.3s reverse;
}
.select-message-panel {
  position: fixed;
  bottom: 0%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 2000;
}
@keyframes slide-in {
  0% {
    bottom: v-bind(slideInStartPosition);
  }
  100% {
    bottom: 0%;
  }
}
</style>
