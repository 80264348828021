<template>
  <div id="app-page" ontouchstart="" class="fixed w-full inset-0 pt-safe-area">
    <Header
      ref="header"
      :isShowPrevious="true"
      headerTitle="乗務員とメッセージ"
      @clickPrevious="goToBackPage()"
    ></Header>
    <div ref="messageArea" class="fixed-app bg-blue200 overflow-y-auto">
      <div
        ref="messages"
        class="w-screen bg-blue200 overflow-y-visible p-2 mb-[156px]"
      >
        <div class="p-2" v-for="(item, index) in messages" :key="index">
          <ChatMessageSection
            :messageObject="item"
            :isFirst="index ? item.sender !== messages[index - 1].sender : true"
          />
        </div>
      </div>
    </div>
    <div
      class="fixed button-area w-screen bottom-0 pt-[56px] px-6 pb-safe-area"
    >
      <TheButton
        class="w-full mb-4"
        text="メッセージを選択"
        @click-button="showSelectMessage()"
      />
    </div>
    <HalfPanel
      ref="selectMessage"
      :isShow="isShowSelectMessage"
      @closePanel="isShowSelectMessage = false"
    >
      <template v-slot:header>メッセージを選択</template>
      <template v-slot:body>
        <div class="relative h-[calc(50vh_-_70px)]">
          <div class="overflow-y-scroll max-h-[calc(50vh_-_125px)]">
            <div v-if="existsTemplateMessageList" class="px-6 pb-16">
              <template v-for="item in templateMessages" :key="item.id">
                <BaseRadioButton
                  class="first:mt-0"
                  :itemKey="String(item.id)"
                  :label="item.body"
                  v-model="selectedTemplateMessage"
                />
              </template>
            </div>
            <div v-else class="flex w-full">
              <div
                class="flex flex-wrap w-full bg-danger100 mx-5 p-3 border-[1px] border-solid border-danger300 rounded-[8px]"
              >
                <img
                  src="@/assets/Icon_Caution.svg"
                  class="h-[16px] w-[16px] mr-2"
                />
                <div class="text-W4 text-[13px] leading-[18px] text-danger300">
                  メッセージの取得に失敗しました。
                </div>
              </div>
            </div>
          </div>
          <div
            class="absolute bottom-0 px-6 py-4 w-screen pointer-events-none mb-safe-area"
          >
            <div
              class="bg-gradient-to-b from-transparent to-white h-[54px]"
            ></div>
            <div class="bg-white w-full">
              <TheButton
                v-if="existsTemplateMessageList"
                class="w-full pointer-events-auto"
                text="送信する"
                :isDisabled="!selectedTemplateMessage"
                @click-button="sendMessage()"
              />
              <TheButton
                v-else
                class="w-full pointer-events-auto"
                text="メッセージを再取得"
                @click-button="fetchTemplateMessage()"
              />
            </div>
          </div>
        </div>
      </template>
    </HalfPanel>
    <!-- メッセージ送信失敗ポップアップ -->
    <Modal
      v-if="isShowSendMessageFailedModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 font-semibold text-[17px] leading-[25.5px]">
          メッセージを送信できませんでした。
        </div>
        <div class="mt-3 mx-5 text-W3 text-[15px] leading-[23px]">
          通信環境をご確認いただくか、時間を置いて再度お試しください。
        </div>
        <div
          class="mt-5 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
          @click="isShowSendMessageFailedModal = false"
        >
          もどる
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import BaseRadioButton from '@/components/atoms/BaseRadioButton.vue'
import ChatMessageSection from '@/components/molecules/ChatMessageSection.vue'
import HalfPanel from '@/components/organisms/HalfPanel.vue'
import Header from '@/components/organisms/Header.vue'
import Modal from '@/components/Modal.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Util from '@/mixins/util'
import ArrangementUtil from '@/mixins/arrangementUtil'
import log from 'loglevel'

const TaxiReservationChat = {
  name: 'TaxiReservationChat',
  components: {
    BaseRadioButton,
    ChatMessageSection,
    Header,
    HalfPanel,
    TheButton,
    Modal,
  },
  mixins: [Util, ArrangementUtil],
  data() {
    return {
      isShowSelectMessage: false, // メッセージ選択パネル表示
      isShowSendMessageFailedModal: false, // メッセージ送信失敗モーダル表示
      selectedTemplateMessage: null, // 選択されている送信メッセージ
    }
  },
  computed: {
    /**
     * メッセージ履歴を取得して表示用配列を返す
     */
    messages() {
      return this.$store.state.MobilityReservationStore.taxi.messageList
    },
    /**
     * 乗務員に送信できるテンプレートメッセージ
     */
    templateMessages() {
      return this.$store.state.MobilityReservationStore.taxi.templateMessageList
    },
    /**
     * テンプレートメッセージの存在判定
     */
    existsTemplateMessageList() {
      // eslint-disable-next-line no-magic-numbers
      return this.templateMessages.length !== 0
    },
  },
  watch: {
    messages: {
      immediate: true,
      deep: true,
      handler(newMessageList) {
        if (this.judgeUnreadTaxiMessages(newMessageList)) {
          // 未読の乗務員メッセージがある場合のみ、既読乗務員メッセージ数を更新
          const driverMessagesLength = newMessageList.filter(
            (message) =>
              message.sender === this.$config.TAXI_MESSAGE_SENDER.DRIVER
          ).length

          this.setLocalStorage(
            this.$config.MESSAGE_LOG_LENGTH,
            driverMessagesLength
          )
        }
      },
    },
  },
  mounted() {
    this.scrollToBottom()
  },
  methods: {
    /**
     * 戻るボタン押下処理
     */
    goToBackPage() {
      this.$router.push({
        name: this.$config.DISPLAY_TAXI_RESERVATION_COMPLETED,
      })
    },
    /**
     * メッセージ選択プルアップを表示する
     */
    showSelectMessage() {
      this.selectedTemplateMessage = null
      this.isShowSelectMessage = true
    },
    /**
     * メッセージを送信する
     */
    async sendMessage() {
      this.$store.commit('startLoading')

      const success = (messageList) => {
        if (messageList != null) {
          // 最新のメッセージが取得できた場合のみ更新する
          this.$store.commit(
            'MobilityReservationStore/updateTaxiReservationMessageList',
            messageList
          )
        }
        this.$store.commit('endLoading')
      }
      const failed = () => {
        // メッセージ送信失敗ポップアップ表示
        this.isShowSendMessageFailedModal = true
        this.$store.commit('endLoading')
      }
      // メッセージの送信
      const message = this.templateMessages.find(
        (item) => item.id == this.selectedTemplateMessage
      )

      this.$store.dispatch('MobilityReservationStore/sendTaxiTemplateMessage', {
        messageId: this.selectedTemplateMessage,
        message: message.body,
        success: success,
        failed: failed,
      })

      // パネルを閉じる
      this.isShowSelectMessage = false
    },
    /**
     * ページの最下部までスクロールする処理
     */
    scrollToBottom() {
      const top = this.$refs.messages.clientHeight
      this.$refs.messageArea.scrollTo({top, behavior: 'smooth'})
    },
    /**
     * 乗務員とのテンプレートメッセージ取得処理
     */
    fetchTemplateMessage() {
      this.$store.commit('startLoading')
      // テンプレートメッセージ未取得の場合は、取得処理を行う
      const success = () => {
        this.$store.commit('endLoading')
      }
      const failed = () => {
        this.$store.commit('endLoading')
        log.debug('定型文取得失敗')
      }
      const error = (e) => {
        this.$store.commit('endLoading')
        log.debug('定型文取得エラー', e)
        throw e
      }
      this.$store.dispatch(
        'MobilityReservationStore/getTaxiTemplateMessageList',
        {
          success: success,
          failed: failed,
          error: error,
        }
      )
    },
  },
}
export default TaxiReservationChat
</script>

<style scoped>
.button-area {
  background: linear-gradient(
    to bottom,
    rgb(229 239 250 / 0),
    rgb(229 239 250 / 200) 40%
  );
}
</style>
