<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <g transform="translate(-223 -647)">
      <g transform="translate(-460.558 289.942)">
        <g>
          <path
            d="M692.474,368.5h-6.75a1.5,1.5,0,0,1-1.5-1.5v-6.75a1.5,1.5,0,0,1,1.5-1.5H687.6v.75h-1.875a.75.75,0,0,0-.75.75V367a.75.75,0,0,0,.75.75h6.75a.75.75,0,0,0,.75-.75v-1.875h.75V367A1.5,1.5,0,0,1,692.474,368.5Z"
            fill="currentcolor"
          />
          <path
            d="M692.474,368.589h-6.75A1.6,1.6,0,0,1,684.131,367v-6.75a1.6,1.6,0,0,1,1.593-1.594h1.969v.937h-1.969a.657.657,0,0,0-.656.657V367a.656.656,0,0,0,.656.656h6.75a.657.657,0,0,0,.657-.656v-1.969h.937V367A1.6,1.6,0,0,1,692.474,368.589Zm-6.75-9.75a1.408,1.408,0,0,0-1.406,1.407V367a1.407,1.407,0,0,0,1.406,1.406h6.75A1.406,1.406,0,0,0,693.879,367v-1.782h-.561V367a.845.845,0,0,1-.844.843h-6.75a.845.845,0,0,1-.844-.843v-6.75a.845.845,0,0,1,.844-.844h1.783v-.563Z"
            fill="currentcolor"
          />
          <path
            d="M694.516,362.964a.468.468,0,0,1-.469-.468v-4.032h-4.031a.468.468,0,0,1-.469-.468h0a.469.469,0,0,1,.469-.469h4.5a.469.469,0,0,1,.469.469h0v4.5A.468.468,0,0,1,694.516,362.964Z"
            fill="currentcolor"
          />
          <path
            d="M688.949,364.028a.469.469,0,0,1-.332-.8h0l5.564-5.564a.469.469,0,1,1,.663.663h0l-5.564,5.564A.468.468,0,0,1,688.949,364.028Z"
            fill="currentcolor"
          />
        </g>
        <path id="Rectangle" d="M683.558,357.058h12v12h-12Z" fill="none" />
      </g>
    </g>
  </svg>
</template>
