import config from '@/const/const.js'
const constant = config.constant
/**
 * ローカルストレージからオブジェクトを取得する
 * @param {Object} key ローカルストレージのKey
 */
function getLocalStorage(key) {
  if (localStorage.getItem(key)) {
    try {
      // JSONをObjectに変換し返却
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      // jsonに問題がある場合は履歴を削除し空を返却
      localStorage.removeItem(key)
      return JSON.parse(localStorage.getItem(key))
    }
  } else {
    return null
  }
}
/**
 * ローカルストレージにオブジェクトを格納する
 * @param {String} key ローカルストレージのKey
 * @param {Object} value ローカルストレージに登録するデータ
 */
function setLocalStorage(key, value) {
  const parsed = JSON.stringify(value)
  localStorage.setItem(key, parsed)
}

/**
 * 移動手段をローカルストレージから取得する
 * @return 電車バス用移動手段情報
 */
export function getSearchOptionsFromLocalStorage() {
  const baseOptions = {
    isSuperexpressTrain: true, //新幹線
    isUltraexpressTrain: true, //有料特急
    isHighwayBus: true, //高速バス
    isDomesticFlight: true, //飛行機
    isLocalBus: true, //路線/連絡バス
    isFerry: true, //フェリー
  }

  // ローカルストレージから取得
  const result = getLocalStorage(constant.ROUTE_SEARCH_OPTIONS_KEY)

  // ローカルストレージに無い、取得失敗した場合
  if (result == null) {
    return baseOptions
  }
  return result
}
/**
 * 移動手段をローカルストレージに登録する
 * @param {Object} value ローカルストレージに登録するデータ
 */
export function setSearchOptionsToLocalStorage(value) {
  setLocalStorage(constant.ROUTE_SEARCH_OPTIONS_KEY, value)
}

/**
 * ローカルストレージからルート履歴情報を取得する
 * @return ルート履歴情報(リスト)
 */
export function getRouteHistoryFromLocalStorage() {
  // ローカルストレージから取得
  const result = getLocalStorage(constant.ROUTE_HISTORY_STORAGE_KEY)

  // ローカルストレージに無い、取得失敗した場合
  if (result == null) {
    return []
  }
  return result
}
/**
 * ルート履歴情報をローカルストレージに登録する
 * @param {Object} value ローカルストレージに登録するデータ
 */
export function setRouteHistoryToLocalStorage(value) {
  //ストレージからルート履歴情報を取得
  const baseRouteHistory = getRouteHistoryFromLocalStorage()

  // 重複チェック用内部関数
  const checkDuplicateData = (base, target) => {
    return base.name == target.name && base.address == target.address
  }

  // 経由地用重複チェック用内部関数
  const isDuplicateVia = (bViaList, tViaList) => {
    // ストレージと確認対象のviaの設定数が違う場合
    if (bViaList.length !== tViaList.length) return false

    // 配列数が同じである場合、それぞれの要素同士でパラメータをチェック
    // ストレージと確認対象が空配列の場合は、重複していると判断
    for (let i = 0; i < bViaList.length; i++) {
      // eslint-disable-next-line no-magic-numbers
      if (checkDuplicateData(bViaList[i], tViaList[i])) {
        continue
      } else {
        return false
      }
    }
    return true
  }

  // 重複していないデータを取得
  const noDuplicationRouteHistoryList = baseRouteHistory.filter((base) => {
    return (
      checkDuplicateData(base?.start, value?.start) === false ||
      checkDuplicateData(base?.goal, value?.goal) === false ||
      isDuplicateVia(base?.via, value?.via) === false
    )
  })

  //履歴を追加
  noDuplicationRouteHistoryList.unshift(value)

  //最新の10件以内になるように限定
  while (constant.MAX_HISTORY_NUM < noDuplicationRouteHistoryList.length) {
    noDuplicationRouteHistoryList.pop()
  }
  // 保存
  setLocalStorage(
    constant.ROUTE_HISTORY_STORAGE_KEY,
    noDuplicationRouteHistoryList
  )
}
