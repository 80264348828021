<template>
  <div>
    <div class="fixed-app flex flex-col">
      <!-- ヘッダー部 -->
      <div id="time-table-direction" class="w-full bg-white z-[500]">
        <div class="flex text-center w-full items-center h-[45px] px-3">
          <div class="w-full text-left">
            <img
              src="@/assets/Icon_Left_gray.svg"
              class="h-4"
              @click="goToBackPage()"
            />
          </div>
          <div class="text-W6 leading-[15px] text-[15px] w-full">時刻表</div>
          <div class="w-full"></div>
        </div>
        <!-- 線 -->
        <div class="h-[0.5px] w-full bg-gray300" />
      </div>
      <div
        id="information"
        class="bg-white w-full shadow-[0px_0px_10px_rgba(0,0,0,0.2)] z-[100]"
      >
        <div class="pl-6 flex w-full items-center">
          <div
            class="text-W2 text-[13px] leading-[13px] text-left pr-[20px] flex-1"
          >
            <!-- 名前 -->
            <div class="text-W6 text-[18px] leading-[1.125rem] mt-4">
              {{ timeTableResult.node.name }}
            </div>
            <!-- 路線 -->
            <div class="mt-3">
              {{ timeTableResult.link.name }}
            </div>
            <!-- 方面 -->
            <div class="mt-1">
              {{
                timeTableResult.link.direction[selectedDirectionInfo.direction]
              }}方面
            </div>
          </div>
          <!-- お気に入り -->
          <div class="ml-auto" @click="onClickFavorite()">
            <div v-show="!isRegisterFavorite" class="mr-[11px] w-[40px]">
              <img
                class="h-5 w-5 mx-auto"
                src="@/assets/Icon_Favorite_Off.svg"
              />
              <div class="text-W4 text-[10px] mt-1 text-primary">未登録</div>
            </div>
            <div v-show="isRegisterFavorite" class="mr-[11px]">
              <img
                class="h-5 w-5 mx-auto"
                src="@/assets/Icon_Favorite_On.svg"
              />
              <div class="text-W4 text-[10px] mt-1 text-primary">登録済み</div>
            </div>
          </div>
        </div>

        <div
          class="flex h-[48px] mx-5 my-4 h-12 border-[1px] border-gray300 rounded-[8px]"
          @click="onClickDate"
        >
          <div class="flex w-full m-4 items-center justify-between">
            <div class="flex items-center">
              <img
                class="mr-2 h-4 w-4"
                src="@/assets/route/Icon_Optimal_Clock.svg"
              />
              <div class="text-W5 text-[13px]">
                <div v-if="!searchDate">日付選択</div>
                <div v-else>
                  {{ generateSearchDate(searchDate, true) }}
                </div>
              </div>
            </div>
            <img src="@/assets/Icon_down_black.svg" class="h-2 w-2" />
          </div>
        </div>
      </div>

      <div
        class="w-full overflow-y-auto scroll-bar-none pt-2 pb-6 bg-gray200 flex-1"
        ref="scrollArea"
        @scroll="handleScroll"
      >
        <!-- サイドバー -->
        <div id="timetableSidebar" class="fixed right-3 pointer-events-none">
          <TimetableSideBar
            class="relative top-[50%] translate-y-[-50%] pointer-events-auto"
            :hourSideBarList="hourSideBarList"
            @select-sidebar-item="jumpHour($event)"
          />
        </div>
        <div v-if="isShowDiagram">
          <div
            v-for="(operation, index) in operations"
            :key="operation.id"
            :ref="`operation${index}`"
          >
            <!-- 「時」の区切り（1時間おきに表示する） -->
            <div
              v-if="isShowDividerTime(operation.time, index)"
              class="w-full flex pl-3 mt-2"
            >
              <div
                class="text-W4 text-[13px] text-gray400"
                name="timetableHour"
                :id="`timetableHour_${getFormatHour(operation.time)}`"
              >
                {{ getFormatHour(operation.time) }}:00
              </div>
              <div class="flex-1 h-[0.5px] ml-1 bg-gray300 self-center" />
            </div>
            <!-- 時刻表詳細カード -->
            <TimeTableDetailCard
              class="mt-2"
              :operation="operation"
              :stationType="selectedDirectionInfo.stationType"
              :isEnabledHighlight="
                nextTimeTableTime == operation.time && judgeSearchToday
              "
            />
          </div>
          <FooterMargin />
        </div>
        <div v-else>
          <div class="text-W2 mt-8 text-[13px] leading-[13px] text-gray500">
            出発情報がみつかりませんでした
          </div>
        </div>
      </div>
    </div>

    <!-- 日付選択ポップアップ -->
    <Modal
      v-if="isShowDateSelectForm"
      class="modal"
      @disPlayModal="completeDate"
      modalPaddingBottom="8px"
    >
      <div id="select-date-modal">
        <DateSelectForm
          @completeDate="completeDate"
          :initialDate="searchDate"
        />
      </div>
    </Modal>
    <!-- 時刻表取得エラーポップアップ -->
    <Modal
      v-if="isNoDiagramLink"
      class="modal"
      @disPlayModal="isNoDiagramLink = false"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-6">
        <div class="pb-5 text-W5 text-[17px] leading-[21.5px]">
          対象日付の時刻表がみつかりませんでした。
        </div>
        <div
          class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
          @click="isNoDiagramLink = false"
        >
          OK
        </div>
      </div>
    </Modal>
    <!-- お気に入り登録限界ポップアップ -->
    <Modal
      v-if="isShowFavoriteMaxModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-6">
        <div class="text-W5 text-[17px] leading-[21.5px]">
          お気に入り登録数は
          <br />
          最大10件です。
        </div>
        <div class="mt-4 mx-5 text-W3 text-[15px] leading-[21.5px]">
          時刻表のトップ画面から、登録済みのお気に入りを削除してください。
        </div>
        <div
          class="mt-6 py-4 mx-auto the-button-default text-W7 text-[15px] leading-[15px]"
          @click="isShowFavoriteMaxModal = false"
        >
          OK
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
/**
 * 時刻表表示画面
 * ＜概要＞
 * 時刻表を表示する画面
 * 選択した駅・方面の1日分の時刻表を表示する
 */
import Modal from '@/components/Modal.vue'
import DateSelectForm from '@/components/DateSelectForm.vue'
import Util from '@/mixins/util'
import timeTableUtil from '@/mixins/timeTableUtil'
import TimeTableDetailCard from '@/components/timeTable/TimeTableDetailCard.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import dayjs from 'dayjs'
import TimetableSideBar from '@/components/timeTable/TimetableSideBar.vue'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

// 利用できる乗り物が時刻表に存在しない場合
const NOT_FOUND_NEXT_TIMETABLE_INDEX = -1

// 深夜の時間帯におけるEND（02時）
const MIDDLE_OF_THE_NIGHT_END_HOUR = 2

let currentHour = '' // 「時」の区切りの判定用時間 dataだと変更が監視されてしまうのでここに宣言

const TimeTableDetailPage = {
  name: 'TimeTableDetailPage',
  components: {
    Modal,
    DateSelectForm,
    TimeTableDetailCard,
    FooterMargin,
    TimetableSideBar,
  },
  mixins: [Util, timeTableUtil],
  data() {
    return {
      isShowDateSelectForm: false, // 時刻設定ポップアップ表示フラグ
      isNoDiagramLink: false, //時刻表エラーポップアップ表示フラグ
      isShowFavoriteMaxModal: false, // お気に入り登録限度ポップアップ表示フラグ
      showingTimeTableResult: this.$store.state.TimeTableStore.timeTableResult, // 画面表示用の検索結果
      nextTimeTableIndex: 0, // 次の時刻表カードのインデックス
      hourSideBarList: [], // 時刻表の時間帯一覧
      isScrolling: false, // 時刻表がスクロール中であるかどうか
      scrollTimeout: null, // 時刻表がスクロール中であるかを判定する際に使用するタイマー
      dateTimeIntervalId: null, // 日時取得用のインターバルID
    }
  },
  mounted() {
    this.scrollToCurrentTime()
    this.setHourSideBarList()
    this.setTimetableSideBarHeight()
    this.startDateTimeInterval()
  },
  beforeUnmount() {
    // 日時取得用のインターバル処理終了
    this.endDateTimeInterval()
  },
  computed: {
    /**
     * 時刻表検索結果
     * @param {Function} get 表示用の時刻表検索結果を返却
     * @param {Function} set メモリの表示用時刻表検索結果とStoreの検索結果を更新
     */
    timeTableResult: {
      get() {
        return this.showingTimeTableResult
      },
      set(value) {
        // Storeとメモリに対して保持
        this.showingTimeTableResult = structuredClone(value)
        this.$store.commit('TimeTableStore/updateTimeTableResult', value)
      },
    },
    /**
     * 方面選択結果の取得
     */
    selectedDirectionInfo() {
      return this.$store.state.TimeTableStore.selectedDirectionInfo
    },
    /**
     * 日付の取得
     */
    searchDate() {
      return this.$store.state.TimeTableStore.searchDate
    },
    /**
     * 時刻表の詳細情報
     */
    operations() {
      switch (this.selectedDirectionInfo.direction) {
        case 'up':
          return this.timeTableResult.link.upOperation
        case 'down':
          return this.timeTableResult.link.downOperation
        default:
          return []
      }
    },
    /**
     * お気に入り一覧
     */
    favoriteList() {
      return this.$store.state.TimeTableStore.favoriteTimeTables
    },
    /**
     * 時刻表識別ID
     */
    selectedTimeTableId() {
      return this.$store.state.TimeTableStore.selectedTimeTableId
    },
    /**
     * お気に入り用整形データ
     */
    favoriteFormatData() {
      const direction = this.selectedDirectionInfo?.direction
      return {
        id: this.selectedTimeTableId,
        node: this.timeTableResult.node?.id,
        name: this.timeTableResult.node?.name,
        link: this.timeTableResult.link?.id,
        linkName: this.timeTableResult.link?.name,
        type: this.selectedDirectionInfo?.stationType,
        operation: direction,
        directionName: this.timeTableResult.link?.direction[direction],
      }
    },
    /**
     * お気に入り登録判定
     */
    isRegisterFavorite() {
      // お気に入りのリストから登録されているお気に入りを取得する
      return this.favoriteList.find((favorite) => {
        return (
          favorite.node == this.favoriteFormatData.node &&
          favorite.link == this.favoriteFormatData.link &&
          favorite.operation == this.favoriteFormatData.operation &&
          favorite.directionName == this.favoriteFormatData.directionName
        )
      })
    },
    /**
     * 時刻表データを表示するかどうか
     */
    isShowDiagram() {
      return 0 < this.operations.length // eslint-disable-line no-magic-numbers
    },
    /**
     * 検索日が今日か
     */
    judgeSearchToday() {
      return this.searchDate === dayjs().format('YYYY-MM-DD')
    },
    /**
     * 直近の時刻表から取得した時間
     */
    nextTimeTableTime() {
      return this.operations[this.nextTimeTableIndex]?.time
    },
  },
  methods: {
    /**
     * 時刻表サイドバーの高さ算出と設定
     */
    setTimetableSideBarHeight() {
      // 更新対象の要素
      const sidebarEl = document.getElementById('timetableSidebar')

      // 更新後の高さを算出するため、各要素の高さを取得
      const headerH = document
        .getElementById('time-table-direction')
        .getBoundingClientRect().height
      const timeTableInfoH = document
        .getElementById('information')
        .getBoundingClientRect().height
      const footerH = this.$config.FOOTER_HEIGHT

      // 高さを計算し反映
      const height = `calc(100vh - ${headerH}px - ${timeTableInfoH}px - ${footerH}px)`
      sidebarEl.style.setProperty('height', height)
    },
    /**
     * スクロール中イベント発火時処理
     * スクロール中かそうでないかをdataに保持する
     */
    handleScroll() {
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout)
        this.scrollTimeout = null
      }
      this.isScrolling = true
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false
      }, 100) // eslint-disable-line no-magic-numbers
    },
    /**
     * 現在の表示している時刻表における各時間帯一覧を作成し保持する
     */
    setHourSideBarList() {
      this.$nextTick(() => {
        // ターゲットとなる要素(NodeList)を取得
        const elNodeList = document.getElementsByName('timetableHour')
        // 配列として扱うため、NodeList→Arrayに変換
        const elList = Array.prototype.slice.call(elNodeList)
        // 時間(先頭2文字)だけを抽出
        const hourList = elList.map((el) => {
          return el.textContent.substr(0, 2) // eslint-disable-line no-magic-numbers
        })

        // 抽出結果を保持
        this.hourSideBarList = hourList
      })
    },
    /**
     * 受け取った時間帯の文字列を用いて、時間帯の先頭にスクロール位置を動かす
     * @param {String} target 時間帯('09'等)
     */
    jumpHour(target) {
      // スクロール中にscrollIntoViewでスクロール位置をずらそうとすると
      // DOMの描画が追いつかず表示されない事象が発生する。
      // スクロール中は各時間帯にジャンプしないように制御することで事象回避する。
      if (this.isScrolling) return

      // 移動対象の要素を探索
      const targetEl = document.getElementById(`timetableHour_${target}`)
      if (!targetEl) return

      // スクロール位置をずらす
      targetEl.scrollIntoView()
    },
    /**
     * 日時設定押下時処理(Modal表示処理)
     */
    onClickDate() {
      // ポップアップを表示する
      this.isShowDateSelectForm = true
    },
    /**
     * 日付選択完了時処理
     * @param {String} selectedDate ポップアップで選択した日付
     */
    completeDate(selectedDate) {
      // ポップアップを非表示にする
      this.isShowDateSelectForm = false

      if (!selectedDate) {
        // 日付選択していない場合は終了する
        return
      }

      // 時刻表の再検索
      const success = (timeTableResult) => {
        // 方面情報が返却されなかった場合(null)はエラーポップアップ表示
        if (!timeTableResult.link) {
          this.isNoDiagramLink = true
          this.$store.commit('endLoading')
          return
        }
        // 時刻表の検索日付を保持
        const param = dayjs(selectedDate).format('YYYY-MM-DD')
        this.$store.commit('TimeTableStore/updateSearchDate', param)
        // 時刻表検索結果をstoreに保存
        this.timeTableResult = timeTableResult
        // 取得結果に合わせてスクロール位置を再調整
        this.resetScrollTop()
        // 時刻表の時間帯一覧を更新
        this.setHourSideBarList()
        this.$store.commit('endLoading')
      }

      // ローディング開始
      this.$store.commit('startLoading')

      // 時刻表取得API実行
      this.$store.dispatch('TimeTableStore/getTimeTables', {
        success: success,
        nodeId: this.timeTableResult.node.id,
        linkId: this.timeTableResult.link.id,
        startDate: this.getFormatYearDate(selectedDate),
      })
    },
    /**
     * 前の画面に遷移
     */
    goToBackPage() {
      // ストアに保持している時刻表に関する情報を初期化
      this.$store.commit('TimeTableStore/initTimeTableState')
      // 時刻表Top画面に遷移
      this.$router.push({name: this.$config.DISPLAY_TIME_TABLE_TOP})
      // Storeをリセット
      this.$store.commit('TimeTableStore/updateSelectedTimeTableId', null)
    },
    /**
     *  「時」の区切りを表示するか
     * @param {*} time 時刻表の時間
     * @param {*} index 時刻表の詳細情報のインデックス
     * @return {*} true:「時」の区切りを表示する
     */
    isShowDividerTime(time, index) {
      const hour = this.getFormatHour(time)
      // 初回または時間が切り替わった場合に「時」の区切りを表示する
      const firstIndex = 0
      const result = index === firstIndex || currentHour != hour
      if (result) {
        currentHour = hour
      }
      return result
    },
    /**
     * お気に入りリボンタップ処理
     */
    onClickFavorite() {
      // 追加時かつ既に最大件数登録されている場合は、ポップアップを表示
      const isRegister = !!this.isRegisterFavorite
      const isAlreadyMax =
        this.$config.MAX_FAVORITE_TIME_TABLE <= this.favoriteList.length
      if (!isRegister && isAlreadyMax) {
        this.isShowFavoriteMaxModal = true
        return
      }

      // お気に入り情報更新(Store/Storage)
      const favoriteData = isRegister
        ? this.deleteFavoriteTimeTable([this.favoriteFormatData])
        : this.addFavoriteTimeTable(this.favoriteFormatData)
      this.$store.commit('TimeTableStore/updateFavoriteTimetable', favoriteData)

      // 時刻表データ更新(Store/DB)
      const favorite = this.favoriteFormatData
      const link = this.timeTableResult.link
      const ope = this.moldingOperations(favorite, link, this.getNow())
      isRegister
        ? this.deleteOperationsToDbAndStore(this.favoriteFormatData.id)
        : this.updateOperationsToDbAndStore(ope)
    },
    /**
     * 現在以降一つ目の情報が画面内に表示されるようにスクロールする
     */
    scrollToCurrentTime() {
      // 時刻表が存在しない場合は終了
      if (this.operations.length === 0) return // eslint-disable-line no-magic-numbers
      // 時刻表から検索条件の日時でIndex取得
      this.nextTimeTableIndex = this.getNextTimeTableIndex()
      // スクロールを設定する
      const refName = this.getTopScrollRefName(this.nextTimeTableIndex)
      this.$refs[refName][0]?.scrollIntoView?.() // eslint-disable-line no-magic-numbers
    },
    /**
     * 時刻表から検索条件の日時(YYYY/MM/DD HH:MM)で直近となるレコードのIndexを取得する
     * @returns 時刻表データで直近となるレコードのIndex（取得できない場合は-1を返却）
     */
    getNextTimeTableIndex() {
      // 検索日が前日の場合は現在の時間が00〜02時であれば、1日後を検索対象とする
      const targetSearchDate = this.isMiddleOfTheNightOfToday()
        ? dayjs(this.searchDate).add(1, 'day') // eslint-disable-line no-magic-numbers
        : dayjs(this.searchDate)
      const targetSearchDateTime = targetSearchDate
        .set('hour', dayjs().hour())
        .set('minute', dayjs().minute())

      return this.operations.findIndex((operation) => {
        return dayjs(operation.time).isSameOrAfter(targetSearchDateTime)
      })
    },
    /**
     * 時刻表データで直近となるレコードのIndexを元にスクロール設定用のRefNameを設定する
     * @param nextTimeTableIndex 現在時刻を基準とした次の時刻表
     * @returns スクロール設定用のRefName
     */
    getTopScrollRefName(nextTimeTableIndex) {
      if (nextTimeTableIndex === NOT_FOUND_NEXT_TIMETABLE_INDEX) {
        // 時刻表から直近となるレコードのIndexが取得できていない場合、スクロールが最下部となるよう設定
        const LAST_INDEX = this.operations.length - 1 // eslint-disable-line no-magic-numbers
        return `operation${LAST_INDEX}`
      }

      const FIRST_INDEX = 0 // eslint-disable-line no-magic-numbers
      if (nextTimeTableIndex === FIRST_INDEX) {
        // 時刻表から直近となるレコードのIndexが最上部の場合、スクロールも最上部となるよう設定
        return `operation${FIRST_INDEX}`
      }

      // 時刻表から直近となるレコードのIndexが最上部以外の場合、スクロールは時刻表の直近1つ前を最上部に設定
      const PREV_INDEX = nextTimeTableIndex - 1 // eslint-disable-line no-magic-numbers
      return `operation${PREV_INDEX}`
    },
    /**
     * 画面更新時にスクロール位置を再調整
     */
    resetScrollTop() {
      this.$nextTick(() => {
        this.scrollToCurrentTime()
      })
    },
    /**
     * 日時取得用のインターバル処理開始
     */
    startDateTimeInterval() {
      this.dateTimeIntervalId = setInterval(() => {
        // 直近の時刻表から取得した時間を再設定
        this.nextTimeTableIndex = this.getNextTimeTableIndex()
      }, 1000) // eslint-disable-line no-magic-numbers
    },
    /**
     * 日時取得用のインターバル処理終了
     */
    endDateTimeInterval() {
      // インターバル処理を終了する
      clearInterval(this.dateTimeIntervalId)
      this.dateTimeIntervalId = null
    },

    /**
     * 検索対象が現在の深夜か判定
     * @return 判定結果
     */
    isMiddleOfTheNightOfToday() {
      const ymdH = 'YYYY-MM-DD HH'
      // 検索日の日時（00:00による日付切り替わりを考慮して翌日で取得）
      const nextDayYmdH = dayjs(this.searchDate)
        .add(1, 'day') // eslint-disable-line no-magic-numbers
        .set('hour', dayjs().hour())

      // 現在日付の00時（深夜の開始範囲）
      const middleOfTheNightStartYmdH = dayjs().startOf('day')
      // 現在日付の02時（深夜の終了範囲）
      const middleOfTheNightEndYmdH = middleOfTheNightStartYmdH.add(
        MIDDLE_OF_THE_NIGHT_END_HOUR,
        'hour'
      )

      // 検索日の翌日00〜02時の範囲か
      return dayjs(nextDayYmdH.format(ymdH)).isBetween(
        middleOfTheNightStartYmdH.format(ymdH),
        middleOfTheNightEndYmdH.format(ymdH),
        'hour',
        '[]'
      )
    },
  },
}
export default TimeTableDetailPage
</script>
