<template>
  <div class="flex w-full pt-3 pb-3 pl-3 pr-4 bg-white rounded-[8px]">
    <!-- アイコン -->
    <!-- 出発地・到着地のモビリティが同じ場合 -->
    <div v-if="isSingleIcon" class="w-10 h-10 mr-1">
      <img :src="getMobilityIcon(start.type)" />
    </div>
    <!-- 出発地・到着地のモビリティが異なる場合 -->
    <div v-else class="w-11 h-11 relative">
      <img
        :src="getMobilityIcon(start.type)"
        class="w-7 h-7 absolute top-0 left-0"
      />
      <img
        :src="getMobilityIcon(goal.type)"
        class="w-7 h-7 absolute bottom-0 right-0"
      />
    </div>
    <div class="pl-3 flex">
      <!-- 経由地設定アイコン -->
      <div
        v-if="isExistVia"
        class="bg-[#E8374A] w-2 h-2 rounded-[50%] my-auto mr-2"
      />
      <!-- 出発地・到着地表示 -->
      <div
        ref="spotArea"
        class="flex my-auto truncate"
        :style="{width: spotAreaWidth}"
      >
        <!-- 出発地 -->
        <div
          ref="startSpot"
          class="text-W5 text-[15px] truncate"
          :style="{minWidth: startWidth, maxWidth: startWidth}"
        >
          {{ start.name }}
        </div>
        <!-- 矢印 -->
        <img src="@/assets/Arrow_Right_Gray.svg" class="w-3 mx-2" />
        <!-- 目的地 -->
        <div
          ref="goalSpot"
          class="text-W5 text-[15px] truncate"
          :style="{maxWidth: goalWidth}"
        >
          {{ goal.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 乗り換え検索トップ画面の検索履歴カード
 */
const ConditionsHistoryCard = {
  name: 'ConditionsHistoryCard',
  props: {
    // 出発地点情報
    start: {
      type: Object,
      require: true,
    },
    // 目的地点情報
    goal: {
      type: Object,
      require: true,
    },
    // 経由地点情報
    via: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      // 地点名部分の横幅
      spotAreaWidth: undefined,
      // 出発地点名部分の横幅
      startWidth: undefined,
      // 目的地点名部分の横幅
      goalWidth: undefined,
    }
  },
  mounted() {
    // 履歴カード内の表示領域を設定
    this.setHistoryCardIndicatedArea()
  },
  computed: {
    /**
     * アイコンが一つかどうかを判定する
     * @return Boolean true:出発地と目的地の駅種別が同じ
     */
    isSingleIcon() {
      return this.start.type == this.goal.type
    },
    /**
     * 経由地が存在するかどうか
     * @return Boolean true:経由地あり
     */
    isExistVia() {
      // eslint-disable-next-line no-magic-numbers
      return this.via != null && 0 < this.via.length
    },
  },
  methods: {
    /**
     * 駅種別に応じてアイコンを出し分ける
     * @param {String} stationType 駅種別
     * @return アイコン
     */
    getMobilityIcon(stationType) {
      switch (stationType) {
        // 電車
        case this.$config.STATION_TYPE.STATION:
          return require('@/assets/transfer/Icon_Train.svg')
        // バス
        case this.$config.STATION_TYPE.BUSSTOP:
        case this.$config.STATION_TYPE.SHUTTLE_BUSSTOP:
        case this.$config.STATION_TYPE.HIGHWAY_BUSSTOP:
          return require('@/assets/transfer/Icon_Bus.svg')
        // 飛行機
        case this.$config.STATION_TYPE.AIRPORT:
          return require('@/assets/transfer/Icon_Flight.svg')
        // フェリー
        case this.$config.STATION_TYPE.PORT:
          return require('@/assets/transfer/Icon_Ferry.svg')
        default:
          break
      }
    },
    /**
     * 履歴カード内の表示領域設定
     */
    setHistoryCardIndicatedArea() {
      // 地点名部分の横幅の設定
      const spotAreaWidth = this.setSpotAreaWidth()

      // 出発地名、目的地名の表示領域幅の設定
      this.setSpotAreaNameWidth(spotAreaWidth)
    },
    /**
     * 履歴カード内の地点名部分の横幅の設定
     * @return {Number} 地点名部分の横幅
     */
    setSpotAreaWidth() {
      // 画面幅(地点名表示領域以外の幅)
      const diplayWitdh = 148
      // 経由地アイコン幅
      const waypointIconWidth = 16

      // 画面幅-左右余白(64)-カード内左余白(12)-アイコン幅(44)-アイコン右余白(12)-経由地アイコン幅(あり16,なし0)-カード内右余白(16)
      let spotAreaWidthNumber =
        window.innerWidth -
        diplayWitdh -
        /* eslint-disable-next-line no-magic-numbers */
        (this.isExistVia ? waypointIconWidth : 0)
      // 画面幅(地点名表示領域以外の幅)を保持
      this.spotAreaWidth = `${spotAreaWidthNumber}px`
      return spotAreaWidthNumber
    },
    /**
     * 履歴カード内の出発地名、目的地名の表示領域幅の設定
     * @param {Number} 地点名部分の横幅
     */
    setSpotAreaNameWidth(spotAreaWidth) {
      // 矢印アイコンの左右にある空白幅
      const arrowSpace = 28

      if (spotAreaWidth < this.$refs.spotArea.offsetWidth) {
        // 横幅が表示領域を超えている場合
        // 一つの地点の横幅計算
        // eslint-disable-next-line no-magic-numbers
        let spotWidth = (spotAreaWidth - arrowSpace) / 2
        const currentStartWidth = this.$refs.startSpot.offsetWidth
        if (currentStartWidth < spotWidth) {
          // スタート地点が一つの地点の最大横幅に満たない場合
          // 元々持っている横幅を最小横幅とする。
          // ただしそのままの値とすると三点リーダとなるため、+5しておく
          // eslint-disable-next-line no-magic-numbers
          this.startWidth = `${currentStartWidth + 5}px`
        } else {
          // 出発地点の横幅が一つの地点の最大横幅を超えている時は、
          // 出発地点、目的地の両方の最大横幅を指定する
          this.startWidth = `${spotWidth}px`
          this.goalWidth = `${spotWidth}px`
        }
      }
    },
  },
}
export default ConditionsHistoryCard
</script>
<style scoped></style>
