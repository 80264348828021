import Util from '@/mixins/util'
import MenuUtil from '@/mixins/menuUtil'
import TimeTableUtil from '@/mixins/timeTableUtil'
import NativeUtil from '@/mixins/nativeUtil'
import ArrangementUtil from '@/mixins/arrangementUtil.js'
import dayjs from 'dayjs'
import {isAuthenticated} from '@/auth/AuthorizationInteractor.js'
dayjs.locale('ja') // 日付の形式を日本のものに変更
import {constant} from '@/const/const.js'
import {getSearchOptionsFromLocalStorage} from '@/utils/localStorageUtil'

const STORE_DB_SETTINGS = {
  tableName: constant.STORE_TABLE_NAME,
  storeName: constant.STORE_TABLE_NAME,
  keyPath: constant.STORE_KEY,
}

export default {
  data() {
    return {
      deviceToken: '', // デバイストークン
    }
  },
  mixins: [Util, MenuUtil, TimeTableUtil, NativeUtil, ArrangementUtil],
  methods: {
    /**
     * デバイストークン取得処理
     */
    getDeviceToken(completion) {
      // webViewかどうか
      const isWebView = NativeUtil.methods.isWebView()

      if (isWebView) {
        // webViewの場合はデバイストークン取得
        this.linkNativeCallback('getDeviceToken')

        window.fetchDeviceTokenAndInitData = (fetchedDeviceToken) => {
          this.deviceToken = fetchedDeviceToken
          if (completion) {
            completion()
          }
        }
      } else {
        // webView以外の場合は後続処理を実行
        if (completion) {
          completion()
        }
      }
    },
    /**
     * 初期化処理
     */
    async initData() {
      // フッタータブを取得
      this.getFooterTab()
      // 電車バス用移動手段の設定
      this.setOptionsOfSearchConditions()
      // メニューのお気に入りを取得
      this.getMenuFavoriteFromStorage()
      // 時刻表のお気に入りを取得
      this.getFavoriteTimeTableFromStorage()
      // ローカルストレージからアカウント取得・作成エラーフラグ削除
      localStorage.removeItem(this.$config.USER_ACCOUNT_DEFECT)
      // ローカルストレージから配信されなくなった古い既読情報/表示済み情報を排除
      this.removeOldMessageFromStorage()
      // お知らせ一覧情報を取得
      this.$store.dispatch('getNotificationMessages', {
        // 取得成功時とエラーレスポンス時は何も行わない
        error: () => {},
        failed: () => {},
      })

      // 必要な項目の取得が終われば、初回起動処理を実施
      this.startUp()
    },
    /**
     * 初回起動処理
     */
    async startUp() {
      let appInfo = this.getAppInfo()

      const icon = await this.getUserIcon(this).catch(() => null)
      if (icon) {
        appInfo.icon = icon
      }

      // アイコンサイズが未設定の場合は、デフォルトサイズ(中)を設定
      if (!appInfo.size) {
        appInfo.size = constant.USER_ICON_SIZE_LIST.MIDDLE.KEY
      }

      // ユーザー情報を保持して次ページに遷移
      this.$store.commit('updateUserAppInfo', appInfo)
      this.getOriginalStoreFromIndexedDB()
    },
    /**
     * ユーザー情報登録
     */
    createUser() {
      const vm = this
      return new Promise((resolve, reject) => {
        const success = () => {
          resolve()
        }
        const failed = () => {
          reject()
        }
        const error = () => {
          reject()
        }
        const deviceToken = vm.deviceToken
        vm.$store.dispatch('createUser', {
          success: success,
          failed: failed,
          error: error,
          deviceToken: deviceToken,
        })
      })
    },
    /**
     * アプリ内情報の登録処理
     * @param {String} userName ユーザー名
     * @param {String} userIcon ユーザーアイコン(base64)
     * @param {String} iconSizeKey ユーザーアイコンサイズkey(LARGE/MIDDLE/SMALL)
     */
    registerAppInfo(userName, userIcon, iconSizeKey) {
      // ユーザー情報を保持
      const appInfo = {
        name: userName,
        icon: userIcon,
        size: iconSizeKey,
      }
      this.updateAppInfo(this, appInfo).then((updatedData) => {
        this.$store.commit('updateUserAppInfo', updatedData)
        this.goToAppropriatePage()
      })
    },
    /**
     * ユーザー情報取得
     */
    getUser() {
      const deviceToken = this.deviceToken
      return new Promise((resolve, reject) => {
        // 成功時処理
        const success = () => {
          resolve()
        }
        // 失敗時処理
        const failed = (e) => {
          reject(e)
        }
        // エラー時処理
        const error = (e) => {
          reject(e)
        }

        // ユーザー情報取得処理
        this.$store.dispatch('getUser', {
          success: success,
          failed: failed,
          error: error,
          deviceToken: deviceToken,
        })
      })
    },
    /**
     * IndexedDBからStoreを取り出す
     */
    getOriginalStoreFromIndexedDB() {
      // 認証必須画面に遷移するかを判定する
      const storageNextPage = this.getLocalStorage(constant.NEXT_PAGE_IN_AUTH)
      localStorage.removeItem(constant.NEXT_PAGE_IN_AUTH)

      const success = (result) => {
        if (result) {
          // IndexedDBにStore情報が堆肥されていれば、復元を行う
          const parsedResult = JSON.parse(result.value)
          this.restoreStoreFromIndexedDB(parsedResult)
          this.disposeStoreFromIndexedDB()
        }
        this.goNextPage(storageNextPage)
      }

      // データ取得処理を呼び出し
      this.$store.dispatch('IndexedDbStore/getData', {
        success: success,
        setting: STORE_DB_SETTINGS,
        key: this.$config.STORE_KEY,
      })
    },
    /**
     * IndexedDBからStoreデータを復旧させる
     * @param {*} storageData IndexedDBにあるStoreデータ
     */
    restoreStoreFromIndexedDB(storageData) {
      this.$store.commit('restoreStore', storageData.root)
      this.$store.commit('MenuStore/restoreStore', storageData.menu)
      this.$store.commit('RouteStore/restoreStore', storageData.route)
      this.$store.commit('TimeTableStore/restoreStore', storageData.timeTable)
      this.$store.commit(
        'MobilityReservationStore/restoreStore',
        storageData.mobility
      )
    },
    /**
     * IndexedDBからStoreデータを削除する
     */
    disposeStoreFromIndexedDB() {
      this.$store.dispatch('IndexedDbStore/deleteData', {
        setting: STORE_DB_SETTINGS,
        key: this.$config.STORE_KEY,
      })
    },
    /**
     * 次画面に遷移する
     */
    async goNextPage(storageNextPage) {
      const isLogin = storageNextPage != null ? await isAuthenticated() : false
      const isAuthenticatedPageJump = storageNextPage && isLogin

      // フッター表示
      this.$store.commit('updateShowFooter', true)
      // 表示させるページを取得する
      let nextPage = this.getNextPageId(
        this.$store.state.selectedFooterTab,
        this
      )
      const reservedOrUsingOfMobilityType =
        this.$store.getters[
          'MobilityReservationStore/reservedOrUsingOfMobilityType'
        ]

      // タクシーを予約処理中・または探している最中か
      const isReservingOrFindingTaxiStatus =
        this.$store.getters[
          'MobilityReservationStore/isReservingOrFindingTaxiStatus'
        ]

      // 自転車を予約している最中か
      const isReservingBicycleStatus =
        this.$store.getters['MobilityReservationStore/isReservingBicycleStatus']

      // 手配TOP画面から何かしらのモビリティを手配しているか
      const isReservingFromArrangementTop =
        nextPage === this.$config.DISPLAY_ARRANGEMENT_TOP &&
        reservedOrUsingOfMobilityType !== this.$config.USING_MOBILITY_TYPE.NONE

      if (isReservingFromArrangementTop) {
        // 前回表示が手配機能画面でタクシーor自転車手配中の状態の場合、スポットタブに切り替えてルート検索Topに遷移
        const searchRouteTabId = this.$config.FOOTER_LIST.find((footer) => {
          return footer.name == 'spot'
        }).id

        // タクシーを手配しており、現在タクシーを予約処理中・または探している最中の場合
        const isInReservingTaxi =
          reservedOrUsingOfMobilityType ===
            this.$config.USING_MOBILITY_TYPE.TAXI &&
          isReservingOrFindingTaxiStatus

        // 自転車を手配しており、現在手配処理中の場合
        const isInReservingBicycle =
          reservedOrUsingOfMobilityType ===
            this.$config.USING_MOBILITY_TYPE.BICYCLE && isReservingBicycleStatus

        // タクシー手配中にアプリキルされた場合
        // タクシー手配中は、再起動時にタクシー手配確定画面が表示されることが期待値となる
        // ※アプリキルでタクシーキャンセルされるとユーザーに誤認させないため
        // また、他モビリティ予約導線に乗って欲しくないためルートTOP画面を遷移元として設定
        if (isInReservingTaxi) {
          this.$store.commit(
            'MobilityReservationStore/updateScreenTransitionSource',
            {
              displayName: this.$config.DISPLAY_SEARCH_TOP,
              footerId: searchRouteTabId,
            }
          )

          this.$router.push({
            name: this.$config.DISPLAY_TAXI_RESERVATION_COMPLETED,
          })
          return
        }

        // 自転車手配中にアプリキルされた場合
        // また、他モビリティ予約導線に乗って欲しくないためルートTOP画面を遷移元として設定
        if (isInReservingBicycle) {
          this.$store.commit(
            'MobilityReservationStore/updateScreenTransitionSource',
            {
              displayName: this.$config.DISPLAY_SEARCH_TOP,
              footerId: searchRouteTabId,
            }
          )

          this.$router.push({
            name: this.$config.DISPLAY_BICYCLE_RESERVATION_CONFIRMED,
          })
          return
        }

        this.$store.commit('updateFooterTab', searchRouteTabId)
        this.$router.push({name: this.$config.DISPLAY_SEARCH_TOP})
      } else if (isAuthenticatedPageJump) {
        // 認証必須ページに遷移
        this.$router.push({name: storageNextPage})
      } else if (nextPage) {
        // 前回利用したTopページに遷移
        this.$router.push({name: nextPage})
      } else {
        // 上記に当てはまらない場合、ルート検索Topに遷移
        this.$router.push({name: this.$config.DISPLAY_SEARCH_TOP})
      }
    },
    /**
     * suicaトップ画面に遷移する
     */
    goSuicaTop() {
      // SuicaStoreに保持する遷移元画面を更新
      this.$store.commit('SuicaStore/updateScreenTransitionSource', {
        displayName: this.$config.DISPLAY_SEARCH_TOP,
        footerId: this.$config.FOOTER_ID_SPOT,
      })

      // メニュータブに更新
      this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_MENU)

      // Suicaトップ画面に遷移する
      this.$router.push({name: this.$config.DISPLAY_SUICA_TOP})
    },
    /**
     * アイコン設定後の遷移先を決定し、遷移する処理
     */
    goToAppropriatePage() {
      // ローカルストレージからアカウント取得・作成エラーフラグ削除
      localStorage.removeItem(this.$config.USER_ACCOUNT_DEFECT)
      // ローカルストレージから配信されなくなった古い既読情報/表示済み情報を排除
      this.removeOldMessageFromStorage()
      // お知らせ一覧情報を取得
      this.$store.dispatch('getNotificationMessages', {
        // 取得成功時とエラーレスポンス時は何も行わない
        error: () => {},
        failed: () => {},
      })

      // Androidの場合、suicaトップ画面に遷移
      if (NativeUtil.methods.isAndroidWebView()) {
        this.goSuicaTop()
      } else {
        this.goNextPage()
      }
    },
    /**
     * 画面読み込み時にローカルストレージから表示中のフッター情報を取得
     */
    getFooterTab() {
      // ローカルストレージ上にフッター情報があることを確認
      let footer = this.getLocalStorage(this.$config.FOOTER_TAB)
      if (footer != null) {
        this.$store.commit('updateFooterTab', footer)
      }
    },
    /**
     * 画面読み込み時にローカルストレージから時刻表のお気に入り情報を取得
     */
    async getFavoriteTimeTableFromStorage() {
      // Storageからお気に入り時刻表を取得し、Storeに保持
      const storageFavoriteList = this.getFavoriteTimeTable()
      const favoriteList = storageFavoriteList || []
      this.updateFavoriteTimetablesToStore(favoriteList)

      // アプリ再起動後の画面が時刻表である場合、取得処理不要
      const isShowNowTimetableScreen =
        this.getLocalStorage(constant.FOOTER_TAB) ===
        constant.FOOTER_ID_TIME_TABLE
      if (isShowNowTimetableScreen) return

      // 時刻表を最新化する
      const ope = this.$store.state.TimeTableStore.favoriteOperations
      await this.modernizeFavoriteOperations(favoriteList, ope)
    },
    /**
     * Storeに取得したお気に入り時刻表一覧を保存する
     */
    updateFavoriteTimetablesToStore(favoriteList) {
      this.$store.commit('TimeTableStore/updateFavoriteTimetable', favoriteList)
    },
    /**
     * 画面読み込み時にローカルストレージからメニューのお気に入り情報を取得
     */
    getMenuFavoriteFromStorage() {
      const menuFavoriteList = this.getFavoriteSpotList()
      if (menuFavoriteList != null) {
        this.$store.commit('MenuStore/updateFavoriteSpots', menuFavoriteList)
      }
    },
    /**
     * 画面読み込み時にサーバーからモビリティ手配情報を取得
     */
    async getMobilityInfo() {
      const footerTab = this.getLocalStorage(this.$config.FOOTER_TAB)
      if (footerTab === this.$config.FOOTER_ID_ARRANGEMENT) {
        // 初期表示タブが手配の場合はモビリティ手配情報取得が完了するのを待つ
        return new Promise((resolve, reject) => {
          // モビリティ手配情報取得
          this.$store.dispatch('MobilityReservationStore/getMobilityInfo', {
            success: resolve,
            failed: reject,
            error: reject,
          })
        })
      } else {
        // 初期表示タブが手配以外の場合は取得開始して結果を待たない
        const updateInProgress =
          'MobilityReservationStore/updateInProgressGetMobilityInfo'
        this.$store.commit(updateInProgress, true)
        const success = () => {
          this.$store.commit(updateInProgress, false)
        }
        const failed = () => {
          this.$store.commit(updateInProgress, false)
          throw new Error()
        }
        const error = (e) => {
          this.$store.commit(updateInProgress, false)
          throw e
        }
        this.$store.dispatch('MobilityReservationStore/getMobilityInfo', {
          success,
          failed,
          error,
        })
      }
    },
    /**
     * ローカルストレージ内の既読情報/表示済み情報から、配信されなくなった古い情報を排除する。
     * ※古い情報判定は、配信終了日から1ヶ月以上経過しているものとする
     */
    removeOldMessageFromStorage() {
      // 既読済み通知IDを取得
      const KEY_READ = this.$config.READ_NOTIFICATION_MESSAGES
      const readMsgIds = this.getLocalStorage(KEY_READ) || []
      // 一覧表示済み通知IDを取得
      const KEY_DISPLAY = this.$config.DISPLAY_NOTIFICATION_MESSAGES
      const displayMsgIds = this.getLocalStorage(KEY_DISPLAY) || []

      // 配信終了日が保持期間を過ぎているお知らせは、既読情報から排除する
      // ※通知ID：{配信終了日(YYYY-MM-DD)}#{UUID}
      // ※保持期間：配信終了後1ヶ月間
      const limitDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD') // eslint-disable-line no-magic-numbers
      const newReadMsgIds = readMsgIds.filter((id) => limitDate < id)
      const newDisplayMsgIds = displayMsgIds.filter((id) => limitDate < id)

      // 各ローカルストレージを更新
      this.setLocalStorage(KEY_READ, newReadMsgIds)
      this.setLocalStorage(KEY_DISPLAY, newDisplayMsgIds)
    },
    /**
     * バス電車用の移動手段情報設定(ローカルストレージからの復帰)
     */
    setOptionsOfSearchConditions() {
      // ローカルストレージに値があれば復帰する
      this.$store.commit('updateSearchOptions', {
        key: 'searchOptions',
        value: getSearchOptionsFromLocalStorage(),
      })
    },
  },
}
