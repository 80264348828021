<template>
  <!-- 時刻表駅名入力画面 -->
  <div id="timeTableSuggest" class="h-full w-full" ref="timeTableSuggest">
    <TextForm
      class="searchText pt-5 px-5"
      :text="word"
      :placeholder="'駅・バス停・空港・港を入力'"
      @input-text="onchangeText"
      @click-back-button="goToBackPage()"
      :maxlength="'50'"
      ref="textForm"
    />
    <div id="timeTableContents" class="mt-5 text-left">
      <div class="px-5" :style="scrollStyle">
        <div v-if="isEditing" class="h-full">
          <!-- 入力時 -->
          <StationSuggestList
            :stationList="stations"
            @click-station="completeForm($event)"
          />
          <div class="h-5 w-full" />
        </div>
        <div v-else class="h-full">
          <!-- 未入力時 -->
          <div class="ml-1">
            <!-- 履歴 -->
            <div class="text-W6 text-[13px] leading-[13px] pb-5">履歴</div>
            <StationHistoryList
              :stationHistoryList="stationHistoryList"
              @click-history-station="completeForm($event)"
            />
            <div class="pt-5 separate-border" />
            <div class="h-5 w-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 時刻表駅名入力画面
 * ＜概要＞
 * 時刻表の駅名を入力することで、駅の候補を表示する。
 * 検索した候補を選択すると、該当駅の時刻表取得を行い、時刻表方面選択画面へ遷移する
 */
import Util from '@/mixins/util'
import TextForm from '@/components/TextForm.vue'
import StationSuggestList from '@/components/transfer/StationSuggestList.vue'
import StationHistoryList from '@/components/transfer/StationHistoryList.vue'
import NativeUtil from '@/mixins/nativeUtil'

const MAX_HISTORY_NUM = 10
const LOAD_SPOT_API_SEC = 100

// 駅名検索の文字数制限
const SEARCH_TRANSFER_LIMIT_MIN = 1
const SEARCH_TRANSFER_LIMIT_MAX = 50

const TimeTableSuggestPage = {
  name: 'TimeTableSuggestPage',
  components: {
    TextForm,
    StationSuggestList,
    StationHistoryList,
  },
  mixins: [Util, NativeUtil],
  data() {
    return {
      word: '',
      stations: [],
      errMsg: '',
      timer: 0,
      stationHistoryList: [],
      isEditing: false, // true:入力中、false:未入力
    }
  },
  computed: {
    /**
     * キーボード表示判定（iOSのみ判定する）
     */
    isShowKeyboard() {
      if (this.isIosWebView()) {
        return this.$store.state.isShowKeyboard
      } else {
        return false
      }
    },
    /**
     * スクロールエリアのスタイル
     */
    scrollStyle() {
      if (this.isShowKeyboard) {
        // キーボード表示時は内部スクロールが表示されないように全表示
        return {
          'overflow-y': 'visible',
          height: '100%',
        }
      } else {
        // キーボード非表示時は特定の範囲内でスクロールされるようにする
        return {
          'overflow-y': 'auto',
          height: 'calc(100vh - 100px)',
        }
      }
    },
  },
  watch: {
    isShowKeyboard: {
      handler(newVal) {
        // キーボード非表示時にスクロールをリセットする
        if (!newVal) {
          this.$refs.timeTableSuggest.scrollTo({top: 0})
        }
      },
    },
  },
  created() {
    //ローカルストレージから履歴取得
    this.getHistory(this.$config.TIME_TABLE_HISTORY_STORAGE_KEY)
    // フッター部を非表示にする
    this.$store.commit('updateShowFooter', false)
  },
  mounted() {
    // 入力フォームにフォーカスを当てる
    this.$refs.textForm.focusInputForm()
  },
  beforeUnmount() {
    // フッター部を表示する
    this.$store.commit('updateShowFooter', true)
  },
  methods: {
    /**
     * 入力文字の内容を用いて駅名検索を行う
     * @param {String} value 検索文字
     */
    onchangeText(value) {
      this.word = value
      this.errMsg = ''
      clearTimeout(this.timer)

      //入力中かの判定
      this.isEditing = value != ''

      // 駅名検索対象ではない場合、何もしない
      if (!this.validSearchTransfer()) {
        return
      }
      const vm = this
      const success = (stations) => {
        vm.stations = stations || []
      }
      const failed = () => {
        // 取得できなかった際は何も表示しない
        vm.stations = []
        throw new Error()
      }
      const error = (e) => {
        // エラーが発生した際は何も表示しない
        vm.stations = []
        throw e
      }
      this.timer = setTimeout(() => {
        vm.$store.dispatch('TimeTableStore/getStationInfo', {
          success: success,
          failed: failed,
          error: error,
          word: vm.word,
        })
      }, LOAD_SPOT_API_SEC)
    },
    /**
     * 駅名検索の有効文字かどうか
     * 有効文字：文字が２字以上５０文字以下
     */
    validSearchTransfer() {
      return (
        SEARCH_TRANSFER_LIMIT_MIN <= this.word.length &&
        this.word.length <= SEARCH_TRANSFER_LIMIT_MAX
      )
    },
    /**
     * ストレージに履歴一覧を反映、路線情報を保持した上、方面選択画面に遷移する
     * @param {Object} value 選択された駅名の情報
     */
    completeForm(value) {
      // ローカルストレージに格納
      this.setHistory(this.$config.TIME_TABLE_HISTORY_STORAGE_KEY, value)

      // 方面選択画面で表示する用の情報を保持
      this.$store.commit('TimeTableStore/updateSelectTimeTableStation', value)

      // 時刻表取得処理
      this.$router.push({name: 'TimeTableDirection'})
    },
    /**
     *ローカルストレージに履歴一覧を保存
     */
    setHistory(key, value) {
      // 重複したデータかどうかを判別
      const checkDuplicateData = (history) => {
        return (
          history.name == value.name &&
          history.addressName == value.addressName &&
          history.id == value.id &&
          history.ruby == value.ruby &&
          history.types == value.types
        )
      }
      this.stationHistoryList = this.stationHistoryList.filter((history) => {
        // 重複していないデータのみに絞り込み
        return checkDuplicateData(history) === false
      })

      //履歴を追加
      this.stationHistoryList.unshift(value)
      //最新の10件以内になるように限定
      while (MAX_HISTORY_NUM < this.stationHistoryList.length) {
        this.stationHistoryList.pop()
      }

      //ローカルストレージに保存
      const parsed = JSON.stringify(this.stationHistoryList)
      localStorage.setItem(key, parsed)
    },

    /**
     *ローカルストレージから履歴一覧を取得
     */
    getHistory(key) {
      if (localStorage.getItem(key)) {
        try {
          const storageHistory = JSON.parse(localStorage.getItem(key))
          this.stationHistoryList = this.filterValidateFavorite(storageHistory)
        } catch (e) {
          //jsonに問題がある場合は履歴を削除
          localStorage.removeItem(key)
        }
      }
    },
    /**
     * 検索履歴の駅情報に不足がないかチェックし、問題ない履歴のみに絞る
     * (次画面で履歴情報を用いて表示する必要があるため)
     * @param {Array} favorites 評価前の検索履歴一覧
     * @return {Array} 評価後の検索履歴一覧
     */
    filterValidateFavorite(storageHistory) {
      return storageHistory.filter((data) => {
        // 方面選択画面の描画に必要な値が揃っているかを判定
        return (
          data.nodeId &&
          data.name &&
          data.ruby &&
          data.types &&
          data.addressName &&
          // 全ての路線情報に必要な値が揃っているかどうか
          data.links.every((link) => {
            return (
              link.color && link.id && link.name && link.type && link.direction
            )
          })
        )
      })
    },
    goToBackPage() {
      this.$router.push({name: 'TimeTableTop'})
    },
  },
}
export default TimeTableSuggestPage
</script>

<style scoped>
.separate-border {
  border-bottom: 0.5px solid #dbdee3;
}
</style>
