<template>
  <Modal :isShowCloseButton="false" :isModalCenter="true" modalPaddingX="20px">
    <div class="px-5 py-6">
      <div class="text-W5 text-[17px] leading-[25.5px] mt-3">
        <div v-if="isRevisionMode">サービス利用規約の変更</div>
        <div v-else>サービス利用規約</div>
      </div>
      <div class="text-W3 text-[15px] leading-[23px] text-left mt-4">
        <div v-if="isRevisionMode">
          <p>NAMOの利用規約が変更されました。</p>
          <p class="mt-6">
            以下のリンクからご確認および再同意の上、ご利用をお願いいたします。
          </p>
        </div>
        <div v-else>
          <p>
            NAMOのご利用にあたり、利用規約及びプライバシーポリシーに同意いただく必要がございます。
          </p>
          <p class="mt-6">
            以下のリンクからご確認および同意の上、ご利用をお願いいたします。
          </p>
        </div>
      </div>
      <div class="flex justify-center items-center mt-6">
        <BaseLink label="利用規約" :url="agreementUrl" class="mr-6" />
        <BaseLink label="プライバシーポリシー" :url="privacyPolicyUrl" />
      </div>
      <BaseCheckBox
        class="mx-auto mt-6"
        v-model="isApplyPolicy"
        label="利用規約とプライバシーポリシーを確認の上、同意する"
      />
      <TheButton
        class="mx-auto mt-6"
        text="同意する"
        :isDisabled="isAgreeDisabled()"
        @click-button="agree()"
      />
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/Modal.vue'
import BaseLink from '../atoms/BaseLink.vue'
import BaseCheckBox from '../atoms/BaseCheckBox.vue'
import TheButton from '../atoms/TheButton.vue'
const NamoTermModal = {
  name: 'NamoTermModal',
  components: {
    Modal,
    BaseLink,
    BaseCheckBox,
    TheButton,
  },
  emits: ['agree-term'],
  data() {
    return {
      isApplyPolicy: false, // 同意フラグ
      agreementUrl: process.env.VUE_APP_NAMO_AGREEMENT_URL, // 利用規約URL
      privacyPolicyUrl: process.env.VUE_APP_NAMO_PRIVACY_POLICY_URL, // プライバシーポリシーURL
    }
  },
  props: {
    // 再同意モードか否か
    isRevisionMode: {
      type: Boolean,
      require: true,
    },
  },
  methods: {
    /**
     * 「同意する」ボタンの非活性状態
     */
    isAgreeDisabled() {
      return !this.isApplyPolicy
    },
    /**
     * 「同意する」押下時の処理
     */
    agree() {
      this.$emit('agree-term')
    },
  },
}
export default NamoTermModal
</script>
<style scoped></style>
