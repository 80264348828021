<template>
  <!-- お気に入りカード -->
  <!-- 大枠 -->
  <div
    id="TimeTableFavoriteCard"
    class="w-full pl-[13px] pr-[9px] py-[17px] bg-white rounded-[10px] shadow-normal border-[3px]"
    :class="isCheckedFavoriteCard"
    @click="onClickFavorite()"
  >
    <div class="flex">
      <!-- 編集チェックアイコン -->
      <label
        v-if="isEditMode"
        class="check-box ml-1 mr-3 my-2"
        :for="uniqueKey"
      >
        <input
          type="checkbox"
          :id="uniqueKey"
          v-model="checked"
          @change="onChangeChecked()"
        />
        <span class="check-text items-center flex"></span>
      </label>
      <!-- 種別アイコン -->
      <img id="icon" class="m-auto w-9 h-9" :src="getIcon()" />
      <!-- 各種表示 -->
      <div class="flex-1 self-center ml-4 my-auto h-[37px] truncate">
        <!-- 駅名 -->
        <div
          class="text-left block text-W7 text-[16px] leading-[17px] truncate"
        >
          {{ name }}
        </div>
        <!-- 路線名/方面名 -->
        <div
          class="mt-2 text-left text-W4 text-[13px] leading-[13px] text-gray500 truncate"
        >
          {{ linkDirectionName }}
        </div>
      </div>
      <!-- 3本線アイコン -->
      <img
        v-if="isEditMode"
        class="ml-4 my-2.5 w-4 h-4 handle"
        src="@/assets/ThreeLines.svg"
      />
    </div>
    <div class="flex w-full mt-5 h-[72px]">
      <div class="width-calc pr-3">
        <!-- 時刻表 -->
        <div
          v-if="!isExistTimeTable"
          class="text-W4 text-[13px] bg-gray200 text-gray400 h-[72px] rounded-[8px]"
        >
          <!-- 時刻表が無い場合 -->
          <div class="pl-3 text-left leading-[72px]">
            時刻表を取得できませんでした
          </div>
        </div>
        <div v-else>
          <div
            class="flex mb-2 last:mb-0 h-8"
            v-for="operation in showingOperation"
            :key="operation.id"
          >
            <div
              class="flex items-center px-3 py-2 text-W7 text-[15px] leading-[16px] bg-gray200 rounded-[10px]"
            >
              <!-- 時刻 -->
              {{ getFormatTimeToHHmm(operation.time) }}
            </div>
            <div
              class="flex items-center w-full px-3 pl-2 text-[13px] leading-[13px] ml-1.5 bg-gray200 rounded-[10px] truncate"
            >
              <!-- 種別 -->
              <span
                class="mr-2 text-W7"
                :style="{color: getOperationColor(operation)}"
              >
                {{ operation.type }}
              </span>
              <!-- 当駅始発 -->
              <img
                v-if="isShowDepartureLavel(operation)"
                class="mr-2 w-8 h-5"
                src="@/assets/Departure.svg"
              />
              <div v-if="operation.destination">
                <!-- 罫線 -->
                <span
                  class="w-[1px] h-2.5 border-l-[1px] border-gray400"
                ></span>
                <!-- 行き先 -->
                <span class="ml-2 truncate">
                  {{ operation.destination.name }}行
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右矢印アイコン -->
      <div class="w-3 my-auto">
        <img
          v-if="!isEditMode"
          class="w-2.5 h-2.5"
          src="@/assets/Icon_Right_gray.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * お気に入り時刻表カードコンポーネント
 */
import Util from '@/mixins/util'
import timeTableUtil from '@/mixins/timeTableUtil'
import dayjs from 'dayjs'
import * as mobilityUtil from '@/utils/mobilityUtil'

const TimeTableFavoriteCard = {
  name: 'TimeTableFavoriteCard',
  mixins: [Util, timeTableUtil],
  emits: ['click-favorite', 'check-favorite', 'request-get-timetable'],
  props: {
    // お気に入り情報
    favoriteTimeTable: {
      type: Object,
      require: true,
    },
    // 編集モードフラグ
    isEditMode: {
      type: Boolean,
      require: true,
    },
    // 時刻表情報
    operations: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      checked: false, // 編集中のチェック状況
      showingOperation: [],
      timeoutId: null,
    }
  },
  watch: {
    /**
     * 編集モード状態の監視
     */
    isEditMode: function () {
      // 編集モード状態が変わったら、チェック状態は初期化する
      this.checked = false
    },
    /**
     * propsで渡される時刻表データを監視
     */
    operations: function () {
      // 更新されたら画面を更新する
      this.extractDisplayOperation()
    },
  },
  created() {
    // 表示するデータを抽出する
    this.extractDisplayOperation()
    this.setDisplayUpdateTimer()
  },
  beforeUnmount() {
    // setTimeoutを解除する
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
  },
  computed: {
    /**
     * ユニークキーを取得する
     */
    uniqueKey() {
      return this.favoriteTimeTable.id
    },
    /**
     * 種別を取得する
     */
    type() {
      return this.favoriteTimeTable.type
    },
    /**
     * 駅名を取得する
     */
    name() {
      return this.favoriteTimeTable.name
    },
    /**
     * 路線名/方面名を取得する
     */
    linkDirectionName() {
      return `${this.favoriteTimeTable.linkName}【${this.favoriteTimeTable.directionName}方面】`
    },
    /**
     * チェック時、お気に入りカードの枠および影を青色に変更する
     */
    isCheckedFavoriteCard() {
      return this.checked
        ? 'shadow-[#009CE566] border-[#009CE566]' // 透明度約40%の青
        : 'border-white'
    },
    /**
     * 時刻表データの有無を確認する
     */
    isExistTimeTable() {
      return 0 < this.showingOperation.length // eslint-disable-line no-magic-numbers
    },
  },
  methods: {
    /**
     * 表示する時刻表データを抽出する
     */
    extractDisplayOperation() {
      // 時刻表データが無い、取得したデータにて現在時刻から先のデータが存在しない
      if (!this.operations) {
        return
      }
      const nextIndex = this.findNextOperationIndex(this.operations)
      if (nextIndex === -1 /*eslint-disable-line no-magic-numbers*/) {
        return
      }

      // 表示データを更新する
      this.showingOperation = this.operations.slice(nextIndex, nextIndex + 2) // eslint-disable-line no-magic-numbers
      // データがないorデータが2件未満の場合は、時刻表再取得を親に要求する
      // eslint-disable-next-line no-magic-numbers
      if (this.showingOperation.length < 2) {
        this.$emit('request-get-timetable')
      }
    },
    /**
     * 表示を更新するタイマーを設定する
     */
    setDisplayUpdateTimer() {
      /* eslint-disable no-magic-numbers */
      let intervalSecond = 60 * 60 * 1000
      // 時刻表データが無い、取得したデータにて現在時刻から先のデータが存在しない
      if (this.operations) {
        const nextIndex = this.findNextOperationIndex(this.operations)
        if (nextIndex !== -1) {
          const currentTime = dayjs()
          // 次の時刻表の1分後に更新する
          const targetTime = dayjs(this.operations[nextIndex].time).add(1, 'm')
          // 対象の時刻が現在時刻より後の場合、ミリ秒を設定する
          if (targetTime.isAfter(currentTime)) {
            intervalSecond = targetTime.diff(currentTime)
          }
        }
      }

      // 一定周期で表示を更新する
      this.timeoutId = setTimeout(() => {
        this.extractDisplayOperation()
        this.setDisplayUpdateTimer()
      }, intervalSecond)
      /* eslint-enable no-magic-numbers */
    },
    /**
     * 種類別アイコンの取得
     */
    getIcon() {
      let iconName = ''
      switch (this.type) {
        case this.$config.STATION_TYPE.STATION:
          // 路線アイコン取得処理
          iconName = mobilityUtil.getLinksNameIcon(
            this.favoriteTimeTable.link,
            this.favoriteTimeTable.linkName
          )
          if (iconName == 'Icon_Move_Train.svg') {
            // デフォルトの路線アイコンを取得した場合、お気に入り表示用の電車アイコンに置換する
            iconName = 'Icon_Favorite_Train.svg'
          } else {
            // favoriteIconとは別階層にある路線アイコンを返す
            return require('@/assets/' + iconName)
          }
          break
        case this.$config.STATION_TYPE.AIRPORT:
          iconName = 'Icon_Favorite_Airplane.svg'
          break
        case this.$config.STATION_TYPE.PORT:
          iconName = 'Icon_Favorite_Ship.svg'
          break
        case this.$config.STATION_TYPE.BUSSTOP:
        case this.$config.STATION_TYPE.SHUTTLE_BUSSTOP:
        case this.$config.STATION_TYPE.HIGHWAY_BUSSTOP:
          iconName = 'Icon_Favorite_Bus.svg'
          break
        default:
          iconName = 'Icon_Favorite_Train.svg'
          break
      }
      return require(`@/assets/favoriteIcon/${iconName}`)
    },
    /**
     * カードクリック処理
     */
    onClickFavorite() {
      this.$emit('click-favorite', this.favoriteTimeTable)
    },
    /**
     * 色の取得
     */
    getOperationColor(operation) {
      return operation.color
    },
    /**
     * チェックボックスの変更通知
     */
    onChangeChecked() {
      // チェック状況を親コンポーネントにemitする
      this.$emit('check-favorite', {
        favoriteTimeTable: this.favoriteTimeTable,
        clicked: this.checked,
      })
    },
    /**
     * 当駅始発ラベルを表示するかどうか
     */
    isShowDepartureLavel(operation) {
      return (
        this.type != this.$config.STATION_TYPE.AIRPORT &&
        this.type != this.$config.STATION_TYPE.PORT &&
        operation &&
        operation.isDeparture
      )
    },
  },
}
export default TimeTableFavoriteCard
</script>

<style scoped>
/* アイコン画像に影をつける */
img#icon {
  filter: drop-shadow(0px 2px 10px #00000033);
}
/* 右矢印アイコンが消えないよう、時刻表の路線名・方面名の幅を動的に調整する */
.width-calc {
  width: calc(100% - 12px);
}
.check-box input {
  display: none; /* デフォルトのチェックボックスを非表示 */
}
.check-box input + .check-text::before {
  background-image: url(@/assets/CheckOff.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  position: relative;
  width: 20px;
  height: 20px;
}
.check-box input:checked + .check-text::before {
  background-image: url(@/assets/CheckOn.svg);
}
</style>
