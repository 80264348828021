<template>
  <div>
    <div class="loader" :style="loadingWidth" />
  </div>
</template>
<script>
const BaseLoading = {
  name: 'BaseLoading',
  props: {
    /**
     * ローディングアイコンのサイズ
     */
    width: {
      type: String,
      default: '12',
    },
  },
  computed: {
    /**
     * ローディングアイコンのサイズをCSS変数で定義
     */
    loadingWidth: {
      get() {
        return {'--width': this.width + 'px'}
      },
    },
  },
}
export default BaseLoading
</script>
<style scoped>
.loader {
  --width: 12px;
  width: var(--width);
  margin-top: 3px;
  padding: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #999999;
  --m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--m);
  mask: var(--m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
</style>
