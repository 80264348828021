import {createRouter, createWebHashHistory} from 'vue-router'

import Load from '../views/Load.vue'

// ロード画面(Setup)
import Welcome from '../views/setup/Welcome.vue'
import SetupEmailVerified from '../views/setup/SetupEmailVerified.vue'
import SetupLoginMethod from '../views/setup/SetupLoginMethod.vue'
import SetupUserInfo from '../views/setup/SetupUserInfo.vue'
import SetupUserInfoFinished from '../views/setup/SetupUserInfoFinished.vue'
import SignupForMobility from '../views/setup/SignupForMobility.vue'

// ルート検索（Spot）
import SearchTop from '../views/searchRoute/SearchTop.vue'
import RouteByOptimal from '../views/searchRoute/RouteByOptimal.vue'
import RouteByTrainBus from '../views/searchRoute/RouteByTrainBus.vue'
import RouteDetailByTrainBus from '../views/searchRoute/RouteDetailByTrainBus.vue'
import RouteByCar from '../views/searchRoute/RouteByCar.vue'
import RouteByBicycle from '../views/searchRoute/RouteByBicycle.vue'
import RouteByWalk from '../views/searchRoute/RouteByWalk.vue'
import DestinationSetting from '../views/searchRoute/DestinationSetting.vue'

// ルート検索 パネル内（children）
import RouteNavi from '../components/RouteNavi.vue'

// 乗り換え案内（Transfer）
import TransferTop from '../views/transfer/TransferTop.vue'
import TransferSuggest from '../views/transfer/TransferSuggest.vue'
import TransferResult from '../views/transfer/TransferResult.vue'
import TransferDetail from '../views/transfer/TransferDetail.vue'

// 乗り物手配（Arrangement）
import ArrangementTop from '../views/arrangement/ArrangementTop.vue'

// 時刻表（TimeTable）
import TimeTableTop from '../views/timeTable/TimeTableTop.vue'
import TimeTableSuggest from '../views/timeTable/TimeTableSuggest.vue'
import TimeTableDirection from '../views/timeTable/TimeTableDirection.vue'
import TimeTableDetail from '../views/timeTable/TimeTableDetail.vue'

// メニュー(Menu)
import Menu from '../views/menu/Menu.vue'
import FavoriteSpotList from '../views/menu/FavoriteSpotList.vue'
import FavoriteSpotSuggest from '../views/menu/FavoriteSpotSuggest.vue'
import FavoriteSpotDetail from '../views/menu/FavoriteSpotDetail.vue'
import UserInfoDetail from '../views/menu/UserInfoDetail.vue'
import PaymentMethodManagement from '../views/payment/PaymentMethodManagement.vue'
import TimetableLicenseInfo from '../views/menu/TimetableLicenseInfo.vue'
import NotificationList from '../views/menu/NotificationList.vue'
import NotificationDetail from '../views/menu/NotificationDetail.vue'

// 自転車予約(BicycleReservation)
import BicycleReservationTop from '../views/arrangement/bicycleReservation/BicycleReservationTop.vue'
import BicycleReservationPortOnMap from '../views/arrangement/bicycleReservation/BicycleReservationPortOnMap.vue'
import BicycleReservationSelect from '../views/arrangement/bicycleReservation/BicycleReservationSelect.vue'
import BicycleReservationPaymentConfirm from '../views/arrangement/bicycleReservation/BicycleReservationPaymentConfirm.vue'
import BicycleReservationConfirmed from '../views/arrangement/bicycleReservation/BicycleReservationConfirmed.vue'
import BicycleReservationReturnPortOnMap from '../views/arrangement/bicycleReservation/BicycleReservationReturnPortOnMap.vue'
import BicycleReservationReturnPortDetail from '../views/arrangement/bicycleReservation/BicycleReservationReturnPortDetail.vue'

// タクシー予約(TaxiReservation)
import TaxiReservationTop from '../views/arrangement/taxiReservation/TaxiReservationTop.vue'
import TaxiReservationConfirm from '../views/arrangement/taxiReservation/TaxiReservationConfirm.vue'
import TaxiReservationPaymentConfirm from '../views/arrangement/taxiReservation/TaxiReservationPaymentConfirm.vue'
import TaxiReservationCompleted from '../views/arrangement/taxiReservation/TaxiReservationCompleted.vue'
import TaxiReservationChat from '../views/arrangement/taxiReservation/TaxiReservationChat.vue'

// 保険金請求
import InsuranceClaimsLogin from '../views/insurance/InsuranceClaimsLogin'
import InsuranceClaimsTop from '../views/insurance/InsuranceClaimsTop'
import InsuranceClaimDetail from '../views/insurance/InsuranceClaimDetail'
import InsuranceClaimConfirmed from '../views/insurance/InsuranceClaimConfirmed'

// Suica
import SuicaTop from '../views/suica/SuicaTop.vue'
import SuicaDetail from '../views/suica/SuicaDetail.vue'

// 決済
import RegisterCreditCard from '../views/payment/creditCards/RegisterCreditCard.vue'
import PaymentMethodSelect from '../views/payment/PaymentMethodSelect.vue'

import {login, getAccessToken} from '@/auth/AuthorizationInteractor.js'
import Util from '@/mixins/util'
import {constant} from '@/const/const.js'
import store from '@/store/index'

const AUTH = {
  LOGIN: 0,
  SIGNUP_MOBILITY: 1,
}
const routes = [
  {
    path: '/',
    name: 'Load',
    component: Load,
  },
  {
    // ようこそ画面
    path: '/Welcome',
    name: 'Welcome',
    component: Welcome,
  },
  {
    // 次回以降のログイン設定画面
    path: '/SetupLoginMethod',
    name: 'SetupLoginMethod',
    component: SetupLoginMethod,
  },
  {
    // メール送信完了画面
    path: '/SetupEmailVerified',
    name: 'SetupEmailVerified',
    component: SetupEmailVerified,
  },
  {
    // ユーザー情報登録画面
    path: '/SetupUserInfo',
    name: 'SetupUserInfo',
    component: SetupUserInfo,
  },
  {
    // ユーザー情報登録完了画面
    path: '/SetupUserInfoFinished',
    name: 'SetupUserInfoFinished',
    component: SetupUserInfoFinished,
  },
  {
    // 登録・ログイン促進画面
    path: '/SignupForMobility',
    name: 'SignupForMobility',
    component: SignupForMobility,
  },
  {
    path: '/SearchTop',
    name: 'SearchTop',
    component: SearchTop,
  },
  {
    path: '/RouteByOptimal',
    name: 'RouteByOptimal',
    component: RouteByOptimal,
  },
  {
    path: '/RouteByTrainBus',
    name: 'RouteByTrainBus',
    component: RouteByTrainBus,
  },
  {
    path: '/RouteDetailByTrainBus',
    name: 'RouteDetailByTrainBus',
    component: RouteDetailByTrainBus,
  },
  {
    path: '/RouteByCar',
    name: 'RouteByCar',
    component: RouteByCar,
  },
  {
    path: '/RouteByBicycle',
    name: 'RouteByBicycle',
    component: RouteByBicycle,
  },
  {
    path: '/RouteByWalk',
    name: 'RouteByWalk',
    component: RouteByWalk,
  },
  {
    path: '/RouteNavi',
    name: 'RouteNavi',
    component: RouteNavi,
  },
  {
    path: '/DestinationSetting',
    name: 'DestinationSetting',
    component: DestinationSetting,
  },
  {
    // 乗り換えTOP画面
    path: '/TransferTop',
    name: 'TransferTop',
    component: TransferTop,
  },
  {
    path: '/TransferSuggest',
    name: 'TransferSuggest',
    component: TransferSuggest,
  },
  {
    path: '/TransferResult',
    name: 'TransferResult',
    component: TransferResult,
  },
  {
    path: '/TransferDetail',
    name: 'TransferDetail',
    component: TransferDetail,
  },
  {
    path: '/ArrangementTop',
    name: 'ArrangementTop',
    component: ArrangementTop,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // 時刻表画面
    path: '/TimeTableTop',
    name: 'TimeTableTop',
    component: TimeTableTop,
  },
  {
    // 時刻表駅名入力画面
    path: '/TimeTableSuggest',
    name: 'TimeTableSuggest',
    component: TimeTableSuggest,
  },
  {
    // 時刻表方面選択画面
    path: '/TimeTableDirection',
    name: 'TimeTableDirection',
    component: TimeTableDirection,
  },
  {
    // 時刻表表示画面
    path: '/TimeTableDetail',
    name: 'TimeTableDetail',
    component: TimeTableDetail,
  },
  {
    // メニュー
    path: '/Menu',
    name: 'Menu',
    component: Menu,
  },
  {
    // お気に入り一覧画面
    path: '/FavoriteSpotList',
    name: 'FavoriteSpotList',
    component: FavoriteSpotList,
  },
  {
    // お気に入り地点検索画面
    path: '/FavoriteSpotSuggest',
    name: 'FavoriteSpotSuggest',
    component: FavoriteSpotSuggest,
  },
  {
    // お気に入り地点詳細画面
    path: '/FavoriteSpotDetail',
    name: 'FavoriteSpotDetail',
    component: FavoriteSpotDetail,
  },
  {
    // ユーザー情報参照画面
    path: '/UserInfoDetail',
    name: 'UserInfoDetail',
    component: UserInfoDetail,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // 支払い方法選択画面
    path: '/PaymentMethodSelect',
    name: 'PaymentMethodSelect',
    component: PaymentMethodSelect,
    meta: {
      requiresAuth: AUTH.LOGIN,
    },
  },
  {
    // 支払い方法管理画面
    path: '/PaymentMethodManagement',
    name: 'PaymentMethodManagement',
    component: PaymentMethodManagement,
    meta: {
      requiresAuth: AUTH.LOGIN,
    },
  },
  {
    // 時刻表ライセンス情報画面
    path: '/TimetableLicenseInfo',
    name: 'TimetableLicenseInfo',
    component: TimetableLicenseInfo,
  },
  {
    // お知らせ履歴一覧画面
    path: '/NotificationList',
    name: 'NotificationList',
    component: NotificationList,
  },
  {
    // お知らせ履歴詳細画面
    path: '/NotificationDetail',
    name: 'NotificationDetail',
    component: NotificationDetail,
  },
  {
    // 自転車予約TOP画面
    path: '/BicycleReservationTop',
    name: 'BicycleReservationTop',
    component: BicycleReservationTop,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // 自転車予約用ポート表示画面
    path: '/BicycleReservationPortOnMap',
    name: 'BicycleReservationPortOnMap',
    component: BicycleReservationPortOnMap,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // 自転車選択画面
    path: '/BicycleReservationSelect',
    name: 'BicycleReservationSelect',
    component: BicycleReservationSelect,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // 支払確認画面
    path: '/BicycleReservationPaymentConfirm',
    name: 'BicycleReservationPaymentConfirm',
    component: BicycleReservationPaymentConfirm,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // 予約確定画面
    path: '/BicycleReservationConfirmed',
    name: 'BicycleReservationConfirmed',
    component: BicycleReservationConfirmed,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // 自転車返却ポート表示画面
    path: '/BicycleReservationReturnPortOnMap',
    name: 'BicycleReservationReturnPortOnMap',
    component: BicycleReservationReturnPortOnMap,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // 自転車返却ポート詳細画面
    path: '/BicycleReservationReturnPortDetail',
    name: 'BicycleReservationReturnPortDetail',
    component: BicycleReservationReturnPortDetail,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // タクシー予約TOP画面
    path: '/TaxiReservationTop',
    name: 'TaxiReservationTop',
    component: TaxiReservationTop,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // タクシー予約内容確認画面
    path: '/TaxiReservationConfirm',
    name: 'TaxiReservationConfirm',
    component: TaxiReservationConfirm,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // タクシー支払確認画面画面
    path: '/TaxiReservationPaymentConfirm',
    name: 'TaxiReservationPaymentConfirm',
    component: TaxiReservationPaymentConfirm,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // タクシー手配完了画面
    path: '/TaxiReservationCompleted',
    name: 'TaxiReservationCompleted',
    component: TaxiReservationCompleted,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // タクシー支払確認画面画面
    path: '/TaxiReservationChat',
    name: 'TaxiReservationChat',
    component: TaxiReservationChat,
    meta: {
      requiresAuth: AUTH.SIGNUP_MOBILITY,
    },
  },
  {
    // 保険金請求ログイン画面
    path: '/InsuranceClaimsLogin',
    name: 'InsuranceClaimsLogin',
    component: InsuranceClaimsLogin,
    meta: {
      requiresAuth: AUTH.LOGIN,
    },
  },
  {
    // 保険金請求Top画面
    path: '/InsuranceClaimsTop',
    name: 'InsuranceClaimsTop',
    component: InsuranceClaimsTop,
    meta: {
      requiresAuth: AUTH.LOGIN,
    },
  },
  {
    // 請求候補登録・編集画面
    path: '/InsuranceClaimDetail',
    name: 'InsuranceClaimDetail',
    component: InsuranceClaimDetail,
    meta: {
      requiresAuth: AUTH.LOGIN,
    },
  },
  {
    // 請求候補登録・編集画面
    path: '/InsuranceClaimConfirmed',
    name: 'InsuranceClaimConfirmed',
    component: InsuranceClaimConfirmed,
    meta: {
      requiresAuth: AUTH.LOGIN,
    },
  },
  {
    // SuicaTop画面
    path: '/SuicaTop',
    name: 'SuicaTop',
    component: SuicaTop,
  },
  {
    // Suica詳細画面
    path: '/SuicaDetail',
    name: 'SuicaDetail',
    component: SuicaDetail,
  },
  {
    // お支払い方法を登録画面
    path: '/RegisterCreditCard',
    name: 'RegisterCreditCard',
    component: RegisterCreditCard,
    meta: {
      requiresAuth: AUTH.LOGIN,
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return {top: 0}
  },
})
function locationHashChanged() {
  // ハッシュ変更イベント発火時(手動でURLを変更した場合)にローディング画面に戻る
  location.href = location.protocol + '//' + location.host + '/'
}
window.onhashchange = locationHashChanged

let isFirstPage = true
let lastRequestedPath = ''

function isNeedAuthPage(to) {
  return to.matched.some((record) => {
    return (
      record.meta.requiresAuth == AUTH.LOGIN ||
      record.meta.requiresAuth == AUTH.SIGNUP_MOBILITY
    )
  })
}

/**
 * 画面遷移前の割り込み処理
 */
router.beforeEach(async (to, from, next) => {
  // 最後に要求された画面遷移先を保持しておく
  lastRequestedPath = to.path

  // ページリロード時のリダイレクト先
  if (isFirstPage) {
    // queryパラメータは引き継ぐ
    next({
      path: '/',
      query: to.query,
    })
    isFirstPage = false
    return
  }
  const query = new URLSearchParams(window.location.search)
  if (query.has('code') && query.has('state')) {
    // Auth0ログイン成功時からの最初のリダイレクトの場合
    // 遷移をキャンセル
    next(false)
    return
  }

  // ルートへの遷移は常に許可する
  if (to.path == '/') {
    next()
    return
  }

  // 遷移元と遷移先が同一のパスの場合はキャンセルする（Safariのエラー対策）
  if (to.path === from.path) {
    next(false)
    return
  }

  if (isNeedAuthPage(to)) {
    // 認証必須の画面に遷移する場合、ログイン判定を行う
    let authenticated = store.state.user.auth.isLogin
    if (authenticated) {
      // ログインされている場合、トークンの期限切れなどを考慮し、あらためて認証判定を行う
      try {
        authenticated = !!(await getAccessToken())
      } catch (e) {
        authenticated = false
        throw new Error('invalidAccessToken')
      } finally {
        store.commit('updateUserAuthInfo', {isLogin: authenticated})
      }

      // 非同期待ちの間に別画面への遷移が行われた場合、以降の処理を行わない
      if (lastRequestedPath != to.path) {
        next(false)
        return
      }
    }

    if (!authenticated) {
      // 認証されていない時、登録・ログイン促進画面へ遷移
      Util.methods.setLocalStorage(constant.NEXT_PAGE_IN_AUTH, to.name)
      const firstIndex = 0
      const metaRequireAuth = to.matched[firstIndex].meta.requiresAuth

      switch (metaRequireAuth) {
        case AUTH.SIGNUP_MOBILITY:
          if (from.path === '/SignupForMobility' && !(to.path === '/Menu')) {
            // 同一画面への遷移は回避する
            next(false)
          } else {
            next('/SignupForMobility')
          }
          break
        case AUTH.LOGIN:
        default:
          localStorage.removeItem(constant.USER_SETUP_COMPLETED)
          await login()
          next(false)
      }
    } else {
      // 認証されている場合は、そのまま遷移する
      next()
    }
  } else {
    // 認証不要の画面に遷移する場合は、そのまま遷移する
    next()
  }
})

/**
 * 画面遷移後の割り込み処理
 */
router.afterEach((to, from) => {
  // 前の画面情報をクエリに追加
  to.params.prevRouteName = from.name
})

export {routes}
export default router
