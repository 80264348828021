<template>
  <div
    class="flex items-center active:bg-blue100 py-3"
    @click="$emit('click-address-suggest', address)"
  >
    <div>
      <IconSearch class="m-2 w-4 h-4" />
    </div>
    <div class="text-left ml-2 truncate">
      <span class="text-W4 text-[16px] leading-4">
        <span class="text-gray">「</span>
        <span>
          {{ address }}
        </span>
      </span>
    </div>
    <div class="min-w-[80px] text-left">
      <span class="text-gray">」で検索</span>
    </div>
  </div>
</template>

<script>
import IconSearch from '@/components/icons/common/IconSearch.vue'

const AddressSuggestList = {
  name: 'AddressSuggestList',
  components: {
    IconSearch,
  },
  props: {
    address: String,
  },
}
export default AddressSuggestList
</script>

<style scoped></style>
