<template>
  <!-- お気に入り地点詳細表示画面 -->
  <div id="favorite-spot-detail" class="h-full flex flex-col">
    <!-- ヘッダー部 -->
    <div class="flex text-center w-full items-center bg-white h-[45px] px-3">
      <div class="w-8 text-left">
        <img
          src="@/assets/Icon_Left_gray.svg"
          class="h-4"
          @click="gotoBackPage()"
        />
      </div>
      <div class="text-W6 leading-[15px] text-[15px] w-full truncate">
        {{ getFavoriteSpotName }}
      </div>
      <div class="w-8"></div>
    </div>
    <!-- 線 -->
    <div class="h-[0.5px] w-full bg-gray300" />
    <div id="favorite-spot-contents" class="flex-1">
      <!-- マップ -->
      <BaseMap
        ref="BaseMap"
        mapElementId="favorite-spot-map"
        :defaultCenter="defaultCenter"
        :isTouchMap="true"
        :copyrightBottomSpace="90"
        @finish-generate-map="finishGenerateMap()"
      />
      <div class="absolute top-[45px] w-full px-2 mt-2">
        <!-- 黒枠 -->
        <div
          id="FavoriteSpotCard"
          class="flex w-full pl-3 pr-4 py-4 bg-gray600 rounded-[4px]"
        >
          <!-- ピンアイコン -->
          <img
            class="mr-2 w-3 h-3"
            src="@/assets/footer/IconTabRouteBlue.svg"
          />
          <div class="flex-1 self-center">
            <div
              class="text-left text-W4 text-[14px] leading-[19px] my-[-2.5px] text-white"
            >
              <!-- 名称 -->
              {{ showFavoriteName }}
            </div>
            <div
              class="text-left text-W3 text-[13px] leading-[19px] mb-[-3px] text-white"
              :class="addMt"
            >
              <!-- 住所 -->
              {{ concatAddress }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="panel-info"
      class="absolute bottom-0 bg-white w-full rounded-t-[20px] h-20 z-[500]"
    >
      <!-- Navitimeの縮尺・ゼンリン情報 -->
      <div class="opacity-100" id="navitime-copyright"></div>
      <div class="m-5 flex">
        <div
          class="w-1/2 mr-1 py-3 rounded-[20px] text-W4 text-[15px] leading-[15px] bg-white text-danger300"
        >
          <span class="px-3" @click="isDeleteFavoritePopup = true">
            登録地点を削除
          </span>
        </div>
        <div
          class="w-1/2 ml-1 py-3 rounded-[8px] text-W7 text-[15px] bg-primary text-white"
        >
          <span class="px-3" @click="goToSuggest()">登録内容を変更</span>
        </div>
      </div>
    </div>
  </div>
  <!-- 削除用ポップアップ -->
  <Modal
    v-if="isDeleteFavoritePopup"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="pt-9 pb-6 mx-5">
      <div class="text-W5 text-[17px] leading-[21.5px]">
        {{ getFavoriteSpotName }}{{ showModalString }}を削除します。
        <br />
        よろしいですか？
      </div>
      <!-- ボタン -->
      <div class="flex justify-center mt-6 items-center">
        <div
          class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
          @click="isDeleteFavoritePopup = false"
        >
          キャンセル
        </div>

        <div
          class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
          @click="onClickDeletePopup()"
        >
          削除
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
/**
 * お気に入り地点詳細表示画面
 */
import Modal from '@/components/Modal.vue'
import BaseMap from '@/components/atoms/BaseMap.vue'
import menuUtil from '@/mixins/menuUtil'

const LAT_INDEX = 0 // 緯度
const LON_INDEX = 1 // 経度
// 地図上に表示するアイコン画像サイズ
const SPOT_ICON_SIZE = {
  height: 72,
  width: 60,
}

const FavoriteSpotDetail = {
  name: 'FavoriteSpotDetail',
  components: {Modal, BaseMap},
  mixins: [menuUtil],
  data() {
    return {
      isDeleteFavoritePopup: false, // 削除用ポップアップ用展開フラグ
    }
  },
  created() {
    // フッター非表示
    this.$store.commit('updateShowFooter', false)
  },
  computed: {
    /**
     * お気に入り地点情報の取得
     */
    showFavoriteData() {
      // 前画面から取得したお気に入り地点情報を返す
      return JSON.parse(this.$route.params.spotData)
    },
    /**
     * 「自宅情報」を表示するための文字列取得
     */
    showModalString() {
      // keyがhomeである場合、削除ポップアップ画面に「自宅情報」を表示
      return this.$route.params.key == this.$config.FAVORITE_SPOT_HOME
        ? '情報'
        : ''
    },
    /**
     * 名称が空文字もしくわ名称なしのチェック
     */
    isNotName() {
      // 前画面から取得した名称を取得し、チェックする
      return (
        this.showFavoriteData.spotName == '' ||
        this.showFavoriteData.spotName == '名称なし'
      )
    },
    /**
     * 黒枠の名称表示
     */
    showFavoriteName() {
      // 前画面から取得したお気に入りの名称データの有無で返すデータを分ける
      return this.isNotName ? '' : this.showFavoriteData.spotName
    },
    /**
     * お気に入り地点の名称を取得する
     */
    getFavoriteSpotName() {
      switch (this.$route.params.key) {
        case this.$config.FAVORITE_SPOT_HOME:
          return '自宅'
        case this.$config.FAVORITE_SPOT_WORK:
          return '会社/学校'
        case this.$config.FAVORITE_SPOT:
          return this.showFavoriteData.spotName
        default:
          return ''
      }
    },
    /**
     * お気に入り地点のアイコンを取得する
     */
    getFavoriteSpotIcon() {
      switch (this.$route.params.key) {
        case this.$config.FAVORITE_SPOT_HOME:
          return 'favoriteSpotMapIcon/Icon_Home.svg'
        case this.$config.FAVORITE_SPOT_WORK:
          return 'favoriteSpotMapIcon/Icon_Work.svg'
        case this.$config.FAVORITE_SPOT:
          return 'favoriteSpotMapIcon/Icon_Favorite.svg'
        default:
          return ''
      }
    },
    /**
     * 緯度取得
     */
    lat() {
      return this.showFavoriteData.coord.split(',')[LAT_INDEX]
    },
    /**
     * 経度取得
     */
    lon() {
      return this.showFavoriteData.coord.split(',')[LON_INDEX]
    },
    /**
     * 住所の表示位置調整用クラス属性設定処理
     */
    addMt() {
      // スポット名の有無で、住所との間のマージンを調整する
      return this.isNotName ? 'mt-[-3px]' : 'mt-[17px]'
    },
    /**
     * 住所を取得する
     * trim()で文字の両端に空白があれば除去
     */
    concatAddress() {
      return this.showFavoriteData.address
        .concat(
          ' ', // 住所と建物名の間に空白
          this.showFavoriteData.buildingName
        )
        .trim()
    },
    /**
     * マップに渡す中心座標を返却する
     */
    defaultCenter() {
      return {
        lat: Number(this.lat),
        lon: Number(this.lon),
      }
    },
  },
  methods: {
    /**
     * 前画面（お気に入り地点検索画面）に戻る
     */
    gotoBackPage() {
      this.$router.push({
        name: 'FavoriteSpotList',
      })
    },
    /**
     * 地点のサジェスト画面に遷移
     */
    goToSuggest() {
      this.$router.push({
        name: 'FavoriteSpotSuggest',
        params: {key: this.$route.params.key, id: this.showFavoriteData.id},
      })
    },
    /**
     * お気に入りカード削除処理
     */
    onClickDeletePopup() {
      // 削除用ローカルストレージの戻り値を保持
      const localDeleteData = this.deleteFavoriteSpotList(
        this.showFavoriteData,
        this.$route.params.key
      )
      // ストレージ、Storeに削除後のデータを反映
      this.$store.commit('MenuStore/updateFavoriteSpots', localDeleteData)
      // ポップアップを閉じる
      this.isDeleteFavoritePopup = false

      // お気に入り一覧画面に戻る
      this.gotoBackPage()
    },
    /**
     * マップ生成後の処理を行う
     * お気に入り地点にアイコンを置き、中心に移動させる
     */
    finishGenerateMap() {
      const lat = this.defaultCenter.lat
      const lon = this.defaultCenter.lon
      const icon = this.getFavoriteSpotIcon
      this.$refs.BaseMap.setCenterAndZoomDefault(lat, lon)
      this.$refs.BaseMap.putPin('favoriteIcon', lat, lon, SPOT_ICON_SIZE, icon)
    },
  },
  beforeUnmount() {
    // フッター表示
    this.$store.commit('updateShowFooter', true)
  },
}
export default FavoriteSpotDetail
</script>

<style scoped>
/* 縮尺・ゼンリン情報の親要素 */
#panel-info ::v-deep(#navitime-copyright) {
  display: flex;
  position: absolute;
  top: -22px;
  bottom: auto;
  transition: opacity 0.2s;
}
/* Navitime 縮尺情報 */
#panel-info ::v-deep(.gia-parts-scale) {
  position: unset;
  line-height: 8px;
  margin-right: 7px;
}
/* Navitime ゼンリン情報 */
#panel-info ::v-deep(.gia-parts-copyright-text) {
  position: unset;
  line-height: 8px;
}
</style>
