<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g transform="translate(-242.999 -172)">
      <rect
        width="16"
        height="16"
        transform="translate(242.999 172)"
        fill="none"
      />
      <path
        d="M5.888,18.538H15.3a2.306,2.306,0,0,0,1.745-.647,2.664,2.664,0,0,0,.621-1.915V7.413L16.222,8.86v7.005a1.293,1.293,0,0,1-.285.911.912.912,0,0,1-.694.306H5.94a1.214,1.214,0,0,1-.89-.306,1.226,1.226,0,0,1-.311-.911V6.826a1.226,1.226,0,0,1,.311-.911,1.214,1.214,0,0,1,.89-.306h7.1l1.447-1.447h-8.6A2.649,2.649,0,0,0,3.952,4.8,2.585,2.585,0,0,0,3.3,6.723v9.253a2.588,2.588,0,0,0,.651,1.915A2.638,2.638,0,0,0,5.888,18.538Zm2.988-5.312,1.609-.689L17.9,5.132,16.724,3.974,9.319,11.379,8.6,12.937a.188.188,0,0,0,.047.23A.2.2,0,0,0,8.876,13.227Zm9.61-8.682.6-.613a.752.752,0,0,0,.217-.511.69.69,0,0,0-.209-.5l-.2-.2a.571.571,0,0,0-.477-.183.763.763,0,0,0-.494.234l-.6.6Z"
        transform="translate(239.701 169.462)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
