<template>
  <div id="RouteDetailCard">
    <!-- 乗換不要駅の場合 -->
    <div v-if="isNoTransferRequired">
      <div class="relative">
        <div class="customBorder" />
      </div>
      <div class="flex py-2">
        <div class="text-W4 text-[12px] leading-[12px] text-gray ml-4 pr-2">
          乗換不要
        </div>
        <div class="text-W4 text-[12px] leading-[12px]">{{ pointName }}</div>
      </div>
      <div class="relative">
        <div class="customBorder" />
      </div>
      <div class="mt-3 ml-5 flex">
        <img :src="getRouteMobilityIcon(moveSection)" class="w-4 h-4 mr-2" />
        <div class="flex-1">
          <div class="text-W5 text-[13px] leading-[13px] mb-1">
            {{ lineName }}
          </div>
          <div class="text-W4 text-[11px] leading-[11px] text-gray">
            {{ destinationName }}
          </div>
        </div>
      </div>
    </div>
    <!-- 乗換必要駅やそれ以外の地点の場合 -->
    <div v-else class="bg-white rounded-[4px]">
      <div class="flex py-4 px-5">
        <!-- point名称 -->
        <div class="flex-1">
          <div class="text-W7 text-[15px] leading-[19px]">
            {{ pointName }}
          </div>
          <WeatherInfo
            v-if="existWeather(spotWeather)"
            class="mt-1"
            :weather="spotWeather"
            weatherModalTitle="本日の出発地の天気"
          />
        </div>
        <!-- 着時間（一番上の場合は出発時間） -->
        <div v-if="!isNull(toTime)" class="text-W4 text-[14px] ml-auto">
          <span>
            {{ toTime }}
          </span>
          <span v-if="isShowToTimeUnit">
            <span class="ml-1">着</span>
          </span>
        </div>
      </div>
      <!-- 出口 or 路線情報 -->
      <div v-if="isWalkSection">
        <!-- 徒歩の場合、出口情報があれば表示 -->
        <div v-if="!isNull(gateway)">
          <!-- 灰色線 -->
          <div class="bg-gray300 w-full h-[1px]" />
          <!-- 出口情報 -->
          <div class="py-4 flex">
            <!-- 「出口」ラベル -->
            <div
              class="pt-0.5 text-W4 text-[14px] text-gray500 ml-11 leading-[14px]"
            >
              出口：
            </div>
            <!-- 名称 -->
            <div
              class="bg-warning300 px-[5px] py-0.5 text-W4 text-[14px] text-gray600 leading-[14px]"
            >
              {{ gateway }}
            </div>
          </div>
        </div>
      </div>
      <!-- 徒歩以外の場合、路線情報表示 -->
      <div v-else>
        <!-- 灰色線 -->
        <div class="bg-gray300 w-full h-[1px]" />
        <div class="py-4 px-5 flex">
          <!-- アイコン -->
          <img :src="getRouteMobilityIcon(moveSection)" class="w-4 h-4" />
          <div class="ml-2 flex-1">
            <!-- 路線名と出発時間 -->
            <div class="flex w-full">
              <!-- 路線名 -->
              <div class="text-W7 text-[14px] leading-[14px] flex-1">
                {{ lineName }}
              </div>
              <!-- 出発時間 -->
              <div
                v-if="!isNull(fromTime)"
                class="text-W4 text-[14px] leading-[14px] flex ml-auto"
              >
                <span class="ml-1">
                  {{ fromTime }}
                </span>
                <span class="ml-1">発</span>
              </div>
            </div>
            <!-- 方面名 -->
            <div
              v-if="destinationName"
              class="mt-2 text-W4 text-[14px] text-gray500 leading-[14px]"
            >
              {{ destinationName }}方面
            </div>
            <!-- プラットホームと列車種別 -->
            <div
              v-if="isShowStartPlatformAndTypeArea"
              class="mt-2 flex text-W4 text-[14px] text-gray500 leading-[14px]"
            >
              <span v-if="!isNull(startPlatform)">
                <span>{{ startPlatform }}</span>
                <span v-if="!isNull(transportType)" class="mx-1 text-gray300">
                  ｜
                </span>
              </span>
              <span v-if="!isNull(transportType)">{{ transportType }}</span>
            </div>
            <!-- おすすめ乗車位置 -->
            <div
              v-if="isShowGetoff"
              class="mt-[21px] text-W4 text-[14px] text-gray500 leading-[14px]"
            >
              <div class="mb-2">おすすめ乗車位置</div>
              <div v-if="isNull(getoff)">特定できませんでした</div>
              <div v-else>
                <GetoffPosition :getoff="getoff"></GetoffPosition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * ルート案内 電車バス詳細画面カードコンポーネント
 */
import Util from '@/mixins/util'
import SearchRouteUtil from '@/mixins/searchRouteUtil'
import GetoffPosition from '@/components/atoms/GetoffPosition.vue'
import WeatherInfo from '@/components/molecules/WeatherInfo.vue'
const RouteDetailCard = {
  name: 'RouteDetailCard',
  components: {GetoffPosition, WeatherInfo},
  mixins: [Util, SearchRouteUtil],
  emits: [],
  props: {
    /**
     * ポイントの名称
     */
    pointName: {
      type: String,
      require: true,
    },
    /**
     * ひとつ前のmoveの到着時間
     */
    prevToTime: {
      type: String,
      require: false,
    },
    /**
     * 最初のmoveセクションかどうか
     */
    isFirstMove: {
      type: Boolean,
      require: false,
    },
    /**
     * 出口情報
     */
    gateway: {
      type: String,
      require: false,
    },
    /**
     * moveセクション情報
     */
    moveSection: {
      type: Object,
      require: true,
    },
    /**
     * 出発ホーム情報
     */
    startPlatform: {
      type: String,
      require: false,
    },
    /**
     * 乗換不要有無
     */
    isNoTransferRequired: {
      type: Boolean,
      require: true,
    },
    /**
     * 天気情報(指定しない場合は表示しない)
     */
    spotWeather: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  computed: {
    /**
     * 到着時間の生成
     * @return {String} 到着時間
     */
    toTime() {
      let totimeData = ''
      if (this.isFirstMove) {
        // 最初のmoveセクションの場合
        if (this.isWalkSection) {
          // 徒歩の場合は、出発時間を表示する
          totimeData = this.getFormatTime(this.moveSection.fromTime)
        }
      } else {
        // 最初のmoveセクションではない場合
        if (!this.isNull(this.prevToTime)) {
          // prevToTimeが存在する場合
          totimeData = this.getFormatTime(this.prevToTime)
        }
      }
      return totimeData
    },
    /**
     * 到着時間に「着」の文字を表示するかどうか
     * @return {Boolean} true:表示する, false:表示しない
     */
    isShowToTimeUnit() {
      return !this.isFirstMove && !this.isNull(this.toTime)
    },
    /**
     * 徒歩セクションかどうかの判定
     * @return {Boolean} true:徒歩セクション, false:徒歩以外
     */
    isWalkSection() {
      return this.moveSection.move == this.$config.MOVE.WALK
    },
    /**
     * 路線名取得
     * @return {String} 路線名
     */
    lineName() {
      return this.moveSection.lineName
    },
    /**
     * 出発時間
     * @return {String} 出発時間
     */
    fromTime() {
      if (this.isNull(this.moveSection.fromTime)) {
        return null
      }
      return this.getFormatTime(this.moveSection.fromTime)
    },
    /**
     * 方面情報
     * @return {String} 方面情報
     */
    destinationName() {
      let name = ''
      const transport = this.moveSection.transport
      if (
        this.isNull(transport.destination) ||
        this.isNull(transport.destination.name)
      ) {
        // transport直下のdestinationがない場合はlinksの中のdestinationを探す
        if (
          Array.isArray(transport.links) &&
          !this.isNull(transport.links[0]) && // eslint-disable-line no-magic-numbers
          !this.isNull(transport.links[0].destination) && // eslint-disable-line no-magic-numbers
          !this.isNull(transport.links[0].destination.name) // eslint-disable-line no-magic-numbers
        ) {
          name = transport.links[0].destination.name // eslint-disable-line no-magic-numbers
        }
      } else {
        name = transport.destination.name
      }
      return name
    },
    /**
     * 運行路線種別
     * @return {String} 運行路線種別
     */
    transportType() {
      return this.moveSection.transport.type
    },
    /**
     * おすすめ乗車位置を表示するかどうか
     * @return {Boolean} true:表示 false:表示しない
     */
    isShowGetoff() {
      switch (this.moveSection.move) {
        case this.$config.MOVE.SUPEREXPRESS_TRAIN:
        case this.$config.MOVE.SLEEPER_ULTRAEXPRESS:
        case this.$config.MOVE.ULTRAEXPRESS_TRAIN:
        case this.$config.MOVE.EXPRESS_TRAIN:
        case this.$config.MOVE.RAPID_TRAIN:
        case this.$config.MOVE.SEMIEXPRESS_TRAIN:
        case this.$config.MOVE.LOCAL_TRAIN:
          return true
        default:
          return false
      }
    },
    /**
     * おすすめ乗車位置
     * @return {String} おすすめ乗車位置
     */
    getoff() {
      return this.moveSection.transport.getoff
    },
    /**
     * ホーム情報と列車種別の表示エリアを表示するかどうか
     * @return {Boolean} true:表示 false:表示しない
     */
    isShowStartPlatformAndTypeArea() {
      return (
        !this.isNull(this.startPlatform) || !this.isNull(this.transportType)
      )
    },
  },
  methods: {
    /**
     * 天気情報が存在するかどうか (時間毎の天気が存在するかで判断)
     * @param {Object} spotWeather 天気情報
     * @returns 天気情報の存在有無
     */
    existWeather(spotWeather) {
      if (spotWeather === undefined) return false

      return spotWeather.daily.length > 0 // eslint-disable-line no-magic-numbers
    },
  },
}
export default RouteDetailCard
</script>
<style scoped>
.customBorder {
  width: 100%;
  border-bottom: 1px solid #dbdee3;
}
.customBorder::before {
  content: '';
  position: absolute;
  width: 20px;
  left: -20px;
  border-bottom: 1px solid #dbdee3;
}
</style>
