<template>
  <div class="text-[0]">
    <label>
      <input type="checkbox" class="hidden" v-model="isChecked" />
      <div class="inline-flex items-start">
        <div class="w-fit h-fit m-w-fit m-h-fit">
          <svg
            v-if="isChecked"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            class="fill-primary"
            :style="checkStyle"
          >
            <rect width="16" height="16" rx="4" fill="#fff" />
            <path
              d="M126.644-828.467l6.422-6.444-.956-.956-5.467,5.489L124-833.022l-.956.956ZM121.333-824a1.279,1.279,0,0,1-.933-.4,1.279,1.279,0,0,1-.4-.933v-13.333a1.279,1.279,0,0,1,.4-.933,1.279,1.279,0,0,1,.933-.4h13.333a1.279,1.279,0,0,1,.933.4,1.279,1.279,0,0,1,.4.933v13.333a1.279,1.279,0,0,1-.4.933,1.279,1.279,0,0,1-.933.4Z"
              transform="translate(-120 840)"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            class="fill-gray500"
            :style="checkStyle"
          >
            <rect width="16" height="16" rx="4" fill="#fff" />
            <path
              d="M121.333-824a1.279,1.279,0,0,1-.933-.4,1.279,1.279,0,0,1-.4-.933v-13.333a1.279,1.279,0,0,1,.4-.933,1.279,1.279,0,0,1,.933-.4h13.333a1.279,1.279,0,0,1,.933.4,1.279,1.279,0,0,1,.4.933v13.333a1.279,1.279,0,0,1-.4.933,1.279,1.279,0,0,1-.933.4Zm0-1.333h13.333v-13.333H121.333Z"
              transform="translate(-120 840)"
            />
          </svg>
        </div>
        <div class="text-W4 ml-2 text-left" :style="labelStyle">
          {{ label }}
        </div>
      </div>
    </label>
  </div>
</template>
<script>
/**
 * チェックボックスのコンポーネント
 */
const BaseCheckBox = {
  name: 'BaseCheckBox',
  props: {
    /**
     * チェックボックス右に表示するラベル
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * 値
     */
    modelValue: {
      type: Boolean,
      required: true,
    },
    /**
     * チェックボックスの大きさ(単位はピクセル)
     */
    checkboxSize: {
      type: Number,
      default: 16,
    },
    /**
     * フォントサイズ(単位はピクセル)
     */
    fontSize: {
      type: Number,
      default: 13,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    /**
     * チェック状態
     */
    isChecked: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
    /**
     * チェックマークのスタイル
     */
    checkStyle() {
      return {
        width: `${this.checkboxSize}px`,
        height: `${this.checkboxSize}px`,
      }
    },
    /**
     * ラベルのスタイル
     */
    labelStyle() {
      return {
        'font-size': `${this.fontSize}px`,
        'line-height': `${this.checkboxSize}px`,
      }
    },
  },
}
export default BaseCheckBox
</script>
