import auth from '@/auth/createAuth/index.js'
import {registerCallback} from '@/auth/AuthorizationInteractor.js'
import {waitForLoading} from '@/auth/util.js'
import log from 'loglevel'

/**
 * Authの処理を行うための初期化処理
 */
const init = () => {
  const authorizationInteractorCallback = {
    /**
     * アクセストークンを取得
     * @returns {String} アクセストークン
     */
    getAccessToken: async () => {
      let accessToken = ''
      await waitForLoading()
      accessToken = await auth.getAccessTokenSilently()

      return accessToken
    },
    /**
     * ログイン状態を取得
     * @returns {Boolean} ログイン状態
     */
    isAuthenticated: async () => {
      await waitForLoading()
      try {
        const accessToken = await auth.getAccessTokenSilently()
        if (accessToken) return true
        return false
      } catch (e) {
        log.debug(e.error)
        return false
      }
    },
    /**
     * メール承認状態を取得
     * @returns {Boolean} メール承認状態
     */
    isEmailVerified: async () => {
      let emailVerified = false
      await waitForLoading()
      const idTokenClaims = auth.idTokenClaims.value
      if (idTokenClaims.email_verified) {
        emailVerified = true
      }

      return emailVerified
    },
    /**
     * ログアウトする
     * @param {boolean} force 強制ログアウトを行うかフラグ
     */
    logout: async function ({force = false} = {}) {
      if (force || (await this.isAuthenticated())) {
        auth.logout({
          logoutParams: {returnTo: window.location.origin},
        })
      }
    },
    /**
     * ログインする
     * ※Auth画面に強制遷移
     */
    login: async function () {
      const appState = {
        target: '/?loggedIn=true',
      }
      const authorizationParams = {
        // 強制Auth画面表示
        prompt: 'login',
      }
      auth.loginWithRedirect({appState, authorizationParams})
    },
    /**
     * サインアップする
     * ※Auth画面に強制遷移
     */
    signup: async function () {
      const appState = {
        target: '/?loggedIn=true',
      }
      const authorizationParams = {
        // 強制Auth画面表示
        prompt: 'login',
        // 新規登録画面
        // eslint-disable-next-line camelcase
        screen_hint: 'signup',
      }
      auth.loginWithRedirect({appState, authorizationParams})
    },
    /**
     * Auth0の登録メールアドレスを取得
     * @returns {String} Auth0の登録メールアドレス
     */
    getAuth0EmailAddress: function () {
      const email = auth.user.value.email
      return email
    },
  }

  registerCallback(authorizationInteractorCallback)
}

export {init}
