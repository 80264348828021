<template>
  <div :id="getOuterId" class="item-initial" :class="getItemClass">
    <div
      :id="getInnerId"
      class="w-full h-full py-4"
      @click="onClickMobilityCard()"
    >
      <div class="flex px-3">
        <img :src="getCardIcon" class="w-7 h-7 mt-auto pr-1" />
        <div class="flex-1">
          <div class="text-W4 mx-auto leading-[12px]" :class="getTitleClass">
            {{ getTitle }}
          </div>
          <div v-if="summaryTime != null" class="flex justify-center mt-1">
            <div class="h-[23px]">
              <span v-if="isExistHour" class="mr-1">
                <span class="text-W6 text-[21px] leading-[23px]">
                  {{ timeHour }}
                </span>
                <span class="text-W5 text-[14px] leading-[14px]">時間</span>
              </span>
              <span class="text-W6 text-[21px] leading-[23px]">
                {{ timeMinute }}
              </span>
              <span class="text-W5 text-[14px] leading-[14px] mt-[6px]">
                {{ '分' }}
              </span>
            </div>
          </div>
          <div v-else>
            <div class="text-W6 text-[21px] leading-[23px]">-</div>
          </div>
        </div>
      </div>
      <div class="flex">
        <!-- ダミーの要素 -->
        <div class="flex-1 h-1 max-w-[28px]" />
        <!-- 追加情報 -->
        <div
          v-if="isShowFare"
          class="text-gray500 text-W4 mx-auto mt-1 leading-3"
        >
          {{ getFare }}
        </div>
        <div
          v-if="isBicycleCard"
          class="text-gray500 text-W4 mx-auto mt-1 leading-3"
        >
          （{{ calorieInfo }}）
        </div>
        <div v-if="isWalkCard" class="flex-2 text-gray500 text-W4 mx-auto mb-4">
          （{{ calorieInfo }} {{ stepInfo }}）
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * ルート検索 最適画面の各モビリティカードコンポーネント
 */
import Util from '@/mixins/util'
const MobiltyCard = {
  name: 'MobiltyCard',
  components: {},
  mixins: [Util],
  emits: ['card-tap', 'completed-circle-animation', 'completed-animation'],
  props: {
    /**
     * カード種別
     */
    type: {
      type: String,
      require: true,
    },
    /**
     * 移動時間
     */
    summaryTime: {
      type: Number,
      require: true,
    },
    /**
     * 料金
     */
    fare: {
      type: Number,
      require: false,
      default: undefined,
    },
    /**
     * カロリー
     */
    calorie: {
      type: Number,
      require: false,
      default: undefined,
    },
    /**
     * 歩数
     */
    step: {
      type: Number,
      require: false,
      default: undefined,
    },
    /**
     * アニメーション要否
     */
    isAnimation: {
      type: Boolean,
      require: false,
      default: true,
    },
  },
  data() {
    return {
      // 回転アニメーション回数
      animationCount: 1,
      // キャンセル済みかどうか
      isCanceled: false,
    }
  },
  mounted() {
    if (this.isAnimation) {
      // 内枠を非表示にする
      document.getElementById(this.getInnerId).classList.add('invisible')
      // アニメーションの周期をカウントするリスナーを設定
      document
        .getElementById(this.getOuterId)
        .addEventListener('animationiteration', this.onCircleAnimationIteration)
    }
  },
  computed: {
    /**
     * 外枠のID取得
     */
    getOuterId() {
      return `outer-${this.type}`
    },
    /**
     * 内枠のID取得
     */
    getInnerId() {
      return `inner-${this.type}`
    },
    /**
     * 各アイテムの追加クラスを設定
     * 各アイテムのアニメーション関連のクラスを付与する
     */
    getItemClass() {
      let classList = `${this.type}-setting common-animation-setting`
      if (this.isAnimation) {
        return `${classList} ${this.type}-circle-animation`
      } else {
        return `${classList} ${this.type}-non-anim-setting`
      }
    },
    /**
     * タイトル取得
     */
    getTitle() {
      switch (this.type) {
        case 'train':
          return '電車・バス'
        case 'car':
          return '車・タクシー'
        case 'bicycle':
          return '自転車'
        case 'walk':
          return '歩き'
        default:
          return ''
      }
    },
    /**
     * タイトルのクラス取得
     */
    getTitleClass() {
      switch (this.type) {
        case 'train':
          return 'text-blue'
        case 'car':
          return 'text-[#00A33E]'
        case 'bicycle':
          return 'text-[#E8374A]'
        case 'walk':
          return 'text-[#F39800]'
        default:
          return ''
      }
    },
    /**
     * カードのアイコンを取得する
     */
    getCardIcon() {
      switch (this.type) {
        case 'train':
          return require('@/assets/route/Icon_TrainBus.svg')
        case 'car':
          return require('@/assets/route/Icon_Car.svg')
        case 'bicycle':
          return require('@/assets/route/Icon_Bicycle.svg')
        case 'walk':
          return require('@/assets/route/Icon_Walk.svg')
        default:
          return ''
      }
    },
    /**
     * 料金を表示するかどうか
     */
    isShowFare() {
      return this.type == 'train' || this.type == 'car'
    },
    /**
     * 料金を返却する
     * ない場合は-円を返却
     * 車の場合は最後に〜を付与する
     */
    getFare() {
      // 金額データの有無を確認
      const isFare = this.fare != undefined
      // フォーマットした金額を返却
      const fare = isFare ? this.addCommaForInteger(this.fare) : ' - '
      // 対象が「車」ならチルダマークを付与
      const isTilde = this.type == 'car' && isFare ? '〜' : ''

      return `（${fare}円${isTilde}）`
    },
    /**
     * 自転車カードかどうか
     */
    isBicycleCard() {
      return this.type == 'bicycle'
    },
    /**
     * カロリーを返却する
     * ない場合は-を返却
     */
    calorieInfo() {
      // カロリーデータの有無を確認
      const isCalorie = this.calorie != undefined
      // フォーマットしたカロリーを返却
      const calorie = isCalorie ? this.addCommaForInteger(this.calorie) : ' - '

      return `${calorie}kcal`
    },
    /**
     * 歩きカードかどうか
     */
    isWalkCard() {
      return this.type == 'walk'
    },
    /**
     * 歩数を返却する
     * ない場合は表示しない
     */
    stepInfo() {
      // 歩数ーデータの有無を確認
      const isStep = this.step != undefined
      // フォーマットした歩数を返却
      const step = isStep ? this.addCommaForInteger(this.step) : ' - '

      return `${step}歩`
    },
    /**
     * 移動時間(h)の値を算出
     */
    timeHour() {
      const hour = 60
      return Math.floor(this.summaryTime / hour)
    },
    /**
     * 移動時間(m)の値を算出
     */
    timeMinute() {
      const hour = 60
      return this.summaryTime % hour
    },
    /**
     * 時間の単位が存在するかどうか
     */
    isExistHour() {
      return 0 < this.timeHour // eslint-disable-line no-magic-numbers
    },
  },
  methods: {
    /**
     * ローディング終了時処理
     * @param {Boolean} isCancel キャンセルの場合はtrue
     */
    finishLoading(isCancel) {
      // キャンセルの場合はキャンセル済みにする
      this.isCanceled = isCancel

      // 周期検知リスナーを解除
      document
        .getElementById(this.getOuterId)
        .removeEventListener(
          'animationiteration',
          this.onCircleAnimationIteration
        )
      // 周期カウントを設定する
      document
        .getElementById(this.getOuterId)
        .style.setProperty('--count', this.animationCount)
      // 回転アニメーション終了を検知する
      document
        .getElementById(this.getOuterId)
        .addEventListener('animationend', this.onCircleAnimationEnd)
    },

    /**
     * ローディング再実行時処理
     */
    restartLoading() {
      // 回転アニメーション周期カウントの初期化
      document.getElementById(this.getOuterId).style.setProperty(
        '--count',
        99999999 // eslint-disable-line no-magic-numbers
      )
      this.animationCount = 1

      // ローディングアニメーション系を全て削除
      document
        .getElementById(this.getInnerId)
        .classList.remove('inner-animation')
      document
        .getElementById(this.getOuterId)
        .classList.remove(`${this.type}-circle-animation`)
      document
        .getElementById(this.getOuterId)
        .classList.remove(`${this.type}-square-animation`)

      // 内枠を非表示にする
      document.getElementById(this.getInnerId).classList.add('invisible')

      // アニメーションの周期をカウントするリスナーを設定
      document
        .getElementById(this.getOuterId)
        .addEventListener('animationiteration', this.onCircleAnimationIteration)

      // 回転アニメーションを付与
      document
        .getElementById(this.getOuterId)
        .classList.add(`${this.type}-circle-animation`)
    },

    /**
     * アニメーションの周期通知
     */
    onCircleAnimationIteration() {
      // 1周期ごとにカウントアップする
      this.animationCount++
    },
    /**
     * 回転アニメーション終了時処理
     */
    onCircleAnimationEnd() {
      // 回転アニメーション完了を親に通知する
      this.$emit('completed-circle-animation')

      // 各リスナーを解除
      document
        .getElementById(this.getOuterId)
        .removeEventListener('animationend', this.onCircleAnimationEnd)

      // キャンセルの場合はここまで
      if (this.isCanceled) {
        return
      }

      // 四角に広がるアニメーションの終了検知リスナーを設定
      document
        .getElementById(this.getOuterId)
        .addEventListener('animationend', this.onSquareAnimationEnd)

      // 四角に広がるアニメーションの設定を付与
      document
        .getElementById(this.getOuterId)
        .classList.add(`${this.type}-square-animation`)
    },
    /**
     * 四角に広がるアニメーション終了時処理
     */
    onSquareAnimationEnd() {
      // 各リスナーを解除
      document
        .getElementById(this.getOuterId)
        .removeEventListener('animationend', this.onSquareAnimationEnd)
      // 内枠アニメーションの終了検知リスナーを設定
      document
        .getElementById(this.getInnerId)
        .addEventListener('animationend', this.onCompleteAnimation)
      // 内枠アニメーションを付与
      document.getElementById(this.getInnerId).classList.add('inner-animation')
    },
    /**
     * 全てのアニメーションが完了した際の処理
     */
    onCompleteAnimation() {
      // リスナーを解除する
      document
        .getElementById(this.getInnerId)
        .removeEventListener('animationend', this.onCompleteAnimation)
      // 親にアニメーション完了を通知する
      this.$emit('completed-animation')
    },
    /**
     * モビリティカードのクリック処理
     */
    onClickMobilityCard() {
      this.$emit('card-tap')
    },
  },
}
export default MobiltyCard
</script>
<style scoped>
/** 一つのアイテムの初期設定 */
.item-initial {
  --count: 99999999; /**アニメーションの回数（外部から書き換える。初期値は無限に近くしておく。） */
  --top: calc(50%);
  --left: calc(50% - 9px); /** 9pxは調整値 */
  --startWidth: 16px;
  --startHeight: 16px;
  --endWidth: calc((100vw - 51px) / 2);
  --endUpperHeight: 87px;
  --endLowerHeight: 87px;

  position: absolute;
  border-width: 0px;
  border-style: solid;
}

/** 各アイテムの共通設定（アニメーションあり時） */
.common-animation-setting {
  top: var(--top);
  left: var(--left);
  width: var(--startWidth);
  height: var(--startHeight);
  border-radius: 50%;
}

/**各アイテムの設定 */
/* 電車・バス 設定 */
.train-setting {
  border-color: #009ce580;
  background: #009ce580;
  transform: translateX(-14px) translateY(-14px);
}
/* 車 設定 */
.car-setting {
  border-color: #00a33e80;
  background: #00a33e80;
  transform: translateX(14px) translateY(-14px);
}
/* 自転車 設定 */
.bicycle-setting {
  border-color: #d11f2e80;
  background: #d11f2e80;
  transform: translateX(-14px) translateY(14px);
}
/* 徒歩 設定 */
.walk-setting {
  border-color: #fcc80080;
  background: #fcc80080;
  transform: translateX(14px) translateY(14px);
}

/* アニメーションなし時設定 */
/* 電車のアニメーションなし時設定 */
.train-non-anim-setting {
  animation: color-change-anim-frame-train 0s linear both,
    square-anim-train 0s ease-out 0s both;
}
/* 車のアニメーションなし時設定 */
.car-non-anim-setting {
  animation: color-change-anim-frame-car 0s linear both,
    square-anim-car 0s ease-out 0s both;
}
/* 自転車のアニメーションなし時設定 */
.bicycle-non-anim-setting {
  animation: color-change-anim-frame-bicycle 0s linear both,
    square-anim-bicycle 0s ease-out 0s both;
}
/* 徒歩のアニメーションなし時設定 */
.walk-non-anim-setting {
  animation: color-change-anim-frame-walk 0s linear both,
    square-anim-walk 0s ease-out 0s both;
}

/* 回転アニメーション */
/* 回転アニメーション 電車・バス */
.train-circle-animation {
  animation: circle-anim-frame-train 0.6s linear;
  animation-iteration-count: var(--count);
}
/* 回転アニメーション 車 */
.car-circle-animation {
  animation: circle-anim-frame-car 0.6s linear;
  animation-iteration-count: var(--count);
}
/* 回転アニメーション 自転車 */
.bicycle-circle-animation {
  animation: circle-anim-frame-bicycle 0.6s linear;
  animation-iteration-count: var(--count);
}
/* 回転アニメーション 徒歩 */
.walk-circle-animation {
  animation: circle-anim-frame-walk 0.6s linear;
  animation-iteration-count: var(--count);
}
/* 四角に広がるアニメーション */
/* 四角に広がるアニメーション 電車・バス */
.train-square-animation {
  animation: color-change-anim-frame-train 0.2s ease-out both,
    square-anim-train 0.2s ease-out 0.2s both;
}
/* 四角に広がるアニメーション 車 */
.car-square-animation {
  animation: color-change-anim-frame-car 0.2s ease-out both,
    square-anim-car 0.2s ease-out 0.2s both;
}
/* 四角に広がるアニメーション 自転車 */
.bicycle-square-animation {
  animation: color-change-anim-frame-bicycle 0.2s ease-out both,
    square-anim-bicycle 0.2s ease-out 0.2s both;
}
/* 四角に広がるアニメーション 徒歩 */
.walk-square-animation {
  animation: color-change-anim-frame-walk 0.2s ease-out both,
    square-anim-walk 0.2s ease-out 0.2s both;
}
/* 内枠のアニメーション */
.inner-animation {
  animation: inner-anim 0.4s ease-out 0.4s both;
}

/* 回転アニメーション */
@keyframes circle-anim-frame-train {
  0% {
    transform: rotate(0deg) translateX(-14px) translateY(-14px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(-14px) translateY(-14px)
      rotate(-360deg);
  }
}
@keyframes circle-anim-frame-car {
  0% {
    transform: rotate(0deg) translateX(14px) translateY(-14px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(14px) translateY(-14px) rotate(-360deg);
  }
}
@keyframes circle-anim-frame-bicycle {
  0% {
    transform: rotate(0deg) translateX(-14px) translateY(14px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(-14px) translateY(14px) rotate(-360deg);
  }
}
@keyframes circle-anim-frame-walk {
  0% {
    transform: rotate(0deg) translateX(14px) translateY(14px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(14px) translateY(14px) rotate(-360deg);
  }
}

/* 玉の色を変えるアニメーション */
@keyframes color-change-anim-frame-train {
  100% {
    border-width: 2px;
    background: #f2f7fc;
  }
}
@keyframes color-change-anim-frame-car {
  100% {
    border-width: 2px;
    background: #f7fffb;
  }
}
@keyframes color-change-anim-frame-bicycle {
  100% {
    border-width: 2px;
    background: #fff7f7;
  }
}
@keyframes color-change-anim-frame-walk {
  100% {
    border-width: 2px;
    background: #fbfbf5;
  }
}

/** 四角に広がるアニメーション */
@keyframes square-anim-train {
  100% {
    width: var(--endWidth);
    height: var(--endUpperHeight);
    top: calc(var(--top) - (var(--endUpperHeight) - var(--startHeight)));
    left: calc(var(--left) - (var(--endWidth) - var(--startWidth)));
    border-radius: 10px;
  }
}
@keyframes square-anim-car {
  100% {
    width: var(--endWidth);
    height: var(--endUpperHeight);
    top: calc(var(--top) - (var(--endUpperHeight) - var(--startHeight)));
    border-radius: 10px;
  }
}
@keyframes square-anim-bicycle {
  100% {
    width: var(--endWidth);
    height: var(--endLowerHeight);
    left: calc(var(--left) - (var(--endWidth) - var(--startWidth)));
    border-radius: 10px;
  }
}
@keyframes square-anim-walk {
  100% {
    width: var(--endWidth);
    height: var(--endLowerHeight);
    border-radius: 10px;
  }
}

/** 各アイテム内部のアニメーション */
@keyframes inner-anim {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
</style>
