<template>
  <div
    id="loading"
    class="la-ball-clip-rotate loading"
    :class="addClass"
    :style="picScale"
  >
    <div></div>
  </div>
</template>

<script>
const LoadingView = {
  name: 'LoadingView',
  props: {
    scale: {
      type: Number,
      required: false,
      default: 1,
    },
    addClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    picScale() {
      return {
        '--pic_scale': this.scale,
      }
    },
  },
}
export default LoadingView
</script>
<style scoped>
.loading {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  color: #529def;
}
.la-ball-clip-rotate,
.la-ball-clip-rotate > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.la-ball-clip-rotate {
  display: block;
  font-size: 0;
}
.la-ball-clip-rotate > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.la-ball-clip-rotate {
  width: calc(64px * var(--pic_scale));
  height: calc(64px * var(--pic_scale));
}
.la-ball-clip-rotate > div {
  width: calc(64px * var(--pic_scale));
  height: calc(64px * var(--pic_scale));
  background: transparent;
  border-width: calc(4px * var(--pic_scale));
  border-bottom-color: transparent;
  border-radius: 100%;
  -webkit-animation: ball-clip-rotate 0.75s linear infinite;
  -moz-animation: ball-clip-rotate 0.75s linear infinite;
  -o-animation: ball-clip-rotate 0.75s linear infinite;
  animation: ball-clip-rotate 0.75s linear infinite;
}
/*
 * Animation
 */
@-webkit-keyframes ball-clip-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes ball-clip-rotate {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes ball-clip-rotate {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes ball-clip-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
