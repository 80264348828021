<template>
  <div class="overflow-hidden" ref="panelContents">
    <Teleport to="body">
      <transition name="slide" v-on:after-enter="automaticGrantFocus()">
        <BasePanel
          v-if="isShowSuggest"
          :spaceHeight="panelSpaceHeight"
          :isOverflowYAuto="true"
          :isRoundedTop="false"
          :isShowShadow="true"
          ref="basePanel"
        >
          <template v-slot:body>
            <div class="h-full flex flex-col">
              <!--
            TODO:
              TextForm コンポーネントを変更すると他viewに影響が出るため、一旦 TextFormV2コンポーネントを新規作成
              デザイン改修終わり次第V2をとり、TextForm コンポーネントに戻す
          -->
              <TextFormV2
                class="searchText mx-5 pb-3"
                inputClass="bg-gray200 !border-[0px]"
                placeholder="住所を検索"
                @input-text="onchangeText($event)"
                @click-back-button="$emit('click-back-button')"
                :maxlength="'100'"
                ref="textForm"
              />
              <div
                v-if="isEditing"
                class="overflow-y-auto scroll-bar-none pb-10 flex-1"
              >
                <AddressSuggestList
                  class="address-suggest-list"
                  :address="word"
                  @click-address-suggest="clickAddressSuggestList($event)"
                />
                <SuggestList
                  :spots="spots"
                  @click-suggest="completeForm($event)"
                  class="suggest-list"
                />
              </div>
              <template v-else>
                <div class="overflow-y-auto scroll-bar-none pb-10 flex-1">
                  <div
                    class="mt-5 mb-3 ml-5 text-W7 text-left text-[13px] leading-none"
                  >
                    履歴
                  </div>
                  <SpotHistoryList
                    :spotHistoryList="spotHistoryList"
                    @click-spot-history="completeForm($event)"
                    class="history-list"
                  />
                </div>
              </template>
            </div>
          </template>
        </BasePanel>
      </transition>
    </Teleport>
    <!-- 住所取得エラーポップアップ -->
    <Teleport to="body">
      <Modal
        v-if="isNoAddressPopupFlg"
        class="modal text-center"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="px-4 pt-9 pb-4">
          <div class="pb-5 text-W5 text-[17px] leading-[17px]">
            選択した住所が存在しません。
          </div>
          <div
            class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
            @click="isNoAddressPopupFlg = false"
          >
            OK
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>
<script>
import TextFormV2 from '@/components/TextFormV2.vue'
import SuggestList from '@/components/SuggestList.vue'
import SpotHistoryList from '@/components/organisms/searchRoute/SpotHistoryList.vue'
import AddressSuggestList from '@/components/AddressSuggestList.vue'
import Modal from '@/components/Modal.vue'
import BasePanel from '@/components/atoms/BasePanel.vue'
import util from '@/mixins/util'
import searchRouteUtil from '@/mixins/searchRouteUtil'

const LOAD_SPOT_API_SEC = 100

const TaxiReservationSuggest = {
  name: 'TaxiReservationSuggest',
  components: {
    TextFormV2,
    SuggestList,
    SpotHistoryList,
    AddressSuggestList,
    Modal,
    BasePanel,
  },
  mixins: [util, searchRouteUtil],
  emits: ['click-back-button', 'select-spot'],
  props: {
    // 地点検索表示フラグ
    isShowSuggest: {
      type: Boolean,
      default: false,
    },
    // パネルの昇降位置調整
    panelSpaceHeight: {
      type: Number,
      require: false,
      default: 44,
    },
  },
  data() {
    return {
      word: '', // 入力テキスト
      spots: [], // スポット検索候補一覧
      timer: 0, // タイマーの識別情報
      isEditing: false, // true:入力中、false:未入力
      spotHistoryList: this.getSpotHistoryList(), // 検索履歴一覧
      isNoAddressPopupFlg: false, // 住所検索エラーポップアップフラグ
    }
  },
  watch: {
    /**
     * 地点検索画面表示状態の監視
     */
    isShowSuggest: {
      immediate: true,
      handler(value) {
        // 表示に切り替わった時に、値を初期化し、スクロール領域の再計算を行う
        this.$nextTick(() => {
          if (value) {
            this.$refs.textForm.reset()
            this.word = ''
            this.isEditing = false
            this.spots = []
          }
        })
      },
    },
  },
  methods: {
    /**
     * 初回展開時のオートフォーカス付与
     */
    automaticGrantFocus() {
      this.$refs.textForm.focusInputForm()
    },
    /**
     * 入力文字の内容を用いて地点検索を行う
     * @param {String} value 検索文字
     */
    onchangeText(value) {
      this.word = value
      clearTimeout(this.timer)

      //入力中かの判定
      this.isEditing = value != ''
      if (!this.isEditing) {
        // 入力文字を削除して空文字（未入力状態）にした場合、スポット検索しない
        return
      }
      const vm = this
      this.spots = []
      const success = (spots) => {
        vm.spots = spots
      }
      this.timer = setTimeout(() => {
        vm.$store.dispatch('getSpot', {
          success: success,
          word: vm.word,
        })
      }, LOAD_SPOT_API_SEC)
    },
    /**
     * 住所検索
     * @param value 住所検索キーワード
     */
    clickAddressSuggestList(value) {
      if (this.isNull(value)) {
        // 空文字（未入力状態）の場合、住所検索しない
        return
      }

      this.$store.commit('startLoading')

      const vm = this
      const success = (addressInfo) => {
        vm.$store.commit('endLoading')
        // 検索結果が0件の場合はエラー表示
        if (addressInfo == null) {
          // 住所が見つからなければエラー表示
          vm.isNoAddressPopupFlg = true
          return
        }
        const searchAddressSpot = {
          name: addressInfo.name,
          address: addressInfo.name,
          coord: `${addressInfo.coord.lat},${addressInfo.coord.lon}`,
        }
        vm.completeForm(searchAddressSpot)
      }

      // 入力フォームにフォーカスが当たっていれば外す
      this.$refs.textForm.blurInputForm()

      //住所検索を実行
      this.$store.dispatch('getAddress', {
        success: success,
        word: value,
      })
    },
    /**
     * ストレージに履歴一覧を反映した後、親コンポーネントに選択した地点情報を通知
     * @param {Object} value 選択した地点情報
     */
    completeForm(value) {
      // ローカルストレージに格納
      this.spotHistoryList = this.setSpotHistoryList(value)
      let updateValue = structuredClone(value)

      if (value.buildingName != null && value.buildingName != '') {
        updateValue.address = updateValue.address.concat(
          ' ',
          updateValue.buildingName
        )
      }
      this.$emit('select-spot', updateValue)
    },
  },
}
export default TaxiReservationSuggest
</script>
<style scoped>
/* パネル昇降のトランジション */
.slide-enter-active,
.slide-leave-active {
  transform: translate(0px, 0px) translateZ(1px);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(100vh) translateY(0px) translateZ(1px);
}

.history-list :deep(.history-col),
.address-suggest-list,
.suggest-list :deep(.suggest-col) {
  padding-right: 20px;
  padding-left: 20px;
}
</style>
