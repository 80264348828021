<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <path
      d="M10.386,10.225a.712.712,0,0,0-.058-.286.805.805,0,0,0-.173-.249L4.776,4.434a.666.666,0,0,0-.5-.21.711.711,0,0,0-.369.094.737.737,0,0,0-.257.253.717.717,0,0,0,.119.875l4.887,4.778L3.772,15a.711.711,0,0,0-.119.871.727.727,0,0,0,.257.257.711.711,0,0,0,.369.094.666.666,0,0,0,.5-.21l5.378-5.255a.711.711,0,0,0,.177-.249A.753.753,0,0,0,10.386,10.225Z"
      transform="translate(-0.971 -4.225)"
      fill="currentColor"
    />
    <rect width="12" height="12" fill="none" />
  </svg>
</template>
