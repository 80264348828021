<template>
  <div id="notification-list" class="fixed-app flex flex-col bg-gray200">
    <!-- ヘッダー部 -->
    <Header
      headerTitle="お知らせ"
      :isShowPrevious="true"
      @clickPrevious="gotoMenuPage()"
    />
    <div class="overflow-y-auto scroll-bar-none flex-1 px-5 pt-6 pb-10">
      <img
        class="m-auto h-14 w-14 mb-6"
        src="@/assets/menu/notification_post.svg"
      />
      <!-- お知らせがない場合 -->
      <div v-if="isNoMessage" class="text-W4 text-[11px] text-gray400">
        お知らせはありません
      </div>
      <!-- お知らせがある場合 -->
      <div v-else>
        <!-- メッセージ -->
        <div class="bg-white rounded-[8px]">
          <div
            class="p-4 border-gray300 text-left border-b-[0.5px] last:border-b-0 flex items-center"
            v-for="message in messages"
            :key="message.messageId"
            @click="gotoNotificationDetail(message)"
          >
            <!-- お知らせアイコン -->
            <img
              :src="notificationIcon(message.category)"
              class="h-8 w-8 mr-2"
            />
            <div class="flex-1 overflow-hidden mr-4">
              <div class="flex items-center mb-2 h-[15px]">
                <div class="text-W4 text-[13px] leading-[13px] text-gray mr-2">
                  {{ getFormatYearDateSlash(message.distributionStartDate) }}
                </div>
                <NotificationImportantTag
                  v-if="message.important"
                  class="h-full"
                />
              </div>
              <div class="text-W4 text-[15px] leading-[15px] truncate">
                {{ message.title }}
              </div>
            </div>
            <!-- 右側アイコン -->
            <div>
              <img
                v-if="judgeReadMessage(message.messageId)"
                src="@/assets/Icon_Right_gray.svg"
                class="h-3 w-3"
              />
              <div v-else class="badge-blue" />
            </div>
          </div>
        </div>
        <div class="mt-4 text-W4 text-[11px] text-gray400 text-left">
          最新30件を表示
        </div>
      </div>
    </div>
    <FooterMargin />
  </div>
</template>

<script>
import Header from '@/components/organisms/Header.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import NotificationImportantTag from '@/components/icons/menu/NotificationImportantTag.vue'
import Util from '@/mixins/util'

/**
 * お知らせ通知一覧画面
 */
const NotificationList = {
  name: 'NotificationList',
  components: {Header, FooterMargin, NotificationImportantTag},
  mixins: [Util],
  computed: {
    /**
     * お知らせ通知一覧
     */
    messages: {
      get() {
        return this.$store.state.notificationList.notification || []
      },
    },
    /**
     * お知らせ通知が存在しないかどうか
     */
    isNoMessage: {
      get() {
        const noMessages = 0
        return this.messages.length === noMessages
      },
    },
  },
  watch: {
    messages: {
      deep: true,
      immediate: true,
      handler: function () {
        // 初回読み込み時、お知らせ更新時のタイミングでストレージのお知らせ表示済み情報を更新
        this.updateDisplayNotificationMessages()
      },
    },
  },
  methods: {
    /**
     * お知らせアイコン取得処理
     * @param {String} category カテゴリ
     * @return {Boolean} お知らせアイコン
     */
    notificationIcon(category) {
      switch (category) {
        case this.$config.PAYMENT_NG: // 未決済時
          return require('@/assets/menu/notification_paymentNG.svg')
        default: // デフォルト値はインフォメーションアイコン
          return require('@/assets/menu/notification_information.svg')
      }
    },
    /**
     * ストレージの表示済みメッセージ情報を更新
     */
    updateDisplayNotificationMessages() {
      // 表示済みお知らせ通知ID一覧(LocalStorage)
      const key = this.$config.DISPLAY_NOTIFICATION_MESSAGES
      const displayedMessageIds = this.getLocalStorage(key) || []
      // 最新のお知らせ通知ID一覧(Store)
      const latestMessageIds = this.messages.map((item) => item.messageId)
      // 上記をマージして更新
      const newDisplayedMessageIds = Array.from(
        new Set([...displayedMessageIds, ...latestMessageIds])
      )
      this.setLocalStorage(key, newDisplayedMessageIds)
    },
    /**
     * メニューTOP画面に遷移する
     */
    gotoMenuPage() {
      this.$router.push({name: this.$config.DISPLAY_MENU_TOP})
    },
    /**
     * お知らせ詳細画面に遷移する
     * @param {Object} message お知らせ情報
     */
    gotoNotificationDetail(message) {
      // Storeに選択したメッセージを保持し、詳細画面に遷移
      this.$store.commit('updateSelectedNotificationMessage', message)
      this.$router.push({name: this.$config.DISPLAY_NOTIFICATION_DETAIL})
    },
    /**
     * 既読メッセージかどうか判断する
     * @param {String} messageId 通知ID
     * @return {Boolean} 既読かどうか
     */
    judgeReadMessage(messageId) {
      // ローカルストレージから既読済みメッセージ一覧を取得
      const readMessageIdList =
        this.getLocalStorage(this.$config.READ_NOTIFICATION_MESSAGES) || []
      // 通知IDが一致すれば、一度既読したと判断
      return readMessageIdList.some((id) => id === messageId)
    },
  },
}
export default NotificationList
</script>

<style scoped>
/* 青のバッジアイコン */
.badge-blue {
  width: 8px;
  height: 8px;
  margin: 2px;
  border-radius: 12px;
  background-color: #0072ff;
}
</style>
