<template>
  <div
    name="FormRadioButton"
    class="flex items-center py-3 px-4 h-10 rounded-[24px] bg-gray200"
    @click="emitGenderKey()"
  >
    <!-- デフォルトラジオボタン非表示のためラベルで括る -->
    <input
      type="radio"
      :value="itemKey"
      :disabled="isDisabled"
      :checked="itemKey === modelValue"
    />
    <span class="flex items-center radio-text"></span>

    <!-- ラベル表示 -->
    <span class="ml-1.5 mr-4 text-W4 text-[15px] whitespace-nowrap">
      {{ label }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'FormRadioButton',
  props: {
    // ラジオボタンの識別子
    itemKey: {
      type: String,
      require: false,
      default: '',
    },
    // ボタンの右側に表示するラベル
    label: {
      type: String,
      require: false,
      default: '',
    },
    // 選択中のラジオボタンの識別子
    modelValue: {
      type: String,
      require: false,
      default: '',
    },
    // ラジオボタンコンポーネントの操作可否状態
    isDisabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  methods: {
    /**
     * 性別情報のkeyを通知する
     */
    emitGenderKey() {
      if (this.isDisabled) {
        return
      }
      this.$emit('update:modelValue', this.itemKey)
    },
  },
}
</script>
<style scoped>
.radio-button input {
  display: none; /* デフォルトラジオボタン非表示 */
}
.radio-button input + .radio-text::before {
  background-image: url(@/assets/RadioOff.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  position: relative;
  width: 12px;
  height: 12px;
}
.radio-button input:checked + .radio-text::before {
  background-image: url(@/assets/RadioOn.svg);
}
</style>
