<template>
  <div class="bg-white w-full" v-cloak>
    <!-- タイトル -->
    <div
      class="h-8 w-full flex justify-between items-baseline"
      @click="isOpened = !isOpened"
    >
      <div class="text-W7 text-[18px] leading-[18px]">{{ title }}</div>
      <img class="h-5 w-5" :src="accordionIcon()" />
    </div>
    <div class="border-b-[1px] border-gray300" />
    <!-- コンテンツ -->
    <div v-if="isOpened" class="text-left pt-4">
      <slot name="AccordionContents" />
    </div>
  </div>
</template>
<script>
/**
 * アコーディオンコンポーネント
 */
const AccordionPanel = {
  name: 'AccordionPanel',
  props: {
    /**
     * アコーディオンタイトル
     */
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpened: false, // 開閉状態
    }
  },
  methods: {
    /**
     * アコーディオンの開閉アイコン
     */
    accordionIcon() {
      // 開いているときは「-」、閉じているときは「＋」を設定
      const iconName = this.isOpened ? 'MinusBlue.svg' : 'PlusBlue.svg'
      return require('@/assets/' + iconName)
    },
  },
}
export default AccordionPanel
</script>
