<template>
  <svg viewBox="0 0 32 32">
    <g transform="translate(-178 -119)">
      <circle
        cx="16"
        cy="16"
        r="16"
        transform="translate(178 119)"
        fill="#f2f3f5"
        class="icon-favorite-office-school-circle"
      />
      <g>
        <rect
          width="18"
          height="18"
          transform="translate(185 126)"
          fill="none"
        />
        <path
          d="M120-824.818v-11.7h3.479V-840H131.7v6.958h3.479v8.223h-6.473V-828.3h-2.235v3.479Zm1.265-1.265h2.214V-828.3h-2.214Zm0-3.479h2.214v-2.214h-2.214Zm0-3.479h2.214v-2.214h-2.214Zm3.479,3.479h2.214v-2.214h-2.214Zm0-3.479h2.214v-2.214h-2.214Zm0-3.479h2.214v-2.214h-2.214Zm3.479,6.958h2.214v-2.214h-2.214Zm0-3.479h2.214v-2.214h-2.214Zm0-3.479h2.214v-2.214h-2.214Zm3.479,10.437h2.214V-828.3H131.7Zm0-3.479h2.214v-2.214H131.7Z"
          transform="translate(66.409 967.409)"
          fill="#0072ff"
          class="icon-favorite-office-school-path"
        />
      </g>
    </g>
  </svg>
</template>
