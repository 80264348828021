<template>
  <Overlay
    pixelOfTitleAndBody="16"
    :isEnableCloseOutsideArea="true"
    @close-overlay="$emit('close-select-box', id)"
  >
    <!-- セレクトボックスのタイトル -->
    <template v-slot:header>
      <div class="pb-2">{{ headerTitle }}</div>
    </template>
    <!-- アイテム一覧 -->
    <template v-slot:body>
      <div class="px-4">
        <BaseBox class="bg-white !rounded-[16px]">
          <template v-slot:explanation>
            <div class="divide-y divide-gray300">
              <div
                v-for="item in selectItems"
                :key="item.id"
                class="text-[15px] text-W5 font-semibold leading-[19px] text-center py-5 px-6"
                @click="$emit('click-item', item)"
              >
                {{ item.label }}
              </div>
            </div>
          </template>
        </BaseBox>
      </div>
    </template>
  </Overlay>
</template>
<script>
import BaseBox from '@/components/atoms/BaseBox.vue'
import Overlay from '@/components/Overlay.vue'

const SelectBox = {
  name: 'SelectBox',
  components: {
    BaseBox,
    Overlay,
  },
  emits: ['close-select-box', 'click-item'],
  props: {
    /**
     * タイトルの表示有無
     */
    headerTitle: {
      type: String,
      default: '',
    },
    /**
     * 選択肢
     */
    selectItems: {
      type: Object,
      required: true,
    },
  },
}
export default SelectBox
</script>
<style scoped></style>
