<template>
  <div>
    <div class="flex w-full text-center">
      <div v-for="tab in tabs" :key="tab.id" class="flex-1">
        <div
          class="text-[13px] leading-[13px] pb-2 border-b-[1px]"
          :class="tabClass(tab.id)"
          @click="$emit('update:modelValue', tab.id)"
        >
          {{ tab.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const BaseTab = {
  name: 'BaseTab',
  props: {
    /**
     * 選択中のタブID
     */
    modelValue: {
      type: Number,
      required: true,
    },
    /**
     * タブ一覧
     * [{id(number), title(string)}]
     */
    tabs: {
      type: Array,
      required: true,
      validator(val) {
        // id(Number)とtitle(String)を持つ配列であるかどうかを判定
        const isValidVal = val.every((el) => {
          return typeof el.id === 'number' && typeof el.title === 'string'
        })
        return isValidVal
      },
    },
  },
  methods: {
    /**
     * タブに付与するクラス
     * 選択中と非選択中の場合でレイアウトを変える
     * @param {Number} id タブID
     * @returns 選択/未選択状態のCSS
     */
    tabClass(id) {
      return this.modelValue === id
        ? 'text-W7 text-primary border-primary' // 選択状態
        : 'text-W4 text-gray400 border-gray300' // 未選択状態
    },
  },
}
export default BaseTab
</script>
<style scoped></style>
