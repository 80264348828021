/* eslint-disable no-magic-numbers */
// ユーザーアイコンの基準サイズ
const USER_ICON_SIZE = 90
// キャンバスの大きさ
const CANVAS_SIZE = USER_ICON_SIZE * 2
// ピン背景の大きさ
const PIN_WIDTH = CANVAS_SIZE * (5 / 6)
const PIN_HEIGHT = CANVAS_SIZE
// ピン背景とアイコンの縁の大きさ
const PIN_OFFSET_X = -4
const PIN_OFFSET_Y = 16
const PIN_EDGE_X = 24
const PIN_EDGE_Y = 6
// ユーザーアイコンの大きさ
const ICON_SIZE = PIN_WIDTH - PIN_EDGE_X * 2
// 余白
const TOP_MARGIN = 0
const LEFT_MARGIN = (CANVAS_SIZE - PIN_WIDTH) / 2 + 1
// ユーザーアイコンの下部余白
const DEFAULT_OFFSET_BOTTOM = 0
/* eslint-enable no-magic-numbers */

/**
 * アイコンの描画
 */
const loadImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.src = url
  })
}

const canvasToBase64 = async (canvas) => {
  return await new Promise((resolve) => {
    // blob形式に変換
    canvas.toBlob((blob) => {
      // base64形式に変換
      var reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        resolve(reader.result)
      }
    })
  })
}

const getPixelRatio = () => {
  const defaultRatio = 1
  const ratio = window.devicePixelRatio
  return ratio || defaultRatio
}

export default {
  methods: {
    /**
     * ユーザーアイコン情報の設定
     * @param {*} storeUserIcon Store内のユーザーアイコン(base64)
     * @param {Number} size アイコンのサイズ
     * @param {Number} offsetY アイコン下部の余白
     * @return {String} 合成した画像データ(base64)
     */
    async createUserIcon(
      storeUserIcon,
      size = USER_ICON_SIZE,
      offsetY = DEFAULT_OFFSET_BOTTOM
    ) {
      const scale = (size / CANVAS_SIZE) * getPixelRatio()
      /**
       * キャンバスの準備
       */
      const canvas = document.createElement('canvas')
      canvas.width = CANVAS_SIZE * scale
      canvas.height = CANVAS_SIZE * scale
      const context = canvas.getContext('2d')
      context.imageSmoothingEnabled = true

      // ベースのピンアイコン読み込み
      const baseImage = await loadImage(
        require('@/assets/map/UserIconBase.svg')
      )
      context.drawImage(
        baseImage,
        LEFT_MARGIN * scale,
        TOP_MARGIN * scale - offsetY,
        PIN_WIDTH * scale,
        PIN_HEIGHT * scale
      )

      // ユーザーアイコン読み込み
      const userIcon = storeUserIcon
        ? storeUserIcon // 登録時：Base64画像
        : require('@/assets/Icon_Guest.svg') // 未登録時：assets画像
      const userIconImage = await loadImage(userIcon)
      context.drawImage(
        userIconImage,
        (PIN_OFFSET_X + PIN_EDGE_X + LEFT_MARGIN) * scale,
        (PIN_OFFSET_Y + PIN_EDGE_Y) * scale - offsetY,
        ICON_SIZE * scale,
        ICON_SIZE * scale
      )

      // 合成した画像データをbase64化
      return await canvasToBase64(canvas)
    },
    /**
     * 画像ファイルを読み込んでbase64化する
     * @param {*} url ファイルのパス
     * @param {*} size
     * @returns
     */
    async imageToBase64(url, {width, height}) {
      const canvas = document.createElement('canvas')
      const ratio = getPixelRatio()
      canvas.width = width * ratio
      canvas.height = height * ratio
      const context = canvas.getContext('2d')
      context.imageSmoothingEnabled = true
      const dx = 0
      const dy = 0
      const img = await loadImage(url)
      context.drawImage(img, dx, dy, canvas.width, canvas.height)
      return await canvasToBase64(canvas)
    },
  },
}
