<template>
  <div class="flex w-full items-center">
    <img class="h-4 w-4" src="@/assets/route/Icon_Optimal_Clock.svg" />
    <span class="ml-1 text-W4 text-[13px] leading-[13px] text-center">
      {{ getDateTime }}
    </span>
    <img
      src="@/assets/Icon_down_gray.svg"
      class="ml-1 h-2.5 w-2.5"
      :class="additionalDownArrowClass"
    />
  </div>
</template>
<script>
import Util from '@/mixins/util'

/**
 * 日時表示のコンポーネント
 */
const DateTime = {
  name: 'DateTime',
  props: {
    /**
     * 日時
     */
    targetTime: {
      type: String,
      require: true,
    },
    /**
     * 出発/到着
     */
    timeType: {
      type: Object,
      require: true,
    },
    /**
     * 下矢印アイコン用クラス
     */
    additionalDownArrowClass: {
      type: String,
      require: false,
    },
  },
  mixins: [Util],
  computed: {
    /**
     * 日時の文字列を取得する
     */
    getDateTime() {
      return this.generateSearchDateTime(
        this.targetTime,
        this.timeType,
        false,
        this.$config.NOTATION_TYPE.SLASH
      )
    },
  },
}
export default DateTime
</script>
<style scoped></style>
