<template>
  <div name="BaseLink" class="flex items-center h-3">
    <!-- ラベル表示 -->
    <div class="text-W2 text-blue underline" @click="openUrlWithAnotherTab()">
      {{ label }}
    </div>
    <!-- リンクアイコン表示 -->
    <img src="@/assets/IconLink.svg" class="w-3 h-3 ml-1" />
  </div>
</template>
<script>
/**
 * リンク表示用コンポーネント
 */
const BaseLink = {
  name: 'BaseLink',
  props: {
    /**
     * ラベル
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * URL
     */
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    /**
     * propsで受け取ったURLを別タブで開く
     */
    openUrlWithAnotherTab() {
      window.open(this.url, '_blank', 'noreferrer')
    },
  },
}
export default BaseLink
</script>
