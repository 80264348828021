<template>
  <!-- 正式なレイアウトが出てきていないので、決めうち実装 -->
  <div
    class="w-fit flex items-center h-[12px]"
    @click="weatherInfoModalFlg = true"
  >
    <div class="text-W5 text-[10px] leading-[10px] text-success300 mr-2">
      現在の天気
    </div>
    <!-- 天気 -->
    <img class="h-[12px] w-[12px] mr-1" :src="weatherIcon()" />
    <!-- 気温 -->
    <div class="text-W4 text-[11px] leading-[11px] text-gray500 mr-2">
      {{ `${Math.round(currentDailyWeatherInfo.temperature)}℃` }}
    </div>
    <!-- 降水確率 -->
    <img class="h-2.5 w-1.5 mr-1" src="@/assets/weather/rainyPercent.svg" />
    <div class="text-W4 text-[11px] leading-[11px] text-gray500 mr-2">
      {{ `${currentDailyWeatherInfo.precipitationPercentage}%` }}
    </div>
    <img class="h-[10px] w-[10px]" src="@/assets/Icon_Right_gray.svg" />
    <Teleport to="#app-page">
      <WeatherModal
        v-if="weatherInfoModalFlg"
        :title="weatherModalTitle"
        :observatory="weather.observatory"
        :daily="weather.daily"
        :weekly="weather.weekly"
        @closeModal="weatherInfoModalFlg = false"
      />
    </Teleport>
  </div>
</template>
<script>
import * as WeatherUtil from '@/utils/weatherUtil.js'
import WeatherModal from '@/modals/weatherModal.vue'
const WeatherInfo = {
  name: 'WeatherInfo',
  components: {WeatherModal},
  data() {
    return {
      weatherInfoModalFlg: false,
    }
  },
  props: {
    // 天気情報
    weather: {
      type: Object,
      required: true,
    },
    // 天気情報モーダルのタイトル
    weatherModalTitle: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['click-weather-info'],
  computed: {
    /**
     * 時間毎の天気情報の中から直近情報を返却する
     * @returns 現在時刻に最も近い天気情報
     */
    currentDailyWeatherInfo() {
      return this.weather.daily[0] // eslint-disable-line no-magic-numbers
    },
  },
  methods: {
    /**
     * 天気アイコン取得
     * @returns 天気アイコン
     */
    weatherIcon() {
      return WeatherUtil.getWeatherIcon(this.currentDailyWeatherInfo.code)
    },
  },
}
export default WeatherInfo
</script>
<style scooped></style>
