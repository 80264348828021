<template>
  <div class="h-full overflow-y-auto flex flex-col">
    <!-- ヘッダー -->
    <div
      class="flex w-full justify-around items-center bg-white h-[47px] px-5 sticky top-0 border-b-[1px] border-gray300 z-10 shrink-0"
    >
      <div class="w-10">
        <img
          src="@/assets/IconLeftBlack.svg"
          class="w-4 h-4"
          @click="goToInsuranceClaimsTop()"
        />
      </div>
      <div class="grow text-W6 leading-[15px] text-[16px]">請求候補を登録</div>
      <div
        class="w-10 text-right text-W4 text-[13px] leading-[19.5px] text-blue"
        @click="openHelpSite()"
      >
        ヘルプ
      </div>
    </div>
    <div class="flex-1">
      <!-- ボディ -->
      <div class="text-left mx-6 pb-8 mt-6">
        <div>
          <div class="text-W7 text-[13px] leading-[13px] mb-2">交通機関</div>
          <SelectForm
            ref="Transportation"
            selectBoxTitle="交通機関の選択"
            :selectItems="transportationList"
            :isDisabled="isNotReceiptType"
            v-model="claimData.transportation"
          />
        </div>

        <div class="mt-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-4">料金</div>
          <AmountFormWithValidation
            ref="Fare"
            v-model="claimData.fare"
            :minValue="1"
            :maxValue="999999"
            :validationType="['required', 'minValue', 'maxValue']"
            :isFirstLoadValidation="isUpdate"
            :isDisabled="isNotReceiptType"
            placeholder="0"
            errorMessage="1〜999,999の数字を入力してください。"
          />
        </div>

        <div class="mt-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">領収書</div>
          <ImageUpload
            v-model="receiptImage"
            :disabledText="receiptImageDisabledText"
            :isDisabled="isNotRequiredReceiptImage"
            :isShowNoImageIcon="isShowNoImageIcon"
            :isUploadError="isUploadError"
            @upload-error="isShowUploadErrorModal = true"
            :placeholder="placeholder"
            :errorMessage="errorMessage"
          />
        </div>

        <div class="mt-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">利用日</div>
          <DateFormWithValidation
            ref="UsedDate"
            :defaultDate="claimData.usedDate"
            placeholder="YYYY/MM/DD"
            errorMessage="正しい利用日を入力してください。"
            :maxValue="maxUsedDate"
            :minValue="minUsedDate"
            :validationType="['required', 'maxValue', 'minValue']"
            :isFirstLoadValidation="isUpdate"
            :inputClass="inputClass"
            :isDisabled="isNotReceiptType"
            @input-text="claimData.usedDate = $event"
          />
        </div>

        <div class="mt-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-1">出発地</div>
          <div class="text-W4 text-gray text-[11px] leading-[11px] mb-2">
            住所、駅名、バス停、施設名など
          </div>
          <TextFormWithValidation
            ref="Departure"
            placeholder="出発地"
            errorMessage="100文字以内で入力してください。"
            maxlength="100"
            :text="claimData.departure"
            :validationType="['required']"
            :isFirstLoadValidation="isUpdate"
            inputClass="bg-gray200 border-gray200"
            :isDisabled="false"
            @input-text="claimData.departure = $event"
          />
        </div>

        <div class="mt-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-1">目的地</div>
          <div class="text-W4 text-gray text-[11px] leading-[11px] mb-2">
            住所、駅名、バス停、施設名など
          </div>
          <TextFormWithValidation
            ref="Destination"
            placeholder="目的地"
            errorMessage="100文字以内で入力してください。"
            maxlength="100"
            :text="claimData.destination"
            :validationType="['required']"
            :isFirstLoadValidation="isUpdate"
            inputClass="bg-gray200 border-gray200"
            :isDisabled="false"
            @input-text="claimData.destination = $event"
          />
        </div>

        <div class="mt-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">
            記名被保険者（お車の所有者）と利用者の関係
          </div>
          <div class="w-full flex flex-row justify-between">
            <FormRadioButton
              v-for="relationshipInsuredObj in relationshipInsuredList"
              :key="relationshipInsuredObj.key"
              :itemKey="relationshipInsuredObj.key"
              :label="relationshipInsuredObj.label"
              :isDisabled="isNotReceiptType"
              v-model="claimData.relationshipInsured"
              class="w-full mr-2 last:mr-0"
            />
          </div>
        </div>

        <div v-show="isRelationshipInsuredFamily" class="mt-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">利用者氏名</div>
          <TextFormWithValidation
            ref="UserName"
            placeholder="東海 太郎"
            errorMessage="100文字以内で入力してください。"
            maxlength="100"
            :text="claimData.userName"
            :validationType="['required']"
            :isFirstLoadValidation="isUpdate"
            :inputClass="inputClass"
            :isDisabled="isNotReceiptType"
            @input-text="claimData.userName = $event"
          />
        </div>

        <TheButton
          class="mt-8"
          text="登録する"
          :isDisabled="isEntryError()"
          @click-button="onClickRegisterClaim()"
        />
      </div>
    </div>
    <!-- アップロードエラー -->
    <UploadErrorModal v-model="isShowUploadErrorModal" />
    <!-- 請求登録時エラーポップアップ -->
    <Modal
      v-if="isShowRegisterClaimFailedModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 font-semibold text-[17px] leading-[25.5px]">
          登録に失敗しました。
        </div>
        <div class="mt-3 mx-5 text-W3 text-[15px] leading-[23px]">
          申し訳ございませんが、時間を置いて再度お試しください。
        </div>
        <div
          class="mt-5 py-4 mx-auto the-button-default text-W7 text-[15px] leading-[15px]"
          @click="isShowRegisterClaimFailedModal = false"
        >
          もどる
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import SelectForm from '@/components/organisms/SelectForm.vue'
import AmountFormWithValidation from '@/components/molecules/AmountFormWithValidation.vue'
import TextFormWithValidation from '@/components/molecules/TextFormWithValidation.vue'
import DateFormWithValidation from '@/components/molecules/DateFormWithValidation.vue'
import FormRadioButton from '@/components/atoms/FormRadioButton.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import ImageUpload from '@/components/molecules/ImageUpload.vue'
import UploadErrorModal from '@/components/UploadErrorModal.vue'
import Util from '@/mixins/util.js'
import dayjs from 'dayjs'
import deepcopy from 'deepcopy'
import Modal from '@/components/Modal.vue'

const InsuranceClaimDetail = {
  name: 'InsuranceClaimDetail',
  components: {
    SelectForm,
    AmountFormWithValidation,
    TextFormWithValidation,
    DateFormWithValidation,
    FormRadioButton,
    TheButton,
    ImageUpload,
    UploadErrorModal,
    Modal,
  },
  data() {
    return {
      claimItemId: '', // itemId
      claimData: {
        //claimテーブルのdetailに当たる
        transportation: null, // 交通機関
        fare: null, // 料金
        usedDate: '', // 利用日
        departure: '', // 出発地
        destination: '', // 目的地
        relationshipInsured: this.$config.RELATIONSHIP_INSURED.SELF.key, // 記名被保険者（お車の所有者）と利用者の関係
        userName: '', // 利用者氏名
      },
      scanStatus: '', // ウイルススキャンステータス
      type: this.$config.CLAIM_TYPE.RECEIPT, // 登録・更新するものの種類
      transportationList: this.$config.TRANSPORTATION_LIST, // 交通機関の選択肢
      relationshipInsuredList: [
        this.$config.RELATIONSHIP_INSURED.SELF,
        this.$config.RELATIONSHIP_INSURED.FAMILY,
      ], // 記名被保険者（お車の所有者）と利用者の関係の選択肢
      isShowUploadErrorModal: false, // アップロードエラー表示フラグ
      receiptImage: '', // 領収書画像
      isShowRegisterClaimFailedModal: false, // 請求情報登録失敗ポップアップ
      downloadedReceiptImage: '', // 初期表示時に読み込んだS3画像
      isUpdate: false, // 新規登録か編集か（編集時にtrue）
    }
  },
  computed: {
    /**
     * ユーザ操作による登録ではないことの判定（Namo利用か否か）
     */
    isNotReceiptType() {
      return this.type !== this.$config.CLAIM_TYPE.RECEIPT
    },
    /**
     * 記名被保険者（お車の所有者）と利用者の関係が同居の家族か否か
     */
    isRelationshipInsuredFamily() {
      return (
        this.claimData.relationshipInsured ===
        this.$config.RELATIONSHIP_INSURED.FAMILY.key
      )
    },
    /**
     * 活性・非活性のクラスを返す
     */
    inputClass() {
      const baseClass = 'bg-gray200 border-gray200'
      const textColor = this.isNotReceiptType
        ? ' !text-gray400'
        : ' !text-gray600'
      return baseClass + textColor
    },

    /**
     * 領収書不要文言
     */
    receiptImageDisabledText() {
      // NAMO利用の場合は領収書不要
      if (this.isNotReceiptType) {
        return 'NAMOでの利用のため領収書は不要です'
      }
      if (this.isNotRequiredReceiptImage) {
        return '電車・バスを利用の場合は不要です'
      } else {
        return ''
      }
    },
    /**
     * 領収書不要判定
     */
    isNotRequiredReceiptImage() {
      // NAMO利用の場合は領収書不要
      if (this.isNotReceiptType) {
        return true
      }
      // 電車・バス利用の場合は領収書不要
      const {TRAIN, BUS} = this.$config.TRANSPORTATION_LIST

      return (
        this.claimData.transportation === TRAIN.id ||
        this.claimData.transportation === BUS.id
      )
    },

    /**
     * 画像変更判定
     */
    isReceiptImageChanged() {
      return this.downloadedReceiptImage !== this.receiptImage
    },

    /**
     * 領収書画像表示判定
     */
    isShowNoImageIcon() {
      return (
        this.scanStatus === this.$config.VIRUS_SCAN_STATUS.UNSCANNED ||
        this.scanStatus === this.$config.VIRUS_SCAN_STATUS.REJECTED
      )
    },

    /**
     * 領収書ウイルススキャンNG
     */
    isUploadError() {
      return this.scanStatus === this.$config.VIRUS_SCAN_STATUS.REJECTED
    },

    /**
     * 領収書ウイルススキャン未完了
     */
    isUnScanned() {
      return this.scanStatus === this.$config.VIRUS_SCAN_STATUS.UNSCANNED
    },

    /**
     * 編集画面の領収書エラーメッセージ(ウイルススキャン中のみ表示しない)
     */
    errorMessage() {
      return this.isUnScanned ? '' : '領収書を追加してください。'
    },

    /**
     * 領収書欄の表示テキスト
     */
    placeholder() {
      return this.isUnScanned ? 'アップロード中' : 'ファイルを追加してください'
    },

    /**
     * 詳細画面で編集、参照する請求情報
     */
    claimDetail() {
      return this.$store.state.ClaimStore.claims.find(
        (claim) => claim.itemId === this.$route.params.claimItemId
      )
    },
    /**
     * 事故紐付け情報
     */
    incidentInfo() {
      return this.$store.state.ClaimStore.incidentInfo
    },
    /**
     * 利用日の最小値
     */
    minUsedDate() {
      return dayjs(this.incidentInfo.incidentDate).startOf('day').toDate()
    },
    /**
     * 利用日の最大値
     */
    maxUsedDate() {
      return dayjs().endOf('day').toDate()
    },
  },
  created() {
    // 遷移元から請求データIDを取得
    const claimItemId = this.$route.params.claimItemId

    // 請求IDがある場合は編集時の初期表示処理を実行する
    if (claimItemId) {
      // 請求データIDを取得できた場合Storeの値を初期値にセット
      this.claimItemId = claimItemId
      this.isUpdate = true

      const claim = deepcopy(this.claimDetail)
      this.claimData = claim.detail
      this.type = claim.type
      this.scanStatus = claim.scanStatus
      // 遷移前に取得した領収書画像を初期値として設定する
      this.receiptImage = this.$route.params.receiptImage
      this.downloadedReceiptImage = this.$route.params.receiptImage
    }
  },
  methods: {
    /**
     * 請求TOP画面に遷移する
     */
    goToInsuranceClaimsTop() {
      this.$router.push({name: this.$config.DISPLAY_INSURANCE_CLAIMS_TOP})
    },
    /**
     * ヘルプページを外部ブラウザで開く
     */
    openHelpSite() {
      window.open(
        process.env.VUE_APP_NAMO_CONTRACTOR_HELP_URL,
        '_blank',
        'noreferrer'
      )
    },
    /**
     * 入力フォームが一つでもエラーもしくは未選択であるかどうか
     */
    isEntryError() {
      // 同居の家族の場合はユーザ名もNullチェック
      const isNullUserName =
        this.isRelationshipInsuredFamily &&
        Util.methods.isNull(this.$refs.UserName)

      // Nullチェック
      if (
        Util.methods.isNull(this.$refs.Fare) ||
        Util.methods.isNull(this.$refs.UsedDate) ||
        Util.methods.isNull(this.$refs.Departure) ||
        Util.methods.isNull(this.$refs.Destination) ||
        isNullUserName
      ) {
        return true
      }

      // 同居の家族の場合はユーザ名もチェック
      const isInvalidUserName =
        this.isRelationshipInsuredFamily && this.$refs.UserName.isInvalid

      // 領収書が必須の場合、領収書が選択されているかチェック
      // ※ウイルススキャン未実施(アップロード中表示)時は領収書を画面に保持してなくて良い
      const isInvalidReceiptImage =
        !this.isNotRequiredReceiptImage &&
        !this.receiptImage &&
        !this.isUnScanned

      // バリデーションの確認（交通機関は選択されているかの確認）
      return (
        Util.methods.isNull(this.claimData.transportation) ||
        this.$refs.Fare.isInvalid ||
        this.$refs.UsedDate.isInvalid ||
        this.$refs.Departure.isInvalid ||
        this.$refs.Destination.isInvalid ||
        isInvalidUserName ||
        isInvalidReceiptImage
      )
    },
    /**
     * 請求情報登録処理を実行する
     */
    onClickRegisterClaim() {
      this.$store.commit('startLoading')

      // 成功時
      const success = () => {
        this.$store.commit('endLoading')
        // 請求登録TOP画面に遷移する
        this.$router.push({
          name: this.$config.DISPLAY_INSURANCE_CLAIMS_TOP,
        })
      }

      // 失敗時
      const failed = () => {
        this.$store.commit('endLoading')
        // 請求情報登録失敗ポップアップ表示
        this.isShowRegisterClaimFailedModal = true
      }

      // 本人の場合は、userNameを空文字にする
      if (!this.isRelationshipInsuredFamily) {
        this.claimData.userName = ''
      }

      // 電車・バス以外、かつ領収書データを編集した場合は領収書データをアップロードする
      const imageBase64String =
        !this.isNotRequiredReceiptImage && this.isReceiptImageChanged
          ? this.receiptImage
          : ''

      //画像アップロード処理を実行する
      this.$store.dispatch('ClaimStore/postClaim', {
        claimItemId: this.claimItemId,
        claimDetail: this.claimData,
        type: this.type,
        imageBase64String: imageBase64String,
        success: success,
        failed: failed,
      })
    },
  },
}
export default InsuranceClaimDetail
</script>
<style scoped></style>
