<template>
  <Modal
    :isShowCloseButton="true"
    :isModalCenter="true"
    modalPaddingX="20px"
    modalPaddingBottom="0px"
    @disPlayModal="$emit('closeModal')"
  >
    <div class="weatherModal p-5">
      <!-- タイトル・住所 -->
      <div class="flex justify-between items-center">
        <div class="text-W7 text-[15px] leading-[15px] mr-2">{{ title }}</div>
        <div
          class="flex-1 text-W4 text-[11px] leading-[11px] text-gray400 text-right"
        >
          {{ `${observatory}周辺` }}
        </div>
      </div>
      <!-- 本日の天気 -->
      <div class="mt-6">
        <!-- 現在 -->
        <div class="mb-6">
          <div class="flex justify-center items-center">
            <img
              class="h-10 w-10 mr-2"
              :src="weatherIcon(currentWeather.code)"
            />
            <div class="flex items-end">
              <div class="text-W7 text-[34px] leading-[34px]">
                {{ Math.round(currentWeather.temperature) }}
              </div>
              <div class="text-W4 text-[16px] leading-[16px] mb-1">℃</div>
            </div>
          </div>
          <div class="flex justify-center items-center mt-4">
            <div class="text-W7 text-[13px] leading-[13px] mr-4">
              {{ currentWeather.name }}
            </div>
            <div class="text-W4 text-[13px] leading-[13px] flex">
              <div class="mr-1">降水確率</div>
              <div>{{ `${currentWeather.precipitationPercentage}%` }}</div>
            </div>
          </div>
          <div class="flex justify-center items-center mt-2">
            <div class="text-W4 text-[13px] leading-[13px] mr-4 text-danger300">
              {{ `最高${currentWeather.highestTemperature}℃` }}
            </div>
            <div class="text-W4 text-[13px] leading-[13px] text-primary">
              {{ `最低${currentWeather.lowestTemperature}℃` }}
            </div>
          </div>
        </div>
        <!-- 時間毎 -->
        <div class="w-full flex">
          <!-- ラベル(左側) -->
          <div
            class="bg-gray200 px-2 py-4 rounded-l-[8px] w-[50px] border-r-[2px] border-white"
          >
            <div class="text-gray400 text-W4 text-[11px] leading-[11px] mb-3">
              時刻
            </div>
            <div class="h-5 mb-3" />
            <div class="text-gray400 text-W4 text-[11px] leading-[13px] mb-2">
              気温
            </div>
            <div class="text-gray400 text-W4 text-[11px] leading-[13px]">
              降水
            </div>
          </div>
          <!-- 天気情報(右側) -->
          <div class="flex-1 bg-gray200 px-2 rounded-r-[8px] overflow-x-auto">
            <div class="flex">
              <div
                v-for="(item, index) in daily"
                :key="item.date"
                class="pr-2 last:pr-4"
              >
                <div class="flex">
                  <div v-if="judgeDateChange(index)" class="bg-gray300 w-9">
                    <div class="flex items-center justify-center h-full">
                      <div
                        class="text-W4 text-[11px] leading-[11px] text-gray400"
                      >
                        {{ dateChangeText(index) }}
                      </div>
                    </div>
                  </div>
                  <div class="w-9 py-4">
                    <div class="text-W4 text-[11px] leading-[11px] mb-3">
                      <div v-if="index === 0">現在</div>
                      <div v-else>
                        {{ convertHour(item.date) }}
                      </div>
                    </div>
                    <img
                      class="h-5 w-5 mb-3 mx-auto"
                      :src="weatherIcon(item.code)"
                    />
                    <div class="text-W4 text-[13px] leading-[13px] mb-2">
                      {{ `${Math.round(item.temperature)}℃` }}
                    </div>
                    <div class="text-W4 text-[13px] leading-[13px]">
                      {{ `${item.precipitationPercentage}%` }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 週間の天気 -->
      <div class="mt-6">
        <div class="text-W6 text-[15px] leading-[15px] text-left mb-2">
          週間天気
        </div>
        <div
          v-for="(item, index) in weekly"
          :key="item.date"
          class="py-2 flex items-center border-b-[1px] border-gray300"
        >
          <div class="text-W4 text-[13px] leading-[13px] mr-10">
            <div v-if="index === 0" class="w-[57px]">本日</div>
            <div v-else class="flex">
              <div class="w-[18px]">{{ convertDate(item.date) }}</div>
              <div class="w-[39px]" :class="dayOfWeekColor(item.date)">
                {{ convertDayOfWeek(item.date) }}
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <img class="h-5 w-5 mr-[22px]" :src="weatherIcon(item.code)" />
            <div class="text-W4 text-[13px] leading-[13px] text-danger300 mr-4">
              {{ `${item.highestTemperature}℃` }}
            </div>
            <div class="text-W4 text-[13px] leading-[13px] text-primary mr-4">
              {{ `${item.lowestTemperature}℃` }}
            </div>
            <div class="text-W4 text-[13px] leading-[13px]">
              {{ `${item.precipitationPercentage}%` }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import * as WeatherUtil from '@/utils/weatherUtil.js'
import dayjs from 'dayjs'

const WeatherModal = {
  name: 'WeatherModal',
  components: {Modal},
  props: {
    // タイトル
    title: {
      type: String,
      require: false,
      default: '',
    },
    // 観測所名
    observatory: {
      type: String,
      require: false,
      default: '',
    },
    // 1日の天気情報
    daily: {
      type: Array,
      require: true,
    },
    // 週間の天気情報
    weekly: {
      type: Array,
      require: true,
    },
  },
  computed: {
    /**
     * 現在の天気情報
     * @returns 天気情報
     */
    currentWeather: {
      get() {
        const currentWeather = this.daily[0] // eslint-disable-line no-magic-numbers
        const todayWeather = this.weekly[0] // eslint-disable-line no-magic-numbers
        // 最高気温、最低気温をweeklyから抽出して付与
        const highestTemperature = todayWeather.highestTemperature
        const lowestTemperature = todayWeather.lowestTemperature
        return {...currentWeather, highestTemperature, lowestTemperature}
      },
    },
  },
  methods: {
    /**
     * 天気アイコン取得
     * @param {String} code 天気コード
     * @returns 天気アイコン
     */
    weatherIcon(code) {
      return WeatherUtil.getWeatherIcon(code)
    },
    /**
     * 時間(H)の文字列に変換
     * @param {String} date 日付文字列
     * @returns 変換後文字列
     */
    convertHour(date) {
      return dayjs(date).format('H')
    },
    /**
     * 日付(d)の文字列に変換
     * @param {String} date 日付文字列
     * @returns 変換後文字列
     */
    convertDate(date) {
      return dayjs(date).format('D')
    },
    /**
     * 曜日(dd)の文字列に変換
     * @param {String} date 日付文字列
     * @returns 変換後文字列
     */
    convertDayOfWeek(date) {
      return dayjs(date).format('(dd)')
    },
    /**
     * 曜日のフォントカラーを返却
     * @param {String} date 日付文字列
     * @returns フォントカラーを定義するクラス名
     */
    dayOfWeekColor(date) {
      const dayOfWeekKey = dayjs(date).format('d')
      const SATURDAY_KEY = '6'
      const SUNDAY_KEY = '0'

      // 土曜日の場合は青字
      if (dayOfWeekKey === SATURDAY_KEY) return 'text-primary'

      // 日曜日の場合は赤字
      if (dayOfWeekKey === SUNDAY_KEY) return 'text-danger300'

      // それ以外の曜日の場合は黒字
      return 'text-gray600'
    },
    /**
     * 日跨ぎかどうかを判定する
     * @param {Number} index 表示対象の要素番号
     * @returns 日付が変わっているかどうか
     */
    judgeDateChange(index) {
      // 先頭は日跨ぎのケースはありえないため、固定でfalseを返却
      if (index === 0) return false // eslint-disable-line no-magic-numbers

      const prevDate = dayjs(this.daily[index - 1].date) // eslint-disable-line no-magic-numbers
      const currentDate = dayjs(this.daily[index].date)

      // 1つ前の日付と比較し、日付が変わっているかを判定し返却
      return currentDate.hour(0).isAfter(prevDate.hour(0)) // eslint-disable-line no-magic-numbers
    },
    /**
     * 日跨ぎ時の表示文言を返却する
     * @param {Number} index 表示対象の要素番号
     * @returns 明日/明後日
     */
    dateChangeText(index) {
      // 先頭の日付を基準とする
      const baseIndex = 0
      const baseDate = dayjs(this.daily[baseIndex].date)
      const targetDate = dayjs(this.daily[index].date)
      // 基準日+1日であれば、「明日」を返却する
      // eslint-disable-next-line no-magic-numbers
      if (baseDate.hour(0).add(1, 'day').isSame(targetDate.hour(0))) {
        return '明日'
      }

      // 基準日+2日であれば、「明後日」を返却する
      // eslint-disable-next-line no-magic-numbers
      if (baseDate.hour(0).add(2, 'day').isSame(targetDate.hour(0))) {
        return '明後日'
      }
    },
  },
}
export default WeatherModal
</script>

<style scoped>
.weatherModal {
  overflow-y: auto;
  /* 画面全体 - (上下余白(20px+20px) + 閉じるボタン(16px+8px)) */
  max-height: calc(100vh - 64px);
}
</style>
