import config from '@/const/const.js'
import deepcopy from 'deepcopy'
import util from './util.js'
import store from '@/store/index.js'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import 'dayjs/locale/ja'
// 日付の形式を日本のものに変更
dayjs.locale('ja')
dayjs.extend(isSameOrAfter)
const constant = config.constant

// お気に入り時刻表データのIndexedDBの設定
const OPERATION_DB_SETTINGS = {
  tableName: constant.FAVORITE_OPERATIONS_TABLE_NAME,
  storeName: constant.FAVORITE_OPERATIONS_TABLE_NAME,
  keyPath: constant.OPERATIONS_KEY,
}

export default {
  methods: {
    /**
     * 時刻表のお気に入りデータを取得
     */
    getFavoriteTimeTable() {
      return util.methods.getLocalStorage(constant.FAVORITE_TIME_TABLE)
    },
    /**
     * 時刻表のお気に入りデータを登録
     * @param {Object} data 登録対象のオブジェクト
     */
    addFavoriteTimeTable(data) {
      let target = this.getFavoriteTimeTable()
      if (!target) {
        // 初回登録
        target = new Array(data)
      } else {
        target.push(data)
      }
      util.methods.setLocalStorage(constant.FAVORITE_TIME_TABLE, target)
      return target
    },
    /**
     * 時刻表のお気に入りデータを削除
     * @param {Array} data 削除対象のオブジェクト
     */
    deleteFavoriteTimeTable(data) {
      let target = this.getFavoriteTimeTable().filter((value) => {
        return !data.some((deleteData) => {
          return this.checkExistDiagram(value, deleteData)
        })
      })
      util.methods.setLocalStorage(constant.FAVORITE_TIME_TABLE, target)
      return target
    },
    /**
     * 時刻表のお気に入りデータを更新
     * @param {Object} updateData 更新用のお気に入りデータ
     * @param {Object} targetData 更新対象のお気に入りデータ
     */
    updateFavoriteTimeTable(updateData, targetData) {
      const oldFavoriteList = this.getFavoriteTimeTable()

      const updatedFavoriteList = oldFavoriteList.map((favorite) => {
        return this.checkExistDiagram(favorite, targetData)
          ? updateData // 一致すれば、更新データを有効
          : favorite // 一致しなければ、既存のデータを有効
      })
      util.methods.setLocalStorage(
        constant.FAVORITE_TIME_TABLE,
        updatedFavoriteList
      )
      return updatedFavoriteList
    },
    /**
     * お気に入り一括更新
     * @param {Array} updateList
     * @returns
     */
    updateBulkFavoriteTimeTable(updateList) {
      util.methods.setLocalStorage(constant.FAVORITE_TIME_TABLE, updateList)
      return updateList
    },
    /**
     * 指定した時刻表情報が既にお気に入り登録済みかどうかを判定
     * @param {Object} targetData 対象の時刻表情報
     * @returns true: 登録済み | false: 未登録
     */
    isExistFavoriteData(targetData) {
      const oldFavoriteList = this.getFavoriteTimeTable()
      return (
        targetData != null &&
        oldFavoriteList.some((value) => {
          return this.checkExistDiagram(value, targetData)
        })
      )
    },
    /**
     * IndexedDBにお気に入り時刻表データを追加する
     * @param {*} success 成功時コールバック
     * @param {*} error 失敗時コールバック
     * @param {*} id 追加する時刻表データのID
     * @param {*} operation 追加する時刻表データ
     */
    addFavoriteOperation({success, error, id, data}) {
      // データ追加処理を呼び出し
      store.dispatch('IndexedDbStore/addData', {
        success: success,
        error: error,
        setting: OPERATION_DB_SETTINGS,
        key: id,
        data: JSON.stringify(data),
      })
    },
    /**
     * IndexedDBにあるお気に入り時刻表データを更新する
     * 既にあるデータの場合は更新する
     * @param {*} success 成功時コールバック
     * @param {*} error 失敗時コールバック
     * @param {*} id お気に入りデータのID
     * @param {*} operation 保存する時刻表データ
     */
    updateFavoriteOperation({success, error, id, data}) {
      // IndexedDB作成処理
      store.dispatch('IndexedDbStore/updateData', {
        success: success,
        error: error,
        setting: OPERATION_DB_SETTINGS,
        key: id,
        data: JSON.stringify(data),
      })
    },
    /**
     * IndexedDBにお気に入り時刻表データを削除する
     * @param {*} success 成功時コールバック
     * @param {*} error 失敗時コールバック
     * @param {*} id
     * @param {*} operation
     */
    deleteFavoriteOperation({success, error, id}) {
      // IndexedDB作成処理
      store.dispatch('IndexedDbStore/deleteData', {
        success: success,
        error: error,
        setting: OPERATION_DB_SETTINGS,
        key: id,
      })
    },
    /**
     * IndexedDBにお気に入り時刻表データを取得する
     * @param {*} success 成功時コールバック
     * @param {*} error 失敗時コールバック
     * @returns 時刻表データ
     */
    getFavoriteOperation({success, error}) {
      const successProcess = (operations) => {
        // 時刻表データがJSON形式になっているので配列に変換する
        const parseResult = []
        for (let item of operations) {
          let temp = deepcopy(item)
          temp.value = JSON.parse(temp.value)
          parseResult.push({id: temp.operationsKey, data: temp.value})
        }
        success(parseResult)
      }

      store.dispatch('IndexedDbStore/getAllData', {
        success: successProcess,
        error: error,
        setting: OPERATION_DB_SETTINGS,
      })
    },
    /**
     * お気に入り情報が一致するかを判定する
     * 条件：ノードID,路線Id,方面(up or down),方面名
     * @param {Object} target
     * @param {Object} base
     * @return {Boolean} 存在している場合は,true
     */
    checkExistDiagram(target, base) {
      return (
        target.node == base.node &&
        target.link == base.link &&
        target.operation == base.operation &&
        target.directionName == base.directionName
      )
    },
    /**
     * 時刻表データからオペレーション情報の抽出
     * 用途：APIからデータ取得後のお気に入りデータに合致するoperation情報を取得したい
     * @param {Object} link APIから取得した時刻表データ
     * @param {Sting} direction 登録済の方面情報('up' or 'down')
     * @return {Array} operation情報、該当データがなければ[]
     */
    extractDiagramOperations(link, direction) {
      // 存在チェック
      if (util.methods.isNull(link) || util.methods.isEmpty(link)) {
        return []
      }
      // APIのレスポンスから使用したい時刻表データを返す
      switch (direction) {
        case 'up':
          return link.upOperation
        case 'down':
          return link.downOperation
        default:
          return []
      }
    },
    /**
     * 時刻表データのうち、次の時刻表のインデックスを返却する
     * @param {*} operations 時刻表データ
     * @returns 該当箇所のindex
     */
    findNextOperationIndex(operations) {
      const now = util.methods.getFotmatYearDateTime()
      return operations.findIndex((item) => {
        let targetTime = dayjs(item.time)
        return targetTime.isSameOrAfter(now)
      })
    },

    /**
     * お気に入りの時刻表データを最新化する
     * @param {Object} favoriteTimeTables お気に入り情報一覧
     * @param {Object} storeOperations sotreにあるオペレーション情報
     * @returns {Promise} 時刻表更新結果
     */
    async modernizeFavoriteOperations(favoriteTimeTables, storeOperations) {
      const promises = []
      for (let favorite of favoriteTimeTables) {
        const promise = this.doGettingTimeTable(storeOperations, favorite)
        promises.push(promise)
      }

      // 取得対象がなければ何も行わず正常終了
      // eslint-disable-next-line no-magic-numbers
      if (promises.length === 0) return

      return Promise.allSettled(promises).then((results) => {
        // 全てrejectedの場合は異常終了
        const isAllRejected = results.every((a) => a.status === 'rejected')
        if (isAllRejected) {
          throw new Error()
        }

        // データの保持(DB/Store)
        for (let result of results) {
          // 成功していないものは処理しない
          if (result.status != 'fulfilled') continue
          this.updateOperationsToDbAndStore(result.value)
        }
        // データ保持完了したら正常終了
        return
      })
    },
    /**
     * 最新の時刻表データ取得のためのPrmiseデータを取得
     * @param {Object} storeOperations Storeデータ
     * @param {Object} favoriteData 時刻表データ
     * @return {Promise} 時刻表取得結果
     */
    doGettingTimeTable(storeOperations, favoriteData) {
      // 時刻表取得実施判定
      // 再取得不要対象の場合は、現在保持している時刻表データを正として返却する
      const ope = storeOperations.find((item) => item.id === favoriteData.id)
      if (this.judgeUnnecessaryUpdateTimeTable(ope)) {
        return Promise.resolve(ope)
      }

      const startTime = dayjs().format('YYYY-MM-DD[T]HH:mm:ss')
      return this.createPromiseOfDiagrams(favoriteData, startTime)
    },
    /**
     * 時刻表取得の非同期処理の作成
     * @param {Object} favoriteData Storageから取得したお気に入り情報
     * @param {Object} startTime 現在時刻
     * @return {Promise} 時刻表取得結果
     */
    createPromiseOfDiagrams(favoriteData, startTime) {
      return new Promise((resolve) => {
        // 成功時処理
        const success = (result) => {
          const link = result.link
          const data = this.moldingOperations(favoriteData, link, startTime)
          resolve(data)
        }
        // 失敗時処理
        const failed = () => {
          const data = this.moldingOperations(favoriteData, null, startTime)
          resolve(data)
        }
        // エラー時処理
        const error = () => {
          const data = this.moldingOperations(favoriteData, null, startTime)
          resolve(data)
        }
        store.dispatch('TimeTableStore/getTimeTables', {
          success: success,
          failed: failed,
          error: error,
          nodeId: favoriteData.node,
          linkId: favoriteData.link,
          startTime: startTime,
        })
      })
    },
    /**
     * 時刻表を更新する必要があるかどうかを判断する
     * @param {Object} operation 時刻表情報
     * @returns {Boolean} true: 更新不要 | false: 更新必要
     */
    judgeUnnecessaryUpdateTimeTable(operation) {
      if (!operation) return false

      const resultIndex = this.findNextOperationIndex(operation.data.operation)
      // 時刻表データが見つからない場合は再取得対象とする
      /*eslint-disable-next-line no-magic-numbers*/
      if (resultIndex === -1) return false

      // 表示対象データが2件以上ある、または前回取得から12時間経過していないデータは再取得しない
      const hasTwoOrMoreOpe = this.checkDisplayDiagram(operation, resultIndex)
      const isOverHalfDateTime = this.isOverHalfDateTime(operation.data.time)
      if (hasTwoOrMoreOpe || isOverHalfDateTime) return true

      // 上記に当てはまらない場合は再取得対象とする
      return false
    },
    /**
     * 時刻表データに成形
     * @param {Object} favorite お気に入り情報
     * @param {Object} link 路線情報
     * @param {String} startTime 検索時間
     * @returns IndexedDB,Storeに保持する時刻表データ
     */
    moldingOperations(favorite, link, startTime) {
      return {
        // お気に入りID
        id: favorite.id,
        // 時刻表情報
        data: {
          time: startTime,
          operation: this.extractDiagramOperations(link, favorite.operation),
        },
      }
    },
    /**
     * 現在地時刻から見て表示可能データが2個以上存在するチェック
     * @param {String} findOperation IDに紐づく時刻表データ
     * @param {String} nextIndex 時刻表データにて、現在時刻から直近の未来データに該当するインデックス値
     * @return {Boolean} データが2個以上あればTrue
     */
    checkDisplayDiagram(findOperation, nextIndex) {
      const maxDiplayCount = 2
      const displayDiagram = findOperation.data.operation.length - nextIndex
      return maxDiplayCount <= displayDiagram
    },
    /**
     * 前回取得から12時間以上経過のチェック
     * @param {String} time Storeにある時刻表データのtime情報
     * @return {Boolean} 時刻表データを取得して12時間経過していなければTrue
     */
    isOverHalfDateTime(time) {
      // 現在時刻
      const nowTime = dayjs()
      // 前回取得時刻
      const lastGetTime = dayjs(time)

      // 前回取得日時から12時間が経過しているかどうか
      // eslint-disable-next-line no-magic-numbers
      return lastGetTime.add(12, 'h').isAfter(nowTime)
    },
    /**
     * StoreとDBの時刻表データを同期しながら更新/登録する
     * ※共通的に更新処理を行ないため、本来望ましくないがUtil内でStore参照/更新を行う
     * @param {*} data 対象データ
     */
    updateOperationsToDbAndStore(data) {
      // DB更新
      const operations = store.state.TimeTableStore.favoriteOperations
      const hasData = operations.find((item) => item.id === data.id)
      hasData
        ? this.updateFavoriteOperation(data) // 既に保持している場合は更新
        : this.addFavoriteOperation(data) // 保持していない場合は登録

      // Store更新
      store.commit('TimeTableStore/updateFavoriteOperations', data)
    },
    /**
     * StoreとDBの時刻表データを同期しながら削除する
     * ※共通的に更新処理を行ないため、本来望ましくないがUtil内でStore削除を行う
     * @param {*} targetId 対象データID
     */
    deleteOperationsToDbAndStore(targetId) {
      // DB更新
      this.deleteFavoriteOperation({id: targetId})
      // Store更新
      store.commit('TimeTableStore/deleteFavoriteOperations', targetId)
    },
  },
}
