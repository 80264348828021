/**
 * 請求用Storeモジュール
 */
import * as Axios from '@/service/AxiosService'
import axios from 'axios'
import config from '@/const/const.js'
import deepcopy from 'deepcopy'
import log from 'loglevel'
const constant = config.constant

// 事故紐付け情報の初期値
const DEFAULT_INCIDENT_INFO = {
  status: '',
}

/**
 * 領収書画像アップロード処理
 * @param {String} signedUrl 署名付きURL
 * @param {String} imageBase64String base64エンコードされた領収書画像データ
 * @param {*} success 成功時に実行するコールバック関数
 * @param {*} error エラー時に実行するコールバック関数
 */
function uploadReceiptImage(signedUrl, imageBase64String, success, error) {
  // ItemIDと署名付きURLがある場合、画像をS3にアップロード
  // AxiosServiceだとbaseUrlを先頭に記載してしまうためライブラリを直接呼び出す
  //TODO Promiseを返す形に修正し、テストも追加する
  axios
    .put(signedUrl, imageBase64String, {
      headers: {
        'Content-Type': 'image/jpeg',
        'X-Amz-Acl': 'bucket-owner-full-control',
      },
    })
    .then((s3Res) => {
      if (s3Res.status === constant.API_STATUS_CODE_OK) {
        if (success) {
          success()
        }
      } else {
        if (error) {
          error()
        }
      }
    })
    .catch((e) => {
      log.debug(e)
      if (error) {
        error()
        return
      }
      // 指定がなければ共通エラーとしてハンドリング
      throw e
    })
}

/**
 * 領収書画像ダウンロード処理
 * @param {String} signedUrlForDownload 署名付きURL
 * @param {*} success 成功時に実行するコールバック関数
 * @param {*} error エラー時に実行するコールバック関数
 */
function downloadReceiptImage(signedUrlForDownload, success, error) {
  // ItemIDと署名付きURLがある場合、画像をS3から取得
  //TODO Promiseを返す形に修正し、テストも追加する
  axios
    .get(signedUrlForDownload, {
      headers: {
        'Content-Type': 'image/jpeg',
      },
    })
    .then((s3Res) => {
      if (s3Res.status === constant.API_STATUS_CODE_OK) {
        if (success) {
          success(s3Res.data)
          return
        }
      } else {
        if (error) {
          error()
        }
      }
    })
    .catch((e) => {
      log.debug(e)
      if (error) {
        error()
        return
      }
      // 指定がなければ共通エラーとしてハンドリング
      throw e
    })
}

export default {
  namespaced: true,
  state: {
    // 請求データリスト
    claims: [],
    // 選択状態の請求データのItemIdのリスト
    selectedClaimItemIds: [],
    // 事故紐付け情報
    incidentInfo: {
      status: '',
      incidentDate: '',
      creditLimit: 0,
    },
    // 事故紐付け番号
    claimReceiptNumber: '',
  },
  getters: {
    /**
     * 選択された請求の料金の合計を返却する
     */
    totalSelectedClaimAmount(state) {
      // 選択されている請求を取得し、合計金額を計算
      const selectedClaims = state.claims.filter((claim) => {
        return state.selectedClaimItemIds.includes(claim.itemId)
      })
      const total = selectedClaims.reduce((sum, element) => {
        return sum + parseInt(element.detail.fare)
        // eslint-disable-next-line no-magic-numbers
      }, 0)

      return total
    },
    /**
     * 全請求の料金の合計を返却する
     */
    totalClaimAmount(state) {
      // 合計金額を計算
      const total = state.claims.reduce((sum, element) => {
        return sum + parseInt(element.detail.fare)
        // eslint-disable-next-line no-magic-numbers
      }, 0)
      return total
    },
  },
  mutations: {
    /**
     * 請求データリスト更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} list 新しい請求データリスト
     */
    updateClaims(state, list) {
      state.claims = list
    },
    /**
     * 事故紐付け番号更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} value 事故紐付け番号
     */
    updateClaimReceiptNumber(state, value) {
      state.claimReceiptNumber = value
    },
    /**
     * 選択状態の請求データのItemIdリスト更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} list 新しい請求データリスト
     */
    updateSelectedClaimItemIds(state, list) {
      state.selectedClaimItemIds = list
    },
    /**
     * 事故紐付け情報更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 事故紐付け情報
     */
    updateIncidentInfo(state, obj) {
      state.incidentInfo = obj
    },
    /**
     * 事故紐付け情報のステータス更新処理
     * @param {*} state Storeオブジェクト
     * @param {String} value 事故紐付け情報
     */
    updateIncidentInfoStatus(state, value) {
      state.incidentInfo.status = value
    },
    /**
     * 事故紐付け情報初期化処理
     * @param {*} state Storeオブジェクト
     */
    initIncidentInfo(state) {
      state.incidentInfo = deepcopy(DEFAULT_INCIDENT_INFO)
    },
  },
  actions: {
    /**
     * 請求データリスト取得API実行処理
     * @param {*} state ClaimStoreのstoreのstate
     * @param {*} commit Storeオブジェクト
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     */
    getClaims({state, commit}, {success, failed, error}) {
      // パラメータ
      const param = {
        claimReceiptNumber: state.claimReceiptNumber,
      }
      return Axios.get('claims', param, {
        needsAccessToken: true,
      })
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            commit('updateClaims', res.body)
            if (success) {
              success()
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 請求依頼API実行処理
     * @param {*} state ClaimStoreのstoreのstate
     * @param {*} commit Storeオブジェクト
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     */
    createLinkClaims({state, commit}, {success, failed, error}) {
      // リクエストボディ
      const body = {
        claimReceiptNumber: state.claimReceiptNumber,
        itemIdArray: state.selectedClaimItemIds,
      }
      return Axios.post('linkClaim', body, {
        needsAccessToken: true,
      })
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            commit(
              'updateIncidentInfoStatus',
              constant.INCIDENT_INFO_STATUS.REGISTERED
            )
            const selectedClaims = state.claims.filter((claim) => {
              return state.selectedClaimItemIds.includes(claim.itemId)
            })
            // 請求TOP画面に戻った際に、描画が完了する前に表示する内容を受付済みの請求情報のみにする
            commit('updateClaims', selectedClaims)
            if (success) {
              success()
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },

    /**
     * 請求詳細情報登録・更新処理
     * @param {*} state このstoreのstate
     * @param {*} commit Storeオブジェクト
     * @param {Object} claimItemId 請求情報のitemId
     * @param {Object} claimDetail 請求詳細情報
     * @param {String} type 請求データ種別(NAMO経由のモビリティ利用かそれ以外)
     * @param {String} imageBase64String base64エンコードされた領収書画像データ
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     * @param {Function} error エラー時に実行するコールバック関数
     */
    postClaim(
      {state, commit},
      {
        claimItemId,
        claimDetail,
        type,
        imageBase64String,
        success,
        failed,
        error,
      }
    ) {
      //  画像データがあるかでS3アップロードするか判定する(編集時含む)
      const needsImageUpload = !!imageBase64String

      const body = {
        claimReceiptNumber: state.claimReceiptNumber,
        itemId: claimItemId,
        type,
        needsImageUpload: needsImageUpload,
        detail: claimDetail,
      }
      return Axios.post('claim', body, {needsAccessToken: true})
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            const itemId = data.body.itemId
            const signedUrl = data.body.signedUrl

            // itemIdの追加処理(新規登録時のみ実行)
            if (!claimItemId) {
              const selectedClaimItemIds = deepcopy(state.selectedClaimItemIds)
              selectedClaimItemIds.push(itemId)
              commit('updateSelectedClaimItemIds', selectedClaimItemIds)
            }

            if (needsImageUpload) {
              uploadReceiptImage(signedUrl, imageBase64String, success, error)
            } else {
              if (success) {
                success()
              }
            }
          } else {
            throw data
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * S3データダウンロード処理
     * @param {*} state Storeオブジェクト
     * @param {String} itemId
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} error 失敗時に実行するコールバック関数
     */
    getS3ReceiptData({state}, {itemId, success, error}) {
      // リクエストボディ
      const param = {
        claimReceiptNumber: state.claimReceiptNumber,
        itemId: itemId,
      }
      //TODO Promiseを返す形に修正し、テストも追加する
      Axios.get('signedUrl', param, {
        needsAccessToken: true,
      })
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            const signedUrl = res.body.signedUrl

            downloadReceiptImage(signedUrl, success, error)
          } else {
            if (error) {
              error()
            }
          }
        })
        .catch((e) => {
          log.debug(e)
          // エラー時の処理指定がある場合はそちらを有効
          if (error) {
            return error(e)
          }

          // 指定がなければ共通エラーとしてハンドリング
          throw e
        })
    },
    /**
     * 事故請求ログインAPI実行処理
     * @param {*} commit Storeオブジェクト
     * @param {String} claimReceiptNumber 受付番号
     * @param {Function} success 成功時に実行するコールバック関数
     * @param {Function} failed 失敗時に実行するコールバック関数
     */
    insuranceClaimLogin({commit}, {claimReceiptNumber, success, failed}) {
      // リクエストボディ
      const body = {
        claimReceiptNumber: claimReceiptNumber,
      }
      //TODO Promiseを返す形に修正し、テストも追加する
      Axios.post('insuranceClaimLogin', body, {
        needsAccessToken: true,
      })
        .then((data) => {
          if (data.statusCode === constant.API_STATUS_CODE_OK) {
            commit('updateClaimReceiptNumber', claimReceiptNumber)
            const responseBody = data.body
            const incidentInfo = {
              status: responseBody.status,
              incidentDate: responseBody.incidentDate,
              creditLimit: responseBody.creditLimit,
            }
            commit('updateIncidentInfo', incidentInfo)
            if (success) {
              success()
            }
          } else {
            if (failed) {
              failed(data.body.param.message)
            }
          }
        })
        .catch((e) => {
          log.debug(e)
          throw e
        })
    },
  },
}
