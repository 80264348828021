<template>
  <div>
    <div class="flex flex-col">
      <div
        class="flex mb-5 last:mb-0"
        v-for="stationHistory in stationHistoryList"
        :key="stationHistory.id"
        @click="$emit('click-history-station', stationHistory)"
      >
        <img
          class="ml-2 w-4 h-4 self-center"
          :src="getStationIcon(stationHistory.types)"
        />
        <div class="text-left ml-4 self-center">
          <div class="text-W4 station-history-name text-[16px] leading-4">
            {{ stationHistory.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const StationHistoryList = {
  name: 'StationHistoryList',
  props: {
    stationHistoryList: {
      type: Array,
      required: true,
    },
  },
  emits: ['click-history-station'],
  methods: {
    /**
     * モビリティごとにアイコンを切り替え
     */
    getStationIcon(types) {
      let iconName
      switch (types) {
        case 'station':
          iconName = 'TransferTrainIconGray.svg'
          break
        case 'airport':
          iconName = 'TransferAirplainIconGray.svg'
          break
        case 'port':
          iconName = 'TransferFerryIconGray.svg'
          break
        case 'busstop': // 路線バスのバス停
        case 'shuttle_busstop': // 空港連絡バスのバス停
        case 'highway_busstop': // 高速バスのバス停
          iconName = 'TransferBusIconGray.svg'
          break
        default:
          return
      }
      return require(`@/assets/${iconName}`)
    },
  },
}
export default StationHistoryList
</script>
<style scoped></style>
