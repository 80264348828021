<template>
  <div id="TransferMove" class="flex w-full">
    <!-- 駅数表示部分 -->
    <div
      class="ml-1 w-16"
      :style="{'margin-top': callingAtTop + 'px'}"
      @click="isShowCallingAt = !isShowCallingAt"
    >
      <div ref="callingAtCount">
        <div
          v-if="moveSection.move != 'walk' && 0 < callingAt.length"
          class="mx-auto text-W5 text-[13px] leading-[13px] text-primary"
        >
          {{ callingAt.length + 1 }}駅
        </div>
        <div v-if="0 < callingAt.length" class="h-2.5 w-2.5 mx-auto mt-1">
          <img v-if="isShowCallingAt" src="@/assets/Icon_topV2.svg" />
          <img v-else src="@/assets/Icon_downV2.svg" />
        </div>
      </div>
    </div>
    <!-- 線 -->
    <div
      ref="sectionLine"
      class="h-auto w-1"
      :style="getMoveLineColor(moveSection)"
    />
    <!-- 路線情報表示部分 -->
    <div class="flex-1">
      <!-- 徒歩 -->
      <div v-if="moveSection.move == 'walk'" class="pl-[18px] py-4 text-left">
        <div class="flex">
          <img src="@/assets/MobilityWalk.svg" class="h-5 w-5 mr-2" />
          <div class="flex-1">
            <div class="h-[20px] text-W4 text-[13px] text-left">
              <span>
                徒歩&nbsp;{{ addCommaForInteger(moveSection.distance) }}m/{{
                  addCommaForInteger(moveSection.time)
                }}分
              </span>
            </div>
            <div
              class="mt-1 text-W4 text-[11px] text-gray500 text-left leading-[11px]"
            >
              <span class="mr-1">{{ nextPointSection.spotName }}</span>
              <span>{{ nextPointSection.startPlatform }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 徒歩以外 -->
      <div v-else class="pl-4 py-4 flex">
        <img
          class="w-5 h-5"
          :src="getMobilityIconFromTransportInfo(moveSection)"
        />
        <div class="ml-2 w-full">
          <div class="leading-5 text-W4 text-[13px] text-left">
            {{ moveSection.lineName }}
          </div>
          <div
            v-if="destinationName"
            class="mt-1 text-W4 text-[11px] text-gray500 text-left leading-[11px]"
          >
            {{ destinationName }}方面
          </div>

          <div
            v-if="
              prevPointSection.startPlatform || nextPointSection.goalPlatform
            "
            class="w-full h-3"
          />
          <div class="flex w-full">
            <div class="mr-auto">
              <div
                v-if="prevPointSection.startPlatform"
                class="text-center text-left flex"
              >
                <div class="text-W2 text-[13px] leading-[16px] text-left">
                  &nbsp;[発]
                  {{ prevPointSection.startPlatform }}
                </div>
              </div>
              <div
                v-if="
                  prevPointSection.startPlatform &&
                  nextPointSection.goalPlatform
                "
                class="w-full h-1"
              />
              <div
                v-if="nextPointSection.goalPlatform"
                class="text-center text-left flex"
              >
                <div class="text-W2 text-[13px] leading-[16px] text-left">
                  &nbsp;[着]
                  {{ nextPointSection.goalPlatform }}
                </div>
              </div>
            </div>
            <div class="ml-auto flex">
              <div v-if="moveSection.fare" class="flex text-W4 mt-4">
                <div class="leading-[14px] text-end">
                  {{ addCommaForInteger(moveSection.fare) }}
                </div>
                <div class="text-[11px] leading-[14px] text-end">円</div>
              </div>
            </div>
          </div>
          <!-- おすすめ乗車位置 -->
          <div
            v-if="isShowGetoff"
            class="mt-4 text-W4 text-[11px] text-gray500 leading-[11px] text-left"
          >
            <div class="mb-2">おすすめ乗車位置</div>
            <div v-if="isNull(getoff)">特定できませんでした</div>
            <div v-else>
              <GetoffPosition :getoff="getoff" />
            </div>
          </div>
        </div>
      </div>
      <!-- 途中駅表示 -->
      <div v-if="isShowCallingAt" class="text-left left-[-7px] relative">
        <div
          v-for="(station, index) in callingAt"
          :key="index"
          class="flex mt-2 first:mt-0 last:mb-[17px]"
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            class="relative my-auto top-[-1px]"
          >
            <g fill="#fff" stroke-width="3" :style="getCallingAtCircleColor">
              <circle cx="5" cy="5" r="5" stroke="none" />
              <circle cx="5" cy="5" r="3.5" fill="none" />
            </g>
          </svg>
          <div class="ml-4 text-W4 text-gray500 tracking-normal leading-[12px]">
            {{ station.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util'
import GetoffPosition from '@/components/atoms/GetoffPosition.vue'
import {getMobilitySectionColor} from '@/utils/transferUtil'
import * as mobilityUtil from '@/utils/mobilityUtil'

const TransferDetailMove = {
  name: 'TransferDetailMove',
  components: {GetoffPosition},
  props: {
    moveSection: {
      type: Object,
    },
    prevPointSection: {
      type: Object,
    },
    nextPointSection: {
      type: Object,
    },
  },
  mixins: [Util],
  data() {
    return {
      isShowCallingAt: false,
      callingAtTop: 0, // 経由駅数の上部マージン
    }
  },
  mounted() {
    // 経由駅の開閉ボタンの上部マージン数を算出
    this.setCallingAtCountTop()
  },
  computed: {
    /**
     * 途中駅に表示する丸デザインのカラースタイル
     */
    getCallingAtCircleColor() {
      return {
        stroke: this.moveSection.color || this.$config.COLOR.GENERAL_MOBILITY,
      }
    },
    /**
     * 電車経路の区間駅情報
     */
    callingAt() {
      if (this.moveSection.transport && this.moveSection.transport.callingAt) {
        return this.moveSection.transport.callingAt
      }
      return []
    },
    /**
     * 方面情報
     * @return {String} 行き先の駅名
     */
    destinationName() {
      const transport = this.moveSection.transport

      // 行き先駅情報の中に駅名が存在する場合、その名称を返却
      if (!this.isNull(transport.destination?.name)) {
        return transport.destination.name
      }

      // 行き先駅情報の中に駅名が存在しない場合、路線情報から駅名を取得し返却する
      const isExistLinksDestinationName =
        Array.isArray(transport.links) &&
        !this.isNull(transport.links[0]?.destination?.name) // eslint-disable-line no-magic-numbers

      return isExistLinksDestinationName
        ? transport.links[0]?.destination?.name // eslint-disable-line no-magic-numbers
        : ''
    },
    /**
     * 出発駅のプラットフォーム
     */
    isStartPlatform() {
      return this.prevPointSection.startPlatform ? true : false
    },
    /**
     * 到着駅のプラットフォーム
     */
    isGoalPlatform() {
      return this.nextPointSection.goalPlatform ? true : false
    },
    /**
     * おすすめ乗車位置を表示するかどうか
     * @return {Boolean} true:表示 false:表示しない
     */
    isShowGetoff() {
      switch (this.moveSection.move) {
        case this.$config.MOVE.SUPEREXPRESS_TRAIN:
        case this.$config.MOVE.SLEEPER_ULTRAEXPRESS:
        case this.$config.MOVE.ULTRAEXPRESS_TRAIN:
        case this.$config.MOVE.EXPRESS_TRAIN:
        case this.$config.MOVE.RAPID_TRAIN:
        case this.$config.MOVE.SEMIEXPRESS_TRAIN:
        case this.$config.MOVE.LOCAL_TRAIN:
          return true
        default:
          return false
      }
    },
    /**
     * おすすめ乗車位置
     */
    getoff() {
      return this.moveSection.transport.getoff
    },
  },
  methods: {
    /**
     * moveセクションの線のスタイル
     * @param {Object} moveSection 移動セクション
     * @return {Object} 背景色スタイルオブジェクト
     */
    getMoveLineColor(moveSection) {
      return {
        background: getMobilitySectionColor(moveSection),
      }
    },
    /**
     * 経由駅の開閉ボタンの上部マージン数を算出
     */
    setCallingAtCountTop() {
      const sectionLineEl = this.$refs.sectionLine // セクション自体
      const callingAtCountEl = this.$refs.callingAtCount // 経由駅数
      // 要素が取得できなければ何もしない
      if (!sectionLineEl || !callingAtCountEl) return

      const sectionHeight = sectionLineEl.clientHeight // 初期表示(折りたたみ)時のセクションの高さ
      const callingAtCountHeight = callingAtCountEl.clientHeight // 経由駅数の高さ

      // 開閉ボタンが折りたたみ時に中心になるよう上部の余白を算出
      this.callingAtTop = (sectionHeight - callingAtCountHeight) / 2 // eslint-disable-line no-magic-numbers
    },
    /**
     * モビリティアイコン取得
     * @param {Object} transportInfo 交通機関情報
     * @return アイコン
     */
    getMobilityIconFromTransportInfo(transportInfo) {
      return mobilityUtil.getMobilityIconFromTransportInfo(transportInfo)
    },
  },
}
export default TransferDetailMove
</script>
<style scoped></style>
