<template>
  <!-- 請求情報カード -->
  <!-- 大枠 -->
  <div id="ClaimCard" class="w-full bg-white rounded-[10px]" :class="cardClass">
    <!-- カード上部 -->
    <div class="flex my-2 items-center" :class="cardXMarginClass">
      <!-- チェックボックス -->
      <div v-if="isNeedCheckAndEdit" class="mr-3">
        <label class="check-box" :for="itemId">
          <input type="checkbox" :id="itemId" v-model="checkedValue" />
          <span class="check-text items-center flex"></span>
        </label>
      </div>
      <!-- 金額表示 -->
      <div class="flex-1 text-left flex">
        <div class="text-W7 text-[18px] leading-[23px]">
          {{ displayFare }}
        </div>
        <div
          v-if="isClaimDataOfNAMO"
          class="flex items-center justify-center text-W5 text-[11px] text-blue leading-none w-[46px] h-[19px] my-auto ml-2 bg-blue200 rounded-[4px]"
        >
          NAMO
        </div>
      </div>
      <!-- 編集ボタン -->
      <div
        v-if="isNeedCheckAndEdit"
        class="flex items-center"
        @click="onClickedEdit()"
      >
        <img src="@/assets/Icon_Edit.svg" class="w-3 h-3 mr-1" />
        <div class="text-W4 text-blue text-[13px]">編集</div>
      </div>
    </div>
    <!-- 間の線 -->
    <div class="h-[1px] bg-gray300 w-full" />
    <!-- カード下部 -->
    <div class="my-4 text-left" :class="cardXMarginClass">
      <!-- 交通機関 -->
      <div class="flex items-center mb-2 w-full">
        <img src="@/assets/claims/StartSpot.svg" class="w-3 h-3 mr-2" />
        <div class="text-W4 text-[13px] leading-[13px] text-gray500 truncate">
          {{ transportationName }}
        </div>
      </div>
      <!-- 出発地、目的地 -->
      <div class="flex items-center mb-2 w-full">
        <img src="@/assets/claims/GoalSpot.svg" class="w-3 h-3 mr-2" />
        <div class="flex items-center w-full" ref="spotArea">
          <div
            ref="startSpot"
            class="text-W4 text-[13px] leading-[13px] text-gray500 truncate"
            :style="{minWidth: startWidth, maxWidth: startWidth}"
          >
            {{ claim.detail.departure }}
          </div>
          <div class="text-W4 text-[13px] leading-[13px] text-gray500 mx-2">
            -
          </div>
          <div
            ref="goalSpot"
            class="text-W4 text-[13px] leading-[13px] text-gray500 truncate"
            :style="{maxWidth: goalWidth}"
          >
            {{ claim.detail.destination }}
          </div>
        </div>
      </div>
      <!-- 利用者氏名 -->
      <div class="flex items-center w-full">
        <img src="@/assets/claims/Claimant.svg" class="w-3 h-3 mr-2" />
        <div class="text-W4 text-[13px] leading-[13px] text-gray500 truncate">
          {{ claim.detail.userName }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util'
const ClaimCard = {
  name: 'ClaimCard',
  emits: ['check-claim', 'edit-claim'],
  mixins: [Util],
  props: {
    // 請求情報
    claim: {
      type: Object,
      required: true,
    },
    // 選択フラグ
    checked: {
      type: Boolean,
      default: true,
    },
    // 編集・選択表示の有無
    isNeedCheckAndEdit: {
      type: Boolean,
      default: true,
    },
    // エラーフラグ
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 出発地点名の表示幅
      startWidth: undefined,
      // 目的地点名の表示幅
      goalWidth: undefined,
    }
  },
  computed: {
    /**
     * ItemIdを取得する
     */
    itemId() {
      return this.claim.itemId
    },
    /**
     * 請求データがNAMO利用のものか否か
     */
    isClaimDataOfNAMO() {
      return this.claim.type === this.$config.CLAIM_TYPE.NAMO
    },
    /**
     * カード内の横余白クラスを決定する
     */
    cardXMarginClass() {
      return this.isNeedCheckAndEdit && this.checked ? 'mx-5' : 'mx-[23px]'
    },
    /**
     * 選択状態に合わせてカードのスタイルを決定する
     */
    cardClass() {
      return this.isNeedCheckAndEdit && this.checked
        ? this.isError
          ? 'border border-danger300 py-[3px] bg-danger100'
          : 'border-[4px] shadow-normal shadow-[#009CE566] border-[#009CE566]' // 透明度約40%の青
        : 'border border-gray300 py-[3px]'
    },
    /**
     * 交通機関名
     */
    transportationName() {
      return Object.values(this.$config.TRANSPORTATION_LIST).find(
        (obj) => obj.id === this.claim.detail.transportation
      )?.label
    },
    /**
     * チェックボックスの値
     * getters：propsで受け取った値を取得
     * setters：変更後の値を親に通知
     */
    checkedValue: {
      get() {
        return this.checked
      },
      set(checkData) {
        // チェック状況を親コンポーネントにemitする
        this.$emit('check-claim', {
          claimItemId: this.claim.itemId,
          checked: checkData,
        })
      },
    },
    /**
     * 表示用の金額
     */
    displayFare() {
      return this.claim.detail.fare == null
        ? '￥ -'
        : `￥${this.addCommaForInteger(this.claim.detail.fare)}`
    },
  },
  mounted() {
    this.setSpotAreaNameWidth()
  },
  methods: {
    /**
     * 「編集」の押下通知
     */
    onClickedEdit() {
      // 「編集」押下を親コンポーネントにemitする
      this.$emit('edit-claim', this.claim)
    },
    /**
     * 出発地点名・目的地点名の表示幅を設定
     */
    setSpotAreaNameWidth() {
      // 出発地・目的地の表示以外の領域の幅
      // カードの外の余白20*2, カードの中の余白20*2, スポットアイコン12+アイコン右側余白8,
      // eslint-disable-next-line no-magic-numbers
      const otherAreaWidth = 20 * 2 + 20 * 2 + 12 + 8
      // 出発地・目的地の表示領域の幅
      // eslint-disable-next-line no-magic-numbers
      const spotAreaWidth = window.innerWidth - otherAreaWidth
      // ハイフンと左右にある空白幅(余白8px*2 + 文字5px)
      const hyphenSpace = 23
      if (spotAreaWidth < this.$refs.spotArea.offsetWidth) {
        // 横幅が表示領域を超えている場合
        // 一つの地点の横幅計算
        // eslint-disable-next-line no-magic-numbers
        let spotWidth = (spotAreaWidth - hyphenSpace) / 2
        const currentStartWidth = this.$refs.startSpot.offsetWidth
        if (currentStartWidth < spotWidth) {
          // 出発地点が一つの地点の最大横幅に満たない場合
          // 元々持っている横幅を最小横幅とする。
          // ただしそのままの値とすると三点リーダとなるため、調整分の横幅を追加しておく
          const adjustmentPx = 10
          this.startWidth = `${currentStartWidth + adjustmentPx}px`
          // 目的地点の幅を設定しないとそのまま表示されてしまうため、
          // 表示領域から出発地の横幅とハイフン用幅を引いた幅を最大横幅に指定する
          const maxGoalWidth =
            spotAreaWidth - currentStartWidth - adjustmentPx - hyphenSpace
          this.goalWidth = `${maxGoalWidth}px`
        } else {
          // 出発地点の横幅が一つの地点の最大横幅を超えている時は、
          // 出発地点、目的地の両方の最大横幅を指定する
          this.startWidth = `${spotWidth}px`
          this.goalWidth = `${spotWidth}px`
        }
      }
    },
  },
}
export default ClaimCard
</script>
<style>
.check-box input {
  display: none; /* デフォルトのチェックボックスを非表示 */
}
.check-box input + .check-text::before {
  background-image: url(@/assets/CheckOff.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  position: relative;
  width: 20px;
  height: 20px;
}
.check-box input:checked + .check-text::before {
  background-image: url(@/assets/CheckOn.svg);
}
</style>
