<template>
  <div class="mr-1">
    <div>
      <!-- 電車・空港・フェリー -->
      <div class="text-W6 text-[13px] leading-[13px] pb-5">電車・空港・港</div>
      <div
        v-for="station in otherStationList"
        :key="station.id"
        class="flex mb-[20px] last:mb-0"
        @click="$emit('click-station', station)"
      >
        <img
          class="ml-4 mr-4 h-4 w-4 self-center"
          :src="getStationIcon(station.types)"
        />
        <div class="text-left">
          <div class="text-W4 text-[16px] leading-4">{{ station.name }}</div>
          <div class="text-W2 text-[13px] text-gray500 leading-[13px] mt-1">
            {{ station.ruby }}
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5 separate-border" />
    <div class="pt-5">
      <!-- バス停 -->
      <div class="text-W6 text-[13px] leading-[13px] pb-5">バス停</div>
      <div
        v-for="station in busStationList"
        :key="station.id"
        class="flex mb-[20px] last:mb-0"
        @click="$emit('click-station', station)"
      >
        <img
          class="ml-4 mr-4 h-4 w-4 self-center"
          src="@/assets/TransferBusIconBlue.svg"
        />
        <div class="text-left ml-2">
          <div class="text-W4 text-[16px] leading-4">{{ station.name }}</div>
          <div class="text-W2 text-[13px] text-gray500 leading-[13px] mt-1">
            {{ station.addressName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const StationSuggestList = {
  name: 'StationSuggestList',
  props: {
    stationList: {
      type: Array,
      required: true,
    },
  },
  emits: ['click-station'],
  data() {
    return {
      busStationList: [], // バス停のみの一覧
      otherStationList: [], // バス停以外の駅一覧
    }
  },
  watch: {
    stationList: function () {
      this.sepatareStationList()
    },
  },
  methods: {
    /**
     * 受け取った駅名配列をバス停とそれ以外の配列に分割する
     */
    sepatareStationList() {
      let busStationList = []
      let otherStationList = []
      // バス停とそれ以外で配列を分割
      for (let station of this.stationList) {
        switch (station.types) {
          case 'busstop': // 路線バスのバス停
          case 'shuttle_busstop': // 空港連絡バスのバス停
          case 'highway_busstop': // 高速バスのバス停
            busStationList.push(station)
            break
          default:
            otherStationList.push(station)
        }
      }
      this.busStationList = busStationList
      this.otherStationList = otherStationList
    },
    /**
     * モビリティごとにアイコンを切り替え
     */
    getStationIcon(types) {
      let iconName
      switch (types) {
        case 'station':
          iconName = 'TransferTrainIconBlue.svg'
          break
        case 'airport':
          iconName = 'TransferAirplainIconBlue.svg'
          break
        case 'port':
          iconName = 'TransferFerryIconBlue.svg'
          break
        default:
          return
      }
      return require(`@/assets/${iconName}`)
    },
  },
}
export default StationSuggestList
</script>
<style scoped>
.separate-border {
  border-bottom: 0.5px solid #dbdee3;
}
</style>
