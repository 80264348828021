<template>
  <div
    id="RouteByTrainBus"
    ref="RouteByTrainBus"
    class="fixed top-0 w-full h-full bg-gray200 flex flex-col"
  >
    <!-- ヘッダー部 -->
    <div
      id="header-area"
      class="header flex w-full pt-safe-area pb-10 pr-4 pl-3 shadow-normal"
    >
      <!-- 戻るアイコン -->
      <div
        id="backButton"
        class="circle top-4 left-3 mt-4 mr-4 shadow-normal"
        @click="goToBackPage()"
      >
        <img class="h-4 w-4 mt-2.5 mx-auto" src="@/assets/Icon_Left_gray.svg" />
      </div>
      <!-- 経路情報 -->
      <div
        class="flex-1 w-full h-full mt-4 rounded-[20px] bg-white shadow-normal"
      >
        <DisplaySpotAndTime
          :searchConditions="trainBusRouteSearchConditions"
          @display-calendar-set-time="isShowCalenderSelectModal = true"
        />
      </div>
    </div>
    <div
      class="overflow-y-auto scroll-bar-none w-full flex-1"
      id="routeByTrainBusContents"
    >
      <!-- コンテンツ部（ルートがある場合） -->
      <div v-if="isTrainBusRouteSearchResult">
        <!-- カード -->
        <div class="py-[18px] px-6 h-full">
          <MobilityTrainBusCard
            class="mb-2 last:mb-0"
            v-for="(route, index) in trainBusRouteSearchResult"
            :key="index"
            :routeNo="route.routeNo"
            :routeInfo="route"
            :isNoTransfersCount="route.transitCount === 0"
            :isFirstTag="isRouteFastTime(route)"
            :isLowCostTag="isOptimalRoute('summaryFare', route.summaryFare)"
            @click-train-bus-card="clickTrainBusCard(route.routeNo)"
          />
        </div>
      </div>
      <!-- コンテンツ部（ルートが一つも存在しない場合） -->
      <div v-else class="mt-16">
        <div class="text-W2 text-[13px] leading-[13px] text-gray500">
          ルートがみつかりませんでした
        </div>
        <div class="mt-4 flex justify-center">
          <img :src="errorSrc" class="h-[140px] w-[140px]" />
        </div>
      </div>
      <div class="h-8"></div>
    </div>
    <FooterMargin />
  </div>
  <!-- 時刻設定ポップアップ -->
  <Modal
    class="modal"
    v-if="isShowCalenderSelectModal"
    @disPlayModal="isShowCalenderSelectModal = false"
    modalPaddingBottom="8px"
  >
    <div id="select-datetime-modal">
      <CalendarSelectForm
        @completeDate="completeDate"
        :initialDateTime="trainBusRouteSearchConditions.targetTime"
        :initialTargetType="trainBusRouteSearchConditions.timeType"
      />
    </div>
  </Modal>
</template>
<script>
import Util from '@/mixins/util'
import MobilityTrainBusCard from '@/components/MobilityTrainBusCard.vue'
import Modal from '@/components/Modal.vue'
import DisplaySpotAndTime from '@/components/organisms/DisplaySpotAndTime.vue'
import CalendarSelectForm from '@/components/CalendarSelectForm.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'

// 出発・到着情報
const TIME_TYPE = {
  START: 1,
  GOAL: 2,
}

const RouteByTrainBus = {
  name: 'RouteByTrainBus',
  components: {
    MobilityTrainBusCard,
    Modal,
    DisplaySpotAndTime,
    CalendarSelectForm,
    FooterMargin,
  },
  mixins: [Util],
  data() {
    return {
      isShowCalenderSelectModal: false, //時刻設定ポップアップ表示フラグ
      errorSrc: this.getErrorAssetsSrc(), // 検索失敗時の画像パス
    }
  },
  created() {},
  computed: {
    /**
     * 電車・バス画面内の検索条件の取得
     */
    trainBusRouteSearchConditions() {
      return this.$store.state.RouteStore.trainBusRouteSearchConditions
    },
    /**
     * 電車・バス画面内の検索条件の取得
     */
    trainBusRouteSearchResult() {
      return this.$store.state.RouteStore.trainBusRouteSearchResult
    },
    /**
     * 電車・バスの検索結果が存在するか
     */
    isTrainBusRouteSearchResult() {
      return (
        !this.isNull(this.trainBusRouteSearchResult) &&
        this.trainBusRouteSearchResult.length !== 0 // eslint-disable-line no-magic-numbers
      )
    },
    /**
     * 全ルート結果から到着時間を抽出し、リストにする
     * @return {Array} 到着時間のリスト
     */
    getGoalTimeList() {
      let list = []
      this.trainBusRouteSearchResult.forEach((result) => {
        list.push(this.getGoalTime(result))
      })
      return list
    },
  },
  methods: {
    /**
     * 最適ルートかどうかを返却
     * ・key=summaryFare：乗車金額
     * @param {String} key 検索対象のキー
     * @param {String | Number} value 比較対象の値
     * @return {Boolean} 最適ルートかどうか
     */
    isOptimalRoute(key, value) {
      return this.isMinObject(this.trainBusRouteSearchResult, key, value)
    },
    /**
     * ルート内で最短時間かどうかを返却
     * 出発検索:到着時刻が早いデータにラベルを付与
     * 到着検索:総移動時間が短いデータにラベルを付与
     * @param {Object} value 比較対象データ
     * @return {Boolean} 一番早い到着時間かどうか
     */
    isRouteFastTime(value) {
      if (this.trainBusRouteSearchConditions.timeType.id === TIME_TYPE.START) {
        // 検索結果にある全ての到着時間と比較して一番早い時間のデータにラベルをつける
        const goalTime = this.getGoalTime(value)
        return this.isMinTime(goalTime)
      } else {
        return this.isMinObject(
          this.trainBusRouteSearchResult,
          'summaryTime',
          value.summaryTime
        )
      }
    },
    /**
     * 到着時間を取得する
     * 対象データ
     * @param {Object} resultData routeNoごとのルートデータ
     * @return {String} 到着時間
     */
    getGoalTime(resultData) {
      const lastIndexAdjustment = 1
      let list = resultData.sections.filter(
        (section) => section.type === 'move'
      )
      return list[list.length - lastIndexAdjustment].toTime
    },
    /**
     * 対象データの到着時間が一番早いデータであるかを返却する
     * @param {Object} target 比較対象の到着時間
     * @return {Boolean} 検索結果データの中で到着時間が早いデータ
     */
    isMinTime(target) {
      return (
        this.getGoalTimeList.find((obj) => {
          return obj < target
        }) === undefined
      )
    },
    /**
     * 前の画面に遷移
     */
    goToBackPage() {
      // キャッシュしている電車バスのルート線情報をクリア
      this.$store.commit('clearDrawRouteData', 'trainBus')

      // 最適ルート画面に遷移
      this.$router.push({name: this.$config.DISPLAY_ROUTE_BY_OPTIMAL})
    },

    /**
     * モビリティカード押下時処理
     * 選択されたルート結果内番号のルート情報をStoreに格納し、詳細画面に遷移する
     * @param {String} routeNo routeTotalで採番されるルート結果内番号
     */
    clickTrainBusCard(routeNo) {
      const selectedRouteSearchResult = this.trainBusRouteSearchResult.find(
        (route) => {
          return route.routeNo == routeNo
        }
      )
      // トータルルート情報を退避
      this.$store.commit(
        'RouteStore/updateSelectedRouteTotal',
        selectedRouteSearchResult
      )
      // ルート詳細画面に遷移
      this.$router.push({name: 'RouteDetailByTrainBus'})
    },
    /**
     * 日付選択完了時処理
     * 検索条件格納用Storeを更新後、再検索処理を実行する
     * @param {String} dateTime 検索条件：日時
     * @param {Object} targetType 検索条件：出発情報 or 到着情報
     */
    completeDate(dateTime, targetType) {
      this.$store.commit('startLoading')

      // 乗り換え検索時に使用する時間を保持
      this.$store.commit('RouteStore/updateTrainBusSearchConditions', {
        key: 'targetTime',
        value: dateTime,
      })
      // 検索条件に反映
      this.$store.commit('RouteStore/updateTrainBusSearchConditions', {
        key: 'timeType',
        value: targetType,
      })
      // 検索条件が変わるため、キャッシュしている電車バスのルート線情報をクリア
      this.$store.commit('clearDrawRouteData', 'trainBus')

      // ポップアップを非表示にする
      this.isShowCalenderSelectModal = false

      const success = () => {
        this.$store.commit('endLoading')
      }

      const failed = () => {
        this.$store.commit('endLoading')
        // 取得失敗した場合は、初期値(0件)を設定する
        this.$store.commit('RouteStore/updateTrainBusRouteResult', {
          value: [],
        })
        throw new Error()
      }

      const error = (e) => {
        this.$store.commit('endLoading')
        // 取得失敗した場合は、初期値(0件)を設定する
        this.$store.commit('RouteStore/updateTrainBusRouteResult', {
          value: [],
        })
        throw e
      }

      // 再検索する
      this.$store.dispatch('RouteStore/getRouteTrainBus', {
        success: success,
        failed: failed,
        error: error,
      })
    },
  },
}
export default RouteByTrainBus
</script>
<style scoped>
.header {
  background: linear-gradient(#61c7f7, #009ce5);
}
.circle {
  height: 36px;
  width: 36px;
  line-height: 36px;
  background: white;
  border-radius: 50%;
}
#routeByTrainBusContents::-webkit-scrollbar {
  /* スクロールバー非表示 */
  display: none;
}
</style>
