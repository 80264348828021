<template>
  <div class="bg-gray600 flex flex-col w-[18px] rounded-[23px] opacity-80">
    <ul>
      <li
        v-for="item in hourSideBarList"
        :key="item"
        :data-index="item"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        class="text-W4 text-[10px] leading-[11px] text-white pb-1 last:pb-2.5 pt-0 first:pt-2.5"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
const TimetableSideBar = {
  name: 'TimetableSideBar',
  props: {
    /**
     * 時間帯一覧
     */
    hourSideBarList: {
      type: Array,
      require: true,
    },
  },
  emits: ['select-sidebar-item'],
  methods: {
    /**
     * 時刻表時間リストのタップ開始処理
     * @param {TouchEvent} event touchStartイベント
     */
    handleTouchStart(event) {
      // 通知処理
      this.notifyTouch(event.target)
    },
    /**
     * 時刻表時間リストのタップ中処理
     * @param {TouchEvent} event touchMoveイベント
     */
    handleTouchMove(event) {
      // 引数のeventは押下開始時点の要素情報を保持している。
      // 押下したまま動かした場合も、移動後ではなく移動前の要素情報が取得されてしまうため、移動後の選択している時間帯が取得できない。
      // ただ、押下中の指が触れている座標は移動後の座標が取得できるので、座標をもとに要素を探索し、時刻表の時間帯要素を取得する。
      const firstIndex = 0
      const touch = event.touches[firstIndex]
      const element = document.elementFromPoint(touch.clientX, touch.clientY)
      // 通知処理
      this.notifyTouch(element)
    },
    /**
     * 選択した時刻表の親コンポーネント通知処理
     * @param {Element} element 要素情報
     */
    notifyTouch(element) {
      // あらかじめ時刻表時間の各要素に付与した属性を参照し、その値を親へ通知する
      if (element?.hasAttribute('data-index')) {
        const index = element.getAttribute('data-index')
        if (index !== null) {
          this.$emit('select-sidebar-item', index)
        }
      }
    },
  },
}
export default TimetableSideBar
</script>

<style scoped></style>
