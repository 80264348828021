<template>
  <div class="flex w-full">
    <div class="flex-1 overflow-hidden">
      <!-- 出発地 -->
      <SpotName class="mb-2" :spotName="this.start" type="start" />
      <!-- 経由地 -->
      <div v-if="showVia">
        <div v-for="spot in via" :key="spot.id">
          <SpotName class="mb-2" :spotName="spot.name" type="via" />
        </div>
      </div>
      <!-- 目的地 -->
      <SpotName class="" :spotName="this.goal" type="goal" />
    </div>
    <!-- 経由地表示/非表示切り替えアイコン -->
    <div v-if="existVia" class="ml-4 mt-[11px]" @click="changeShowVia">
      <div class="flex h-[13px] w-auto items-center">
        <span v-if="showVia">
          <img src="@/assets/Icon_top.svg" class="h-2.5 w-2.5" />
        </span>
        <span v-else>
          <img src="@/assets/Icon_down.svg" class="h-2.5 w-2.5" />
        </span>
        <span class="ml-1 text-W4 text-[13px] leading-[13px] text-blue">
          経由地
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import SpotName from '@/components/atoms/SpotName.vue'

/**
 * 経路表示のコンポーネント
 */
const DisplaySearchSpots = {
  name: 'DisplaySearchSpots',
  components: {SpotName},
  emits: ['display-via-spot'],
  props: {
    /**
     * 出発地点名
     */
    start: {
      type: String,
      require: true,
    },
    /**
     * 経由地点名
     */
    via: {
      type: Array,
      require: false,
      default: () => {
        return []
      },
    },
    /**
     * 目的地点名
     */
    goal: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      // 経由地表示フラグ
      showVia: false,
    }
  },
  updated() {
    // 画面変更を親に通知
    this.$emit('display-via-spot')
  },
  computed: {
    /**
     * 経由地情報があるか
     * @return true：経由地がある
     *         false：経由地がない
     */
    existVia() {
      return this.via.length !== 0 // eslint-disable-line no-magic-numbers
    },
  },
  methods: {
    /**
     * 経由地表示フラグを切り替える
     */
    changeShowVia() {
      this.showVia = !this.showVia
    },
  },
}
export default DisplaySearchSpots
</script>
<style scoped></style>
