<template>
  <!-- フッター部コンポーネント -->
  <div id="footer-bar">
    <div
      class="footer touch-none fixed bottom-0 z-[1000] w-full rounded-t-[32px] bg-[#041427]"
    >
      <div class="w-screen flex text-center text-[10px] mb-safe-area">
        <div class="w-1/5" v-for="item in footer" :key="item.id">
          <div @click="updateFooterTab(item)">
            <!-- 手配タブ選択の場合 -->
            <template v-if="isArrangementMenu(item.id)">
              <div v-if="isReservedOrUsingOfMobility">
                <!-- 自転車/タクシーが予約/利用時 -->
                <img
                  class="mx-auto mt-1.5 w-[46px] h-[46px]"
                  :src="getArrangementFooterIcon(item.iconName)"
                />
              </div>
              <div v-else>
                <div v-if="isSelectedFooterTab(item.id)">
                  <!-- 選択時 -->
                  <img
                    class="mx-auto mt-2.5 w-6 h-6 mb-1"
                    :src="getFooterIcon(item.iconName.on)"
                  />
                  <div
                    class="text-[#00e0ff] text-[10px] text-W6 leading-[10px] mb-1"
                  >
                    {{ item.displayName }}
                  </div>
                </div>
                <div v-else>
                  <!-- 未選択時 -->
                  <img
                    class="mx-auto mt-2.5 w-6 h-6 mb-1"
                    :src="getFooterIcon(item.iconName.off)"
                  />
                  <div
                    class="text-[#00e0ff] text-[10px] text-W4 leading-[10px] mb-1"
                  >
                    {{ item.displayName }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-if="isSelectedFooterTab(item.id)">
                <!-- 選択時 -->
                <div class="mx-auto mt-3.5 w-[40px] h-[34px] relative">
                  <img
                    class="mx-[10px] w-5 h-5 mb-1"
                    :src="getFooterIcon(item.iconName.on)"
                  />
                  <div
                    class="text-white text-[10px] text-W6 leading-[10px] mb-1"
                  >
                    {{ item.displayName }}
                  </div>
                  <!-- メニュータブかつ未表示のお知らせ通知はある場合、赤いバッジをつける -->
                  <div
                    v-if="isMenu(item.id) && existUnDisplayMessage()"
                    class="badge"
                  />
                </div>
              </div>
              <div v-else>
                <div class="mx-auto mt-3.5 w-[40px] h-[34px] relative">
                  <!-- 未選択時 -->
                  <img
                    class="mx-[10px] w-5 h-5 mb-1"
                    :src="getFooterIcon(item.iconName.off)"
                  />
                  <div
                    class="text-gray text-[10px] text-W4 leading-[10px] mb-1"
                  >
                    {{ item.displayName }}
                  </div>
                  <!-- メニュータブかつ未表示のお知らせ通知はある場合、赤いバッジをつける -->
                  <div
                    v-if="isMenu(item.id) && existUnDisplayMessage()"
                    class="badge"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util.js'

const AppFooter = {
  name: 'AppFooter',
  data() {
    return {
      footer: this.$config.FOOTER_LIST,
    }
  },
  mixins: [Util],
  created() {},
  computed: {
    /**
     * 選択中のフッタータブIDを取得する
     * @return {Object} 選択中のフッタータブ情報
     */
    selectedFooterTab() {
      return this.$store.state.selectedFooterTab
    },
    /**
     * 利用中のモビリティ種別を返す変数
     */
    usingMobilityType() {
      return this.$store.getters[
        'MobilityReservationStore/reservedOrUsingOfMobilityType'
      ]
    },
    /**
     * 「予約中」または「利用中」のモビリティがあるかどうかを返す変数
     */
    isReservedOrUsingOfMobility() {
      return this.usingMobilityType !== this.$config.USING_MOBILITY_TYPE.NONE
    },
    /**
     * モビリティ情報取得済
     */
    inProgressGetMobilityInfo() {
      return this.$store.state.MobilityReservationStore
        .inProgressGetMobilityInfo
    },
  },
  methods: {
    /**
     * フッターアイコンのパスを取得する
     * @param {String} iconName  アイコンのファイル名
     */
    getFooterIcon(iconName) {
      return require('@/assets/footer/' + iconName)
    },
    /**
     * 選択した項目か判定する
     * @param {number} footerId  選択した項目ID
     * @return {boolean} true:選択した項目、false:選択外の項目
     */
    isSelectedFooterTab(footerId) {
      return footerId == this.selectedFooterTab
    },
    /**
     * フッター更新時処理
     * @param {Object} value  選択したタブ
     */
    updateFooterTab(value) {
      // 初回モビリティ情報取得中のタブアイコン押下は無視する
      if (this.inProgressGetMobilityInfo) {
        return
      }
      if (value.id === this.$store.state.selectedFooterTab) {
        // 検索条件等データ初期化処理
        this.initializeConditionsAndResult(value.id)
        // 各TOP画面へ遷移
        this.$router.push({name: value.initialPage})
        return
      }

      // 更新前に現在のタブの表示ページ情報を保持しておく
      this.storeCurrentTabInformation()

      // storeに保持しているページ情報を取得
      let nextPage = this.getNextPageId(value.id)
      // 取得したページをプッシュ
      if (
        value.id === this.$config.FOOTER_ID_ARRANGEMENT &&
        this.isReservedOrUsingOfMobility
      ) {
        // 手配タブ(自転車利用中・タクシー手配利用中)を押下した場合

        // 遷移元のパスをstoreに登録
        this.$store.commit(
          'MobilityReservationStore/updateScreenTransitionSource',
          {
            displayName: this.$route.name,
            footerId: this.$store.state.selectedFooterTab,
          }
        )
        // 利用中の乗り物に合わせて遷移先を切り替える
        switch (this.usingMobilityType) {
          case this.$config.USING_MOBILITY_TYPE.BICYCLE:
            nextPage = this.$config.DISPLAY_BICYCLE_RESERVATION_CONFIRMED
            break
          case this.$config.USING_MOBILITY_TYPE.TAXI:
            nextPage = this.$config.DISPLAY_TAXI_RESERVATION_COMPLETED
            break
          default:
            break
        }
      }

      if (nextPage) {
        // 選択したタブの画面または予約確定画面に遷移
        this.$router.push({name: nextPage})
      }
      // タブIDを更新
      this.$store.commit('updateFooterTab', value.id)
      // ストレージのタブIDを更新
      this.setLocalStorage(this.$config.FOOTER_TAB, value.id)
      // 認証必須画面遷移用のストレージを破棄
      localStorage.removeItem(this.$config.NEXT_PAGE_IN_AUTH)
    },
    /**
     * 現在のタブの表示ページ情報を保持する
     */
    storeCurrentTabInformation() {
      const footerName = this.$config.FOOTER_LIST.find((footer) => {
        return footer.id == this.selectedFooterTab
      }).name
      let page = {key: footerName, value: this.$route.name}
      this.$store.commit('updateCurrentPage', page)
    },
    /**
     * 引数のIDが手配メニューIDかどうか判定する
     */
    isArrangementMenu(id) {
      return id === this.$config.FOOTER_ID_ARRANGEMENT
    },
    /**
     * 引数のIDがメニューIDかどうか判定する
     */
    isMenu(id) {
      return id === this.$config.FOOTER_ID_MENU
    },
    /**
     * お知らせ通知一覧で未表示のお知らせが存在するかを判定する
     */
    existUnDisplayMessage() {
      return this.$store.getters['existUnDisplayMessage']
    },
    /**
     * 自転車／タクシー共通で利用中のフッターアイコンを返す
     */
    getArrangementFooterIcon(iconName) {
      switch (this.usingMobilityType) {
        case this.$config.USING_MOBILITY_TYPE.BICYCLE:
          return this.getFooterIcon(iconName.bicycle)
        case this.$config.USING_MOBILITY_TYPE.TAXI:
          return this.getFooterIcon(iconName.taxi)
        default:
      }
    },
    /**
     * 画面ごとの初期化処理
     * @param {Number} footerId  選択中のタブID
     */
    initializeConditionsAndResult(footerId) {
      switch (footerId) {
        // スポット
        case this.$config.FOOTER_ID_SPOT:
          // ルート検索結果画面(モビリティ)から遷移する際に削除処理の方が速いため
          // ここでは、ルート形状データだけを初期化する
          this.$store.commit('clearDrawRouteScript')
          this.$root.$refs.routeMap?.currentSpotFit?.()
          this.$root.$refs.routeMap?.clearDrawRoute?.()
          break
        // 乗換案内
        case this.$config.FOOTER_ID_TRANSFER:
          this.$store.commit('TransferStore/resetConditionAndResult')
          break
        // 時刻表
        case this.$config.FOOTER_ID_TIME_TABLE:
          this.$store.commit('TimeTableStore/initTimeTableState')
          break
        case this.$config.FOOTER_ID_ARRANGEMENT:
        case this.$config.FOOTER_ID_MENU:
          break
        default:
      }
    },
  },
}
export default AppFooter
</script>
<style scoped>
/* フッター */
.footer {
  transform: translateZ(1px);
}
/* メニューのバッジ */
.badge {
  position: absolute;
  top: -2px;
  right: 6px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #e82a2a;
}
</style>
