<template>
  <div id="overlay" class="overlay">
    <div
      class="overlay-layer"
      :class="blurEnabled && 'overlay-layer-blur'"
      @click="closeOverlay('outsideArea')"
    >
      <div class="w-full pb-10">
        <div @click="stopEvent">
          <!-- ヘッダー -->
          <div
            class="flex text-center w-full items-center px-9"
            :style="{'padding-bottom': `${pixelOfTitleAndBody}px`}"
          >
            <div class="h-4 w-4"></div>
            <div class="flex-1 text-W6 text-white text-[19px] leadiing-[19px]">
              <slot name="header"></slot>
            </div>
            <img
              v-if="isShowCloseButton"
              src="@/assets/Icon_Close_wh.svg"
              class="h-4 w-4"
              @click="closeOverlay('closeButton')"
            />
          </div>
          <!-- メイン -->
          <div>
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const OverlayComponent = {
  name: 'OverlayComponent',
  emits: ['close-overlay'],
  props: {
    // タイトルとボディ間のスペース
    pixelOfTitleAndBody: {
      type: String,
      default: '36',
    },
    // 背景押下時のクリックイベント発火制御
    isEnableCloseOutsideArea: {
      type: Boolean,
      default: false,
    },
    // 閉じるボタンの表示制御
    isShowCloseButton: {
      type: Boolean,
      default: true,
    },
    // 背景ボカシの有無
    blurEnabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /**
     * オーバーレイ非表示通知
     * @param {String} tapArea タップされたエリア
     */
    closeOverlay(tapArea) {
      // 閉じるボタン押下時、もしくは背景押下時(有効時)に通知する
      if (
        tapArea == 'closeButton' ||
        (tapArea == 'outsideArea' && this.isEnableCloseOutsideArea)
      ) {
        this.$emit('close-overlay')
      }
    },
    /**
     * 背景押下時の発火有効時には、クリックイベントの伝搬を停止
     * @param {Object} e クリックイベント
     */
    stopEvent(e) {
      if (this.isEnableCloseOutsideArea) {
        e.stopPropagation()
      }
    },
  },
}
export default OverlayComponent
</script>
<style scoped>
.overlay {
  position: fixed;
  z-index: 5000;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 5000px);
  transform: translate3d(0, 0, 5000px);
}
.overlay-layer {
  display: flex;
  align-items: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000066;
}
.overlay-layer-blur {
  backdrop-filter: blur(3px) brightness(81%);
  background-color: #1a1c2133;
}
</style>
