<template>
  <!-- 乗務員メッセージ -->
  <div v-if="messageObject.sender === sender.DRIVER" class="flex justify-start">
    <div class="h-8 w-8">
      <img
        v-if="isFirst"
        class="h-8 w-8"
        src="@/assets/arrangementIcon/taxi/IconDriver.svg"
      />
    </div>
    <div
      class="driverSection p-4 ml-4 rounded-ee-[40px] text-W4 text-[14px] bg-white max-w-[60%] text-left"
    >
      {{ messageObject.message }}
    </div>
    <span class="ml-1 text-W3 text-[9px] text-gray500 self-end">
      {{ formattedCreateAt }}
    </span>
  </div>
  <!-- 利用者メッセージ -->
  <div
    v-else-if="messageObject.sender === sender.USER"
    class="flex justify-end"
  >
    <span class="text-W3 text-[9px] text-gray500 self-end">
      {{ formattedCreateAt }}
    </span>
    <div
      class="userSection p-4 ml-1 rounded-ee-[40px] text-W4 text-[14px] bg-blue300 border border-blue400 max-w-[60%] text-left"
    >
      {{ messageObject.message }}
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util'

const ChatMessageSection = {
  name: 'ChatMessageSection',
  mixins: [Util],
  props: {
    // メッセージオブジェクト
    messageObject: {
      type: Object,
      require: true,
    },
    isFirst: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    formattedCreateAt() {
      return this.getFormatTime(this.messageObject.createAt)
    },
    sender: {
      get() {
        return this.$config.TAXI_MESSAGE_SENDER
      },
    },
  },
}
export default ChatMessageSection
</script>
<style scoped>
.driverSection {
  border-radius: 0px 40px 40px 40px;
}
.userSection {
  border-radius: 40px 40px 0px 40px;
}
</style>
