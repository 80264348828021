<template>
  <svg viewBox="0 0 32 32">
    <g transform="translate(-116 -119)">
      <circle
        cx="16"
        cy="16"
        r="16"
        transform="translate(116 119)"
        fill="#f2f3f5"
        class="icon-favorite-home-circle"
      />
      <g transform="translate(123 126)">
        <rect width="18" height="18" fill="none" />
        <path
          d="M161.3-825.684h3.254v-5.423h4.772v5.423h3.254v-8.46l-5.64-4.23-5.64,4.23Zm-1.3,1.3v-10.412L166.941-840l6.941,5.206v10.412h-5.857v-5.423h-2.169v5.423ZM166.941-832.039Z"
          transform="translate(-157.871 841.191)"
          fill="#0072ff"
          class="icon-favorite-home-path"
        />
      </g>
    </g>
  </svg>
</template>
