<template>
  <div id="suicaDetail">
    <!-- ヘッダー -->
    <Header
      headerTitle="モバイルSuica"
      :isShowClose="true"
      @clickClose="clickClose()"
    />
    <!-- ボディ -->
    <div class="px-6 pt-5 pb-6 overflow-y-auto scroll-bar-none">
      <div>
        <!-- カード -->
        <div class="pb-6">
          <div class="flex justify-center">
            <img
              src="@/assets/suica/namoCard.png"
              alt=""
              width="682"
              height="446"
              class="aspect-[1.53]"
            />
          </div>
          <div class="pt-3 text-left pl-[14px]">
            <div
              class="text-W4 text-[13px] leading-[18px] tracking-[0.26px] text-gray500"
            >
              チャージ残高
            </div>
            <div
              v-if="isReadCardFailed"
              class="pt-3 text-W4 text-[13px] leading-[18px] text-gray400 h-[26px]"
            >
              取得できませんでした。
            </div>
            <div
              v-else-if="isReadCardCompleted"
              class="pt-1 text-W7 text-[22px] leading-none tracking-[0.44px] h-[26px]"
            >
              ￥{{ formattedBalance }}
            </div>
            <!-- SUICAローディング -->
            <BaseLoading v-else class="pt-2" width="20" />
          </div>
        </div>

        <!-- チャージボタン -->
        <div
          class="flex relative w-full justify-center items-center text-W7 text-[15px] leading-[15px] the-button-default px-6 py-4"
          @click="onClickChargeButton()"
        >
          チャージ・利用履歴の確認
          <IconLink class="w-3 h-3 ml-2" />
        </div>
      </div>

      <!-- 連携解除 -->
      <div class="absolute bottom-8 inset-x-0">
        <span
          class="text-W4 text-[13px] leading-[18px] tracking-[0.26px] text-danger300"
          @click="isDisconnectModal = true"
        >
          連携解除
        </span>
      </div>
    </div>
  </div>
  <!-- 連携解除ポップアップ -->
  <Modal
    v-if="isDisconnectModal"
    class="modal"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="pt-9 pb-6 mx-5">
      <div class="text-W5 font-semibold text-[17px] leading-[25.5px] mb-4">
        モバイルSuicaの連携を解除します。よろしいですか？
      </div>
      <div class="flex justify-center mt-6 items-center">
        <div
          class="w-1/2 py-4 the-button-danger text-W7 text-[15px] leading-[15px] mr-3"
          @click="onClickDisconnectButton()"
        >
          連携解除
        </div>
        <div class="w-1/2">
          <TheButton
            text="もどる"
            :isDisabled="false"
            @click-button="isDisconnectModal = false"
          />
        </div>
      </div>
    </div>
  </Modal>
  <!-- 連携解除完了ポップアップ -->
  <Modal
    v-if="isDisconnectCompletedModal"
    class="modal"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="pt-9 pb-6 mx-5">
      <div class="text-W5 font-semibold text-[17px] leading-[25.5px] mb-4">
        連携を解除しました。
      </div>
      <TheButton text="OK" @click-button="backToMenu()" />
    </div>
  </Modal>
  <!-- Suica連携エラー -->
  <Modal
    v-if="suicaCardNotFound"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="center px-5 pt-9 pb-6">
      <div class="text-W5 text-[17px] mb-3">
        連携できるモバイルSuicaがありませんでした。
      </div>
      <div
        class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
        @click="backToMenu()"
      >
        もどる
      </div>
    </div>
  </Modal>
  <!-- ネットワークエラー -->
  <Modal
    v-if="networkError"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="px-5 pt-8 pb-6">
      <div class="text-W5 font-semibold text-[17px] leading-[25.5px]">
        エラーが発生しました。
      </div>
      <div class="mt-3 mx-5 text-W3 text-[15px] leading-[23px]">
        通信環境をご確認いただくか、時間を置いて再度お試しください。
      </div>
      <div
        class="mt-5 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
        @click="networkError = false"
      >
        もどる
      </div>
    </div>
  </Modal>
</template>

<script>
import TheButton from '@/components/atoms/TheButton.vue'
import Header from '@/components/organisms/Header.vue'
import Modal from '@/components/Modal.vue'
import IconLink from '@/components/icons/common/IconLink.vue'
import SuicaUtil from '@/mixins/suicaUtil'
import NativeUtil from '@/mixins/nativeUtil'
import Util from '@/mixins/util'
import suica from '@/utils/suicaNativeUtil'
import log from 'loglevel'
import BaseLoading from '@/components/atoms/BaseLoading.vue'

const SuicaDetail = {
  name: 'SuicaDetail',
  components: {TheButton, Header, Modal, IconLink, BaseLoading},
  mixins: [SuicaUtil, NativeUtil],
  data() {
    return {
      /**
       * 残高
       */
      balance: 0,
      /**
       * 読み込み完了フラグ
       */
      isReadCardCompleted: false,
      /**
       * 読み込み失敗フラグ
       */
      isReadCardFailed: false,
      /**
       * 連携解除モーダル表示フラグ
       */
      isDisconnectModal: false,
      /**
       * 連携解除完了モーダル表示フラグ
       */
      isDisconnectCompletedModal: false,
      /**
       * Suica機能アクセス中フラグ
       */
      isSuicaAccessing: false,
      /**
       * Suica連携カードなしフラグ
       */
      suicaCardNotFound: false,
      /**
       * ネットワークエラーフラグ
       */
      networkError: false,
    }
  },
  computed: {
    /**
     * カンマ区切りされた残高
     */
    formattedBalance() {
      return Util.methods.addCommaForInteger(this.balance)
    },
  },
  async mounted() {
    await this.readCardInfo()
    this.addResumeEvent(this.readCardInfo)
  },
  beforeUnmount() {
    this.removeResumeEvent(this.readCardInfo)
  },
  methods: {
    /**
     * Suicaカード情報を読み込む
     */
    async readCardInfo() {
      try {
        if (this.isReadCardFailed) {
          this.isReadCardCompleted = false
        }
        const cardInfo = await suica.getCardInfo()
        this.balance = cardInfo.balance
        this.isReadCardFailed = false
      } catch (e) {
        // 連携カードがない場合
        if (e.message?.includes('Not found SuicaCardData')) {
          this.suicaCardNotFound = true
        }
        this.isReadCardFailed = true
        log.debug(e)
      } finally {
        this.isReadCardCompleted = true
      }
    },
    /**
     * チャージボタン押下処理
     */
    async onClickChargeButton() {
      // Suicaアプリを開く
      await suica.openSuicaApp()
    },
    /**
     * 連携解除ボタン押下処理
     */
    async onClickDisconnectButton() {
      // 多重クリック防止
      if (this.isSuicaAccessing) return
      try {
        this.$store.commit('startLoading')
        this.isSuicaAccessing = true
        // 連携解除
        await suica.unlinkSuica()
        this.isDisconnectCompletedModal = true
        this.isDisconnectModal = false
      } catch (e) {
        // 閉局中エラーの場合
        if (
          e?.response?.data?.statusCode ===
          this.$config.API_STATUS_CODE_SERVICE_UNAVAILABLE
        ) {
          throw e
        } else {
          // エラーメッセージ表示
          this.networkError = true
        }
      } finally {
        this.$store.commit('endLoading')
        this.isSuicaAccessing = false
      }
    },
    /**
     * 連携解除完了OKボタン押下処理
     */
    async backToMenu() {
      this.$router.push({name: this.$config.DISPLAY_MENU_TOP})
    },
  },
}
export default SuicaDetail
</script>
<style scoped></style>
