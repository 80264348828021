<template>
  <BasePanel
    :spaceHeight="maxTopSpace"
    :isOverflowYAuto="getIsOverflowYAuto"
    :isPanelIcon="true"
    :isShowFooter="true"
    :contentId="contentId"
    :footerBgColor="footerBgColor"
    :style="setPosition"
    class="transition-all duration-[500ms]"
    ref="scrollArea"
  >
    <template v-slot:header><slot name="header" /></template>
    <template v-slot:body><slot name="body" /></template>
  </BasePanel>
</template>
<script>
/**
 * スクロールパネル
 */
import BasePanel from '@/components/atoms/BasePanel.vue'
import deepcopy from 'deepcopy'
import Util from '@/mixins/util'

// パネルモード
const PANEL_MODE = {
  MAX: 1,
  MIDDLE: 2,
  MIN: 3,
}

// 各モードの余白値の初期値
const DEFAULT_PANEL_SPACE = {
  MAX: 40,
  MIDDLE: 200,
  MIN: 300,
}

const ScrollPanel = {
  name: 'ScrollPanel',
  mixins: [Util],
  components: {BasePanel},
  props: {
    /**
     * 初期表示時のパネルモード
     * （デフォルトは中間）
     */
    defaultPanelMode: {
      type: Number,
      require: false,
      default: PANEL_MODE.MIDDLE,
      validator(val) {
        return [PANEL_MODE.MAX, PANEL_MODE.MIDDLE, PANEL_MODE.MIN].includes(val)
      },
    },
    /**
     * 最大表示時の余白高(px)
     */
    maxTopSpace: {
      type: [Number, String],
      default: DEFAULT_PANEL_SPACE.MAX,
    },
    /**
     * 中間表示時の余白高(px)
     */
    middleTopSpace: {
      type: [Number, String],
      default: DEFAULT_PANEL_SPACE.MIDDLE,
    },
    /**
     * 最小表示時の余白高(px)
     */
    minTopSpace: {
      type: [Number, String],
      default: DEFAULT_PANEL_SPACE.MIN,
    },
    /**
     * ボディ部のID
     */
    contentId: {
      type: String,
      default: '',
    },
    /**
     * フッターエリア背景色
     */
    footerBgColor: {
      type: String,
      default: 'bg-white',
    },
  },
  emits: ['updatePanelMode'],
  mounted() {
    // パネル昇降イベント付与
    this.setSwipeEvent()
  },
  beforeUnmount() {
    // パネル昇降イベント削除
    this.removeSwipeEvent()
  },
  data() {
    return {
      startPos: {}, // タップ開始位置
      movingPos: {}, // タップ中位置
      panelMode: this.defaultPanelMode, // パネルモード
    }
  },
  computed: {
    /**
     * パネルの昇降位置設定
     */
    setPosition() {
      let topVal
      switch (this.panelMode) {
        case this.$config.PANEL_MODE.MAX:
          topVal = this.maxTopSpace
          break
        case this.$config.PANEL_MODE.MIDDLE:
          topVal = this.middleTopSpace
          break
        case this.$config.PANEL_MODE.MIN:
          topVal = this.minTopSpace
          break
        default:
          // eslint-disable-next-line no-magic-numbers
          topVal = 0
          break
      }
      if (typeof topVal === 'number') {
        topVal += 'px'
      }
      return {
        top: topVal,
      }
    },
    /**
     * 垂直スクロール指定
     * （最大時のみ可）
     */
    getIsOverflowYAuto() {
      switch (this.panelMode) {
        case this.$config.PANEL_MODE.MAX:
          return true
        case this.$config.PANEL_MODE.MIDDLE:
        case this.$config.PANEL_MODE.MIN:
        default:
          return false
      }
    },
  },
  methods: {
    /**
     * パネル昇降イベント付与
     */
    setSwipeEvent() {
      const vm = this
      const scrollAreaRef = this.$refs.scrollArea

      // ヘッダー部のイベント追加
      const scrollHeaderRef = scrollAreaRef.$refs.header
      scrollHeaderRef.addEventListener('touchstart', (e) => {
        vm.headerTouchStart(e)
      })
      scrollHeaderRef.addEventListener('touchmove', (e) => {
        vm.headerTouchMove(e)
      })
      scrollHeaderRef.addEventListener('touchend', (e) => {
        vm.headerTouchEnd(e)
      })

      // ボディ部のイベント追加
      const scrollBodyRef = scrollAreaRef.$refs.body
      scrollBodyRef.addEventListener('touchstart', (e) => {
        vm.touchStart(e)
      })
      scrollBodyRef.addEventListener('touchmove', (e) => {
        vm.touchMove(e)
      })
      scrollBodyRef.addEventListener('touchend', (e) => {
        vm.touchEnd(e)
      })
    },
    /**
     * パネル昇降イベント削除
     */
    removeSwipeEvent() {
      const vm = this
      const scrollAreaRef = this.$refs.scrollArea

      // ヘッダー部のイベント削除
      const scrollHeaderRef = scrollAreaRef.$refs.header
      scrollHeaderRef.removeEventListener('touchstart', (e) => {
        vm.headerTouchStart(e)
      })
      scrollHeaderRef.removeEventListener('touchmove', (e) => {
        vm.headerTouchMove(e)
      })
      scrollHeaderRef.removeEventListener('touchend', (e) => {
        vm.headerTouchEnd(e)
      })

      // ボディ部のイベント削除
      const scrollBodyRef = scrollAreaRef.$refs.body
      scrollBodyRef.removeEventListener('touchstart', (e) => {
        vm.touchStart(e)
      })
      scrollBodyRef.removeEventListener('touchmove', (e) => {
        vm.touchMove(e)
      })
      scrollBodyRef.removeEventListener('touchend', (e) => {
        vm.touchEnd(e)
      })
    },
    /**
     * スワイプ開始処理
     */
    touchStart(e) {
      // スワイプ開始位置を取得
      this.startPos = {
        x: e.touches[this.$config.ARRAY_INDEX_ZERO].pageX,
        y: e.touches[this.$config.ARRAY_INDEX_ZERO].pageY,
      }
    },
    /**
     * スワイプ中処理
     */
    touchMove(e) {
      e.preventDefault(this.startPos)
      /**
       * スワイプ量の算出
       */
      const prevMovingPos = deepcopy(this.movingPos)
      const prevPos = this.isEmpty(prevMovingPos)
        ? deepcopy(this.startPos)
        : prevMovingPos

      const newPos = {
        x: e.changedTouches[this.$config.ARRAY_INDEX_ZERO].pageX,
        y: e.changedTouches[this.$config.ARRAY_INDEX_ZERO].pageY,
      }

      // スワイプ量に応じて要素をスクロール
      const diffY = prevPos.y - newPos.y
      let scrollBodyRef = this.$refs.scrollArea.$refs.body
      scrollBodyRef.scrollTop = scrollBodyRef.scrollTop + diffY
      // 移動後の位置を保持
      this.movingPos = newPos
    },
    /**
     * スワイプ終了処理
     */
    touchEnd() {
      if (this.compareSwipe(this.$config.SWIPE_ACTION_UP)) {
        // 上スワイプ
        if (this.isCanScroll(this.$config.SWIPE_ACTION_UP)) {
          this.swipe(this.$config.SWIPE_ACTION_UP)
        }
      } else if (this.compareSwipe(this.$config.SWIPE_ACTION_DOWN)) {
        // 下スワイプ
        if (this.isCanScroll(this.$config.SWIPE_ACTION_DOWN)) {
          this.swipe(this.$config.SWIPE_ACTION_DOWN)
        }
      }

      // スワイプ終了
      this.movingPos = {}
    },
    /**
     * ヘッダー部分のスワイプ開始処理
     */
    headerTouchStart(e) {
      // スワイプ開始位置を取得
      this.startPos = {
        x: e.touches[this.$config.ARRAY_INDEX_ZERO].pageX,
        y: e.touches[this.$config.ARRAY_INDEX_ZERO].pageY,
      }
    },
    /**
     * ヘッダー部分のスワイプ中処理
     */
    headerTouchMove(e) {
      e.preventDefault(this.startPos)
      // 移動後の位置を保持
      this.movingPos = {
        x: e.changedTouches[this.$config.ARRAY_INDEX_ZERO].pageX,
        y: e.changedTouches[this.$config.ARRAY_INDEX_ZERO].pageY,
      }
    },
    /**
     * ヘッダー部分のスワイプ終了処理
     */
    headerTouchEnd() {
      if (this.compareSwipe(this.$config.SWIPE_ACTION_UP)) {
        // 上スワイプ
        this.swipe(this.$config.SWIPE_ACTION_UP)
      } else if (this.compareSwipe(this.$config.SWIPE_ACTION_DOWN)) {
        // 下スワイプ
        this.swipe(this.$config.SWIPE_ACTION_DOWN)
      }
    },
    /**
     * スワイプしたかどうか判定
     * @param {string} mode up/down
     * @return {boolean} true：有効、false：無効
     */
    compareSwipe(mode) {
      const upDistance = this.$config.PANEL_SWIPE_UP_MOVED_DISTANCE
      const downDistance = this.$config.PANEL_SWIPE_DOWN_MOVED_DISTANCE
      const diffH = this.movingPos.y - this.startPos.y

      return mode == this.$config.SWIPE_ACTION_UP
        ? diffH < upDistance
        : downDistance < diffH
    },
    /**
     * 上下にスクロールできるかどうかを判定
     * @param {String} type スクロール方向
     * @return スクロール可否
     */
    isCanScroll(type) {
      // スクロール位置
      const scrollAreaRef = this.$refs.scrollArea
      const scrollBodyRef = scrollAreaRef.$refs.body
      const scrollTop = scrollBodyRef.scrollTop
      return type == this.$config.SWIPE_ACTION_UP ? true : scrollTop == 0 // eslint-disable-line no-magic-numbers
    },
    /**
     * パネルの昇降位置切り替え
     * [上スワイプ時]
     * ・最大→最大
     * ・中間→最大
     * ・最小→中間
     * [下スワイプ時]
     * ・最大→中間
     * ・中間→最小
     * ・最小→最小
     * @param {String} value 昇降方向
     */
    swipe(value) {
      if (this.panelMode == this.$config.PANEL_MODE.MAX) {
        // 現在の表示が最大の場合
        this.panelMode =
          value == this.$config.SWIPE_ACTION_UP
            ? this.panelMode // 上スワイプ時は変化なし
            : this.$config.PANEL_MODE.MIDDLE // 下スワイプ時は中間に更新
      } else if (this.panelMode == this.$config.PANEL_MODE.MIDDLE) {
        // 現在の表示が中間の場合
        this.panelMode =
          value == this.$config.SWIPE_ACTION_UP
            ? this.$config.PANEL_MODE.MAX // 上スワイプ時は最大に更新
            : this.$config.PANEL_MODE.MIN // 下スワイプ時は最小に更新
      } else if (this.panelMode == this.$config.PANEL_MODE.MIN) {
        // 現在の表示が最小の場合
        this.panelMode =
          value == this.$config.SWIPE_ACTION_UP
            ? this.$config.PANEL_MODE.MIDDLE // 上スワイプ時は中間に更新
            : this.panelMode // 下スワイプ時は変化なし
      }

      this.$emit('updatePanelMode', this.panelMode)
    },
  },
}
export default ScrollPanel
</script>
<style scoped></style>
