<template>
  <div class="relative h-full">
    <div id="port-map" ref="map" class="w-full h-full"></div>
    <div
      v-if="isNull(frozen.mapRelated.showingInfoWindow)"
      class="select-port p-3 text-W4 text-[13px] leading-[13px] text-white bg-black mt-safe-area"
    >
      ポートを選択してください
    </div>
    <!-- ポート詳細情報 -->
    <div
      class="absolute bottom-[33px] left-4 flex justify-center items-center bg-white px-4 py-3 shadow-normal rounded-[30px] mb-safe-area"
      @click="$emit('click-research')"
    >
      <img class="h-4 w-4" src="@/assets/Icon_Route_Glasses.svg" />
      <div class="ml-1 mt-[1px] text-W5 text-[15px] leading-[15px]">
        このエリアを検索
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/mixins/util.js'
import CanvasUtil from '@/mixins/canvasUtil.js'
import arrangementUtil from '@/mixins/arrangementUtil.js'

// 地図上に表示するアイコン画像サイズ
const PARK_ICON_SIZE = {
  height: 46,
  width: 36,
}

// 現在地アイコンのサイズ
const CURRENT_LOCATION_ICON_SIZE = {
  height: 36,
  width: 36,
}
// 吹き出しの表示位置調整用パラメータ
const ADJUSTMENT_POSITION = {
  X: 0,
  Y: -34,
}

// コピーライト下の余白
const COPYRIGHT_UNDER_SPACE = 4

/**
 * ポート一覧表示用マップコンポーネント
 */
const PortMap = {
  name: 'PortMap',
  mixins: [Util, CanvasUtil, arrangementUtil],
  emits: ['click-research', 'click-card', 'change-center-zoomlevel'],
  props: {
    /**
     * ポート一覧
     */
    portList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // VueのReactivityによる値変更検知をさせないため、オブジェクトを凍結
      frozen: Object.freeze({
        mapRelated: {
          map: null,
          addGlMarkerList: [], // マップアイコンリスト
          selectedGlMarker: null, // 選択したマップアイコン
          showingInfoList: [], // 吹き出しオブジェクト一覧
          showingInfoWindow: null,
        },
      }),
      isFirstLocationData: true, //初回の位置情報データかどうか
      userLocationData: null, // 自位置情報クラスのインスタンス
      selectedParkId: null, // 選択パークID
    }
  },
  mounted() {
    this.getMapScript()
  },
  watch: {
    /**
     * 現在地が変更された場合、マップの現在地を更新
     */
    currentPosition: {
      deep: true,
      handler() {
        this.updateCurrentPosition()
      },
    },
    /**
     * ポート一覧が更新された場合、ポート一覧表示を更新
     */
    portList() {
      if (!this.isExistsMap()) {
        return
      }
      this.reloadPortIcon()
    },
  },
  computed: {
    /**
     * 現在地
     */
    currentPosition() {
      return this.$store.state.currentPosition
    },
    /**
     * ポート情報カードの横幅を算出し返却
     */
    getParkInfoCardWidth() {
      // 画面の横幅
      const clientWidth = document.documentElement.clientWidth
      // マップ外部の余白
      const mapMarginX = 40
      // マップ内部の余白
      const mapPaddingX = 24
      return clientWidth - mapMarginX - mapPaddingX
    },
    /**
     * 選択ポート
     */
    targetParkInfo() {
      if (!this.frozen.mapRelated.selectedGlMarker) {
        return
      }
      return this.portList.find(
        (port) => port.parkId === this.frozen.mapRelated.selectedGlMarker.name
      )
    },
  },
  methods: {
    /**
     * マップスクリプト取得処理
     */
    getMapScript() {
      let vm = this
      // 地図情報を取得する
      const success = (data) => {
        // 取得成功時、スクリプトに反映およびアイコン表示位置に地図移動
        vm.addMapScript(window.atob(data))
        vm.updateCurrentPosition()
        vm.$store.commit('endLoading')
      }
      vm.$store.commit('startLoading')
      vm.$nextTick(function () {
        // 地図情報取得処理
        vm.$store.dispatch('getMapScript', {
          success,
        })
      })
    },
    /**
     * マップスクリプト追加
     */
    addMapScript(base64Script) {
      if (this.isNull(document.getElementById('port-map'))) {
        return
      }
      let script = document.createElement('script')
      // スクリプトの加工・DOM追加
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('id', 'portmapscript')
      script.text = base64Script
      document.head.appendChild(script)
      this.init()
    },
    /* eslint-disable no-undef */
    /**
     * マップ情報の初期設定
     */
    init() {
      // コピーライトおよび縮尺の基準位置
      const basePoint = COPYRIGHT_UNDER_SPACE
      const copyrightBottom = basePoint + this.$store.state.bottomSafeAreaHeight

      // ストアに位置情報がない場合は位置情報を取得
      this.frozen.mapRelated.map = new mapscript.Map(
        process.env.VUE_APP_NAVITIME_CLIENT_ID,
        {
          target: '#port-map',
          // 初期値は東京駅
          center: new mapscript.value.LatLng(
            this.currentPosition.lat ||
              this.$config.DEFAULT_CURRENT_POSITION.LAT,
            this.currentPosition.lon ||
              this.$config.DEFAULT_CURRENT_POSITION.LON
          ),
          // 縮尺設定
          zoomLevel: this.$config.MAP_ZOOMLEVEL.ZOOM_200_M,
          options: {
            copyright: {
              // ゼンリンの表示位置指定
              offset: new mapscript.value.Point(basePoint, copyrightBottom),
            },
            scale: {
              // 縮尺の表示位置指定
              offset: new mapscript.value.Point(basePoint, copyrightBottom),
            },
            // タイルの3Dビル表示設定を無効にする
            tileBuilding3D: false,
          },
        }
      )
      if (!this.isExistsMap()) {
        return
      }
      // ZoomLevelに変更がある場合は、変更後の数値を親に通知
      this.frozen.mapRelated.map.addEventListener(
        'centermoved',
        this.emitMapCenterCoord
      )
      // ポートアイコン配置
      this.reloadPortIcon()
    },
    /**
     * zoomLevelと座標を親に通知する
     */
    emitMapCenterCoord(value) {
      const mapInfo = {
        coord: {
          lat: value.center.lat,
          lon: value.center.lng,
        },
        zoomLevel: value.zoom,
      }
      this.$emit('change-center-zoomlevel', mapInfo)
    },
    /**
     * 現在地表示の更新処理
     */
    updateCurrentPosition() {
      // インスタンス生成されていない場合はスキップ
      if (!this.isExistsMap()) {
        return
      }

      // storeの緯度経度がnullの場合はスキップ
      if (!this.currentPosition.lat || !this.currentPosition.lon) {
        return
      }

      // storeの現在地を取得
      const updateLatLon = new mapscript.value.LatLng(
        this.currentPosition.lat,
        this.currentPosition.lon
      )

      // ユーザーアイコンのインスタンスを作成・更新
      if (this.userLocationData == null) {
        // 初回はインスタンスを生成
        this.userLocationData = new mapscript.value.UserLocationData(
          updateLatLon, // 緯度軽度
          this.$config.ZERO // 方角
        )
      } else {
        // 作成済みの場合は緯度軽度を更新
        this.userLocationData.setLatlng(updateLatLon)
      }

      if (this.isFirstLocationData) {
        // 初回 位置情報更新フラグを落とす
        this.isFirstLocationData = false
        // 現在地アイコン読み込み
        const currentLocation = require('@/assets/IconCurrentLocation.svg')

        const info = new mapscript.value.GLMarkerIconInfo({
          icon: currentLocation, // 画像パス(base64文字列も可)
          size: CURRENT_LOCATION_ICON_SIZE, // 画像サイズ(指定なければデフォルト値が設定される)
        })

        // マップにユーザアイコンを配置
        this.frozen.mapRelated.map.setUserLocation(
          new mapscript.object.UserLocation({info: info})
        )
        // ユーザの位置情報を設定（アニメーションなし）
        this.frozen.mapRelated.map.setUserLocationData(
          this.userLocationData,
          false
        )
      } else {
        // ２回目以降 位置情報更新
        // ユーザの位置情報を設定（アニメーションあり）
        this.frozen.mapRelated.map.setUserLocationData(
          this.userLocationData,
          true
        )
      }
    },
    /**
     * ポートアイコンを再描画
     */
    async reloadPortIcon() {
      // 現在表示しているアイコンを削除
      this.removeAllParkIcon()
      // 保持している吹き出しオブジェクトを破棄
      this.removeAllShowingInfo()
      // 選択中のポートIDおよび吹き出しを解除
      this.selectedParkId = null
      this.frozen.mapRelated.showingInfoWindow = null

      // 表示対象のポート情報が存在しない場合、何も行わない
      const emptyArrLength = 0
      if (this.portList.length === emptyArrLength) {
        return
      }

      // アイコン表示に用いる情報を生成
      const portIconList = []
      // ポートアイコンのキャッシュ
      const portIconCache = {}

      for (const portInfo of this.portList) {
        const svg = await this.generateParkIconSvg(
          portInfo.cycleNum,
          portIconCache
        )
        const iconInfo = {
          base64Script: svg,
          lat: portInfo.lat,
          lon: portInfo.lon,
          name: portInfo.parkId,
        }
        portIconList.push(iconInfo)
      }

      // アイコン配置
      this.setPortIconList(portIconList)

      // 初回ポート一覧情報フラグをfalseにする
      this.isFirstPortList = false
    },
    /**
     * ポートアイコン一覧を描画
     */
    setPortIconList(portIconList) {
      if (!this.isExistsMap()) {
        return
      }
      for (let icon of portIconList) {
        const glMarker = this.makeMapGLMarker(
          icon.lat,
          icon.lon,
          icon.base64Script,
          icon.name,
          PARK_ICON_SIZE
        )
        this.frozen.mapRelated.addGlMarkerList.push(glMarker)
      }
      this.frozen.mapRelated.map.addGLMarkers(
        this.frozen.mapRelated.addGlMarkerList
      )
    },
    /**
     * GLMarkerを生成する
     * @param {Number} latitude 緯度
     * @param {Number} longitude 軽度
     * @param {String} icon 配置したいアイコン
     * @param {String} name アイコンにつける名前
     * @param {Object} size 縦横サイズ
     * @returns GLMarker
     */
    makeMapGLMarker(latitude, longitude, icon, name, size) {
      const position = new mapscript.value.LatLng(latitude, longitude)

      const info = new mapscript.value.GLMarkerIconInfo({
        icon: icon, // 画像(base64文字列)
        size: size, // 画像サイズ(指定なければデフォルト値が設定される)
      })

      const glMarker = new mapscript.object.GLMarker({
        position: position, // 表示する緯度経度
        info: info,
        name: name,
      })

      // 押下イベント付与
      glMarker.addEventListener('click', this.clickPortIcon)

      return glMarker
    },
    /**
     * ポートアイコン押下時のクリック処理
     */
    clickPortIcon(event) {
      const selectGlMarker = event.sourceObject

      // 選択されたポートID取得
      this.selectedParkId = selectGlMarker.name

      // 選択した地点にマップを移動
      const selectedLatLon = selectGlMarker.getPosition()
      this.frozen.mapRelated.map.setCenter(selectedLatLon, true)

      // 選択した地点に詳細カードを表示
      this.showInfoWindow(selectedLatLon)
    },
    /**
     * ポート情報の吹き出しを表示
     * @param {LatLon} LatLonValue mapscriptの緯度軽度オブジェクト
     */
    showInfoWindow(latLonValue) {
      // 今表示しているポート情報があれば、非表示にする
      if (this.frozen.mapRelated.showingInfoWindow != null) {
        this.frozen.mapRelated.showingInfoWindow.close()
      }

      // タップされた駐輪場情報を設定
      const selectedPort = this.portList.find(
        (port) => port.parkId === this.selectedParkId
      )

      if (this.isNull(selectedPort)) {
        // 駐輪場情報がない場合は吹き出し表示せず終了する
        return
      }

      // 営業時間の設定
      const selectedPortBusinessTime = {
        start: selectedPort.startTime,
        end: selectedPort.endTime,
      }

      // 表示するDom要素を生成
      const clickFunc = () => this.$emit('click-card', selectedPort) // クリックイベントの発火処理
      const content = this.generateDetailWindow(
        selectedPort.imageUrl,
        selectedPort.parkId,
        selectedPort.parkName,
        selectedPortBusinessTime,
        selectedPort.parkStatus,
        clickFunc
      )

      // 既に表示したことある場合は、表示にする
      const findShowingInfo = this.frozen.mapRelated.showingInfoList.find(
        (record) => {
          return record.parkId == this.selectedParkId
        }
      )
      if (!this.isNull(findShowingInfo)) {
        this.resetWindowContents(this.selectedParkId, content)
        this.frozen.mapRelated.showingInfoWindow = findShowingInfo.infoWindow
        this.frozen.mapRelated.showingInfoWindow.open()
        return
      }

      //ずらし幅の設定
      const point = new mapscript.value.Point(
        ADJUSTMENT_POSITION.X,
        ADJUSTMENT_POSITION.Y
      )
      // 吹き出しオブジェクトの生成
      this.frozen.mapRelated.showingInfoWindow =
        new mapscript.object.InfoWindow({
          content: content, // 吹き出しの中に表示したい内容
          position: latLonValue, // 吹き出しを表示する緯度経度
          showClose: false, // 標準の閉じるアイコンを非表示
          offset: point, // 表示位置のずらし幅
        })

      // 吹き出しをマップに描画
      this.frozen.mapRelated.map.addInfoWindow(
        this.frozen.mapRelated.showingInfoWindow
      )

      // 吹き出しオブジェクト一覧に追加
      const holdShowingInfo = {
        parkId: this.selectedParkId,
        infoWindow: this.frozen.mapRelated.showingInfoWindow,
      }
      this.frozen.mapRelated.showingInfoList.push(holdShowingInfo)

      // 吹き出しのレイアウトを調整
      adjustInfoWindow()
    },
    /**
     * 処理実行前にマップが非表示となることがあるため
     * PortMapScriptが存在するか確認
     */
    isExistsMap() {
      return (
        !this.isNull(document.getElementById('portmapscript')) &&
        !this.isNull(this.frozen.mapRelated.map?.mapController)
      )
    },
    /**
     * 指定しているポートアイコンを全て削除
     */
    removeAllParkIcon() {
      if (this.$config.ZERO < this.frozen.mapRelated.addGlMarkerList.length) {
        // ポートアイコン
        this.frozen.mapRelated.map.removeGLMarkers(
          this.frozen.mapRelated.addGlMarkerList
        )
        this.frozen.mapRelated.addGlMarkerList = []
      }
      if (this.frozen.mapRelated.selectedGlMarker != null) {
        // 選択中アイコン
        this.frozen.mapRelated.map.removeGLMarker(
          this.frozen.mapRelated.selectedGlMarker
        )
        this.frozen.mapRelated.selectedGlMarker = null
      }
    },
    /**
     * 保持している吹き出しオブジェクトを全て破棄
     */
    removeAllShowingInfo() {
      for (const showingInfo of this.frozen.mapRelated.showingInfoList) {
        this.frozen.mapRelated.map.removeInfoWindow(showingInfo.infoWindow)
      }
      this.frozen.mapRelated.showingInfoList = []
    },
    /**
     * ポートアイコンのSVGを生成
     * @param {Number} cycleNum 表示する数字
     * @param {Object} portIconCache キャッシュ
     * @returns ポートアイコンのSVG
     */
    async generateParkIconSvg(cycleNum, portIconCache) {
      // 生成済であればそれを返す
      if (portIconCache[cycleNum]) {
        return portIconCache[cycleNum]
      }
      const iconPort = require('@/assets/bicycle/IconPort.svg')
      const borderColor = cycleNum ? '#009ce5' : '#979ca1'
      const svgSrc = await fetch(iconPort).then((r) => r.text())
      const svg = svgSrc
        .replace('$$borderColor', borderColor)
        .replace('$$value', cycleNum)
        .replace(/^\s+/, '')
        .replace(/\n/, '')
      const encodedSrc = encodeURIComponent(svg)
      portIconCache[cycleNum] = `data:image/svg+xml;charset=utf8,${encodedSrc}`
      return portIconCache[cycleNum]
    },
  },
  beforeUnmount() {
    if (!this.isExistsMap()) {
      return
    }
    let mapElement = document.getElementById('portmapscript')
    // ヘッダーからマップスクリプトを削除する
    document.head.removeChild(mapElement)

    // マップ破棄処理
    this.frozen.mapRelated.map.destroy()
    // MapScriptのStyle要素を削除
    const mapStyleEl = document.getElementById('gaia-stylesheet')
    mapStyleEl.remove()
  },
}
export default PortMap
/**
 * 吹き出しのDOM要素を調整
 */
function adjustInfoWindow() {
  const targetWindow = document
    .getElementById('port-map')
    .querySelector('.gia-dom-layer')
    .querySelector('.gia-object-infowindow')
  targetWindow.classList.remove('gia-object-infowindow')
  targetWindow.style.height = null
}
</script>
<style scoped>
/* ゼンリン・縮尺のレイアウト */
#port-map ::v-deep(.gia-parts-scale) {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
  line-height: 11px;
  max-width: 100vw - 270px;
  padding: 2px 0;
}
#port-map ::v-deep(.gia-parts-copyright-text) {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
  line-height: 11px;
  padding: 2px 0;
}
/* 「ポートを選択してください」のスタイル */
.select-port {
  position: absolute;
  /* 文字の中央からの調整になるため、6.5+12+16=34.5pxに設定 */
  top: 34.5px;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  opacity: 0.7;
  border-radius: 4px;
}
</style>
