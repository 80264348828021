<template>
  <!-- お気に入り地点カード -->
  <!-- 大枠 -->
  <div
    id="FavoriteSpotCard"
    class="flex w-full pl-4 pr-2 py-4 bg-white rounded-[10px] h-16"
    @click="touchAction()"
  >
    <!-- 種別アイコン -->
    <img class="mr-4 w-8 h-8" :src="getIcon()" />
    <!-- 各種表示 -->
    <div class="flex-1 self-center mt-1 w-10">
      <div
        class="text-left text-W4 text-[14px] leading-[19px] truncate"
        :class="showNameClass"
      >
        <!-- 名称 -->
        {{ spotName }}
      </div>
      <div
        class="text-left text-W3 text-[13px] leading-[16.9px] text-gray400 truncate"
        :class="addMt"
      >
        <!-- 住所 -->
        {{ address }}
      </div>
    </div>
    <!-- 右矢印アイコン -->
    <div class="flex items-center ml-4">
      <img class="w-3 h-3" src="@/assets/Icon_Right_gray.svg" />
    </div>
  </div>
</template>

<script>
const FavoriteSpotCard = {
  name: 'FavoriteSpotCard',
  props: {
    // 表示アイコン
    icon: {
      type: String,
      require: true,
    },
    // スポット名
    spotName: {
      type: String,
      require: false,
    },
    // 住所
    address: {
      type: String,
      require: false,
    },
    // 表示名のクラス属性（「未登録」や「追加する」等の場合、文字を青色にする）
    showNameClass: {
      type: String,
      require: false,
    },
  },
  data() {
    return {}
  },
  emits: ['touchAction'],
  computed: {
    /**
     * 住所の表示位置調整用クラス属性設定処理
     */
    addMt() {
      // スポット名がある場合、住所との間のマージンを設定する
      return this.spotName ? 'mt-1' : ''
    },
  },
  methods: {
    /**
     * 種類別アイコンの取得
     */
    getIcon() {
      return require(`@/assets/${this.icon}`)
    },
    /**
     * カードクリック処理
     */
    touchAction() {
      this.$emit('touchAction')
    },
  },
}
export default FavoriteSpotCard
</script>

<style></style>
