<template>
  <div class="relative">
    <!-- マップ -->
    <div id="route-map" ref="map" class="w-full h-screen" />
    <!-- ボタン(検索欄上部) -->
    <div
      class="absolute right-5"
      :class="isShowPaymentFailedMessage ? 'top-16' : 'top-4'"
    >
      <div class="flex flex-col">
        <!-- コンパス/Nアイコン -->
        <div
          class="circle-back-current-position-button active:bg-blue200"
          @click="changeMapMode()"
        >
          <img
            class="mx-auto"
            :class="isCompassIcon ? 'h-4 w-4' : 'h-5 w-5'"
            :src="iconPath"
          />
        </div>
        <!-- 現在地アイコン -->
        <transition name="currentSpot">
          <div
            v-show="isShowGoBuckCurrentSpot"
            class="circle-back-current-position-button mt-4"
            @click="currentSpotFit()"
          >
            <img class="h-4 w-4 mx-auto" src="@/assets/current_location.svg" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * タイル地図の表示および制御するコンポーネント
 * ＜概要＞
 * Navitime APIで取得したタイル地図情報の表示および位置情報の制御を行う
 * 位置情報は一定間隔で取得する
 */
import Util from '@/mixins/util.js'
import canvasUtil from '@/mixins/canvasUtil'
import nativeUtil from '@/mixins/nativeUtil'
import log from 'loglevel'
import * as mobilityUtil from '@/utils/mobilityUtil'

// マップのズームレンジ最小値
const MAP_ZOOM_RANGE_MIN = 6
// マップのズームレンジ最大値
const MAP_ZOOM_RANGE_MAX = 20
// 緯度経度のインデックス番号
const LAT_INDEX = 0
const LON_INDEX = 1
// 向いている方向画像のサイズ
const DIRECTION_SIZE = {width: 128, height: 128}
// 出発・経由地に配置する画像サイズ
const SPOT_WHITE_CIRCLE_SIZE = {
  height: 15,
  width: 15,
}
// 到着地に配置する画像サイズ
const ICON_MAP_PLACE_SIZE = {
  height: 44,
  width: 44,
}
// 中継地に配置する画像サイズ
const ICON_MAP_RELAY_SIZE = {
  height: 40,
  width: 36,
}
// トラッキングモード
const TRACKING_MODE = {
  NONE: 'none',
  FOLLOW: 'follow',
  HEADING_UP: 'heading_up',
}
const RouteMap = {
  name: 'RouteMap',
  props: {},
  mixins: [Util],
  data() {
    return {
      // VueのReactivityによる値変更検知をさせないため、オブジェクトを凍結
      frozen: Object.freeze({
        mapRelated: {
          map: null,
          addGlMarkerList: [], // 追加表示アイコン情報
          searchSpotsGlMarkers: this.$store.state.searchSpotsGlMarkers,
          userIconMarker: null, // ユーザーアイコンマーカー
        },
      }),
      isFirstCurrentSpotInfo: false,
      // 下記を凍結した場合、ルート描画の挙動に問題が発生する。(ルート線の色)
      // 凍結せずとも挙動に問題ないため凍結しない。
      figure: null, // ルート形状情報
      isFirstLocationData: true, //初回の位置情報データかどうか
      userLocationData: null, // 自位置情報クラスのインスタンス
      routeScript: null, // 表示中の経路情報
      timeoutId: null,
      headingIconInfo: null, // 方向アイコン
      heading: null, // 退避用の方角(ヘディングアップモードに切り替える際、即座に反映させるために定義)
      isCurrentSpotCenter: true, // 現在地中心かどうか
      currentMapMode: '', // 現在のマップモード
      // マップ移動中判定用変数
      judgeMapMoving: {
        lat: '',
        lon: '',
        isMoving: false,
      },
      stopMap: new CustomEvent(this.$config.CUSTOM_EVENT_HANDLER.STOP_MAP),
      movingMap: new CustomEvent(this.$config.CUSTOM_EVENT_HANDLER.MOVING_MAP),
      longPressMap: new CustomEvent(
        this.$config.CUSTOM_EVENT_HANDLER.LONG_PRESS_MAP
      ),
    }
  },
  mounted() {
    this.loadMap()
    window.addEventListener('heading-change', this.onHeadingChange)
  },
  beforeUnmount() {
    this.unmountProcess()
    window.removeEventListener('heading-change', this.onHeadingChange)
  },
  computed: {
    /**
     * 現在地に戻るアイコンを表示するかどうか
     * 下記条件を満たす場合に、アイコンを表示する
     * ・現在地が取得済みであること（現在地アイコンが生成されていること）
     * ・現在地がマップの中心で"ない"こと
     */
    isShowGoBuckCurrentSpot() {
      return !this.isCurrentSpotCenter && this.userLocationData
    },
    /**
     * 未決済時のエラーメッセージ表示状態
     * @returns 表示状態
     */
    isShowPaymentFailedMessage() {
      // 決済が失敗状態であるかどうか
      const isPaymentFailing = mobilityUtil.determiningPaymentFailing(
        this.$store.state.CreditCardsStore.paymentStatus
      )
      // 「予約中」または「利用中」のモビリティがあるかどうか
      const usingMobilityType =
        this.$store.getters[
          'MobilityReservationStore/reservedOrUsingOfMobilityType'
        ]
      const isReservedOrUsingOfMobility =
        usingMobilityType !== this.$config.USING_MOBILITY_TYPE.NONE
      return !isReservedOrUsingOfMobility && isPaymentFailing
    },
    /**
     * コンパスアイコン表示対象かどうかを判定
     * @returns 表示有無
     */
    isCompassIcon() {
      switch (this.currentMapMode) {
        case this.$config.MAP_MODE.HEADING_UP:
        case this.$config.MAP_MODE.OPERATING:
          return true
        case this.$config.MAP_MODE.NORTH_UP:
        default:
          return false
      }
    },
    /**
     * ノースアップアイコン/コンパスアイコンの切り替え
     * @returns アイコン
     */
    iconPath() {
      return this.isCompassIcon
        ? require('@/assets/map/Compass.svg')
        : require('@/assets/map/NorthUp.svg')
    },
    /**
     * 入力中フラグ更新
     */
    updateInputFlag() {
      return this.$store.state.inputFlag
    },
    /**
     * ルート形状スクリプト取得
     */
    drawRouteScript() {
      return this.$store.state.drawRouteScript
    },
    /**
     * ストアに保持しているユーザアイコン
     */
    storeUserIcon() {
      return this.$store.state.user.appInfo.icon
    },
    /**
     * ユーザーアイコンサイズ
     */
    userIconSize() {
      return this.$store.state.user.appInfo.userIconSize
    },
    /**
     * ユーザーの現在地情報
     */
    currentPosition() {
      return this.$store.state.currentPosition
    },
    /**
     * フッター表示状態を取得
     */
    isShowFooter() {
      return this.$store.state.isShowFooter && !this.$store.state.isShowKeyboard
    },
  },
  watch: {
    /**
     * 入力中フラグ更新時処理
     */
    updateInputFlag: function (value) {
      // 入力中(true)はzoomRange無効化する
      // 入力終了時(false)はzoomRange有効化する
      this.setLockZoomRange(value)
    },
    /**
     * ルート形状スクリプトの変更を検知
     */
    drawRouteScript: function (routeScript) {
      // マップオブジェクトが生成されていない場合は何もしない
      if (!this.isExistsMap()) {
        return
      }

      this.routeScript = routeScript
      // ローディング不要の場合は表示しない
      if (!this.$store.state.isNoLoadingWhenDrawRoute) {
        this.$store.commit('startLoading')
      } else {
        // ローディング不要フラグを落とす
        this.$store.commit('updateIsNoLoadingWhenDrawRoute', false)
      }

      // ルート初期化コールバック
      const vm = this
      const clearRouteAndIcon = () => {
        // 現在のルート形状とアイコンを削除
        vm.removeAllAddIcon()
        vm.clearDrawRoute()

        if (routeScript) {
          // ルート形状を保持している場合は描画
          vm.drawRoute(routeScript)
          // 地点アイコンを書き換え
          this.clearSearchSpotMarkers()
          this.putSearchSpotMarkers()
        }

        vm.$store.commit('endLoading')
      }

      // ローディングを表示させるため、別のコンテキストでルート初期化処理を実行
      setTimeout(clearRouteAndIcon, 0) // eslint-disable-line no-magic-numbers
    },
    /**
     * ユーザーの位置情報が更新されたら、マップ上のアイコンを移動させる
     */
    currentPosition: {
      deep: true,
      handler: function () {
        this.updateCurrentPosition()
      },
    },
    // フッター表示有無によるコピーライト位置調整
    isShowFooter: function (value) {
      // フッターの状態
      this.changeCopyrightPosition(value)
    },
  },
  methods: {
    /**
     * マップスクリプトの読み込み
     */
    loadMap() {
      this.$store.commit('startLoading')
      // 既にマップスクリプトが存在している場合は、新たな取得はしない
      let mapElement = document.getElementById('RouteMapScript')
      if (mapElement) {
        // 初回現在地は取得済みにしておく
        this.isFirstCurrentSpotInfo = true
        // マップに埋め込まれているデータを取得
        let text = mapElement.text
        // ヘッダーからマップスクリプトを削除する
        document.head.removeChild(mapElement)
        // あらかじめ取得しておいたデータを用いて新たにマップを追加する
        this.addMapScript(text)
        this.updateCurrentPosition()
        this.$store.commit('endLoading')
        return
      }

      // 非同期処理部分にはvmを用いること
      let vm = this
      const success = (data) => {
        vm.addMapScript(window.atob(data))
        this.updateCurrentPosition()
        this.$store.commit('endLoading')
      }

      vm.$nextTick(function () {
        vm.$store.dispatch('getMapScript', {
          success,
        })
      })
    },
    /**
     * マップスクリプト追加
     */
    addMapScript(base64Script) {
      if (this.isNull(document.getElementById('route-map'))) {
        return
      }
      let script = document.createElement('script')
      // スクリプトの加工・DOM追加
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('id', 'RouteMapScript')
      script.text = base64Script
      document.head.appendChild(script)
      this.init()

      // 入力中であればズームレンジを無効化
      this.setLockZoomRange(this.updateInputFlag)
    },
    /**
     * マップ情報の初期設定
     */
    init() {
      /* eslint-disable no-undef */
      const defaultZoomLevel = 15

      // コピーライトおよび縮尺の基準位置
      const basePoint = this.$config.COPYRIGHT_UNDER_SPACE.FOOTER_SHOW
      const copyrightBottom =
        basePoint +
        this.$config.FOOTER_HEIGHT +
        this.$store.state.bottomSafeAreaHeight

      const mapInfo = this.$store.state.map
      const lat =
        mapInfo.center.lat ||
        this.currentPosition.lat ||
        this.$config.DEFAULT_CURRENT_POSITION.LAT

      const lon =
        mapInfo.center.lng ||
        this.currentPosition.lon ||
        this.$config.DEFAULT_CURRENT_POSITION.LON

      this.frozen.mapRelated.map = new mapscript.Map(
        process.env.VUE_APP_NAVITIME_CLIENT_ID,
        {
          target: '#route-map',
          center: new mapscript.value.LatLng(lat, lon),
          zoomLevel: mapInfo.zoom || defaultZoomLevel,
          options: {
            copyright: {
              // ゼンリンの表示位置指定
              offset: new mapscript.value.Point(basePoint, copyrightBottom),
            },
            scale: {
              // 縮尺の表示位置指定
              offset: new mapscript.value.Point(basePoint, copyrightBottom),
            },
            // タイルの3Dビル表示設定を無効にする
            tileBuilding3D: false,
          },
        }
      )

      if (!this.isExistsMap()) {
        return
      }
      // マップの回転を有効
      this.frozen.mapRelated.map.setMapRotationEnabled(true)
      // マップの中心移動を検知するイベントリスナー追加
      this.frozen.mapRelated.map.addEventListener(
        'centermoved',
        this.onMapOperate
      )
      // マップ長押しを検知するイベントリスナー追加
      this.frozen.mapRelated.map.addEventListener(
        'longtap',
        this.onMapLongPress
      )
      // ユーザーのトラッキングモードを検知するイベントリスナー追加
      this.frozen.mapRelated.map.setUserLocationTrackingModeChangeListener(
        (trackingMode) => {
          // アイコン追従モードの場合は現在地がマップの中心であると判断
          this.isCurrentSpotCenter = this.judgeCurrentSpotCenter(trackingMode)
        }
      )

      // マップサイズを設定
      const height = document.documentElement.clientHeight
      const width = document.documentElement.clientWidth
      const mapSize = new mapscript.value.Size(height, width)
      this.frozen.mapRelated.map.setClientSize(mapSize)

      // マップサイズ変更イベントを設定(FlutterのWebViewではAndroidでのみ発火する)
      window.visualViewport.addEventListener('resize', this.onMapResize)

      // ルート検索TOP画面ではない、かつルート情報があればルート描画
      if (
        this.$route.name != this.$config.DISPLAY_SEARCH_TOP &&
        !this.isNull(this.drawRouteScript)
      ) {
        // 現在のルート形状とアイコンを削除
        this.removeAllAddIcon()
        this.clearDrawRoute()
        this.drawRoute(this.drawRouteScript)
        this.putSearchSpotMarkers()
      } else {
        // トラッキングモードをfollowにする
        this.frozen.mapRelated.map.setTrackingMode(TRACKING_MODE.FOLLOW)
      }
      /* eslint-enable no-undef */
    },
    /**
     * マップが操作されるタイミングで現在のマップモードを判断し保持
     */
    onMapOperate(event) {
      // マップを操作したらその時点のマップモードを判定して保持
      this.currentMapMode = this.judgeMapMode()
      this.movingMapEventFunc(event)
    },
    /**
     * マップ移動イベントの設定を行う
     */
    movingMapEventFunc: function (event) {
      // マップ移動後の中心座標を保持
      const center = event.center
      this.judgeMapMoving.lat = center.latMillisec
      this.judgeMapMoving.lon = center.lngMillisec

      // 時間を少し置いた後、中心座標が更新されているかどうかでマップ移動中or停止を判定
      const delayTime = 300
      setTimeout(() => {
        if (
          this.judgeMapMoving.lat == center.latMillisec &&
          this.judgeMapMoving.lon == center.lngMillisec
        ) {
          // 中心座標に変更なければ、停止通知
          this.stopMapEventFunc()
          // 移動中のフラグをfalseに切り替える
          this.judgeMapMoving.isMoving = false
        }
      }, delayTime)

      if (!this.judgeMapMoving.isMoving) {
        // 中心座標に変更あり、停止→移動に切り替わりであれば、移動中通知
        this.moveMapEventFunc()
        // 移動中のフラグをtrueに切り替える
        this.judgeMapMoving.isMoving = true
      }
    },
    /**
     * マップを長押しした際に目的地設定画面へ遷移
     */
    onMapLongPress() {
      // マップが存在しない場合は何もしない
      if (!this.isExistsMap()) {
        return
      }
      // 長押し通知
      this.longPressMapEventFunc()
    },
    /**
     * マップ停止イベントの設定を行う
     */
    stopMapEventFunc: function () {
      window.dispatchEvent(this.stopMap)
    },
    /**
     * マップ移動イベントの設定を行う
     */
    moveMapEventFunc: function () {
      window.dispatchEvent(this.movingMap)
    },
    /**
     * マップ長押しイベントの設定を行う
     */
    longPressMapEventFunc: function () {
      window.dispatchEvent(this.longPressMap)
    },

    /**
     * コピーライトの位置調整
     * @param {Boolean} isShowFooterValue フッター表示有無(true:あり、false:なし)
     */
    changeCopyrightPosition(isShowFooterValue) {
      // マップが生成されているか
      if (this.isExistsMap()) {
        // フッター有無によるコピーライトおよび縮尺の基準位置を設定（ルート機能共通）
        const basePoint = isShowFooterValue
          ? this.$config.FOOTER_HEIGHT +
            this.$config.COPYRIGHT_UNDER_SPACE.FOOTER_SHOW
          : this.$config.COPYRIGHT_UNDER_SPACE.FOOTER_HIDE
        const copyrightBottom =
          basePoint + this.$store.state.bottomSafeAreaHeight
        // eslint-disable-next-line no-undef, no-magic-numbers
        const point = new mapscript.value.Point(0, copyrightBottom)
        this.frozen.mapRelated.map.setScalePosition(point)
        this.frozen.mapRelated.map.setCopyrightPosition(point)
      }
    },
    /**
     * マップモードを切り替え、最新のモードを保持
     *
     * ・ノースアップ → ヘディングアップ
     * ・ヘディングアップ → ノースアップ(追従有効)
     * ・マップ操作モード → ノースアップ(追従無効)
     */
    changeMapMode() {
      // 現在のモードを確認し、モード切り替え
      switch (this.judgeMapMode()) {
        case this.$config.MAP_MODE.NORTH_UP: // 現在がノースアップの場合はヘディングアップに切り替え
          this.changeHeadingUpMode()
          break
        case this.$config.MAP_MODE.HEADING_UP: // 現在がヘディングアップの場合はノースアップに切り替え(追従有効)
          this.changeNorthUpMode(true)
          break
        case this.$config.MAP_MODE.OPERATING: // 現在がマップ操作の場合はノースアップに切り替え(追従無効)
          this.changeNorthUpMode(false)
          break
        default:
      }

      // 変更後のモードを保持
      this.currentMapMode = this.judgeMapMode()
    },
    /**
     * ノースアップモードに切り替え
     * @param {Boolean} isTrackingFollow 追従(follow)を有効にするか
     */
    changeNorthUpMode(isTrackingFollow) {
      this.frozen.mapRelated.map.setDirection(this.$config.MAP_DIRECTION.NORTH)

      // 追従モード切り替えの指定がある場合、トラッキングモードをfollowに切り替え
      if (isTrackingFollow) {
        this.frozen.mapRelated.map.setTrackingMode(TRACKING_MODE.FOLLOW)
      }
    },
    /**
     * ヘディングアップモードに切り替え
     * ※コンパス未取得の時は何もしない
     */
    changeHeadingUpMode() {
      // コンパス情報が取得できていない場合は、何も行わない
      const unavailableHeading = this.heading === null // 初期値=コンパス取得状態と判定
      if (unavailableHeading) return

      // コンパス情報が取得できている場合は、ヘディングアップに切り替え
      const latLng = this.userLocationData.getLatlng()
      this.frozen.mapRelated.map.setDirection(this.heading)
      this.frozen.mapRelated.map.setCenter(latLng)
      this.frozen.mapRelated.map.setTrackingMode(TRACKING_MODE.HEADING_UP)
    },
    /**
     * トラッキングモードとマップの方角からマップモードを判定
     * @return マップモード
     */
    judgeMapMode() {
      const mode = this.frozen.mapRelated.map.getTrackingMode()
      const direction = this.frozen.mapRelated.map.getDirection()
      // ヘディングアップ時はヘディングアップモードを返却
      if (mode == TRACKING_MODE.HEADING_UP)
        return this.$config.MAP_MODE.HEADING_UP

      // 北の場合、ノースアップを返却
      if (direction === this.$config.MAP_DIRECTION.NORTH)
        return this.$config.MAP_MODE.NORTH_UP

      // 上記以外はマップ操作モードを返却
      return this.$config.MAP_MODE.OPERATING
    },
    /**
     * トラッキングモードからマップの中心が現在地中心なのかどうかを判定する
     * @param {String} trackingMode トラッキングモード
     * @return {Boolean} 現在地中心なのかどうか
     */
    judgeCurrentSpotCenter(trackingMode) {
      return (
        trackingMode == TRACKING_MODE.FOLLOW ||
        trackingMode == TRACKING_MODE.HEADING_UP
      )
    },
    /**
     * ズームレンジを固定化させる
     * @param {Boolean} :isLocked true:固定化 false:解除
     */
    setLockZoomRange(isLocked) {
      /* eslint-disable no-undef */
      if (!this.isExistsMap()) {
        return
      }
      if (this.frozen.mapRelated.map) {
        this.frozen.mapRelated.map.setZoomRange(
          new mapscript.value.ZoomRange(
            isLocked
              ? this.frozen.mapRelated.map.getZoomLevel()
              : MAP_ZOOM_RANGE_MIN,
            isLocked
              ? this.frozen.mapRelated.map.getZoomLevel()
              : MAP_ZOOM_RANGE_MAX
          )
        )
      }
      /* eslint-enable no-undef */
    },
    /**
     * 任意の場所に画面を移動させる
     * @param {Number} lat 緯度
     * @param {Number} lon 経度
     */
    callFitWithLatLon(lat, lon) {
      if (!this.isExistsMap()) {
        return
      }
      /* eslint-disable no-undef */
      const spot = new mapscript.value.LatLng(lat, lon)
      this.fit([spot])
      /* eslint-enable no-undef */
    },
    /**
     * 現在地に画面を移動させる
     */
    currentSpotFit() {
      if (!this.isExistsMap()) {
        return
      }
      if (this.userLocationData) {
        // 既に現在地を表示している場合は、何もしない
        const trackingMode = this.frozen.mapRelated.map.getTrackingMode()
        if (!this.judgeCurrentSpotCenter(trackingMode)) {
          const latLng = this.userLocationData.getLatlng()
          this.frozen.mapRelated.map.setCenter(latLng)
          this.frozen.mapRelated.map.setTrackingMode(TRACKING_MODE.FOLLOW)
        }
      }
    },
    /**
     * 引数の座標を基に画面を移動させる
     */
    spotFitWithLatLon(lat, lon) {
      /* eslint-disable no-undef */
      const position = new mapscript.value.LatLng(lat, lon)
      /* eslint-enable no-undef */
      this.frozen.mapRelated.map.setCenter(position)
      this.frozen.mapRelated.map.setZoomLevel(
        this.$config.MAP_ZOOMLEVEL.ZOOM_200_M
      )
      // 追従をONにする
      this.frozen.mapRelated.map.setTrackingMode(TRACKING_MODE.FOLLOW)
    },
    /**
     * スポット位置に合わせてマップの縮尺を調整
     */
    fit(spotList) {
      /* eslint-disable no-undef */
      const rect = mapscript.util.locationsToLatLngRect(spotList)
      this.frozen.mapRelated.map.moveBasedOnLatLngRect(rect, true)
      // 追従をOFFにする
      this.frozen.mapRelated.map.setTrackingMode(TRACKING_MODE.NONE)
      /* eslint-enable no-undef */
    },
    /**
     * 複数の地点に対し、Fit処理を行う
     */
    callMultipleFitWithLatLon(latLonList) {
      /* eslint-disable no-undef */
      if (!this.isExistsMap()) {
        return
      }
      let mapSpotList = []
      for (let spot of latLonList) {
        mapSpotList.push(new mapscript.value.LatLng(spot.lat, spot.lon))
      }
      const LENGTH_ZERO = 0
      if (LENGTH_ZERO < mapSpotList.length) {
        this.fit(mapSpotList)
      }
      /* eslint-enable no-undef */
    },
    /**
     * 現在地情報を定期取得し、ピンの描画を更新
     */
    async updateCurrentPosition() {
      const map = this.frozen.mapRelated.map
      this.clearTimeout()

      if (!this.isExistsMap()) {
        // mapscriptが読み込まれていない場合、一定時間後再度実行。
        const intervalMillSec = 1000
        this.timeoutId = setTimeout(() => {
          this.timeoutId = null
          this.updateCurrentPosition()
        }, intervalMillSec)
        return
      }

      /* eslint-disable no-undef */
      if (!this.currentPosition.lat || !this.currentPosition.lon) {
        // 取得前（初期値）の場合は何もしない
        return
      }

      // 最新の現在位置のインスタンスを生成
      const updateLatLon = new mapscript.value.LatLng(
        this.currentPosition.lat,
        this.currentPosition.lon
      )

      // ユーザーアイコンのインスタンスを作成・更新
      if (this.userLocationData == null) {
        // 初回はインスタンスを生成
        this.userLocationData = new mapscript.value.UserLocationData(
          updateLatLon, // 緯度軽度
          this.$config.ZERO // 方角
        )
      } else {
        // 作成済みの場合は緯度軽度を更新
        this.userLocationData.setLatlng(updateLatLon)
      }

      // マップにユーザーアイコンを反映
      if (this.isFirstLocationData) {
        const trackingMode = map.getTrackingMode()
        // 初回 位置情報更新フラグを落とす
        this.isFirstLocationData = false
        const icon = await canvasUtil.methods.imageToBase64(
          require('@/assets/map/UserPoint.svg'),
          DIRECTION_SIZE
        )
        // await後にマップが失われていた場合は後続処理を行わない（画面遷移等）
        if (!this.isExistsMap()) {
          return
        }
        const userPoint = new mapscript.value.GLMarkerIconInfo({
          icon,
          size: DIRECTION_SIZE,
          gravity: 'center',
          isHighResolution: true,
        })
        map.setUserLocation(
          new mapscript.object.UserLocation({info: userPoint})
        )
        map.setUserLocationData(this.userLocationData)
        map.setCenter(updateLatLon)
        map.setTrackingMode(trackingMode)
        this.createUserIconMarker(updateLatLon)
        nativeUtil.methods.getHeading()
      } else {
        // ２回目以降 位置情報更新
        const duration = 1
        const easing = {calculateAnimationProgress: (t) => t}
        const animation = new mapscript.value.animation.AnimationOption(
          duration,
          easing
        )
        this.frozen.mapRelated.userIconMarker?.setPosition(
          updateLatLon,
          animation
        )
        map.setUserLocationData(this.userLocationData, true)
      }
      /* eslint-enable no-undef */
    },
    /**
     * ユーザーアイコンの生成
     * @param {*} position 生成時のアイコンの位置
     */
    async createUserIconMarker(position) {
      const map = this.frozen.mapRelated.map
      const size = this.$config.USER_ICON_SIZE_LIST[this.userIconSize].VALUE

      const icon = await canvasUtil.methods.createUserIcon(
        this.storeUserIcon,
        size.width,
        size.offsetY
      )
      // await後にマップが失われていた場合は後続処理を行わない（画面遷移等）
      if (!this.isExistsMap()) {
        return
      }

      const info = new window.mapscript.value.GLMarkerIconInfo({
        icon: icon,
        size: size,
        isHighResolution: true,
      })
      const marker = new window.mapscript.object.GLMarker({
        position: position,
        info: info,
      })
      this.frozen.mapRelated.userIconMarker = marker

      // 現在のサイズのアイコンをマップに追加する
      map.addGLMarker(marker)
    },
    /**
     * 指定の場所に対して指定のアイコンを配置する
     * @param {Number} latitude 緯度
     * @param {Number} longitude 軽度
     * @param {String} iconName 配置したいアイコン名
     * @param {Object} size 縦横サイズ
     * @param {Boolean} isDomMarker DOM版マーカーを使用するかどうか
     * @param {Number} zIndex 要素のZ-index
     * @param {Object} offset オフセット位置
     * @returns DOM版Markerの場合の場合：marker GL版Markerの場合：GLMarker
     */
    putPin(
      latitude,
      longitude,
      iconName,
      {size = {}, isDomMarker = false, zIndex = undefined, offset = undefined}
    ) {
      /* eslint-disable no-undef */
      // 表示地点指定
      const position = new mapscript.value.LatLng(latitude, longitude)
      // 表示アイコン指定
      const icon = this.isBase64Str(iconName)
        ? iconName
        : require('@/assets/' + iconName)

      let marker
      if (isDomMarker) {
        // DOM版Markerの場合
        marker = new mapscript.object.Marker({
          icon: icon, // 画像パス(base64文字列も可)
          position: position, // マーカーを表示する緯度経度
          size: size, // 画像サイズ
          zIndex: zIndex,
          offset: offset,
        })
        this.frozen.mapRelated.map.addMarker(marker)
      } else {
        // GL版Marker
        const info = new mapscript.value.GLMarkerIconInfo({
          icon: icon, // 画像パス(base64文字列も可)
          size: size, // 画像サイズ(指定なければデフォルト値が設定される)
        })
        marker = new mapscript.object.GLMarker({
          position: position, // 表示する緯度経度
          info: info,
        })
        this.frozen.mapRelated.map.addGLMarker(marker)
      }
      return marker
      /* eslint-enable no-undef */
    },
    /**
     * ルート経路の描画
     * @param {String} ルート形状のGeoJson文字列
     */
    drawRoute(routeScript) {
      // ノースアップモードに切り替え(マップの上を北に変更)
      this.frozen.mapRelated.map.setDirection(this.$config.MAP_DIRECTION.NORTH)

      /**
       * ルート形状描画
       */
      const geojson = JSON.parse(routeScript)
      this.drawShapeRoute(geojson)

      /**
       * アイコンの配置
       */

      // 電車乗り換えアイコン
      const selectedRouteTotal = this.$store.state.RouteStore.selectedRouteTotal
      this.putTransferRailWayIcon(selectedRouteTotal)
    },
    /**
     * 検索した地点に対し、アイコンを配置する
     */
    putSearchSpotMarkers() {
      const ROUTE_TYPE_TRAIN_BUS = 'TrainBus'
      const conditions =
        this.$store.state.routeType == ROUTE_TYPE_TRAIN_BUS
          ? // 電車バスの場合、選択したルートの地点を表示
            this.$store.state.RouteStore.trainBusRouteSearchConditions
          : // 上記以外の場合、検索した地点(もしくはリルートした地点)を表示する
            this.$store.state.RouteStore.forResearchConditions

      if (this.isNull(conditions?.start) || this.isNull(conditions?.goal)) {
        // 出発地、到着地がなければ、アイコンは表示しない
        return
      }

      if (!this.isExistsMap()) {
        return
      }

      // 出発
      const startGlMarkers = this.createGlMarker(
        conditions.start,
        'SpotWhiteCircle.svg',
        SPOT_WHITE_CIRCLE_SIZE
      )
      this.frozen.mapRelated.searchSpotsGlMarkers.push(startGlMarkers)

      // 到着
      const goalGlMarkers = this.createGlMarker(
        conditions.goal,
        'Icon_Map_Place.svg',
        ICON_MAP_PLACE_SIZE
      )
      this.frozen.mapRelated.searchSpotsGlMarkers.push(goalGlMarkers)

      // 経由地
      const viaArr = conditions.via
      for (let via of viaArr) {
        const viaGlMarkers = this.createGlMarker(
          via,
          'SpotWhiteCircle.svg',
          SPOT_WHITE_CIRCLE_SIZE
        )
        this.frozen.mapRelated.searchSpotsGlMarkers.push(viaGlMarkers)
      }
      this.addGLMarkers(this.frozen.mapRelated.searchSpotsGlMarkers)
      this.$store.commit(
        'updateSearchSpotGlMarkers',
        this.frozen.mapRelated.searchSpotsGlMarkers
      )
    },
    /**
     * 検索した地点のアイコンを除去する
     */
    clearSearchSpotMarkers() {
      if (!this.isExistsMap()) {
        return
      }
      if (this.$config.ZERO < this.$store.state.searchSpotsGlMarkers.length) {
        // 検索した地点のアイコンがある場合のみ除去する
        this.frozen.mapRelated.map.removeGLMarkers(
          this.frozen.mapRelated.searchSpotsGlMarkers
        )
        this.$store.commit('updateSearchSpotGlMarkers', [])
        this.frozen.mapRelated.searchSpotsGlMarkers = []
      }
    },

    /**
     * 乗り換え地点に対しアイコンを配置
     */
    putTransferRailWayIcon(selectedRouteTotal) {
      if (selectedRouteTotal && selectedRouteTotal.sections) {
        const NEXT_ONE = 1
        for (let i = 0; i < selectedRouteTotal.sections.length; i++) {
          const currettSection = selectedRouteTotal.sections[i]
          const nextSection = selectedRouteTotal.sections[i + NEXT_ONE]

          if (currettSection.type == 'move' && currettSection.nextTransit) {
            // 乗り換え地点が存在する場合は、対象の地点にアイコンを置く
            const transferGlMarker = this.putPin(
              nextSection.coord.lat,
              nextSection.coord.lon,
              'TransferRailWay.svg',
              {size: ICON_MAP_RELAY_SIZE}
            )

            // アイコン情報をdataに保持
            this.frozen.mapRelated.addGlMarkerList.push(transferGlMarker)
          }
        }
      }
    },
    /**
     * ルート描画処理
     * @param {String} geojson ルート描画情報
     */
    drawShapeRoute(geojson) {
      /* eslint-disable no-undef, no-magic-numbers */
      // ルート線オブジェクトを生成
      this.figure = new mapscript.value.GeoJsonFigureCondition(geojson, {
        isRouteShape: true, // ルート線かどうか(ルート描画の場合は必須)
      })

      // 表示領域の調整
      const [lng1, lat1, lng2, lat2] = geojson.bbox
      const rect = mapscript.util.locationsToLatLngRect([
        new mapscript.value.LatLng(lat1, lng1),
        new mapscript.value.LatLng(lat2, lng2),
      ])

      const mobility = this.$store.state.routeType
      if (
        mobility === this.$config.MOBILITY.WALK ||
        mobility === this.$config.CAR_TYPE.RECOMMEND ||
        mobility === this.$config.CAR_TYPE.TOLL ||
        mobility === this.$config.CAR_TYPE.FREE ||
        mobility === this.$config.MOBILITY.BICYCLE
      ) {
        // 車・自転車・徒歩ルート表示時は画面下部に詳細情報を表示する領域があるため、
        // その部分を排除した短形でルートを描画&画面移動する

        const paddingBottom = 188 // カード下margin(28px) + カード高(160px)
        // 被せたくない領域を設定
        const padding = new mapscript.value.RectExtension(
          0,
          paddingBottom,
          0,
          0
        )
        // 地図の中心座標の位置を調整
        this.frozen.mapRelated.map.setCenterOffset(
          new mapscript.value.Point(0, -(paddingBottom / 2))
        )
        // LatLngRectが画面内に収まる位置に移動(余白の考慮あり)
        this.frozen.mapRelated.map.moveBasedOnLatLngRect(
          rect,
          true,
          undefined,
          padding
        )
      } else {
        // LatLngRectが画面内に収まる位置に移動
        this.frozen.mapRelated.map.moveBasedOnLatLngRect(rect, true)
      }
      // 追従をOFFにする
      this.frozen.mapRelated.map.setTrackingMode(TRACKING_MODE.NONE)
      // ルート線を地図に追加
      this.frozen.mapRelated.map.addGeoJsonFigure(this.figure)
      /* eslint-enable no-undef, no-magic-numbers */
    },
    /**
     * 指定地点のGLMarker作成
     * @param {Object} spot 地点情報
     * @param {String} iconName アイコン名
     * @param {Object} size 縦横サイズ
     */
    createGlMarker(spot, iconName, size) {
      if (spot.coord == '') {
        // 緯度経度がない場合はスキップ
        return
      }
      const coord = spot.coord.split(',')
      /* eslint-disable no-undef */
      return new mapscript.object.GLMarker({
        position: new mapscript.value.LatLng(
          coord[LAT_INDEX],
          coord[LON_INDEX]
        ),
        info: new mapscript.value.GLMarkerIconInfo({
          icon: require('@/assets/' + iconName),
          size: size,
        }),
      })
      /* eslint-enable no-undef */
    },
    /**
     * マップにアイコンを描画
     */
    addGLMarkers(glMarkers) {
      this.frozen.mapRelated.map.addGLMarkers(glMarkers)
    },
    /**
     * 追加で表示しているアイコンの描画を全て削除
     */
    removeAllAddIcon() {
      this.frozen.mapRelated.map.removeGLMarkers(
        this.frozen.mapRelated.addGlMarkerList
      )
    },
    /**
     * 現状のルート描画を削除する
     */
    clearDrawRoute() {
      // ルート形状を削除
      /* eslint-disable no-undef */
      if (this.figure) {
        this.frozen.mapRelated.map.setCenterOffset(
          new mapscript.value.Point(0, 0) // eslint-disable-line no-magic-numbers
        )
        this.frozen.mapRelated.map.removeGeoJsonFigure(this.figure)
        this.figure = null
      }
    },
    /**
     * 処理実行前にマップが非表示となることがあるため
     * RouteMapScriptが存在するか確認
     */
    isExistsMap() {
      return (
        !this.isNull(document.getElementById('RouteMapScript')) &&
        !this.isNull(this.frozen.mapRelated.map?.mapController)
      )
    },
    /**
     * コンポーネントを破棄する際の初期化処理
     */
    unmountProcess() {
      // マップのリサイズイベントを取り除く
      window.visualViewport.removeEventListener('resize', this.onMapResize)
      this.frozen.mapRelated.map.removeEventListener(
        'centermoved',
        this.onMapOperate
      )

      this.clearTimeout()

      if (!this.isExistsMap()) {
        return
      }
      // 検索地点のアイコン破棄
      this.clearSearchSpotMarkers()
      try {
        // マップの中心地、ズームレベルを保持しておく
        this.$store.commit(
          'updateMapCenter',
          this.frozen.mapRelated.map.getCenter()
        )
        this.$store.commit(
          'updateMapZoom',
          this.frozen.mapRelated.map.getZoomLevel()
        )
      } catch (e) {
        // エラー時は何もしない
        log.debug('error', e)
      }
      // マップ破棄処理
      this.frozen.mapRelated.map.destroy()
      // MapScriptのStyle要素を削除
      const mapStyleEl = document.getElementById('gaia-stylesheet')
      mapStyleEl.remove()
    },
    /**
     * タイムアウト設定をクリアする
     */
    clearTimeout() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
    },
    /**
     * マップサイズ変更イベント
     */
    onMapResize() {
      // マップが存在しない場合は何もしない
      if (!this.isExistsMap()) {
        return
      }
      const currentSize = this.frozen.mapRelated.map.getClientSize()
      const height = document.documentElement.clientHeight
      const width = document.documentElement.clientWidth

      // 現在のサイズと比較し、マップ表示可能領域が広くなっている場合のみリサイズする
      if (currentSize.height < height || currentSize.width < width) {
        const mapSize = new mapscript.value.Size(height, width)
        this.frozen.mapRelated.map.setClientSize(mapSize)
      }
    },
    /**
     * 向いている方向（コンパス）の変更イベント
     */
    async onHeadingChange(event) {
      // マップが存在しない場合は何もしない
      if (!this.isExistsMap()) {
        return
      }
      const map = this.frozen.mapRelated.map

      // 方角または位置情報が取得できない場合は何もしない
      const heading = event.detail.heading
      if (
        heading == null ||
        !this.currentPosition.lat ||
        !this.currentPosition.lon
      ) {
        return
      }
      this.heading = heading
      if (this.headingIconInfo == null) {
        const icon = await canvasUtil.methods.imageToBase64(
          require('@/assets/map/UserPointDirection.svg'),
          DIRECTION_SIZE
        )
        // await後にマップが失われていた場合は後続処理を行わない（画面遷移等）
        if (!this.isExistsMap()) {
          return
        }
        const headingIconInfo = new mapscript.value.GLMarkerIconInfo({
          icon,
          size: DIRECTION_SIZE,
          gravity: 'center',
          isHighResolution: true,
        })
        map.setUserLocation(
          new mapscript.object.UserLocation({info: headingIconInfo})
        )
        this.headingIconInfo = headingIconInfo
      }
      if (this.userLocationData) {
        this.userLocationData.setHeading(heading)
        map.setUserLocationData(this.userLocationData, false)
      }
    },
  },
}
export default RouteMap
</script>
<style scoped>
#route-map {
  height: 100vh;
}
/* ゼンリン・縮尺のレイアウト */
#route-map ::v-deep(.gia-parts-scale) {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
  line-height: 11px;
  max-width: 100vw - 270px;
  padding: 2px 0;
  z-index: 0;
}
#route-map ::v-deep(.gia-parts-copyright-text) {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
  line-height: 11px;
  padding: 2px 0;
  z-index: 0;
}

.currentSpot-enter-active,
.currentSpot-leave-active {
  transition: opacity 0.5s ease;
}

.currentSpot-enter-from,
.currentSpot-leave-to {
  opacity: 0;
}
</style>
