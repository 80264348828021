<template>
  <div class="ml-2 mr-5">
    <div class="flex flex-wrap">
      <div
        class="mobility ml-3 mt-2"
        v-for="mobility in selectMobilityList"
        :key="mobility.id"
      >
        <RadioButton
          :title="mobility.title"
          :isValid="mobility.isValid"
          @click="changeOption(mobility.id)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import RadioButton from '@/components/atoms/RadioButton.vue'

const MobilityRadioButton = {
  name: 'MobilityRadioButton',
  components: {RadioButton},
  emits: ['update:selectMobilityList'],
  props: {
    selectMobilityList: {
      type: Array,
      require: true,
    },
  },
  computed: {
    /**
     * 各乗り物のid、名称、選択状態の配列
     */
    mobility: {
      /**
       * 各乗り物のid、名称、選択状態を取得する
       * @returns {Array} 各乗り物のid、名称、選択状態
       */
      get() {
        return this.selectMobilityList
      },
      /**
       * 各乗り物のid、名称、選択状態を親コンポーネントに通知する
       * @param {Array} value 各乗り物のid、名称、選択状態
       */
      set(value) {
        this.$emit('update:selectMobilityList', value)
      },
    },
  },
  methods: {
    /**
     * 乗り物選択時の切替処理
     * @param {String} id 乗り物のid
     */
    changeOption(id) {
      // 更新された乗り物を探す
      this.mobility = this.mobility.map((option) => {
        option.isValid = id === option.id ? !option.isValid : option.isValid
        return option
      })
    },
  },
}
export default MobilityRadioButton
</script>
<style scoped></style>
