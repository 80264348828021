<template>
  <FavoriteSuggestList
    @click-gotoBack="gotoBackPage()"
    @select-spot="selectSpot($event)"
    @no-address="showNoAddressPopup()"
  />
  <!-- お気に入り登録確認ポップアップ -->
  <Modal
    v-if="isRegisterSpotPopup"
    class="modal"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="10px"
  >
    <div class="pt-9 pb-6 mx-5">
      <div class="text-W5 text-[17px] leading-[21.5px]">
        この住所を登録します
        <br />
        よろしいですか？
      </div>
      <!-- スポット名・住所・建物名用入力枠 -->
      <div class="bg-gray200 mt-4 rounded-[4px] p-5 flex flex-col text-left">
        <div
          v-if="!isEmptyName"
          class="text-W5 text-[15px] leading-[15px] mb-3"
        >
          {{ this.selectSpotInfo.name }}
        </div>
        <div class="text-W2 text-[13px] leading-[13px] text-gray500 pb-6">
          {{ this.selectSpotInfo.address }}
        </div>
        <div class="text-W4 text-[13px]">（任意）建物名など</div>
        <TextForm
          class="searchText pt-2"
          :isBackButton="false"
          placeholder="マンション名・部屋番号など"
          maxlength="50"
          @input-text="setEditBuildingName"
          ref="buildingNameTextForm"
        />
      </div>
      <!-- ボタン -->
      <div class="flex justify-center mt-6 items-center">
        <div
          class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mx-3"
          @click="isRegisterSpotPopup = false"
        >
          キャンセル
        </div>
        <div
          class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px] mr-3"
          @click="registerSpot()"
        >
          {{ isEmptyName ? '登録して次へ' : '登録' }}
        </div>
      </div>
    </div>
  </Modal>
  <!-- 表示名設定ポップアップ -->
  <Modal
    v-if="isSetDisplayNamePopup"
    class="modal"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="pt-9 pb-6 mx-5">
      <div class="text-W5 text-[17px] leading-[20px]">
        表示名を編集してください
      </div>
      <!-- 表示名用入力枠 -->
      <TextForm
        class="searchText mt-4"
        :isBackButton="false"
        placeholder="お気に入り地点名を入力"
        maxlength="50"
        @input-text="setEditDisplayName"
      />
      <!-- ボタン -->
      <div class="flex justify-center mt-4 items-center">
        <div
          class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
          @click="updateDisplayName(false)"
        >
          スキップ
        </div>
        <div
          class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
          :class="disableButtonStyle"
          @click="updateDisplayName(true)"
        >
          登録
        </div>
      </div>
    </div>
  </Modal>
  <!-- 住所取得エラーポップアップ -->
  <Modal
    v-if="isNoAddressPopupFlg"
    class="modal"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="px-4 pt-9 pb-4">
      <div class="pb-5 text-W5 text-[17px] leading-[17px]">
        選択した住所が存在しません。
      </div>
      <div
        class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
        @click="isNoAddressPopupFlg = false"
      >
        OK
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import FavoriteSuggestList from '@/components/menu/FavoriteSuggestList.vue'
import TextForm from '@/components/TextForm.vue'
import menuUtil from '@/mixins/menuUtil'
import deepcopy from 'deepcopy'
/**
 * お気に入り地点検索画面
 */
const FavoriteSpotSuggest = {
  name: 'FavoriteSpotSuggest',
  components: {
    Modal,
    TextForm,
    FavoriteSuggestList,
  },
  mixins: [menuUtil],
  data() {
    return {
      isNoAddressPopupFlg: false, // 住所未取得ポップアップ
      isRegisterSpotPopup: false, // お気に入り登録確認ポップアップ
      isSetDisplayNamePopup: false, // 表示名登録ポップアップ
      selectSpotInfo: null, // 選択した地点情報
      editBuildingName: '', // 入力している追加住所
      editDisplayName: '', // 入力している表示名
      target: '', //何に対する更新か
      favoriteId: '', //お気に入りID
    }
  },
  created() {
    this.target = this.$route.params.key // 前画面から渡されたkey情報(home, work, favorite)
    this.favoriteId = this.$route.params.id != null ? this.$route.params.id : ''
  },
  computed: {
    /**
     * スポット名の空文字チェック
     */
    isEmptyName() {
      return this.selectSpotInfo.name == ''
    },
    /**
     * 表示名設定ポップアップの登録ボタンのスタイル
     */
    disableButtonStyle() {
      return this.isEditBlankName ? 'opacity-40' : ''
    },
    /**
     * 表示名の未入力と空白チェック
     */
    isEditBlankName() {
      // 未入力である、空白文字がある-> ボタンはdisabled
      return this.editDisplayName == ''
    },
  },
  methods: {
    /**
     * 入力されたビル名を設定
     * @param {String} value 検索文字
     */
    setEditBuildingName(value) {
      this.editBuildingName = value
    },
    /**
     * 入力された表示名を設定
     * @param {String} value 検索文字
     */
    setEditDisplayName(value) {
      this.editDisplayName = value
    },
    /**
     * お気に入り一覧画面に遷移
     */
    gotoBackPage() {
      // 前画面の情報を取得
      const prevRouteName = this.$route.params.prevRouteName

      if (prevRouteName == null) {
        // 前画面の情報がない場合、お気に入り地点一覧画面に遷移
        this.$router.push({name: this.$config.DISPLAY_FAVORITE_SPOT_LIST})
        return
      }

      switch (prevRouteName) {
        case this.$config.DISPLAY_FAVORITE_SPOT_DETAIL:
          // お気に入り地点詳細画面の場合は、お気に入り地点一覧画面に遷移
          this.$router.push({name: this.$config.DISPLAY_FAVORITE_SPOT_LIST})
          break
        case this.$config.DISPLAY_SEARCH_TOP:
        case this.$config.DISPLAY_ROUTE_BY_OPTIMAL:
          // 前画面が検索Top or ルート検索最適画面の場合、フッターをルート検索に切り替えて遷移
          this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_SPOT)
          this.$router.push({name: prevRouteName})
          break
        default:
          // 上記以外の場合、元の画面に遷移
          this.$router.push({name: prevRouteName})
      }
    },
    /**
     * 地点選択時処理
     * @param {String} spot FavoriteSuggestListから渡ってきた地点情報
     */
    selectSpot(spot) {
      //selectSpotInfoに選択情報を保持
      this.selectSpotInfo = deepcopy(spot)
      // イレギュラーな地点名の場合は空文字を設定する
      if (this.isIrregularSpotName(this.selectSpotInfo.name)) {
        this.selectSpotInfo.name = ''
      }
      //お気に入り登録確認ポップアップ表示
      this.isRegisterSpotPopup = true
    },
    /**
     * イレギュラーな地点名かどうかを判定する
     * @param {String} name 判定する地点名
     */
    isIrregularSpotName(name) {
      switch (name) {
        case '名称なし':
        case '自宅':
        case '会社/学校':
          // 上記の場合はから文字を返却
          return true
        default:
          // それ以外の場合はそのまま返却
          return false
      }
    },
    /**
     * 登録ボタン押下時処理
     */
    async registerSpot() {
      // 建物名フォームのフォーカスを外す
      // ※キーボードが閉じるのを待つ必要があるため、処理待ち時間を指定
      const waitTime = 100
      await this.$refs.buildingNameTextForm.blurInputForm(waitTime)

      // スポット名を決める、基本はnameに入っているものをそのまま表示
      let spotNameData = this.selectSpotInfo.name
      if (!spotNameData && this.target == this.$config.FAVORITE_SPOT) {
        // keyが「favorite」で名称がないデータは、「名称なし」として各画面に表示
        spotNameData = '名称なし'
      }
      // 登録データの準備
      let data = {
        spotName: spotNameData, // スポット名
        address: this.selectSpotInfo.address, // 住所
        buildingName: this.editBuildingName, // マンション名、号室
        coord: this.selectSpotInfo.coord, //座標
        // カテゴリー 取得できる場合のみ設定
        category: this.selectSpotInfo.category || {},
      }

      // お気に入り地点登録処理実行
      this.setFavoriteSpot(data)

      // お気に入り登録確認ポップアップを閉じる
      this.isRegisterSpotPopup = false

      // スポット名がない場合は、表示名入力画面を展開
      if (this.isEmptyName) {
        // 表示名設定ポップアップを表示
        this.isSetDisplayNamePopup = true
      } else {
        // スポット名があるデータで登録した場合、そのままお気に入り一覧画面に戻る
        this.gotoBackPage()
      }
    },
    /**
     * 表示名更新処理
     * @param {boolean} isUpdate 更新を実行するか
     */
    updateDisplayName(isUpdate) {
      if (isUpdate) {
        // 入力値が空文字、空白のみの場合ボタン押下を無効にする
        if (this.isEditBlankName) {
          return
        }
        // 登録データの準備
        let data = {
          spotName: this.editDisplayName, // スポット名
          address: this.selectSpotInfo.address, // 住所
          buildingName: this.editBuildingName, // マンション名、号室
          coord: this.selectSpotInfo.coord, //座標
        }
        // お気に入り地点登録処理実行
        this.setFavoriteSpot(data)
      }
      // 表示名設定ポップアップを閉じる
      this.isSetDisplayNamePopup = false
      // お気に入り一覧画面に戻る
      this.gotoBackPage()
    },
    /**
     * 住所未取得ポップアップ表示
     */
    showNoAddressPopup() {
      this.isNoAddressPopupFlg = true
    },
    /**
     * データ登録
     * @param {Object} data 登録対象のデータ
     * @return {Object} commitData 登録後のデータ
     */
    setFavoriteSpot(data) {
      const updateFavorite = deepcopy(data)
      // ローカルストレージへ登録
      const {commitData, dataId} = this.addFavoriteSpotList(
        updateFavorite,
        this.target,
        this.favoriteId
      )
      this.favoriteId = dataId
      this.$store.commit('MenuStore/updateFavoriteSpots', commitData)
    },
  },
}
export default FavoriteSpotSuggest
</script>

<style></style>
