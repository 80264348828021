<template>
  <div
    class="flex relative w-full justify-center items-center text-W7 text-[15px] leading-none px-6 py-4"
    :class="additionalClass()"
    @click="clickButton()"
  >
    <div v-if="hasPrevImg" :style="marginRight">
      <img class="h-4 w-4" :src="prevImg" />
    </div>
    {{ text }}
    <div v-if="hasAppendImg" class="inline-block pl-2 pt-[1%]">
      <img class="h-3 w-3" :src="appendImg" />
    </div>
  </div>
</template>
<script>
/*
 * 注意
 * public配下の capy.html は TheButton コンポーネント が使用できないため注意
 * 全体的な変更を行う際は一緒に変更が必要
 */

import Util from '@/mixins/util.js'
/**
 * ボタンの最小コンポーネント
 */
const TheButton = {
  name: 'TheButton',
  mixins: [Util],
  props: {
    // ボタン種別
    buttonType: {
      type: String,
      default: 'primary',
      validator(val) {
        return ['primary', 'secondary'].includes(val)
      },
    },
    // ボタンの名称
    text: {
      type: String,
      require: true,
    },
    // 非活性かどうか
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // 文字の前に表示するアイコンパス（@/assets/以降の相対パス）
    prevImgPath: {
      type: String,
      default: '',
    },
    // ボタンの右側に表示するアイコンパス
    appendImgPath: {
      type: String,
      default: '',
    },
    // アイコンの設置位置
    blankSpace: {
      type: String,
      default: '1',
    },
  },
  emits: ['click-button'],
  computed: {
    /**
     * 文字の前に表示するアイコンの有無を返す
     */
    hasPrevImg() {
      return !this.isNull(this.prevImgPath)
    },
    /**
     * 文字の後に表示するアイコンの有無を返す
     */
    hasAppendImg() {
      return !this.isNull(this.appendImgPath)
    },
    /**
     * 文字の前にアイコンを表示する
     */
    prevImg() {
      return require(`@/assets/${this.prevImgPath}`)
    },
    /**
     * 文字の後にアイコンを表示する
     */
    appendImg() {
      return require(`@/assets/${this.appendImgPath}`)
    },
    /**
     * アイコンに付与するマージン
     */
    marginRight() {
      // tailwindの値からremに変換(1=0.25rem)
      const coefficient = 0.25
      const blankSpace = Number(this.blankSpace) * coefficient
      return {'margin-right': `${blankSpace}rem`}
    },
  },
  methods: {
    /**
     * 追加で付与するクラス名
     */
    additionalClass() {
      const opacityClass = this.isDisabled ? 'opacity-40' : ''
      let buttonClass
      switch (this.buttonType) {
        case 'secondary':
          buttonClass = 'the-button-secondary'
          break
        case 'primary':
        default:
          buttonClass = 'the-button-default'
          break
      }
      return `${buttonClass} ${opacityClass}`
    },
    /**
     * ボタン押下時処理
     */
    clickButton() {
      // 非活性の際は何も通知しない
      if (this.isDisabled) {
        return
      }

      //   活性時は親コンポーネントに通知する
      this.$emit('click-button')
    },
  },
}
export default TheButton
</script>
<style scoped></style>
