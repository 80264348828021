<template>
  <!-- メニューカード -->
  <!-- 大枠 -->
  <div id="MenuCard" class="flex w-full py-3" @click="onClickMenu()">
    <!-- 種別アイコン -->
    <img v-if="icon" class="mr-2 w-8 h-8" :src="getIcon()" />
    <!-- 各種表示 -->
    <div
      class="flex-1 self-center mt-1 text-left text-W7 text-[15px] leading-4 truncate"
    >
      <!-- カード名 -->
      {{ name }}
    </div>
  </div>
</template>

<script>
const MenuCard = {
  name: 'MenuCard',
  props: {
    // 表示アイコン
    icon: {
      type: String,
      require: false,
    },
    // カード名
    name: {
      type: String,
      require: true,
    },
  },
  emits: ['click-menu'],
  computed: {},
  methods: {
    /**
     * 種類別アイコンの取得
     */
    getIcon() {
      return this.icon ? require(`@/assets/${this.icon}`) : ''
    },
    /**
     * カードクリック処理
     */
    onClickMenu() {
      this.$emit('click-menu')
    },
  },
}
export default MenuCard
</script>

<style></style>
