import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import config from '@/const/const.js'
import util from '@/mixins/util'
const constant = config.constant

dayjs.extend(isBetween)
// ポート運用ステータス
const PARK_STATUS = {
  OPERATING: 0, // 運用中
  PAUSED: 1, // 一時休止中
  PARKING: 2, // 一時駐輪中
}

export default {
  methods: {
    /**
     * ×ボタン押下処理
     * @param {Boolean} isInitialize 初期化を行うか
     */
    clickClose(isInitialize) {
      // 手配機能遷移前の画面/フッター選択情報
      const screenTransitionSource =
        this.$store.state.MobilityReservationStore.screenTransitionSource
      const nextDisplayName = screenTransitionSource.displayName // 遷移先画面名
      const nextFooterId = screenTransitionSource.footerId // 遷移後のフッター表示
      let routerPushData = {} // 遷移時の引数

      /**
       * ルート検索情報の初期化・遷移時の引数追加
       * ※遷移先がSearchTopの場合のみ
       */
      if (nextDisplayName === this.$config.DISPLAY_SEARCH_TOP) {
        //ルート検索結果をクリアする
        this.$store.commit('RouteStore/updateRouteResult', {
          key: 'init',
        })
        // 検索条件の初期化
        this.$store.commit('initializeSearchConditions')
        // ルート描画と初回ルート検索実施フラグの初期化
        this.$store.commit('clearDrawRouteData')
        this.$store.commit('clearDrawRouteScript')
        this.$store.commit('updateSearchRoute', false)
        this.$store.commit('RouteStore/updateIsCompleteSearchRoute', false)
      }

      /**
       * 予約情報の初期化・画面遷移
       */
      // フッターIDを更新して画面遷移
      this.$store.commit('updateFooterTab', nextFooterId)
      routerPushData.name = nextDisplayName
      this.$router.push(routerPushData)

      // 遷移後に予約情報を初期化
      if (isInitialize) {
        this.$store.commit('MobilityReservationStore/initBicycleInfo') // 自転車
        this.$store.commit('MobilityReservationStore/initTaxiInfo') // タクシー
      }

      // 一時的なカード選択情報を解放
      this.$store.commit('CreditCardsStore/clearTemporarySelectedCardSeq')
    },
    /**
     * 営業時間の説明文言判定処理
     * @param {*} target 営業時間(オブジェクト内にて開始時間、終了時間を積めておく)
     * @returns 24時間営業であること
     */
    checkBusinessTime(target) {
      const zeroTime = '00:00'
      return target.start == zeroTime && target.end == zeroTime
    },
    /**
     * 営業時間内かを判定し返却する。
     * ※24時間営業の場合は、固定で営業中を返却
     * @param {String} startTime 開始時刻
     * @param {String} endTime 終了時刻
     * @returns 営業中であるかどうか
     */
    isOpeningPort(startTime, endTime) {
      // 現在時刻
      const now = dayjs()
      const hourIndex = 0
      const minIndex = 1

      // 開始時刻
      const startObj = startTime.split(':')
      const startDate = dayjs()
        .set('hour', startObj[hourIndex])
        .set('minute', startObj[minIndex])
        .set('second', '00')

      // 終了時刻
      const endObj = endTime.split(':')
      let endDate = dayjs()
        .set('hour', endObj[hourIndex])
        .set('minute', endObj[minIndex])
        .set('second', '00')

      // 終了の時間より開始の時間の方が大きい場合は1日後の日付を終了日とする
      if (endDate.hour() < startDate.hour()) {
        // eslint-disable-next-line no-magic-numbers
        endDate = endDate.add(1, 'day')
      }

      return startTime === endTime
        ? true // 24時間営業の場合は固定で営業中を返却する。
        : now.isBetween(startDate, endDate) // 上記ではない場合、営業時間内か判定し返却する。
    },
    /**
     * 吹き出し内の要素作成
     * @param {String} icon 駐輪場のイメージ画像
     * @param {String} portId ポートID
     * @param {String} portName ポート名
     * @param {Object} businessTime 営業時間
     * @param {String} businessTime.start 開始時間
     * @param {String} businessTime.end 終了時間
     * @param {Number} parkStatus 営業状態
     * @param {Function} clickFunc クリックイベントのコールバック関数
     * @returns {Element} ポート情報の要素
     */
    generateDetailWindow(
      icon,
      portId,
      portName,
      businessTime,
      parkStatus,
      clickFunc
    ) {
      // 運用中以外、営業時間外であるか
      const isNotOperating =
        this.isPortPausedOrParking(parkStatus) ||
        !this.isOpeningPort(businessTime.start, businessTime.end)

      // 土台のDivタグ
      const baseDiv = document.createElement('div')
      baseDiv.setAttribute('id', portId)
      baseDiv.classList.add(
        'p-3',
        'bg-white',
        'shadow-normal',
        'rounded-[10px]',
        'w-[311px]'
      )

      // クリックイベント発火用のDivタグ（baseDivにid設定した場合、吹き出しより大きな範囲でイベント発火する問題が発生したための対処）
      const eventDiv = document.createElement('div')
      eventDiv.id = 'port-info-bubble' // idの設定
      eventDiv.classList.add('flex', 'items-center')
      eventDiv.onclick = isNotOperating ? null : clickFunc // クリックイベント発火時の処理を追加

      // アイコン部分のDivタグ
      const iconDiv = document.createElement('div')
      iconDiv.classList.add('w-[72px]', 'h-[49px]', 'bg-white')
      const image = document.createElement('img')
      image.src = icon
      // 画像読み込みに失敗した場合は代替画像表示
      image.addEventListener('error', (e) => {
        this.setNoImageOnError(e)
      })
      iconDiv.appendChild(image)

      // 詳細情報部分のDivタグ
      const detailDiv = document.createElement('div')
      detailDiv.classList.add('flex-1', 'pl-3', 'text-left')

      // ポート名部分
      const portNameDiv = document.createElement('div')
      portNameDiv.innerHTML = portName
      portNameDiv.classList.add(
        'text-W7',
        'text-3',
        'leading-3',
        'mb-2',
        'w-[186px]',
        'truncate'
      )

      // 営業時間部分
      const businessTimeDiv = document.createElement('div')
      businessTimeDiv.classList.add('flex', 'items-center')
      // 営業時間(ラベル)部分
      const businessTimeLavelDiv = document.createElement('div')
      businessTimeLavelDiv.innerHTML = '[営業時間]'
      businessTimeLavelDiv.classList.add('w-14', 'text-W4', 'leading-[12px]')
      // 営業時間(コンテキスト)部分
      const isOpenTwentyFour = this.checkBusinessTime(businessTime)
      const businessTimeValueDiv = document.createElement('div')
      if (isOpenTwentyFour) {
        businessTimeValueDiv.innerHTML = '24時間'
      } else {
        businessTimeValueDiv.innerHTML = `${businessTime.start}-${businessTime.end}`
      }
      businessTimeValueDiv.classList.add(
        'pl-1',
        'flex-1',
        'text-W4',
        'leading-[12px]'
      )
      // 運用状態アイコン
      const portStatusDiv = document.createElement('div')
      if (isNotOperating) {
        // 運用していない場合
        portStatusDiv.innerHTML = this.isPortPausedOrParking(parkStatus)
          ? '休止中'
          : '時間外'
        const bgColor = this.isPortPausedOrParking(parkStatus)
          ? 'bg-[#6B7075]'
          : 'bg-danger300'
        portStatusDiv.classList.add(
          'text-W4',
          'leading-[12px]',
          'w-auto',
          'rounded-[12px]',
          'py-1',
          'px-2',
          'text-white',
          bgColor
        )
      }
      // 右アイコン表示
      const path = require('@/assets/arrangementIcon/bicycle/IconRight.svg')
      const rightImage = document.createElement('img')
      rightImage.src = path
      rightImage.classList.add('h-3', 'w-3')

      // 営業時間部分の合併
      businessTimeDiv.appendChild(businessTimeLavelDiv)
      businessTimeDiv.appendChild(businessTimeValueDiv)
      if (isNotOperating) {
        // 運用していない場合はステータスを表示
        businessTimeDiv.appendChild(portStatusDiv)
      }
      // 詳細部分（右側）の合併
      detailDiv.appendChild(portNameDiv)
      detailDiv.appendChild(businessTimeDiv)
      // 土台部分の合併
      eventDiv.appendChild(iconDiv)
      eventDiv.appendChild(detailDiv)
      if (!isNotOperating) {
        // 運用中の場合は右アイコン表示
        eventDiv.appendChild(rightImage)
      }
      baseDiv.appendChild(eventDiv)

      return baseDiv
    },
    /**
     * ポートが休止中、一時駐輪状態かを返却する
     * @param {Number} parkStatus ポートステータス
     * @returns ポートが休止中か一時駐輪か
     */
    isPortPausedOrParking(parkStatus) {
      return (
        parkStatus === PARK_STATUS.PAUSED || parkStatus === PARK_STATUS.PARKING
      )
    },
    /**
     * 画像読み込みに失敗した際の代替画像設定処理
     * @param {Element} e エレメント
     */
    setNoImageOnError(e) {
      e.target.src = require('@/assets/NoImage.svg')
    },
    /**
     * 吹き出し内の要素を更新する
     * @param {String} targetId 対象の要素のid
     * @param {Element} content 挿入する要素
     */
    resetWindowContents(targetId, content) {
      // 削除対象の要素を取得
      const targetNode = document.getElementById(targetId)
      // 親要素を取得
      const parentNode = targetNode.parentElement
      // 削除対象の要素を削除
      parentNode.removeChild(targetNode)
      // 新しい要素を挿入
      parentNode.insertBefore(content, parentNode.firstChild)
    },
    /**
     * ポート一覧取得API用現在位置座標オブジェクト作成処理
     * @param {*} position 任意座標
     * @returns 現在位置座標オブジェクト
     */
    createPositionForQuery(position) {
      // 座標作成
      return {
        lat: position.lat || constant.DEFAULT_CURRENT_POSITION.LAT,
        lon: position.lon || constant.DEFAULT_CURRENT_POSITION.LON,
      }
    },
    /**
     * 未読の乗務員メッセージが存在するかを判定する
     * @param {Array} messageList メッセージ履歴
     * @returns {Boolean} 未読判定結果
     */
    judgeUnreadTaxiMessages(messageList) {
      // 受け取ったメッセージの中から乗務員メッセージ数を取得
      const driverMessagesLength = messageList.filter(
        (message) => message.sender === constant.TAXI_MESSAGE_SENDER.DRIVER
      ).length

      // ローカルストレージから既読済みの乗務員メッセージ数を取得
      const readDriverMessagesLength =
        util.methods.getLocalStorage(constant.MESSAGE_LOG_LENGTH) || 0 // eslint-disable-line no-magic-numbers

      // [最新の乗務員メッセージ数]と[既読済み乗務員メッセージ数]を比較し、
      // 既読済み乗務員メッセージ数が下回れば未読ありと判断する
      return readDriverMessagesLength < driverMessagesLength
    },
  },
}
