<template>
  <div class="rounded-[4px] text-left">
    <div v-if="isTilte" class="pb-1">
      <slot name="title" />
    </div>
    <slot name="explanation" />
  </div>
</template>
<script>
const BaseBox = {
  name: 'BaseBox',
  props: {
    /**
     * タイトルの表示有無
     */
    isTilte: {
      type: Boolean,
      default: false,
    },
  },
}
export default BaseBox
</script>
<style scoped></style>
