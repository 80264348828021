<template>
  <div id="BicycleReservationConfirmed" class="fixed-app">
    <!-- ヘッダー -->
    <Header
      ref="arrangementHeader"
      :headerTitle="arrangementHeaderTitle"
      :isShowPrevious="false"
      :isShowClose="true"
      @clickClose="onClickHeaderClose()"
    />
    <div class="mt-5 mx-6 mb-8">
      <span class="text-W7 text-[18px] leading-[18px] text-center">
        {{ mainTitle }}
      </span>
      <div class="mt-5 text-W4 text-[12px] leading-[12px] text-gray600">
        <div class="text-right">
          <span>有効期限&nbsp;</span>
          <span>{{ reserveLimit }}</span>
        </div>
      </div>
      <!--自転車番号、解錠コード-->
      <div class="mt-2 rounded-[4px] bg-blue200">
        <div class="p-3">
          <div class="flex w-full text-W4 text-[12px] leading-[12px]">
            <div class="text-left">自転車番号</div>
            <div v-if="isFelica" class="ml-auto text-right">解錠コード</div>
          </div>
          <div class="flex mt-1 text-W7 text-[24px] leading-[27px]">
            <div class="text-left">
              {{ reservationInfoData.cycName }}
            </div>
            <div v-if="isFelica" class="ml-auto text-right">
              {{ reservationInfoData.passcode }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="isQR">
        <!-- 丸型鍵の説明 -->
        <div class="text-left my-4 text-W4 text-[14px] leading-[21px]">
          自転車操作端末で
          <span class="font-bold">「開始」を押してから、</span>
          下の「自転車の鍵を開ける」ボタンをタップしてください。
        </div>
        <div class="mt-4 flex justify-center">
          <img
            class="w-full"
            src="@/assets/arrangementIcon/bicycle/HowToUnLockCycle.png"
          />
        </div>
        <div class="mt-5">
          <TheButton
            text="自転車の鍵を開ける"
            class="the-button-orange"
            :isDisabled="isCompletedArrangement"
            @click-button="unlockCycle()"
          />
        </div>
        <!-- セクション分けボーダー -->
        <div class="my-6 border border-gray300"></div>
      </div>
      <!-- ボタン -->
      <div class="mt-5">
        <TheButton
          text="ポートまでのルートを見る"
          class="mb-3"
          :isDisabled="isCompletedArrangement"
          @click-button="searchRoute()"
        />
        <TheButton
          text="返却できるポートを探す"
          class="mb-3"
          :isDisabled="isCompletedArrangement"
          @click-button="clickReturnPort()"
        />
        <TheButton
          text="予約を取り消す"
          class="the-button-secondary"
          :isDisabled="isNotReservationCompletedStatus"
          @click-button="isShowCancelConfirmModal = true"
        />
      </div>
      <!-- 貸出ポート情報 -->
      <div class="text-left mt-8">
        <div class="text-W7 text-[18px] leading-[18px] mb-3">
          貸出ポート情報
        </div>
        <!-- ポート名 -->
        <div class="text-W6 text-[12px] leading-[18px] mb-3">
          {{ reservationInfoData.parkName }}
        </div>
        <!-- 画像 -->
        <img
          class="w-full mb-3 bg-white"
          :src="reservationInfoData.imageUrl"
          @error="setNoImageOnError($event)"
        />
        <!-- マップ -->
        <BaseMap
          v-if="isShowPortMap"
          ref="BaseMap"
          mapElementId="targetPortMap"
          :defaultCenter="defaultCenter"
          :isTouchMap="false"
          :mapSize="mapSize"
          @finish-generate-map="finishGenerateMap()"
        />
        <!-- ポート情報 -->
        <div class="mt-4">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">営業時間</div>
          <div v-if="is24Hours" class="text-W4 text-[14px] leading-[14px]">
            24時間
          </div>
          <div v-else class="text-W4 text-[14px] leading-[14px]">
            {{ businessTime }}
          </div>
        </div>
        <div class="mt-3">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">住所</div>
          <div class="text-W4 text-[14px] leading-[14px]">
            {{ reservationInfoData.parkAddress }}
          </div>
        </div>
        <div class="mt-3">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">アクセス</div>
          <div class="text-W4 text-[14px] leading-[21px] my-[-3.5px]">
            {{ reservationInfoData.howToAccess }}
          </div>
        </div>
      </div>
      <!-- アコーディオン -->
      <div class="py-8">
        <AccordionPanel title="自転車を借りる方法">
          <template v-slot:AccordionContents>
            <div class="text-W4 text-[14px] leading-[21px]">
              <!-- 説明文 -->
              <div class="flex">
                <div class="pr-2 my-[-3.5px]">①</div>
                <div class="flex1 my-[-3.5px]">
                  自転車操作パネルで「START」を押して解錠コード入力。
                </div>
              </div>
              <div class="flex mt-2">
                <div class="pr-2 my-[-3.5px]">②</div>
                <div class="flex1 my-[-3.5px]">
                  電子錠が自動で開き利用開始です。
                </div>
              </div>
              <!-- 画像 -->
              <img
                class="pt-4 w-full"
                src="@/assets/arrangementIcon/bicycle/HowToRentCycle.png"
              />
            </div>
          </template>
        </AccordionPanel>
        <AccordionPanel title="自転車を返却する方法" class="mt-3">
          <template v-slot:AccordionContents>
            <div class="text-W4 text-[14px] leading-[21px]">
              <div>
                <div class="flex">
                  <div class="pr-2 my-[-3.5px]">①</div>
                  <div class="flex1 my-[-3.5px]">
                    サイクルポートに駐輪して、手動で施錠します。
                  </div>
                </div>
                <!-- 画像 -->
                <img
                  class="pt-4 w-full"
                  src="@/assets/arrangementIcon/bicycle/HowToLockCycle.png"
                />
              </div>
              <div class="mt-4">
                <!-- 説明文 -->
                <div class="flex">
                  <div class="pr-2 my-[-3.5px]">②</div>
                  <div class="flex1 my-[-3.5px]">
                    自転車操作パネルの「ENTER」または「返却」を押して返却完了です。
                  </div>
                </div>
                <!-- 画像 -->
                <img
                  class="pt-4 w-full"
                  src="@/assets/arrangementIcon/bicycle/HowToReturnCycle.svg"
                />
              </div>
            </div>
          </template>
        </AccordionPanel>
        <AccordionPanel title="自転車を一時駐輪する" class="mt-3">
          <template v-slot:AccordionContents>
            <div>
              <div class="text-W7 text-[13px] leading-[13px]">
                一時駐輪（停車）の方法
              </div>
              <div class="text-W4 text-[14px] leading-[21px] mt-3">
                <div class="my-[-3.5px]">
                  手動で自転車後部にある鍵をかけてください。
                </div>
                <div class="flex mt-2">
                  <div class="pr-2 my-[-3.5px]">※</div>
                  <div class="flex1 my-[-3.5px]">
                    鍵の種類により施錠方法が異なります。
                  </div>
                </div>
                <div class="flex mt-2">
                  <div class="pr-2 my-[-3.5px]">※</div>
                  <div class="flex1 my-[-3.5px]">
                    一時停車中も利用料金がかかります。
                  </div>
                </div>
                <div class="flex mt-2">
                  <div class="pr-2 my-[-3.5px]">※</div>
                  <div class="flex1 my-[-3.5px]">
                    オレンジ色のつまみがある鍵は、オレンジのつまみを押し込みながら施錠してください。
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="text-W7 text-[13px] leading-[13px]">
                一時駐輪（再開）の方法
              </div>
              <div class="text-W4 text-[14px] leading-[21px] mt-3">
                自転車の「START」または「開始」ボタンを押し、借りた際と同じ方法で鍵を開けてから、利用を再開してください。
              </div>
            </div>
          </template>
        </AccordionPanel>
      </div>
    </div>
    <!-- 有効期限超過ポップアップ -->
    <Modal
      v-if="isShowReservationOverTimeModal"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-5">
        <div class="text-W5 text-[17px] leading-[21.5px] mb-4">
          一定時間が経過したため、予約を取り消しました。
        </div>
        <TheButton text="OK" @click-button="closeReservationOverTime()" />
      </div>
    </Modal>
    <!-- キャンセル実施確認ポップアップ -->
    <Modal
      v-if="isShowCancelConfirmModal"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-5">
        <div class="text-W5 text-[17px] leading-[21.5px] mb-4">
          自転車を変えた場合、変更前の自転車はしばらくご利用できませんのでご注意ください。
        </div>
        <!-- ボタン -->
        <div class="flex justify-center mt-4 items-center">
          <!-- キャンセルボタン -->
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="isShowCancelConfirmModal = false"
          >
            キャンセル
          </div>
          <!-- OKボタン -->
          <div class="w-1/2">
            <TheButton text="OK" @click-button="onClickOkInCancelConfirm()" />
          </div>
        </div>
      </div>
    </Modal>
    <!-- キャンセル成功ポップアップ -->
    <Modal
      v-if="isShowCancelSuccessModal"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-5">
        <div class="text-W5 text-[17px] leading-[21.5px] mb-4">
          予約を取り消しました。
        </div>
        <TheButton text="OK" @click-button="onClickOkInSuccess()" />
      </div>
    </Modal>
    <!-- キャンセル失敗ポップアップ -->
    <Modal
      v-if="isShowCancelFailedModal"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-5">
        <div class="text-W5 text-[17px] leading-[21.5px] mb-4">
          予約の取り消しができませんでした。
        </div>
        <TheButton text="OK" @click-button="isShowCancelFailedModal = false" />
      </div>
    </Modal>
    <!-- 解錠失敗ポップアップ -->
    <Modal
      v-if="isShowUnlockFailedModal"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-5">
        <div class="text-W5 text-[17px] leading-[21.5px] mb-4">
          解錠できませんでした。
        </div>
        <TheButton text="OK" @click-button="isShowUnlockFailedModal = false" />
      </div>
    </Modal>
  </div>
</template>

<script>
/**
 * 自転車予約確定画面
 */
import Header from '@/components/organisms/Header.vue'
import ArrangementUtil from '@/mixins/arrangementUtil.js'
import AccordionPanel from '@/components/molecules/AccordionPanel.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Modal from '@/components/Modal.vue'
import dayjs from 'dayjs'
import Util from '@/mixins/util.js'
import deepcopy from 'deepcopy'
import BaseMap from '@/components/atoms/BaseMap.vue'

// アタッチメント種別
const CSA_TYPE = {
  FELICA: 0, // FeliCa対応版
  QR: 1, // QR対応版
}

// マップサイズ
const MARGIN_X = 48

// 地図上に表示するアイコン画像サイズ
const PORT_ICON = {
  name: 'Icon_Map_Place.svg',
  height: 44,
  width: 44,
}

const BicycleReservationConfirmed = {
  name: 'BicycleReservationConfirmed',
  components: {
    Header,
    AccordionPanel,
    TheButton,
    Modal,
    BaseMap,
  },
  mixins: [Util, ArrangementUtil],
  data() {
    return {
      isShowCancelConfirmModal: false, // キャンセル実施確認ポップアップフラグ
      isShowCancelSuccessModal: false, // キャンセル成功ポップアップフラグ
      isShowCancelFailedModal: false, // キャンセル失敗ポップアップフラグ
      isShowUnlockFailedModal: false, // 解錠失敗ポップアップフラグ
      reservationInfoData: deepcopy(
        this.$store.state.MobilityReservationStore.bicycle.reservationInfo
      ), // 画面表示用の予約情報
      isFailedReservation: false, // 手配失敗フラグ
    }
  },
  computed: {
    /**
     * BaseMap内のマップインスタンス取得
     */
    mapSize() {
      return {
        height: 480,
        width: document.documentElement.clientWidth - MARGIN_X,
      }
    },
    /**
     * マップ表示時の初期中心地
     */
    defaultCenter() {
      return {
        lat: this.reservationInfoData.lat,
        lon: this.reservationInfoData.lon,
      }
    },
    /**
     * 有効期限を指定の日付フォーマットで取得する
     */
    reserveLimit() {
      // 有効期限が入っていればフォーマットして返却
      // 入っていなければ空文字を返却
      const targetData = this.reservationInfoData
      return !this.isNull(targetData.reserveLimit)
        ? dayjs(targetData.reserveLimit).format('YYYY/MM/DD H:mm')
        : ''
    },
    /**
     * 営業時間が24時間営業か確認する
     * @return {boolean} true: 24時間営業
     */
    is24Hours() {
      const businessTime = {
        start: this.reservationInfoData.startTime,
        end: this.reservationInfoData.endTime,
      }
      return this.checkBusinessTime(businessTime)
    },
    /**
     * 予約ステータスを返却する
     */
    reservationStatus() {
      return this.$store.state.MobilityReservationStore.bicycle
        .reservationStatus
    },
    /**
     * モビリティ手配の予約時間経過フラグを取得
     */
    isReservationOverTime() {
      return this.$store.getters[
        'MobilityReservationStore/isReservationOverTime'
      ]
    },
    /**
     * 有効期限超過ポップアップを表示するか
     */
    isShowReservationOverTimeModal() {
      return this.isReservationOverTime && !this.isFailedReservation
    },
    /**
     * 予約完了ステータス以外かを返却する
     */
    isNotReservationCompletedStatus() {
      return (
        this.reservationStatus !==
        this.$config.BICYCLE_STATUS_CODE.COMPLETE_RESERVATION
      )
    },
    /**
     * マップを表示するかを返却する
     * 予約完了から利用中の場合はtrueを返却する
     */
    isShowPortMap() {
      // 予約完了・利用中の場合はtrue
      const mapShowedStatusArray = [
        this.$config.BICYCLE_STATUS_CODE.COMPLETE_RESERVATION,
        this.$config.BICYCLE_STATUS_CODE.START_USING,
      ]
      return mapShowedStatusArray.includes(this.reservationStatus)
    },
    /**
     * 予約処理中ステータス以外かを返却する
     */
    isNotReservingStatus() {
      return (
        this.reservationStatus !== this.$config.BICYCLE_STATUS_CODE.RESERVING
      )
    },
    /**
     * 返却完了ステータスかどうかを返却する
     * 返却後はステータスが初期状態になるため、判定条件にデフォルトの値を設定
     */
    isCompleteReturnStatus() {
      return (
        this.reservationStatus ===
        this.$config.BICYCLE_STATUS_CODE.DEFAULT_STATUS
      )
    },
    /**
     * 手配が終了したかを返却する
     */
    isCompletedArrangement() {
      return this.isCompleteReturnStatus || this.isReservationOverTime
    },
    /**
     * ヘッダータイトルを取得する
     * [予約中/キャンセル時/手配失敗時]：ご予約中の自転車
     * 上記以外：ご利用中の自転車
     */
    arrangementHeaderTitle() {
      const isReservationCancelFlg =
        this.$store.state.MobilityReservationStore.bicycle
          .isReservationCancelFlg
      // 手動予約キャンセルフラグか、手配失敗フラグがtrueの場合、予約中の文言を表示する
      if (isReservationCancelFlg || this.isFailedReservation)
        return 'ご予約中の自転車'

      // 初期値(空文字)もしくはundefinedの場合は予約として扱う
      let text
      switch (this.reservationStatus) {
        case this.$config.BICYCLE_STATUS_CODE.START_USING: // 利用開始
        case this.$config.BICYCLE_STATUS_CODE.DEFAULT_STATUS: // デフォルトの初期状態
          text = 'ご利用中の自転車'
          break
        case this.$config.BICYCLE_STATUS_CODE.COMPLETE_RESERVATION: // 予約中
        case this.$config.BICYCLE_STATUS_CODE.CANCEL_RESERVATION: // キャンセル
        default:
          // 上記と一致しないケース(初期値等)の場合は、予約中として判定
          text = 'ご予約中の自転車'
          break
      }
      return text
    },
    /**
     * アタッチメント種別がFelica対応版かどうかを返す
     */
    isFelica() {
      return this.reservationInfoData.csaType === CSA_TYPE.FELICA
    },
    /**
     * アタッチメント種別がQR対応版かどうかを返す
     */
    isQR() {
      return this.reservationInfoData.csaType === CSA_TYPE.QR
    },
    /**
     * タイトル
     */
    mainTitle() {
      switch (this.reservationInfoData.csaType) {
        case CSA_TYPE.FELICA:
          return '解錠コード発行'
        case CSA_TYPE.QR:
          return '自転車の解錠について'
        default:
          // 上記と一致しないケースの場合は、QR版の文言を表示
          return '自転車の解錠について'
      }
    },
    /**
     * 営業時間を返却
     */
    businessTime() {
      // 営業時間が入っていれば営業時間情報を返却
      // 入っていなければ空文字を返却
      const targetData = this.reservationInfoData
      const isFulfilledBusinessTime =
        !this.isNull(targetData.startTime) && !this.isNull(targetData.endTime)

      return isFulfilledBusinessTime
        ? `${targetData.startTime}~${targetData.endTime}`
        : ''
    },
  },
  watch: {
    /**
     * ステータスウォッチャ
     * 初期表示時にもhandlerを実行
     */
    reservationStatus: {
      immediate: true,
      handler(newStatus, oldStatus) {
        this.switchDisplayByReservationStatus(newStatus, oldStatus)
      },
    },
  },
  methods: {
    /**
     * マップ生成後の処理
     */
    finishGenerateMap() {
      // ポートのアイコンを配置
      this.setPortIcon()
    },
    /**
     * マップにポートアイコンを描画する
     */
    setPortIcon() {
      const lat = this.reservationInfoData.lat
      const lon = this.reservationInfoData.lon
      const iconName = PORT_ICON.name
      const iconSize = {
        height: PORT_ICON.height,
        width: PORT_ICON.width,
      }

      // アイコンをプロット
      this.$refs.BaseMap.putPin('portIcon', lat, lon, iconSize, iconName)
    },
    /**
     * 返却できるポートを探すボタン押下処理
     */
    clickReturnPort() {
      // 自転車 地図から返却画面に遷移する
      this.$router.push({
        name: this.$config.DISPLAY_BICYCLE_RESERVATION_RETURN_PORT_ON_MAP,
      })
    },
    /**
     * モビリティ手配の予約時間経過ポップアップを閉じる
     */
    closeReservationOverTime() {
      // 画面を閉じて予約情報を初期化する
      this.clickClose(true)
    },
    /**
     * 現在地からの利用開始ポートまでのルート検索を行う。
     */
    searchRoute() {
      /**
       * 再検索に伴う初期化処理
       */
      // 最適画面 - 検索状態の初期化
      this.$store.commit('updateSearchRoute', false)
      this.$store.commit('RouteStore/updateIsCompleteSearchRoute', false)

      // ルート描画初期化(ローディングなし)
      this.$store.commit('updateIsNoLoadingWhenDrawRoute', true)
      this.$store.commit('clearDrawRouteData')
      this.$store.commit('clearDrawRouteScript')

      /**
       * 検索条件設定（現在時間・現在地出発・ポート目的地）
       * ※初期値を設定している場合、最適画面にて現在地として検索されるため、明示的な設定不要。
       */
      // 検索条件の初期化
      this.$store.commit('initializeSearchConditions')

      const port = {
        name: this.reservationInfoData.parkName,
        address: this.reservationInfoData.parkAddress,
        coord: `${this.reservationInfoData.lat},${this.reservationInfoData.lon}`,
      }
      // 出発地点設定処理
      // ここで検索をする場合、事前に現在地を設定する必要があるため
      this.$store.commit('updateSearchConditions', {
        key: this.$config.SPOT_TYPE_START,
        value: {
          name: '現在地',
          address: '',
          coord: '',
        },
      })
      // 到着地点(ポート)
      this.$store.commit('updateSearchConditions', {
        key: this.$config.SPOT_TYPE_GOAL,
        value: port,
      })
      // 時間(現在時刻/出発)
      this.$store.commit('updateSearchConditions', {
        key: 'timeType',
        value: {id: 1, name: '出発'},
      })
      this.$store.commit('updateSearchConditions', {
        key: 'targetTime',
        value: this.getNow(),
      })

      /**
       * 遷移処理
       */
      // 最適画面に遷移
      const spotTab = 1
      this.$store.commit('updateFooterTab', spotTab)
      this.$router.push({name: 'RouteByOptimal'})
      // ストレージのタブIDを更新
      this.setLocalStorage(this.$config.FOOTER_TAB, spotTab)
    },
    /**
     * キャンセル実施確認ポップアップのOKボタン押下処理
     */
    onClickOkInCancelConfirm() {
      // 成功時
      const success = () => {
        this.$store.commit('endLoading')
        this.isShowCancelConfirmModal = false
        this.isShowCancelSuccessModal = true
      }

      // 失敗時
      const failed = () => {
        this.$store.commit('endLoading')
        this.isShowCancelConfirmModal = false
        this.isShowCancelFailedModal = true
      }

      // 異常時
      const error = (e) => {
        this.$store.commit('endLoading')
        this.isShowCancelConfirmModal = false
        throw e
      }

      // 自転車予約キャンセルAPI実行
      this.$store.commit('startLoading')
      this.$store.dispatch(
        'MobilityReservationStore/cancelBicycleReservation',
        {
          success,
          failed,
          error,
        }
      )
    },
    /**
     * ヘッダーを閉じるボタン押下時処理
     */
    onClickHeaderClose() {
      const isInit = this.isCompletedArrangement
      this.clickClose(isInit)
    },
    /**
     * キャンセル成功ポップアップのOKボタン押下処理
     */
    onClickOkInSuccess() {
      this.isShowCancelSuccessModal = false
      // 予約ステータスを初期値に更新
      this.$store.commit('MobilityReservationStore/initReservationStatus')
      // 予約キャンセルフラグをfalseに更新
      this.$store.commit(
        'MobilityReservationStore/updateReservationCancelFlg',
        false
      )
      // 画面を閉じて予約情報を初期化する
      this.clickClose(true)
    },
    /**
     * 自転車の解錠を行う（アタッチメント種別がQR対応版のみ）
     */
    unlockCycle() {
      // 成功時
      const success = () => {
        this.$store.commit('endLoading')
      }

      // 失敗時
      const failed = () => {
        this.$store.commit('endLoading')
        this.isShowUnlockFailedModal = true
      }

      // 自転車解錠API実行
      this.$store.commit('startLoading')
      this.$store.dispatch('MobilityReservationStore/unlockBicycle', {
        success: success,
        failed: failed,
        cycName: this.reservationInfoData.cycName,
      })
    },
    /**
     * 手配ステータスによって表示を切り替える
     */
    switchDisplayByReservationStatus(newStatus, oldStatus) {
      // 予約処理中の場合はローディング表示
      if (newStatus === this.$config.BICYCLE_STATUS_CODE.RESERVING) {
        this.$store.commit('startLoading')
      }

      // 予約完了の場合は最新データを一時退避し、ローディング削除
      if (newStatus === this.$config.BICYCLE_STATUS_CODE.COMPLETE_RESERVATION) {
        this.reservationInfoData = deepcopy(
          this.$store.state.MobilityReservationStore.bicycle.reservationInfo
        )
        this.$store.commit('endLoading')
      }

      // 手配処理中から初期値・キャンセルの場合はローディング削除
      // DB側では元ステータスにロールバックを行っているため
      const failedStatusPattern = [
        this.$config.BICYCLE_STATUS_CODE.DEFAULT_STATUS,
        this.$config.BICYCLE_STATUS_CODE.CANCEL_RESERVATION,
      ]
      const isFailedNewStatus = failedStatusPattern.includes(newStatus)
      const isFailedReservation =
        oldStatus === this.$config.BICYCLE_STATUS_CODE.RESERVING &&
        isFailedNewStatus
      if (isFailedReservation) {
        this.$store.commit('endLoading')
        this.isFailedReservation = true
      }
    },
  },
}
export default BicycleReservationConfirmed
</script>

<style></style>
