/**
 * レスポンス返却用例外
 */
class ReturnResponseError extends Error {
  /**
   * コンストラクタ
   * @param {*} message エラー内容
   * @param {*} response レスポンス
   */
  constructor(message, response) {
    super(message)
    this.message = message
    this.response = response
  }
}

export default ReturnResponseError
