import {constant} from '@/const/const'

/**
 * 天気アイコン取得処理
 * @param {String} weatherCode 天気コード
 * @returns 天気アイコン
 */
export function getWeatherIcon(weatherCode) {
  let iconName = ''
  // 時々：ANY、のち：TO、又：OR
  switch (weatherCode) {
    // 晴れ
    case constant.WEATHER_CODE.SUNNY:
      iconName = '10_sunny.svg'
      break
    case constant.WEATHER_CODE.SUNNY_ANY_CLOUDY:
      iconName = '12_sunny_any_cloudy.svg'
      break
    case constant.WEATHER_CODE.SUNNY_ANY_RAINY:
      iconName = '13_sunny_any_rainy.svg'
      break
    case constant.WEATHER_CODE.SUNNY_ANY_RAINY_OR_SNOW:
      iconName = '14_sunny_any_rainy_or_snow.svg'
      break
    case constant.WEATHER_CODE.SUNNY_ANY_SNOW:
      iconName = '15_sunny_any_snow.svg'
      break
    case constant.WEATHER_CODE.SUNNY_TO_CLOUDY:
      iconName = '16_sunny_to_cloudy.svg'
      break
    case constant.WEATHER_CODE.SUNNY_TO_RAINY:
      iconName = '17_sunny_to_rainy.svg'
      break
    case constant.WEATHER_CODE.SUNNY_TO_RAINY_OR_SNOW:
      iconName = '18_sunny_to_rainy_or_snow.svg'
      break
    case constant.WEATHER_CODE.SUNNY_TO_SNOW:
      iconName = '19_sunny_to_snow.svg'
      break
    // 曇り
    case constant.WEATHER_CODE.CLOUDY:
      iconName = '20_cloudy.svg'
      break
    case constant.WEATHER_CODE.CLOUDY_ANY_SUNNY:
      iconName = '21_cloudy_any_sunny.svg'
      break
    case constant.WEATHER_CODE.CLOUDY_ANY_RAINY:
      iconName = '23_cloudy_any_rainy.svg'
      break
    case constant.WEATHER_CODE.CLOUDY_ANY_RAINY_OR_SNOW:
      iconName = '24_cloudy_any_rainy_or_snow.svg'
      break
    case constant.WEATHER_CODE.CLOUDY_ANY_SNOW:
      iconName = '25_cloudy_any_snow.svg'
      break
    case constant.WEATHER_CODE.CLOUDY_TO_SUNNY:
      iconName = '26_cloudy_to_sunny.svg'
      break
    case constant.WEATHER_CODE.CLOUDY_TO_RAINY:
      iconName = '27_cloudy_to_rainy.svg'
      break
    case constant.WEATHER_CODE.CLOUDY_TO_RAINY_OR_SNOW:
      iconName = '28_cloudy_to_rainy_or_snow.svg'
      break
    case constant.WEATHER_CODE.CLOUDY_TO_SNOW:
      iconName = '29_cloudy_to_snow.svg'
      break
    // 雨
    case constant.WEATHER_CODE.RAINY:
      iconName = '30_rainy.svg'
      break
    case constant.WEATHER_CODE.RAINY_ANY_SUNNY:
      iconName = '31_rainy_any_sunny.svg'
      break
    case constant.WEATHER_CODE.RAINY_ANY_CLOUDY:
      iconName = '32_rainy_any_cloudy.svg'
      break
    case constant.WEATHER_CODE.RAINY_ANY_SNOW:
    case constant.WEATHER_CODE.RAINY_ANY_SNOW2:
      iconName = '34_rainy_any_snow.svg'
      break
    case constant.WEATHER_CODE.RAINY_TO_SUNNY:
      iconName = '36_rainy_to_sunny.svg'
      break
    case constant.WEATHER_CODE.RAINY_TO_CLOUDY:
      iconName = '37_rainy_to_cloudy.svg'
      break
    case constant.WEATHER_CODE.RAINY_TO_SNOW:
    case constant.WEATHER_CODE.RAINY_TO_SNOW2:
      iconName = '38_rainy_to_snow.svg'
      break
    // 雨又雪
    case constant.WEATHER_CODE.RAINY_OR_SNOW:
    case constant.WEATHER_CODE.RAINY_OR_SNOW2:
    case constant.WEATHER_CODE.RAINY_OR_SNOW3:
    case constant.WEATHER_CODE.SNOW_OR_RAINY:
    case constant.WEATHER_CODE.SNOW_OR_RAINY2:
      iconName = '40_rainy_or_snow.svg'
      break
    case constant.WEATHER_CODE.RAINY_OR_SNOW_ANY_SUNNY:
      iconName = '41_rainy_or_snow_any_sunny.svg'
      break
    case constant.WEATHER_CODE.RAINY_OR_SNOW_ANY_CLOUDY:
      iconName = '42_rainy_or_snow_any_cloudy.svg'
      break
    case constant.WEATHER_CODE.RAINY_OR_SNOW_TO_SUNNY:
      iconName = '46_rainy_or_snow_to_sunny.svg'
      break
    case constant.WEATHER_CODE.RAINY_OR_SNOW_TO_CLOUDY:
      iconName = '47_rainy_or_snow_to_cloudy.svg'
      break
    // 雪
    case constant.WEATHER_CODE.SNOW:
      iconName = '50_snow.svg'
      break
    case constant.WEATHER_CODE.SNOW_ANY_SUNNY:
      iconName = '51_snow_any_sunny.svg'
      break
    case constant.WEATHER_CODE.SNOW_ANY_CLOUDY:
      iconName = '52_snow_any_cloudy.svg'
      break
    case constant.WEATHER_CODE.SNOW_ANY_RAINY:
    case constant.WEATHER_CODE.SNOW_ANY_RAINY2:
      iconName = '53_snow_any_rainy.svg'
      break
    case constant.WEATHER_CODE.SNOW_TO_SUNNY:
      iconName = '56_snow_to_sunny.svg'
      break
    case constant.WEATHER_CODE.SNOW_TO_CLOUDY:
      iconName = '57_snow_to_cloudy.svg'
      break
    case constant.WEATHER_CODE.SNOW_TO_RAINY:
    case constant.WEATHER_CODE.SNOW_TO_RAINY2:
      iconName = '58_snow_to_rainy.svg'
      break
    // 不明
    case constant.WEATHER_CODE.NOT_FOUND:
    default:
      iconName = '255_not_found.svg'
  }

  return require(`@/assets/weather/${iconName}`)
}
