<template>
  <div ref="baseEl" class="relative h-full w-full">
    <!-- タイル地図の表示および制御するコンポーネント -->
    <div
      id="base-map"
      :name="mapElementId"
      ref="map"
      :style="noTouchAreaStyle"
    />
    <!-- マップが操作できないよう、透過Layerをかぶせる -->
    <div
      v-if="!isTouchMap"
      class="absolute top-0 layer-z-index"
      :style="noTouchAreaStyle"
    />
  </div>
</template>
<script>
/**
 * マップ ベースコンポーネント
 * ※アイコン管理は呼び出し下で実施
 */
import Util from '@/mixins/util.js'

const BaseMap = {
  name: 'BaseMap',
  mixins: [Util],
  props: {
    // MapScriptを生成する際に付与するID
    mapElementId: String,
    // マップを操作できるかどうか
    isTouchMap: {
      type: Boolean,
      default: true,
    },
    // マップの初期表示指定があるかどうか（指定しない場合、東京駅）
    defaultCenter: {
      type: Object,
      default: {},
    },
    // マップの中心座標のずらしを行うかどうか
    centerOffset: {
      type: Object,
      default: {
        lat: 0,
        lon: 0,
      },
    },
    // マップの表示サイズ指定があるかどうか（していない場合、親要素依存）
    mapSize: {
      type: Object,
      default: {},
    },
    // ゼンリン・コピーライトの表示位置指定（画面下からのpx指定）
    copyrightBottomSpace: {
      type: Number,
      default: 8,
    },
    // RectExtensionにて使用する表示領域拡張用パディング（px指定）
    mapPaddingPx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      // VueのReactivityによる値変更検知をさせないため、オブジェクトを凍結
      frozen: Object.freeze({
        mapRelated: {
          map: null,
        },
      }),
    }
  },
  mounted() {
    // マップを読み込み
    this.loadMap()
  },
  computed: {
    /**
     * タップ不可用の透過レイヤーのサイズ指定
     */
    noTouchAreaStyle() {
      return this.mapSize.height != null && this.mapSize.width
        ? {height: `${this.mapSize.height}px`, width: `${this.mapSize.width}px`}
        : {height: `100%`, width: `100%`}
    },
  },
  methods: {
    /**
     * ポートマップの読み込みを行う
     */
    loadMap() {
      const mapElement = document.getElementById(this.mapElementId)
      if (mapElement) {
        let text = mapElement.text
        document.head.removeChild(mapElement)
        this.addMapScript(text)
        // 既に生成済みの場合、初期設定処理を行う
      } else {
        // 未生成の場合、マップを生成する
        let vm = this
        // 地図情報を取得する
        const success = (data) => {
          this.addMapScript(window.atob(data))
          vm.$store.commit('endLoading')
        }
        vm.$store.commit('startLoading')
        vm.$nextTick(function () {
          // 地図情報取得処理
          vm.$store.dispatch('getMapScript', {
            success,
          })
        })
      }
    },
    /**
     * マップスクリプト追加
     */
    addMapScript(base64Script) {
      if (this.isNull(document.getElementById('base-map'))) {
        return
      }
      let script = document.createElement('script')
      // スクリプトの加工・DOM追加
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('id', this.mapElementId)
      script.text = base64Script
      document.head.appendChild(script)
      this.init()
    },
    /* eslint-disable no-undef */
    /**
     * マップ情報の初期設定
     */
    init() {
      /**
       * 各種初期設定
       */
      const currentPosition = this.$store.state.currentPosition

      const centerLat =
        this.defaultCenter.lat ||
        currentPosition.lat ||
        this.$config.DEFAULT_CURRENT_POSITION.LAT
      const centerLon =
        this.defaultCenter.lon ||
        currentPosition.lon ||
        this.$config.DEFAULT_CURRENT_POSITION.LON
      const zoomLevel = this.$config.MAP_ZOOMLEVEL.ZOOM_200_M
      const mapSizeHeight = this.mapSize.height
      const mapSizeWidth = this.mapSize.width
      const centerOffsetLat = this.centerOffset.lat
      const centerOffsetLon = this.centerOffset.lon

      /**
       * マップ生成
       */
      // インスタンス生成
      this.frozen.mapRelated.map = new mapscript.Map(
        process.env.VUE_APP_NAVITIME_CLIENT_ID,
        {
          target: '#base-map',
          center: new mapscript.value.LatLng(centerLat, centerLon),
          options: {
            // 中心位置をずらす
            centerOffset: new mapscript.value.Point(
              centerOffsetLat,
              centerOffsetLon
            ),
            // タイルの3Dビル表示設定を無効にする
            tileBuilding3D: false,
          },
          zoomLevel: zoomLevel,
        }
      )

      if (!this.isExistsMap()) {
        return
      }
      // サイズ指定
      if (mapSizeHeight != null && mapSizeWidth != null) {
        const mapSize = new mapscript.value.Size(mapSizeHeight, mapSizeWidth)
        this.frozen.mapRelated.map.setClientSize(mapSize)
      }

      this.$emit('finish-generate-map')

      // コピーライト位置調整
      if (!this.isNull(this.copyrightBottomSpace)) {
        this.setCopyrightBottomSpace()
      }
    },
    /**
     * ゼンリン・コピーライトの位置指定用のCSS変数の設定
     */
    setCopyrightBottomSpace() {
      // 対象要素の取得
      const mapEl = document.getElementById(`base-map`)
      const firstIndex = 0
      const zenrinEl = mapEl.getElementsByClassName('gia-parts-copyright-text')[
        firstIndex
      ]
      const scaleEl =
        mapEl.getElementsByClassName('gia-parts-scale')[firstIndex]

      // CSS変数の付与
      const value = `${this.copyrightBottomSpace}px`
      zenrinEl.style.setProperty('--bottomPx', value)
      scaleEl.style.setProperty('--bottomPx', value)
    },
    /**
     * 指定の場所に対してアイコンを配置する
     * @param {Array} iconList アイコン情報の一覧
     * @param {String} iconList.name アイコンに付与する名前
     * @param {Number} iconList.lat 緯度
     * @param {Number} iconList.lon 軽度
     * @param {Object} iconList.size サイズ
     * @param {String} iconList.icon アイコン名
     * @returns {Array} GLMarker
     */
    generateGlMarkers(iconList) {
      if (!this.isExistsMap()) {
        return
      }
      let glMarkers = []
      for (let iconInfo of iconList) {
        // アイコン配置位置
        const position = new mapscript.value.LatLng(iconInfo.lat, iconInfo.lon)

        // アイコン情報
        const info = new mapscript.value.GLMarkerIconInfo({
          // 画像パス(base64文字列も可)
          icon: require('@/assets/' + iconInfo.icon),
          // 画像サイズ(指定なければデフォルト値が設定される)
          size: {
            height: iconInfo.size.height,
            width: iconInfo.size.width,
          },
        })

        // GLMarkerを生成
        const glMarker = new mapscript.object.GLMarker({
          name: iconInfo.name,
          position: position, // 表示する緯度経度
          info: info,
        })

        glMarkers.push(glMarker)
      }
      return glMarkers
    },
    /**
     * マップにアイコンを描画
     */
    addGLMarkers(glMarkers) {
      if (!this.isExistsMap()) {
        return
      }
      this.frozen.mapRelated.map.addGLMarkers(glMarkers)
    },
    /**
     * 指定の場所に対してアイコンを配置する
     * @param {Number} latitude 緯度
     * @param {Number} longitude 軽度
     * @returns GLMarker
     */
    putPin(name, latitude, longitude, size, icon) {
      if (!this.isExistsMap()) {
        return
      }
      // アイコン配置位置
      const position = new mapscript.value.LatLng(latitude, longitude)

      // アイコン情報
      const info = new mapscript.value.GLMarkerIconInfo({
        // 画像パス(base64文字列も可)
        icon: require('@/assets/' + icon),
        // 画像サイズ(指定なければデフォルト値が設定される)
        size: {
          height: size.height,
          width: size.width,
        },
      })

      // GLMarkerを生成
      const glMarker = new mapscript.object.GLMarker({
        name: name,
        position: position, // 表示する緯度経度
        info: info,
      })

      // Mapにプロット
      this.frozen.mapRelated.map.addGLMarker(glMarker)
      return glMarker
    },
    /**
     * 追加で表示しているアイコンの描画を全て削除
     */
    removeGLMarkers(glMarkers) {
      if (!this.isExistsMap()) {
        return
      }
      this.frozen.mapRelated.map.removeGLMarkers(glMarkers)
    },
    /**
     * マップの中心地を移動
     * @param {Number} lat 緯度
     * @param {Number} lon 経度
     */
    setCenter(lat, lon) {
      if (!this.isExistsMap()) {
        return
      }
      const spot = new mapscript.value.LatLng(lat, lon)
      // 画面移動
      this.frozen.mapRelated.map.setCenter(spot)
    },
    /**
     * マップの中心地を移動し、ズームレベルを標準に切り替える
     * @param {Number} lat 緯度
     * @param {Number} lon 経度
     */
    setCenterAndZoomDefault(lat, lon) {
      if (!this.isExistsMap()) return

      // 中心地を移動
      this.setCenter(lat, lon)
      // ズームレベルをデフォルトに変更
      const defaultZoom = this.$config.MAP_ZOOMLEVEL.ZOOM_200_M
      this.frozen.mapRelated.map.setZoomLevel(defaultZoom)
    },
    /**
     * 中心座標の取得処理
     */
    getCenter() {
      if (!this.isExistsMap()) {
        return
      }
      return this.frozen.mapRelated.map.getCenter()
    },
    /**
     * 任意の場所に画面を移動させる
     * @param {Number} lat 緯度
     * @param {Number} lon 経度
     */
    callFitWithLatLon(lat, lon) {
      if (!this.isExistsMap()) {
        return
      }
      // 座標チェック(無効な場合は何もしない)
      if (!this.checkValidLat(lat) || !this.checkValidLon(lon)) return

      // 有効な桁数に切り取り

      /* eslint-disable no-undef */
      const spot = new mapscript.value.LatLng(
        this.fixedFloatNum(lat),
        this.fixedFloatNum(lon)
      )
      this.fit([spot])
      /* eslint-enable no-undef */
    },
    /**
     * 緯度が有効な範囲であるかどうか
     */
    checkValidLat(lat) {
      // 数値でない場合、無効を返却
      if (typeof lat !== 'number') return false

      // マップ範囲内であれば有効を返却
      return (
        this.$config.NAVITIME_MAP_VALID_AREA.LAT.MIN <= lat &&
        lat <= this.$config.NAVITIME_MAP_VALID_AREA.LAT.MAX
      )
    },
    /**
     * 経度が有効な範囲であるかどうか
     */
    checkValidLon(lon) {
      // 数値でない場合、無効を返却
      if (typeof lon !== 'number') return false

      // マップ範囲内であれば有効を返却
      return (
        this.$config.NAVITIME_MAP_VALID_AREA.LON.MIN <= lon &&
        lon <= this.$config.NAVITIME_MAP_VALID_AREA.LON.MAX
      )
    },
    /**
     * Navitimeの有効な小数点まで切り取り
     */
    fixedFloatNum(num) {
      const digit = 6
      return parseFloat(num.toFixed(digit))
    },
    /**
     * 複数地点のFit処理を行う
     * @param {Array} spots 地点一覧
     * @param {Number} spots.lat 緯度
     * @param {Number} spots.lon 経度
     */
    fitMultipleLatLon(spots) {
      if (!this.isExistsMap()) {
        return
      }
      let spotList = []
      /* eslint-disable no-undef */
      for (let spot of spots) {
        let latLon = new mapscript.value.LatLng(spot.lat, spot.lon)
        spotList.push(latLon)
      }
      const rect = mapscript.util.locationsToLatLngRect(spotList)

      let mapPadding = null
      if (!this.isNull(this.mapPaddingPx)) {
        // マップのパディング設定
        const mapPaddingPx = this.mapPaddingPx
        mapPadding = new mapscript.value.RectExtension(
          mapPaddingPx,
          mapPaddingPx,
          mapPaddingPx,
          mapPaddingPx
        )
      }

      this.frozen.mapRelated.map.moveBasedOnLatLngRect(
        rect,
        false,
        null,
        mapPadding
      )
    },
    /**
     * スポット位置に合わせてマップの縮尺を調整
     * ※複数指定した場合、全てが収まるように調整
     */
    fit(spotList) {
      if (!this.isExistsMap()) {
        return
      }
      /* eslint-disable no-undef */
      const rect = mapscript.util.locationsToLatLngRect(spotList)
      this.frozen.mapRelated.map.moveBasedOnLatLngRect(rect, false)
      this.frozen.mapRelated.map.setZoomLevel(
        this.$config.MAP_ZOOMLEVEL.ZOOM_200_M
      )
      /* eslint-enable no-undef */
    },
    /**
     * 処理実行前にマップが非表示となることがあるため
     * BaseMapScriptが存在するか確認
     */
    isExistsMap() {
      return (
        !this.isNull(document.getElementById(this.mapElementId)) &&
        !this.isNull(this.frozen.mapRelated.map?.mapController)
      )
    },
    /* eslint-enable no-undef */
  },
  beforeUnmount() {
    if (!this.isExistsMap()) {
      return
    }

    // マップ破棄処理
    this.frozen.mapRelated.map.destroy()
    // MapScriptのStyle要素を削除
    const mapStyleEl = document.getElementById('gaia-stylesheet')
    mapStyleEl.remove()
  },
}
export default BaseMap
</script>
<style scoped>
#base-map {
  /* iOSのアドレスバーを考慮した高さを取得 */
  height: -webkit-fill-available;
}
#base-map ::v-deep(.gia-parts-scale) {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
  line-height: 11px;
  max-width: 100vw - 270px;
  padding: 0;
  bottom: var(--bottomPx);
}
#base-map ::v-deep(.gia-parts-copyright-text) {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
  line-height: 11px;
  padding: 0;
  bottom: var(--bottomPx);
}
.layer-z-index {
  z-index: 10;
  transform: translateZ(1px);
}
</style>
