<template>
  <svg viewBox="0 0 16 16">
    <path
      d="M87.46,188h1.2v-4.8h-1.2Zm.54-6.28a.65.65,0,0,0,.47-.184.609.609,0,0,0,.19-.456.669.669,0,0,0-.19-.484.663.663,0,0,0-.94,0,.668.668,0,0,0-.19.484.61.61,0,0,0,.19.456A.649.649,0,0,0,88,181.72ZM88.005,192a7.744,7.744,0,0,1-3.11-.63A8.081,8.081,0,0,1,80.63,187.1a8.007,8.007,0,0,1,0-6.226,8,8,0,0,1,1.72-2.537,8.211,8.211,0,0,1,2.547-1.71,8.007,8.007,0,0,1,6.226,0,8,8,0,0,1,4.247,4.25,8,8,0,0,1,0,6.225,8.171,8.171,0,0,1-1.71,2.542,8.076,8.076,0,0,1-2.54,1.72A7.737,7.737,0,0,1,88.005,192Zm0-1.2a6.794,6.794,0,1,0-4.82-1.99A6.532,6.532,0,0,0,88.01,190.8ZM88,184Z"
      transform="translate(-80 -176)"
      fill="#1a1c21"
      class="icon-instructions-path"
    />
  </svg>
</template>
