<template>
  <div
    class="base-link-text text-W5 text-primary underline text-[13px] inline-block"
    @click="openUrlWithAnotherTab()"
  >
    {{ label }}
  </div>
</template>
<script>
/**
 * リンク表示用コンポーネント
 */
const BaseLink = {
  name: 'BaseLink',
  props: {
    /**
     * ラベル
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * URL
     */
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    /**
     * propsで受け取ったURLを別タブで開く
     */
    openUrlWithAnotherTab() {
      window.open(this.url, '_blank', 'noreferrer')
    },
  },
}
export default BaseLink
</script>

<style scoped>
.base-link-text::after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url(@/assets/common/Icon_Target_Blank.svg);
  margin-left: 4px;
  vertical-align: middle;
}
</style>
