<template>
  <div>
    <!-- 日付選択フォーム部分 -->
    <DateForm
      :defaultDate="defaultDate"
      :placeholder="placeholder"
      :isDisabled="isDisabled"
      :inputClass="formColorClass"
      @input-text="setAndValidateValue"
    />
    <!-- エラーメッセージ部分 -->
    <div
      v-show="isErrorFlg"
      class="mt-2 text-left text-danger300 text-W2 leading-[13px]"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import DateForm from '@/components/DateForm.vue'
import {useVuelidate} from '@vuelidate/core'
import {required, minValue, maxValue} from '@vuelidate/validators'
import CustomValidationRules from '@/mixins/customValidationRulesUtil.js'
import Util from '@/mixins/util.js'

const DateFormWithValidationComponent = {
  name: 'DateFormWithValidation',
  mixins: [CustomValidationRules, Util],
  components: {DateForm},
  props: {
    // 最大文字数
    maxlength: {
      type: String,
      default: '-1',
    },
    // 初期表示日時
    defaultDate: {
      type: String,
      default: '',
    },
    // プレースホルダーのテキスト
    placeholder: {
      type: String,
      default: '',
    },
    // 非活性設定
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // エラー時のテキスト
    errorMessage: {
      type: String,
      default: '',
    },
    // バリデーションの種類
    validationType: {
      type: Array,
      required: true,
    },
    // フォームに付与するクラス
    inputClass: {
      type: String,
      default: '',
    },
    // 最小日付
    // minValueルールがついてるのであれば必ず設定すること！
    minValue: {
      type: Date,
      default: null,
    },
    // 最大日付
    // maxValueルールがついてるのであれば必ず設定すること！
    maxValue: {
      type: Date,
      default: null,
    },
    // 初期表示時にバリデーションを行うか否か
    isFirstLoadValidation: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * バリデーションルールの付与
   */
  validations() {
    return {
      selectedDate: this.validationList(),
    }
  },
  emits: ['input-text'],
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      selectedDate: new Date(this.defaultDate),
      isErrorFlg: false,
    }
  },
  computed: {
    /**
     * フォームに付与するクラスの切り替え（エラー時 : デフォルト時）
     */
    formColorClass() {
      const errorClass = '!border-danger300 bg-danger100'
      return this.isErrorFlg ? errorClass : this.inputClass
    },
    /**
     * 入力文字がバリデーションエラーであるかどうか
     */
    isInvalid: {
      get() {
        return this.v$.selectedDate.$invalid
      },
    },
  },
  async mounted() {
    if (this.isFirstLoadValidation) {
      await this.setAndValidateValue(this.defaultDate)
    }
  },
  methods: {
    /**
     * 付与するバリデーションを設定し返却する
     */
    validationList() {
      let validation = {}

      // 必須チェック付与
      if (this.validationType.includes('required')) {
        validation.required = required
      }
      // 最小日付チェック付与
      if (this.validationType.includes('minValue')) {
        validation.minValue = minValue(this.minValue)
      }
      // 最大日付チェック付与
      if (this.validationType.includes('maxValue')) {
        validation.maxValue = maxValue(this.maxValue)
      }

      return validation
    },
    /**
     * フォーカスアウト時バリデーションを行う処理
     */
    async setAndValidateValue(value) {
      const dateValue = new Date(value)
      this.selectedDate = dateValue
      // 親に修正を通知
      this.$emit('input-text', value)

      // バリデーション結果取得
      const validationResult = await this.v$.selectedDate.$validate()
      // エラーがなければtrueが返ってくるので、falseをエラーフラグに詰める
      this.isErrorFlg = !validationResult
    },
  },
}
export default DateFormWithValidationComponent
</script>
<style scoped></style>
