<template>
  <div name="BaseCheckBox" class="flex items-start">
    <!-- デフォルトチェックボックス非表示のためラベルで括る -->
    <label class="check-box">
      <input
        type="checkbox"
        :checked="modelValue"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
      <span class="check-text items-center flex"></span>
    </label>
    <!-- ラベル表示 -->
    <span
      class="ml-2 text-W4 text-[14px]"
      @click="$emit('update:modelValue', !modelValue)"
    >
      {{ label }}
    </span>
  </div>
</template>
<script>
/**
 * チェックボックスのコンポーネント
 */
const BaseCheckBox = {
  name: 'BaseCheckBox',
  props: {
    /**
     * チェックボックス右に表示するラベル
     */
    label: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * 値
     */
    modelValue: {
      type: Boolean,
      detault: false,
    },
  },
}
export default BaseCheckBox
</script>
<style scoped>
.check-box input {
  display: none; /* デフォルトのチェックボックスを非表示 */
}
.check-box input + .check-text::before {
  background-image: url(@/assets/CheckOff.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  position: relative;
  width: 20px;
  height: 20px;
}
.check-box input:checked + .check-text::before {
  background-image: url(@/assets/CheckOn.svg);
}
</style>
