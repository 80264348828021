/**
 * 最大ファイルサイズ[MB]
 */
export const MAX_FILE_SIZE = 10
/**
 * 有効ファイル形式
 */
const ENABLE_EXTENSIONS = ['jpeg', 'jpg', 'jpe', 'png', 'bmp', 'heic', 'heif']
/**
 * 最大画像サイズ(縦or横のピクセル)
 */
const DEFAULT_MAX_IMAGE_SIZE = 2048

import nativeUtil from '@/mixins/nativeUtil'

/**
 * 拡張子一覧取得
 * @param {String} separator 拡張子間の区切り文字
 * @returns 拡張子一覧(文字列)
 */
export function getEnableExtensions(separator = '/') {
  return ENABLE_EXTENSIONS.join(separator)
}

/**
 * 画像サイズの比率を維持しつつ縮小後のサイズを取得
 */
function getLimitedSize(maxSize, width, height) {
  if (!maxSize || (width <= maxSize && height <= maxSize)) {
    return {width, height, resize: false}
  } else if (width > height) {
    return {width: maxSize, height: (maxSize * height) / width, resize: true}
  } else {
    return {width: (maxSize * width) / height, height: maxSize, resize: true}
  }
}
/**
 * 画像利用可否判定
 * @param {File} file Fileオブジェクト 
 * @returns {Boolean} 利用可否
 */
function isValid(file) {
  // ファイルサイズチェック
  const maxFileSize = MAX_FILE_SIZE * Math.pow(1024, 2) // eslint-disable-line no-magic-numbers
  if (maxFileSize < file.size) { return false }
  
  // 拡張子チェック
  const extension = file.name.split('.').pop().toLowerCase()
  if (!ENABLE_EXTENSIONS.includes(extension)) { return false }

  return true
}
/**
 * 画像ファイル読み込み
 * 入力された画像をjpegに変換して返す
 * @param {File} file Fileオブジェクト
 * @param {number} maxSize 最大サイズ（縦または横のピクセルが）
 */
export async function loadImageFile(file, maxSize = DEFAULT_MAX_IMAGE_SIZE) {
  // 引数チェック
  if (!file) throw new Error()

  if (!isValid(file)) throw new Error()

  return new Promise((resolve, reject) => {
    let base64data
    const image = document.createElement('img')

    // 画像ファイルとして認められた場合に呼ばれる
    image.onload = () => {
      const size = getLimitedSize(maxSize, image.width, image.height)

      // 縮小&JPEG変換用キャンバスを生成
      const canvas = document.createElement('canvas')
      canvas.width = size.width
      canvas.height = size.height

      // 縮小してキャンバスに描画
      const context = canvas.getContext('2d')
      const basePoint = 0
      context.fillStyle = 'white'
      context.fillRect(basePoint, basePoint, size.width, size.height)
      context.drawImage(image, basePoint, basePoint, size.width, size.height)

      // キャンバスのDataURLをJPEG形式で返す
      const jpegQuality = 0.85
      resolve(canvas.toDataURL('image/jpeg', jpegQuality))
    }

    // 画像ファイルとして認められない場合に呼ばれる
    image.onerror = async (e) => {
      if (base64data) {
        // nativeの画像変換を呼び出して扱える形式に変換する
        const convertedImage = await nativeUtil.methods.convertImage(base64data)

        // 同じデータで複数回処理されるのを回避するためクリアする
        base64data = undefined

        // 変換が成功した場合はあらためてimageにセットする（再び onload または onerror が動く）
        if (convertedImage) {
          image.src = convertedImage
          return
        }
      }
      reject(e)
    }

    // FileReaderでFileを読んでbase64にする
    const reader = new FileReader()
    reader.onload = () => {
      // 画像ファイルを読み込み、imgのsrcにセットすることで正しい画像ファイルであるか判定する
      base64data = reader.result
      image.src = base64data
    }
    reader.onerror = (e) => reject(e)
    reader.readAsDataURL(file)
  })
}
