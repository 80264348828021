<template>
  <svg viewBox="0 0 12 12">
    <g transform="translate(-123 -1055)">
      <path
        d="M6,12,0,6,6,0,7.071,1.071,2.141,6l4.929,4.929Z"
        transform="translate(132.535 1067) rotate(180)"
        fill="#1a1c21"
        class="icon-list-right-arrow-path"
      />
      <rect
        width="12"
        height="12"
        transform="translate(123 1055)"
        fill="none"
      />
    </g>
  </svg>
</template>
