<template>
  <div class="p-4">
    <SelectRadioButtonForm
      class="w-full h-[28px]"
      :radioButtonList="getRadioButtonFormList"
      :selectedNum="targetTimeType.id"
      @click-radio-button="changeTargetTimeType($event)"
    />
    <div class="flex pt-[42px] pb-10 items-center justify-around">
      <div>
        <InputDateTimeForm
          class="routeSearchDate"
          :inputType="'date'"
          :defaultDateTime="routeSearchDateTime"
          :validationResult="routeSearchDateErrorFlg"
          @change-datetime="changeRouteSearchDateTime($event)"
        ></InputDateTimeForm>
      </div>
      <div>
        <InputDateTimeForm
          class="routeSearchDate"
          :inputType="'time'"
          :defaultDateTime="routeSearchDateTime"
          :validationResult="routeSearchDateErrorFlg"
          :dateDisabled="isFirstLastTrain"
          :inputTimeType="getTimeTypeName"
          @change-datetime="changeRouteSearchDateTime($event)"
        ></InputDateTimeForm>
      </div>
    </div>
    <div class="mb-6">
      <div
        class="m-auto w-fit text-W4 leading-[15px] text-[15px] text-blue"
        @click="currentDate()"
      >
        現在時刻
      </div>
    </div>
    <div
      id="timer-button"
      class="text-W7 text-[15px] the-button-default w-full text-base py-4 leading-[100%] flex items-center justify-center"
      @click="clickTimerButton()"
    >
      日時設定を完了
    </div>
  </div>
</template>

<script>
import SelectRadioButtonForm from '@/components/SelectRadioButtonForm.vue'
import InputDateTimeForm from '@/components/InputDateTimeForm.vue'
import Util from '@/mixins/util'

const CalendarSelectForm = {
  name: 'CalendarSelectForm',
  components: {SelectRadioButtonForm, InputDateTimeForm},
  mixins: [Util],
  emits: ['completeDate'],
  data() {
    return {
      routeSearchDateErrorFlg: true,
      // 日時
      routeSearchDateTime: this.initialDateTime,
      // 出発or到着or始発or終電
      targetTimeType: this.initialTargetType,
    }
  },
  props: {
    // 日時の初期値
    initialDateTime: {
      type: String,
    },
    // 出発到着の初期値
    initialTargetType: {
      type: Object,
    },
    // 始発・終電表示フラグ
    isShowFirstLastTrain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * ラジオボタンに表示するチェックリストを取得する
     */
    getRadioButtonFormList() {
      if (this.isShowFirstLastTrain) {
        // 出発・到着・始発・終電を返す
        return this.$config.TIME_TYPE_LIST
      }
      // 出発・到着を返す
      return this.$config.TIME_TYPE_LIST.filter((type) => {
        return (
          type.id == this.$config.TIME_TYPE_ID.START ||
          type.id == this.$config.TIME_TYPE_ID.GOAL
        )
      })
    },
    /**
     * 始発or終電をチェックしているか確認する
     * @param return true: 始発 or 終電
     */
    isFirstLastTrain() {
      return (
        this.targetTimeType.id == this.$config.TIME_TYPE_ID.FIRST ||
        this.targetTimeType.id == this.$config.TIME_TYPE_ID.LAST
      )
    },
    /**
     * 「出発」「到着」「始発」「終電」のうち、選択されたものを返却
     * @return 「出発」「到着」「始発」「終電」
     */
    getTimeTypeName() {
      return this.targetTimeType.name
    },
  },
  methods: {
    /**
     * ルート検索時間変更時の処理
     */
    changeRouteSearchDateTime(event) {
      this.routeSearchDateTime = event
    },
    /**
     * 出発/到着情報の変更処理
     */
    changeTargetTimeType(event) {
      // 選択したevent(id)に紐づく出発/到着情報を取得
      let timeType = this.$config.TIME_TYPE_LIST.find((type) => {
        return type.id == event
      })
      // 存在しない場合は初期値を設定する
      if (timeType == null) {
        timeType = this.$config.TIME_TYPE_LIST[this.$config.ARRAY_INDEX_ZERO]
      }
      this.targetTimeType = timeType
    },
    /**
     * 日時設定を完了するボタン押下処理
     */
    clickTimerButton() {
      // 日付設定完了を親コンポーネントに通知する
      this.$emit('completeDate', this.routeSearchDateTime, this.targetTimeType)
    },
    /**
     * 現在時刻を表示
     * 始発・終電の設定用画面で、始発or終電が選択中の場合は、タイプもリセットする
     */
    currentDate() {
      // 現在時刻を設定
      this.routeSearchDateTime = this.getNow()

      // 「出発」を設定する
      if (this.isShowFirstLastTrain && this.isFirstLastTrain) {
        this.targetTimeType =
          this.$config.TIME_TYPE_LIST[this.$config.ARRAY_INDEX_ZERO]
      }
    },
  },
}
export default CalendarSelectForm
</script>

<style></style>
