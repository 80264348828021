<template>
  <div>
    <!-- 検索結果データがない場合の文言 -->
    <div v-if="isShowNoneRoute" class="h-[186.5px] pt-2">
      <div class="text-W2 text-[13px] text-gray500 leading-[13px]">
        ルートがみつかりませんでした
      </div>
      <div class="mt-4 flex justify-center">
        <img :src="getErrorAssetsSrc()" class="h-[140px] w-[140px]" />
      </div>
    </div>
    <div v-else class="h-[202.5px]">
      <div class="table mx-auto">
        <div class="container relative w-[55px] h-[170px] table-cell">
          <!-- 電車バスカード -->
          <MobilityCard
            ref="trainCard"
            type="train"
            :summaryTime="resultTotal.summaryTime"
            :fare="resultTotal.summaryFare"
            :isAnimation="isNeedAnimation"
            @card-tap="selectMobility('trainBus')"
            @completed-circle-animation="confirmEndCircleAnimation('trainBus')"
            @completed-animation="confirmEndAnimation('trainBus')"
          />
          <!-- 車カード -->
          <MobilityCard
            ref="carCard"
            type="car"
            :summaryTime="resultCar.summaryTime"
            :fare="resultCar.additionalFare.taxi"
            :isAnimation="isNeedAnimation"
            @card-tap="selectMobility('car')"
            @completed-circle-animation="confirmEndCircleAnimation('car')"
            @completed-animation="confirmEndAnimation('car')"
          />
          <!-- 自転車カード -->
          <MobilityCard
            ref="bicycleCard"
            type="bicycle"
            :summaryTime="resultBicycle.summaryTime"
            :isAnimation="isNeedAnimation"
            :calorie="resultBicycle.totalCalories"
            @card-tap="selectMobility('bicycle')"
            @completed-circle-animation="confirmEndCircleAnimation('bicycle')"
            @completed-animation="confirmEndAnimation('bicycle')"
          />
          <!-- 徒歩カード -->
          <MobilityCard
            ref="walkCard"
            type="walk"
            :summaryTime="resultWalk.summaryTime"
            :isAnimation="isNeedAnimation"
            :calorie="resultWalk.totalCalories"
            :step="resultWalk.totalSteps"
            @card-tap="selectMobility('walk')"
            @completed-circle-animation="confirmEndCircleAnimation('walk')"
            @completed-animation="confirmEndAnimation('walk')"
          />
        </div>
      </div>
      <!-- 間の線 -->
      <div
        v-if="(!isNeedAnimation && !isRestartAnimation) || isCompleteAnimation"
        class="mt-8 w-full px-6 h-[0.5px]"
      >
        <div class="bg-gray300 w-full h-full" />
      </div>
    </div>
  </div>
</template>
<script>
import MobilityCard from '@/components/molecules/searchRoute/MobilityCard.vue'
import Util from '@/mixins/util'

// 各モビリティにて、データがない場合の初期値
const DEFAULTDATA = {additionalFare: {}}
const MobilityCardList = {
  name: 'MobilityCardList',
  mixins: [Util],
  emits: [
    'select-mobility',
    'complete-animations',
    'complete-circle-animations',
  ],
  components: {MobilityCard},
  props: {
    // getRouteTotalのレスポンス
    routeSearchResult: {
      type: Object,
    },
  },
  data() {
    return {
      isInitSearchRoute: this.$store.state.isInitSearchRoute, // 初期化フラグを保持（書き換えられる前の値を持っておく）
      completeCircleAnimations: [], // 回転アニメーションが完了したモビリティ
      completeAnimations: [], // アニメーションが完了したモビリティ
      isShowingLoading: true, // ローディングを表示中かどうか
      isRestartAnimation: false, // アニメーション再実行フラグ
    }
  },
  created() {},
  mounted() {},
  computed: {
    /**
     * ルート検索データの存在チェック
     * 検索結果が存在していない場合、trueをを返却
     * @returns {Boolean} 親から渡ってきたデータがNULLもしくわ空オブジェクトの場合、trueを返却
     */
    isNotResultData() {
      return (
        this.isNull(this.routeSearchResult) ||
        this.isEmpty(this.routeSearchResult)
      )
    },
    /**
     * 全カードの回転アニメーションが完了しているかを確認
     * @returns {Boolean} 全モビリティのアニメーションが完了した場合、trueを返却
     */
    isCompleteCircleAnimation() {
      return (
        this.completeCircleAnimations.length === 4 // eslint-disable-line no-magic-numbers
      )
    },
    /**
     * 全カードのアニメーションが完了しているかを確認
     * @returns {Boolean} 全モビリティのアニメーションが完了した場合、trueを返却
     */
    isCompleteAnimation() {
      return (
        this.completeAnimations.length === 4 // eslint-disable-line no-magic-numbers
      )
    },
    /**
     * 電車バスデータの確認と取得処理
     * データの存在チェックから該当するデータを返却
     * @returns {Object} APIのレスポンスデータ or 初期値用データ
     */
    resultTotal() {
      return !this.isNotResultData &&
        !this.isNull(this.routeSearchResult) &&
        Array.isArray(this.routeSearchResult.total) &&
        this.routeSearchResult.total[0] // eslint-disable-line no-magic-numbers
        ? this.routeSearchResult.total[0] // eslint-disable-line no-magic-numbers
        : DEFAULTDATA
    },
    /**
     * 車データの確認と取得処理
     * データの存在チェックから該当するデータを返却
     * @returns {Object} APIのレスポンスデータ or 初期値用データ
     */
    resultCar() {
      return !this.isNotResultData &&
        Array.isArray(this.routeSearchResult.carRecommend) &&
        this.routeSearchResult.carRecommend[0] // eslint-disable-line no-magic-numbers
        ? this.routeSearchResult.carRecommend[0] // eslint-disable-line no-magic-numbers
        : DEFAULTDATA
    },
    /**
     * 自転車データの確認と取得処理
     * データの存在チェックから該当するデータを返却
     * @returns {Object} APIのレスポンスデータ or 初期値用データ
     */
    resultBicycle() {
      return !this.isNotResultData &&
        Array.isArray(this.routeSearchResult.bicycle) &&
        this.routeSearchResult.bicycle[0] // eslint-disable-line no-magic-numbers
        ? this.routeSearchResult.bicycle[0] // eslint-disable-line no-magic-numbers
        : DEFAULTDATA
    },
    /**
     * 徒歩データの確認と取得処理
     * データの存在チェックから該当するデータを返却
     * @returns {Object} APIのレスポンスデータ or 初期値用データ
     */
    resultWalk() {
      return !this.isNotResultData &&
        Array.isArray(this.routeSearchResult.walk) &&
        this.routeSearchResult.walk[0] // eslint-disable-line no-magic-numbers
        ? this.routeSearchResult.walk[0] // eslint-disable-line no-magic-numbers
        : DEFAULTDATA
    },
    /**
     * アニメーション要否
     * 検索未完了でアニメーションを開始する場合はtrueを返却
     * 初回実行時以外は不要とする
     * アニメーション際実行時は影響しない
     */
    isNeedAnimation() {
      return !this.isInitSearchRoute
    },
    /**
     * ルートなし表示をするかどうか
     * ローディング表示中かつアニメーションが必要な場合はfalseを返却。
     * ローディングが非表示orアニメーションが不要な場合は、全てのモビリティの検索結果がない場合false、一つでもあればtrueを返却する
     */
    isShowNoneRoute() {
      const isShow =
        !this.isShowingLoading ||
        (!this.isNeedAnimation && !this.isRestartAnimation)
          ? !this.isNull(this.routeSearchResult) &&
            !Array.isArray(this.routeSearchResult.total) &&
            !Array.isArray(this.routeSearchResult.carRecommend) &&
            !Array.isArray(this.routeSearchResult.bicycle) &&
            !Array.isArray(this.routeSearchResult.walk)
          : false
      // ルートなし表示の場合アニメーションが終了するため、親にアニメーション完了を通知する
      if (isShow) {
        this.$emit('complete-animations')
      }
      return isShow
    },
  },
  methods: {
    /**
     * カードタップ時の通知処理
     * 選択されたモビリティを親に通知し、遷移処理を行う
     * @param {String} targetMobility モビリティ名
     */
    selectMobility(targetMobility) {
      this.$emit('select-mobility', targetMobility)
    },
    /**
     * 全カードの回転アニメーション終了時の通知処理
     * 各カードの回転アニメーション完了通知があればモビリティ名を保持し、
     * 4モビリティ分保持したら、通知する
     * @param {String} targetMobility モビリティ名
     */
    confirmEndCircleAnimation(targetMobility) {
      this.completeCircleAnimations.push(targetMobility)
      if (this.isCompleteCircleAnimation) {
        this.$emit('complete-circle-animations')
        // ローディング表示中フラグを落とす
        this.isShowingLoading = false
      }
    },
    /**
     * 全カードのアニメーション終了時の通知処理
     * 各カードのアニメーション完了通知があればモビリティ名を保持し、
     * 4モビリティ分保持したら、通知する
     * @param {String} targetMobility モビリティ名
     */
    confirmEndAnimation(targetMobility) {
      this.completeAnimations.push(targetMobility)
      if (this.isCompleteAnimation) {
        // 再実行フラグを落とす
        this.isRestartAnimation = false
        // 親にアニメーション完了を通知する
        this.$emit('complete-animations')
      }
    },
    /**
     * ローディング終了処理
     * @param {Boolean} isCancel キャンセルするならtrue
     */
    finishLoading(isCancel) {
      // 各カードにローディング終了を通知する
      this.$refs.trainCard.finishLoading(isCancel)
      this.$refs.carCard.finishLoading(isCancel)
      this.$refs.bicycleCard.finishLoading(isCancel)
      this.$refs.walkCard.finishLoading(isCancel)
    },
    /**
     * ローディング再実行処理
     */
    restartLoading() {
      // 再実行フラグを立てる
      this.isRestartAnimation = true
      // 配列初期化
      this.completeAnimations = []
      this.completeCircleAnimations = []
      // ローディング表示中フラグを立てる
      this.isShowingLoading = true
      // 各カードにローディング再実行を通知する
      this.$nextTick(() => {
        this.$refs.trainCard.restartLoading()
        this.$refs.carCard.restartLoading()
        this.$refs.bicycleCard.restartLoading()
        this.$refs.walkCard.restartLoading()
      })
    },
  },
}
export default MobilityCardList
</script>
<style></style>
