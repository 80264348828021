<template>
  <!-- 時刻表画面 -->
  <div class="w-full h-full bg-gray200">
    <div class="fixed rounded-[50%] left-[50%] header" />
    <div id="timeTableTop" class="fixed w-full h-screen flex flex-col">
      <div class="w-full flex mt-[24px] px-6 pb-2">
        <!-- 通常時モード -->
        <div v-if="!isEditMode" class="flex w-full">
          <div class="leading-[20px] text-W5 text-[20px] text-white">
            お気に入り時刻表
          </div>
          <div class="ml-4 text-W2 text-white leading-[14px] mt-auto">
            {{ favoriteTimeTables.length }}/10件
          </div>
          <div
            class="text-W4 text-[13px] text-white leading-[13px] mt-auto ml-auto"
            :class="displayEditFont()"
            @click="onClickEditMode(true)"
          >
            編集
          </div>
        </div>
        <!-- 編集時モード -->
        <div v-else class="flex min-h-[20px] w-full">
          <div
            class="w-[60px] mt-auto ml-[5px] text-W2 text-white leading-[14px] text-left"
          >
            {{ selectFavoriteTimeTable.length }}/
            {{ favoriteTimeTables.length }}件
          </div>
          <div
            class="flex-1 mt-auto text-W5 text-[15px] text-white leading-[15px]"
          >
            選択してください
          </div>
          <div
            class="w-[60px] mt-auto ml-auto text-W4 text-[13px] text-white leading-[13px] text-right"
            @click="onClickEditMode(false)"
          >
            完了
          </div>
        </div>
      </div>
      <div class="h-full overflow-y-auto flex-1">
        <div class="pb-16" id="timeTableFavoriteContents">
          <div v-if="!isEditMode">
            <!-- 通常時 -->
            <div
              class="mt-4 mx-6"
              v-for="favoriteTimeTable in favoriteTimeTables"
              :key="favoriteTimeTable.id"
            >
              <TimeTableFavoriteCard
                :favoriteTimeTable="favoriteTimeTable"
                :isEditMode="isEditMode"
                :operations="extractFavoriteOperation(favoriteTimeTable)"
                @click-favorite="onClickFavoriteCard($event)"
                @check-favorite="onCheckFavoriteCard($event)"
                @request-get-timetable="getTimeTables()"
              />
            </div>
          </div>
          <div v-else>
            <!-- 編集時 -->
            <draggable
              v-model="favoriteTimeTables"
              item-key="id"
              handle=".handle"
              ghostClass="opacity-0"
              animation="200"
            >
              <template #item="{element}">
                <div class="mt-4 mx-6">
                  <TimeTableFavoriteCard
                    :favoriteTimeTable="element"
                    :isEditMode="isEditMode"
                    :operations="extractFavoriteOperation(element)"
                    @click-favorite="onClickFavoriteCard($event)"
                    @check-favorite="onCheckFavoriteCard($event)"
                    @request-get-timetable="getTimeTables()"
                  />
                </div>
              </template>
            </draggable>
          </div>
          <!-- 時刻表追加カード -->
          <div
            v-if="!isMaxFavoriteTimeTables"
            class="mt-4 mx-6 p-[1px] bg-white rounded-[10px] shadow-normal"
          >
            <div
              class="bg-blue200 rounded-[10px] h-[135px] pt-4"
              @click="goToTimeTableSuggest()"
            >
              <img
                src="@/assets/Icon_TimeTable_Plus.svg"
                class="mx-auto w-10 h-10"
              />
              <div
                class="the-button-default shadow-normal w-[138px] h-[47px] text-[15px] text-W7 mx-auto leading-[23px] mt-4 py-3"
              >
                時刻表を追加
              </div>
            </div>
          </div>
          <div
            v-if="isZeroFavoriteTimeTables"
            class="mt-6 mx-10 rounded-[30px] text-center bg-white"
          >
            <div class="text-W5 text-[15px] leading-[24px] pt-7 px-[42px]">
              時刻表をお気に入り登録すると
              <br />
              直近の出発時刻を
              <br />
              確認できるようになります
            </div>
            <img
              class="mt-5 mx-auto w-[220px] h-[169]"
              src="@/assets/TimeTablePreview.png"
            />
          </div>
          <div class="h-[49px] w-full" />
        </div>
        <div v-if="isEditMode" class="h-[79px]"></div>
        <FooterMargin />
      </div>
    </div>
  </div>

  <!-- フッター -->
  <SelectPanel
    v-if="isEditMode"
    negativeName="選択項目を削除"
    :negativeEnabled="isEnableFooterDelete"
    @click-negative="onClickFooterDelete()"
    positiveName="時刻表を変更"
    :positiveEnabled="isOneSelectedFavorite"
    @click-positive="onClickFooterChange()"
  />

  <!-- お気に入り削除用ポップアップ -->
  <Modal
    v-if="isShowDeletePopup"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="pt-9 pb-6 mx-5">
      <div class="text-W5 text-[17px] leading-[21.5px]">
        {{ generateDeletePopupMassage }}のお気に入りを削除します。
        <br />
        よろしいですか？
      </div>
      <!-- 駅名・路線名・方面名 削除対象が1件の場合のみ表示-->
      <div
        v-if="isOneSelectedFavorite"
        class="bg-gray200 mt-4 rounded-[4px] p-5 flex flex-col text-left"
      >
        <div class="text-W5 text-[15px] leading-[15px] mb-3">
          {{ selectFavoriteTimeTable[0].name }}
        </div>
        <div class="text-W2 text-[13px] leading-[13px] text-gray500">
          {{ selectFavoriteTimeTable[0].linkName }}/{{
            selectFavoriteTimeTable[0].directionName
          }}
        </div>
      </div>
      <!-- ボタン -->
      <div class="flex justify-center mt-6 items-center">
        <div
          class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
          @click="clickCancelButton()"
        >
          キャンセル
        </div>
        <div class="w-1/2">
          <TheButton
            text="削除"
            :isDisabled="false"
            @click-button="onClickDeletePopup()"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
/**
 * 時刻表画面
 * ＜概要＞
 * 時刻表のトップ画面。
 * 入力欄をタップすることで、時刻表入力画面へ遷移する
 */
import Modal from '@/components/Modal.vue'
import Util from '@/mixins/util'
import timeTableUtil from '@/mixins/timeTableUtil'
import TimeTableFavoriteCard from '@/components/timeTable/TimeTableFavoriteCard.vue'
import SelectPanel from '@/components/SelectPanel.vue'
import draggable from 'vuedraggable'
import TheButton from '@/components/atoms/TheButton.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'

const TimeTablePage = {
  name: 'TimeTablePage',
  components: {
    Modal,
    TimeTableFavoriteCard,
    SelectPanel,
    draggable,
    TheButton,
    FooterMargin,
  },
  mixins: [Util, timeTableUtil],
  data() {
    return {
      isEditMode: false, // 編集フラグ
      isShowDeletePopup: false, // 削除ポップアップ用切り替え
      selectFavoriteTimeTable: [], // 選択中のお気に入り情報
      favoriteTimeTables: this.$store.state.TimeTableStore.favoriteTimeTables, // お気に入り情報一覧
      isGettingTimeTable: false,
    }
  },
  watch: {
    /**
     * 編集モードの状態監視
     * 複数契機で変更されるケースがあるので、監視をする
     */
    isEditMode: function () {
      // 編集モード時はフッター部を非表示にする
      this.$store.commit('updateShowFooter', !this.isEditMode)
    },
  },
  created() {
    // お気に入り時刻表データを取得する
    this.$store.commit('TimeTableStore/updateTargetFavoriteData', null)
    this.getTimeTables()
  },
  computed: {
    /**
     * お気に入り情報の0件判定
     */
    isZeroFavoriteTimeTables() {
      return this.favoriteTimeTables.length === 0 // eslint-disable-line no-magic-numbers
    },
    /**
     * お気に入り情報の最大件数
     */
    isMaxFavoriteTimeTables() {
      return (
        this.$config.MAX_FAVORITE_TIME_TABLE <= this.favoriteTimeTables.length
      )
    },
    /**
     * 編集時のフッターの削除ボタン活性状態
     */
    isEnableFooterDelete() {
      // eslint-disable-next-line no-magic-numbers
      return 0 < this.selectFavoriteTimeTable.length
    },
    /**
     * 選択中のお気に入り情報の1件判定
     */
    isOneSelectedFavorite() {
      // eslint-disable-next-line no-magic-numbers
      return this.selectFavoriteTimeTable.length === 1
    },
    /**
     * お気に入り削除ポップアップの文言を生成する
     * @return {String} 1件の場合：次
     *                  2件以上の場合：◯件
     */
    generateDeletePopupMassage() {
      return this.isOneSelectedFavorite
        ? '次'
        : `${this.selectFavoriteTimeTable.length}件`
    },
    /**
     * お気に入り登録している時刻表のデータ
     */
    favoriteOperations() {
      return this.$store.state.TimeTableStore.favoriteOperations
    },
  },
  methods: {
    /**
     * お気に入りの時刻表データを取得する
     */
    async getTimeTables() {
      // 処理中の場合は最新化処理をしない
      if (this.$store.state.TimeTableStore.isModernizeFavoriteOperations) {
        return
      }
      // 時刻表データの最新化のフラグを立てる
      this.$store.commit(
        'TimeTableStore/updateIsModernizeFavoriteOperations',
        true
      )
      try {
        // 最新化処理の実行
        await this.modernizeFavoriteOperations(
          this.favoriteTimeTables,
          this.favoriteOperations
        )
      } catch (e) {
        // 失敗した際はなにも行わない
      } finally {
        // 時刻表データの最新化中のフラグを落とす
        this.$store.commit(
          'TimeTableStore/updateIsModernizeFavoriteOperations',
          false
        )
      }
    },
    /**
     * 時刻表データを取得する
     * @param {*} favoriteTimeTable 取得対象となるお気に入り情報
     */
    extractFavoriteOperation(favoriteTimeTable) {
      const favoriteOperation = this.favoriteOperations.find(
        (item) => item.id == favoriteTimeTable.id
      )
      // eslint-disable-next-line no-magic-numbers
      if (!favoriteOperation) {
        // 該当する時刻表データがない場合
        return []
      }
      return favoriteOperation.data.operation
    },
    /**
     * 時刻表入力画面に遷移
     */
    goToTimeTableSuggest() {
      this.$router.push({
        name: 'TimeTableSuggest',
      })
    },
    /**
     * お気に入り情報コンポーネントのクリック処理
     * 選択されたお気に入り情報をキーに時刻表詳細画面に遷移する
     * @param {*} selectedFavoriteTimeTable 選択されたお気に入り情報
     */
    onClickFavoriteCard(selectedFavoriteTimeTable) {
      if (this.isEditMode) {
        return
      }
      // ローディング開始
      this.$store.commit('startLoading')
      // 検索日時の生成
      const startDate = this.getFormatYearDate()

      // 成功時処理
      const success = (result) => {
        this.$store.commit('endLoading')
        // 画面遷移処理を実行
        this.gotoTimeTableDetail(result, startDate, selectedFavoriteTimeTable)
      }

      // 時刻表取得API実行
      this.$store.dispatch('TimeTableStore/getTimeTables', {
        success: success,
        // ノードID
        nodeId: selectedFavoriteTimeTable.node,
        // 路線ID
        linkId: selectedFavoriteTimeTable.link,
        // 検索日付
        startDate: startDate,
      })
    },
    /**
     * お気に入り情報コンポーネントのチェックボタンクリック処理
     * チェックの押されたお気に入り情報を保持する
     * @param {*} checkedFavoriteTimeTable 対象お気に入り情報のチェック状況
     */
    onCheckFavoriteCard(checkedFavoriteTimeTable) {
      // 編集中の場合、コンポーネントのチェック状況を保持する
      const isChecked = checkedFavoriteTimeTable.clicked
      const favoriteTimeTable = checkedFavoriteTimeTable.favoriteTimeTable
      if (isChecked) {
        this.selectFavoriteTimeTable.push(favoriteTimeTable)
      } else {
        this.selectFavoriteTimeTable = this.selectFavoriteTimeTable.filter(
          (timeTable) => {
            // 一致するデータは削除する(falseを返却する)
            return !this.checkExistDiagram(timeTable, favoriteTimeTable)
          }
        )
      }
    },
    /**
     * 時刻表詳細画面への遷移処理
     * 必要データをStoreに保持した後、画面遷移を行う
     * @param {*} result 路線検索結果
     * @param {String} startDate 出発日
     * @param {*} selectedFavoriteTimeTable 選択されたお気に入りデータ
     */
    gotoTimeTableDetail(result, startDate, selectedFavoriteTimeTable) {
      // 時刻表情報IDはstoreに保存
      this.$store.commit(
        'TimeTableStore/updateSelectedTimeTableId',
        selectedFavoriteTimeTable.id
      )
      // 検索日時はstoreに保存
      this.$store.commit('TimeTableStore/updateSearchDate', startDate)
      // 方面選択情報をStoreに保存
      this.$store.commit('TimeTableStore/updateDirectionInfo', {
        linkId: selectedFavoriteTimeTable.link,
        direction: selectedFavoriteTimeTable.operation,
        stationType: selectedFavoriteTimeTable.type,
      })
      // 時刻表検索結果をstoreに保存
      this.$store.commit('TimeTableStore/updateTimeTableResult', result)

      // 時刻表詳細画面に遷移
      this.$router.push({name: 'TimeTableDetail'})
    },
    /**
     * 編集モード切り替え処理
     * お気に入り情報が0件の場合は、何も処理しない
     */
    onClickEditMode(editMode) {
      if (this.isZeroFavoriteTimeTables) {
        // お気に入り情報が0件の場合は、何も処理しない
        return
      }
      this.isEditMode = editMode
      if (!this.isEditMode) {
        // ストレージのお気に入り一覧を更新
        const updateData = this.updateBulkFavoriteTimeTable(
          this.favoriteTimeTables
        )
        // Storeのお気に入り一覧を更新
        this.$store.commit('TimeTableStore/updateFavoriteTimetable', updateData)
      }
      // 選択中のデータは初期化
      this.selectFavoriteTimeTable = []
    },
    /**
     * お気に入りカード削除用ポップアップ画面展開処理
     */
    onClickDeleteCard(selectedFavoriteTimeTable) {
      // 削除選択中のお気に入り情報を更新
      this.selectFavoriteTimeTable = selectedFavoriteTimeTable
      //削除ポップアップを表示する
      this.isShowDeletePopup = true
    },
    /**
     * 「編集」の透明度変更
     * お気に入り件数が0件の場合、文字の透明度を40%にする
     */
    displayEditFont() {
      return this.isZeroFavoriteTimeTables ? 'opacity-[.40]' : 'opacity-100'
    },
    /**
     * 削除キャンセル処理
     * ポップアップを非表示にする
     */
    clickCancelButton() {
      this.isShowDeletePopup = false
    },
    /**
     * お気に入りカード削除処理
     * 選択されたお気に入りカードの情報を削除する
     */
    onClickDeletePopup() {
      // 削除用ローカルストレージの戻り値を保持
      const localDeleteData = this.deleteFavoriteTimeTable(
        this.selectFavoriteTimeTable
      )

      // ストレージ、Storeに削除後のデータを反映
      this.$store.commit(
        'TimeTableStore/updateFavoriteTimetable',
        localDeleteData
      )
      // 時刻表データ削除
      this.deleteDiagram()

      // 削除選択中のお気に入り情報をクリアする
      this.selectFavoriteTimeTable = []
      // ポップアップを閉じる
      this.isShowDeletePopup = false
      // 編集モードを解除
      this.isEditMode = false

      // お気に入りのデータを最新化する
      this.favoriteTimeTables = localDeleteData
    },
    /**
     * 編集時のフッターの削除ボタン押下処理
     */
    onClickFooterDelete() {
      this.isShowDeletePopup = true
    },
    /**
     * 編集時のフッターの時刻表変更ボタン押下処理
     */
    onClickFooterChange() {
      this.$store.commit(
        'TimeTableStore/updateTargetFavoriteData',
        // eslint-disable-next-line no-magic-numbers
        this.selectFavoriteTimeTable[0]
      )
      this.goToTimeTableSuggest()
    },
    /**
     * 時刻表データの削除処理
     * IndexedDBとStoreにある時刻表データを削除する
     */
    deleteDiagram() {
      // StoreとIndexedDBにある該当の時刻表データを削除
      this.selectFavoriteTimeTable.forEach((target) => {
        this.deleteOperationsToDbAndStore(target.id)
      })
    },
  },
}
export default TimeTablePage
</script>
<style scoped>
.header {
  width: calc(100vw * 1.5);
  height: calc(100vw * 1.5 * 0.4);
  top: calc(((100vw * 1.5 * 0.4) - 138px) * -1);
  background: linear-gradient(#61c7f7, #009ce5);
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}
/* フッター */
.footer {
  transform: translateZ(1px);
}
.text-area {
  height: 40px;
  width: 100%;
  border-radius: 26px;
  font-size: 15px;
  padding: 12px 16px;
  line-height: 16px;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}
.text-area::placeholder {
  color: #979ca1;
  font-weight: 300;
}
#timeTableFavoriteContents::-webkit-scrollbar {
  /* スクロールバー非表示 */
  display: none;
}
</style>
