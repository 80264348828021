<template>
  <div id="app-page" ontouchstart="">
    <!-- ローディング -->
    <div v-if="loadingFlag" id="loading" class="center loading-area">
      <Loading />
    </div>

    <!-- コンテンツ -->
    <div id="appContents" class="absolute inset-0">
      <!-- マップ -->
      <RouteMap v-if="isShowRouteMap" ref="routeMap" type="route" />
      <div id="routerViewArea">
        <router-view />
      </div>
    </div>
    <!-- フッター -->
    <Footer v-if="isShowFooter" />
    <!-- エラーメッセージを表示するモーダル -->
    <Modal
      v-if="hasErrorKey"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="center px-5 pt-9 pb-6">
        <div class="text-W5 text-[17px] mb-3">
          {{ errorTitle }}
        </div>
        <div class="text-W3 text-[15px] pb-5">
          {{ errorMessage }}
        </div>
        <div
          class="text-W7 bg-primary rounded-[8px] text-white text-[15px] leading-[15px] py-4 mx-auto"
          @click="closeErrorModal()"
        >
          OK
        </div>
      </div>
    </Modal>
    <!-- 利用規約同意ポップアップ -->
    <NamoTermModal
      v-if="isShowTermsAgreementPopup"
      :isRevisionMode="isRevisionMode"
      @agree-term="agreeWithTerm()"
    />
    <!-- 利用規約改訂日取得失敗モーダル -->
    <Modal
      v-if="isFailedFetchRevisionDate"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="center px-5 pt-9 pb-6">
        <div class="text-W5 text-[17px] mb-3">エラーが発生しました。</div>
        <div class="text-W3 text-[15px] pb-5">
          通信環境をご確認いただくか、時間を置いて再度お試しください。
        </div>
        <div
          class="text-W7 bg-primary rounded-[8px] text-white text-[15px] leading-[15px] py-4 mx-auto"
          @click="closeWidelyUsedErrorModal()"
        >
          もどる
        </div>
      </div>
    </Modal>
    <!-- 閉局エラーモーダル -->
    <Modal
      v-if="isShowClosedErrorModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-6">
        <div class="text-W5 text-[17px] mb-3">
          この機能はメンテナンス中です。
        </div>
        <div class="text-W3 text-[15px] mb-5">
          <template v-if="finalLimitDate">
            <p class="mb-1">
              終了予定時刻：{{ formatLimitDate(finalLimitDate) }}
            </p>
            申し訳ございませんが、しばらくお待ちください。
          </template>
          <template v-else>
            申し訳ございませんが、時間を置いて再度お試しください。
          </template>
        </div>
        <TheButton text="OK" @click-button="closeClosedErrorModal()" />
      </div>
    </Modal>
    <!-- 画面ロック用レイヤー -->
    <div v-if="screenLockFlag" class="screen-lock-layer"></div>
  </div>
</template>

<script>
import Footer from './components/organisms/Footer.vue'
import Loading from '@/components/Loading.vue'
import RouteMap from '@/components/RouteMap.vue'
import Modal from '@/components/Modal.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import NamoTermModal from '@/components/organisms/NamoTermModal.vue'
import Util from '@/mixins/util'
import NativeUtil from '@/mixins/nativeUtil.js'
import {isAuthenticated, logout} from '@/auth/AuthorizationInteractor.js'
import {setWatchPositionHandler} from '@/utils/locationUtil'
import dayjs from 'dayjs'

const ERROR_TYPES = [
  {
    key: 'networkError',
    title: '通信エラー',
    message: `通信エラーが発生しました。
      通信環境をご確認のうえ、
      再度実行してください。`,
  },
  {
    key: 'otherError',
    title: 'エラー',
    message: `問題が発生したため、
    処理を中断しました。`,
  },
  {
    key: 'invalidAccessToken',
    title: 'セッションの有効期限が切れました。',
    message: 'もう一度ログインしてください。',
  },
]

const App = {
  name: 'App',
  components: {
    Footer,
    Loading,
    RouteMap,
    Modal,
    TheButton,
    NamoTermModal,
  },
  mixins: [Util, NativeUtil],
  data() {
    return {
      isShowRouteMap: false, // 地図表示フラグ
      isAuthLogin: false,
      isShowTermsAgreementPopup: false, // 利用規約同意ポップアップ表示フラグ
      isRevisionMode: false, // 再同意モード
      isFailedFetchRevisionDate: false, // 利用規約最終改訂日取得失敗フラグ
    }
  },
  created() {
    this.fetchLatestTermsRevisionDate()
    this.addGMOGetTokenScript()
  },
  async mounted() {
    this.executeWatchPositionHandler()
    // WebViewからのアクセスの場合はNativeから呼び出される関数をwindowに定義
    if (this.isWebView()) {
      this.defineNativeEvent()
      this.defineWindowResumeEvent()
      this.defineFetchingStatusMethodInWindow()
      this.addResumeEvent(this.getNotificationMessages)
    }
    this.isAuthLogin = await isAuthenticated()
    // 無操作タイムアウトのセットアップを行う
    this.setupNoOperationTimeout()
  },
  unmounted() {
    // resizeイベント破棄
    window.removeEventListener('resize', this.handleResize)
    window.visualViewport.removeEventListener('resize', this.handleResize)
    // IndexedDBクローズ処理
    this.$store.dispatch('IndexedDbStore/closeDatabase')
  },
  watch: {
    /**
     * パスの変更のタイミングでマップ表示切り替えを行う
     */
    currentPath: {
      immediate: true,
      handler(newVal) {
        this.updateSearchRouteTab(newVal)
        this.hiddenFooter(newVal)
      },
    },
  },
  computed: {
    /**
     * 現在の画面名を返却
     * @returns {String} 画面名
     */
    getCurrentDisplay() {
      return this.$route.name
    },
    /**
     * routeのパスを取得
     */
    currentPath() {
      return this.$route.path
    },
    /**
     * フッター表示状態を取得
     */
    isShowFooter() {
      return this.$store.state.isShowFooter && !this.$store.state.isShowKeyboard
    },
    /**
     * ローディング表示判定
     */
    loadingFlag() {
      return this.$store.state.loadingFlag
    },
    /**
     * エラー情報
     */
    error() {
      return ERROR_TYPES.find((error) => {
        return error.key === this.$store.state.errorKey
      })
    },
    /**
     * エラーのキーが有効か確認
     * return true: 有効, false: 無効
     */
    hasErrorKey() {
      return ERROR_TYPES.some((error) => {
        return error.key === this.$store.state.errorKey
      })
    },
    /**
     * エラータイトルを取得
     */
    errorTitle() {
      return this.error?.title || ''
    },
    /**
     * エラーメッセージを取得
     */
    errorMessage() {
      return this.error?.message || ''
    },
    /**
     * 選択中のフッタータブID
     */
    selectedFooterTab() {
      return this.$store.state.selectedFooterTab
    },
    /**
     * スポット機能の中のマップを表示しない画面であるか
     */
    isNoDisplayMapOfSpot() {
      const noDisplayNameList = ['/RouteByOptimal', '/RouteByTrainBus']
      return noDisplayNameList.includes(this.currentPath)
    },
    /**
     * 画面ロック用判定
     */
    screenLockFlag() {
      return this.$store.state.screenLockFlag
    },
    /**
     * 閉局の最終制限時間
     */
    finalLimitDate() {
      return this.$store.getters['getFinalLimitDate']
    },
    /**
     * 閉局エラー表示用フラグ
     */
    isShowClosedErrorModal() {
      return this.$store.state.isShowClosedErrorModal
    },
  },
  methods: {
    /**
     * お知らせ通知取得処理
     */
    getNotificationMessages() {
      this.$store.dispatch('getNotificationMessages', {
        // 取得成功時とエラーレスポンス時は何も行わない
        error: () => {},
        failed: () => {},
      })
    },
    /**
     * 位置情報取得処理開始
     */
    async executeWatchPositionHandler() {
      await setWatchPositionHandler(
        (position) => {
          // 取得成功
          // 位置情報をStoreに保持
          this.$store.commit('updateCurrentPosition', {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          })
        },
        () => {
          // 取得失敗
          this.$store.commit('updateCurrentPosition', {
            lat: null,
            lon: null,
          })
        }
      )
    },
    /**
     * エラーモーダルを閉じる
     */
    async closeErrorModal() {
      // セッション切れの場合
      if (this.error?.key === 'invalidAccessToken') {
        // 再ログインをしてもらうためauth0の強制ログアウトを行う
        await logout({force: true})
      } else {
        this.$store.commit('errorModal', '')
      }
    },
    /**
     * ルートマップの切り替え処理
     * フッターの選択状態に応じて切り替える
     * ・ルート検索(Load画面以外)：表示
     * ・上記以外：非表示
     */
    updateSearchRouteTab(path) {
      // ルート検索タブのIDを取得
      const searchRouteTabId = this.$config.FOOTER_LIST.find((footer) => {
        return footer.name == 'spot'
      }).id

      const noDisplayMapPath = [
        '/',
        '/Welcome', // ようこそ画面
        '/SetupEmailVerified', // メール送信完了
        '/SetupLoginMethod', // ログイン設定画面
        '/SetupUserInfo', // ユーザー情報登録画面
        '/SetupUserInfoFinished', // ユーザー情報登録完了画面
      ]
      this.isShowRouteMap =
        // ルート検索タブで、マップを表示しない画面は除外
        searchRouteTabId == this.selectedFooterTab &&
        !noDisplayMapPath.includes(path)
    },
    /**
     * フッターの非表示処理
     * 対象の画面は、フッターを表示させない
     * @param {String} path 現在表示している画面のパス
     */
    hiddenFooter(path) {
      const targetScreen = [
        '/',
        '/Welcome', // ようこそ画面
        '/SetupEmailVerified', // メール送信完了
        '/SetupLoginMethod', // 次回ログイン方法設定画面
        '/SetupUserInfo', // ユーザー情報登録画面
        '/SetupUserInfoFinished', // ユーザー情報登録完了画面
        '/UserInfoDetail', // ユーザー情報入力画面
        '/BicycleReservationTop', // 自転車予約TOP画面
        '/BicycleReservationPortOnMap', // ポート選択（マップ）画面
        '/BicycleReservationSelect', // 自転車選択画面
        '/BicycleReservationPaymentConfirm', // 支払確認画面
        '/BicycleReservationConfirmed', // 予約確定画面
        '/BicycleReservationReturnPortOnMap', // 返却ポート選択（マップ）画面
        '/BicycleReservationReturnPortDetail', // 返却ポート詳細画面
        '/TaxiReservationTop', // タクシー予約TOP画面
        '/TaxiReservationConfirm', // タクシー予約内容確認画面
        '/TaxiReservationPaymentConfirm', // タクシー支払確認画面
        '/TaxiReservationChat', // タクシーチャット画面
        '/TaxiReservationCompleted', // タクシー手配完了画面
        '/InsuranceClaimsLogin', // 保険金請求ログイン画面
        '/InsuranceClaimsTop', // 保険金請求Top画面
        '/InsuranceClaimDetail', // 請求候補登録・編集画面
        '/InsuranceClaimConfirmed', // 請求手続き確認画面
        '/SuicaTop', // SuicaTop画面
        '/SuicaDetail', // Suica詳細画面
        '/PaymentMethodSelect', // 支払い方法選択画面
        '/PaymentMethodManagement', // 支払い方法管理画面
        '/RegisterCreditCard', // お支払い方法を登録画面
        '/TimetableLicenseInfo', // 時刻表ライセンス情報画面
        '/NotificationList', // お知らせ一覧画面
        '/NotificationDetail', // お知らせ詳細画面
      ]
      this.$store.commit('updateShowFooter', !targetScreen.includes(path))
    },
    /**
     * Nativeから呼ばれるイベントを定義する
     */
    defineNativeEvent() {
      window.updateIsShowKeyboard = (isShowKeyboard) => {
        this.$store.commit('updateIsShowKeyboard', isShowKeyboard)
      }
    },
    /**
     * 手配情報取得処理をwindowメソッドとして読み込ませる処理
     */
    defineFetchingStatusMethodInWindow() {
      // 最新手配情報取得処理
      window.fetchLatestArrangementStatus = () => {
        const vm = this
        const isNotArrangingTaxi =
          !vm.$store.getters['MobilityReservationStore/isArrangingTaxiStatus']
        /**
         * TODO:
         * 不具合に対する一時的対応。
         * タクシー支払確認画面でgetMobilityInfoが実行されてしまうことで、予期しない挙動になる不具合が発生。
         * 他への影響を考慮し、該当の画面では実行しないよう直接要因に対し対応。
         */
        const isIgnorePage = [
          this.$config.DISPLAY_TAXI_RESERVATION_PAYMENT_CONFIRM,
        ].includes(this.getCurrentDisplay)
        if (vm.isAuthLogin && isNotArrangingTaxi && !isIgnorePage) {
          // ログイン済み & タクシー手配中でない場合は最新手配情報取得
          vm.$store.dispatch('MobilityReservationStore/getMobilityInfo', {})
        }
      }
    },
    /**
     * 利用規約最新改訂日の取得を行う
     */
    fetchLatestTermsRevisionDate() {
      const vm = this
      const success = () => {
        vm.showTermsAgreementPopupByNeeded()
      }

      const failed = () => {
        vm.isFailedFetchRevisionDate = true
      }

      const error = () => {
        vm.isFailedFetchRevisionDate = true
      }

      vm.$store.dispatch('getAppSettings', {
        success: success,
        failed: failed,
        error: error,
      })
    },
    /**
     * 利用規約改訂日と同意日を比較し、初回同意・再同意ポップアップを表示する
     */
    showTermsAgreementPopupByNeeded() {
      // 必要項目取得
      const lastAgreedTermsRevisionDate = this.getLocalStorage(
        this.$config.LAST_AGREED_TERMS_REVISION_DATE
      )
      const latestTermsRevisionDate =
        this.$store.state.appSettings.latestRevisionDate

      // ローカルストレージ内の最終同意日がない場合は初回同意ポップアップ表示
      if (this.isNull(lastAgreedTermsRevisionDate)) {
        this.isShowTermsAgreementPopup = true
        return
      }

      // サーバー上の利用規約改訂日が最後に同意された規約の改訂日と異なる場合は再同意ポップアップ表示
      if (latestTermsRevisionDate !== lastAgreedTermsRevisionDate) {
        this.isRevisionMode = true
        this.isShowTermsAgreementPopup = true
      }
    },
    /**
     * 規約同意後の処理
     */
    agreeWithTerm() {
      // 最後に同意した規約の改訂日を更新する
      this.setLocalStorage(
        this.$config.LAST_AGREED_TERMS_REVISION_DATE,
        this.$store.state.appSettings.latestRevisionDate
      )
      // ポップアップを非表示
      this.isShowTermsAgreementPopup = false
    },
    /**
     * 利用規約改訂日取得失敗モーダルを閉じる
     */
    closeWidelyUsedErrorModal() {
      this.isFailedFetchRevisionDate = false
      this.fetchLatestTermsRevisionDate()
    },
    /**
     * GMOのトークン取得スクリプトを追加
     */
    addGMOGetTokenScript() {
      const url = process.env.VUE_APP_GMO_GET_TOKEN_SCRIPT_URL
      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('src', url)
      script.setAttribute('async', true)
      document.head.appendChild(script)
    },
    /**
     * 無操作タイムアウトのセットアップ
     */
    setupNoOperationTimeout() {
      // Nativeから呼ばれるイベントを登録する
      window.addEventListener('no-operation-timeout', async () => {
        const isLogin = this.$store.state.user.auth.isLogin
        if (!isLogin) {
          // 未ログイン時はそのまま利用可能
          return
        }

        const [AUTO_AUTH, DEVICE_AUTH, EMAIL_PW_AUTH] =
          this.$config.LOGIN_SETTINGS
        // ログイン方法ごとに処理分け
        const loginMethod = this.$store.state.user.loginMethod
        switch (loginMethod) {
          case DEVICE_AUTH.KEY:
            // スマホの解除方法の場合は生体認証実行
            this.doBioAuth()
            break
          case EMAIL_PW_AUTH.KEY:
            // メアド、パスワードの場合は強制ログアウトさせる
            this.setLocalStorage(this.$config.FORCE_RE_LOGIN, true)
            await logout()
            break
          case AUTO_AUTH.KEY:
            // 自動認証の場合は、そのまま利用可能。
            break
          default:
        }
      })
    },
    /**
     * 無操作タイムアウト時の生体認証
     */
    doBioAuth() {
      // 生体認証成功時は何もしない
      const success = () => {}
      // 生体認証失敗時は強制ログアウト
      const failed = async () => {
        await logout({force: true})
      }
      this.defineGetBioAuthResultMethod(success, failed)
      this.linkNativeCallback('bioAuth')
    },
    /**
     * 閉局の最終制限時間を指定しての形式に変換する
     * @param {String} limitDate 閉局の最終制限時間
     * @returns 想定する形式に変換されたメ閉局の最終制限時間
     */
    formatLimitDate(limitDate) {
      return dayjs(limitDate).format('YYYY/MM/DD HH:mm:ss')
    },
    /**
     * 閉局エラーモーダルを閉じる
     */
    closeClosedErrorModal() {
      this.$store.commit('updateIsShowClosedErrorModal', false)
      this.$store.commit('updateClosedDataList', [])
    },
  },
}
export default App
</script>

<style>
/** 
  共通部かつtailwindへの記載が難しい共通スタイルを下記に記載
 */
#app-page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center;
  color: #2c3e50;
}
/* 共通フォント指定 */
/*
 * 注意
 * public配下の capy.html は text-WX クラス が使用できないため注意
 * 全体的な変更を行う際は一緒に変更が必要
 */
.text-W2 {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
}
.text-W3 {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
}
.text-W4 {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
}
.text-W5 {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Medium';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
}
.text-W6 {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Bold';
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
}
.text-W7 {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Bold';
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1c21;
}
img {
  -webkit-user-drag: none;
}
#routerViewArea {
  pointer-events: none;
  position: absolute;
  top: var(--top-safe-area-height);
  bottom: var(--bottom-safe-area-height);
  right: 0;
  left: 0;
}
#routerViewArea > * {
  pointer-events: auto;
  overflow-y: auto;
}
.fixed-app {
  position: fixed;
  height: calc(
    100vh - var(--top-safe-area-height) - var(--bottom-safe-area-height)
  );
  width: 100%;
}
.pt-safe-area {
  padding-top: var(--top-safe-area-height);
}
.mt-safe-area {
  margin-top: var(--top-safe-area-height);
}
.pb-safe-area {
  padding-bottom: var(--bottom-safe-area-height);
}
.mb-safe-area {
  margin-bottom: var(--bottom-safe-area-height);
}
:root {
  --top-safe-area-height: 0px;
  --bottom-safe-area-height: 0px;
}
</style>
