<template>
  <div class="px-1.5 pb-1.5">
    <div class="text-W7 text-left text-[15px] leading-[26px]">
      この場所でよろしいですか？
    </div>
    <!-- 目的地選択 -->
    <BaseBox class="mt-1.5">
      <template v-slot:explanation>
        <div
          class="flex flex-row items-center bg-blue200 rounded-[4px] py-[6px] px-3"
        >
          <div class="flex flex-1 flex-col grid">
            <div class="flex flex-row items-center">
              <img
                src="@/assets/arrangementIcon/taxi/IconDropOffPin.svg"
                class="h-[13px] w-2.5"
              />
              <div class="ml-1.5 text-W4 text-xs leading-[18px] text-gray500">
                目的地
              </div>
            </div>
            <div
              class="mt-[2px] text-[12px] leading-[18px] h-[18px] truncate"
              :class="addressNameFieldClass"
            >
              {{ addressNameFieldText }}
            </div>
          </div>
        </div>
      </template>
    </BaseBox>
    <TheButton
      text="ここに行く"
      class="mt-4"
      @click-button="$emit('click-go-there')"
      :isDisabled="isButtonDisabled"
    />
  </div>
</template>
<script>
import BaseBox from '@/components/atoms/BaseBox.vue'
import TheButton from '@/components/atoms/TheButton.vue'

/**
 * この場所でよろしいですか?コンポーネント
 */
const TaxiReservationDestinationConfirm = {
  name: 'TaxiReservationDestinationConfirm',
  components: {TheButton, BaseBox},
  emits: ['click-go-there'],
  props: {
    // 目的地
    dropOff: {
      Type: Object,
      require: true,
    },
    // ボタン非活性フラグ
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
    // 住所名表示フラグ
    isShowAddressNameText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /**
     * 住所が設定されていないかどうかを返却
     */
    isNoSettingDropOffAddress() {
      return this.dropOff.address == ''
    },
    /**
     * 住所名表示エリアに設定する追加クラスを返却
     */
    addressNameFieldClass() {
      return this.isNoSettingDropOffAddress ? 'text-W2 text-gray' : 'text-W6'
    },
    /**
     * 住所名表示エリアに表示するテキストを返却
     */
    addressNameFieldText() {
      if (!this.isShowAddressNameText) {
        return ''
      }
      return this.isNoSettingDropOffAddress
        ? '住所が取得できません'
        : this.dropOff.address
    },
  },
}
export default TaxiReservationDestinationConfirm
</script>
<style scoped></style>
