<template>
  <!-- 乗り換えTOP画面 -->
  <div class="fixed top-0 flex flex-col bg-gray200 h-screen w-full">
    <!-- ヘッダー -->
    <div class="bg-white pt-safe-area h-[47px] flex justify-center px-5">
      <div class="text-W7 text-[15px] leading-[15px] my-auto">乗り換え案内</div>
    </div>
    <!-- 検索条件項目 -->
    <div class="bg-white px-5 pt-2 pb-6">
      <!-- 出発 -->
      <div class="flex items-center">
        <div
          class="flex-1 border-[1px] border-gray300 rounded-t-[8px] overflow-hidden whitespace-nowrap"
          @click="goToTransferSuggest(spotType.start, null)"
        >
          <div class="flex items-center p-4 text-W4 text-[15px] leading-[15px]">
            <img
              src="@/assets/route/Icon_Optimal_Start.svg"
              class="mr-2 h-3 w-3"
            />
            <div class="text-ellipsis overflow-hidden">
              <span v-if="transferConditions.start.name">
                {{ transferConditions.start.name }}
              </span>
              <span v-else class="text-gray">駅・バス停・空港・港</span>
            </div>
          </div>
        </div>
        <!-- ルート検索最適画面で古い画像の方を参照しているため、新しくV2を用意 -->
        <div class="ml-2 w-10 h-10" @click="exchangeSpot()">
          <img class="ml-1 mt-1 w-8 h-8" src="@/assets/Icon_ExchangeV2.svg" />
        </div>
      </div>
      <!-- 経由地 -->
      <div v-for="spot in viaSpots" :key="spot.id">
        <div class="flex items-center">
          <div
            class="flex-1 border-[1px] border-t-0 border-gray300 overflow-hidden whitespace-nowrap"
            @click="goToTransferSuggest(spotType.via, spot.id)"
          >
            <div
              class="flex items-center p-4 text-W4 text-[15px] leading-[15px]"
            >
              <img
                src="@/assets/route/Icon_Optimal_Via.svg"
                class="mr-2 h-3 w-3 items-center"
              />
              <div class="text-ellipsis overflow-hidden">
                <span v-if="spot.name">
                  {{ spot.name }}
                </span>
                <span v-else class="text-gray">駅・バス停・空港・港</span>
              </div>
            </div>
          </div>
          <div class="ml-2 w-10 h-10 flex items-center justify-center">
            <img
              class="h-4"
              src="@/assets/route/Icon_Optimal_Via_Delete_Button.svg"
              @click="deleteViaInputBox(spot.id)"
            />
          </div>
        </div>
      </div>
      <!-- 到着 -->
      <div class="flex items-center">
        <div
          class="flex-1 border-[1px] border-t-0 border-gray300 rounded-b-[8px] overflow-hidden whitespace-nowrap"
          @click="goToTransferSuggest(spotType.goal, null)"
        >
          <div class="flex items-center p-4 text-W4 text-[15px] leading-[15px]">
            <img
              src="@/assets/route/Icon_Optimal_Goal.svg"
              class="mr-2 h-3 w-3 items-center"
            />
            <div class="text-ellipsis overflow-hidden">
              <span v-if="transferConditions.goal.name">
                {{ transferConditions.goal.name }}
              </span>
              <span v-else class="text-gray">駅・バス停・空港・港</span>
            </div>
          </div>
        </div>
        <div
          :class="displayViaAddMaker"
          class="ml-2 w-10 h-10"
          @click="addViaInputBox()"
        >
          <!-- ルート検索最適画面で古い画像の方を参照しているため、新しくV2を用意 -->
          <img class="ml-1 mt-1 w-8 h-8" src="@/assets/PlusCircleV2.svg" />
        </div>
      </div>
      <!-- 日時設定・検索ボタン -->
      <div class="flex mt-4 items-center justify-between">
        <div class="basis-3/5 h-12 border-[1px] border-gray300 rounded-[8px]">
          <DateTime
            class="m-4"
            :targetTime="getConditionsTime"
            :timeType="getConditionsTimeType"
            :additionalDownArrowClass="'ml-2'"
            @click="onClickDate()"
          />
        </div>

        <TheButton
          class="basis-2/5 leading-[15px] w-[118px] ml-2"
          text="検索"
          :isDisabled="!isSearchValid()"
          @click-button="search()"
        />
      </div>
    </div>
    <!-- 履歴項目 -->
    <div class="w-full px-5 mt-6">
      <div class="flex z-30">
        <div class="text-W7 text-[13px] leading-[13px]">履歴から再検索</div>
        <div class="bg-[#E8374A] w-2 h-2 rounded-[50%] ml-4 my-auto" />
        <div class="text-W3 leading-3 mt-[1px] ml-1">経由地設定あり</div>
      </div>
    </div>
    <div class="h-0 relative">
      <!-- ぼかし -->
      <div
        class="scroll-top-blur w-full h-4 z-20 absolute top-[-1px] left-0"
      ></div>
    </div>
    <div class="w-full flex-1 overflow-y-auto px-5 scroll-bar-none">
      <!-- 履歴データがある場合 -->
      <div v-if="isExistConditionsHistory">
        <!-- 履歴データのカード表示 -->
        <div
          class="mt-4"
          v-for="history in conditionsHistory"
          :key="history.id"
        >
          <ConditionsHistoryCard
            class="shadow-normal"
            :start="history.start"
            :via="history.via"
            :goal="history.goal"
            @click="search(history)"
          />
        </div>
        <!-- 余白分 -->
        <div class="w-full h-[64px] mt-[18px]"></div>
      </div>
      <!-- 履歴データがない場合 -->
      <div v-else class="mt-12 text-W2 text-gray500 text-[13px]">
        履歴がありません
      </div>
    </div>
    <FooterMargin />
  </div>

  <!-- 時刻設定ポップアップ -->
  <Modal
    class="modal"
    v-if="isShowCalenderSelectModal"
    @disPlayModal="disPlayModal"
    modalPaddingBottom="8px"
  >
    <div id="select-datetime-modal">
      <CalendarSelectForm
        @completeDate="completeDate"
        :initialDateTime="transferConditions.targetTime"
        :initialTargetType="transferConditions.timeType"
        :isShowFirstLastTrain="true"
      />
    </div>
  </Modal>
</template>
<script>
/**
 * 乗り換えTOP画面
 * ＜概要＞
 * ・乗り換え画面に最初に表示する画面
 * ・経由地の有無や数によって表示内容や高さが動的に変化する
 */
import Util from '@/mixins/util'
import TransferUtil from '@/mixins/transferUtil'
import Modal from '@/components/Modal.vue'
import CalendarSelectForm from '@/components/CalendarSelectForm.vue'
import DateTime from '@/components/atoms/DateTime.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import ConditionsHistoryCard from '@/components/molecules/transfer/ConditionsHistoryCard.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'

// エラーメッセージ定数
const ROUTE_NOT_FOUND_MESSAGE = 'The specified route is not found.'
const EXCEED_DISTANCE_LIMIT_MESSAGE =
  'This route does not observe the distance limit.'

const TransferTop = {
  name: 'TransferTop',
  components: {
    Modal,
    CalendarSelectForm,
    DateTime,
    TheButton,
    ConditionsHistoryCard,
    FooterMargin,
  },
  mixins: [Util, TransferUtil],
  data() {
    return {
      spotType: {
        start: this.$config.SPOT_TYPE_START, // 出発地用のKey名
        via: this.$config.SPOT_TYPE_VIA, // 経由地地用のKey名
        goal: this.$config.SPOT_TYPE_GOAL, // 目的地用のKey名
      },
      isShowCalenderSelectModal: false, //時刻設定ポップアップ表示フラグ
      conditionsHistory: this.getConditionsHistory(), // 検索履歴一覧
    }
  },
  computed: {
    /**
     * storeに格納した経由地情報取得
     */
    viaSpots() {
      return this.transferConditions.via
    },
    /**
     * 検索条件取得
     */
    transferConditions() {
      return this.$store.state.TransferStore.transferConditions
    },
    /**
     * 経由地の表示確認
     * @param なし
     * @returns {Boolean} true:経由地表示している状態、false:経由地が非表示の状態
     */
    isViaSpots() {
      return this.$config.ARRAY_INDEX_ZERO < this.viaSpots.length
    },
    /**
     * ＋ボタンの表示切替
     * @param なし
     * @returns {String} 表示枠数が3の場合、色が薄くなる
     */
    displayViaAddMaker() {
      return this.viaSpots.length < this.$config.VIA_DATA_MAX_NUMBER
        ? ''
        : 'opacity-40'
    },
    /**
     * 日時情報を取得する
     * @return {String} 日時情報
     */
    getConditionsTime() {
      return this.transferConditions.targetTime
    },
    /**
     * 出発/到着/始発/終電情報を取得する
     * @return {Map} 出発/到着/始発/終電情報(id,name)
     */
    getConditionsTimeType() {
      return this.transferConditions.timeType
    },
    /**
     * 検索履歴が存在するかどうか
     * @return {Boolean} 履歴が1件以上ある場合:true それ以外:false
     */
    isExistConditionsHistory() {
      // eslint-disable-next-line no-magic-numbers
      return this.conditionsHistory != null && 0 < this.conditionsHistory.length
    },
  },
  methods: {
    /**
     * 駅名検索画面に遷移
     */
    goToTransferSuggest(key, viaId) {
      this.$router.push({
        name: 'TransferSuggest',
        params: {key: key, viaId: viaId},
      })
    },
    /**
     * 出発地と到着地を交換する
     */
    exchangeSpot() {
      this.$store.commit('TransferStore/exchangeSpots')
    },
    /**
     * 経由地入力枠の追加
     */
    addViaInputBox() {
      if (this.viaSpots.length < this.$config.VIA_DATA_MAX_NUMBER) {
        this.$store.commit('TransferStore/addVia', {
          id: Date.now(),
          name: '',
          address: '',
          nodeId: '',
          ruby: '',
          type: '',
        })
      }
    },
    /**
     * 経由地入力枠の削除
     * @param {String} target 削除対象の経由地情報のID
     */
    deleteViaInputBox(target) {
      this.$store.commit('TransferStore/deleteVia', target)
    },
    /**
     * ルート検索実行
     * @param {*} historyData 選択した履歴データ
     */
    search(historyData) {
      if (historyData) {
        // 履歴を選択した場合は、地点を検索条件に反映する
        this.$store.commit('TransferStore/updateTransferConditions', {
          key: 'start',
          value: historyData.start,
        })
        this.$store.commit('TransferStore/updateTransferConditions', {
          key: 'via',
          value: historyData.via,
        })
        this.$store.commit('TransferStore/updateTransferConditions', {
          key: 'goal',
          value: historyData.goal,
        })
        // 履歴カードから検索を行う場合は、現在時刻と出発に更新して検索する
        this.$store.commit('TransferStore/updateTransferConditions', {
          key: 'targetTime',
          value: this.getNow(),
        })
        this.$store.commit('TransferStore/updateTransferConditions', {
          key: 'timeType',
          value: {id: 1, name: '出発'},
        })
      }

      const vm = this
      const success = (result) => {
        // 成功時処理
        // 経由地が空白の場合は削除する
        // 検索履歴の保持の前に空白を除去する必要があるため、このタイミングで行う。
        const newViaSpots = this.transferConditions.via.filter((via) => {
          return via.name != ''
        })
        this.$store.commit('TransferStore/updateTransferConditions', {
          key: 'via',
          value: newViaSpots,
        })

        // 検索結果の保持
        vm.$store.commit('TransferStore/updateTransferSearchResult', result)
        // 検索履歴の保持
        const historyData = {
          id: Date.now().toString(),
          start: this.transferConditions.start,
          via: this.transferConditions.via,
          goal: this.transferConditions.goal,
        }
        vm.registerTransferConditionsHistory(historyData)
        // ローディング終了
        vm.$store.commit('endLoading')
        // 検索結果一覧表示
        vm.$router.push({name: 'TransferResult'})
      }
      const failed = (res) => {
        vm.$store.commit('TransferStore/updateTransferSearchResult', [])
        vm.$store.commit('endLoading')

        // 経路が見つからないか、距離制限を超えている場合は画面遷移する
        const message = res.body.param?.message
        if (
          message === ROUTE_NOT_FOUND_MESSAGE ||
          message === EXCEED_DISTANCE_LIMIT_MESSAGE
        ) {
          // 検索結果一覧表示
          vm.$router.push({name: 'TransferResult'})
          return
        }

        throw new Error()
      }
      const error = (e) => {
        // 失敗時処理
        vm.$store.commit('TransferStore/updateTransferSearchResult', [])
        vm.$store.commit('endLoading')
        throw e
      }

      vm.$store.commit('startLoading')

      vm.$store.dispatch('TransferStore/getRouteTotal', {
        success: success,
        failed: failed,
        error: error,
      })
    },
    /**
     * 検索条件のバリデーションチェック
     * 有効：true
     * 無効：false
     */
    isSearchValid() {
      const start = this.transferConditions.start.name
      const goal = this.transferConditions.goal.name

      // 出発地・到着地未設定チェック
      return start != '' && goal != ''
    },
    /**
     * 日時設定押下時処理
     */
    onClickDate() {
      this.disPlayModal(true, this.$config.MODAL_CALENDER_SELECT)
    },

    /**
     * Modal表示処理
     * @param {Boolean} isShow Modalを表示するかしないか
     * @param {String} modalKey 表示、非表示するModalの種類
     */
    disPlayModal(isShow, modalKey) {
      switch (modalKey) {
        // 時刻設定ポップアップ
        case this.$config.MODAL_CALENDER_SELECT:
          this.isShowCalenderSelectModal = isShow
          break
        default:
          // 共通処理(defaultの場合は一律非表示にする)
          this.isShowCalenderSelectModal = false
          break
      }
    },
    /**
     * 日付選択完了時処理
     */
    completeDate(dateTime, targetType) {
      // 乗り換え検索時に使用する時間を保持
      this.$store.commit('TransferStore/updateTransferConditions', {
        key: 'targetTime',
        value: dateTime,
      })
      // 検索条件に反映
      this.$store.commit('TransferStore/updateTransferConditions', {
        key: 'timeType',
        value: targetType,
      })

      // ローカルストレージへ保存
      this.setLocalStorage(this.$config.TRANSFER_DATE, {
        searchType: targetType,
        date: dateTime,
      })

      // ポップアップを非表示にする
      this.disPlayModal(false, this.$config.MODAL_CALENDER_SELECT)
    },
  },
}
export default TransferTop
</script>
<style scoped>
.modal {
  transform: translateZ(1px);
}
/* スクロール領域上部のぼかし */
.scroll-top-blur {
  background: linear-gradient(0deg, #f2f3f5 0%, #f2f3f5 20%, #f2f3f500 100%) 0%
    0% no-repeat padding-box;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}
</style>
