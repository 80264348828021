<template>
  <svg viewBox="0 0 12 12">
    <g transform="translate(-123 -1055)">
      <rect
        width="12"
        height="12"
        transform="translate(123 1055)"
        fill="none"
      />
      <path
        d="M207.769-743l-.769-.769L211.231-748,207-752.231l.769-.769L212-748.769,216.231-753l.769.769L212.769-748,217-743.769l-.769.769L212-747.231Z"
        transform="translate(-83 1809)"
        fill="#1a1c21"
      />
    </g>
  </svg>
</template>
