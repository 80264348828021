<template>
  <div id="suicaTop">
    <!-- 緑枠 -->
    <div class="flex-col h-full w-full full-display pt-4">
      <div class="flex">
        <img
          class="ml-[68px] h-[44px]"
          src="@/assets/suica/SuicaLinkSuggestion.svg"
        />
        <div class="ml-auto mr-5 w-4">
          <img
            src="@/assets/IconCloseBlack.svg"
            class="h-4"
            @click="clickClose()"
          />
        </div>
      </div>
      <div class="flex justify-center items-end mt-4 mx-4">
        <img
          src="@/assets/suica/SuicaLinkTop.png"
          class="h-full w-full aspect-[4/5]"
        />
      </div>
    </div>
    <!-- 白枠 -->
    <div class="bg-white py-8">
      <div class="mx-10 text-W4 text-[15px] leading-[15px]">
        <div class="flex items-center">
          <img class="h-3 w-3 mr-2" src="@/assets/IconCheckMarkBlue.svg" />
          連携ボタンを押すだけで設定完了！
        </div>
        <div class="flex items-center mt-3">
          <img class="h-3 w-3 mr-2" src="@/assets/IconCheckMarkBlue.svg" />
          ルート検索時に残高を表示
        </div>
        <div class="flex items-center mt-3">
          <img class="h-3 w-3 mr-2" src="@/assets/IconCheckMarkBlue.svg" />
          移動中の
          <span class="text-W7 text-[15px] leading-[15px]">
            チャージ不足に気づきやすい
          </span>
        </div>
      </div>
      <!-- ボタン -->
      <div class="mx-6 mt-8">
        <TheButton
          text="モバイルSuicaを連携する"
          class="mb-3"
          @click-button="clickCollaboration()"
        />
        <div
          class="flex relative w-full justify-center items-center text-W7 text-[15px] leading-[15px] the-button-secondary rounded-[8px] px-6 py-4"
          @click="clickNewIssued()"
        >
          モバイルSuicaを新規発行
          <IconLink class="w-3 h-3 ml-2" />
        </div>
      </div>
    </div>
    <!-- Suica連携連携対象なしエラー -->
    <Modal
      v-if="suicaCardNotFound"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-6">
        <div class="text-W5 text-[17px] mb-3">
          連携できるモバイルSuicaがありませんでした。
        </div>
        <div
          class="text-W7 the-button-default text-[15px] leading-[15px] py-4 mx-auto"
          @click="suicaCardNotFound = false"
        >
          もどる
        </div>
      </div>
    </Modal>
    <!-- Suica連携エラー -->
    <Modal
      v-if="networkError"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 font-semibold text-[17px] leading-[25.5px]">
          エラーが発生しました。
        </div>
        <div class="mt-3 mx-5 text-W3 text-[15px] leading-[23px]">
          通信環境をご確認いただくか、時間を置いて再度お試しください。
        </div>
        <div
          class="mt-5 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
          @click="networkError = false"
        >
          もどる
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import SuicaUtil from '@/mixins/suicaUtil'
import TheButton from '@/components/atoms/TheButton.vue'
import Modal from '@/components/Modal.vue'
import IconLink from '@/components/icons/common/IconLink.vue'
import suica from '@/utils/suicaNativeUtil'

const SuicaTop = {
  name: 'SuicaTop',
  components: {TheButton, Modal, IconLink},
  mixins: [SuicaUtil],
  data() {
    return {
      /**
       * Suica連携対象なしフラグ
       */
      suicaCardNotFound: false,
      /**
       * Suica連携失敗フラグ
       */
      networkError: false,
      /**
       * Suica機能アクセス中フラグ
       */
      isSuicaAccessing: false,
    }
  },
  methods: {
    /**
     * 連携ボタン押下処理
     */
    async clickCollaboration() {
      // 多重クリック防止
      if (this.isSuicaAccessing) return
      this.$store.commit('startLoading')
      try {
        this.isSuicaAccessing = true
        try {
          await suica.linkSuica()
          this.$router.push({name: this.$config.DISPLAY_SUICA_DETAIL})
        } catch (e) {
          // 閉局中エラーの場合
          if (
            e?.response?.data?.statusCode ===
            this.$config.API_STATUS_CODE_SERVICE_UNAVAILABLE
          ) {
            throw e
          } else if (e.message == 'Not found SuicaCardData') {
            // 連携できるSuicaがありませんメッセージ表示
            this.suicaCardNotFound = true
          } else {
            // エラーメッセージ表示
            this.networkError = true
          }
        }
      } finally {
        this.$store.commit('endLoading')
        this.isSuicaAccessing = false
      }
    },
    /**
     * 新規発行ボタン押下処理
     */
    async clickNewIssued() {
      // Suicaアプリを開く
      await suica.openSuicaApp()
    },
  },
}
export default SuicaTop
</script>

<style scoped>
.full-display {
  background-color: #6eba2b;
}
</style>
