<template>
  <div>
    <!-- お支払い方法あり -->
    <div
      v-if="hasPriorityCard"
      class="rounded-[10px] border border-gray300 border-solid"
    >
      <div class="flex items-center justify-between flex-wrap p-4">
        <div class="flex items-center">
          <div class="mr-2">
            <img
              class="h-8 w-8"
              src="@/assets/payment/cardLogo/IconCreditCard.svg"
            />
          </div>
          <div class="mr-2 text-W2 text-[15px]">
            {{ priorityCard.cardNumber }}
          </div>
          <div class="text-W2 text-[15px]">{{ priorityCard.expire }}</div>
        </div>

        <div class="flex-grow flex justify-end">
          <div
            class="bg-gray200 text-W7 text-primary rounded-[8px] w-[50px] text-W5 text-[13px] leading-[29px]"
            @click="$emit('change-credit-card')"
          >
            変更
          </div>
        </div>
      </div>
    </div>

    <!-- お支払い方法なし -->
    <div v-else class="rounded-[10px] border border-gray300 border-solid">
      <div class="flex items-center justify-between flex-wrap p-4">
        <div class="flex items-center">
          <div class="mr-2">
            <img
              class="h-8 w-8"
              src="@/assets/payment/cardLogo/IconCreditCard.svg"
            />
          </div>
          <div class="text-gray400 text-W2 text-[15px] text-left">
            登録がありません
          </div>
        </div>

        <div class="flex-grow flex justify-end">
          <div
            class="bg-primary text-W7 text-white rounded-[8px] w-[50px] text-W5 text-[13px] leading-[29px]"
            @click="$emit('register-credit-card')"
          >
            追加
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 選択中のお支払い情報表示コンポーネント
 */
const SelectedPaymentCard = {
  name: 'SelectedPaymentCard',
  props: {
    // 優先使用されるクレジットカード情報
    priorityCard: {
      type: Object,
      required: true,
    },
    // お支払い方法保持しているか
    hasPriorityCard: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['change-credit-card', 'register-credit-card'],
}
export default SelectedPaymentCard
</script>
