<template>
  <BaseMap
    ref="BaseMap"
    :mapElementId="mapElementId"
    :isTouchMap="isTouchMap"
    :defaultCenter="defaultCenter"
    :mapSize="mapSize"
    :centerOffset="centerOffset"
    :copyrightBottomSpace="copyrightBottomSpace"
    @finish-generate-map="finishGenerateMap()"
  />
</template>
<script>
/**
 * UserLocation付きMap
 */
import BaseMap from '@/components/atoms/BaseMap.vue'
import Util from '@/mixins/util.js'
import canvasUtil from '@/mixins/canvasUtil'
import nativeUtil from '@/mixins/nativeUtil'

// 向いている方向画像のサイズ
const DIRECTION_SIZE = {width: 128, height: 128}

const MapWithUserLocation = {
  name: 'MapWithUserLocation',
  components: {BaseMap},
  mixins: [Util],
  props: {
    mapElementId: String,
    isTouchMap: {
      type: Boolean,
      default: true,
    },
    defaultCenter: {
      type: Object,
      default: {},
    },
    centerOffset: {
      type: Object,
      default: {
        lat: 0,
        lon: 0,
      },
    },
    mapSize: {
      type: Object,
      default: {},
    },
    copyrightBottomSpace: {
      type: Number,
    },
    isSetTrackingMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      watchPositionId: -1,
      userLocationData: null,
      isFirstLocationData: true,
      timeoutId: null,
      userIconMarker: null, // ユーザーアイコンマーカー
      headingIconInfo: null, // 方向アイコン
    }
  },
  computed: {
    /**
     * BaseMap内のマップインスタンスを取得
     */
    map() {
      return this.$refs.BaseMap.$data.frozen.mapRelated.map
    },
    /**
     * ストアに保持しているユーザアイコン
     */
    storeUserIcon() {
      return this.$store.state.user.appInfo.icon
    },
    /**
     * ユーザーアイコンサイズ
     */
    userIconSize() {
      return this.$store.state.user.appInfo.userIconSize
    },
    /**
     * ユーザーの位置情報を取得
     */
    currentPosition() {
      return this.$store.state.currentPosition
    },
  },
  watch: {
    /**
     * 位置情報が変われば、マップ上のアイコンを更新する
     */
    currentPosition: function () {
      // 現在地が書き変わるタイミングで更新
      this.updateCurrentPosition()
    },
  },
  mounted() {
    window.addEventListener('heading-change', this.onHeadingChange)
  },
  beforeUnmount() {
    this.clearTimeout()
    window.removeEventListener('heading-change', this.onHeadingChange)
  },
  methods: {
    /**************************************
     * ユーザーアイコン付きマップ独立の関数
     **************************************/
    /**
     * マップ生成後処理
     */
    finishGenerateMap() {
      // トラッキング設定する場合はモードをfollowにする
      if (this.isSetTrackingMode) {
        this.map.setTrackingMode('follow')
      }
      // 位置情報反映
      this.updateCurrentPosition()
      // ポートのアイコンを配置
      this.$emit('finish-generate-map')
    },
    /**
     * 現在地情報を定期取得し、ピンの描画を更新
     */
    async updateCurrentPosition() {
      const map = this.map
      this.clearTimeout()

      // mapscriptが読み込まれていない場合、一定時間後再度実行。
      if (!this.isExistsMap()) {
        const waitTimer = 1000
        this.timeoutId = setTimeout(() => {
          this.timeoutId = null
          this.updateCurrentPosition()
        }, waitTimer)
        return
      }

      /* eslint-disable no-undef */

      // 最新の現在位置のインスタンスを生成
      const updateLatLon = new mapscript.value.LatLng(
        this.currentPosition.lat,
        this.currentPosition.lon
      )

      // ユーザーアイコンのインスタンスを作成・更新
      if (this.userLocationData == null) {
        // 初回はインスタンスを生成
        this.userLocationData = new mapscript.value.UserLocationData(
          updateLatLon, // 緯度軽度
          this.$config.ZERO // 方角
        )
      } else {
        // 作成済みの場合は緯度軽度を更新
        this.userLocationData.setLatlng(updateLatLon)
      }

      // マップにユーザーアイコンを反映
      if (this.isFirstLocationData) {
        const trackingMode = map.getTrackingMode()
        // 初回 位置情報更新フラグを落とす
        this.isFirstLocationData = false
        const icon = await canvasUtil.methods.imageToBase64(
          require('@/assets/map/UserPoint.svg'),
          DIRECTION_SIZE
        )
        // await後にマップが失われていた場合は後続処理を行わない（画面遷移等）
        if (!this.isExistsMap()) {
          return
        }
        const userPoint = new mapscript.value.GLMarkerIconInfo({
          icon,
          size: DIRECTION_SIZE,
          gravity: 'center',
          isHighResolution: true,
        })
        map.setUserLocation(
          new mapscript.object.UserLocation({info: userPoint})
        )
        map.setUserLocationData(this.userLocationData)
        map.setCenter(updateLatLon)
        map.setTrackingMode(trackingMode)
        this.createUserIconMarker(updateLatLon)
        nativeUtil.methods.getHeading()
      } else {
        // ２回目以降 位置情報更新
        const duration = 1
        const easing = {calculateAnimationProgress: (t) => t}
        const animation = new mapscript.value.animation.AnimationOption(
          duration,
          easing
        )
        this.userIconMarker?.setPosition(updateLatLon, animation)
        map.setUserLocationData(this.userLocationData, true)
      }
      /* eslint-enable no-undef */
    },
    /**
     * ユーザーアイコンの生成
     * @param {*} position 生成時のアイコンの位置
     */
    async createUserIconMarker(position) {
      const map = this.map
      const size = this.$config.USER_ICON_SIZE_LIST[this.userIconSize].VALUE

      // 切り替えサイズ数分のユーザーアイコンを生成する
      const icon = await canvasUtil.methods.createUserIcon(
        this.storeUserIcon,
        size.width,
        size.offsetY
      )
      // await後にマップが失われていた場合は後続処理を行わない（画面遷移等）
      if (!this.isExistsMap()) {
        return
      }

      const info = new window.mapscript.value.GLMarkerIconInfo({
        icon: icon,
        size: size,
        isHighResolution: true,
      })
      const marker = new window.mapscript.object.GLMarker({
        position: position,
        info: info,
      })
      this.userIconMarker = marker

      // アイコンをマップに追加する
      map.addGLMarker(marker)
    },
    /**
     * 現在地に画面を移動させる
     */
    currentSpotFit() {
      if (this.userLocationData) {
        const latLon = this.userLocationData.getLatlng()
        this.setCenter(latLon.lat, latLon.lng)
      }
    },
    /**
     * タイムアウト設定をクリアする
     */
    clearTimeout() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
    },
    /**************************************
     * BaseMap関数のオーバーライド
     **************************************/
    putPin(name, latitude, longitude, size, icon) {
      return this.$refs.BaseMap.putPin(name, latitude, longitude, size, icon)
    },
    setCenter(lat, lon) {
      return this.$refs.BaseMap.setCenter(lat, lon)
    },
    setCenterAndZoomDefault(lat, lon) {
      return this.$refs.BaseMap.setCenterAndZoomDefault(lat, lon)
    },
    getCenter() {
      return this.$refs.BaseMap.getCenter()
    },
    callFitWithLatLon(lat, lon) {
      return this.$refs.BaseMap.callFitWithLatLon(lat, lon)
    },
    fit(spotList) {
      return this.$refs.BaseMap.fit(spotList)
    },
    fitMultipleLatLon(spots) {
      return this.$refs.BaseMap.fitMultipleLatLon(spots)
    },
    isExistsMap() {
      return this.$refs?.BaseMap?.isExistsMap() ?? false
    },
    /**
     * 向いている方向（コンパス）の変更イベント
     */
    async onHeadingChange(event) {
      /* eslint-disable no-undef */
      // マップが存在しない場合は何もしない
      if (!this.isExistsMap()) {
        return
      }
      const map = this.map

      // 方角または位置情報が取得できない場合は何もしない
      const heading = event.detail.heading
      if (
        heading == null ||
        !this.currentPosition.lat ||
        !this.currentPosition.lon
      ) {
        return
      }
      if (this.headingIconInfo == null) {
        const icon = await canvasUtil.methods.imageToBase64(
          require('@/assets/map/UserPointDirection.svg'),
          DIRECTION_SIZE
        )
        // await後にマップが失われていた場合は後続処理を行わない（画面遷移等）
        if (!this.isExistsMap()) {
          return
        }
        const headingIconInfo = new mapscript.value.GLMarkerIconInfo({
          icon,
          size: DIRECTION_SIZE,
          gravity: 'center',
          isHighResolution: true,
        })
        map.setUserLocation(
          new mapscript.object.UserLocation({info: headingIconInfo})
        )
        this.headingIconInfo = headingIconInfo
      }
      if (this.userLocationData) {
        this.userLocationData.setHeading(heading)
        map.setUserLocationData(this.userLocationData, false)
      }
      /* eslint-enable no-undef */
    },
  },
}
export default MapWithUserLocation
</script>
<style scoped></style>
