export default {
  methods: {
    /**
     * 閉じるアイコン押下処理
     */
    clickClose() {
      const screenTransitionSource =
        this.$store.state.SuicaStore.screenTransitionSource
      const nextDisplayName = screenTransitionSource.displayName
      const nextFooterId = screenTransitionSource.footerId

      this.$store.commit('updateFooterTab', nextFooterId)
      this.$router.push({
        name: nextDisplayName,
      })
    },
  },
}
