<template>
  <div class="number-form">
    <div class="flex items-baseline">
      <!-- 円マーク -->
      <span class="mr-2 text-W7 text-[22px] leading-[18px]" :class="fontColor">
        ￥
      </span>
      <!-- 数値入力フォーム -->
      <input
        id="amountFormInput"
        ref="amountFormInput"
        class="text-W7 bg-white amountForm"
        :class="fontColor + ' ' + inputClass"
        type="text"
        maxlength="15"
        :value="commaSeparatedValue"
        :placeholder="placeholder"
        :disabled="isDisabled"
        inputmode="numeric"
        @focus="isEditing = true"
        @blur="changeTypeOnBlur()"
        @input="onInput($event)"
        @keydown.e.prevent
        @keydown.-.prevent
      />
    </div>
    <!-- 下線 -->
    <div
      class="mt-3 border-b border-solid border-gray300"
      :class="underLineColor"
    />
  </div>
</template>

<script>
import Util from '@/mixins/util'

const AmountForm = {
  name: 'AmountForm',
  props: {
    // プレースホルダー
    placeholder: {
      type: String,
      default: '入力',
    },
    // 付与クラス
    inputClass: {
      type: String,
      default: '',
    },
    // inputタグの操作可否状態
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // v-model
    modelValue: {
      type: Number,
      required: true,
    },
    // 下線の色
    underLineColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isEditing: false, // 編集中フラグ
    }
  },
  computed: {
    /**
     * コンマ区切りの数値
     * nullかundefinedの場合はnull
     */
    commaSeparatedValue() {
      // Number型に変換すると0となってしまうためnullを明示的に代入
      // isNullだと0も含む上、nullとundefined両方判定したいため==で比較
      if (this.modelValue == null) {
        return null
      }

      const numberModelValue = Number(this.modelValue)
      // 編集中はv-modelの数値そのまま、編集中でない場合はコンマ区切りの数値
      return this.isEditing
        ? numberModelValue
        : Util.methods.addCommaForInteger(numberModelValue)
    },
    /**
     * 入力値が空かどうか判定
     * nullかundefinedの場合はtrue
     */
    isEmptyInput() {
      // isNullだと0も含む上、nullとundefined両方判定したいため==で比較
      return this.commaSeparatedValue == null
    },
    /**
     * フォントカラー
     */
    fontColor() {
      return this.isDisabled || this.isEmptyInput
        ? 'text-gray400 disabled:opacity-100'
        : 'text-gray600'
    },
  },
  methods: {
    /**
     * フォーカスアウト時の処理
     * 編集中フラグをfalseに切り替える
     */
    changeTypeOnBlur() {
      this.isEditing = false
      this.$emit('blur')
    },
    /**
     * 入力時の処理
     * 数値入力時は小数点も取り除いた数値を取得する
     * undefined(空)の場合はnullを返却
     * <例>
     * ・valueが11.11 -> 1111
     * ・valueが11 -> 11
     * ・valueがundefined -> null
     * ・valueがnull -> null
     */
    onInput(event) {
      let value = event.target.value
      let returnValue = null

      // 数値が入っている場合は小数点を切り落として返却
      if (value != null) {
        value = value.toString().replace(/[^0-9]/g, '')
        returnValue = Number(value)
      }

      this.$refs.amountFormInput.value = returnValue
      this.$emit('update:modelValue', returnValue)
    },
  },
}

export default AmountForm
</script>
<style scoped>
.amountForm {
  border: none;
  outline: none;
  line-height: 18px;
  font-size: 22px;
  width: 100%;
  -webkit-appearance: none;
  font-weight: 700;
}
</style>
