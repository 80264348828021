<template>
  <div id="notification-detail" class="fixed-app flex flex-col bg-gray200">
    <!-- ヘッダー部 -->
    <Header
      headerTitle="お知らせ"
      :isShowPrevious="true"
      @clickPrevious="gotoNotificationInfo()"
    />
    <div class="overflow-y-auto scroll-bar-none flex-1 px-5 pt-5 pb-10">
      <div class="bg-white p-4 text-left rounded-[8px]">
        <!-- 配信開始日 -->
        <div class="flex items-center h-[17px] mb-1">
          <div class="text-W4 text-[13px] text-gray400 leading-[21px] mr-2">
            {{ getFormatYearDateSlash(message.distributionStartDate) }}
          </div>
          <NotificationImportantTag v-if="message.important" class="h-full" />
        </div>
        <!-- 件名 -->
        <div class="text-W7 text-[20px] leading-[28px] break-words">
          {{ message.title }}
        </div>
        <!-- 線 -->
        <div class="border-gray300 border-b-[1px] my-4" />
        <!-- 本文 -->
        <div class="text-W4 text-[15px] leading0[24px] whitespace-pre-line break-words">
          {{ message.body }}
        </div>
      </div>
    </div>
    <FooterMargin />
  </div>
</template>

<script>
import Header from '@/components/organisms/Header.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import NotificationImportantTag from '@/components/icons/menu/NotificationImportantTag.vue'
import Util from '@/mixins/util'

/**
 * お知らせ通知詳細画面
 */
const NotificationDetail = {
  name: 'NotificationDetail',
  components: {Header, FooterMargin, NotificationImportantTag},
  mixins: [Util],
  computed: {
    /**
     * お知らせ通知一覧
     */
    message: {
      get() {
        return this.$store.state.selectedNotificationMessage
      },
    },
  },
  mounted() {
    // 画面表示されたタイミングで、対象のメッセージを既読済み判定にする
    this.readMessage()
  },
  unmounted() {
    // 選択していたお知らせ情報を初期化する
    this.$store.commit('clearSelectedNotificationMessage')
  },
  methods: {
    /**
     * お知らせ一覧画面に遷移する
     */
    gotoNotificationInfo() {
      this.$router.push({name: this.$config.DISPLAY_NOTIFICATION_LIST})
    },
    /**
     * お知らせ既読化処理
     */
    readMessage() {
      // ローカルストレージから既読済みメッセージ一覧を取得
      const key = this.$config.READ_NOTIFICATION_MESSAGES
      const readMessageIdList = this.getLocalStorage(key) || []

      // 既に既読済みの場合は何もしない
      if (readMessageIdList.some((id) => id === this.message.messageId)) return

      // 既読済み一覧に追加し、ローカルストレージに更新
      readMessageIdList.push(this.message.messageId)
      this.setLocalStorage(key, readMessageIdList)
    },
  },
}
export default NotificationDetail
</script>

<style></style>
