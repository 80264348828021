<template>
  <div name="GetoffPosition">
    <!-- 前・中・後 -->
    <div v-if="isTypeFrontMiddleBack" class="flex">
      <!-- 前 -->
      <div v-if="isFindKey('前')">
        <!-- 前がおすすめに含まれている場合 -->
        <img
          class="w-[58px] h-[21px]"
          src="@/assets/getOffIcon/Icon_Front.svg"
        />
      </div>
      <div v-else>
        <!-- 含まれていない場合 -->
        <img
          class="w-8 h-[15px] mt-1.5"
          src="@/assets/getOffIcon/Icon_Front_Gray.svg"
        />
      </div>
      <!-- 中 -->
      <div v-if="isFindKey('中')">
        <!-- おすすめの場合 -->
        <img
          class="w-[52px] h-[21px] ml-1"
          src="@/assets/getOffIcon/Icon_Middle.svg"
        />
      </div>
      <div v-else>
        <!-- 含まれていない場合 -->
        <img
          class="w-8 h-[15px] mt-1.5 ml-1"
          src="@/assets/getOffIcon/Icon_Middle_Gray.svg"
        />
      </div>
      <!-- 後 -->
      <div v-if="isFindKey('後')">
        <!-- おすすめの場合 -->
        <img
          class="w-[58px] h-[21px] ml-1"
          src="@/assets/getOffIcon/Icon_Back.svg"
        />
      </div>
      <div v-else>
        <!-- 含まれていない場合 -->
        <img
          class="w-8 h-[15px] mt-1.5 ml-1"
          src="@/assets/getOffIcon/Icon_Back_Gray.svg"
        />
      </div>
    </div>
    <!-- 数字の場合 -->
    <div v-else class="flex flex-wrap mb-[-4px]">
      <div class="text-W4 text-[14px] leading-[14px] text-gray500 mt-0.5">
        前から
      </div>
      <!-- 数字 -->
      <div v-for="num in getoffNumArray" :key="num" class="pb-1">
        <div v-if="isSingleDigitNumber(num)">
          <!-- １〜９の場合 -->
          <img
            class="w-5 h-4.5 ml-0.5"
            :src="require(`@/assets/getOffIcon/Icon_No${num}.svg`)"
          />
        </div>
        <div v-else-if="isMoreDoubleDigitNumber(num)">
          <!-- １０以上の場合 -->
          <img
            class="w-[26px] h-4.5 ml-0.5"
            :src="require(`@/assets/getOffIcon/Icon_No${num}.svg`)"
          />
        </div>
        <div
          v-else
          class="text-W4 text-[14px] leading-[14px] text-gray500 mt-0.5 ml-0.5"
        >
          〜
        </div>
      </div>
      <div
        class="text-W4 text-[14px] leading-[14px] text-gray500 mt-0.5 ml-0.5"
      >
        車両目
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/mixins/util'
const GetoffPosition = {
  name: 'GetoffPosition',
  mixins: [Util],
  props: {
    /**
     * 降車推奨位置情報
     * パターン１：'1・2・３'
     * パターン２：'1、2、３'
     * パターン３：'前、中、後'
     * パターン４：'前・中・後'
     */
    getoff: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      getoffArray: this.getoff.split(/、|・/g), // 降車推奨位置を配列データとして変換
    }
  },
  computed: {
    /**
     * 乗車位置が前中後で表記されているか判別する
     * @returns true: 前中後で判別されている
     */
    isTypeFrontMiddleBack() {
      return (
        this.getoffArray.find(
          (key) => key === '前' || key === '中' || key === '後'
        ) != undefined
      )
    },
    /**
     * 乗車位置の数字を半角に変換し、連番部分を〜に変換した数字配列を生成・返却する
     * @returns 変換後の乗車位置配列
     */
    getoffNumArray() {
      const ONE = 1
      let resultArray = []

      for (let i = 0; i < this.getoffArray.length; i++) {
        // 全角数字を半角数字および数値に変換
        const num = this.fullNumberToNumber(this.getoffArray[i])

        // 連番チェック
        if (ONE <= i && i < this.getoffArray.length - ONE) {
          //添字が2番目〜最後の一つ前までの場合
          const prevNum = this.fullNumberToNumber(this.getoffArray[i - ONE]) // 一つ前の値
          const nextNum = this.fullNumberToNumber(this.getoffArray[i + ONE]) // 一つ後の値

          // 前後の値との差分がそれぞれ1の場合、連番と判定する
          if (num - prevNum == ONE && nextNum - num == ONE) {
            // 連番の場合、〜が格納済みかチェック
            if (resultArray[resultArray.length - ONE] != '〜') {
              // 格納済みでない場合、〜を格納する
              resultArray.push('〜')
            }
            continue
          }
        }
        // 数値を格納する
        resultArray.push(num)
      }
      return resultArray
    },
  },
  methods: {
    /**
     * 乗車位置に任意の値が含まれているか判別する
     * @param {String} val 任意の値
     * @returns true: 含まれている
     */
    isFindKey(val) {
      return this.getoffArray.find((key) => key === val) != undefined
    },
    /***
     * 対象の値が数字か判別する
     * @param {Number} val 任意の値
     * @returns true: 数字であること
     */
    isNumber(num) {
      return typeof num === 'number'
    },
    /**
     * 引数が1桁の数字かどうかを判定する
     */
    isSingleDigitNumber(num) {
      // eslint-disable-next-line no-magic-numbers
      return this.isNumber(num) && num < 10
    },
    /**
     * 引数が2桁以上の数字かどうかを判定する
     */
    isMoreDoubleDigitNumber(num) {
      // eslint-disable-next-line no-magic-numbers
      return this.isNumber(num) && 10 <= num
    },
  },
}
export default GetoffPosition
</script>

<style></style>
