<template>
  <div>
    <!-- 登録値 -->
    <div class="flex justify-between mx-9 mt-2 mb-5" ref="favoriteList">
      <!-- 現在地 -->
      <div
        class="flex flex-col items-center w-[66px]"
        @click="onClickFavorite(favoriteCurrent)"
      >
        <div><IconFavoriteCurrent class="w-8 h-8" /></div>
        <div class="mt-1 text-W4 text-[11px] leading-none text-primary">
          {{ favoriteCurrent.name }}
        </div>
      </div>
      <!-- 自宅 -->
      <div
        class="flex flex-col items-center w-[66px]"
        @click="onClickFavorite(favoriteHome)"
      >
        <div><IconFavoriteHome class="w-8 h-8" /></div>
        <div class="mt-1 text-W4 text-[11px] leading-none text-primary">
          {{ favoriteHome.name }}
        </div>
      </div>
      <!-- 会社/学校 -->
      <div
        class="flex flex-col items-center w-[66px]"
        @click="onClickFavorite(favoriteWork)"
      >
        <div><IconFavoriteOfficeSchool class="w-8 h-8" /></div>
        <div class="mt-1 text-W4 text-[11px] leading-none text-primary">
          {{ favoriteWork.name }}
        </div>
      </div>
      <!-- お気に入り -->
      <div
        class="flex flex-col items-center w-[66px]"
        @click="onClickFavorite(favoriteList)"
      >
        <div><IconFavoriteRibbon class="w-8 h-8" /></div>
        <div class="mt-1 text-W4 text-[11px] leading-none text-primary">
          {{ favoriteList.name }}
        </div>
      </div>
    </div>
    <Teleport to="body">
      <!-- お気に入り一覧表示 -->
      <Teleport to="body" :disabled="isShowFooter">
        <SuggestFavoriteSpotsList
          v-if="isShowFavoriteSpotOverlay"
          :favoriteSpotList="favoriteSpotList"
          @onClickPrevious="isShowFavoriteSpotOverlay = false"
          @onClickFavoriteCard="onClickFavoriteSpot($event)"
          @onClickAddFavorite="goToFavoriteSuggest(favoriteList.id)"
        />
      </Teleport>
      <!-- お気に入り未登録ポップアップ -->
      <Modal
        v-if="isShowNoRegisterFavoriteModal"
        class="modal text-center"
        :isShowCloseButton="false"
        :isModalCenter="true"
        modalPaddingX="20px"
      >
        <div class="pt-9 px-5 pb-6">
          <div class="text-W5 text-[17px]">
            <span class="leading-[21.5px]">
              {{ targetFavoriteData.name }}が登録されていません。
            </span>
            <br />
            <span class="leading-[21.5px]">登録しますか？</span>
          </div>
          <!-- ボタン -->
          <div class="flex justify-center mt-4 items-stretch">
            <div
              class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
              @click="closeNoRegisterFavoriteModal()"
            >
              キャンセル
            </div>
            <div
              class="w-1/2 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
              @click="goToFavoriteSuggest(targetFavoriteData.id)"
            >
              登録
            </div>
          </div>
        </div>
      </Modal>
    </Teleport>
  </div>
</template>
<script>
/**
 * お気に入り地点の表示と選択、未登録時の遷移までを行う
 */
import IconFavoriteCurrent from '@/components/icons/common/IconFavoriteCurrent.vue'
import IconFavoriteHome from '@/components/icons/common/IconFavoriteHome.vue'
import IconFavoriteOfficeSchool from '@/components/icons/common/IconFavoriteOfficeSchool.vue'
import IconFavoriteRibbon from '@/components/icons/common/IconFavoriteRibbon.vue'
import Modal from '@/components/Modal.vue'
import Overlay from '@/components/Overlay.vue'
import SuggestFavoriteSpotsList from '@/components/organisms/searchRoute/SuggestFavoriteSpotsList.vue'

import util from '@/mixins/util'
// お気に入り表示情報
const FAVORITE_CURRENT = {
  id: 'current',
  name: '現在地',
}
const FAVORITE_HOME = {
  id: 'home',
  name: '自宅',
}
const FAVORITE_WORK = {
  id: 'work',
  name: '会社/学校',
}
const FAVORITE_LIST = {
  id: 'favorite',
  name: 'お気に入り',
}

const SuggestFavoriteSpots = {
  name: 'SuggestFavoriteSpots',
  components: {
    IconFavoriteCurrent,
    IconFavoriteHome,
    IconFavoriteOfficeSchool,
    IconFavoriteRibbon,
    Overlay,
    Modal,
    SuggestFavoriteSpotsList,
  },
  mixins: [util],
  props: {},
  data() {
    return {
      favoriteCurrent: FAVORITE_CURRENT, // 現在地
      favoriteHome: FAVORITE_HOME, // 自宅
      favoriteWork: FAVORITE_WORK, // 勤務先
      favoriteList: FAVORITE_LIST, // お気に入り
      isShowFavoriteSpotOverlay: false, // お気に入り一覧オーバーレイフラグ
      isShowNoRegisterFavoriteModal: false, // お気に入り未登録ポップアップフラグ
      targetFavoriteData: null, // 未登録時の対象
    }
  },
  computed: {
    /**
     * 登録している自宅情報
     */
    home() {
      return this.$store.state.MenuStore.favoriteSpots.home
    },
    /**
     * 登録している会社/学校情報
     */
    work() {
      return this.$store.state.MenuStore.favoriteSpots.work
    },
    /**
     * 登録しているお気に入り地点一覧情報
     */
    favoriteSpotList() {
      return this.$store.state.MenuStore.favoriteSpots.favorite
    },
  },
  methods: {
    /**
     * お気に入り未登録ポップアップを閉じる
     */
    closeNoRegisterFavoriteModal() {
      this.isShowNoRegisterFavoriteModal = false
      this.targetFavoriteData = null
    },
    /**
     * お気に入り地点選択画面への遷移処理
     * @param {string} targetId 選択した地点のID
     */
    goToFavoriteSuggest(targetId) {
      // 地点検索コンポーネントの表示フラグを立てる
      this.$store.commit('updateIsShowSuggestFlg', true)
      // フッタータブ情報を切り替え
      this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_MENU)
      // お気に入り地点検索画面に遷移
      this.$router.push({
        name: this.$config.DISPLAY_FAVORITE_SPOT_SUGGEST,
        params: {key: targetId},
      })
    },
    /**
     * お気に入り地点が登録されているかどうかを返却
     * true:未登録, false:登録済
     * @param {boolean} target 押下したお気に入り種別
     */
    isNoRegisteredSpot(target) {
      let result
      const emptyFavoriteList = 0
      switch (target.id) {
        case FAVORITE_CURRENT.id:
          // 現在地選択の場合は固定でfalse返却
          result = false
          break
        case FAVORITE_HOME.id:
          // 住所もしくは緯度経度が未設定だったら、未登録判定
          result = this.home.address == '' || this.home.coord == ''
          break
        case FAVORITE_WORK.id:
          // 住所もしくは緯度経度が未設定だったら、未登録判定
          result = this.work.address == '' || this.work.coord == ''
          break
        case FAVORITE_LIST.id:
          // お気に入りの場合、登録件数が0件であれば、未登録判定
          result = this.favoriteSpotList.length == emptyFavoriteList
          break
        default:
          result = true
      }
      return result
    },
    /**
     * お気に入りアイコン押下処理
     * @param {Object} target 押下したお気に入り種別
     */
    onClickFavorite(target) {
      // 未登録の場合は未登録ポップアップを表示
      if (this.isNoRegisteredSpot(target)) {
        this.targetFavoriteData = target
        this.isShowNoRegisterFavoriteModal = true
        return
      }

      // 各選択地点で登録処理振り分け
      let spot
      switch (target.id) {
        case FAVORITE_CURRENT.id:
          // 現在地を選択した場合、現在地の設定を反映
          spot = {
            name: '現在地',
            address: '',
            coord: '',
            category: {},
          }
          break
        case FAVORITE_HOME.id:
          spot = {
            name: '自宅',
            address: this.home.address,
            coord: this.home.coord,
            buildingName: this.home.buildingName,
            category: this.home.category || {},
          }
          break
        case FAVORITE_WORK.id:
          spot = {
            name: '会社/学校',
            address: this.work.address,
            coord: this.work.coord,
            buildingName: this.work.buildingName,
            category: this.work.category || {},
          }
          break
        case FAVORITE_LIST.id:
          // お気に入りアイコンの場合
          this.isShowFavoriteSpotOverlay = true
          return
        default:
          return
      }
      this.$emit('select-favorite-spot', spot)
    },
    /**
     * お気に入り一覧の地点押下処理
     * @param {Object} favorite お気に入り情報
     */
    onClickFavoriteSpot(favorite) {
      this.isShowFavoriteSpotOverlay = false
      const spot = {
        name: favorite.spotName,
        address: favorite.address,
        coord: favorite.coord,
        buildingName: favorite.buildingName,
        category: favorite.category || {},
      }
      this.$emit('select-favorite-spot', spot)
    },
  },
}
export default SuggestFavoriteSpots
</script>
<style scoped></style>
