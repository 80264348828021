import config from '@/const/const.js'
import util from './util.js'
import 'dayjs/locale/ja'
import deepcopy from 'deepcopy'

const constant = config.constant

export default {
  methods: {
    /**
     * 乗り換え履歴の取得
     * @return {Object} 乗り換え検索履歴
     */
    getConditionsHistory() {
      return util.methods.getLocalStorage(
        constant.TRANSFER_CONDITIONS_HISTORY_STORAGE_KEY
      )
    },
    /**
     * 乗り換え検索履歴を登録
     * ※履歴がある場合、先頭に移動する
     * @param {Object} registerHistory 登録用の検索履歴データ
     * @return {Object} 検索履歴登録後の履歴一覧
     */
    registerTransferConditionsHistory(registerHistory) {
      let conditionHistoryList = deepcopy(this.getConditionsHistory()) ?? []
      // eslint-disable-next-line no-magic-numbers
      if (conditionHistoryList.length !== 0) {
        // 履歴がある場合、重複したデータを削除
        conditionHistoryList = this.removeDuplicateData(
          registerHistory,
          conditionHistoryList
        )
      }
      
      //履歴を追加
      conditionHistoryList.unshift(registerHistory)
      //最新の10件以内になるように限定
      while (constant.MAX_HISTORY_NUM < conditionHistoryList.length) {
        conditionHistoryList.pop()
      }
      util.methods.setLocalStorage(
        constant.TRANSFER_CONDITIONS_HISTORY_STORAGE_KEY,
        conditionHistoryList
      )
      return conditionHistoryList
    },
    /**
     * @param registerData 検索対象データ
     * @param conditionHistoryList  削除対象データ
     */
    removeDuplicateData(registerData, conditionHistoryList) {
      // 重複したデータを探す
      return conditionHistoryList.filter((history) => {
        return (
          !this.isDuplicatedObject(registerData.start, history.start) ||
          !this.isDuplicatedVia(registerData.via, history.via) ||
          !this.isDuplicatedObject(registerData.goal, history.goal)
        )
      })
    },
    /**
     * 引数が同じかを判断
     * @param {Object} registerData
     * @param {Object} historyData
     * @returns
     */
    isDuplicatedObject(registerData, historyData) {
      return (
        registerData.name == historyData.name &&
        registerData.nodeId == historyData.nodeId &&
        registerData.type == historyData.type
      )
    },
    /**
     * 経由地が同じデータかを判断
     * @param registerData  登録データ
     * @param historyData 履歴データ
     * @return true：同じデータ
     *         false：異なるデータ
     */
    isDuplicatedVia(registerVia, historyVia) {
      // 経由地の数が同じかを判断
      let isNotSameLength = registerVia.length !== historyVia.length
      if (isNotSameLength) {
        return false
      }

      // 経由地をひとつずつ比較する
      let result = true
      for (let i = 0; i < registerVia.length; i++) {
        if (!result) {
          // すでにデータが異なる場合は何もせずに処理を抜ける
          break
        }
        result = this.isDuplicatedObject(registerVia[i], historyVia[i])
      }
      return result
    },
  },
}
