<template>
  <div v-if="apiErrorCode">
    <Modal
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-[31.75px] pb-6">
        <div class="text-W5 text-[17px] leading-[25.5px]">
          {{ errorTitle }}
        </div>
        <div
          class="text-W3 text-[15px] leading-[23px] mt-[7.75px] mb-5"
          v-if="errorMessage"
        >
          {{ errorMessage }}
        </div>
        <div class="mt-[11.75px]">
          <TheButton text="もどる" @click-button="onClickButton()" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import TheButton from '@/components/atoms/TheButton.vue'

const CreditCardErrorModal = {
  name: 'CreditCardErrorModal',
  components: {Modal, TheButton},
  emits: ['close'],
  computed: {
    /**
     * storeのAPIエラーコード
     */
    apiErrorCode() {
      return this.$store.state.CreditCardsStore.apiErrorCode
    },
    /**
     * エラー表示内容のオブジェクト
     */
    error() {
      return Object.values(this.$config.API_ERROR.CREDIT_CARD_PAYMENT).find(
        (error) => {
          return error.code === this.apiErrorCode
        }
      )
    },
    /**
     * エラータイトル
     */
    errorTitle() {
      return this.error?.title ?? ''
    },
    /**
     * エラーメッセージ
     */
    errorMessage() {
      return this.error?.message ?? ''
    },
  },
  methods: {
    /**
     * もどるボタン押下時の処理
     */
    onClickButton() {
      // storeのエラーコードを初期化（モーダルが閉じられる）
      this.$store.commit('CreditCardsStore/updateApiErrorCode', '')
      this.$emit('close')
    },
  },
}
export default CreditCardErrorModal
</script>
<style scoped></style>
