<template>
  <svg viewBox="0 0 16 16">
    <defs>
      <clipPath>
        <rect width="16" height="16" fill="none" />
      </clipPath>
    </defs>
    <rect width="16" height="16" fill="none" />
    <g clip-path="url(#clip-path)">
      <path
        d="M127.155-825.57a6.852,6.852,0,0,1-5.07-2.114A7.025,7.025,0,0,1,120-832.805h1.2a5.884,5.884,0,0,0,1.724,4.269,5.683,5.683,0,0,0,4.229,1.764,5.828,5.828,0,0,0,4.309-1.784,5.928,5.928,0,0,0,1.764-4.329,5.6,5.6,0,0,0-1.784-4.2,5.97,5.97,0,0,0-4.289-1.714,5.451,5.451,0,0,0-2.555.621,6.969,6.969,0,0,0-2.074,1.643h2.1v1.2h-4.189V-839.5h1.2v2.124a7.626,7.626,0,0,1,2.475-1.924,6.817,6.817,0,0,1,3.036-.7,7.16,7.16,0,0,1,2.826.561,7.4,7.4,0,0,1,2.315,1.533,7.2,7.2,0,0,1,1.563,2.275,6.909,6.909,0,0,1,.571,2.806,7.051,7.051,0,0,1-.571,2.826,7.253,7.253,0,0,1-1.563,2.3,7.46,7.46,0,0,1-2.315,1.553A7.051,7.051,0,0,1,127.155-825.57Zm2.565-3.948-3.086-3.046v-4.289h1.2v3.788l2.746,2.686Z"
        transform="translate(-119.215 840.785)"
        fill="#979ca1"
        class="icon-history-path"
      />
    </g>
  </svg>
</template>
