import config from '@/const/const.js'
import util from './util.js'
const constant = config.constant

export default {
  methods: {
    /**
     * ログイン方法データを登録/更新
     * @param {String} data ログイン設定
     */
    setLoginMethod(data) {
      util.methods.setLocalStorage(constant.LOGIN_SETTING_KEY, data)
    },
    /**
     * ログイン方法データを取得
     * @returns 取得結果
     */
    getLoginMethod() {
      // ローカルストレージのログイン設定取得
      const loginMethod = util.methods.getLocalStorage(
        constant.LOGIN_SETTING_KEY
      )

      return loginMethod
    },
    /**
     * 既にアプリ内情報が作成されているかの判定処理
     * @returns 判定結果
     */
    isSetAppInfo() {
      // ローカルストレージのアプリ内情報取得
      const appInfo = util.methods.getLocalStorage(constant.APP_INFO_KEY)
      // アプリ内情報自体が作成されているかを返却
      return !util.methods.isNull(appInfo)
    },
  },
}
