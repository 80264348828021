<template>
  <div id="BicycleReservationReturnPortDetail" class="fixed-app flex flex-col">
    <!-- ヘッダー -->
    <Header
      ref="arrangementHeader"
      headerTitle="返却できるポートを探す"
      :isShowPrevious="true"
      :isShowClose="true"
      @clickPrevious="gotoBackPage()"
      @clickClose="jumpToReservationConfirmedPage()"
    />
    <!-- ボディ -->
    <div class="px-6 pt-5 pb-6 overflow-y-auto scroll-bar-none flex-1">
      <!-- ポート名 -->
      <div class="text-left text-W7 text-[18px] leading-[27px]">
        {{ parkInfo.parkName }}
      </div>
      <!-- ポート詳細情報 -->
      <div class="mt-5 flex">
        <div class="flex-1 text-left">
          <div>
            <div class="text-W7 text-[13px] leading-[13px]">営業時間</div>
            <div
              v-if="is24Hours"
              class="text-W4 text-[14px] leading-[14px] mt-2"
            >
              24時間
            </div>
            <div v-else class="text-W4 text-[14px] leading-[14px] mt-2">
              {{ `${parkInfo.startTime}~${parkInfo.endTime}` }}
            </div>
          </div>
          <div class="mt-3">
            <div class="text-W6 text-[14px] leading-[14px]">住所</div>
            <div class="text-W4 text-[14px] leading-[21px] mt-1.5">
              {{ parkInfo.parkAddress }}
            </div>
          </div>
        </div>
        <div class="pl-[18px]">
          <!-- 画像 -->
          <img
            class="h-[90px] w-[142px] bg-white"
            :src="parkInfo.imageUrl"
            @error="setNoImageOnError($event)"
          />
        </div>
      </div>
      <!-- アクセス情報 -->
      <div class="mt-3.5">
        <BaseBox class="bg-gray200 w-full p-3">
          <template v-slot:explanation>
            <div class="text-W4 text-[14px] text-gray500 leading-[21px]">
              {{ parkInfo.howtoAccess }}
            </div>
          </template>
        </BaseBox>
      </div>
      <div class="mt-3">
        <BicycleRentInfoBox
          :cycleNum="parkInfo.cycleNum"
          :settingNum="parkInfo.settingNum"
          :basicCharge="planInfo.basic.basicCharge"
          :basicPeriod="planInfo.basic.basicPeriod"
          :useCharge="planInfo.use.useCharge"
          :usePeriod="planInfo.use.usePeriod"
        />
      </div>
      <!-- ポートに行くボタン -->
      <div class="mt-8">
        <TheButton text="ポートに行く" @click-button="searchRoute()" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 返却ポート詳細画面
 */
import Header from '@/components/organisms/Header.vue'
import ArrangementUtil from '@/mixins/arrangementUtil.js'
import BaseBox from '@/components/atoms/BaseBox.vue'
import BicycleRentInfoBox from '@/components/molecules/arrangement/BicycleRentInfoBox.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Util from '@/mixins/util.js'
import deepcopy from 'deepcopy'

const BicycleReservationReturnPortDetail = {
  name: 'BicycleReservationReturnPortDetail',
  mixins: [ArrangementUtil, Util],
  components: {
    Header,
    BaseBox,
    BicycleRentInfoBox,
    TheButton,
  },
  data() {
    return {
      isBackPageFlg: false, // 前の画面に戻るフラグ（返却ポート詳細情報を初期化するフラグ）
      planInfo: deepcopy(
        this.$store.getters['MobilityReservationStore/planInfoWithCommadCharge']
      ), // プラン情報
    }
  },
  computed: {
    /**
     * 営業時間が24時間営業か確認する
     * @return {boolean} true: 24時間営業
     */
    is24Hours() {
      const businessTime = {
        start: this.parkInfo.startTime,
        end: this.parkInfo.endTime,
      }
      return this.checkBusinessTime(businessTime)
    },
    /**
     * ポート詳細情報(ポート情報及び自転車情報一覧)
     */
    parkDetailInfo() {
      return this.$store.state.MobilityReservationStore.bicycle.parkDetailInfo
    },
    /**
     * ポート詳細取得APIから取得したポート情報
     */
    parkInfo() {
      return this.parkDetailInfo.parkInfo
    },
  },
  unmounted() {
    if (this.isBackPageFlg) {
      // 返却ポート詳細情報の初期化
      this.resetReturnParkDetailInfo()
    }
  },
  methods: {
    /**
     * ヘッダー戻るボタン押下処理
     */
    gotoBackPage() {
      this.$router.push({
        name: this.$config.DISPLAY_BICYCLE_RESERVATION_RETURN_PORT_ON_MAP,
      })
      // データの初期化するフラグを立てる
      this.isBackPageFlg = true
    },
    /**
     * 予約確定画面への遷移処理
     */
    jumpToReservationConfirmedPage() {
      // 予約確定画面へ遷移
      this.$router.push({
        name: this.$config.DISPLAY_BICYCLE_RESERVATION_CONFIRMED,
      })

      // 初期化フラグをtrueにする
      this.isBackPageFlg = true
    },
    /**
     * ポート詳細の初期化処理
     */
    resetReturnParkDetailInfo() {
      // 詳細情報の初期化
      this.$store.commit('MobilityReservationStore/updateParkDetailInfo', {})
    },
    /**
     * 現在地からの利用開始ポートまでのルート検索を行う。
     */
    searchRoute() {
      /**
       * 再検索に伴う初期化処理
       */
      // 最適画面 - 検索状態の初期化
      this.$store.commit('updateSearchRoute', false)
      this.$store.commit('RouteStore/updateIsCompleteSearchRoute', false)

      // ルート描画初期化(ローディングなし)
      this.$store.commit('updateIsNoLoadingWhenDrawRoute', true)
      this.$store.commit('clearDrawRouteData')
      this.$store.commit('clearDrawRouteScript')

      /**
       * 検索条件設定（現在時間・現在地出発・ポート目的地）
       * ※初期値を設定している場合、最適画面にて現在地として検索されるため、明示的な設定不要。
       */
      // 検索条件の初期化
      this.$store.commit('initializeSearchConditions')

      const port = {
        name: this.parkInfo.parkName,
        address: this.parkInfo.parkAddress,
        coord: `${this.parkInfo.lat},${this.parkInfo.lon}`,
      }
      // 出発地点設定処理
      // ここで検索をする場合、事前に現在地を設定する必要があるため
      this.$store.commit('updateSearchConditions', {
        key: this.$config.SPOT_TYPE_START,
        value: {
          name: '現在地',
          address: '',
          coord: '',
        },
      })
      // 到着地点(ポート)
      this.$store.commit('updateSearchConditions', {
        key: this.$config.SPOT_TYPE_GOAL,
        value: port,
      })
      // 時間(現在時刻/出発)
      this.$store.commit('updateSearchConditions', {
        key: 'timeType',
        value: {id: 1, name: '出発'},
      })
      this.$store.commit('updateSearchConditions', {
        key: 'targetTime',
        value: this.getNow(),
      })

      /**
       * 遷移処理
       */
      // 最適画面に遷移
      const spotTab = 1
      this.$store.commit('updateFooterTab', spotTab)
      this.$router.push({name: 'RouteByOptimal'})
      // ストレージのタブIDを更新
      this.setLocalStorage(this.$config.FOOTER_TAB, spotTab)
    },
  },
}
export default BicycleReservationReturnPortDetail
</script>

<style scoped></style>
