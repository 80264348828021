<template>
  <Modal
    v-if="modelValue"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="px-5 py-6 text-center">
      <div class="text-W5 text-[17px]">アップロードエラー</div>
      <div class="mt-4 text-W3 text-[15px]">
        お手数おかけしますが、ファイルの形式・容量をご確認の上、画像を選び直してください。
      </div>
      <div class="rounded-[4px] mt-6 p-5 bg-gray200 text-left">
        <div class="text-W5 text-[15px]">アップロード可能なファイル</div>
        <ul class="mt-3 text-W3 text-[13px] text-gray500">
          <li>・{{ extensions }}</li>
          <li>・最大{{ limitSize }}MB</li>
        </ul>
      </div>
      <div class="flex justify-center mt-6 items-center">
        <TheButton
          text="もどる"
          @click-button="$emit('update:modelValue', false)"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/Modal.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import {getEnableExtensions, MAX_FILE_SIZE} from '@/utils/imageUtil.js'
const UploadErrorModal = {
  name: 'UploadErrorModal',
  components: {Modal, TheButton},
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      // アップロード可能なファイル形式一覧
      extensions: getEnableExtensions(),
      // ファイルサイズ上限
      limitSize: MAX_FILE_SIZE,
    }
  },
}
export default UploadErrorModal
</script>
