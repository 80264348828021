<template>
  <iframe
    id="capy-iframe"
    class="w-full border-0 overflow-hidden"
    src="/capy.html"
    :height="iframeHeight"
    @load="onLoadIframe()"
  ></iframe>
</template>
<script>
const CaptchaClient = {
  props: {
    // エラーメッセージ表示フラグ
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click-captcha', 'click-back'],
  data() {
    return {
      iframeElement: null, // iframeのelement
      iframeHeight: '150', // iframeの高さ
      formObserver: null, // iframe内のform要素のResizeObserverインスタンス
    }
  },
  methods: {
    /**
     * captcha認証確定ボタン押下時の処理
     * @param {Object} submitイベント
     */
    clickCaptchaButton(event) {
      // iframe破棄前の処理呼び出し
      this.beforeRemoveIframe()

      const capyChallengeKey = event.target['capy_challengekey'].value
      const capyAnswer = event.target['capy_answer'].value

      this.$emit('click-captcha', capyChallengeKey, capyAnswer)
      event.preventDefault()
    },
    /**
     * 「もどる」ボタン押下時の処理
     */
    clickBackButton() {
      // iframe破棄前の処理呼び出し
      this.beforeRemoveIframe()

      this.$emit('click-back')
    },
    /**
     * iframe読み込み完了時の処理
     */
    onLoadIframe() {
      this.iframeElement = document.getElementById('capy-iframe')

      // scriptにsrc追加
      this.iframeElement.contentWindow.addScript(
        process.env.VUE_APP_CAPY_PUZZLE_URL
      )

      // エラー表示
      if (this.isError) {
        this.iframeElement.contentWindow.document.getElementById(
          'capy-error-message'
        ).style.display = 'block'
      }

      // 監視用の ResizeObserver インスタンスを用意
      // 監視対象要素の大きさが変わるたびにこれに渡したコールバックが走る
      const capyFormElement =
        this.iframeElement.contentWindow.document.getElementById('capy-form')
      this.formObserver = new ResizeObserver(() => {
        // iframeの高さ取得
        this.iframeHeight = String(capyFormElement.clientHeight)
      })
      // ResizeObserver インスタンスに監視対象要素を登録
      this.formObserver.observe(capyFormElement)

      // 戻るボタン押下時のイベントリスナー登録
      this.iframeElement.contentWindow.document
        .getElementById('capy-back-button')
        .addEventListener('click', this.clickBackButton)

      // 認証ボタン押下時のイベントリスナー登録
      this.iframeElement.contentWindow.document
        .getElementById('capy-form')
        .addEventListener('submit', this.clickCaptchaButton)
    },
    /**
     * iframe破棄前に行う処理
     * ※unmountedのタイミングではiframeが破棄済のため
     */
    beforeRemoveIframe() {
      // fromの高さ監視終了
      this.formObserver.disconnect()

      // 戻るボタン押下時のイベントリスナー削除
      this.iframeElement.contentWindow.document
        .getElementById('capy-back-button')
        .addEventListener('click', this.clickBackButton)
      // 認証ボタン押下時のイベントリスナー削除
      this.iframeElement.contentWindow.document
        .getElementById('capy-form')
        .addEventListener('submit', this.clickCaptchaButton)
    },
  },
}
export default CaptchaClient
</script>
<style scoped></style>
