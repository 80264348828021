<template>
  <div class="w-full h-screen">
    <MapWithUserLocation
      ref="BaseUserMap"
      mapElementId="taxi-reservation-map"
      :centerOffset="centerOffset"
      :defaultCenter="defaultCenter"
      :isSetTrackingMode="false"
      :copyrightBottomSpace="copyrightBottomSpace"
      @finish-generate-map="finishGenerateMap()"
    />
  </div>
</template>
<script>
import Util from '@/mixins/util'
import CanvasUtil from '@/mixins/canvasUtil'
import MapWithUserLocation from '@/components/atoms/MapWithUserLocation.vue'

const TaxiReservationMap = {
  name: 'TaxiReservationMap',
  components: {MapWithUserLocation},
  mixins: [Util, CanvasUtil],
  emits: ['moving-map', 'stop-map'],
  data() {
    return {
      // マップ移動中判定用変数
      judgeMapMoving: {
        timer: '',
        lat: '',
        lon: '',
        isMoving: false,
      },
    }
  },
  computed: {
    /**
     * 乗車地
     */
    pickUp() {
      return this.$store.state.MobilityReservationStore.taxi.routeInfo.pickUp
    },
    /**
     * ユーザーマップインスタンス
     */
    map() {
      return this.$refs.BaseUserMap.map
    },
    /**
     * マップの中心座標ずらし値を返却
     */
    centerOffset() {
      const lon = -231 / 2 // eslint-disable-line no-magic-numbers
      return {
        lat: 0, // eslint-disable-line no-magic-numbers
        lon,
      }
    },
    /**
     * マップ初期表示時の中心座標を返却
     */
    defaultCenter() {
      return {
        lat: this.pickUp.lat,
        lon: this.pickUp.lon,
      }
    },
    /**
     * ゼンリン・コピーライトの表示位置指定
     */
    copyrightBottomSpace() {
      const basePoint = COPYRIGHT_UNDER_SPACE
      return this.$store.state.bottomSafeAreaHeight + basePoint
    },
  },
  methods: {
    /**
     * マップ生成後に行う処理
     */
    finishGenerateMap() {
      // マップ移動Event追加
      this.map.addEventListener('centermoved', this.movingMapEventFunc)
    },
    /**
     * マップ移動イベントの設定を行う
     */
    movingMapEventFunc: function (event) {
      // マップ移動後の中心座標を保持
      const center = event.center
      this.judgeMapMoving.lat = center.latMillisec
      this.judgeMapMoving.lon = center.lngMillisec

      // 時間を少し置いた後、中心座標が更新されているかどうかでマップ移動中or停止を判定
      const delayTime = 300
      this.judgeMapMoving.timer = setTimeout(() => {
        if (
          this.judgeMapMoving.lat == center.latMillisec &&
          this.judgeMapMoving.lon == center.lngMillisec
        ) {
          // 中心座標に変更なければ、停止通知
          this.$emit('stop-map')
          // 移動中のフラグをfalseに切り替える
          this.judgeMapMoving.isMoving = false
        }
      }, delayTime)

      if (!this.judgeMapMoving.isMoving) {
        // 中心座標に変更あり、停止→移動に切り替わりであれば、移動中通知
        this.$emit('moving-map')
        // 移動中のフラグをtrueに切り替える
        this.judgeMapMoving.isMoving = true
      }
    },
    /**
     * 現在地に画面を移動させる
     */
    currentSpotFit() {
      return this.$refs.BaseUserMap.currentSpotFit()
    },
    /**
     * 任意の場所に画面を移動させる
     * @param {Number} lat 緯度
     * @param {Number} lon 経度
     */
    callFitWithLatLon(lat, lon) {
      return this.$refs.BaseUserMap.callFitWithLatLon(lat, lon)
    },
    /**
     * 中心座標の取得処理
     */
    getCenter() {
      return this.$refs.BaseUserMap.getCenter()
    },
  },
}

// コピーライト下の余白
const COPYRIGHT_UNDER_SPACE = 8

export default TaxiReservationMap
</script>
<style scoped></style>
