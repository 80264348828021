<template>
  <div id="SetupUserInfo">
    <!-- ヘッダー -->
    <Header
      headerTitle="ユーザー情報を入力"
      :isShowPrevious="false"
      :isShowClose="false"
    />
    <!-- ボディ -->
    <div class="mx-6 pb-8">
      <!-- 入力フォーム -->
      <div class="text-left mt-6 mb-6">
        <div class="mb-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">お名前</div>
          <div class="flex">
            <TextFormWithValidation
              ref="FamilyNameForm"
              class="w-1/2"
              inputClass="bg-gray200 border-gray200"
              :text="userInfo.myoji"
              :validationType="['required']"
              maxlength="100"
              placeholder="東海"
              errorMessage="苗字を入力してください。"
              @input-text="userInfo.myoji = $event"
            />
            <TextFormWithValidation
              ref="FirstNameForm"
              class="w-1/2 ml-1.5"
              inputClass="bg-gray200 border-gray200"
              :text="userInfo.na"
              :validationType="['required']"
              maxlength="100"
              placeholder="太郎"
              errorMessage="名前を入力してください。"
              @input-text="userInfo.na = $event"
            />
          </div>
        </div>
        <div class="mb-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">性別</div>
          <div class="w-full flex flex-row justify-between">
            <FormRadioButton
              v-for="genderObj in GENDER_LIST"
              :key="genderObj.key"
              :itemKey="genderObj.key"
              :label="genderObj.label"
              v-model="userInfo.gender"
              class="mr-2 last:mr-0"
            />
          </div>
        </div>
        <div class="mb-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">生年月日</div>
          <DateFormWithValidation
            ref="BirthdayForm"
            inputClass="bg-gray200 border-gray200"
            :defaultDate="userInfo.birthday"
            :validationType="['required']"
            maxlength="100"
            placeholder="1980/01/01"
            errorMessage="生年月日を入力してください。"
            @input-text="userInfo.birthday = $event"
          />
        </div>
        <div class="mb-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">住所</div>
          <TextFormWithValidation
            ref="AddressForm"
            inputClass="bg-gray200 border-gray200"
            :text="userInfo.address"
            :validationType="['required']"
            maxlength="100"
            placeholder="東京都千代田区丸の内 1-1-1 東海ビル101"
            errorMessage="住所を入力してください。"
            @input-text="userInfo.address = $event"
          />
        </div>
        <div class="mb-5">
          <div class="text-W7 text-[13px] leading-[13px] mb-2">電話番号</div>
          <TextFormWithValidation
            ref="TelForm"
            inputClass="bg-gray200 border-gray200"
            :text="userInfo.tel"
            :validationType="['required', 'tel']"
            maxlength="100"
            placeholder="07000123456"
            errorMessage="正しい電話番号を入力してください。"
            @input-text="userInfo.tel = $event"
          />
        </div>
        <div>
          <div class="mb-2 flex flex-row items-end">
            <div class="text-W7 text-[13px] leading-[13px]">メールアドレス</div>
            <div class="text-W4 text-gray400 text-[11px] leading-[11px]">
              （編集不可）
            </div>
          </div>
          <TextForm
            :text="userInfo.email"
            :isBackButton="false"
            inputClass="bg-gray200 border-gray200 !text-gray400"
            :isDisabled="true"
          />
        </div>
      </div>
      <!-- 登録ボタン -->
      <TheButton
        text="登録する"
        :isDisabled="isEntryError()"
        @click-button="onClickRegisterButton()"
      />
    </div>
    <!-- ユーザー情報登録失敗時ポップアップ -->
    <Modal
      v-if="isShowSetupUserInfoFailedModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 font-semibold text-[17px] leading-[25.5px]">
          エラーが発生しました。
        </div>
        <div class="mt-3 mx-5 text-W3 text-[15px] leading-[23px]">
          通信環境をご確認いただくか、時間を置いて再度お試しください。
        </div>
        <div
          class="mt-5 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
          @click="isShowSetupUserInfoFailedModal = false"
        >
          もどる
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
/**
 * ユーザー情報登録画面
 */
import Header from '@/components/organisms/Header.vue'
import Modal from '@/components/Modal.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import FormRadioButton from '@/components/atoms/FormRadioButton.vue'
import TextFormWithValidation from '@/components/molecules/TextFormWithValidation.vue'
import TextForm from '@/components/TextForm.vue'
import DateFormWithValidation from '@/components/molecules/DateFormWithValidation.vue'
import Util from '@/mixins/util.js'
import NativeUtil from '@/mixins/nativeUtil.js'
import {getAuth0EmailAddress} from '@/auth/AuthorizationInteractor.js'

const SetupUserInfo = {
  name: 'SetupUserInfo',
  components: {
    Header,
    Modal,
    TheButton,
    FormRadioButton,
    TextFormWithValidation,
    DateFormWithValidation,
    TextForm,
  },
  mixins: [Util, NativeUtil],
  data() {
    return {
      userInfo: {...this.$store.state.user.info, email: getAuth0EmailAddress()}, // ユーザー情報
      GENDER_LIST: [
        this.$config.GENDER.MAN,
        this.$config.GENDER.WOMAN,
        this.$config.GENDER.NO_SELECT,
      ], // 性別選択リスト
      isShowSetupUserInfoFailedModal: false, // ユーザー情報登録失敗ポップアップ
    }
  },
  mounted() {
    // 初回にバリデーションを走らせるため、DOMを強制的に再描画
    this.$forceUpdate()
  },
  methods: {
    /**
     * 入力フォームが一つでもエラーであるかどうか
     */
    isEntryError() {
      if (
        this.isNull(this.$refs.FamilyNameForm) ||
        this.isNull(this.$refs.FirstNameForm) ||
        this.isNull(this.$refs.BirthdayForm) ||
        this.isNull(this.$refs.AddressForm) ||
        this.isNull(this.$refs.TelForm)
      ) {
        return true
      }

      return (
        this.$refs.FamilyNameForm.isInvalid ||
        this.$refs.FirstNameForm.isInvalid ||
        this.$refs.BirthdayForm.isInvalid ||
        this.$refs.AddressForm.isInvalid ||
        this.$refs.TelForm.isInvalid
      )
    },
    /**
     * 登録するボタン押下時の処理
     */
    onClickRegisterButton() {
      // 成功時
      const success = () => {
        this.$store.commit('endLoading')
        // ユーザー情報登録完了画面へ遷移
        this.$router.push({name: this.$config.DISPLAY_SETUP_USER_INFO_FINISHED})
      }

      // 失敗時
      const failed = () => {
        this.$store.commit('endLoading')
        // ユーザー情報登録失敗ポップアップ表示
        this.isShowSetupUserInfoFailedModal = true
      }

      // ユーザー情報更新API実行
      this.$store.commit('startLoading')
      this.$store.dispatch('updateUser', {
        success: success,
        failed: failed,
        userInfo: this.userInfo,
      })
    },
  },
}
export default SetupUserInfo
</script>

<style scoped></style>
