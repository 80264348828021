import {createAuth0} from '@auth0/auth0-vue'

const auth = createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  // eslint-disable-next-line camelcase
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    // eslint-disable-next-line camelcase
    redirect_uri: window.location.origin,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  },
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
})

export default auth
