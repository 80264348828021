<template>
  <div class="h-full overflow-y-auto flex flex-col">
    <Header
      class="shrink-0"
      headerTitle="時刻表について"
      :isShowPrevious="true"
      @clickPrevious="gotoMenuTop()"
    />
    <div class="px-5">
      <img
        src="@/assets/menu/jr_official_timetable_logo_landscape.png"
        class="mt-6"
        width="120"
      />
      <div class="text-left text-W5 text-[13px] leading-[21px] mt-5 mb-6">
        〇{{ timetableLicense.title }}
        <br />
        交承 令和３年１８５号-1
        <br />
        {{ timetableLicense.jr }}
        <br />
        {{ timetableLicense.jrBus }}
        <br />
        この時刻データを無断で転載・複写し、又は紙媒体、電磁媒体その他いかなる媒体に加工することも禁じます。
        <br />
        <br />

        私鉄・地下鉄・空路の時刻表に基づく経路検索について
        <br />
        一部に対応していない路線があります。ご了承ください。
        <br />
        (J)23-01&emsp;{{ timetableLicense.jtb }}
        <br />
        <br />

        〇地図について
        <br />
        ・一部収録していない地図、地点があります。ご了承ください。
        <br />
        ・「測量法に基づく国土地理院長承認（使用）R5JHs 167－B7号」
        <br />
        ・本サービスで表示する地図の作成にあたっては、国土地理院長の承認を得て、同院発行の数値地図(国土基本情報)電子国土基本図(地図情報)を使用しております。（承認番号&emsp;平29情使、
        第1521号）
        <br />
        ・本サービスで表示する地図の作成にあたっては、国土交通省の国土数値情報（人口集中地区（面）データ）を使用しております。
        <br />
        ・「測量法に基づく国土地理院長承認(使用)R5JHs 171ーB5号」
        <br />
        <br />

        ※PC向けサービス
        <br />
        また、一般財団法人日本デジタル道路地図協会の全国デジタル道路地図データベース((c)2012-2021一般財団法人日本デジタル道路地図協会)を基に株式会社ゼンリンが追加・加工したものを使用しています(無断複製禁止)(測量法第44条に基づく成果使用承認12-2010)
        <br />
        ※スマートフォン向けサービス
        <br />
        また、一般財団法人日本デジタル道路地図協会の全国デジタル道路地図データベース((c)2012-2021一般財団法人日本デジタル道路地図協会)を基に株式会社ゼンリンが追加・加工したものを使用しています(無断複製禁止)(測量法第44条に基づく成果使用承認12-2040)
        <br />
        <br />

        〇スポット情報について
        <br />
        ・スポット情報やイベント情報は、施設の都合などで内容が変更されている場合がございます。料金・日程・時間など、最新の情報をお知りになりたい場合には、事前に各問合せ先にご確認されることをお薦めいたします。
        <br />
        ・掲載情報については様々な注意を払っておりますが、その内容の正確性・完全性・有用性・安全性等について、当社及び情報提供者が保証するものではありません。
        <br />
        ・掲載情報を利用して生じたいかなる損害･不都合などについて、当社及び情報提供者は一切責任を負いません。地図上の各スポットの位置情報は、ずれが生じる場合もございますので目安としてご利用ください。
        <br />
        <br />

        〇天気について
        <br />
        ・天気情報のデータは、いであ株式会社が提供する全国約1800箇所のピンポイント天気予報（今日明日＆週間）に基づいています｡このデータを無断転載･複写や電磁媒体等に加工することを禁じます。
        <br />
        ・本情報のご利用に基づくいかなる損害に対しても責任は負いかねますのでご了承ください。
      </div>
    </div>
  </div>
  <!-- 取得失敗ポップアップ -->
  <Modal
    v-if="isFailedGetLicense"
    class="modal"
    :isShowCloseButton="false"
    :isModalCenter="true"
    modalPaddingX="20px"
  >
    <div class="pt-9 pb-6 mx-5">
      <div class="text-W5 font-semibold text-[17px] leading-[25.5px] mb-4">
        情報が取得できませんでした。
      </div>
      <div class="max-w-[164px] m-auto">
        <TheButton text="OK" @click-button="gotoMenuTop()" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Header from '@/components/organisms/Header.vue'
import Modal from '@/components/Modal.vue'
import TheButton from '@/components/atoms/TheButton.vue'

const TimetableLicenseInfo = {
  name: 'TimetableLicenseInfo',
  components: {
    Header,
    Modal,
    TheButton,
  },
  data() {
    return {
      timetableLicense: {}, // Navitimeの時刻表ライセンス情報
      isFailedGetLicense: false, // 取得失敗フラグ
    }
  },
  created() {
    this.$store.commit('startLoading')
    // 成功時
    const success = (data) => {
      this.$store.commit('endLoading')
      this.timetableLicense = data
    }
    // 失敗時
    const error = () => {
      this.$store.commit('endLoading')
      this.isFailedGetLicense = true
    }
    this.$store.dispatch('MenuStore/getNavitimeTimetableLicense', {
      success: success,
      failed: error,
      error: error,
    })
  },
  methods: {
    /**
     * メニューTOP画面に遷移する
     */
    gotoMenuTop() {
      this.$router.push({name: this.$config.DISPLAY_MENU_TOP})
    },
  },
}

export default TimetableLicenseInfo
</script>
