<template>
  <div id="favorite-spot-list" class="bg-gray200 h-full flex flex-col">
    <!-- ヘッダー部 -->
    <div class="flex text-center w-full items-center bg-white h-[45px] px-3">
      <div class="w-full text-left">
        <img
          src="@/assets/Icon_Left_gray.svg"
          class="h-4"
          @click="goToBackPage()"
        />
      </div>
      <div class="text-W6 leading-[15px] text-[15px] w-full">
        お気に入り地点
      </div>
      <div class="w-full"></div>
    </div>
    <!-- 線 -->
    <div class="h-[0.5px] w-full bg-gray300" />
    <div id="favorite-spot-contents" class="overflow-y-auto px-6 flex-1">
      <div>
        <div class="text-left text-W6 text-[13px] leading-[13px] pt-7 pb-4">
          自宅
        </div>
        <FavoriteSpotCard
          icon="favoriteIcon/Icon_Favorite_Home.svg"
          :spotName="showName(home)"
          :address="concatAddress(home)"
          :showNameClass="showNameClass(home)"
          @touchAction="changeFavoriteData(home, 'home')"
        />
      </div>
      <div>
        <div class="text-left text-W6 text-[13px] leading-[13px] pt-7 pb-4">
          会社/学校
        </div>
        <FavoriteSpotCard
          icon="favoriteIcon/Icon_Favorite_Office_School.svg"
          :spotName="showName(work)"
          :address="concatAddress(work)"
          :showNameClass="showNameClass(work)"
          @touchAction="changeFavoriteData(work, 'work')"
        />
      </div>
      <div class="pb-8">
        <div class="text-left text-W6 text-[13px] leading-[13px] py-7 pb-4">
          お気に入り地点
          <span class="ml-3 text-W2 text-[12px] leading-[12px] text-gray500">
            {{ favoriteList.length }}/{{ maxFavoriteListLength }}件
          </span>
        </div>
        <div v-if="isNotMaxFavoriteList" class="mb-2">
          <FavoriteSpotCard
            icon="favoriteIcon/Icon_Favorite_Ribbon.svg"
            spotName="追加する"
            showNameClass="text-blue"
            @touchAction="createFavoriteData()"
          />
        </div>
        <div
          v-for="favorite in favoriteList"
          :key="favorite.id"
          class="mb-2 last:mb-0"
        >
          <FavoriteSpotCard
            icon="favoriteIcon/Icon_Favorite_Ribbon_Lighting.svg"
            :spotName="showName(favorite)"
            :address="concatAddress(favorite)"
            @touchAction="changeFavoriteData(favorite, 'favorite')"
          />
        </div>
      </div>

      <FooterMargin />
    </div>
  </div>
</template>

<script>
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import FavoriteSpotCard from '@/components/menu/FavoriteSpotCard.vue'
/**
 * お気に入り一覧画面
 */
const FavoriteSpotList = {
  name: 'FavoriteSpotList',
  components: {FavoriteSpotCard, FooterMargin},
  data() {
    return {
      maxFavoriteListLength: this.$config.FAVORITE_SPOT_MAX_NUMBER, // お気に入り地点の件数上限
    }
  },
  computed: {
    /**
     * 自宅情報を取得する
     */
    home() {
      return this.$store.state.MenuStore.favoriteSpots.home
    },
    /**
     * 会社/学校情報を取得する
     */
    work() {
      return this.$store.state.MenuStore.favoriteSpots.work
    },
    /**
     * お気に入り地点情報を取得する
     */
    favoriteList() {
      return this.$store.state.MenuStore.favoriteSpots.favorite
    },
    /**
     * お気に入り地点の件数が上限に達していないかチェックする
     * @return {boolean} true:上限に達していない
     */
    isNotMaxFavoriteList() {
      return this.favoriteList.length < this.maxFavoriteListLength
    },
  },
  methods: {
    /**
     * 前の画面に遷移
     */
    goToBackPage() {
      // メニュー画面に遷移
      this.$router.push({name: 'Menu'})
    },
    /**
     * 登録済みかチェックする
     * @return {boolean} true:登録済み
     */
    isRegisterFavorite(spotData) {
      return (
        spotData != null && spotData.address != null && spotData.address != ''
      )
    },
    /**
     * 表示名を取得する
     * @param {Object} spotData 検索文字
     */
    showName(spotData) {
      if (this.isRegisterFavorite(spotData)) {
        // 「データが自宅かつスポット名が自宅」もしくは「データが会社/学校かつスポット名が会社/学校」の場合
        if (
          (spotData == this.home && spotData.spotName == '自宅') ||
          (spotData == this.work && spotData.spotName == '会社/学校')
        ) {
          //表示名は空とする
          return ''
        }
        return spotData.spotName || ''
      }
      // 住所がない場合、未登録と判断する
      return '未登録'
    },
    /**
     * 住所を取得する
     * trim()で文字の両端に空白があれば除去
     */
    concatAddress(spotData) {
      return this.isRegisterFavorite(spotData)
        ? // 住所と建物名の間に空白
          spotData.address.concat(' ', spotData.buildingName).trim()
        : ''
    },
    /**
     * 未登録の場合、コンポーネントの文字を青字にする
     */
    showNameClass(spotData) {
      return this.isRegisterFavorite(spotData) ? '' : 'text-blue'
    },
    /**
     * 自宅 or 会社/学校 or お気に入り地点編集処理
     */
    changeFavoriteData(spotData, key) {
      if (this.isRegisterFavorite(spotData)) {
        // お気に入り地点詳細画面
        this.$router.push({
          name: this.$config.DISPLAY_FAVORITE_SPOT_DETAIL,
          params: {key: key, spotData: JSON.stringify(spotData)},
        })
      } else {
        // お気に入り地点選択画面
        this.$router.push({
          name: this.$config.DISPLAY_FAVORITE_SPOT_SUGGEST,
          params: {key: key, id: spotData.id},
        })
      }
    },
    /**
     * お気に入り地点追加処理
     */
    createFavoriteData() {
      this.$router.push({
        name: this.$config.DISPLAY_FAVORITE_SPOT_SUGGEST,
        params: {key: this.$config.FAVORITE_SPOT},
      })
    },
  },
}
export default FavoriteSpotList
</script>

<style></style>
