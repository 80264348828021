<template>
  <div class="overlay bg-white flex flex-col text-center">
    <Header
      headerTitle="お気に入り地点"
      :isShowPrevious="true"
      @clickPrevious="$emit('onClickPrevious')"
    />
    <div class="flex-1 pb-8 pt-5 px-5 overflow-y-auto">
      <!-- お気に入り地点リスト -->
      <div
        v-for="favorite in favoriteSpotList"
        :key="favorite.id"
        class="flex items-center pb-2 last:pb-0"
      >
        <div
          class="flex items-center p-4 border-[1px] border-gray300 rounded-[10px] w-full active:bg-blue100"
          @click="$emit('onClickFavoriteCard', favorite)"
        >
          <img
            class="h-8 w-8 mr-4"
            src="@/assets/favoriteIcon/Icon_Favorite_Ribbon_Lighting.svg"
          />
          <div class="flex-1 overflow-hidden text-left">
            <div class="text-W4 text-[14px] leading-[14px] truncate mb-1">
              {{ favorite.spotName }}
            </div>
            <div class="text-W3 text-[13px] leading-[13px] text-gray truncate">
              {{ showAddress(favorite) }}
            </div>
          </div>
        </div>
      </div>
      <!-- 追加する -->
      <div
        v-if="isNotMaxFavoriteList"
        class="flex items-center pl-4 pr-3 py-4 border-[1px] border-gray300 rounded-[10px] w-full active:bg-blue100"
        @click="$emit('onClickAddFavorite')"
      >
        <img
          class="h-8 w-8 mr-4"
          src="@/assets/favoriteIcon/Icon_Favorite_Ribbon.svg"
        />
        <div class="text-blue text-W4 text-[13px] leading-[13px] text-left">
          追加する
        </div>
      </div>
      <!-- 備考 -->
      <div class="text-W4 text-[11px] leading-[11px] text-gray text-left mt-2">
        お気に入り登録の変更はメニュー画面から可能です。
      </div>
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util.js'
import Header from '@/components/organisms/Header.vue'
const SuggestFavoriteSpotsList = {
  name: 'SuggestFavoriteSpotsList',
  components: {Header},
  emits: ['onClickPrevious', 'onClickFavoriteCard', 'onClickAddFavorite'],
  props: {
    /**
     * お気に入り地点リスト
     */
    favoriteSpotList: {
      type: Array,
      required: true,
    },
  },
  mixins: [Util],
  computed: {
    /**
     * お気に入り地点の件数が上限に達していないかチェックする
     * @return {boolean} true:上限に達していない
     */
    isNotMaxFavoriteList() {
      return (
        this.favoriteSpotList.length < this.$config.FAVORITE_SPOT_MAX_NUMBER
      )
    },
  },
  methods: {
    /**
     * 表示する住所名を作成
     * @param {Object} spot スポット情報
     * @returns {String} 住所名(+建物名)
     */
    showAddress(spot) {
      return !this.isNull(spot.buildingName)
        ? spot.address.concat(' ', spot.buildingName)
        : spot.address
    },
  },
}
export default SuggestFavoriteSpotsList
</script>
<style scoped>
.overlay {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 5000px);
  transform: translate3d(0, 0, 5000px);
}
</style>
