<template>
  <div id="modal" class="modal h-full" ref="modal">
    <div class="modal-layer" @click="closeModal"></div>
    <div class="modal-contents absolute" :style="modalStyle()">
      <img
        v-show="isShowCloseButton"
        src="@/assets/Icon_Close_wh.svg"
        class="h-4 w-4 ml-auto mr-2"
        @click="closeModal"
      />
      <div class="card-box mt-2 shadow-normal">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
const CommonModal = {
  name: 'CommonModal',
  props: {
    // 左右マージンを指定する場合は親で指定する
    modalPaddingX: {
      type: String,
      default: '8px',
    },
    // 下マージンを指定する場合は親で指定する
    modalPaddingBottom: {
      type: String,
      default: '8px',
    },
    // 下部の余白を指定する場合は親で指定する
    modalBottom: {
      type: Number,
      default: 0,
    },
    // 中央寄席のレイアウトにするかどうか
    isModalCenter: {
      type: Boolean,
      default: false,
    },
    // ×ボタンを表示するかどうか
    isShowCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    // iPhoneのブラウザにてポップアップ表示中に背景がスクロール可能になる問題があったので下記スタイルの追加で対処
    document.body.style.overflow = 'hidden'
  },
  unmounted() {
    // 対処のために追加したスタイルを削除
    document.body.style.overflow = ''
  },
  methods: {
    modalStyle() {
      let styles = {
        paddingLeft: this.modalPaddingX,
        paddingRight: this.modalPaddingX,
        paddingBottom: this.modalPaddingBottom,
      }
      if (this.isModalCenter) {
        // 中央寄せ
        styles['top'] = '50%'
        styles['transform'] = 'translateY(-50%)'
      } else {
        const bottom = this.modalBottom + this.$store.state.bottomSafeAreaHeight

        // 下寄せ
        styles['bottom'] = `${bottom}px`
      }
      return styles
    },
    /**
     * Modal非表示処理
     */
    closeModal() {
      // 共通処理のためModal種別は設定しない
      this.$emit('disPlayModal', false)
    },
  },
}
export default CommonModal
</script>
<style scoped>
.modal {
  position: fixed;
  z-index: 5000;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 5000px);
  transform: translate3d(0, 0, 5000px);
}
.modal-layer {
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1c2133;
  backdrop-filter: blur(3px) brightness(81%);
}
.modal-dialog {
  display: table-cell;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-contents {
  position: absolute;
  max-height: 94%;
  width: 100%;
  margin: 0 auto;
}
.card-box {
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(2, 14, 34, 0.07);
  background-color: #ffffff;
}
</style>
