<template>
  <!-- 日付選択コンポーネント -->
  <div class="date-select-form pt-3 pb-3 pl-4 pr-4">
    <div class="flex pt-3 pb-3 mt-10 mb-5 items-center">
      <div class="w-40 mx-auto">
        <InputDateTimeForm
          :inputType="'date'"
          :defaultDateTime="selectDate"
          :validationResult="dateSelectErrorFlg"
          @change-datetime="changeSelectDate($event)"
        ></InputDateTimeForm>
      </div>
    </div>
    <div
      class="text-W4 leading-[15px] text-[15px] w-8 mx-auto text-blue"
      @click="currentDate()"
    >
      本日
    </div>
    <div
      id="timer-button"
      class="text-W7 text-[15px] the-button-default w-full text-base mt-6 py-4 leading-[100%] flex items-center justify-center"
      @click="clickTimerButton()"
    >
      この日付で指定する
    </div>
  </div>
</template>

<script>
/**
 * 日付選択コンポーネント
 * 現在日付を選択した場合、本日と表示され、それ以外は選択した日付がM月D日（曜日）の形式で表示される
 */
import InputDateTimeForm from '@/components/InputDateTimeForm.vue'
import Util from '@/mixins/util'

const DateSelectForm = {
  name: 'DateSelectForm',
  components: {InputDateTimeForm},
  mixins: [Util],
  emits: ['completeDate'],
  data() {
    return {
      dateSelectErrorFlg: true,
      // 日付
      selectDate: this.initialDate,
    }
  },
  props: {
    // 初期値設定用の日付
    initialDate: {
      type: String,
    },
  },
  methods: {
    /**
     * 日付変更時の処理
     */
    changeSelectDate(event) {
      this.selectDate = event
    },
    /**
     * 日付設定を完了するボタン押下処理
     */
    clickTimerButton() {
      // 日付設定完了を親コンポーネントに通知する
      this.$emit('completeDate', this.selectDate)
    },
    /**
     * 現在日付を取得する
     */
    currentDate() {
      this.selectDate = this.getNow()
    },
  },
}
export default DateSelectForm
</script>

<style></style>
