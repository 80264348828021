import {constant} from '@/const/const'
/**
 * 電車の移動であるかどうかを判定
 * @param {String} move 移動の名称
 * @returns 電車の移動であるかどうか
 */
export function isTrainMove(move) {
  return [
    constant.MOVE.SUPEREXPRESS_TRAIN,
    constant.MOVE.SLEEPER_ULTRAEXPRESS,
    constant.MOVE.ULTRAEXPRESS_TRAIN,
    constant.MOVE.EXPRESS_TRAIN,
    constant.MOVE.RAPID_TRAIN,
    constant.MOVE.SEMIEXPRESS_TRAIN,
    constant.MOVE.LOCAL_TRAIN,
  ].includes(move)
}

/**
 * 対象の地点が乗換不要かどうかを判定
 * @param {Object} prevMoveSection 前の移動セクション
 * @param {Object} pointSection 対象の地点セクション
 * @param {Object} nextMoveSection 次の移動セクション
 * @returns 対象の地点が乗換不要かどうか
 */
export function isNoNextTransferRequired(
  prevMoveSection,
  pointSection,
  nextMoveSection
) {
  // 前の移動セクションがない(出発地点)は乗換不要判定の対象外のためfalse
  if (!prevMoveSection) return false
  // 次の移動セクションがない(目的地点)は乗換不要判定の対象外のためfalse
  if (!nextMoveSection) return false
  // 対象の地点セクションが経由駅の場合は乗換不要判定の対象外のためfalse
  if (pointSection.withVia) return false

  // 前後の移動セクションが「電車」である かつ 乗換不要/直通運転の場合はtrue
  return (
    isTrainMove(prevMoveSection.move) &&
    isTrainMove(nextMoveSection.move) &&
    !prevMoveSection.nextTransit
  )
}
/**
 * moveセクションのセクションカラー色を取得
 * @param {Object} moveSection 移動セクション
 * @returns セクションカラー
 */
export function getMobilitySectionColor(moveSection) {
  return moveSection.move == constant.MOBILITY.WALK
    ? constant.COLOR.WALK
    : moveSection.color || constant.COLOR.GENERAL_MOBILITY
}

/**
 * セクションの中から出発時間を取得する
 * @param {Array} sections セクションリスト
 * @return {String} 出発時間 (h:mm形式)
 */
export function getStartTimeFromSection(sections) {
  // 一番最初の「move」セクションを抽出
  const firstSection = sections.find((section) => section.type === 'move')
  // 上記セクションの出発時間を抽出
  return firstSection.fromTime
}

/**
 * セクションの中から到着時間を取得する
 * @param {Array} sections セクションリスト
 * @return {String} 到着時間 (h:mm形式)
 */
export function getGoalTimeFromSection(sections) {
  // 一番最後の「move」セクションを抽出
  const firstSection = sections.findLast((section) => section.type === 'move')
  // 上記セクションの到着時間を抽出
  return firstSection.toTime
}
