<template>
  <div
    id="MobilityTrainBusCard"
    class="flex w-full h-full border border-gray300 rounded-[10px] bg-white"
    @click="$emit('click-train-bus-card')"
  >
    <div class="w-full rounded-r-[10px] py-4">
      <!-- 時間とラベル -->
      <div class="w-full px-4 flex items-center justify-between">
        <!-- 時間 -->
        <div class="flex items-end">
          <div class="flex items-center">
            <div class="text-W6 text-[22px] leading-[25px] self-center">
              {{ getStartTime }}
            </div>
            <img src="@/assets/Arrow_Right_Gray.svg" class="h-3 w-3 mx-1" />
            <div class="text-W6 text-[22px] leading-[25px] self-center">
              {{ getGoalTime }}
            </div>
          </div>
          <div class="text-W4 text-center text-[14px] leading-[16px] ml-2 mb-1">
            ({{ getMoveTime }})
          </div>
        </div>
        <!-- ラベル -->
        <div class="flex">
          <img
            v-if="isFirstTag"
            class="h-6 w-6"
            src="@/assets/Icon_Train_Fast.svg"
          />
          <div v-if="isFirstTag && isLowCostTag" class="w-1"></div>
          <img
            v-if="isLowCostTag"
            class="h-6 w-6"
            src="@/assets/Icon_Train_LowPrice.svg"
          />
        </div>
      </div>
      <!-- 間の線 -->
      <div class="h-[1px] bg-gray300 w-full my-3" />
      <!-- 乗換回数、料金、乗り換え情報、アイコン-->
      <div class="flex items-center px-4">
        <div class="flex-1 w-full">
          <div class="flex h-[15px] items-center">
            <!-- 乗換回数 -->
            <div
              id="transitCount"
              class="text-W5 text-[13px] leading-[15px] mr-2.5"
              :class="getTransitCountClass"
            >
              <span v-if="isNoTransitCount" class="text-blue">乗換なし</span>
              <span v-else>
                <span>乗換</span>
                <span class="font-bold mx-1">
                  {{ this.routeInfo.transitCount }}
                </span>
                <span>回</span>
              </span>
            </div>
            <!-- 料金 -->
            <div class="leading-[15px] flex items-baseline">
              <div class="text-W6 text-[13px]">
                {{ addCommaForInteger(routeInfo.summaryFare) }}
              </div>
              <div class="text-W4 text-[12px] leading-[15px]">&nbsp;円</div>
            </div>
          </div>
          <!-- 乗り換え情報 -->
          <div class="flex mt-[6px]">
            <div
              :id="generateTransportInfoID"
              class="h-4 flex leading-4 text-center items-center overflow-hidden relative scroll-bar-none"
            >
              <div
                v-for="(transportInfo, index) in routeInfo.sections"
                :key="index"
                class="flex whitespace-nowrap"
              >
                <!-- タイプ：move -->
                <div
                  v-if="transportInfo.type == 'move'"
                  class="h-full text-W4 items-center flex"
                >
                  <!-- モビリティアイコン -->
                  <img
                    class="h-4 w-4 self-cente min-w-[16px]"
                    :src="getMobilityIconFromTransportInfo(transportInfo)"
                  />
                  <div
                    v-show="isShowTransportTime(transportInfo)"
                    class="flex self-center pl-[2px] items-center h-full"
                  >
                    <!-- 時間表示 数字 -->
                    <div class="text-W4 self-center h-full leading-[12px]">
                      {{ transportInfo.time }}
                    </div>
                    <!-- 時間表示 単位 -->
                    <div class="text-W2 self-center h-full leading-[12px]">
                      分
                    </div>
                  </div>
                </div>

                <!-- タイプ：point -->
                <div
                  v-show="isShowTypePoint(transportInfo)"
                  class="h-full text-W2 self-center"
                >
                  {{ transportInfo.spotName }}
                </div>
                <div
                  class="h-[1px] w-[6px] bg-gray300 self-center mx-2"
                  v-show="isShowTransportInfoList(index)"
                ></div>
              </div>
              <img
                class="h-[40px] w-4 self-center"
                src="@/assets/Icon_Transport_End.svg"
              />
            </div>
            <div
              v-show="isOverTransportInfo"
              class="h-full text-W2 self-center"
            >
              ⋯
            </div>
          </div>
        </div>
        <!-- アイコン -->
        <img class="ml-3 h-3 w-3" src="@/assets/Icon_Right_gray.svg" />
      </div>
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util'
import * as mobilityUtil from '@/utils/mobilityUtil'
import {
  getStartTimeFromSection,
  getGoalTimeFromSection,
} from '@/utils/transferUtil'
import {transferMinuteToText} from '@/utils/utils'

const MobilityTrainBusCard = {
  name: 'MobilityTrainBusCard',
  components: {},
  data() {
    return {
      isOverTransportInfo: false, //経路情報が枠をはみ出すかどうか
    }
  },
  props: {
    // このカードの順番
    routeNo: {
      type: String,
      default: '0',
      require: true,
    },
    // ルート情報
    routeInfo: {
      type: Object,
      require: true,
    },
    // 乗換回数が0回かどうか
    isNoTransfersCount: {
      type: Boolean,
      default: false,
      require: false,
    },
    // 早く到着タグ有無
    isFirstTag: {
      type: Boolean,
      default: false,
      require: false,
    },
    // 料金安いタグ有無
    isLowCostTag: {
      type: Boolean,
      default: false,
      require: false,
    },
    // 乗り換え情報(sectionsの中身をそのまま渡す)
    transportInfoList: {
      type: Array,
      require: true,
    },
  },
  emits: ['click-train-bus-card'],
  mixins: [Util],
  created() {},
  mounted() {},
  watch: {
    /**
     * カード内容の更新を検知した際に区間要素の調整を行う
     */
    routeInfo: {
      immediate: true,
      handler() {
        this.$nextTick(function () {
          this.resizeTransportAreaWidth()
        })
      },
    },
  },
  computed: {
    /**
     * 乗り換え情報のIDを生成する
     */
    generateTransportInfoID() {
      return 'transportInfo' + this.routeNo
    },
    /**
     * 乗換回数表示部分のclass定義を取得する
     */
    getTransitCountClass() {
      return this.isNoTransfersCount ? 'text-blue' : ''
    },
    /**
     * 出発時間を取得する
     * @return {String} 出発時間 (h:mm形式)
     */
    getStartTime() {
      return this.getFormatTime(
        getStartTimeFromSection(this.routeInfo.sections)
      )
    },
    /**
     * 到着時間を取得する
     * @return {String} 到着時間 (h:mm形式)
     */
    getGoalTime() {
      return this.getFormatTime(getGoalTimeFromSection(this.routeInfo.sections))
    },
    /**
     * 移動時間を取得する
     * @return {string} 移動時間 + 単位
     */
    getMoveTime() {
      return transferMinuteToText(this.routeInfo.summaryTime)
    },
    /**
     * 乗り換えが存在しないかどうかを返却
     */
    isNoTransitCount() {
      // eslint-disable-next-line no-magic-numbers
      return this.routeInfo.transitCount === 0
    },
  },
  methods: {
    /**
     * タイプがpointかつ出発 or 到着以外かどうか
     */
    isShowTypePoint(transportInfo) {
      return (
        transportInfo.type == 'point' &&
        transportInfo.name != '出発' &&
        transportInfo.name != '到着'
      )
    },
    /**
     * 乗り換え情報エリアの横幅を計算しなおして、はみ出さないようにする
     */
    resizeTransportAreaWidth() {
      const transportElement = document.getElementById(
        this.generateTransportInfoID
      )

      const outerCardSpace = 48 // カード外余白
      const innerCardSpace = 32 // カード内余白
      const iconWidth = 24 // アイコン幅
      const pointReaderWidth = 12 // 3点リーダ

      // 乗り換え情報領域の最大横幅
      const maxWidth =
        window.innerWidth -
        (outerCardSpace + innerCardSpace + iconWidth + pointReaderWidth)

      let width = 0
      this.isOverTransportInfo = false
      for (const child of transportElement.children) {
        if (width + child.offsetWidth < maxWidth) {
          width = width + child.offsetWidth
        } else {
          this.isOverTransportInfo = true
          break
        }
      }
      transportElement.style.width = width + 'px'
    },
    /**
     * 移動経路に時間を表示するかを判定する
     */
    isShowTransportTime(transportInfo) {
      // 徒歩と自転車の場合のみ表示
      return (
        transportInfo.move == this.$config.MOBILITY.WALK ||
        transportInfo.move == this.$config.MOBILITY.BICYCLE
      )
    },

    /**
     * 乗換情報を表示するかどうか
     */
    isShowTransportInfoList(index) {
      return (
        index != this.$config.ZERO &&
        index != this.routeInfo.sections.length - 1 // eslint-disable-line no-magic-numbers
      )
    },
    /**
     * モビリティアイコン取得
     * @param {Object} transportInfo 交通機関情報
     * @return アイコン
     */
    getMobilityIconFromTransportInfo(transportInfo) {
      return mobilityUtil.getMobilityIconFromTransportInfo(transportInfo)
    },
  },
}
export default MobilityTrainBusCard
</script>
<style scoped>
/* 乗換・金額間のボーダー */
#transitCount {
  position: relative;
}
#transitCount:after {
  margin: 0 4px;
  content: '';
  width: 1px;
  height: 8px;
  background-color: #dbdee3;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
}
</style>
