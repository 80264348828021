<template>
  <div id="BicycleReservationSelect" class="fixed-app flex flex-col">
    <!-- ヘッダー -->
    <Header
      ref="arrangementHeader"
      headerTitle="自転車の選択"
      :isShowPrevious="true"
      :isShowClose="true"
      @clickPrevious="gotoBackPage()"
      @clickClose="clickClose(true)"
    />
    <!-- ボディ -->
    <div class="px-6 pt-5 pb-6 overflow-y-auto scroll-bar-none flex-1">
      <!-- ポート名 -->
      <div class="text-left text-W7 text-[18px] leading-[27px]">
        {{ parkInfo.parkName }}
      </div>
      <!-- ポート詳細情報 -->
      <div class="mt-5 flex">
        <div class="flex-1 text-left">
          <div>
            <div class="text-W7 text-[13px] leading-[13px]">営業時間</div>
            <div
              v-if="is24Hours"
              class="text-W4 text-[14px] leading-[14px] mt-2"
            >
              24時間
            </div>
            <div v-else class="text-W4 text-[14px] leading-[14px] mt-2">
              {{ `${parkInfo.startTime}~${parkInfo.endTime}` }}
            </div>
          </div>
          <div class="mt-3">
            <div class="text-W6 text-[14px] leading-[14px]">住所</div>
            <div class="text-W4 text-[14px] leading-[21px] mt-1.5">
              {{ parkInfo.parkAddress }}
            </div>
          </div>
        </div>
        <div class="pl-[18px]">
          <!-- 画像 -->
          <img
            class="h-[90px] w-[142px] bg-white"
            :src="parkInfo.imageUrl"
            @error="setNoImageOnError($event)"
          />
        </div>
      </div>
      <!-- アクセス情報 -->
      <div class="mt-3.5">
        <BaseBox class="bg-gray200 w-full p-3">
          <template v-slot:explanation>
            <div class="text-W4 text-[14px] text-gray500 leading-[21px]">
              {{ parkInfo.howtoAccess }}
            </div>
          </template>
        </BaseBox>
      </div>
      <div class="mt-3">
        <BicycleRentInfoBox
          :cycleNum="parkInfo.cycleNum"
          :settingNum="parkInfo.settingNum"
          :basicCharge="planInfo.basic.basicCharge"
          :basicPeriod="planInfo.basic.basicPeriod"
          :useCharge="planInfo.use.useCharge"
          :usePeriod="planInfo.use.usePeriod"
        />
      </div>
      <!-- 予約可能なバイク情報 -->
      <div class="mt-8">
        <div class="text-left text-W7 text-[18px] leading-[18px]">
          予約可能なバイク
        </div>
        <div class="mt-2">
          <template v-for="cycle in cycleInfo.cycleList" :key="cycle.cycName">
            <div
              class="flex justify-between items-center py-3 border-b-[1px] border-gray300"
            >
              <div class="flex items-center">
                <div class="text-left text-W4 text-[14px] leading-[14px] pr-2">
                  {{ cycle.cycName }}
                </div>
                <img
                  :src="batteryIcon(cycle.batteryLevel)"
                  class="h-[11px] w-6"
                />
              </div>
              <TheButton
                text="予約"
                class="w-[50px] py-2 px-3 text-[12px] leading-[14px]"
                @click-button="showAvailableReservationPopup(cycle.cycName)"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 予約確認ポップアップ -->
    <Modal
      v-if="isBookingConfirmationModalFlg"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-9 pb-5">
        <!-- テキスト -->
        <div class="text-W5 text-[17px] leading-[25.5px] my-[-4.25px]">
          <span>予約の有効時間は15分程度です。</span>
          <br />
          <span>このバイクを予約しますか？</span>
        </div>
        <!-- ボタン -->
        <div class="flex justify-center mt-4 items-center">
          <div
            class="w-1/2 py-4 the-button-secondary text-W7 text-[15px] leading-[15px] mr-3"
            @click="cancelBicycleReservation()"
          >
            キャンセル
          </div>
          <div class="w-1/2">
            <TheButton text="OK" @click-button="clickBookingOkButton()" />
          </div>
        </div>
      </div>
    </Modal>
    <!-- 予約不可ポップアップ -->
    <Modal
      v-if="isNotReservableModalFlg"
      class="modal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="pt-9 px-5 pb-5">
        <!-- テキスト -->
        <div class="pb-4 text-W5 text-[17px] leading-[25.5px]">
          <span>予約可能な自転車が</span>
          <br />
          <span>みつかりませんでした。</span>
        </div>
        <!-- ボタン -->
        <TheButton text="OK" @click-button="gotoBackPage()" />
      </div>
    </Modal>
  </div>
</template>

<script>
/**
 * 自転車選択画面コンポーネント
 */
import Header from '@/components/organisms/Header.vue'
import ArrangementUtil from '@/mixins/arrangementUtil.js'
import BaseBox from '@/components/atoms/BaseBox.vue'
import BicycleRentInfoBox from '@/components/molecules/arrangement/BicycleRentInfoBox.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Util from '@/mixins/util.js'
import Modal from '@/components/Modal.vue'
import deepcopy from 'deepcopy'

const BicycleReservationSelect = {
  name: 'BicycleReservationSelect',
  mixins: [ArrangementUtil, Util],
  components: {
    Header,
    BaseBox,
    BicycleRentInfoBox,
    TheButton,
    Modal,
  },
  data() {
    return {
      isNotReservableModalFlg: false, // 予約不可ポップアップフラグ
      isBookingConfirmationModalFlg: false, // 予約確認ダイアログ表示フラグ
      isBackPageFlg: false, // 前の画面に戻るフラグ（ポート詳細情報と選択自転車情報を初期するフラグ）
      planInfo: deepcopy(
        this.$store.getters['MobilityReservationStore/planInfoWithCommadCharge']
      ), // プラン情報
    }
  },
  computed: {
    /**
     * 営業時間が24時間営業か確認する
     * @return {boolean} true: 24時間営業
     */
    is24Hours() {
      const businessTime = {
        start: this.parkInfo.startTime,
        end: this.parkInfo.endTime,
      }
      return this.checkBusinessTime(businessTime)
    },
    /**
     * ポート詳細情報(ポート情報及び自転車情報一覧)
     */
    parkDetailInfo() {
      return this.$store.state.MobilityReservationStore.bicycle.parkDetailInfo
    },
    /**
     * ポート詳細取得APIから取得したポート情報
     */
    parkInfo() {
      return this.parkDetailInfo.parkInfo
    },
    /**
     * 自転車情報一覧
     */
    cycleInfo() {
      return this.parkDetailInfo.cycleInfo
    },
    /**
     * 前画面で選択されたポート情報
     */
    selectedParkInfo() {
      return this.$store.state.MobilityReservationStore.bicycle.selectedParkInfo
    },
  },
  unmounted() {
    if (this.isBackPageFlg) {
      // 詳細情報の初期化
      this.$store.commit('MobilityReservationStore/updateSelectedParkInfo', {})
      this.$store.commit('MobilityReservationStore/updateSelectedCycleInfo', {})
      this.$store.commit('MobilityReservationStore/updateParkDetailInfo', {})
    }
  },
  methods: {
    /**
     * ヘッダー戻るボタン押下処理
     */
    gotoBackPage() {
      this.$router.push({
        name: this.$config.DISPLAY_BICYCLE_RESERVATION_PORT_ON_MAP,
      })
      // データの初期化するフラグを立てる
      this.isBackPageFlg = true
    },
    /**
     * 有効時間確認ポップアップ用ポップアップのキャンセル処理
     */
    cancelBicycleReservation() {
      this.$store.commit('MobilityReservationStore/updateSelectedCycleInfo', {})
      this.isBookingConfirmationModalFlg = false
    },
    /**
     * 予約確認ダイアログ内のOKボタン押下処理
     */
    clickBookingOkButton() {
      this.$store.commit('startLoading')
      const vm = this
      // 成功時
      const success = () => {
        // データ登録
        vm.$store.commit('endLoading')
        vm.gotoTermsPage()
      }
      // 失敗時
      const failed = () => {
        vm.$store.commit('endLoading')
        vm.isBookingConfirmationModalFlg = false
        throw new Error()
      }

      // 異常時
      const error = (e) => {
        vm.$store.commit('endLoading')
        vm.isBookingConfirmationModalFlg = false
        throw e
      }
      // ポートの事業者ID、エリアIDを取得
      const entId = vm.parkInfo.entId
      const areaId = vm.selectedParkInfo.areaId

      // 自転車利用規約取得API実行
      vm.$store.dispatch('MobilityReservationStore/getBicycleTerms', {
        success,
        failed,
        error,
        entId,
        areaId,
      })
    },
    /**
     * お支払いページに遷移
     */
    gotoTermsPage() {
      this.$router.push({
        name: this.$config.DISPLAY_BICYCLE_RESERVATION_PAYMENT_CONFIRM,
      })
      this.isBookingConfirmationModalFlg = false
    },
    /**
     * バッテリーアイコン取得
     * @param {Number} batteryLevel バッテリー残量
     * @return 表示画像
     */
    batteryIcon(batteryLevel) {
      switch (batteryLevel) {
        case 0: // eslint-disable-line no-magic-numbers
        case 1: // eslint-disable-line no-magic-numbers
        case 2: // eslint-disable-line no-magic-numbers
        case 3: // eslint-disable-line no-magic-numbers
          return require(`@/assets/arrangementIcon/bicycle/Battery${batteryLevel}.svg`)
        default:
          return undefined
      }
    },
    /**
     * 最新のポート情報を取得し、取得結果に応じてポップアップを表示
     * ・予約可能の場合：有効時間の確認ポップアップを表示
     * ・予約不可の場合：予約不可ポップアップを表示
     * @param {String} cycName 自転車識別番号
     */
    showAvailableReservationPopup(cycName) {
      // 現在表示しているポートIDを取得
      const parkId = this.parkInfo.parkId

      const vm = this
      // 最新の自転車情報を取得
      vm.$store.commit('startLoading')

      // 成功時
      const success = () => {
        vm.$store.commit('endLoading')

        // 選択した自転車が予約可能かを確認
        if (vm.checkPossibleBicycle(cycName)) {
          // 選択した自転車情報を保持
          const selectCycleInfo = vm.cycleInfo.cycleList.find((cycle) => {
            return cycle.cycName == cycName
          })
          vm.$store.commit(
            'MobilityReservationStore/updateSelectedCycleInfo',
            selectCycleInfo
          )
          // 有効時間確認ポップアップ表示
          vm.isBookingConfirmationModalFlg = true
        } else {
          // 予約不可ポップアップ表示
          vm.isNotReservableModalFlg = true
        }
      }

      // ポート詳細情報取得
      this.$store.dispatch(
        'MobilityReservationStore/getBicycleParkDetailInfoList',
        {
          success: success,
          parkId: parkId,
        }
      )
    },
    /**
     * 選択した自転車が予約可能かどうかを判定し返却
     * @param {String} cycName 自転車識別番号
     * @return 予約可能かどうか
     */
    checkPossibleBicycle(cycName) {
      // 予約可能な自転車が存在するかどうか
      const isEmptyCycle = () => {
        const noCycleNum = 0
        return noCycleNum < this.cycleInfo.getNum
      }

      // 選択している自転車が予約可能かどうか
      const isExistSelectCycle = () => {
        const findCycle = this.cycleInfo.cycleList.find((cycle) => {
          return cycle.cycName == cycName
        })
        return findCycle != null
      }

      return isEmptyCycle() && isExistSelectCycle()
    },
  },
}
export default BicycleReservationSelect
</script>

<style scoped></style>
