<template>
  <div id="favoriteSuggest" class="h-full w-full" ref="favoriteSuggest">
    <!--
      TODO:
        TextForm コンポーネントを変更すると他viewに影響が出るため、一旦 TextFormV2コンポーネントを新規作成
        デザイン改修終わり次第V2をとり、TextForm コンポーネントに戻す
    -->
    <TextFormV2
      class="searchText pt-5 px-5 pb-3"
      inputClass="bg-gray200 !border-[0px]"
      placeholder="登録したい場所を入力"
      @input-text="onchangeText"
      @click-back-button="goToBackPage()"
      maxlength="100"
      ref="textForm"
    />
    <div id="favoriteContents" class="text-left">
      <div :style="scrollStyle">
        <div v-if="isEditing" class="h-full">
          <!-- 入力時 -->
          <AddressSuggestList
            class="address-suggest-list"
            :address="word"
            @click-address-suggest="clickAddressSuggestList($event)"
          />
          <SuggestList
            :spots="spots"
            @click-suggest="completeForm($event)"
            class="suggest-list"
          />
          <div class="h-5 w-full" />
        </div>
        <div v-else class="h-full">
          <!-- 未入力時 -->
          <div class="ml-1">
            <!-- 履歴 -->
            <div class="mt-2 text-W7 text-left text-[13px] leading-none px-5">
              履歴
            </div>
            <SpotHistoryList
              :spotHistoryList="spotHistoryList"
              @click-spot-history="completeForm($event)"
              class="history-list"
            />
            <div class="h-5 w-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextFormV2 from '@/components/TextFormV2.vue'
import SuggestList from '@/components/SuggestList.vue'
import SpotHistoryList from '@/components/organisms/searchRoute/SpotHistoryList.vue'
import AddressSuggestList from '@/components/AddressSuggestList.vue'
import Util from '@/mixins/util'
import NativeUtil from '@/mixins/nativeUtil'

const LOAD_SPOT_API_SEC = 100

const FavoriteSuggestList = {
  name: 'FavoriteSuggestList',
  components: {
    TextFormV2,
    SuggestList,
    SpotHistoryList,
    AddressSuggestList,
  },
  mixins: [Util, NativeUtil],
  data() {
    return {
      word: '',
      spots: [],
      timer: 0,
      isEditing: false, // true:入力中、false:未入力
      spotHistoryList: [],
    }
  },
  emits: ['click-gotoBack', 'select-spot', 'no-address'],
  created() {
    //ローカルストレージから履歴取得
    this.getHistory(this.$config.SEARCH_HISTORY_STORAGE_KEY)
    // フッター部を非表示にする
    this.$store.commit('updateShowFooter', false)
  },
  mounted() {
    this.$refs.textForm.reset()
    // 入力フォームにフォーカスを当てる
    this.$refs.textForm.focusInputForm()
  },
  beforeUnmount() {
    // フッター部を表示する
    this.$store.commit('updateShowFooter', true)
  },
  computed: {
    /**
     * キーボード表示判定（iOSのみ判定する）
     */
    isShowKeyboard() {
      if (this.isIosWebView()) {
        return this.$store.state.isShowKeyboard
      } else {
        return false
      }
    },
    /**
     * スクロールエリアのスタイル
     */
    scrollStyle() {
      if (this.isShowKeyboard) {
        // キーボード表示時は内部スクロールが表示されないように全表示
        return {
          'overflow-y': 'visible',
          height: '100%',
        }
      } else {
        // キーボード非表示時は特定の範囲内でスクロールされるようにする
        return {
          'overflow-y': 'auto',
          height: 'calc(100vh - 88px)',
        }
      }
    },
  },
  watch: {
    isShowKeyboard: {
      handler(newVal) {
        // キーボード非表示時にスクロールをリセットする
        if (!newVal) {
          this.$refs.favoriteSuggest.scrollTo({top: 0})
        }
      },
    },
  },
  methods: {
    /**
     * 入力文字の内容を用いて地点検索を行う
     * @param {String} value 検索文字
     */
    onchangeText(value) {
      this.word = value
      clearTimeout(this.timer)

      //入力中かの判定
      this.isEditing = value != ''
      if (!this.isEditing) {
        // 入力文字を削除して空文字（未入力状態）にした場合、スポット検索しない
        return
      }
      const vm = this
      this.spots = []
      const success = (spots) => {
        vm.spots = spots
      }

      this.timer = setTimeout(() => {
        vm.$store.dispatch('getSpot', {
          success: success,
          word: vm.word,
        })
      }, LOAD_SPOT_API_SEC)
    },
    /**
     * 住所検索
     * @param value 住所検索キーワード
     */
    clickAddressSuggestList(value) {
      const vm = this

      vm.$store.commit('startLoading')

      const success = (addressInfo) => {
        vm.$store.commit('endLoading')
        // 検索結果が0件の場合はエラー表示
        if (addressInfo == null) {
          this.$emit('no-address')
          return
        }
        const searchAddressSpot = {
          name: '', // 住所検索の場合は空文字にする
          address: addressInfo.name,
          coord: `${addressInfo.coord.lat},${addressInfo.coord.lon}`,
        }
        vm.completeForm(searchAddressSpot)
      }

      //住所検索を実行
      vm.$store.dispatch('getAddress', {
        success: success,
        word: value,
      })
    },
    /**
     * 親側に選択した地点を通知
     */
    completeForm(value) {
      this.$emit('select-spot', value)
    },
    /**
     *ローカルストレージから履歴一覧を取得
     */
    getHistory(key) {
      // ストレージから地点の履歴データ取得
      const storageItem = this.getLocalStorage(key)

      // 取得チェック
      if (this.isNull(storageItem) || this.isEmpty(storageItem)) {
        return
      }

      // 名称が現在地の場合は表示しないようにフィルターをかける
      if (Array.isArray(storageItem)) {
        this.spotHistoryList = storageItem.filter(
          (historyItem) => historyItem.name != '現在地'
        )
      }
    },
    /**
     * 前画面へ戻る
     */
    goToBackPage() {
      this.$emit('click-gotoBack')
    },
  },
}
export default FavoriteSuggestList
</script>
<style scoped>
.history-list :deep(.history-col),
.address-suggest-list,
.suggest-list :deep(.suggest-col) {
  padding-right: 20px;
  padding-left: 20px;
}
</style>
