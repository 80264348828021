<template>
  <div :class="disabled ? 'opacity-40' : ''">
    <form>
      <p id="radio1" class="p-[2px]" :class="additionalFormClass">
        <span
          class="radio-span"
          :class="additionalSpanClass"
          v-for="radioButton in radioButtonList"
          :key="radioButton.id"
        >
          <input
            type="radio"
            name="radio1"
            :id="'radio-id' + radioButton.id"
            :value="radioButton.id"
            v-model="checked"
            :disabled="disabled"
          />
          <label
            class="text-W4 text-[13px]"
            :class="additionalLabelClass"
            :for="'radio-id' + radioButton.id"
            @click="$emit('click-radio-button', radioButton.id)"
          >
            {{ radioButton.name }}
          </label>
        </span>
      </p>
    </form>
  </div>
</template>
<script>
const SelectRadioButtonForm = {
  name: 'SelectRadioButtonForm',
  components: {},
  data() {
    return {
      defaultChecked: 1,
    }
  },
  props: {
    // 選択している数値
    selectedNum: {type: Number, default: 1},
    // 表示するボタンのリスト
    radioButtonList: Array,
    // 全体に追加設定するクラス
    additionalFormClass: {type: String, default: ''},
    // 一つのボタンに追加設定するクラス
    additionalSpanClass: {type: String, default: ''},
    // 一つのボタンのテキストに追加設定するクラス
    additionalLabelClass: {type: String, default: ''},
    // 非活性判定
    disabled: {type: Boolean, default: false},
  },
  emits: ['click-radio-button'],
  computed: {
    // 出発到着ラジオボタンの制御
    checked: {
      get() {
        return this.selectedNum
      },
      set(value) {
        this.defaultChecked = value
      },
    },
  },
  methods: {},
}
export default SelectRadioButtonForm
</script>
<style scoped>
#radio1 {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f2f3f5;
  border-radius: 10px;
}
#radio1 span {
  width: 100%;
}
#radio1 input {
  display: none;
  position: absolute;
  left: 0px;
}
#radio1 label {
  display: block;
  padding: 4px 0;
  float: left; /* floatレイアウトで横並びに */
  width: 100%;
  text-align: center;
}
#radio1:after {
  /* clearfix */
  content: '';
  display: block;
  clear: left;
}
.radio-span > label {
  border-radius: 10px;
}
#radio1 input[type='radio'] + label {
  background-color: transparent;
  color: #979ca1;
  border: 0px solid transparent;
}
#radio1 input[type='radio']:checked + label {
  background-color: #ffffff;
  color: #1a1c21;
  border: 0px solid #ffffff;
  box-shadow: 0 2px 6px #00000026;
}
</style>
