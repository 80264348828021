<template>
  <div class="bg-gray200 h-full">
    <Header headerTitle="乗物手配" />
    <div class="calcedHeight overflow-y-auto w-full flex flex-col pb-8">
      <!-- 警告メッセージ -->
      <div
        v-if="isPaymentFailing"
        class="flex w-full border-danger300 mt-6 mb-2"
      >
        <div
          class="flex flex-wrap w-full bg-danger100 mx-5 p-4 border-[1px] border-solid border-danger300 rounded-[8px]"
        >
          <img src="@/assets/Icon_Caution.svg" class="h-[16px] w-[16px] mr-2" />
          <div class="text-W7 text-[13px] leading-[18px] text-danger300">
            未決済のご利用があります。
          </div>
          <div
            class="text-W4 text-[13px] text-left leading-[18px] pl-6 pt-[3px]"
          >
            決済方法を登録メールアドレス宛にお送りしていますので、ご確認ください。決済が完了すると移動手配機能のご利用が可能になります。
          </div>
        </div>
      </div>
      <!-- 画像エリア -->
      <div
        class="my-auto h-full min-h-[205px] max-h-[230px] bg-arrangement-image"
      ></div>
      <!-- 手配ボタンエリア -->
      <div class="mx-auto mt-2 mx-5 px-5 py-2 bg-white rounded-[16px]">
        <div
          v-for="key in Object.keys(arrangementList)"
          :key="arrangementList[key].id"
          class="flex w-full py-3"
          @click="clickArrangement(arrangementList[key].id)"
          :class="
            isPaymentFailing &&
            arrangementList[key].id !== arrangementList.CAR_SHARE.id
              ? 'opacity-40'
              : ''
          "
        >
          <img
            class="mr-2 w-8 h-8"
            :src="getImage(arrangementList[key].icon)"
          />
          <div class="flex items-center flex-wrap">
            <div class="text-left text-W7 text-[15px] leading-4 mr-[7px]">
              {{ arrangementList[key].name }}
            </div>
            <!-- カーシェアの場合 -->
            <div
              class="flex my-[2px]"
              v-if="arrangementList[key].id === arrangementList.CAR_SHARE.id"
            >
              <div class="text-gray text-W5 text-[13px] leading-[13px]">
                WEBサイトへ遷移
              </div>
              <IconLink class="w-3 h-3 ml-1 text-gray" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap bg-yellow100 text-left mt-5 mx-5 py-3 px-4 border-[1px] border-solid border-yellow200 rounded-[8px] items-center"
        @click="openCarShareRegisterHpUrl()"
      >
        <img
          src="@/assets/arrangementIcon/IconArrangementTimesRegister.svg"
          class="h-[32px] w-[54px] mr-3 mt-auto"
        />
        <div class="flex-1 overflow-hidden">
          <div class="flex items-center">
            <div
              class="text-W5 text-[11px] w-auto rounded-[8px] leading-[11px] py-1 px-2 bg-yellow300"
            >
              入会特典あり
            </div>
          </div>
          <div class="flex items-center pt-1.5">
            <div class="text-W7 text-[13px] leading-[13px]">
              カーシェアの新規入会はこちら
            </div>
            <IconLink class="w-3 h-3 ml-1 text-gray" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/organisms/Header.vue'
import IconLink from '@/components/icons/common/IconLink.vue'
import * as mobilityUtil from '@/utils/mobilityUtil'

/**
 * 手配画面トップ
 * ＜概要＞
 * モビリティ手配のトップ画面。
 * 予約したいモビリティをタップすることで、各モビリティ予約画面に遷移する。
 */
const ArrangementTopPage = {
  name: 'ArrangementTopPage',
  components: {
    Header,
    IconLink,
  },
  data() {
    return {
      arrangementList: this.$config.ARRANGEMENT_LIST, // 乗り物手配りスト
    }
  },
  created() {},
  computed: {
    /**
     * 決済が失敗状態であるかどうか
     * @return 決済失敗有無
     */
    isPaymentFailing() {
      return mobilityUtil.determiningPaymentFailing(
        this.$store.state.CreditCardsStore.paymentStatus
      )
    },
  },
  methods: {
    /**
     * 手配アイコンの取得
     */
    getImage(imageName) {
      return require(`@/assets/arrangementIcon/${imageName}`)
    },
    /**
     * 手配選択
     */
    clickArrangement(id) {
      // 未決済状態かつNamoの手配を使用する場合は処理をしない
      if (this.isPaymentFailing && id !== this.arrangementList.CAR_SHARE.id) {
        return
      }
      switch (id) {
        case this.arrangementList.TAXI.id:
          // タクシーを手配
          // 遷移元のパスをstoreに登録
          this.updateScreenTransitionSource()
          // 画面遷移
          this.$router.push({
            name: this.$config.DISPLAY_TAXI_RESERVATION_TOP,
          })
          break
        case this.arrangementList.BICYCLE.id:
          // 自転車を手配
          // 遷移元のパスをstoreに登録
          this.updateScreenTransitionSource()
          // 画面遷移
          this.$router.push({
            name: this.$config.DISPLAY_BICYCLE_RESERVATION_TOP,
          })
          break
        case this.arrangementList.CAR_SHARE.id:
          // カーシェアを手配
          // 外部Webサイトを開く
          window.open(
            process.env.VUE_APP_TIMES_CAR_SHARE_HP_URL,
            '_blank',
            'noreferrer'
          )
          break
        default:
      }
    },
    /**
     * 遷移元のパスをstoreに登録する
     */
    updateScreenTransitionSource() {
      this.$store.commit(
        'MobilityReservationStore/updateScreenTransitionSource',
        {
          displayName: this.$config.DISPLAY_ARRANGEMENT_TOP,
          footerId: this.$store.state.selectedFooterTab,
        }
      )
    },
    /**
     * カーシェア新規登録ページを開く
     */
    openCarShareRegisterHpUrl() {
      window.open(
        process.env.VUE_APP_TIMES_CAR_SHARE_REGISTER_HP_URL,
        '_blank',
        'noreferrer'
      )
    },
  },
}
export default ArrangementTopPage
</script>
<style scoped>
.calcedHeight {
  height: calc(100% - 49px - 48px);
}
.bg-arrangement-image {
  background-image: url(@/assets/arrangementIcon/ArrangementSelect.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
</style>
