export default {
  namespaced: true,
  state: {
    //遷移元パス
    screenTransitionSource: {
      displayName: '',
      footerId: '',
    },
  },
  mutations: {
    /**
     * 遷移元パス更新処理
     * @param {*} state Storeオブジェクト
     * @param {*} obj 遷移元パス
     */
    updateScreenTransitionSource(state, obj) {
      state.screenTransitionSource = {
        displayName: obj.displayName,
        footerId: obj.footerId,
      }
    },
  },
}
