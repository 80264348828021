<template>
  <div>
    <!-- 参照モード -->
    <div v-if="isReferenceMode">
      <!-- テキストフォーム -->
      <TextForm
        v-if="isTextType"
        :maxlength="maxlength"
        :text="text"
        :placeholder="placeholder"
        :isBackButton="false"
        :inputClass="inputClass"
        :isDisabled="true"
        :type="text"
      />
      <DateForm
        v-if="isDateType"
        :defaultDate="text"
        :placeholder="placeholder"
        :isDisabled="true"
        :inputClass="inputClass"
        @input-text="$emit('input-text', $event)"
      />
    </div>
    <!-- 編集モード -->
    <div v-else>
      <!-- バリデーション付きテキストフォーム -->
      <TextFormWithValidation
        ref="Form"
        v-if="isTextType"
        :maxlength="maxlength"
        :text="text"
        :placeholder="placeholder"
        :errorMessage="errorMessage"
        :validationType="validationType"
        :inputClass="inputClass"
        :isDisabled="false"
        @input-text="$emit('input-text', $event)"
      />
      <!-- バリデーション付き日付フォーム -->
      <DateFormWithValidation
        ref="Form"
        v-if="isDateType"
        :defaultDate="text"
        :placeholder="placeholder"
        :isDisabled="false"
        :errorMessage="errorMessage"
        :validationType="validationType"
        :inputClass="inputClass"
        @input-text="$emit('input-text', $event)"
      />
    </div>
  </div>
</template>
<script>
import TextForm from '@/components/TextForm.vue'
import TextFormWithValidation from '@/components/molecules/TextFormWithValidation.vue'
import DateForm from '@/components/DateForm.vue'
import DateFormWithValidation from '@/components/molecules/DateFormWithValidation.vue'

// 参照モード
const DISPLAY_MODE_REFERENCE = 0

const FormInEachMode = {
  name: 'FormInEachMode',
  components: {
    TextForm,
    TextFormWithValidation,
    DateForm,
    DateFormWithValidation,
  },
  props: {
    // 画面モード(参照か編集)
    mode: {
      type: Number,
      default: DISPLAY_MODE_REFERENCE,
    },
    // 入力フォームのタイプ
    type: {
      type: String,
      default: 'text',
    },
    // バリデーションの種類
    validationType: {
      type: Array,
      default: [],
    },
    // 入力テキスト
    text: {
      type: String,
      require: true,
    },
    // 最大文字数
    maxlength: {
      type: String,
      default: '-1',
    },
    // プレースホルダー
    placeholder: {
      type: String,
      default: '',
    },
    // エラー時のテキスト
    errorMessage: {
      type: String,
      default: '',
    },
  },
  emits: ['input-text'],
  computed: {
    /**
     * 参照モードかどうか判定
     */
    isReferenceMode() {
      return this.mode === DISPLAY_MODE_REFERENCE
    },
    /**
     * 各モード時のクラスを返す
     */
    inputClass() {
      const baseClass = 'bg-gray200 border-gray200'
      const textColor = this.isReferenceMode
        ? ' !text-gray400'
        : ' !text-gray600'
      return baseClass + textColor
    },
    /**
     * 入力タイプがtextか判定
     */
    isTextType() {
      return this.type === 'text'
    },
    /**
     * 入力タイプがdateか判定
     */
    isDateType() {
      return this.type === 'date'
    },
  },
}
export default FormInEachMode
</script>
