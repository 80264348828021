<template>
  <BaseCard>
    <div class="px-1.5 pb-1.5">
      <div class="text-W7 text-left text-[15px] leading-[26px]">
        この場所でよろしいですか？
      </div>
      <!-- 目的地選択 -->
      <BaseBox class="mt-1.5">
        <template v-slot:explanation>
          <div
            class="flex flex-row items-center bg-blue200 rounded-[4px] py-[6px] px-3"
          >
            <div class="flex flex-1 flex-col grid">
              <div class="flex flex-row items-center">
                <img
                  src="@/assets/arrangementIcon/taxi/IconDropOffPin.svg"
                  class="h-[13px] w-2.5"
                />
                <div class="ml-1.5 text-W4 text-xs leading-[18px] text-gray500">
                  目的地
                </div>
              </div>
              <div class="mt-[2px] h-[18px]">
                <BaseLoading v-if="isFetching" />
                <div
                  v-else
                  class="text-[12px] leading-[18px] truncate"
                  :class="addressNameFieldClass"
                >
                  {{ addressNameFieldText }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </BaseBox>
      <TheButton
        text="ここに行く"
        class="mt-4"
        @click-button="$emit('click-go-there')"
        :isDisabled="isButtonDisabled"
      />
    </div>
  </BaseCard>
</template>
<script>
import BaseBox from '@/components/atoms/BaseBox.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import BaseLoading from '@/components/atoms/BaseLoading.vue'
import BaseCard from '@/components/atoms/BaseCard.vue'

/**
 * この場所でよろしいですか?コンポーネント
 */
const ConfirmDestinationCard = {
  name: 'ConfirmDestinationCard',
  components: {TheButton, BaseBox, BaseLoading, BaseCard},
  emits: ['click-go-there'],
  props: {
    // 目的地住所
    destinationAddress: {
      Type: String,
      require: true,
    },
    // 取得中フラグ
    isFetching: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    /**
     * 住所が設定されていないかどうかを返却
     */
    isNoSettingDestinationAddress() {
      return this.destinationAddress == ''
    },
    /**
     * 取得中もしくは住所がない(空文字)の場合はボタンを非活性にする
     */
    isButtonDisabled() {
      return this.isFetching || this.isNoSettingDestinationAddress
    },
    /**
     * 住所名表示エリアに設定する追加クラスを返却
     */
    addressNameFieldClass() {
      return this.isNoSettingDestinationAddress
        ? 'text-W2 text-gray'
        : 'text-W6'
    },
    /**
     * 住所名表示エリアに表示するテキストを返却
     */
    addressNameFieldText() {
      return this.isNoSettingDestinationAddress
        ? '住所が取得できません'
        : this.destinationAddress
    },
  },
}
export default ConfirmDestinationCard
</script>
<style scoped></style>
