<template>
  <div
    id="panel-info"
    class="fixed bottom-0 pb-safe-area bg-white w-full rounded-t-[20px] z-[500] shadow-panel"
  >
    <div class="my-4 mx-6 flex justify-stretch gap-x-1 items-center">
      <div
        class="flex-1 rounded-[20px] text-W4 text-[15px] leading-[15px] bg-white text-danger300"
        @click="onClickNegativeButton()"
        :class="addNegativeClass"
      >
        {{ negativeName }}
      </div>
      <div class="flex-1">
        <TheButton
          :text="positiveName"
          :isDisabled="!positiveEnabled"
          @click-button="onClickPositiveButton()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '@/components/atoms/TheButton.vue'
/**
 * 選択パネルコンポーネント
 */

const SelectPanel = {
  name: 'SelectPanel',
  components: {TheButton},
  emits: ['click-positive', 'click-negative'],
  props: {
    // ポジティブボタンの表示名
    positiveName: {
      type: String,
      require: true,
    },
    // ネガティブボタンの表示名
    negativeName: {
      type: String,
      require: true,
    },
    // ポジティブボタンの活性状態
    positiveEnabled: {
      type: Boolean,
      default: true,
      require: false,
    },
    // ネガティブボタンの活性状態
    negativeEnabled: {
      type: Boolean,
      default: true,
      require: false,
    },
  },
  computed: {
    /**
     * ネガティブボタンのクラス追加
     */
    addNegativeClass() {
      return this.negativeEnabled ? '' : 'opacity-40'
    },
  },
  methods: {
    /**
     * ネガティブボタンのクリック処理
     */
    onClickNegativeButton() {
      if (this.negativeEnabled) {
        this.$emit('click-negative')
      }
    },
    /**
     * ポジティブボタンのクリック処理
     */
    onClickPositiveButton() {
      if (this.positiveEnabled) {
        this.$emit('click-positive')
      }
    },
  },
}
export default SelectPanel
</script>

<style scoped></style>
