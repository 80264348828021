<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 17">
    <g transform="translate(-259 -332)">
      <rect
        width="30"
        height="17"
        rx="8.5"
        transform="translate(259 332)"
        fill="#d11f2e"
      />
      <text
        transform="translate(265 344)"
        fill="#fff"
        font-size="9"
        font-family="HiraginoSans-W4, Hiragino Sans"
      >
        <tspan x="0" y="0">重要</tspan>
      </text>
    </g>
  </svg>
</template>
