<template>
  <div>
    <!-- セレクトフォーム -->
    <div
      :class="textColor()"
      class="rounded-[56px] flex items-center text-W4 text-[15px] text-left bg-gray200 px-4 py-3"
      @click="clickForm()"
    >
      <p class="whitespace-nowrap text-ellipsis mr-auto overflow-hidden">
        {{ selectedLabel }}
      </p>
      <img class="h-2.5 w-2.5" src="@/assets/Icon_down_gray.svg" />
    </div>
    <!-- セレクトボックス -->
    <SelectBox
      v-if="isShowSelectBox"
      :headerTitle="selectBoxTitle"
      :selectItems="selectItems"
      @close-select-box="isShowSelectBox = false"
      @click-item="clickItem($event)"
    />
  </div>
</template>

<script>
import SelectBox from '@/components/molecules/SelectBox.vue'

const SelectForm = {
  name: 'SelectForm',
  components: {SelectBox},
  emits: ['update:modelValue'],
  props: {
    /**
     * タイトルの表示有無
     */
    selectBoxTitle: {
      type: String,
      default: '',
    },
    /**
     * 未選択時に表示するテキスト
     */
    unselectedLabel: {
      type: String,
      default: '選択してください',
    },
    /**
     * 選択肢
     */
    selectItems: {
      type: Object,
      required: true,
    },
    /**
     * 活性状態
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * v-model用
     * 選択した値が保持される
     */
    modelValue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      // SelectBox表示切り替え用フラグ
      isShowSelectBox: false,
    }
  },
  computed: {
    /**
     * 表示用の選択したアイテムのラベル
     * 未選択の場合は未選択用のテキストを返却
     */
    selectedLabel() {
      return this.modelValue
        ? this.getItemLabelById(this.modelValue)
        : this.unselectedLabel
    },
  },
  methods: {
    /**
     * アイテムがクリックされた際の処理
     * SelectBoxを非表示にし、v-modelの値を更新
     * @param {Object} item クリックされたアイテムのオブジェクト
     */
    clickItem(item) {
      this.isShowSelectBox = false
      this.$emit('update:modelValue', item.id)
    },
    /**
     * SelectBoxの表示処理
     * 非活性の場合はSelectBoxを表示しない
     */
    clickForm() {
      if (this.isDisabled) return
      this.isShowSelectBox = true
    },
    /**
     * テキスト色を活性状態によって返却する処理
     */
    textColor() {
      return this.isDisabled ? 'text-gray400' : 'text-gray600'
    },
    /**
     * 選択せされたIDに紐づくラベルを返す
     * @param {Number} selectedId 選択されたアイテムのID
     */
    getItemLabelById(selectedId) {
      return Object.values(this.selectItems).find(
        (item) => item.id === selectedId
      )?.label
    },
  },
}

export default SelectForm
</script>

<style scoped></style>
