<template>
  <div class="flex w-full">
    <img :src="getIcon()" class="h-3 w-3 overflow-hidden relative" />
    <div class="grid w-full">
      <span
        class="flex-1 ml-2 text-W4 text-[13px] leading-[13px] text-left truncate"
      >
        {{ this.spotName }}
      </span>
    </div>
  </div>
</template>
<script>
/**
 * 地点名のコンポーネント
 */
const SpotName = {
  name: 'SpotName',
  props: {
    /**
     * 地点名
     */
    spotName: {
      type: String,
      require: true,
    },
    /**
     * 出発地、経由地、目的地のタイプ
     * validatorにてstart、via、goal以外が渡ってきた場合、エラーを出力する
     */
    type: {
      type: String,
      require: true,
      validator(value) {
        return ['start', 'via', 'goal'].includes(value)
      },
    },
  },
  methods: {
    /**
     * typeに応じたアイコンを返す
     * @returns アイコン
     */
    getIcon() {
      switch (this.type) {
        case this.$config.SPOT_TYPE_START:
          return require('@/assets/route/Icon_Optimal_Start.svg')
        case this.$config.SPOT_TYPE_VIA:
          return require('@/assets/route/Icon_Optimal_Via.svg')
        case this.$config.SPOT_TYPE_GOAL:
          return require('@/assets/route/Icon_Optimal_Goal.svg')
        default:
          return ''
      }
    },
  },
}
export default SpotName
</script>
<style scoped></style>
