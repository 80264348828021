import util from './util.js'
import * as mobilityUtil from '@/utils/mobilityUtil'
import config from '@/const/const.js'
const constant = config.constant

export default {
  methods: {
    /**
     * ストレージで保持している地点選択履歴の一覧を取得
     * @returns 地点選択履歴の一覧
     */
    getSpotHistoryList() {
      const historyList = util.methods.getLocalStorage(
        constant.SEARCH_HISTORY_STORAGE_KEY
      )
      return historyList != null ? historyList : []
    },
    /**
     * ストレージに引数で受け取った地点情報を保存する
     * @param {*} targetData 地点情報
     * @returns 追加後の地点選択履歴の一覧
     */
    setSpotHistoryList(targetData) {
      const baseHistoryList = this.getSpotHistoryList()
      const noDuplicationHistoryList = baseHistoryList.filter(
        // 重複していないデータのみに絞り込み
        (history) => {
          return this.checkDuplicateData(history, targetData) === false
        }
      )

      //履歴を追加
      noDuplicationHistoryList.unshift(targetData)

      //最新の10件以内になるように限定
      while (constant.MAX_HISTORY_NUM < noDuplicationHistoryList.length) {
        noDuplicationHistoryList.pop()
      }

      // ストレージに保存
      util.methods.setLocalStorage(
        constant.SEARCH_HISTORY_STORAGE_KEY,
        noDuplicationHistoryList
      )
      return noDuplicationHistoryList
    },
    /**
     * 重複チェック
     */
    checkDuplicateData(base, target) {
      return (
        base.name == target.name &&
        base.address == target.address &&
        base.coord == target.coord
      )
    },
    /**
     * 指定のモビリティの情報を返却する
     * @param {*} mobility モビリティ
     * @returns 指定したモビリティの情報
     */
    getMobilityInfo(mobility) {
      const entries = new Map([
        [
          constant.MOBILITY.TRAIN_BUS,
          {
            dataType: 'total',
            component: constant.DISPLAY_ROUTE_BY_TRAIN_BUS,
          },
        ],
        [
          constant.MOBILITY.CAR,
          {
            dataType: 'carRecommend',
            component: constant.DISPLAY_ROUTE_BY_CAR,
          },
        ],
        [
          constant.MOBILITY.BICYCLE,
          {
            dataType: 'bicycle',
            component: constant.DISPLAY_ROUTE_BY_BICYCLE,
          },
        ],
        [
          constant.MOBILITY.WALK,
          {
            dataType: 'walk',
            component: constant.DISPLAY_ROUTE_BY_WALK,
          },
        ],
      ])
      const mobilityInfoList = Object.fromEntries(entries)
      return mobilityInfoList[mobility]
    },

    /**
     * ルートの乗り物アイコンを取得する
     * ルート詳細では、アイコンの種類が異なるため、mobilityUtilの処理は使用しない。
     * また、自転車・車は使用しないことを前提とする。
     * @param {*} section アイコンを取得したいmoveセクション情報
     */
    getRouteMobilityIcon(section, prevMoveSection) {
      let fileName
      switch (section.move) {
        // 徒歩
        case constant.MOVE.WALK:
          // 前のセクションの路線アイコンを表示させる
          if (prevMoveSection) {
            return this.getRouteMobilityIcon(prevMoveSection)
          }
          break
        // 飛行機
        case constant.MOVE.FLIGHT:
          fileName = 'route/Icon_Route_Flight.svg'
          break
        // フェリー
        case constant.MOVE.FERRY:
          fileName = 'route/Icon_Route_Ferry.svg'
          break
        // バス
        case constant.MOVE.SHUTTLE_BUS:
        case constant.MOVE.LOCAL_BUS:
        case constant.MOVE.HIGHWAY_BUS:
          fileName = 'route/Icon_Route_Bus.svg'
          break
        // 電車
        case constant.MOVE.SUPEREXPRESS_TRAIN:
        case constant.MOVE.SLEEPER_ULTRAEXPRESS:
        case constant.MOVE.ULTRAEXPRESS_TRAIN:
        case constant.MOVE.EXPRESS_TRAIN:
        case constant.MOVE.RAPID_TRAIN:
        case constant.MOVE.SEMIEXPRESS_TRAIN:
        case constant.MOVE.LOCAL_TRAIN:
          // mobilityUtilの処理を用いて、路線アイコンを取得する
          fileName = mobilityUtil.getLinksNameIcon(
            section.transport &&
              section.transport.links &&
              section.transport.links[0].id, // eslint-disable-line no-magic-numbers
            section.lineName
          )
          if (fileName == 'Icon_Move_Train.svg') {
            // デフォルトの路線アイコンを取得した場合、ルートで使用する電車アイコンに置換する
            fileName = 'route/Icon_Route_Train.svg'
          }
          break
        default:
          break
      }
      return fileName ? require('@/assets/' + fileName) : ''
    },
  },
}
