<template>
  <!-- ルート詳細(最適)画面 -->
  <div
    class="fixed top-0 bottom-0 w-full h-screen bg-white overflow-y-auto pt-safe-area"
  >
    <div id="RouteByOptimal" class="w-full min-h-full h-auto pb-[82px]">
      <!-- 地点・時間 -->
      <div id="optimalHeader" class="px-5 py-4 bg-white" :class="addShowClass">
        <div class="flex flex-col">
          <!-- 出発地 -->
          <dl class="flex items-center w-full pb-2">
            <img
              src="@/assets/Icon_Left_gray.svg"
              class="h-4 mr-2"
              @click="goToSearchTop()"
            />
            <dd
              class="w-full mr-3 flex-1 text-W4 text-area bg-gray200 flex items-center"
            >
              <img
                src="@/assets/route/Icon_Optimal_Start.svg"
                class="h-3 w-3"
              />
              <input
                class="bg-gray200 rounded-[8px]"
                type="text"
                v-model="searchConditions.start.name"
                readonly
                @click="showSuggest('start')"
              />
            </dd>
            <div>
              <img
                class="w-10 h-10"
                src="@/assets/Icon_Exchange.svg"
                @click="exchangeSpot()"
              />
            </div>
          </dl>
          <!-- 経由地 -->
          <div v-for="spot in showViaSpots" :key="spot.id">
            <dl class="flex items-center w-full pb-2">
              <div class="w-6" />
              <dd
                class="w-full mr-3 flex-1 text-W4 text-area bg-blue100 flex items-center"
              >
                <img
                  src="@/assets/route/Icon_Optimal_Via.svg"
                  class="h-3 w-3"
                />
                <input
                  class="bg-blue100"
                  type="text"
                  placeholder="経由地を追加"
                  v-model="spot.name"
                  readonly
                  @click="showSuggest('via', spot.id)"
                />
              </dd>
              <div class="w-10 h-10 flex items-center justify-center">
                <img
                  class="h-4"
                  src="@/assets/route/Icon_Optimal_Via_Delete_Button.svg"
                  @click="deleteViaInputBox(spot.id)"
                />
              </div>
            </dl>
          </div>
          <!-- 到着地 -->
          <dl class="flex items-center w-full">
            <div class="w-6" />
            <dd
              class="w-full mr-3 flex-1 text-W4 text-area bg-gray200 flex items-center"
            >
              <img src="@/assets/route/Icon_Optimal_Goal.svg" class="h-3 w-3" />
              <input
                class="bg-gray200"
                type="text"
                v-model="searchConditions.goal.name"
                readonly
                @click="showSuggest('goal')"
              />
            </dd>
            <div
              :class="displayViaAddMaker"
              class="w-10 h-10"
              @click="addViaInputBox()"
            >
              <img class="object-fill" src="@/assets/PlusCircle.svg" />
            </div>
          </dl>
        </div>
        <!-- 時間 -->
        <div class="mt-4">
          <div
            class="m-auto px-2 py-[6px] w-fit flex items-center rounded-[20px] border-[1px] border-gray400"
            @click="isShowCalenderSelectModal = true"
          >
            <img src="@/assets/route/Icon_Optimal_Clock.svg" class="h-4 w-4" />
            <div class="mx-2 text-W4 text-[13px] leading-[13px]">
              {{ searchDateTime }}
            </div>
            <img src="@/assets/Icon_down_gray.svg" class="h-[10px] w-[10px]" />
          </div>
        </div>
      </div>

      <!-- 移動手段の選択 -->
      <div class="mt-2 py-6 bg-white">
        <div
          class="ml-5 mb-4 text-W7 text-[17px] text-left"
          :class="addShowClass"
        >
          移動手段の選択
        </div>
        <MobilityCardList
          ref="mobilityCardList"
          :routeSearchResult="getResult"
          @complete-circle-animations="onCompleteCardCircleAnimations()"
          @complete-animations="onCompleteCardAnimations()"
          @select-mobility="onSelectMobility($event)"
        />
        <!-- 電車バスのルートに含める手段 -->
        <div
          class="ml-5 mt-4 mb-2 text-W6 text-[13px] text-left"
          :class="addShowClass"
        >
          電車バスのルートに含める移動手段
        </div>
        <MobilityRadioButton
          v-model:selectMobilityList="mobilityList"
          :class="addShowClass"
        />
      </div>
      <!-- CO2排出量 -->
      <Co2EmissionsGraph
        id="co2Graph"
        v-if="getResult"
        :co2List="getCo2EmissionsList"
        :class="addShowCo2GraphClass"
      />
      <FooterMargin />
    </div>
    <!-- 時刻設定ポップアップ -->
    <Teleport to="body">
      <Modal
        class="modal"
        v-if="isShowCalenderSelectModal"
        @disPlayModal="isShowCalenderSelectModal = false"
        modalPaddingBottom="8px"
      >
        <div id="select-datetime-modal">
          <CalendarSelectForm
            @completeDate="completeDate"
            :initialDateTime="searchConditions.targetTime"
            :initialTargetType="searchConditions.timeType"
          />
        </div>
      </Modal>
    </Teleport>
    <!-- 地点検索パネル -->
    <SearchOptimalSuggest
      :isShowSuggest="isShowSuggest"
      :targetSpot="selectSpotKey"
      :panelSpaceHeight="suggestSpaceHeight"
      @click-back-button="closeSuggest()"
      @select-spot="updateSpot($event)"
      @select-history-route="updateRoute($event)"
    />
  </div>
</template>
<script>
import Util from '@/mixins/util'
import SearchRouteUtil from '@/mixins/searchRouteUtil'
import Modal from '@/components/Modal.vue'
import CalendarSelectForm from '@/components/CalendarSelectForm.vue'
import SearchOptimalSuggest from '@/components/organisms/searchRoute/SearchOptimalSuggest.vue'
import deepcopy from 'deepcopy'
import MobilityCardList from '@/components/organisms/searchRoute/MobilityCardList.vue'
import Co2EmissionsGraph from '@/components/organisms/searchRoute/Co2EmissionsGraph.vue'
import MobilityRadioButton from '@/components/molecules/MobilityRadioButton.vue'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import {setRouteHistoryToLocalStorage} from '@/utils/localStorageUtil'

const RouteByOptimal = {
  name: 'RouteByOptimal',
  components: {
    Modal,
    CalendarSelectForm,
    SearchOptimalSuggest,
    MobilityCardList,
    Co2EmissionsGraph,
    MobilityRadioButton,
    FooterMargin,
  },
  data() {
    return {
      addShowClass: this.initAddShowClass(), // 各コンテンツの追加クラス
      addShowCo2GraphClass: this.initAddShowClass(), // Co2グラフの追加クラス
      isShowCalenderSelectModal: false, //時刻設定ポップアップフラグ
      isShowSuggest: this.$store.state.isShowSuggestFlg, // 地点検索パネルフラグ
      selectSpotKey: this.$store.state.searchSpotKey.key, // 更新対象の地点
      selectSpotViaId: this.$store.state.searchSpotKey.viaId, // 更新対象の経由地ID
      isNoExecuteReSearch: false, // 再検索未実行フラグ
      isPositionUpdateCondition: false, //位置情報更新による検索条件更新フラグ
      currentPosition: null, // 位置情報データ
      searchingConditions: deepcopy(this.$store.state.searchConditions), // 検索実行時の検索条件
    }
  },
  mixins: [Util, SearchRouteUtil],
  created() {
    // 天気初期化処理
    this.$store.commit('RouteStore/initWeather')
  },
  mounted() {
    if (!this.isInitSearchRoute) {
      // ルート検索設定を初期化する
      this.initSearchSetting()
      // 初回のルート検索実施フラグの切替
      this.$store.commit('updateSearchRoute', true)
    } else {
      // ルート取得済みの場合はアニメーションを実行しないので、背景色を付与しておく
      this.grantBackGroundColor()
    }
  },
  watch: {
    /**
     * 検索条件が更新されたら、ルート再検索を行う
     */
    searchConditions: {
      deep: true,
      handler() {
        if (this.isNoExecuteReSearch) {
          // 再検索未実行フラグが立っている場合は、何も行わない
          return
        }
        if (this.isPositionUpdateCondition) {
          // 位置情報更新による検索条件更新の場合は、フラグを落とすのみ
          this.isPositionUpdateCondition = false
          return
        }
        // 検索条件に空が存在する場合は検索を行わない
        if (this.isExistEmptySpot()) {
          return
        }
        this.getRouteAndAddress()
      },
    },
  },
  computed: {
    /**
     * ルートを取得済みかどうか
     */
    isCompleteSearchRoute() {
      return this.$store.state.RouteStore.isCompleteSearchRoute
    },
    /**
     * 初回ルート検索フラグを取得する
     */
    isInitSearchRoute() {
      return this.$store.state.isInitSearchRoute
    },
    /**
     * 各乗り物のid、名称、選択状態の配列
     */
    mobilityList: {
      /**
       * 各乗り物のid、名称、選択状態を取得する
       * @returns {Array} 各乗り物のid、名称、選択状態
       */
      get() {
        const options = this.searchConditions.searchOptions
        let arr = []
        for (let key in options) {
          const obj = {
            id: key,
            title: this.$config.SELECT_MOBILITY[key],
            isValid: options[key],
          }
          arr.push(obj)
        }
        return arr
      },
      /**
       * 各乗り物のid、名称、選択状態を更新する
       * @param {Array} value 各乗り物のid、名称、選択状態
       */
      set(value) {
        let arr = {}
        for (let mobility of value) {
          arr[mobility.id] = mobility.isValid
        }
        this.$store.commit('updateSearchOptions', {
          key: 'searchOptions',
          value: arr,
        })
      },
    },
    /**
     * 検索条件
     */
    searchConditions() {
      return this.$store.state.searchConditions
    },
    /**
     * 表示用の経由地配列
     */
    showViaSpots() {
      return this.searchConditions.via
    },
    /**
     * 経由地の表示確認
     * @param なし
     * @returns {Boolean} true:経由地表示している状態、false:経由地が非表示の状態
     */
    isViaSpots() {
      return this.$config.ARRAY_INDEX_ZERO < this.showViaSpots.length
    },
    /**
     * ＋ボタンの表示切替
     * @param なし
     * @returns {String} 表示枠数が3の場合、色が薄くなる
     */
    displayViaAddMaker() {
      return this.showViaSpots.length < this.$config.VIA_DATA_MAX_NUMBER
        ? ''
        : 'opacity-40'
    },
    /**
     * ルート検索結果を取得する
     */
    getResult() {
      return this.$store.state.RouteStore.routeSearchResult
    },
    /**
     * 自転車/徒歩のCO2排出量を表示するか
     */
    isCo2OfWalkAndBicycle() {
      return (
        !this.isNull(this.getResult.walk) &&
        !this.isNull(this.getResult.bicycle)
      )
    },
    /**
     * 徒歩のCO2排出量を表示するか
     */
    isCo2OfWalk() {
      return (
        !this.isNull(this.getResult.walk) && this.isNull(this.getResult.bicycle)
      )
    },
    /**
     * 自転車のCO2排出量を表示するか
     */
    isCo2OfBicycle() {
      return (
        this.isNull(this.getResult.walk) && !this.isNull(this.getResult.bicycle)
      )
    },
    /**
     * 電車バスのCO2排出量を表示するか
     */
    isCo2OfTotal() {
      return !this.isNull(this.getResult.total)
    },
    /**
     * 車のCO2排出量を表示するか
     */
    isCo2OfCar() {
      return !this.isNull(this.getResult.carRecommend)
    },
    /**
     * グラフコンポーネントに渡すリストを取得する
     */
    getCo2EmissionsList() {
      // CO2排出量一覧を生成
      let co2List = this.createCo2List()
      // CO2排出量の昇順に並べ替え
      co2List.sort((a, b) => a.value - b.value)
      // 順位を設定
      const rankedCo2List = this.setLankOfCo2EmissionList(co2List)

      return rankedCo2List
    },
    /**
     * 検索日時を取得する
     */
    searchDateTime() {
      return this.generateSearchDateTime(
        this.searchConditions.targetTime,
        this.searchConditions.timeType,
        false,
        this.$config.NOTATION_TYPE.SLASH
      )
    },
    /**
     * サジェストの上部スペース
     */
    suggestSpaceHeight() {
      return this.$store.state.topSafeAreaHeight
    },
  },
  methods: {
    /**
     * 初回のルート検索を設定する
     */
    async initSearchSetting() {
      const updateConditions = deepcopy(this.searchConditions)
      // 出発時刻に現在時刻を設定
      updateConditions.targetTime = this.getNow()

      // 検索条件を更新
      this.$store.commit('updateSearchConditions', {
        key: 'searchConditions',
        value: updateConditions,
      })
    },
    /**
     * 初回のコンテンツ表示クラス設定
     */
    initAddShowClass() {
      return this.$store.state.RouteStore.isCompleteSearchRoute
        ? ''
        : 'opacity-0'
    },
    /**
     * フェードインのアニメーションクラスの付与
     */
    grantFadeInClass() {
      this.addShowClass = 'fadeIn'
    },
    /**
     * 背景色を付与する
     */
    grantBackGroundColor() {
      document.getElementById('RouteByOptimal').classList.add('bg-gray200')
    },
    /**
     * CO2排出量の一覧を生成する
     * (徒歩/自転車、電車バス、車(最適))
     * @returns {Array} co2List CO2排出量の一覧
     */
    createCo2List() {
      let co2List = []
      if (this.isCo2OfWalkAndBicycle) {
        // 歩き・自転車
        co2List.push({
          icon: ['Icon_Walk.svg', 'Icon_Bicycle.svg'],
          name: '歩き・自転車',
          value: '0',
        })
      } else if (this.isCo2OfWalk) {
        // 歩き
        co2List.push({
          icon: ['Icon_Walk.svg'],
          name: '歩き',
          value: '0',
        })
      } else if (this.isCo2OfBicycle) {
        // 自転車
        co2List.push({
          icon: ['Icon_Bicycle.svg'],
          name: '自転車',
          value: '0',
        })
      }
      // 電車、バス
      if (this.isCo2OfTotal) {
        let co2Emissions =
          this.getResult.total[this.$config.ZERO].totalCo2Emissions
        co2List.push({
          icon: ['Icon_TrainBus.svg'],
          name: '電車・バス',
          value: this.roundAndConvertToKilo(co2Emissions, 2), // eslint-disable-line no-magic-numbers
        })
      }
      // 車
      if (this.isCo2OfCar) {
        let co2Emissions =
          this.getResult.carRecommend[this.$config.ZERO].totalCo2Emissions
        co2List.push({
          icon: ['Icon_Car.svg'],
          name: '車',
          value: this.roundAndConvertToKilo(co2Emissions, 2), // eslint-disable-line no-magic-numbers
        })
      }
      return co2List
    },
    /**
     * CO2排出量の中の順位を設定する
     * @param {Array} co2List CO2排出量リスト
     * @returns {Array} result 順位のリスト
     */
    setLankOfCo2EmissionList(co2List) {
      let result = deepcopy(co2List)
      let rank = 1 // 順位
      let addValue = 1 // 順位に加算する値（同率がある場合、この値に加算される）
      for (let i = this.$config.ZERO; i < result.length; i++) {
        if (this.$config.ZERO < i) {
          // eslint-disable-next-line no-magic-numbers
          if (result[i].value != result[i - 1].value) {
            // 順位加算
            rank += addValue
            addValue = 1 // eslint-disable-line no-magic-numbers
          } else {
            // 同率の場合、順位加算に加算
            addValue += 1 // eslint-disable-line no-magic-numbers
          }
        }
        // 順位を設定
        result[i].rank = rank
      }
      return result
    },
    /**
     *トップ画面に遷移
     */
    goToSearchTop() {
      // 再検索未実行フラグを立てる
      this.isNoExecuteReSearch = true

      this.$root.$refs.routeMap.currentSpotFit()

      // トップ画面に遷移
      this.$router.push({name: 'SearchTop'})
    },
    /**
     * 出発地と到着地を交換する
     */
    exchangeSpot() {
      this.$store.commit('exchangeSpots')
    },
    /**
     * 経由地入力枠の追加
     */
    addViaInputBox() {
      if (this.showViaSpots.length < this.$config.VIA_DATA_MAX_NUMBER) {
        this.$store.commit('addShowVia')
      }
    },
    /**
     * 経由地入力枠の削除
     * @param {String} target 削除対象の経由地情報のID
     */
    deleteViaInputBox(target) {
      // 検索条件用の更新
      this.$store.commit('deleteVia', target)
    },
    /**
     * 日付選択完了時処理
     * @param {String} dateTime 更新後の後時
     * @param {String} targetType 更新後の出発/到着情報
     */
    completeDate(dateTime, targetType) {
      const updateConditions = deepcopy(this.searchConditions)
      // 乗り換え検索時に使用する時間を保持
      updateConditions.targetTime = dateTime
      updateConditions.timeType = targetType
      // 検索条件に反映
      this.$store.commit('updateSearchConditions', {
        key: 'searchConditions',
        value: updateConditions,
      })

      // ポップアップを非表示にする
      this.isShowCalenderSelectModal = false
    },
    /**
     * 地点検索パネルの表示
     * @param {String} key 更新対象の地点先
     * @param {String} viaId 更新対象の経由地ID
     */
    showSuggest(key, viaId) {
      // 更新する地点先情報を保持
      this.selectSpotKey = key
      this.selectSpotViaId = viaId
      // 更新する地点をstoreに保持
      this.$store.commit('updateSearchSpotKey', {key: key, viaId: viaId})
      // 地点検索パネルを表示する
      this.isShowSuggest = true
    },
    /**
     * 地点検索パネルを閉じる
     */
    closeSuggest() {
      // 更新する地点先情報を初期化
      this.selectSpotKey = null
      this.selectSpotViaId = null
      // 更新する地点先情報のstoreを初期化
      this.$store.commit('updateSearchSpotKey', {})
      // 地点検索コンポーネントの表示フラグを初期化
      this.$store.commit('updateIsShowSuggestFlg', false)
      // 地点検索パネルを閉じる
      this.isShowSuggest = false
    },
    /**
     * 検索地点条件を設定
     * @param {Object} spot 選択した地点情報
     */
    setSearchConditionsSpot(spot) {
      let updateSpot = deepcopy(spot) // 検索条件用
      // 経由地の場合は、適切な配列の要素に格納
      if (this.selectSpotKey == this.$config.SPOT_TYPE_VIA) {
        // 一致する経由地を更新
        const viaArr = this.showViaSpots
        const updateViaArr = viaArr.map((spot) => {
          // 地点情報に表示用IDを付与
          updateSpot.id = this.selectSpotViaId
          // via識別番号の一致した中継地に検索した値をセットする（それ以外の場合は元の値をそのままセット）
          return spot.id == this.selectSpotViaId ? updateSpot : spot
        })
        updateSpot = updateViaArr
      }

      // 検索条件を更新
      this.$store.commit('updateSearchConditions', {
        key: this.selectSpotKey,
        value: updateSpot,
      })
    },
    /**
     * 地点選択した際の更新処理を行い、パネルを閉じる。
     * （現在地指定した際は、その時点の現在地情報を取得する）
     * @param {Object} spot 地点情報
     */
    updateSpot(spot) {
      // 検索条件を更新
      this.setSearchConditionsSpot(spot)
      // 選択地点情報を初期化し、地点検索パネルを閉じる
      this.closeSuggest()
    },
    /**
     * ルート履歴選択した際の更新処理を行い、パネルを閉じる。
     * @param {Object} selectedRoute 選択したルート履歴情報
     */
    updateRoute(selectedRoute) {
      // 現在の設定をベースに、出発/経由地/目的地のみをルート履歴の内容へ更新
      let updateConditions = structuredClone(this.searchConditions)
      updateConditions.start = selectedRoute.start
      updateConditions.goal = selectedRoute.goal
      updateConditions.via = selectedRoute.via
      this.$store.commit('updateSearchConditions', {
        key: 'searchConditions',
        value: updateConditions,
      })

      // 選択地点情報を初期化し、地点検索パネルを閉じる
      this.closeSuggest()
    },
    /**
     * Co2グラフのクラスを初期化する
     */
    initCo2GraphClass() {
      const co2GraphClassList = document.getElementById('co2Graph').classList
      co2GraphClassList.add('opacity-0')
      co2GraphClassList.remove('fadeInCo2Graph')
      co2GraphClassList.remove('fadeInCo2GraphForResearch')
    },

    /**
     * 現在地取得が必要であれば行い、そのデータを返却する関数
     */
    getPositionWhenNeedPosition(spotInfo) {
      let result
      if (
        spotInfo.name == '現在地' &&
        this.isNull(spotInfo.address) &&
        this.isNull(spotInfo.coord)
      ) {
        // 現在地取得が必要な場合
        if (this.currentPosition == null) {
          // 未取得であれば取得を実行
          this.currentPosition = this.$store.getters['currentSpotObject']
        }
        // 現在地情報を保持しておく
        result = deepcopy(this.currentPosition)
      }
      return result
    },
    /**
     * ルート検索を行うための準備処理
     * 現在地点の座標取得、検索条件の更新、検索前のルート情報・描画等の初期化を行う。
     *
     */
    prepareSearchRoute() {
      const updateConditions = deepcopy(this.searchConditions)
      // 検索条件をチェックして、現在地情報取得が必要であれば行う
      this.currentPosition = null
      for (let [key, value] of Object.entries(this.searchConditions)) {
        switch (key) {
          case this.$config.SPOT_TYPE_START:
          case this.$config.SPOT_TYPE_GOAL: {
            // キーが出発地or目的地の場合
            const data = this.getPositionWhenNeedPosition(value)
            if (data) {
              // 現在地の更新が必要なら更新する
              updateConditions[key] = data
            }
            break
          }
          case this.$config.SPOT_TYPE_VIA: {
            // キーが経由地の場合
            for (let i = 0; i < value.length; i++) {
              const data = this.getPositionWhenNeedPosition(value[i])
              if (data) {
                // 現在地の更新が必要なら更新する
                data.id = value[i].id
                updateConditions.via[i] = data
              }
            }
            break
          }
          default:
            break
        }
      }

      // 検索条件にアップデートがあるかどうかをチェック
      // 連想配列は単純比較不可のため、JSONで比較する
      if (
        JSON.stringify(updateConditions) !=
        JSON.stringify(this.searchConditions)
      ) {
        // 位置情報による検索条件更新フラグを立てる
        this.isPositionUpdateCondition = true
        // 検索条件を更新
        this.$store.commit('updateSearchConditions', {
          key: 'searchConditions',
          value: updateConditions,
        })
      }

      // 検索前にルート検索結果をクリアする
      this.$store.commit('RouteStore/updateRouteResult', {
        key: 'init',
      })
      // 保持ルートの初期化
      this.$store.commit('clearDrawRouteData')
    },
    /**
     * ルート検索・住所検索処理
     *
     * ローディング開始~停止、検索条件設定などルート検索に向けた準備を行った上、ルート検索（必要の場合は住所検索）を実行する。
     * 実行後は検索条件の再設定とルート履歴の保持を行う。
     */
    async getRouteAndAddress() {
      // 画面をロック
      this.$store.commit('startScreenLock')

      // 初回以外の場合は再ローディングを要求する
      if (this.isInitSearchRoute) {
        this.$refs.mobilityCardList.restartLoading()
      }

      // co2グラフの追加クラスを初期化する
      this.initCo2GraphClass()

      // ルート検索を行うための検索条件の準備を行う
      this.prepareSearchRoute()

      // ルート検索,住所検索を実行
      let isLoadingCancel = false
      Promise.allSettled([this.searchRoute(), this.getAddress()])
        .then(([resultSearchRoute, resultAddress]) => {
          // ルート検索の成功失敗問わず、実行時の検索条件を保持
          this.searchingConditions = structuredClone(this.searchConditions)
          // 検索が失敗している時はローディングキャンセルフラグを立てる
          isLoadingCancel = resultSearchRoute.status !== 'fulfilled'

          // ルート検索失敗時および住所検索失敗時は、以降のストレージへの保存処理は行わない
          if (resultSearchRoute.status !== 'fulfilled') return
          if (resultAddress.status !== 'fulfilled') return

          // ルート履歴を保存するためのデータを検索条件と住所検索結果をもとに生成
          const storageData = this.createRouteHistoryData(
            this.searchConditions,
            resultAddress?.value?.address
          )

          // ストレージへ保存
          setRouteHistoryToLocalStorage(storageData)
        })
        .finally(() => {
          // 検索ローディング終了処理実行
          if (this.$refs.mobilityCardList) {
            this.$refs.mobilityCardList.finishLoading(isLoadingCancel)
          }
        })
    },
    /**
     * ルート検索
     * @returns { Promise } API実行結果
     * @throws { Error } API処理失敗
     */
    searchRoute() {
      // ルート検索APIを実行
      return new Promise((resolve, reject) => {
        this.$store.dispatch('RouteStore/searchRouteInfo', {
          success: resolve,
          failed: reject,
        })
      })
    },
    /**
     * 住所情報の取得
     * @returns { Promise }
     *  ・検索条件に「現在地」がない場合：null \
     *  ・検索条件に「現在地」がある場合：API実行結果
     * @throws { Error } API処理失敗
     */
    getAddress() {
      // 現在地が設定されていないので初期値を返す
      const {lat, lon} = this.extractCurrentCoordFromSearchCondition()
      if (!lat || !lon) {
        // ここでは本処理は、成功扱いとする
        return Promise.resolve(null)
      }

      // 現在地が設定されているのでAPIレスポンスを返す
      return new Promise((resolve, reject) => {
        this.$store.dispatch('RouteStore/getAddressByCoord', {
          success: resolve,
          failed: reject,
          error: reject,
          lat: lat,
          lon: lon,
        })
      })
    },
    /**
     * 回転アニメーション完了時処理
     */
    onCompleteCardCircleAnimations() {
      this.$nextTick(() => {
        // ルート検索結果がない場合
        if (
          this.isNull(this.getResult.total) &&
          this.isNull(this.getResult.carRecommend) &&
          this.isNull(this.getResult.bicycle) &&
          this.isNull(this.getResult.walk)
        ) {
          // ルート取得完了済みの場合のみ処理を行う
          if (!this.isCompleteSearchRoute) {
            // フェードインアニメーション完了時処理
            const onFadeInAnimationEnd = () => {
              // リスナーを解除する
              document
                .getElementById('optimalHeader')
                .removeEventListener('animationend', onFadeInAnimationEnd)
              // 背景色を設定する
              this.grantBackGroundColor()
            }
            // フェードインのアニメーション完了検知のリスナーを設定する
            document
              .getElementById('optimalHeader')
              .addEventListener('animationend', onFadeInAnimationEnd)
            // コンテンツをフェードインさせる
            this.grantFadeInClass()

            this.$store.commit('RouteStore/updateIsCompleteSearchRoute', true)
          }
        } else {
          // ルート検索結果が存在する場合
          if (this.isCompleteSearchRoute) {
            // Co2グラフのフェードインアニメーションを設定する
            this.fadeInCo2Graph(true)
          }
        }
      })
    },
    /**
     * カードのアニメーション完了通知時処理
     */
    onCompleteCardAnimations() {
      if (this.isNoExecuteReSearch) {
        // 再検索未実行フラグが立っている場合は、何も行わない
        return
      }
      this.$nextTick(() => {
        if (!this.isCompleteSearchRoute) {
          // ルートが存在していなかった場合は、コンテンツをフェードインさせる
          this.grantFadeInClass()
          // Co2グラフのフェードインアニメーションを設定する
          this.fadeInCo2Graph(false)
          this.$store.commit('RouteStore/updateIsCompleteSearchRoute', true)
        }
        this.$store.commit('endScreenLock')
      })
    },
    /**
     * Co2グラフのフェードインアニメーションを設定する
     * @param {Boolean} isResearch 再検索実行かどうか
     */
    fadeInCo2Graph(isResearch) {
      // CO2グラフのアニメーション完了時処理
      const onCo2GraphAnimationEnd = () => {
        // リスナーを解除する
        document
          .getElementById('co2Graph')
          .removeEventListener('animationend', onCo2GraphAnimationEnd)
        // 背景色を設定する
        this.grantBackGroundColor()
      }
      // Co2グラフのフェードインアニメーション完了を検知するリスナーを設定する
      document
        .getElementById('co2Graph')
        .addEventListener('animationend', onCo2GraphAnimationEnd)

      // 再検索実行かどうかに合わせて、適切なアニメーションをCo2グラフに付与する
      document
        .getElementById('co2Graph')
        .classList.add(
          isResearch ? 'fadeInCo2GraphForResearch' : 'fadeInCo2Graph'
        )
    },
    /**
     * カードのモビリティ選択時処理
     * @param {String} event モビリティ
     */
    onSelectMobility(mobility) {
      // 選択したモビリティの情報を取得する
      const mobilityInfo = this.getMobilityInfo(mobility)
      // 情報orデータがない場合は遷移しない
      if (
        this.isNull(mobilityInfo) ||
        this.isNull(this.getResult[mobilityInfo.dataType])
      ) {
        return
      }

      // 再検索未実行フラグを立てる
      this.isNoExecuteReSearch = true

      // 検索条件が異なっている場合があるので、検索実行時の条件をstoreに反映
      this.$store.commit('updateSearchConditions', {
        key: 'searchConditions',
        value: this.searchingConditions,
      })

      // Storeの同期処理を呼び出し、以降の画面から別Storeの取得・更新を行う
      if (mobility == this.$config.MOBILITY.TRAIN_BUS) {
        this.processBeforeGoTrainBus()
      } else {
        this.processBeforeGoOtherMobility()
      }

      // ルート詳細画面に遷移
      this.$router.push({name: mobilityInfo.component})
    },
    /**
     * 電車バス画面に遷移する前に行う処理
     */
    processBeforeGoTrainBus() {
      // ルート詳細の日付と同期する
      this.$store.commit('RouteStore/updateTrainBusSearchConditions', {
        mode: 'sync',
      })
      // 電車・バスのルート詳細と同期する
      this.$store.commit('RouteStore/updateTrainBusRouteResult', {
        mode: 'sync',
      })
    },
    /**
     * 車、自転車、徒歩画面に遷移前のデータ同期作業
     * 遷移後は、別Storeを使用して再検索・情報の表示を行う
     */
    processBeforeGoOtherMobility() {
      // 再検索用の検索条件を同期する
      this.$store.commit('RouteStore/updateForResearchConditions', {
        mode: 'sync',
      })
      // 再検索用の検索結果を同期する
      this.$store.commit('RouteStore/updateForResearchResult', {
        mode: 'sync',
      })
    },
    /**
     * 空のスポットがないかどうかを判定する
     * 出発地、経由地、目的地のいずれか一つでも空があればtrueを返却する
     */
    isExistEmptySpot() {
      // 現在地設定の場合、address,coordが空文字の場合があるので、名称のみでチェックする
      let isExistEmpty =
        this.isNull(this.searchConditions.start.name) ||
        this.isNull(this.searchConditions.goal.name)
      for (let via of this.searchConditions.via) {
        isExistEmpty = isExistEmpty || this.isNull(via.name)
      }
      return isExistEmpty
    },
    /**
     * ルート検索履歴に保持するためのデータを作成し返却する
     *
     * 検索条件の地点名に対し、条件に当てはまる場合は住所名に更新する。
     * 条件:Nameに「現在地」,「自宅」,「会社/学校」,「名称なし」が設定されている
     * @param {Object} searchConditions 検索条件情報
     * @param {String} currentSpotAddress 現在地の住所名
     * @returns 更新後の検索条件情報
     */
    createRouteHistoryData(searchConditions, currentSpotAddress) {
      const after = structuredClone(searchConditions)
      const checkList = ['自宅', '会社/学校', '名称なし']

      // 検索地点名の差し替え処理
      const replaceSpotName = (spot) => {
        // 地点名がお気に入り地点の固定名称となっている場合、地点名に住所を設定
        if (checkList.includes(spot.name)) {
          spot.name = spot.address
          return
        }
        // 地点名が「現在地」であり、かつ現在地住所が存在すれば、地点名と住所に設定する
        if (spot.name == this.$config.CURRENT_NAME && currentSpotAddress) {
          spot.name = currentSpotAddress
          spot.address = currentSpotAddress
          return
        }
        // 上記に該当しない地点名の場合は、変更しない
      }

      // 検索条件の各地点名に対し、再設定を行う
      replaceSpotName(after[this.$config.SPOT_TYPE_START])
      replaceSpotName(after[this.$config.SPOT_TYPE_GOAL])
      const vias = after[this.$config.SPOT_TYPE_VIA]
      for (let via of vias) {
        replaceSpotName(via)
      }

      return after
    },
    /**
     * 検索条件にある現在地の座標を取得する
     * @returns 現在地の座標情報(数値に変換済)
     * @returns 現在地が見つからない場合は、緯度経度それぞれにNull値
     */
    extractCurrentCoordFromSearchCondition() {
      const copySearchConditions = structuredClone(this.searchConditions)
      const noCurrentSpotData = {lat: null, lon: null}

      // 検索条件の各地点情報を参照するためのkey
      const targetKeys = [
        this.$config.SPOT_TYPE_START,
        this.$config.SPOT_TYPE_GOAL,
        this.$config.SPOT_TYPE_VIA,
      ]
      // 地点情報をもとに現在地座標(lat,lon)を取得
      const findCurrentCoord = (spot) => {
        return spot.name == this.$config.CURRENT_NAME
          ? spot.coord.split(',').map((str) => parseFloat(str))
          : null
      }

      // 各地点に対して現在地の座標を取得しにいく
      let currentCoord = null
      for (let key of targetKeys) {
        // 既に現在地座標を見つけていれば探索は不要のためスキップ
        if (currentCoord != null) continue

        // まだ見つかっていない場合は各地点情報へ探索しにいく
        if (key == this.$config.SPOT_TYPE_VIA) {
          // 経由地の場合
          for (let via of copySearchConditions[key]) {
            currentCoord = findCurrentCoord(via)
            // 見つかった時点で探索は終了
            if (currentCoord) break
          }
        } else {
          // 出発/目的地の場合
          currentCoord = findCurrentCoord(copySearchConditions[key])
        }
      }
      // 現在地の座標を返却(見つからない場合は緯度軽度がそれぞれnullの構造を返却)
      if (!currentCoord) {
        return noCurrentSpotData
      }
      const [lat, lon] = currentCoord

      return {lat: lat, lon: lon}
    },
  },
}
export default RouteByOptimal
</script>
<style scoped>
.text-area {
  /* background-color: #f2f3f5; */
  height: 40px;
  width: 100%;
  border-radius: 8px;
  /* border: 0px solid #979ca1; */
  padding-left: 16px;
  padding-right: 16px;
  -webkit-appearance: none;
}
.text-area::placeholder {
  color: #979ca1;
  font-weight: 300;
  align-self: center;
}
.text-area > .label {
  font-size: 13px;
  line-height: 13px;
  align-self: center;
  min-width: 26px;
}
.text-area > input {
  /* background-color: #f2f3f5; */
  height: 100%;
  font-size: 15px;
  line-height: 16px;
  width: calc(100% - 16px);
  align-self: center;
  text-overflow: ellipsis;
  margin-left: 8px;
  -webkit-appearance: none;
}
/* フェードインアニメーション */
.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  opacity: 0;
}
/* Co2グラフのフェードインアニメーション */
.fadeInCo2Graph {
  animation-name: fadeInAnime;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  opacity: 0;
}
/* Co2グラフの再検索時のフェードインアニメーション */
.fadeInCo2GraphForResearch {
  animation-name: fadeInAnime;
  animation-duration: 0.4s;
  animation-delay: 0.5s; /** 0.5sは調整。タイミング的には0.4sのはず。 */
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  opacity: 0;
}

@keyframes fadeInAnime {
  100% {
    opacity: 1;
  }
}
</style>
