<template>
  <div>
    <!-- フォーム部分 -->
    <AmountForm
      v-model="model"
      :placeholder="placeholder"
      :inputClass="inputClass"
      :isDisabled="isDisabled"
      :underLineColor="underLineColor"
      @blur="updateValue()"
    />
    <!-- エラーメッセージ部分 -->
    <div
      v-show="isErrorFlg"
      class="mt-2 text-left text-danger300 text-W2 leading-[13px]"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import AmountForm from '@/components/atoms/AmountForm.vue'
import {useVuelidate} from '@vuelidate/core'
import {required, numeric, minValue, maxValue} from '@vuelidate/validators'

const AmountFormWithValidation = {
  name: 'AmountFormWithValidation',
  components: {
    AmountForm,
  },
  props: {
    // プレースホルダー
    placeholder: {
      type: String,
      default: '入力',
    },
    // 付与クラス
    inputClass: {
      type: String,
      default: '',
    },
    // inputタグの操作可否状態
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // v-model
    modelValue: {
      type: Number,
      default: null,
    },
    // エラー時のテキスト
    errorMessage: {
      type: String,
      default: '',
    },
    // バリデーションの種類
    validationType: {
      type: Array,
      required: true,
    },
    // 最小値
    minValue: {
      type: Number,
      default: 0,
    },
    // 最大値
    maxValue: {
      type: Number,
      default: 0,
    },
    // 初期表示時にバリデーションを行うか否か
    isFirstLoadValidation: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * バリデーションルールの付与
   */
  validations() {
    return {
      model: this.validationList(),
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      isErrorFlg: false, // エラーフラグ
    }
  },
  computed: {
    /**
     * ローカルで使用するv-model
     */
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
    /**
     * 入力文字がバリデーションエラーであるかどうか
     */
    isInvalid() {
      return this.v$.model.$invalid
    },
    /**
     * 下線の色を返す
     */
    underLineColor() {
      return this.isErrorFlg ? '!border-danger300' : ''
    },
  },
  async mounted() {
    if (this.isFirstLoadValidation) {
      await this.updateValue()
    }
  },
  methods: {
    /**
     * バリデーションルールの定義を作成する処理
     */
    validationList() {
      const validation = {}

      // 必須チェック付与
      if (this.validationType.includes('required')) {
        validation.required = required
      }
      // 数値チェック付与
      if (this.validationType.includes('numeric')) {
        validation.numeric = numeric
      }
      // 最小値チェック付与
      if (this.validationType.includes('minValue')) {
        validation.minValue = minValue(this.minValue)
      }
      // 最大値チェック付与
      if (this.validationType.includes('maxValue')) {
        validation.maxValue = maxValue(this.maxValue)
      }
      return validation
    },
    /**
     * input時に発火する処理
     */
    async updateValue() {
      // バリデーション結果取得
      const validationResult = await this.v$.model.$validate()
      // エラーがなければtrueが返ってくるので、falseをエラーフラグに詰める
      this.isErrorFlg = !validationResult
    },
  },
}

export default AmountFormWithValidation
</script>
