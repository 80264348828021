<template>
  <div
    class="radio-button text-W4 text-[13px] h-[29px] px-2"
    :class="getButtonColor()"
  >
    <img class="h-3 pr-1.5" :src="getRadioCircle()" />
    <div class="h-[13px] leading-[100%] text-[13px]">{{ title }}</div>
  </div>
</template>
<script>
const RadioButton = {
  name: 'RadioButton',
  components: {},
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
  },
  created() {},
  computed: {},
  methods: {
    getButtonColor() {
      return this.isValid ? 'border-blue' : 'text-gray500 border-gray'
    },
    getRadioCircle() {
      return this.isValid
        ? require('@/assets/RadioOn.svg')
        : require('@/assets/RadioOff.svg')
    },
  },
}
export default RadioButton
</script>
<style scoped>
.radio-button {
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.border-blue {
  border: 1px solid #009ce5;
}
.border-gray {
  border: 1px solid #979ca1;
}
</style>
