<template>
  <!-- 時刻表方面カード -->
  <div id="TimeTableDirectionCard">
    <!-- 大枠 -->
    <div class="flex pb-1 w-full">
      <!-- アイコン -->
      <img class="w-5 h-5" :src="getLinksIcon()" />
      <div
        class="ml-2 flex-1 self-center text-left text-W6 text-[15px] leading-5 truncate"
      >
        {{ link.name }}
      </div>
    </div>
    <div v-for="direction in directions" :key="direction.key" class="ml-2 mt-1">
      <div class="flex self-center" @click="onClickDirection(direction.key)">
        <div class="w-1 h-10" :style="{'background-color': link.color}" />
        <div
          class="pl-4 py-3 flex-1 text-W4 text-[15px] leading-[15px] text-left truncate"
        >
          {{ direction.value }}方面
        </div>
        <img
          src="@/assets/Icon_Right_gray.svg"
          class="h-3 ml-auto self-center"
        />
      </div>
      <!-- 線 -->
      <div class="ml-3 h-[0.5px] w-full bg-gray300" />
    </div>
  </div>
</template>

<script>
/**
 * 時刻表方面カードコンポーネント
 */
import Util from '@/mixins/util'
import * as mobilityUtil from '@/utils/mobilityUtil'
const TimeTableDirectionCard = {
  name: 'TimeTableDirectionCard',
  props: {
    link: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {}
  },
  emits: ['click-direction'],
  mixins: [Util],
  computed: {
    /**
     * 方面情報を取得する
     * return {*} [上り方面, 下り方面]。両方ない場合は[]
     */
    directions() {
      let results = []
      if (this.link.direction.up) {
        // 上り方面がある場合
        results.push({key: 'up', value: this.link.direction.up})
      }
      if (this.link.direction.down) {
        // 下り方面がある場合
        results.push({key: 'down', value: this.link.direction.down})
      }
      return results
    },
  },
  methods: {
    /**
     * カードクリック処理
     */
    onClickDirection(directionKey) {
      this.$emit('click-direction', {
        linkId: this.link.id,
        direction: directionKey,
        stationType: this.link.type,
      })
    },
    /**
     * 路線アイコンを取得する
     * @return 路線アイコン
     */
    getLinksIcon() {
      return mobilityUtil.getMobilityIconFromStationType(
        this.link.type,
        this.link.id,
        this.link.name
      )
    },
  },
}
export default TimeTableDirectionCard
</script>

<style></style>
