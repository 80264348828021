<template>
  <div
    class="w-full py-5 flex items-center border-b border-solid border-gray300"
    @click="$emit('click-payment-method-next')"
  >
    <!-- カードアイコン -->
    <img class="h-8 w-8" src="@/assets/payment/cardLogo/IconCreditCard.svg" />
    <div class="text-W4 text-[15px] leading-[15px]">
      <!-- カード番号(マスク付) -->
      <span class="ml-4">
        {{ cardNumber }}
      </span>
      <!-- カード有効期限 -->
      <span class="ml-2">
        {{ expire }}
      </span>
    </div>
    <!-- 右アイコン -->
    <IconListRightArrow v-if="isShowNextIcon" class="h-3 w-3 ml-auto mr-5" />
    <IconDelete
      v-if="isShowDeleteIcon"
      @click="$emit('click-payment-method-delete')"
      class="h-3 w-3 ml-auto mr-5"
    />
  </div>
</template>

<script>
import IconListRightArrow from '@/components/icons/common/IconListRightArrow.vue'
import IconDelete from '@/components/icons/common/IconDelete.vue'

const PaymentMethod = {
  name: 'PaymentMethod',
  emits: ['click-payment-method-next', 'click-payment-method-delete'],
  components: {
    IconListRightArrow,
    IconDelete,
  },
  props: {
    // カード番号
    cardNumber: {
      type: String,
      required: true,
    },
    // 有効期限
    expire: {
      type: String,
      required: true,
    },
    // 「>」アイコン表示フラグ
    isShowNextIcon: {
      type: Boolean,
      default: false,
    },
    // 「x」ボタン表示フラグ
    isShowDeleteIcon: {
      type: Boolean,
      default: false,
    },
  },
}
export default PaymentMethod
</script>
