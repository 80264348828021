<template>
  <div
    id="BicycleRentInfoBox"
    class="flex text-left border border-gray700 rounded-[4px]"
  >
    <div class="w-[93px] text-W7 text-[13px] leading-[13px]">
      <div class="col1 rounded-tl-[4px]">貸出可能数</div>
      <div class="col1">返却可能数</div>
      <div class="col1">貸出料金</div>
      <div class="col1 rounded-bl-[4px]">延長料金</div>
    </div>
    <div class="flex-1 text-W4 text-[14px] leading-[14px]">
      <div class="col2 rounded-tr-[4px]">{{ getCycleNum }}</div>
      <div class="col2">{{ getSettingNum }}</div>
      <div class="col2">税込{{ basicCharge }}円 / {{ basicPeriod }}分</div>
      <div class="col2 rounded-br-[4px]">
        税込{{ useCharge }}円 / {{ usePeriod }}分
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 駐輪場の貸出情報シートコンポーネント
 * ＜概要＞
 * 下記のようなレイアウトを構成するコンポーネント。
 * ・貸出可能数：2台
 * ・返却可能数：可
 * ・貸出料金：税込165円 / 30分
 * ・延長料金：税込165円 / 30分
 */

const BicycleRentInfoBox = {
  name: 'BicycleRentInfoBox',
  props: {
    // 貸出可能数
    cycleNum: {
      type: Number,
      require: true,
    },
    // 返却可能数
    settingNum: {
      type: Number,
      require: true,
    },
    // 基本料金
    basicCharge: {
      type: String,
      require: true,
    },
    // 基本利用期間 (分／貸出)
    basicPeriod: {
      type: Number,
      require: true,
    },
    // 延長料金
    useCharge: {
      type: String,
      require: true,
    },
    // 課金単位 (分)
    usePeriod: {
      type: Number,
      require: true,
    },
  },
  computed: {
    /**
     * 貸出可能数の取得
     */
    getCycleNum() {
      return `${this.cycleNum}台`
    },
    /**
     * 返却可能数の取得
     */
    getSettingNum() {
      // eslint-disable-next-line no-magic-numbers
      if (this.settingNum <= 0) {
        return '空きがありません'
        // eslint-disable-next-line no-magic-numbers
      } else if (this.settingNum === 1) {
        return '1台空きがあります'
      } else {
        return '返却可能'
      }
    },
  },
}
export default BicycleRentInfoBox
</script>

<style scoped>
.col1,
.col2 {
  border-width: 0.5px;
  border-color: #e5e7eb;
}
.col1 {
  padding: 11px 12px 12px 12px;
}
.col2 {
  padding: 11px 12px;
}
</style>
