<template>
  <OverlayComponent
    v-show="isShowOverlay"
    class="overscroll-contain"
    :isEnableCloseOutsideArea="true"
    @close-overlay="this.isShowPanel = false"
  >
    <template v-slot:body>
      <Transition @after-leave="closePanel()">
        <div
          v-if="isShowPanel"
          class="select-message-panel"
          ref="selectMessagePanel"
        >
          <div class="flex items-center px-6 py-6">
            <div class="text-[15px] text-W7">
              <slot name="header"></slot>
            </div>
            <img
              src="@/assets/IconCloseBlack.svg"
              class="h-4 w-4 ml-auto"
              @click="this.isShowPanel = false"
            />
          </div>
          <slot name="body"></slot>
        </div>
      </Transition>
    </template>
  </OverlayComponent>
</template>

<script>
import OverlayComponent from '@/components/Overlay.vue'

const HalfPanel = {
  name: 'HalfPanel',
  components: {OverlayComponent},
  emits: ['closePanel'],
  props: {
    isShow: Boolean,
  },
  data() {
    return {
      isShowOverlay: false, // オーバーレイの表示フラグ
      isShowPanel: false, // プルアップパネルの表示フラグ
    }
  },
  methods: {
    /**
     * プルアップパネルを閉じる時のメソッド
     */
    closePanel() {
      // 後ろの画面を開放
      this.isShowOverlay = false
      document.body.style.overflow = null
      this.$emit('closePanel')
    },
  },
  watch: {
    isShow(value) {
      if (value) {
        // 後ろの画面をロック
        document.body.style.overflow = 'hidden'
        this.isShowPanel = true
        this.isShowOverlay = true
      } else {
        this.isShowPanel = false
      }
    },
  },
}

export default HalfPanel
</script>

<style scoped>
.v-enter-active {
  animation: slide-in 0.3s;
}
.v-leave-active {
  animation: slide-in 0.3s reverse;
}
.select-message-panel {
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 2000;
}
@keyframes slide-in {
  0% {
    top: 100%;
    height: 0;
  }
  100% {
    top: 50%;
    height: 50%;
  }
}
</style>
