<template>
  <div class="flex flex-col">
    <!-- ヘッダー -->
    <div
      ref="header"
      class="flex w-full justify-around items-center bg-white h-[48px] pl-3.5 pr-2.5 sticky top-0 border-b-[1px] border-gray300 z-10"
    >
      <div class="ml-1.5">
        <!-- 戻るボタン -->
        <img
          v-if="isReferenceMode"
          src="@/assets/IconLeftBlack.svg"
          class="w-4 h-4"
          @click="clickPrevious()"
        />
        <!-- キャンセルボタン -->
        <div
          v-else
          class="flex relative justify-center items-center text-W4 text-[13px] leading-[19.5px] text-blue p-1.5"
          @click="clickCancelButton()"
        >
          キャンセル
        </div>
      </div>
      <!-- ヘッダータイトル -->
      <div
        class="text-W6 flex-1 leading-[26px] text-[15px]"
        :class="isReferenceMode ? '' : 'pr-[61px]'"
      >
        ユーザー情報
      </div>
      <!-- 編集ボタン -->
      <div
        v-if="isReferenceMode"
        class="flex relative justify-center items-center text-W4 text-[13px] leading-[19.5px] text-blue p-1.5"
        @click="clickEditButton()"
      >
        編集
      </div>
      <!-- 保存ボタン -->
      <div
        v-else
        class="flex relative justify-center items-center text-W4 text-[13px] leading-[19.5px] text-blue p-1.5"
        :class="saveButtonDisabled()"
        @click="clickSaveButton()"
      >
        保存
      </div>
    </div>
    <div id="userinfoForm" class="overflow-y-auto scroll-bar-none flex-1">
      <!-- ボディ -->
      <div class="mx-6">
        <!-- 入力フォーム -->
        <div class="text-left mt-6 mb-10">
          <div class="mb-5">
            <div class="text-W7 text-[13px] leading-[13px] mb-2">お名前</div>
            <div class="flex">
              <!-- 苗字 -->
              <FormInEachMode
                ref="FamilyNameForm"
                class="w-1/2"
                :mode="displayMode"
                type="text"
                :validationType="['required']"
                :text="userInfo.myoji"
                placeholder="東海"
                maxlength="100"
                errorMessage="苗字を入力してください。"
                @input-text="userInfo.myoji = $event"
              />
              <!-- 名前 -->
              <FormInEachMode
                ref="FirstNameForm"
                class="w-1/2 ml-1.5"
                :mode="displayMode"
                type="text"
                :validationType="['required']"
                :text="userInfo.na"
                placeholder="太郎"
                maxlength="100"
                errorMessage="名前を入力してください。"
                @input-text="userInfo.na = $event"
              />
            </div>
          </div>
          <div class="mb-5">
            <div class="text-W7 text-[13px] leading-[13px] mb-2">性別</div>
            <div class="w-full flex flex-row justify-between">
              <FormRadioButton
                v-for="genderObj in GENDER_LIST"
                :key="genderObj.key"
                :itemKey="genderObj.key"
                :label="genderObj.label"
                :isDisabled="isReferenceMode"
                v-model="userInfo.gender"
                class="mr-2 last:mr-0"
              />
            </div>
          </div>
          <div class="mb-5">
            <div class="text-W7 text-[13px] leading-[13px] mb-2">生年月日</div>
            <FormInEachMode
              ref="BirthdayDateForm"
              :mode="displayMode"
              type="date"
              :validationType="['required']"
              :text="userInfo.birthday"
              placeholder="1980/01/01"
              errorMessage="生年月日を入力してください。"
              @input-text="userInfo.birthday = $event"
            />
          </div>
          <div class="mb-5">
            <div class="text-W7 text-[13px] leading-[13px] mb-2">住所</div>
            <FormInEachMode
              ref="AddressForm"
              :mode="displayMode"
              type="text"
              :validationType="['required']"
              :text="userInfo.address"
              placeholder="東京都千代田区丸の内 1-1-1 東海ビル101"
              maxlength="100"
              errorMessage="住所を入力してください。"
              @input-text="userInfo.address = $event"
            />
          </div>
          <div class="mb-5">
            <div class="text-W7 text-[13px] leading-[13px] mb-2">電話番号</div>
            <FormInEachMode
              ref="TelForm"
              :mode="displayMode"
              type="text"
              :validationType="['required', 'tel']"
              :text="userInfo.tel"
              placeholder="07000123456"
              maxlength="100"
              errorMessage="正しい電話番号を入力してください。"
              @input-text="userInfo.tel = $event"
            />
          </div>
          <div>
            <div class="mb-2 flex flex-row items-end">
              <div class="text-W7 text-[13px] leading-[13px]">
                メールアドレス
              </div>
              <div class="text-W4 text-gray400 text-[11px] leading-[11px]">
                （編集不可）
              </div>
            </div>
            <FormInEachMode type="text" :text="userInfo.email" />
          </div>
        </div>
      </div>
    </div>
    <!-- ユーザー情報登録失敗時ポップアップ -->
    <Modal
      v-if="isShowSetupUserInfoFailedModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 font-semibold text-[17px] leading-[25.5px]">
          エラーが発生しました。
        </div>
        <div class="mt-3 mx-5 text-W3 text-[15px] leading-[23px]">
          通信環境をご確認いただくか、時間を置いて再度お試しください。
        </div>
        <div
          class="mt-5 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
          @click="isShowSetupUserInfoFailedModal = false"
        >
          もどる
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
/**
 * ユーザー情報参照画面
 */
import Header from '@/components/organisms/Header.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import FormRadioButton from '@/components/atoms/FormRadioButton.vue'
import FormInEachMode from '@/components/organisms/FormInEachMode.vue'
import Modal from '@/components/Modal.vue'
import Util from '@/mixins/util.js'
import NativeUtil from '@/mixins/nativeUtil.js'
import deepcopy from 'deepcopy'

const DISPLAY_MODE = {
  REFERENCE: 0,
  EDIT: 1,
}

const UserInfoDetail = {
  name: 'UserInfoDetail',
  components: {
    Header,
    Modal,
    TheButton,
    FormRadioButton,
    FormInEachMode,
  },
  mixins: [Util, NativeUtil],
  mounted() {
    // webViewの場合はFG復帰時の生体認証失敗処理を定義
    if (this.isWebView()) {
      this.defineResumeBioAuthFailedMethod()
    }
  },
  beforeUnmount() {
    // webViewの場合は生体認証失敗処理定義をwindowから削除
    if (this.isWebView()) {
      delete window.resumeBioAuthFailed
    }
  },
  data() {
    return {
      displayMode: DISPLAY_MODE.REFERENCE, // 画面表示モード
      userInfo: deepcopy(this.$store.state.user.info), // ユーザー情報
      defaultUserInfo: deepcopy(this.$store.state.user.info), // 編集前ユーザー情報
      isShowSetupUserInfoFailedModal: false, // ユーザー情報登録失敗ポップアップ
      GENDER_LIST: [
        this.$config.GENDER.MAN,
        this.$config.GENDER.WOMAN,
        this.$config.GENDER.NO_SELECT,
      ], // 性別選択リスト
    }
  },
  computed: {
    /**
     * 参照モードかどうかを返す
     */
    isReferenceMode() {
      return this.displayMode === DISPLAY_MODE.REFERENCE
    },
  },
  methods: {
    /**
     * 戻るボタン押下時の処理
     */
    clickPrevious() {
      // メニュー画面に遷移
      this.$router.push({name: 'Menu'})
    },
    /**
     * 編集ボタン押下時
     */
    clickEditButton() {
      this.displayMode = DISPLAY_MODE.EDIT
    },
    /**
     * キャンセルボタン押下時
     */
    clickCancelButton() {
      this.displayMode = DISPLAY_MODE.REFERENCE
      // 編集前のユーザー情報を復元
      this.userInfo = deepcopy(this.defaultUserInfo)
    },
    /**
     * 保存ボタン押下時
     */
    clickSaveButton() {
      // バリデーションエラー時は何もしない
      if (this.isEntryError()) {
        return
      }

      // 成功時
      const success = () => {
        this.$store.commit('endLoading')
        this.displayMode = DISPLAY_MODE.REFERENCE
        this.defaultUserInfo = deepcopy(this.$store.state.user.info)
      }

      // 失敗時
      const failed = () => {
        this.$store.commit('endLoading')
        // ユーザー情報登録失敗ポップアップ表示
        this.isShowSetupUserInfoFailedModal = true
      }

      // ユーザー情報更新API実行
      this.$store.commit('startLoading')
      this.$store.dispatch('updateUser', {
        success: success,
        failed: failed,
        userInfo: this.userInfo,
      })
    },
    /**
     * 入力フォームが一つでもエラーであるかどうか
     */
    isEntryError() {
      if (
        this.isNull(this.$refs.FamilyNameForm) ||
        this.isNull(this.$refs.FirstNameForm) ||
        this.isNull(this.$refs.BirthdayDateForm) ||
        this.isNull(this.$refs.AddressForm) ||
        this.isNull(this.$refs.TelForm)
      ) {
        return false
      }
      if (
        this.isNull(this.$refs.FamilyNameForm.$refs.Form) ||
        this.isNull(this.$refs.FirstNameForm.$refs.Form) ||
        this.isNull(this.$refs.BirthdayDateForm.$refs.Form) ||
        this.isNull(this.$refs.AddressForm.$refs.Form) ||
        this.isNull(this.$refs.TelForm.$refs.Form)
      ) {
        return false
      }
      return (
        this.$refs.FamilyNameForm.$refs.Form.isInvalid ||
        this.$refs.FirstNameForm.$refs.Form.isInvalid ||
        this.$refs.BirthdayDateForm.$refs.Form.isInvalid ||
        this.$refs.AddressForm.$refs.Form.isInvalid ||
        this.$refs.TelForm.$refs.Form.isInvalid
      )
    },
    /**
     * 非活性/活性時のスタイル
     */
    saveButtonDisabled() {
      return this.isEntryError() ? 'opacity-40' : ''
    },
    /**
     * FG復帰時の生体認証失敗処理をwindowに定義
     */
    defineResumeBioAuthFailedMethod() {
      window.resumeBioAuthFailed = () => {
        // メニュー画面に遷移
        this.$router.push({name: 'Menu'})
      }
    },
  },
}
export default UserInfoDetail
</script>

<style></style>
