<template>
  <div
    id="RouteNavi"
    class="w-full fixed bottom-0 z-[500] flex flex-col items-end !pointer-events-none"
    ref="RouteNavi"
  >
    <div
      class="bg-white w-full shadow-panel rounded-t-[20px] pointer-events-auto"
    >
      <!-- ヘッダー部 -->
      <div class="flex box-content mx-5 mt-4">
        <div class="text-W2 text-left ml-0.5 flex-1 truncate mr-6">
          <div class="start flex h-3">
            <img src="@/assets/Icon_Circle_white.svg" class="h-3" />
            <span class="ml-2 leading-3 truncate">
              {{ searchConditions.start.name }}
            </span>
          </div>
          <div
            v-if="isViaSpots"
            class="mt-2 text-W4 text-[12px] leading-3 text-blue h-3"
          >
            <div v-if="isOpenedVia" class="flex">
              <img src="@/assets/Icon_top.svg" class="h-2.5 mx-[1px]" />
              <span class="ml-2 leading-3" @click="isOpenedVia = !isOpenedVia">
                経由地を非表示
              </span>
            </div>
            <div v-else class="flex">
              <img src="@/assets/Icon_down.svg" class="h-2.5 mx-[1px]" />
              <span class="ml-2 leading-3" @click="isOpenedVia = !isOpenedVia">
                経由地を表示
              </span>
            </div>
          </div>
          <transition
            name="accordion"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
            @after-leave="afterLeave"
          >
            <div v-show="isOpenedVia">
              <div v-for="spot in viaSpots" :key="spot.id">
                <div class="via flex mt-[9px] h-3 relative">
                  <div class="search-spot-area" />
                  <img src="@/assets/Icon_Circle_blue.svg" class="h-3 z-10" />
                  <span class="ml-2 leading-3 truncate">{{ spot.name }}</span>
                </div>
              </div>
            </div>
          </transition>
          <div class="goal flex mt-2.5 w-full relative">
            <img src="@/assets/footer/IconTabRouteBlue.svg" class="h-3" />
            <div class="grid w-full">
              <span class="text-W4 ml-2 leading-3 truncate">
                {{ searchConditions.goal.name }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="rounded-[8px] bg-danger300 px-8 py-3 h-[39px]"
          @click="gotoBackPage()"
        >
          <div class="text-W6 text-[15px] text-white leading-[15px]">終了</div>
        </div>
      </div>
      <div class="mt-2 mx-5 mb-3">
        <div class="flex justify-between mb-5 text-W4 items-end">
          <div>
            <!-- 到着時間 -->
            <span class="text-[28px] leading-[31px]">{{ toTime }}</span>
            <span class="text-[16px] leading-[31px]">着</span>
            <!-- 移動距離 -->
            <span class="text-[15px] leading-[15px] ml-2">
              / {{ distance }}
            </span>
            <!-- 移動時間 -->
            <span class="text-[15px] leading-[15px]">・ {{ summaryTime }}</span>
          </div>
          <!-- 消費カロリー -->
          <span
            class="text-[17px] leading-[19px] text-left self-center text-W4 mt-1 mr-0.5"
          >
            {{ calories }}
          </span>
        </div>
      </div>
      <FooterMargin />
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util'
import FooterMargin from '@/components/organisms/FooterMargin.vue'

const RouteNavi = {
  name: 'RouteNavi',
  components: {FooterMargin},
  data() {
    return {
      isOpenedVia: false, // 経由地表示フラグ（デフォルト：false）
      routeNaviInfo: this.$store.state.RouteStore.routeNaviInfo,
    }
  },
  mixins: [Util],
  computed: {
    /**
     * 電車・バスルートか確認する
     * @return {boolean} true: 電車・バスルート、false: その他のルート
     */
    isTrainBus() {
      return (
        this.routeNaviInfo.routeType ===
        this.$config.MOBILITY_CARD_TYPE_TRAIN_BUS
      )
    },
    /**
     * 徒歩 or 自転車ルートか確認する
     * @return {boolean} true: 徒歩 or 自転車ルート、false: その他のルート
     */
    isWalkOrBicycle() {
      return (
        this.routeNaviInfo.routeType === this.$config.MOBILITY_CARD_TYPE_WALK ||
        this.routeNaviInfo.routeType === this.$config.MOBILITY_CARD_TYPE_BICYCLE
      )
    },
    /**
     * ルート情報取得
     */
    searchConditions() {
      const searchConditions = {
        start: {
          name: '',
        },
        via: [],
        goal: {
          name: '',
        },
      }
      if (this.isTrainBus) {
        // 電車・バスルートの徒歩の場合、対象区間の地点を返す
        const prevSectionNo = this.routeNaviInfo.sectionNo - 1 // eslint-disable-line no-magic-numbers
        const nextSectionNo = this.routeNaviInfo.sectionNo + 1 // eslint-disable-line no-magic-numbers
        // プラットフォーム情報があれば付与する
        const prevPlatform = this.sections[prevSectionNo].startPlatform
          ? ` ${this.sections[prevSectionNo].startPlatform}`
          : ''
        const nextPlatform = this.sections[nextSectionNo].startPlatform
          ? ` ${this.sections[nextSectionNo].startPlatform}`
          : ''
        searchConditions.start.name = `${this.sections[prevSectionNo].spotName}${prevPlatform}`
        searchConditions.goal.name = `${this.sections[nextSectionNo].spotName}${nextPlatform}`

        return searchConditions
      } else {
        // その他の場合、出発地、到着地をそのまま返す
        return this.$store.state.searchConditions
      }
    },
    /**
     * 経由地情報取得
     */
    viaSpots() {
      return this.searchConditions.via
    },
    /**
     * 経由地点が存在するかどうか
     */
    isViaSpots() {
      return this.viaSpots.length !== 0 // eslint-disable-line no-magic-numbers
    },
    /**
     * 区間情報を取得する
     */
    sections() {
      return this.routeNaviInfo.selectedRouteInfo.sections
    },
    /**
     * 到着時間を取得する
     */
    toTime() {
      // 電車・バスルートの場合は区間の到着時間を取得し、その他のルートの場合、到着地の到着時間を取得する
      let toTime = this.isTrainBus
        ? this.sections[this.routeNaviInfo.sectionNo].toTime
        : this.sections[this.sections.length - 2].toTime // eslint-disable-line no-magic-numbers

      return this.getFormatTime(toTime)
    },
    /**
     * 移動距離を取得する
     * @return {string} 移動距離 + 単位
     */
    distance() {
      // 電車・バスルートの場合は区間の移動距離を取得し、その他のルートの場合、合計の移動距離を取得する
      let distance = this.isTrainBus
        ? this.sections[this.routeNaviInfo.sectionNo].distance
        : this.routeNaviInfo.selectedRouteInfo.totalDistance

      // 移動距離に応じて表示する単位を切り替える
      let distanceWithUnit = this.isKilo(distance)
        ? // eslint-disable-next-line no-magic-numbers
          `${this.roundAndConvertToKilo(distance, 1)}km`
        : `${distance}m`
      return distanceWithUnit
    },
    /**
     * 移動時間を取得する
     * @return {string} 移動時間 + 単位
     */
    summaryTime() {
      // 電車・バスルートの場合は区間の移動時間を取得し、その他のルートの場合、合計の移動時間を取得する
      let summaryTime = this.isTrainBus
        ? this.sections[this.routeNaviInfo.sectionNo].time
        : this.routeNaviInfo.selectedRouteInfo.summaryTime

      // 60分以上の場合は、時間と分で表記する
      let hour = 60
      let summaryHour = Math.floor(summaryTime / hour)
      let summaryMinute = summaryTime % hour

      return summaryHour
        ? `${summaryHour}時間${summaryMinute}分` // 60以上の場合、*時間*分表記
        : `${summaryTime}分` // 60分未満の場合、*分表記
    },
    /**
     * 消費カロリー（kcal）を取得する
     * @return {string} 消費カロリー + kcal
     */
    calories() {
      let calories
      if (this.isTrainBus) {
        // 電車・バスルートの場合は区間の移動時間を取得する
        calories = this.sections[this.routeNaviInfo.sectionNo].calories
      } else if (this.isWalkOrBicycle) {
        // 徒歩 or 自転車のルートの場合、合計のカロリーを取得する
        calories = this.routeNaviInfo.selectedRouteInfo.totalCalories
      } else {
        // 車の場合、表示しない
        return ''
      }
      return `${this.addCommaForInteger(calories)}kcal`
    },
  },
  methods: {
    /**
     * 前の画面に遷移
     */
    gotoBackPage() {
      const routeType = this.routeNaviInfo.routeType
      switch (routeType) {
        case this.$config.MOBILITY.TRAIN_BUS: // 電車バス詳細
          this.$router.push({
            name: this.$config.DISPLAY_ROUTE_DETAIL_BY_TRAIN_BUS,
          })
          break
        case this.$config.MOBILITY.CAR: // 車ルート
          this.$router.push({name: this.$config.DISPLAY_ROUTE_BY_CAR})
          break
        case this.$config.MOBILITY.BICYCLE: // 自転車ルート
          this.$router.push({name: this.$config.DISPLAY_ROUTE_BY_BICYCLE})
          break
        case this.$config.MOBILITY.WALK: // 徒歩ルート
          this.$router.push({name: this.$config.DISPLAY_ROUTE_BY_WALK})
          break
        default:
        // 上記画面以外から遷移してきた場合、何も行わない
      }
    },
    enter(el) {
      const height = el.scrollHeight
      el.style.overflow = 'hidden'
      el.style.height = '0'

      window.requestAnimationFrame(() => {
        el.style.height = `${height}px`
      })
    },
    leave(el) {
      const height = el.scrollHeight
      el.style.overflow = 'hidden'
      el.style.height = `${height}px`

      window.requestAnimationFrame(() => {
        el.style.height = `0`
      })
    },
    afterEnter(el) {
      el.style.height = ''
      el.style.overflow = ''
    },
    afterLeave(el) {
      el.style.height = ''
      el.style.overflow = ''
    },
  },
}
export default RouteNavi
</script>
<style scoped>
.search-spot-area {
  top: 12px;
  width: 2px;
  height: 9px;
  position: absolute;
  left: 5px;
  border-left: 2px dotted #dbdee3;
  opacity: 1;
}
.accordion-enter-active,
.accordion-leave-active {
  transition: height 1s ease;
}
</style>
