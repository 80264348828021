<template>
  <div id="setupLoginMethod" class="flex flex-col">
    <!-- ヘッダー -->
    <Header
      ref="arrangementHeader"
      headerTitle="次回以降のログイン設定"
      :isShowPrevious="false"
      :isShowClose="false"
    />
    <div class="overflow-y-auto scroll-bar-none flex-1">
      <div class="text-left text-W4 text-[15px] leading-6 pt-6 px-5">
        次回以降のログイン方法を選択してください。セキュリティ向上のため、アプリ起動時の認証を推奨しています。
      </div>
      <!-- インタラクション画像エリア -->
      <div class="pt-6">
        <img class="mx-auto h-[240px] w-[240px]" :src="getImage()" />
      </div>
      <!-- ラジオボタンエリア -->
      <div class="pt-6 px-10 mb-24">
        <template v-for="item in loginSettingList" :key="item.key">
          <BaseRadioButtonV2
            class="last:mb-0 first:mt-0"
            :itemKey="item.KEY"
            :label="item.LABEL"
            v-model="selectedLoginSetting"
          />
        </template>
      </div>
      <!-- ボタンエリア -->
      <div class="fixed inset-x-0 bottom-0 px-5 pb-6">
        <TheButton
          text="ログイン設定を完了する"
          @click-button="registerLoginMethodWithMove()"
        />
      </div>
    </div>
  </div>
</template>
<script>
/**
 * ログイン設定画面コンポーネント
 */
import Header from '@/components/organisms/Header.vue'
import Util from '@/mixins/util'
import LoginUtil from '@/mixins/loginUtil'
import TheButton from '@/components/atoms/TheButton.vue'
import BaseRadioButtonV2 from '@/components/atoms/BaseRadioButtonV2.vue'
import Setup from '@/views/setup/setup.js'

const AUTO_AUTH_IDX = 0
const DEVICE_AUTH_IDX = 1
const EMAIL_PW_AUTH_IDX = 2

const SetupLoginMethod = {
  name: 'SetupLoginMethod',
  components: {Header, TheButton, BaseRadioButtonV2},
  mixins: [Util, LoginUtil, Setup],
  data() {
    return {
      selectedLoginSetting:
        this.$store.state.user.loginMethod ||
        this.$config.LOGIN_SETTINGS[AUTO_AUTH_IDX].KEY, // ログイン方法
      loginSettingList: this.$config.LOGIN_SETTINGS, // ログイン設定リスト
    }
  },
  computed: {
    /**
     * ユーザー情報
     */
    userInfo() {
      return this.$store.state.user.info
    },
  },
  methods: {
    /**
     * ログイン方法の設定
     * 設定後画面は、画面遷移
     */
    registerLoginMethodWithMove() {
      // ログイン方法の登録(LoginUtilから呼び出し)
      this.setLoginMethod(this.selectedLoginSetting)
      // ログイン方法の登録(Storeに保存)
      this.$store.commit('updateLoginMethod', this.selectedLoginSetting)

      // 既にユーザー情報登録済みであれば初期表示処理を行う
      const isAlreadyRegisteredUser = this.judgeAlreadyRegisteredUser()
      if (isAlreadyRegisteredUser) {
        this.initForAlreadyRegisteredUser()
      } else {
        // ユーザー新規登録の場合はユーザー情報登録画面に遷移
        this.$router.push({name: this.$config.DISPLAY_SETUP_USER_INFO})
      }
    },
    /**
     * ユーザー情報登録済ユーザー向けの画面初期表示処理
     */
    initForAlreadyRegisteredUser() {
      // アプリ内情報の設定状況によって処理を分ける
      if (this.isSetAppInfo()) {
        // 初期表示処理
        this.initData()
      } else {
        // 設定されていない場合は「ようこそ画面」へ遷移
        this.$router.push({name: this.$config.DISPLAY_SETUP_WELCOME})
      }
    },
    /**
     * インタラクション画像の取得
     */
    getImage() {
      switch (this.selectedLoginSetting) {
        case this.$config.LOGIN_SETTINGS[AUTO_AUTH_IDX].KEY:
          return require('@/assets/setup/ImageAutoAuth.png')
        case this.$config.LOGIN_SETTINGS[DEVICE_AUTH_IDX].KEY:
          return require('@/assets/setup/ImageDeviceAuth.png')
        case this.$config.LOGIN_SETTINGS[EMAIL_PW_AUTH_IDX].KEY:
          return require('@/assets/setup/ImageEmailPasswordAuth.png')
        default:
          return ''
      }
    },
    /**
     * 既にユーザー情報登録済みかどうか判定する
     */
    judgeAlreadyRegisteredUser() {
      // 既にユーザー情報が登録されているか
      const isAlreadyRegisteredUser =
        !this.isNull(this.userInfo.myoji) &&
        !this.isNull(this.userInfo.na) &&
        !this.isNull(this.userInfo.birthday) &&
        !this.isNull(this.userInfo.address) &&
        !this.isNull(this.userInfo.tel) &&
        !this.isNull(this.userInfo.email)

      return isAlreadyRegisteredUser
    },
  },
}
export default SetupLoginMethod
</script>
<style scoped></style>
