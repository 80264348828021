/**
 * IndexedDB用Storeモジュール
 */
import config from '@/const/const.js'
import log from 'loglevel'
import dayjs from 'dayjs'
const constant = config.constant

// データベースバージョン
const DB_VERSION = 2

// データベースバージョンの履歴（テーブルの作成要否に使用）
const DB_VERSION_HISTORY = {
  // 初回リリース
  FIRST: 1,
  // キャッシュテーブル追加
  ADD_CACHE_TABLE: 2,
}

export default {
  namespaced: true,
  state: {
    // データベースオブジェクト
    db: null,
  },
  getters: {},
  mutations: {
    /**
     * データベース接続完了時処理
     * @param {*} state
     * @param {*} payload
     */
    connectedDatabase(state, payload) {
      state.db = payload
    },
  },
  actions: {
    /**
     * データベース作成処理
     * @param {*} commit
     */
    async createDatabase({commit}) {
      return new Promise((resolve) => {
        // ブラウザにより異なるため、複数使えるようにする
        const indexedDB =
          window.indexedDB || window.mozIndexedDB || window.msIndexedDB

        if (!indexedDB) {
          // IndexedDB使用不可
          resolve()
          return
        }
        // カラムなどの更新時はバージョンを上げる必要あり
        const dbOpen = indexedDB.open(
          constant.MICHISIRU_DATABASE_NAME,
          DB_VERSION
        )

        // データベースがない場合、既存のversionよりも大きい引数でopenした場合
        dbOpen.onupgradeneeded = function (event) {
          // データベース更新時は既存データが消えないように考慮が必要
          const dbConnection = event.target.result
          const oldVersion = event.oldVersion

          // スキーマを作成する

          // 初回リリース分のテーブル作成がまだ行われていない場合
          if (oldVersion < DB_VERSION_HISTORY.FIRST) {
            // ユーザ情報テーブル作成
            dbConnection.createObjectStore(constant.USER_TABLE_NAME, {
              keyPath: constant.USER_ICON_KEY, // 主キー用
              autoIncrement: false, //ユーザアイコンはオートインクリメント不要
            })
            // お気に入り時刻表データテーブル作成
            dbConnection.createObjectStore(
              constant.FAVORITE_OPERATIONS_TABLE_NAME,
              {
                keyPath: constant.OPERATIONS_KEY, // 主キー用
                autoIncrement: false,
              }
            )
            // Storeの一時保持用テーブル作成
            dbConnection.createObjectStore(constant.STORE_TABLE_NAME, {
              keyPath: constant.STORE_KEY, // 主キー用
              autoIncrement: false, //ユーザアイコンはオートインクリメント不要
            })
          }

          // キャッシュテーブル作成がまだ行われていない場合
          if (oldVersion < DB_VERSION_HISTORY.ADD_CACHE_TABLE) {
            // キャッシュテーブル作成
            dbConnection.createObjectStore(constant.CACHE_TABLE_NAME, {
              keyPath: constant.CACHE_KEY, // 主キー用
              autoIncrement: false, //キャッシュはオートインクリメント不要
            })
          }
        }

        // 接続失敗の場合
        dbOpen.onerror = function (event) {
          log.debug("Can't open DataBase:", event)
          resolve()
        }
        // 接続成功の場合
        dbOpen.onsuccess = function (event) {
          // データベース接続完了時処理
          commit('connectedDatabase', event.target.result)
          resolve()
        }
      })
    },
    /**
     * データベースクローズ処理
     */
    closeDatabase({state}) {
      if (state.db) {
        state.db.close()
      }
    },

    /**
     * IndexedDBにデータを追加する
     * @param {*} state storeのstate
     * @param {*} success 成功時コールバック
     * @param {*} error 失敗時コールバック
     * @param {*} tableName テーブル名
     * @param {*} storeName ストア名
     * @param {*} keyPath キーパス
     * @param {*} key キー
     * @param {*} data データ
     */
    addData(
      {state},
      {success, error, setting: {tableName, storeName, keyPath}, key, data}
    ) {
      // DBのstoreを取得
      var store = state.db
        .transaction(tableName, 'readwrite')
        .objectStore(storeName)
      // 画像をDBに保存
      const request = store.put({
        [keyPath]: key,
        value: data,
      })
      // 成功時処理
      request.onsuccess = () => {
        if (success) {
          success()
        }
      }
      // 失敗時処理
      request.onerror = () => {
        if (error) {
          error()
        }
      }
    },

    /**
     * IndexedDBのデータを更新する
     * @param {*} state storeのstate
     * @param {*} success 成功時コールバック
     * @param {*} error 失敗時コールバック
     * @param {*} tableName テーブル名
     * @param {*} storeName ストア名
     * @param {*} keyPath キーパス
     * @param {*} key キー
     * @param {*} data データ
     */
    updateData(
      {state},
      {success, error, setting: {tableName, storeName, keyPath}, key, data}
    ) {
      // DBのstoreを取得
      var store = state.db
        .transaction(tableName, 'readwrite')
        .objectStore(storeName)
      // 指定されたIDのcursorを取得
      let isExistTarget = false
      store.openCursor(key).onsuccess = (event) => {
        const cursor = event.target.result
        if (!cursor) {
          // 通常1件の更新のため、2回目以降(cursorがない)はここで処理を終了する
          if (!isExistTarget) {
            // 1件もデータが存在しなかった場合はエラーを返す
            if (error) {
              error('No target data.')
            }
          }
          return
        }
        if (cursor.value[keyPath] == key) {
          // idが一致した場合はデータを更新する
          isExistTarget = true
          const updateData = cursor.value
          updateData.value = data
          const request = cursor.update(updateData)
          request.onsuccess = () => {
            if (success) {
              success()
            }
          }
          request.onerror = (errorMessage) => {
            if (error) {
              error(errorMessage)
            }
          }
        }
        cursor.continue()
      }
    },

    /**
     * IndexedDBのデータを削除する
     * @param {*} state storeのstate
     * @param {*} success 成功時コールバック
     * @param {*} error 失敗時コールバック
     * @param {*} tableName テーブル名
     * @param {*} storeName ストア名
     * @param {*} key キー
     */
    deleteData(
      {state},
      {success, error, setting: {tableName, storeName}, key}
    ) {
      // DBのトランザクションを取得
      var store = state.db
        .transaction(tableName, 'readwrite')
        .objectStore(storeName)
      // 削除実行
      const request = store.delete(key)
      // 成功時
      request.onsuccess = () => {
        if (success) {
          success()
        }
      }
      // 失敗時
      request.onerror = (errorMessage) => {
        if (error) {
          error(errorMessage)
        }
      }
    },

    /**
     * IndexedDBのデータを全件取得する
     * @param {*} state storeのstate
     * @param {*} success 成功時コールバック
     * @param {*} error 失敗時コールバック
     * @param {*} tableName テーブル名
     * @param {*} storeName ストア名
     */
    getAllData({state}, {success, error, setting: {tableName, storeName}}) {
      // 読み込み専用でstoreを取得
      var store = state.db
        .transaction(tableName, 'readonly')
        .objectStore(storeName)
      // DBからアイコンを取得
      var request = store.getAll()
      // 成功時
      request.onsuccess = (event) => {
        if (success) {
          success(event.target.result)
        }
      }
      // 失敗時
      request.onerror = (errorMessage) => {
        if (error) {
          error(errorMessage)
        }
      }
    },

    /**
     * IndexedDBのデータを1件取得する
     * @param {*} state storeのstate
     * @param {*} success 成功時コールバック
     * @param {*} error 失敗時コールバック
     * @param {*} tableName テーブル名
     * @param {*} storeName ストア名
     * @param {*} key キー名
     */
    getData({state}, {success, error, setting: {tableName, storeName}, key}) {
      // 読み込み専用でstoreを取得
      var store = state.db
        .transaction(tableName, 'readonly')
        .objectStore(storeName)
      // DBからアイコンを取得
      var request = store.get(key)
      // 成功時
      request.onsuccess = (event) => {
        if (success) {
          success(event.target.result)
        }
      }
      // 失敗時
      request.onerror = (errorMessage) => {
        if (error) {
          error(errorMessage)
        }
      }
    },
    /**
     * IndexedDBのデータベースを初期化する
     * @param {*} state storeのstate
     */
    initializeDatabase({dispatch}) {
      return new Promise((resolve, reject) => {
        // ユーザーテーブルの初期化
        // Database Store内データ全件取得用のPromise作成関数
        const createGetDataPromise = (tableName) => {
          return new Promise((resolve, reject) => {
            dispatch('getAllData', {
              success: (result) => {
                resolve(result)
              },
              error: () => {
                reject()
              },
              setting: {
                tableName: tableName,
                storeName: tableName,
              },
            })
          })
        }
        // Database Store内データ削除用のPromise作成関数
        const createDeleteDataPromise = (tableName, keyName) => {
          return new Promise((resolve, reject) => {
            dispatch('deleteData', {
              success: (result) => {
                resolve(result)
              },
              error: () => {
                reject()
              },
              setting: {
                tableName: tableName,
                storeName: tableName,
              },
              key: keyName,
            })
          })
        }

        let deletePromises = []
        // 各Store内のデータを全件取得
        Promise.all([
          createGetDataPromise(constant.USER_TABLE_NAME),
          createGetDataPromise(constant.FAVORITE_OPERATIONS_TABLE_NAME),
          createGetDataPromise(constant.STORE_TABLE_NAME),
        ])
          .then((results) => {
            const userTableAllData = results[0] // eslint-disable-line no-magic-numbers
            const favoriteOperationsAllData = results[1] // eslint-disable-line no-magic-numbers
            const storeAllData = results[2] // eslint-disable-line no-magic-numbers

            // 各Storeに登録されているデータ全てに対し削除用のPromiseを生成する
            for (let data of userTableAllData) {
              deletePromises.push(
                createDeleteDataPromise(
                  constant.USER_TABLE_NAME,
                  data[constant.USER_ICON_KEY]
                )
              )
            }
            for (let data of favoriteOperationsAllData) {
              deletePromises.push(
                createDeleteDataPromise(
                  constant.FAVORITE_OPERATIONS_TABLE_NAME,
                  data[constant.OPERATIONS_KEY]
                )
              )
            }
            for (let data of storeAllData) {
              deletePromises.push(
                createDeleteDataPromise(
                  constant.STORE_TABLE_NAME,
                  data[constant.STORE_KEY]
                )
              )
            }

            // 削除対象の全てのデータに対し、削除実行
            Promise.all(deletePromises)
              .then(() => {
                resolve()
              })
              .catch(() => {
                reject()
              })
          })
          .catch(() => {
            reject()
          })
      })
    },
    /**
     * キャッシュを取得する
     * @param {*} state
     * @param {*} cacheName キャッシュ名
     * @returns キャッシュされたデータ
     */
    async getCache({state}, {cacheName}) {
      return new Promise((resolve, reject) => {
        const tableName = constant.CACHE_TABLE_NAME
        const storeName = constant.CACHE_TABLE_NAME
        // 読み込み専用でstoreを取得
        const store = state.db
          .transaction(tableName, 'readonly')
          .objectStore(storeName)
        // DBからキャッシュデータを取得
        const request = store.get(cacheName)

        // 成功時
        request.onsuccess = (event) => {
          const {data, expiry} = event.target.result?.value || {}
          // キャッシュの取得ができない、または有効期限が切れていたらnullを返す
          if (expiry == null || expiry < dayjs().unix()) {
            resolve(null)
          } else {
            resolve(data)
          }
        }
        // 失敗時
        request.onerror = (errorMessage) => {
          reject(new Error(errorMessage))
        }
      })
    },
    /**
     * キャッシュを保存する
     * @param {*} state
     * @param {String} cacheName キャッシュ名
     * @param {*} data キャッシュするデータ
     * @param {Number} minuteOfExpiry キャッシュの有効期間（分）
     * @returns
     */
    async saveCache({state}, {cacheName, data, minuteOfExpiry}) {
      return new Promise((resolve, reject) => {
        const tableName = constant.CACHE_TABLE_NAME
        const storeName = constant.CACHE_TABLE_NAME
        const keyPath = constant.CACHE_KEY

        // キャッシュの有効期間
        const expiry = dayjs().add(minuteOfExpiry, 'minute').unix()
        // DBのstoreを取得
        const store = state.db
          .transaction(tableName, 'readwrite')
          .objectStore(storeName)
        // キャッシュをDBに保存
        const request = store.put({
          [keyPath]: cacheName,
          value: {expiry, data},
        })
        // 成功時処理
        request.onsuccess = () => {
          resolve()
        }
        // 失敗時処理
        request.onerror = () => {
          reject()
        }
      })
    },
  },
}
