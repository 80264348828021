<template>
  <div>
    <div class="flex flex-col">
      <div
        v-for="history in routeHistoryList"
        :key="history.key"
        @click="$emit('click-route-history', history)"
        class="flex items-center pb-2 last:pb-0"
      >
        <div
          class="flex items-center pl-4 pr-3 py-4 border-[1px] border-gray300 rounded-[10px] w-full active:bg-blue100"
        >
          <div class="flex-1 overflow-hidden">
            <!-- 出発地 -->
            <SpotName
              class="mb-2"
              :spotName="history.start.name"
              type="start"
            />
            <!-- 経由地 -->
            <div v-for="spot in history.via" :key="spot.id">
              <SpotName class="mb-2" :spotName="spot.name" type="via" />
            </div>
            <!-- 目的地 -->
            <SpotName class="" :spotName="history.goal.name" type="goal" />
          </div>
          <img src="@/assets/Icon_Right_gray.svg" class="w-3 h-3 ml-3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpotName from '@/components/atoms/SpotName.vue'

const RouteHistoryCardList = {
  name: 'RouteHistoryCardList',
  components: {SpotName},
  emits: ['click-route-history'],
  props: {
    /**
     * ルート履歴一覧
     */
    routeHistoryList: {
      type: Array,
      required: true,
    },
  },
}
export default RouteHistoryCardList
</script>
<style scoped></style>
