<template>
  <div id="transferSuggest" class="pl-5 w-full h-full" ref="transferSuggest">
    <TextForm
      class="searchText pt-5 pr-5"
      inputClass="bg-gray200 !border-[0px]"
      :text="word"
      :placeholder="'駅・バス停・空港・港を入力'"
      @input-text="onchangeText"
      @click-back-button="goToBackPage()"
      :maxlength="'50'"
      ref="textForm"
    />
    <div id="transferContents" class="mt-5 text-left">
      <div class="pr-5" :style="scrollStyle">
        <div v-if="isEditing" class="h-full">
          <!-- 入力時 -->
          <StationSuggestList
            :stationList="stations"
            @click-station="completeForm($event)"
          />
          <div class="h-5 w-full" />
        </div>
        <div v-else class="h-full">
          <!-- 未入力時 -->
          <div class="ml-1">
            <!-- 履歴 -->
            <div class="text-W6 text-[13px] leading-[13px] pb-5">履歴</div>
            <StationHistoryList
              :stationHistoryList="stationHistoryList"
              @click-history-station="completeForm($event)"
            />
            <div class="pt-5 separate-border" />
            <div class="h-5 w-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextForm from '@/components/TextForm.vue'
import StationSuggestList from '@/components/transfer/StationSuggestList.vue'
import StationHistoryList from '@/components/transfer/StationHistoryList.vue'
import NativeUtil from '@/mixins/nativeUtil'

const MAX_HISTORY_NUM = 10
const LOAD_SPOT_API_SEC = 100

// 駅名検索の文字数制限
const SEARCH_TRANSFER_LIMIT_MIN = 1
const SEARCH_TRANSFER_LIMIT_MAX = 50

const TransferSuggest = {
  name: 'TransferSuggest',
  components: {
    TextForm,
    StationSuggestList,
    StationHistoryList,
  },
  mixins: [NativeUtil],
  data() {
    return {
      targetSpot: '',
      viaId: null, // via識別番号（UNIX時刻）。何番目の中継地か判別するのに使用する
      word: '',
      stations: [],
      errMsg: '',
      timer: 0,
      stationHistoryList: [],
      isEditing: false, // true:入力中、false:未入力
    }
  },
  created() {
    //ローカルストレージから履歴取得
    this.getHistory(this.$config.TRANSFER_HISTORY_STORAGE_KEY)
    // 以下の方法でフォームのkeyを取得できる
    this.targetSpot = this.$route.params.key
    this.viaId = this.$route.params.viaId
    // フッター部を非表示にする
    this.$store.commit('updateShowFooter', false)
  },
  mounted() {
    // 入力フォームにフォーカスを当てる
    this.$refs.textForm.focusInputForm()
  },
  beforeUnmount() {
    // フッター部を表示する
    this.$store.commit('updateShowFooter', true)
  },
  computed: {
    /**
     * キーボード表示判定（iOSのみ判定する）
     */
    isShowKeyboard() {
      if (this.isIosWebView()) {
        return this.$store.state.isShowKeyboard
      } else {
        return false
      }
    },
    /**
     * スクロールエリアのスタイル
     */
    scrollStyle() {
      if (this.isShowKeyboard) {
        // キーボード表示時は内部スクロールが表示されないように全表示
        return {
          'overflow-y': 'visible',
          height: '100%',
        }
      } else {
        // キーボード非表示時は特定の範囲内でスクロールされるようにする
        return {
          'overflow-y': 'auto',
          height: 'calc(100vh - 88px)',
        }
      }
    },
  },
  watch: {
    isShowKeyboard: {
      handler(newVal) {
        // キーボード非表示時にスクロールをリセットする
        if (!newVal) {
          this.$refs.transferSuggest.scrollTo({top: 0})
        }
      },
    },
  },
  methods: {
    /**
     * 入力文字の内容を用いて駅名検索を行う
     * @param {String} value 検索文字
     */
    onchangeText(value) {
      this.word = value
      this.errMsg = ''
      clearTimeout(this.timer)

      // 入力中かの判定
      this.isEditing = value != ''

      // 駅名検索対象ではない場合、何もしない
      if (!this.validSearchTransfer()) {
        return
      }
      const vm = this
      const success = (stations) => {
        vm.stations = stations || []
      }

      const failed = () => {
        // エラーで取得できなかった際は何も表示しない
        vm.stations = []
        throw new Error()
      }

      const error = (e) => {
        // エラーで取得できなかった際は何も表示しない
        vm.stations = []
        throw e
      }
      this.timer = setTimeout(() => {
        vm.$store.dispatch('TransferStore/getStationInfo', {
          success: success,
          failed: failed,
          error: error,
          word: vm.word,
        })
      }, LOAD_SPOT_API_SEC)
    },
    /**
     * 駅名検索の有効文字かどうか
     * 有効文字：文字が２字以上５０文字以下
     */
    validSearchTransfer() {
      return (
        SEARCH_TRANSFER_LIMIT_MIN <= this.word.length &&
        this.word.length <= SEARCH_TRANSFER_LIMIT_MAX
      )
    },
    /**
     * ストレージに履歴一覧を反映、検索条件に地点を反映を行い、検索Top画面に遷移
     */
    completeForm(value) {
      // ローカルストレージに格納
      this.setHistory(this.$config.TRANSFER_HISTORY_STORAGE_KEY, value)
      let updateValue = {
        name: value.name,
        address: value.addressName,
        nodeId: value.nodeId,
        ruby: value.ruby,
        type: value.types,
      }

      // 経由地の場合は、適切な配列の要素に格納
      if (this.targetSpot == this.$config.SPOT_TYPE_VIA) {
        // 一致する経由地を更新
        const viaArr = this.$store.state.TransferStore.transferConditions.via
        const updateViaArr = viaArr.map((spot) => {
          // 地点情報に表示用IDを付与
          updateValue.id = this.viaId
          // via識別番号の一致した中継地に検索した値をセットする（それ以外の場合は元の値をそのままセット）
          return spot.id == this.viaId ? updateValue : spot
        })
        updateValue = updateViaArr
      }

      // 検索条件に反映
      this.$store.commit('TransferStore/updateTransferConditions', {
        key: this.targetSpot,
        value: updateValue,
      })

      // 検索Top画面に遷移
      this.goToBackPage()
    },

    /**
     *ローカルストレージに履歴一覧を保存
     */
    setHistory(key, value) {
      // 重複したデータかどうかを判別
      const checkDuplicateData = (history) => {
        return (
          history.name == value.name &&
          history.addressName == value.addressName &&
          history.nodeId == value.nodeId &&
          history.ruby == value.ruby &&
          history.types == value.types
        )
      }
      this.stationHistoryList = this.stationHistoryList.filter((history) => {
        // 重複していないデータのみに絞り込み
        return checkDuplicateData(history) === false
      })

      //履歴を追加
      this.stationHistoryList.unshift(value)
      //最新の10件以内になるように限定
      while (MAX_HISTORY_NUM < this.stationHistoryList.length) {
        this.stationHistoryList.pop()
      }

      //ローカルストレージに保存
      const parsed = JSON.stringify(this.stationHistoryList)
      localStorage.setItem(key, parsed)
    },

    /**
     *ローカルストレージから履歴一覧を取得
     */
    getHistory(key) {
      if (localStorage.getItem(key)) {
        try {
          this.stationHistoryList = JSON.parse(localStorage.getItem(key))
        } catch (e) {
          //jsonに問題がある場合は履歴を削除
          localStorage.removeItem(key)
        }
      }
    },
    /**
     * 前画面へ戻る
     */
    async goToBackPage() {
      // テキストフォームのフォーカスを外す
      // ※キーボードが閉じるのを待つ必要があるため、処理待ち時間を指定
      const waitTime = 100
      await this.$refs.textForm.blurInputForm(waitTime)
      this.$router.push({name: 'TransferTop'})
    },
  },
}
export default TransferSuggest
</script>
<style scoped>
.separate-border {
  border-bottom: 0.5px solid #dbdee3;
}
</style>
