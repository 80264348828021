/**
 * ルート検索用Storeモジュール
 */
import * as Axios from '@/service/AxiosService'
import Util from '@/mixins/util'
import config from '@/const/const.js'
import deepcopy from 'deepcopy'
const constant = config.constant

/**
 * ルート検索結果の初期値
 */
const INITIAL_ROUTE_SEARCH_RESULT = {
  total: '', //トータル
  carRecommend: '', //車（おすすめ）
  carToll: '', // 車（有料優先）
  carFree: '', // 車（無料優先）
  walk: '', // 徒歩
  bicycle: '', // 自転車
}

// 天気情報の初期値
const INITIAL_WEATHER_INFO = {
  start: {
    daily: [],
    weekly: [],
    observatory: '',
  },
  goal: {
    daily: [],
    weekly: [],
    observatory: '',
  },
  currentSpot: {
    daily: [],
    weekly: [],
    observatory: '',
  },
}

// ルート検索用のCancelSource
let searchRouteCancelSource = null

// 逆ジオコーディング用のキャンセルソース
let addressByCoordCancelSources = null

// キャンセル理由でrejectされているかどうか
function isCanceled(result) {
  return result.status == 'rejected' && result.reason.message == 'Cancel'
}

/**
 * ルート検索（トータル、電車・バス）APIに渡すパラメータを生成する
 * @returns param routeTotal APIに渡すパラメータ
 */
function setRouteTotalParam(condition) {
  // 検索条件のパラメータ生成.
  let param = {
    // 出発地
    startName: condition.start.name,
    startAddress: condition.start.address,
    startCoord: condition.start.coord,
    // 到着地
    goalName: condition.goal.name,
    goalAddress: condition.goal.address,
    goalCoord: condition.goal.coord,
    // 日付
    date: condition.targetTime,
    // 出発 or 到着
    timeType: condition.timeType.id,
    // 移動手段
    isSuperexpressTrain: condition.searchOptions.isSuperexpressTrain,
    isUltraexpressTrain: condition.searchOptions.isUltraexpressTrain,
    isHighwayBus: condition.searchOptions.isHighwayBus,
    isDomesticFlight: condition.searchOptions.isDomesticFlight,
    isLocalBus: condition.searchOptions.isLocalBus,
    isFerry: condition.searchOptions.isFerry,
  }

  // 経由地
  const FIRST = 1
  for (let i = 0; i < condition.via.length; i++) {
    param['via' + (i + FIRST) + 'Name'] = condition.via[i].name || undefined
    param['via' + (i + FIRST) + 'Address'] =
      condition.via[i].address || undefined
    param['via' + (i + FIRST) + 'Coord'] = condition.via[i].coord || undefined
  }
  return param
}

/**
 * ルート検索（車・徒歩・自転車）APIに渡すパラメータを生成する
 * @returns param routeCar or routeWalk or routeBicycle APIに渡すパラメータ
 */
function setRouteCarWalkBicycleParam(condition) {
  // 検索条件のパラメータ生成.
  let param = {
    // 出発地
    startName: condition.start.name,
    startAddress: condition.start.address,
    startCoord: condition.start.coord,
    // 到着地
    goalName: condition.goal.name,
    goalAddress: condition.goal.address,
    goalCoord: condition.goal.coord,
    // 日付
    date: condition.targetTime,
    // 出発 or 到着
    timeType: condition.timeType.id,
  }

  // 経由地
  const FIRST = 1
  for (let i = 0; i < condition.via.length; i++) {
    param['via' + (i + FIRST) + 'Name'] = condition.via[i].name || undefined
    param['via' + (i + FIRST) + 'Address'] =
      condition.via[i].address || undefined
    param['via' + (i + FIRST) + 'Coord'] = condition.via[i].coord || undefined
  }
  return param
}
/**
 * 想定していないルートタイプかどうか
 * @param {*} routeType
 * @returns
 */
function isInvalidReRouteType(routeType) {
  return (
    routeType != constant.MOBILITY.WALK &&
    routeType != constant.MOBILITY.BICYCLE &&
    routeType != constant.CAR_TYPE.TOLL &&
    routeType != constant.CAR_TYPE.RECOMMEND &&
    routeType != constant.CAR_TYPE.FREE
  )
}

/**
 * 成功時のルート検索レスポンスかどうかをチェックする
 * @return {Boolean} 下記を満たす場合にtrueを返却する
 * ・ステータスコードが200
 * ・ボディ部が存在する
 */
function isSuccessfulRouteResponce(res) {
  return (
    res.statusCode === constant.API_STATUS_CODE_OK &&
    !Util.methods.isNull(res.body)
  )
}

export default {
  namespaced: true,
  state: {
    isCompleteSearchRoute: false, // ルート取得済みかどうか
    // ルート検索結果
    routeSearchResult: INITIAL_ROUTE_SEARCH_RESULT,
    routeSearchParam: {
      total: '', //トータル
      car: '', //車
      walk: '', // 徒歩
      bicycle: '', // 自転車
    },
    trainBusRouteSearchResult: '', // 電車バス用の検索結果
    trainBusRouteSearchConditions: null, // 電車バス用の検索条件
    forResearchResult: '', // 再検索用検索結果(対象:車,自転車,徒歩)
    forResearchConditions: null, // 再検索用検索条件(対象:車,自転車,徒歩)
    selectedRouteTotal: null, // 詳細画面表示用のトータルルート情報
    routeNaviInfo: null, // ナビ表示用のルート情報
    selectedCarType: constant.CAR_TYPE.RECOMMEND, // 選択中の車タイプ
    selectedBicycleType: constant.BICYCLE_TYPE.MY_BICYCLE, // 選択中の自転車タイプ
    reRouteCarShapeData: {}, //再検索用ルート形状データ
    // 各モビリティのバナー表示状態
    displayBanner: {
      trainBus: true,
      car: true,
      bicycle: true,
      walk: true,
    },
    // 天気情報
    weather: structuredClone(INITIAL_WEATHER_INFO),
  },
  getters: {
    /**
     * 親Storeの検索条件を取得する
     * @returns 検索条件
     */
    rootSearchConditions(state, getters, rootState) {
      return rootState.searchConditions
    },
    /**
     * ナビ開始時の出発地点の緯度経度を取得
     * @param {*} state RouteStoreのstate
     * @returns 緯度経度
     */
    routeNaviStartLatLon(state) {
      const routeNaviInfo = state.routeNaviInfo
      if (routeNaviInfo == null) {
        // 未設定の場合は初期地点を返却
        return {
          lat: constant.DEFAULT_CURRENT_POSITION.LAT,
          lon: constant.DEFAULT_CURRENT_POSITION.LON,
        }
      }
      const sections = routeNaviInfo.selectedRouteInfo.sections

      // 電車・バスルートかどうか
      const isTrainBus =
        state.routeNaviInfo.routeType === constant.MOBILITY_CARD_TYPE_TRAIN_BUS

      // ナビ対象の区間情報を取得する
      const start = isTrainBus
        ? sections[routeNaviInfo.sectionNo - 1] // eslint-disable-line no-magic-numbers
        : sections[0] // eslint-disable-line no-magic-numbers

      return {
        lat: start.coord.lat,
        lon: start.coord.lon,
      }
    },
  },
  mutations: {
    /**
     * ルート検索結果更新処理
     * @param {*} state
     * @param {*} obj
     */
    updateRouteResult(state, obj) {
      if (obj.key == 'routeSearchResult') {
        // ルート検索結果まるごと更新の場合
        state.routeSearchResult = obj.value
      } else if (obj.key == 'init') {
        // 検索結果をまるごと初期化
        state.routeSearchResult = deepcopy(INITIAL_ROUTE_SEARCH_RESULT)
      } else {
        // 個々に更新する場合
        state.routeSearchResult[obj.key] = obj.value
      }
    },
    /**
     * ルート詳細表示用データを保持
     */
    updateSelectedRouteTotal(state, obj) {
      state.selectedRouteTotal = obj
    },
    /**
     * ルート詳細表示用のデータを解放
     */
    clearSelectedRouteTotal(state) {
      state.selectedRouteTotal = null
    },
    /**
     * ナビ表示用のルート情報更新
     */
    updateRouteNaviInfo(state, obj) {
      state.routeNaviInfo = obj
    },
    /*
     * 選択中の車種別切替
     */
    updateSelectedCarType(state, value) {
      state.selectedCarType = value
    },
    /**
     * 選択中の自転車タイプを更新
     */
    updateSelectedBicycleType(state, value) {
      state.selectedBicycleType = value
    },
    /**
     * 電車バス画面用の検索条件更新
     */
    updateTrainBusSearchConditions(state, obj) {
      switch (obj.mode) {
        case 'init': // 初期化
          state.trainBusRouteSearchConditions = null
          break
        case 'sync': // 元の検索条件と同期
          state.trainBusRouteSearchConditions = deepcopy(
            this.getters['RouteStore/rootSearchConditions']
          )
          break
        default: // 更新
          state.trainBusRouteSearchConditions[obj.key] = obj.value
          break
      }
    },
    /**
     * 電車バス用の検索結果更新
     */
    updateTrainBusRouteResult(state, obj) {
      switch (obj.mode) {
        case 'init': // 初期化
          state.trainBusRouteSearchResult = ''
          break
        case 'sync': // 元の検索結果と同期
          state.trainBusRouteSearchResult = deepcopy(
            state.routeSearchResult.total
          )
          break
        default: // 更新
          state.trainBusRouteSearchResult = obj.value
          break
      }
    },
    /**
     * ルート取得済みフラグの更新
     */
    updateIsCompleteSearchRoute(state, value) {
      state.isCompleteSearchRoute = value
    },
    /**
     * 詳細画面用 再検索用の検索条件更新
     * 車、自転車、徒歩用に使用する
     */
    updateForResearchConditions(state, obj) {
      switch (obj.mode) {
        case 'init': // 初期化
          state.forResearchConditions = null
          break
        case 'sync': // 元の検索条件と同期
          state.forResearchConditions = deepcopy(
            this.getters['RouteStore/rootSearchConditions']
          )
          break
        default: // 更新
          state.forResearchConditions = obj.value
          break
      }
    },
    /**
     * 詳細画面用 再検索用の検索結果更新
     * 車、自転車、徒歩用に使用する
     * mode:初期化or同期を判断するパラメータ
     */
    updateForResearchResult(state, obj) {
      switch (obj.mode) {
        case 'init': // 初期化
          state.forResearchResult = ''
          break
        case 'sync': // 元の検索結果と同期
          state.forResearchResult = deepcopy(state.routeSearchResult)
          break
        default: // 更新
          state.forResearchResult[obj.key] = obj.value
          break
      }
    },
    /**
     * 詳細画面用 再検索時のルート形状データ
     * @param {*} state
     * @param {*} obj
     */
    updateReRouteCarShapeData(state, obj) {
      switch (obj.mode) {
        case 'init': // 初期化
          state.reRouteCarShapeData = {}
          break
        default: // 更新
          state.reRouteCarShapeData[obj.key] = obj.value
          break
      }
    },
    /**
     * Storeの復元
     * @param {*} state Storeオブジェクト
     * @param {*} value IndexedDBに格納されていたデータ
     */
    restoreStore(state, value) {
      state.isCompleteSearchRoute = value.isCompleteSearchRoute
      state.routeSearchResult = value.routeSearchResult
      state.routeSearchParam = value.routeSearchParam
      state.trainBusRouteSearchResult = value.trainBusRouteSearchResult
      state.trainBusRouteSearchConditions = value.trainBusRouteSearchConditions
      state.forResearchResult = value.forResearchResult
      state.forResearchConditions = value.forResearchConditions
      state.selectedRouteTotal = value.selectedRouteTotal
      state.routeNaviInfo = value.routeNaviInfo
      state.selectedCarType = value.selectedCarType
      state.selectedBicycleType = value.selectedBicycleType
      state.reRouteCarShapeData = value.reRouteCarShapeData
    },
    /**
     * ルート検索の検索条件/結果を初期化
     * @param {*} state Storeオブジェクト
     */
    resetConditionAndResult(state) {
      state.isCompleteSearchRoute = false
      state.routeSearchResult = structuredClone(INITIAL_ROUTE_SEARCH_RESULT)
      state.routeSearchParam = {
        total: '', //トータル
        car: '', //車
        walk: '', // 徒歩
        bicycle: '', // 自転車
      }
      state.trainBusRouteSearchResult = ''
      state.trainBusRouteSearchConditions = null
      state.forResearchResult = ''
      state.forResearchConditions = null
      state.selectedRouteTotal = null
      state.routeNaviInfo = null
      state.selectedCarType = constant.CAR_TYPE.RECOMMEND
      state.selectedBicycleType = constant.BICYCLE_TYPE.MY_BICYCLE
      state.reRouteCarShapeData = {}
      state.weather.start = structuredClone(INITIAL_WEATHER_INFO).start
      state.weather.goal = structuredClone(INITIAL_WEATHER_INFO).goal
    },
    /**
     * 全モビリティのバナー表示状態を初期化(表示)
     * @param {*} state Storeオブジェクト
     */
    initDisplayBanner(state) {
      // 初期値(表示)に更新
      state.displayBanner.trainBus = true
      state.displayBanner.car = true
      state.displayBanner.bicycle = true
      state.displayBanner.walk = true
    },
    /**
     * 指定のモビリティのバナー表示状態を更新
     * @param {*} state Storeオブジェクト
     * @param {Object} payload ペイロード
     * @param {String} payload.target ターゲット
     * @param {Boolean} payload.isShow 表示状態
     */
    updateDisplayBanner(state, payload) {
      const target = payload?.target
      const isShow = payload?.isShow

      // 想定しないターゲットの場合は何もしない
      const isInvalidTarget = !Object.values(constant.MOBILITY).includes(target)
      if (isInvalidTarget) return

      state.displayBanner[target] = isShow
    },
    /**
     * 天気情報更新処理
     * @param {*} state Storeオブジェクト
     * @param {Object} payload ペイロード
     * @param {String} payload.target ターゲット
     * @param {Array} payload.daily 日毎天気情報
     * @param {Array} payload.weekly 週間天気情報
     * @param {String} payload.observatory 観測所名
     */
    updateWeather(state, payload) {
      const target = payload.target
      const daily = payload.daily
      const weekly = payload.weekly
      const observatory = payload.observatory

      // 更新先が不正な値であれば何もしない
      const allowList = Object.keys(INITIAL_WEATHER_INFO)
      if (!allowList.includes(target)) return
      // 日毎、週間の更新する値が配列でなければ何もしない
      if (!Array.isArray(daily) || !Array.isArray(weekly)) return

      // 日毎、週間のデータを更新
      state.weather[payload.target].daily = daily
      state.weather[payload.target].weekly = weekly
      state.weather[payload.target].observatory = observatory
    },
    /**
     * 出発地と目的地における日毎天気情報と週間天気情報を初期化
     * @param {*} state
     */
    initWeather(state) {
      // 日毎、週間のデータを初期化
      state.weather.start = structuredClone(INITIAL_WEATHER_INFO).start
      state.weather.goal = structuredClone(INITIAL_WEATHER_INFO).goal
    },
  },

  actions: {
    /**
     * ルート検索API
     * @param {*} state このstoreのstate
     * @param {*} success 成功時コールバック
     * @param {*} failed 失敗時コールバック
     * @param {*} cancel キャンセル時コールバック
     */
    /* eslint-disable-next-line no-unused-vars */
    searchRouteInfo({state, commit}, {success, failed, cancel}) {
      // 重複している処理をキャンセルし、新しいCancelSourceを発行
      searchRouteCancelSource = Util.methods.resetCancelSource(
        searchRouteCancelSource
      )
      // 初期化フラグ
      let isInitSource = true

      // バナー表示状態を初期化
      commit('initDisplayBanner')

      //各種ルート検索を呼ぶ
      return Promise.allSettled([
        this.dispatch('RouteStore/getRouteTotal', searchRouteCancelSource),
        this.dispatch('RouteStore/getRouteCar', searchRouteCancelSource),
        this.dispatch('RouteStore/getRouteWalk', searchRouteCancelSource),
        this.dispatch('RouteStore/getRouteBicycle', searchRouteCancelSource),
      ])
        .then(([totalResult, carResult, walkResult, bicycleResult]) => {
          if (
            totalResult.status === 'rejected' &&
            carResult.status === 'rejected' &&
            walkResult.status === 'rejected' &&
            bicycleResult.status === 'rejected'
          ) {
            if (failed) {
              failed()
              return
            }
            throw new Error('All promises rejected')
          }
          if (
            isCanceled(totalResult) ||
            isCanceled(carResult) ||
            isCanceled(walkResult) ||
            isCanceled(bicycleResult)
          ) {
            // キャンセルされている場合は、何も行わない
            isInitSource = false
            if (cancel) {
              cancel()
            }
            return
          }

          // 結果を整形する関数
          const zero = 0

          const formatResult = function (result) {
            return result &&
              result.status === 'fulfilled' &&
              result.value &&
              Array.isArray(result.value) &&
              result.value[zero].sections
              ? result.value
              : ''
          }
          const formatCarResult = function (result) {
            return result &&
              result.status === 'fulfilled' &&
              result.value &&
              !Util.methods.isEmpty(result.value)
              ? result.value
              : {recommend: '', tollTime: '', freeTime: ''}
          }
          // 検索結果をフォーマットする
          const value = {
            total: formatResult(totalResult),
            carRecommend: formatCarResult(carResult).recommend,
            carToll: formatCarResult(carResult).tollTime,
            carFree: formatCarResult(carResult).freeTime,
            walk: formatResult(walkResult),
            bicycle: formatResult(bicycleResult),
          }

          // 全ての結果がない場合はエラーを返す。
          if (
            value.total === '' &&
            value.carRecommend === '' &&
            value.carToll === '' &&
            value.carFree === '' &&
            value.walk === '' &&
            value.bicycle === ''
          ) {
            if (failed) {
              failed()
              return
            }
            throw new Error('All route results are empty.')
          }
          // 検索結果をstoreに格納する
          this.commit('RouteStore/updateRouteResult', {
            key: 'routeSearchResult',
            value: value,
          })
          if (success) {
            success()
          }
        })
        .finally(() => {
          if (isInitSource) {
            // 古いソースを初期化
            searchRouteCancelSource = null
          }
        })
    },

    /**
     * ルート検索（トータル）
     * @param {*} state このstoreのstate
     * @param {*} rootState 親のstate
     * @param {*} cancelSource キャンセル用のソース
     * @returns Promise
     */
    getRouteTotal({state, rootState}, cancelSource) {
      return new Promise((resolve, reject) => {
        // パラメータ生成
        const param = setRouteTotalParam(rootState.searchConditions)

        // リクエスト情報を保持
        state.routeSearchParam.total = param

        // 必要な検索条件を取得
        Axios.get('routeTotal', param, {source: cancelSource})
          .then((res) => {
            if (res.statusCode == constant.API_STATUS_CODE_OK) {
              // データ保存処理
              resolve(res.body)
            } else {
              throw res
            }
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    /**
     * ルート検索（車）
     * @param {*} state このstoreのstate
     * @param {*} rootState 親のstate
     * @param {*} cancelSource キャンセル用のソース
     * @returns Promise
     */
    getRouteCar({state, rootState}, cancelSource) {
      return new Promise((resolve, reject) => {
        // パラメータ生成.
        const param = setRouteCarWalkBicycleParam(rootState.searchConditions)

        // リクエスト情報を保持
        state.routeSearchParam.car = param

        // 必要な検索条件を取得
        Axios.get('routeCar', param, {source: cancelSource})
          .then((res) => {
            if (res.statusCode == constant.API_STATUS_CODE_OK) {
              // データ保存処理
              resolve(res.body)
            } else {
              throw res
            }
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    /**
     * ルート検索（徒歩）
     * @param {*} state このstoreのstate
     * @param {*} rootState 親のstate
     * @param {*} cancelSource キャンセル用のソース
     * @returns Promise
     */
    getRouteWalk({state, rootState}, cancelSource) {
      return new Promise((resolve, reject) => {
        // パラメータ生成.
        const param = setRouteCarWalkBicycleParam(rootState.searchConditions)

        // リクエスト情報を保持
        state.routeSearchParam.walk = param

        // 必要な検索条件を取得
        Axios.get('routeWalk', param, {source: cancelSource})
          .then((res) => {
            if (res.statusCode == constant.API_STATUS_CODE_OK) {
              // データ保存処理
              resolve(res.body)
            } else {
              throw res
            }
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    /**
     * ルート検索（自転車）
     * @param {*} state このstoreのstate
     * @param {*} rootState 親のstate
     * @param {*} cancelSource キャンセル用のソース
     * @returns Promise
     */
    getRouteBicycle({state, rootState}, cancelSource) {
      return new Promise((resolve, reject) => {
        // パラメータ生成.
        const param = setRouteCarWalkBicycleParam(rootState.searchConditions)

        // リクエスト情報を保持
        state.routeSearchParam.bicycle = param

        // 必要な検索条件を取得
        Axios.get('routeBicycle', param, {source: cancelSource})
          .then((res) => {
            if (res.statusCode == constant.API_STATUS_CODE_OK) {
              // データ保存処理
              resolve(res.body)
            } else {
              throw res
            }
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    /**
     * ルート再検索（電車・バス）
     * @param {*} state このstoreのstate
     * @param {Function} success 成功時コールバック
     * @param {Function} failed 失敗時コールバック
     * @param {Function} error エラー時コールバック
     */
    getRouteTrainBus({state}, {success, failed, error}) {
      // パラメータ生成
      const param = setRouteTotalParam(state.trainBusRouteSearchConditions)

      // 必要な検索条件を取得
      return Axios.get('routeTotal', param, {source: () => {}})
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            // 検索結果をstoreに格納する
            this.commit('RouteStore/updateTrainBusRouteResult', {
              value: res.body,
            })
            if (success) {
              success()
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * ルート際検索処理
     * @param success 成功時コールバック
     * @param failed 失敗コールバック
     * @param error エラーコールバック
     * @param routeType ルートタイプ
     * @param isNoUpdateInfo ルート情報の更新が不要かどうか
     * @return 新規検索条件、新規検索結果、新規ルート形状（成功時飲み）
     */
    async reRoute(
      {state, rootGetters},
      {success, failed, error, routeType, isNoUpdateInfo}
    ) {
      // 車(最適,高速優先,料金優先)、自転車、徒歩以外の場合は何も行わず終了
      if (isInvalidReRouteType(routeType)) {
        if (failed) {
          failed()
          return
        }
        throw new Error()
      }

      // 検索条件が初期値の場合は何も行わず終了
      let condition = deepcopy(state.forResearchConditions)
      if (Util.methods.isNull(condition)) {
        if (failed) {
          failed()
          return
        }
        throw new Error()
      }

      if (!isNoUpdateInfo) {
        // 検索条件更新
        condition.start = rootGetters.currentSpotObject
        condition.targetTime = Util.methods.getNow()
        condition.timeType = {id: 1, name: '出発'}
      }

      let targetParam = setRouteCarWalkBicycleParam(condition)
      targetParam.routeType = routeType

      // ルート情報取得
      const infoPromise = new Promise((resolve, reject) => {
        if (isNoUpdateInfo) {
          resolve(state.forResearchResult[routeType])
        } else {
          // ルート情報取得APIの向き先
          let targetApi
          switch (routeType) {
            case 'walk':
              targetApi = 'routeWalk'
              break
            case 'bicycle':
              targetApi = 'routeBicycle'
              break
            case 'carRecommend':
            case 'carToll':
            case 'carFree':
              targetApi = 'routeCar'
              break
            default:
              reject()
              return
          }
          Axios.get(targetApi, targetParam)
            .then((res) => {
              // レスポンス内容をチェックする
              if (isSuccessfulRouteResponce(res)) {
                // レスポンスが正常な場合
                resolve(res.body)
              } else {
                // レスポンスが異常な場合
                throw res
              }
            })
            .catch((e) => {
              reject(e)
            })
        }
      })
      // ルート形状取得
      const routePromise = new Promise((resolve, reject) => {
        Axios.get('shapeRoute', targetParam)
          .then((res) => {
            if (res.statusCode === constant.API_STATUS_CODE_OK) {
              resolve(res.body)
            } else {
              throw res
            }
          })
          .catch((e) => {
            reject(e)
          })
      })

      // 結果が全て受け取れるまで待機
      const promises = [infoPromise, routePromise]
      return Promise.all(promises)
        .then(([infoResult, routeResult]) => {
          if (success) {
            success(condition, infoResult, routeResult)
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },

    /**
     * 逆ジオコーディング住所検索API
     * @param {*} state このstoreのstate
     * @param {Function} success 成功時コールバック関数
     * @param {Function} failed 失敗時コールバック関数
     * @param {Function} error エラー時コールバック関数
     * @param {Function} cancel キャンセル時コールバック関数
     * @param {String} lat 緯度
     * @param {String} lon 経度
     */
    getAddressByCoord({state}, {success, failed, error, cancel, lat, lon}) {
      // 重複している処理をキャンセルし、新しいCancelSourceを発行
      addressByCoordCancelSources = Util.methods.resetCancelSource(
        addressByCoordCancelSources
      )
      const param = {
        lat: lat,
        lon: lon,
      }
      return Axios.get('address/coord', param, {
        source: addressByCoordCancelSources,
      })
        .then((res) => {
          if (res.statusCode === constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (e.message == 'Cancel') {
              if (cancel) {
                cancel()
              }
              return
            }

            if (error) {
              error(e)
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
    /**
     * 天気情報取得APIの実行
     * @param success 成功時コールバック
     * @param failed 失敗コールバック
     * @param error エラーコールバック
     * @param lat 緯度
     * @param lon 経度
     * @returns
     */
    getWeather({state}, {success, failed, error, lat, lon}) {
      return Axios.get('weather', {lat, lon}, {source: () => {}})
        .then((res) => {
          if (res.statusCode == constant.API_STATUS_CODE_OK) {
            if (success) {
              success(res.body)
            }
          } else {
            throw res
          }
        })
        .catch((e) => {
          if (e instanceof Error) {
            if (error) {
              error(e)
              return
            }

            // 指定がなければ共通エラーとしてハンドリング
            throw e
          } else {
            if (failed) {
              failed()
              return
            }
            // 指定がなければ共通エラーとしてハンドリング
            throw new Error()
          }
        })
    },
  },
}
