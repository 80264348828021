<template>
  <div>
    <!-- 非活性状態 -->
    <div
      v-if="isDisabled"
      class="border border-gray300 rounded-[10px] bg-white"
    >
      <div class="flex items-center">
        <div class="flex-none py-5 pl-4">
          <img class="w-6 h-6" src="@/assets/Icon_Paperclip.svg" />
        </div>
        <div
          class="flex-auto py-5 pl-2 text-left text-gray400 text-W2 text-[13px]"
        >
          {{ disabledText }}
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="border border-gray300 rounded-[10px] bg-white"
        :class="{'border-danger300': isShowErrorBorder}"
      >
        <div class="flex items-center">
          <!-- 画像選択後 -->
          <template v-if="modelValue">
            <div class="flex-none py-5 pl-4" @click="isShowPreview = true">
              <img class="w-10 h-10" :src="modelValue" />
            </div>
            <div
              class="flex items-center flex-auto py-5 pl-2 text-left text-blue text-W4 text-[15px]"
              @click="isShowPreview = true"
            >
              <img class="w-4 h-4 pr-1" src="@/assets/Icon_Loupe_Plus.svg" />
              画像を拡大
            </div>
            <div
              class="flex-none py-5 px-4 bg-white rounded-[20px]"
              @click="selectImage()"
            >
              <div
                class="items-center text-W7 text-[14px] px-3 py-2 leading-[14px] the-button-secondary"
              >
                変更
              </div>
            </div>
          </template>
          <!-- NoImageアイコンを表示時 -->
          <template v-else-if="isShowNoImageIcon">
            <div class="flex-none py-5 pl-4">
              <div class="w-10 h-10 bg-gray200">
                <img class="w-10 h-10" src="@/assets/Icon_NoImage.svg" />
              </div>
            </div>
            <template v-if="isUploadError">
              <div
                class="flex-auto px-2 text-left text-W2 text-[15px] text-danger300"
              >
                アップロードエラー
                <br />
                画像を選び直してください
              </div>
              <div
                class="flex-none py-5 pr-4 bg-white rounded-[20px]"
                @click="selectImage()"
              >
                <div
                  class="items-center text-W7 text-[14px] px-3 py-2 leading-[14px] the-button-default"
                >
                  追加
                </div>
              </div>
            </template>
            <!-- NoImageアイコンを表示時以外 -->
            <template v-else>
              <div
                class="flex-auto px-2 text-left text-gray400 text-W2 text-[15px]"
              >
                {{ placeholder }}
              </div>
              <div
                class="flex-none py-5 px-4 bg-white rounded-[20px]"
                @click="selectImage()"
              >
                <div
                  class="items-center text-W7 text-[14px] px-3 py-2 leading-[14px] the-button-secondary"
                >
                  変更
                </div>
              </div>
            </template>
          </template>
          <!-- 画像未選択状態 -->
          <template v-else>
            <div class="flex-none py-5 pl-4">
              <img class="w-6 h-6" src="@/assets/Icon_Paperclip_Plus.svg" />
            </div>
            <div
              class="flex-auto px-2 text-left text-gray400 text-W2 text-[15px]"
            >
              {{ placeholder }}
            </div>
            <div
              class="flex-none py-5 pr-4 bg-white rounded-[20px]"
              @click="selectImage()"
            >
              <div
                class="items-center text-W7 text-[14px] px-3 py-2 leading-[14px] the-button-default"
              >
                追加
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- エラーメッセージ部分 -->
      <div
        v-if="isShowError"
        class="mt-2 text-left text-danger300 text-W2 leading-[13px]"
      >
        {{ errorMessage }}
      </div>
      <input
        type="file"
        ref="imageFile"
        value=""
        class="hidden"
        accept="image/*"
        @change="selectedImageFile"
        @cancel="isEdited = true"
      />
      <!-- プレビュー画面 -->
      <Modal
        v-if="isShowPreview"
        :isModalCenter="true"
        modalPaddingX="20px"
        @disPlayModal="isShowPreview = false"
      >
        <div>
          <img
            class="w-full"
            :src="modelValue"
            @click="isShowPreview = false"
          />
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import {loadImageFile} from '@/utils/imageUtil'
import NativeUtil from '@/mixins/nativeUtil'
import Modal from '@/components/Modal.vue'
import log from 'loglevel'

const ImageUpload = {
  name: 'ImageUpload',
  components: {Modal},
  mixins: [NativeUtil],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    // エラー時のテキスト
    errorMessage: {
      type: String,
      default: '',
    },
    // プレースホルダー
    placeholder: {
      type: String,
      default: '',
    },
    // コンポーネントの操作可否状態
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // 非活性時テキスト
    disabledText: {
      type: String,
      default: '',
    },
    // 画像見登録アイコンの表示制御
    isShowNoImageIcon: {
      type: Boolean,
      default: false,
    },
    // アップロードエラー文の表示制御
    isUploadError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'upload-error'],
  data() {
    return {
      // 編集済フラグ
      isEdited: false,
      // プレビュー表示フラグ
      isShowPreview: false,
    }
  },
  computed: {
    /**
     * エラー表示有無
     */
    isShowError() {
      return this.isEdited && !this.modelValue && !!this.errorMessage
    },
    /**
     * エラー時の赤枠線表示有無
     */
    isShowErrorBorder() {
      return (
        (this.isEdited || this.isUploadError) &&
        !this.modelValue &&
        !!this.errorMessage
      )
    },
  },
  methods: {
    /**
     * ファイル追加・変更
     */
    async selectImage() {
      // カメラの権限を確認し、権限がある場合のみ処理を行う
      const isGranted = await this.checkCameraPermission()
      if (isGranted) {
        this.$refs.imageFile.click()
        if (this.isAndroidWebView()) {
          // Androidの場合<input type="file">のoncancelが発火しないため、このタイミングで編集済にする
          this.isEdited = true
        }
      }
    },
    /**
     * 画像ファイル選択処理
     */
    async selectedImageFile(value) {
      const firstIndex = 0
      const file = value.target.files[firstIndex]

      // 同じファイルを選択した場合に発火しなくなるため初期化
      this.$refs.imageFile.value = ''

      if (!file) {
        return
      }

      try {
        const imageSrc = await loadImageFile(file)
        this.$emit('update:modelValue', imageSrc)
      } catch (e) {
        // アップロードエラーダイアログ表示
        this.$emit('upload-error')
        log.debug('image error', e)
      }
    },
  },
}
export default ImageUpload
</script>
