<template>
  <div class="h-full overflow-y-auto flex flex-col">
    <Header
      class="shrink-0"
      headerTitle="保険金請求"
      :isShowClose="true"
      @clickClose="clickCloseButton()"
    />
    <!-- ボディ -->
    <div class="bg-white p-5">
      <!-- 請求者情報 -->
      <BaseBox class="userInformation text-left p-5">
        <template v-slot:explanation>
          <div class="text-W4 text-[13px] text-white leading-none">
            受付番号（前方10桁）
          </div>
          <div class="text-W7 text-[18px] text-white leading-none mt-2">
            {{ claimReceiptNumber }}
          </div>
          <div class="text-W4 text-[13px] text-white leading-none mt-4">
            事故日 {{ getFormatYearDateSlash(incidentInfo.incidentDate) }}
          </div>
        </template>
      </BaseBox>
      <!-- 請求可能な金額 -->
      <BaseBox class="mt-3 p-3 bg-gray200" @click="onClickBillingAmount()">
        <template v-slot:explanation>
          <div class="text-W7 text-[13px] leading-none">請求可能な金額</div>
          <div
            v-if="isLexusSpecialContract"
            class="mt-2 text-W4 text-[14px] leading-none text-gray500"
          >
            契約内容をご確認ください。
          </div>
          <div v-else class="mt-2 flex flex-row items-center">
            <div class="text-W4 text-[14px] leading-none text-gray500">
              ￥{{ addCommaForInteger(incidentInfo.creditLimit) }} ×
              支払い対象日数（最大30日）
            </div>
            <div class="flex flex-row items-center ml-auto">
              <div class="text-W4 text-[13px] leading-none text-blue">詳細</div>
              <img src="@/assets/Icon_Right_blue.svg" class="h-3 w-3" />
            </div>
          </div>
        </template>
      </BaseBox>
      <!-- 請求画面メニュー -->
      <div
        v-if="isNotWaitingOrCompletedClaim"
        class="mt-3 w-full flex flex-row"
      >
        <IconBox
          v-for="menu in menuList"
          class="mr-2 last:mr-0"
          :key="menu.id"
          :icon="menu.icon"
          :title="menu.name"
          @click="onClickMenuCard(menu.id)"
        />
      </div>
    </div>
    <!-- 請求候補一覧 -->
    <div class="flex-1">
      <!-- 登録無し時 -->
      <div
        v-if="isEmptyClaimList"
        class="h-full pt-5 px-5 pb-[120px] bg-gray200"
      >
        <div class="mx-auto text-W7 text-[18px] leading-[27px]">請求候補</div>
        <BaseBox
          class="mt-2 border-2 border-dashed !rounded-[10px] border-gray700"
        >
          <template v-slot:explanation>
            <img
              class="mt-[37px] mx-auto w-[54px] h-[54px]"
              src="@/assets/Icon_Empty_Claim.svg"
            />
            <div
              class="mt-3 mb-9 text-center text-W4 text-[13px] leading-[23px] text-gray400"
            >
              請求候補の登録がありません
            </div>
          </template>
        </BaseBox>
      </div>
      <!-- 請求候補(候補あり) -->
      <div
        v-else-if="isExistsNotWaitingOrCompletedInsuranceList"
        class="h-full pt-5 px-5 pb-[120px] bg-blue200"
      >
        <div class="mx-auto text-W7 text-[18px] leading-none">請求候補</div>
        <div
          class="mt-[10.25px] text-W4 text-[13px] leading-[16.5px] text-gray400 text-left"
        >
          保険金請求をしたい候補にチェックを入れてください。
        </div>
        <div
          class="mt-[1.25px] text-W4 text-[11px] leading-[16.5px] text-gray400 text-left"
        >
          ※請求期間外の候補が表示されていることがあります。
        </div>
        <div>
          <template v-for="(claim, index) in claims" :key="claim.itemId">
            <!-- 日付 -->
            <div
              v-if="isShowDate(index)"
              class="w-full mt-5 first:mt-[14.25px]"
            >
              <div class="text-W7 text-[13px] text-left leading-none">
                {{ displayUsedDate(claim.detail.usedDate) }}
              </div>
            </div>
            <!-- カード -->
            <ClaimCard
              :claim="claim"
              :checked="isIncludeSelectedItemId(claim.itemId)"
              :isNeedCheckAndEdit="true"
              :isError="claimErrors[index]"
              class="my-2"
              @check-claim="checkClaim($event)"
              @edit-claim="clickEditClaim($event)"
            />
            <div
              v-if="isIncludeSelectedItemId(claim.itemId) && claimErrors[index]"
              class="mt-[5px] text-left text-W4 text-[11px] leading-none text-danger300"
            >
              不足項目があります。編集してください。
            </div>
          </template>
        </div>
      </div>
      <!-- 請求完了 -->
      <div v-else class="h-full pt-5 px-5 pb-12 bg-gray200">
        <div class="mx-auto text-W7 text-[18px] leading-[18px]">請求内容</div>
        <div class="mt-3 flex items-center justify-center">
          <img class="h-3 w-3 mr-2" src="@/assets/IconCheckMarkGray.svg" />
          <div class="text-W4 text-[13px] leading-[13px] text-gray400">
            次の内容で請求を受付けました。
          </div>
        </div>
        <div
          class="mt-3 flex items-center justify-between p-4 bg-white rounded-[10px] border border-gray300"
        >
          <div class="text-W4 text-[13px] text-gray500">請求金額合計</div>
          <div class="text-W7 text-[22px] leading-[22px]">
            {{ `¥${totalClaimAmount}` }}
          </div>
        </div>
        <div>
          <template v-for="(claim, index) in claims" :key="claim.itemId">
            <!-- 日付 -->
            <div v-if="isShowDate(index)" class="w-full mt-5">
              <div class="text-W7 text-[13px] text-left leading-none">
                {{ getFormatYearDateSlash(claim.detail.usedDate) }}
              </div>
            </div>
            <!-- カード -->
            <ClaimCard
              :claim="claim"
              :isNeedCheckAndEdit="false"
              class="my-2"
            />
          </template>
        </div>
      </div>
    </div>
    <!-- 請求手続きに進むボタン -->
    <div
      v-if="isNotWaitingOrCompletedClaim"
      class="fixed bg-white w-screen bottom-0 pt-4 px-6 rounded-t-[20px] shadow-panel pb-safe-area"
    >
      <TheButton
        class="w-full last:mb-4"
        text="請求手続きに進む"
        :isDisabled="isNoSelectedClaims || isErrorClaims"
        @click-button="gotoInsuranceClaimConfirmed()"
      />
      <div
        v-if="isErrorClaims"
        class="mt-[5px] text-W4 text-[11px] leading-none text-danger300"
      >
        内容に不足がある候補が選択されています。
      </div>
    </div>
    <!-- 領収書ダウンロードエラーポップアップ -->
    <Modal
      v-if="isShowReceiptImageDownloadFailedModal"
      :isShowCloseButton="false"
      :isModalCenter="true"
      modalPaddingX="20px"
    >
      <div class="px-5 pt-8 pb-6">
        <div class="text-W5 font-semibold text-[17px] leading-[25.5px]">
          エラーが発生しました。
        </div>
        <div class="mt-3 mx-5 text-W3 text-[15px] leading-[23px]">
          通信環境をご確認いただくか、時間を置いて再度お試しください。
        </div>
        <div
          class="mt-5 py-4 the-button-default text-W7 text-[15px] leading-[15px]"
          @click="isShowReceiptImageDownloadFailedModal = false"
        >
          もどる
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import BaseBox from '@/components/atoms/BaseBox.vue'
import BaseCard from '@/components/atoms/BaseCard.vue'
import Header from '@/components/organisms/Header.vue'
import IconBox from '@/components/atoms/IconBox.vue'
import TheButton from '@/components/atoms/TheButton.vue'
import Util from '@/mixins/util'
import NativeUtil from '@/mixins/nativeUtil'
import ClaimCard from '@/components/molecules/claims/ClaimCard.vue'
import deepcopy from 'deepcopy'
import Modal from '@/components/Modal.vue'
import Ajv from 'ajv'
import AddFormats from 'ajv-formats'
import dayjs from 'dayjs'

// メニュー項目
const MENU_ITEM = {
  HELP: {
    id: 1,
    icon: 'Icon_Instructions_blue.svg',
    name: '操作方法について',
  },
  FAQ: {id: 2, icon: 'Icon_FAQ_blue.svg', name: 'よくあるご質問'},
  UPLOAD: {id: 3, icon: 'Icon_Upload_blue.svg', name: '請求候補を登録'},
}

// メニュー表示リスト
const MENU_LIST = [MENU_ITEM.HELP, MENU_ITEM.FAQ, MENU_ITEM.UPLOAD]

const LEXUS_CREDIT_LIMIT = '999999'

const InsuranceClaimsTop = {
  name: 'InsuranceClaimsTop',
  components: {
    BaseBox,
    BaseCard,
    Header,
    IconBox,
    TheButton,
    ClaimCard,
    Modal,
  },
  mixins: [Util, NativeUtil],
  data() {
    return {
      menuList: MENU_LIST, // メニューカード表示項目一覧
      isShowReceiptImageDownloadFailedModal: false, // 遷移前の領収書取り込み失敗ポップアップ
      claimErrors: [], // 請求候補のエラー状況対応表
    }
  },
  computed: {
    /**
     * 請求候補が０件かどうかを返す
     */
    isEmptyClaimList() {
      // eslint-disable-next-line no-magic-numbers
      return this.claims.length === 0
    },
    /**
     * 請求情報リスト
     */
    claims() {
      return this.$store.state.ClaimStore.claims
    },
    /**
     * 選択請求情報リスト
     */
    selectedClaimItemIds() {
      return this.$store.state.ClaimStore.selectedClaimItemIds
    },
    /**
     * 請求カードが未選択かどうか
     */
    isNoSelectedClaims() {
      const noLength = 0
      return this.selectedClaimItemIds.length === noLength
    },
    /**
     * 事故紐付け情報
     */
    incidentInfo() {
      return this.$store.state.ClaimStore.incidentInfo
    },
    /**
     * 受付番号
     */
    claimReceiptNumber() {
      const leadingIndex = 0
      const trailingIndex = -3
      return this.$store.state.ClaimStore.claimReceiptNumber.slice(
        leadingIndex,
        trailingIndex
      )
    },
    /**
     * レクサス特約か否か
     */
    isLexusSpecialContract() {
      return this.incidentInfo.creditLimit === LEXUS_CREDIT_LIMIT
    },
    /**
     * 請求完了か判定
     */
    isNotWaitingOrCompletedClaim() {
      return !(
        this.incidentInfo.status ===
          this.$config.INCIDENT_INFO_STATUS.REGISTERED ||
        this.incidentInfo.status === this.$config.INCIDENT_INFO_STATUS.COMPLETED
      )
    },
    /**
     * 請求未完了の請求があるか判定
     */
    isExistsNotWaitingOrCompletedInsuranceList() {
      return !this.isEmptyClaimList && this.isNotWaitingOrCompletedClaim
    },
    /**
     * 全請求の合計金額
     */
    totalClaimAmount() {
      return this.addCommaForInteger(
        this.$store.getters['ClaimStore/totalClaimAmount']
      )
    },
    /**
     * エラーのある請求データが存在するか否か
     */
    isErrorClaims() {
      return this.selectedClaimItemIds.some(
        (selectedClaimItemId) =>
          this.claimErrors[
            this.claims.findIndex(
              (claim) => claim.itemId === selectedClaimItemId
            )
          ]
      )
    },
  },
  created() {
    this.getClaims()
    // webViewの場合はFG復帰時の生体認証失敗処理を定義
    if (this.isWebView()) {
      this.defineResumeBioAuthFailedMethod()
    }
  },
  beforeUnmount() {
    // webViewの場合は生体認証失敗処理定義をwindowから削除
    if (this.isWebView()) {
      delete window.resumeBioAuthFailed
    }
  },
  methods: {
    /**
     * 受け取ったカードが選択されているかどうか
     * @param {String} itemId 請求ファイル名/予約ID
     * @returns {Boolean} 選択されているか
     */
    isIncludeSelectedItemId(itemId) {
      return this.selectedClaimItemIds.includes(itemId)
    },
    /**
     * 閉じるボタン押下処理
     */
    clickCloseButton() {
      this.$router.push({name: this.$config.DISPLAY_MENU_TOP})
      // 請求データの初期化
      this.$store.commit('ClaimStore/updateClaims', [])
      this.$store.commit('ClaimStore/updateSelectedClaimItemIds', [])
      this.$store.commit('ClaimStore/updateClaimReceiptNumber', '')
      this.$store.commit('ClaimStore/initIncidentInfo')
    },
    /**
     * 日付を表示するか
     * @param index  請求のインデックス
     * @return {Boolean} true:日付を表示する
     */
    isShowDate(index) {
      if (index === this.$config.ZERO) {
        return true
      }
      const usedDate = this.getFormatYearDateSlash(
        this.claims[index].detail.usedDate
      )
      const prevUsedDate = this.getFormatYearDateSlash(
        // eslint-disable-next-line no-magic-numbers
        this.claims[index - 1].detail.usedDate
      )
      return usedDate != prevUsedDate
    },
    /**
     * 表示用の利用日を生成する
     * @param {String} usedDate 利用日
     * @return 表示する利用日（利用日が取得出来なければ指定の文言を表示する）
     */
    displayUsedDate(usedDate) {
      return this.isNull(usedDate)
        ? '- / - / -'
        : this.getFormatYearDateSlash(usedDate)
    },
    /**
     * 請求の選択チェックが変更された時の処理
     * @param {Object} claimCheckedInfo {claimItemId: String, checked: Boolean} 請求のアイテムIDと選択状態
     */
    checkClaim(claimCheckedInfo) {
      let selectedClaimItemIds = deepcopy(
        this.$store.state.ClaimStore.selectedClaimItemIds
      )
      if (claimCheckedInfo.checked) {
        selectedClaimItemIds.push(claimCheckedInfo.claimItemId)
      } else {
        selectedClaimItemIds = selectedClaimItemIds.filter(function (itemId) {
          return itemId != claimCheckedInfo.claimItemId
        })
      }
      this.$store.commit(
        'ClaimStore/updateSelectedClaimItemIds',
        selectedClaimItemIds
      )
    },
    /**
     * 請求の編集ボタンが押下された時の処理
     */
    clickEditClaim(claim) {
      //該当請求の領収書データを取得する
      this.gotoInsuranceClaimDetailForUpdate(claim)
    },
    /**
     * 請求手続きに進むボタンが押下されたときの処理
     */
    gotoInsuranceClaimConfirmed() {
      this.$router.push({name: this.$config.DISPLAY_INSURANCE_CLAIM_CONFIRMED})
    },
    /**
     * 請求情報取得処理
     */
    getClaims() {
      this.$store.commit('startLoading')

      // 成功時
      const success = () => {
        if (
          this.$route.params.prevRouteName ===
          this.$config.DISPLAY_INSURANCE_CLAIMS_LOGIN
        ) {
          const selectedClaimItemIds = this.claims.flatMap((claim) =>
            claim.type === this.$config.CLAIM_TYPE.RECEIPT ? claim.itemId : []
          )
          this.$store.commit(
            'ClaimStore/updateSelectedClaimItemIds',
            selectedClaimItemIds
          )
        }
        this.claimErrors = this.createClaimErrors(this.claims)
        this.$store.commit('endLoading')
      }
      this.$store.dispatch('ClaimStore/getClaims', {
        success: success,
      })
    },
    /**
     * メニューカード押下処理
     */
    async onClickMenuCard(value) {
      switch (value) {
        case MENU_ITEM.HELP.id:
          window.open(
            process.env.VUE_APP_NAMO_CONTRACTOR_HELP_URL,
            '_blank',
            'noreferrer'
          )
          break
        case MENU_ITEM.FAQ.id:
          window.open(
            process.env.VUE_APP_NAMO_CONTRACTOR_FAQ_URL,
            '_blank',
            'noreferrer'
          )
          break
        case MENU_ITEM.UPLOAD.id:
          // カメラの権限を確認し、権限がある場合のみ処理を行う
          if (await this.checkCameraPermission()) {
            this.$router.push({
              name: this.$config.DISPLAY_INSURANCE_CLAIM_DETAIL,
            })
          }
          break
        default:
          break
      }
    },
    /**
     * 請求可能な金額欄押下処理
     */
    onClickBillingAmount() {
      window.open(
        process.env.VUE_APP_NAMO_CONTRACTOR_BILLING_AMOUNT_URL,
        '_blank',
        'noreferrer'
      )
    },
    /**
     * 更新時の遷移処理
     */
    gotoInsuranceClaimDetailForUpdate(claim) {
      const success = (receiptImage) => {
        this.$store.commit('endLoading')

        // 遷移先に請求データIDを渡す
        this.$router.push({
          name: this.$config.DISPLAY_INSURANCE_CLAIM_DETAIL,
          params: {
            claimItemId: claim.itemId,
            receiptImage: receiptImage,
          },
        })
      }

      const error = (e) => {
        this.$store.commit('endLoading')
        // セッション判定NG、または閉局中エラーの場合
        if (
          e?.message?.includes('invalidAccessToken') ||
          e?.response?.data?.statusCode ===
            this.$config.API_STATUS_CODE_SERVICE_UNAVAILABLE
        ) {
          throw e
        }
        // 領収書ダウンロードエラーポップアップ表示
        this.isShowReceiptImageDownloadFailedModal = true
      }

      // 領収書をダウンロードするかを判定する(ウイルススキャン済みデータのみダウンロードする)
      if (claim.scanStatus === this.$config.VIRUS_SCAN_STATUS.SCANNED) {
        this.$store.commit('startLoading')
        // 領収書のダウンロード処理を呼び出す
        this.$store.dispatch('ClaimStore/getS3ReceiptData', {
          itemId: claim.itemId,
          success: success,
          error: error,
        })
      } else {
        // 領収書データなしとして遷移させる
        this.$router.push({
          name: this.$config.DISPLAY_INSURANCE_CLAIM_DETAIL,
          params: {
            claimItemId: claim.itemId,
            receiptImage: '',
          },
        })
      }
    },
    /**
     * FG復帰時の生体認証失敗処理をwindowに定義
     */
    defineResumeBioAuthFailedMethod() {
      window.resumeBioAuthFailed = () => {
        // Storeを初期化し、メニューTOP画面へ遷移
        this.clickCloseButton()
      }
    },
    /**
     * 引数で渡された請求データにエラーがあるか判定する
     * @param {Object} claim 請求情報
     */
    isClaimError(claim) {
      const ajv = new Ajv()

      // ビルトインフォーマット読み込み
      AddFormats(ajv)

      // 空文字＋スペースのみをNGとするチェック
      ajv.addKeyword({
        keyword: 'isNotEmpty',
        validate: (_schema, data) => {
          return typeof data == 'string' && data.trim() !== ''
        },
        errors: false,
      })

      // 事故日の日付（00時00分00秒）
      const incidentDate = dayjs(this.incidentInfo.incidentDate).startOf('day')
      // 上限値は今日の日付（00時00分00秒）
      const maxUsedDate = this.getFormatYearDate(dayjs().startOf('day'))
      // 下限値は事故日の日付
      const minUsedDate = this.getFormatYearDate(incidentDate)

      // 各入力のバリデーションスキーム
      const validationSchemaObject = {
        type: 'object',
        required: [
          'transportation',
          'fare',
          'usedDate',
          'departure',
          'destination',
          'relationshipInsured',
          'userName',
        ],
        properties: {
          // 数値型、1~9
          transportation: {
            type: 'number',
            // eslint-disable-next-line no-magic-numbers
            enum: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
          // 数値型、1~999999
          fare: {
            type: 'number',
            minimum: 1,
            maximum: 999999,
          },
          // 文字列型、yyyy-mm-dd 未来日禁止
          usedDate: {
            type: 'string',
            format: 'date',
            formatMaximum: maxUsedDate,
            formatMinimum: minUsedDate,
          },
          // 文字列、1文字〜100文字
          departure: {type: 'string', isNotEmpty: true, maxLength: 100},
          // 文字列、1文字〜100文字
          destination: {type: 'string', isNotEmpty: true, maxLength: 100},
          // 文字列、0 or 1
          relationshipInsured: {
            type: 'string',
            enum: ['0', '1'],
          },
          // 文字列、0文字(空文字)〜100文字
          userName: {type: 'string', minLength: 0, maxLength: 100},
        },
      }

      // 基本的なバリデーションチェック
      // バリデーションを実行
      const validate = ajv.compile(validationSchemaObject)
      const valid = validate(claim.detail)
      if (!valid) {
        return true
      }

      // 利用者が家族かつ氏名が未入力であるか判定
      const isInvalidFamilyAndUserName =
        claim.detail.relationshipInsured ===
          this.$config.RELATIONSHIP_INSURED.FAMILY.key &&
        claim.detail.userName === ''

      // 領収書画像NG判定
      const isReceiptImageNG =
        claim.scanStatus === this.$config.VIRUS_SCAN_STATUS.REJECTED

      return isInvalidFamilyAndUserName || isReceiptImageNG
    },
    /**
     * 請求候補のエラー状況対応表を生成する
     * @param {Array} claims 請求候補一覧
     */
    createClaimErrors(claims) {
      return claims.map((claim) => this.isClaimError(claim))
    },
  },
}
export default InsuranceClaimsTop
</script>

<style scoped>
/* 請求者情報欄の背景グラデーション */
.userInformation {
  background: transparent linear-gradient(90deg, #003399 0%, #00b4ff 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
.flex-flow-col {
  flex-flow: column;
}
</style>
