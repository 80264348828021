<template>
  <svg viewBox="0 0 32 32">
    <g transform="translate(-241 -119)">
      <circle
        cx="16"
        cy="16"
        r="16"
        transform="translate(241 119)"
        fill="#f2f3f5"
        class="icon-favorite-ribbon-circle"
      />
      <g transform="translate(250.13 128.13)">
        <rect
          width="14"
          height="14"
          transform="translate(-0.129 -0.13)"
          fill="none"
        />
        <path
          d="M200-831v-12.842a1.112,1.112,0,0,1,.348-.811,1.112,1.112,0,0,1,.811-.348h8.5a1.112,1.112,0,0,1,.811.348,1.112,1.112,0,0,1,.348.811V-831l-5.407-2.317Zm1.159-1.757,4.249-1.8,4.249,1.8v-11.085h-8.5Zm0-11.085h0Z"
          transform="translate(-198.538 844.869)"
          fill="#0072ff"
          class="icon-favorite-ribbon-path"
        />
      </g>
    </g>
  </svg>
</template>
