<template>
  <svg viewBox="0 0 32 32">
    <defs>
      <clipPath>
        <rect
          width="16"
          height="16"
          transform="translate(-0.364 -0.758)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g transform="translate(-51 -119)">
      <circle
        cx="16"
        cy="16"
        r="16"
        transform="translate(51 119)"
        fill="#0072ff"
        class="icon-favorite-current-circle"
      />
      <g transform="translate(60.534 125.534)">
        <g transform="translate(-0.932 2.069)" clip-path="url(#clip-path)">
          <path
            d="M14.4,11.8a1.4,1.4,0,1,0-.99-.41A1.349,1.349,0,0,0,14.4,11.8Zm0,6.62a23.425,23.425,0,0,0,3.93-4.39,6.66,6.66,0,0,0,1.27-3.47A5.206,5.206,0,0,0,18.09,6.7,5.037,5.037,0,0,0,14.4,5.2a5.037,5.037,0,0,0-3.69,1.5A5.206,5.206,0,0,0,9.2,10.56a6.563,6.563,0,0,0,1.3,3.47A25.436,25.436,0,0,0,14.4,18.42Zm0,1.58a26.6,26.6,0,0,1-4.81-5.09A7.915,7.915,0,0,1,8,10.56,6.187,6.187,0,0,1,9.93,5.78a6.5,6.5,0,0,1,8.94,0,6.187,6.187,0,0,1,1.93,4.78,7.915,7.915,0,0,1-1.59,4.35A26.6,26.6,0,0,1,14.4,20ZM14.4,10.56Z"
            transform="translate(-6.765 -4.758)"
            fill="#fff"
            class="icon-favorite-current-path"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
