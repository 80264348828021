<template>
  <!-- 乗換不要駅の場合 -->
  <div v-if="isNoTransferRequired" id="TransferPoint" class="flex">
    <!-- 縦線 -->
    <div class="w-[72px]">
      <!-- 前区間の路線 -->
      <div
        class="h-1/2 ml-auto w-1"
        :style="getMoveLineColor(prevMoveSection)"
      />
      <!-- 乗換不要を示す斜線 -->
      <div class="relative">
        <div class="diagonalLine" />
      </div>
      <!-- 次区間の路線 -->
      <div
        class="h-1/2 ml-auto w-1"
        :style="getMoveLineColor(nextMoveSection)"
      />
    </div>
    <!-- 駅情報 -->
    <div class="flex-1">
      <div class="relative">
        <div class="customBorder" />
      </div>
      <div class="flex py-2">
        <div class="text-W4 text-[12px] leading-[12px] text-gray ml-4 pr-2">
          乗換不要
        </div>
        <div class="text-W4 text-[12px] leading-[12px]">
          {{ pointSection.spotName }}
        </div>
      </div>
      <div class="relative">
        <div class="customBorder" />
      </div>
    </div>
  </div>
  <div v-else id="TransferPoint" class="bg-white rounded-[4px] p-3 flex">
    <!-- 時間表示部分 -->
    <div class="end w-14 self-center">
      <!-- 到着時間 -->
      <div v-if="prevMoveSection" class="flex justify-end">
        <!-- 時間 -->
        <div class="text-W6 text-[16px] leading-[17px]">
          {{ getFormatTime(prevMoveSection.toTime) }}
        </div>
        <!-- 「着」文字 -->
        <div
          class="text-W3 text-[10px] text-gray500 leading-[10px] mt-[5px] ml-[2px]"
        >
          着
        </div>
      </div>
      <div v-if="prevMoveSection && nextMoveSection" class="w-full h-[2px]" />
      <!-- 出発時間 -->
      <div v-if="nextMoveSection" class="flex justify-end">
        <!-- 時間 -->
        <div class="text-W6 text-[16px]">
          {{ getFormatTime(nextMoveSection.fromTime) }}
        </div>
        <!-- 「発」文字 -->
        <div
          class="text-W3 text-[10px] text-gray500 leading-[10px] mt-[5px] ml-[2px]"
        >
          発
        </div>
      </div>
    </div>
    <!-- 地点名表示部分 -->
    <div
      class="text-W6 text-[15px] leading-[15px] ml-4 text-left flex-1 self-center"
    >
      {{ pointSection.spotName }}
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util'
import SearchRouteUtil from '@/mixins/searchRouteUtil'
import {getMobilitySectionColor} from '@/utils/transferUtil'

const TransferDetailPoint = {
  name: 'TransferDetailPoint',
  components: {},
  props: {
    pointSection: {
      type: Object,
    },
    prevMoveSection: {
      type: Object,
    },
    nextMoveSection: {
      type: Object,
    },
    isNoTransferRequired: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [Util, SearchRouteUtil],
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {
    /**
     * 区間の線の色を返却する
     * @param {Object} moveSection 移動セクション
     * @return {Object} 背景色スタイルオブジェクト
     */
    getMoveLineColor(moveSection) {
      return {
        background: getMobilitySectionColor(moveSection),
      }
    },
  },
}
export default TransferDetailPoint
</script>
<style scoped>
/* 直通運転時の横線 */
.customBorder {
  width: 100%;
  border-bottom: 1px solid #dbdee3;
}
/* 直通運転時の斜線 */
.diagonalLine {
  position: absolute;
  right: -8px;
  width: 24px;
  height: 8px;
  background: #f2f3f5; /* 背景と同色にする */
  transform: rotate(-45deg);
}
</style>
