<template>
  <div
    id="IconBox"
    class="flex flex-col items-center w-full p-4 border border-gray300 rounded-[10px] bg-white"
  >
    <!-- アイコン -->
    <img class="w-5 h-5" :src="require(`@/assets/${icon}`)" />
    <!-- タイトル -->
    <div class="mt-2 text-W6 text-[15px] leading-[21px]">
      {{ title }}
    </div>
  </div>
</template>

<script>
const IconBox = {
  name: 'IconBox',
  props: {
    // 表示アイコン
    icon: {
      type: String,
      required: true,
    },
    // 表示タイトル
    title: {
      type: String,
      required: true,
    },
  },
}
export default IconBox
</script>

<style></style>
