// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/common/Icon_Text_Form_Close_Black.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input-text[data-v-30b7d16f]{height:43px;border-radius:8px;outline:none;line-height:15px;font-size:15px;padding-left:16px;padding-right:16px;width:100%;-webkit-appearance:none;-moz-appearance:none;appearance:none;color:#1a1c21;font-weight:400;caret-color:#0072ff;transition:padding-right .01s}.input-text[data-v-30b7d16f]::-moz-placeholder{color:#979ca1;font-weight:300}.input-text[data-v-30b7d16f]::placeholder{color:#979ca1;font-weight:300}.input-area>.input-text[data-v-30b7d16f]:focus{border-color:#0072ff;padding-right:40px}input[data-v-30b7d16f]:disabled{opacity:.4}input[type=search][data-v-30b7d16f]::-webkit-search-cancel-button{-webkit-appearance:none}input[type=search][data-v-30b7d16f]:focus::-webkit-search-cancel-button{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:26px 15px;background-position-y:center;background-position-x:-2px;height:43px;width:36px;padding:0;position:absolute;right:0}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
