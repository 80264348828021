<template>
  <div class="fixed-app flex flex-col">
    <!-- ヘッダー部 -->
    <div class="w-full bg-white z-[500]">
      <div class="flex text-center w-full items-center h-[45px] px-3">
        <div class="w-full text-left">
          <img
            src="@/assets/Icon_Left_gray.svg"
            class="h-4"
            @click="goToBackPage()"
          />
        </div>
        <div class="text-W6 leading-[15px] text-[15px] w-full">
          乗り換え案内
        </div>
        <div class="w-full" />
      </div>
      <!-- 線 -->
      <div class="h-[0.5px] w-full bg-gray300" />
    </div>
    <!-- ボディ部 -->
    <div class="flex-1 w-full bg-gray200 overflow-y-auto scroll-bar-none">
      <div class="pt-5 px-5 pb-[90px]">
        <div
          class="bg-white rounded-[8px] p-4 flex mb-[20px] justify-between total-time-distance-suica-shadow-box"
        >
          <div class="w-full leading-[14px] text-left">
            <!-- 時間 -->
            <div
              class="flex items-end"
              :class="isAndroidWebView() ? 'mb-2' : 'mb-1'"
            >
              <div class="flex items-center">
                <div class="text-W6 text-[22px] leading-[25px] self-center">
                  {{ getStartTime }}
                </div>
                <img src="@/assets/Arrow_Right_Gray.svg" class="h-3 w-3 mx-1" />
                <div class="text-W6 text-[22px] leading-[25px] self-center">
                  {{ getGoalTime }}
                </div>
              </div>
              <div
                class="text-W4 text-center text-[14px] leading-[16px] self-center"
              >
                （{{ getMoveTime }}）
              </div>
            </div>
            <!-- 所要金額/Suica残高 -->
            <div class="w-full flex h-auto items-center justify-between">
              <!-- 所要金額 -->
              <div class="flex-1">
                <div class="flex-1">
                  <span class="text-W4 text-[11px] text-gray400 mr-px">
                    合計
                  </span>
                  <span class="text-W4 text-[11px] text-gray400">
                    {{ $config.IC_PRIORITY_DISPLAY }}
                  </span>
                  <!-- iPhone端末は、1行にまとめる -->
                  <span v-if="!isAndroidWebView()" class="text-W5 text-[13px]">
                    <span class="ml-2 mr-px">¥</span>
                    <span>
                      {{ addCommaForInteger(showingRoute.summaryFare) }}
                    </span>
                  </span>
                </div>
                <!-- Android端末は、金額のみを改行 -->
                <span v-if="isAndroidWebView()" class="text-W5 text-[13px]">
                  <span class="mr-px">¥</span>
                  <span>
                    {{ addCommaForInteger(showingRoute.summaryFare) }}
                  </span>
                </span>
              </div>
              <!-- 縦線 -->
              <div v-if="isAndroidWebView()" class="w-[1px] h-8 bg-gray300" />
              <!-- Suica残高を表示 -->
              <div
                v-if="isAndroidWebView()"
                class="flex-1 pl-1"
                @click="onClickDisplaySuicaBalance()"
              >
                <SuicaBalance
                  :balance="balance"
                  :isReadCardCompleted="isReadCardCompleted"
                  :isReadCardFailed="isReadCardFailed"
                  :isBalanceFallingShort="isBalanceFallingShort"
                  :isSuicaLinked="isSuicaLinked"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-for="(section, index) in showingRoute.sections" :key="index">
          <!-- type=point -->
          <div v-if="section.type === 'point'">
            <TransferDetailPoint
              :pointSection="section"
              :prevMoveSection="showingRoute.sections[index - 1]"
              :nextMoveSection="showingRoute.sections[index + 1]"
              :isNoTransferRequired="
                isNoTransferRequired(showingRoute.sections, index)
              "
            />
          </div>

          <!-- type=move -->
          <div v-if="section.type === 'move'">
            <TransferDetailMove
              :moveSection="section"
              :prevPointSection="showingRoute.sections[index - 1]"
              :nextPointSection="showingRoute.sections[index + 1]"
            />
          </div>
        </div>
      </div>
      <FooterMargin :isUseSafeArea="false" />
    </div>
    <!-- １本前・１本後 -->
    <div
      class="absolute bottom-[16px] flex flex-col text-center w-full items-center px-5"
    >
      <div class="transfer-before-after bg-white">
        <div class="text-left">
          <img
            src="@/assets/TransferOneLineBefore.png"
            class="w-[66px] h-7"
            :class="getOneLineBeforeButtonClass"
            @click="beforeTransfer()"
            alt="１本前"
          />
        </div>
        <div class="flex-1 text-W4 text-[13px] leading-[13px]">
          {{ `${baseRouteDateStr}${beforeAfterTransferStr}` }}
        </div>
        <img
          src="@/assets/TransferOneLineAfter.png"
          class="w-[66px] h-7"
          :class="getOneLineAfterButtonClass"
          @click="afterTransfer()"
          alt="１本後"
        />
      </div>
      <FooterMargin :isUseSafeArea="false" />
    </div>
  </div>
</template>
<script>
import Util from '@/mixins/util'
import TransferDetailMove from '@/components/transfer/TransferDetailMove.vue'
import TransferDetailPoint from '@/components/transfer/TransferDetailPoint.vue'
import SuicaBalance from '@/components/molecules/SuicaBalance.vue'
import NativeUtil from '@/mixins/nativeUtil'
import suica from '@/utils/suicaNativeUtil'
import FooterMargin from '@/components/organisms/FooterMargin.vue'
import {
  isNoNextTransferRequired,
  getStartTimeFromSection,
  getGoalTimeFromSection,
} from '@/utils/transferUtil'
import {transferMinuteToText} from '@/utils/utils'

const TransferDetail = {
  name: 'TransferDetail',
  components: {
    TransferDetailMove,
    TransferDetailPoint,
    SuicaBalance,
    FooterMargin,
  },
  mixins: [Util, NativeUtil],
  data() {
    return {
      // 表示しているルート
      showingRoute: this.$store.state.TransferStore.selectedTransferResult,
      // 基本のルート
      baseRoute: this.$store.state.TransferStore.selectedTransferResult,
      // 一本前、一本後のカウント
      slideCount: 0,
      // ベースのルートナンバー
      baseSlideRouteNo: 1,
      isSuicaLinked: false, // Suicaが連携されているか
      balance: 0, // Suica残高
      isReadCardCompleted: false, // Suica残高の取得が完了したか
      isReadCardFailed: false, // Suica残高取得失敗したか
    }
  },
  created() {
    // ベースのルートナンバーを算出
    if (Array.isArray(this.transferSlideResult)) {
      const defaultSlide = this.transferSlideResult
        .sort(
          (lhs, rhs) =>
            rhs.sections[1].fromTime < lhs.sections[1].fromTime ? 1 : -1 // eslint-disable-line no-magic-numbers
        )
        .find((result) => {
          return (
            this.baseRoute.sections[1].fromTime <= result.sections[1].fromTime // eslint-disable-line no-magic-numbers
          )
        })
      this.baseSlideRouteNo = Number(defaultSlide.routeNo)
    }
  },
  async mounted() {
    if (this.isAndroidWebView()) {
      // Androidの場合Suicaの残高を取得
      await this.getSuicaBalance()
      this.addResumeEvent(this.getSuicaBalance)
    }
  },
  beforeUnmount() {
    if (this.isAndroidWebView()) {
      // Androidの場合Suicaの残高を取得を削除
      this.removeResumeEvent(this.getSuicaBalance)
    }
  },
  computed: {
    /**
     * 前後検索結果の取得
     */
    transferSlideResult() {
      return this.$store.state.TransferStore.transferSlideResult
    },
    /**
     * 検索条件の取得
     */
    transferConditions() {
      return this.$store.state.TransferStore.transferConditions
    },
    /**
     * 出発・到着・始発・終電の文字列を表示するかどうか
     */
    isShowTimeType() {
      // 時間種別（出発or到着or始発or終電の文字）が必要かどうか
      const isShowTimeType =
        this.slideCount == 0 || // eslint-disable-line no-magic-numbers
        this.transferConditions.timeType.id ==
          this.$config.TIME_TYPE_ID.FIRST ||
        this.transferConditions.timeType.id == this.$config.TIME_TYPE_ID.LAST

      return isShowTimeType
    },
    /**
     * 基準となるルートの出発時刻の文字列を返却
     */
    baseRouteDateStr() {
      // 検索日付を取得
      const targetTime = this.transferConditions.targetTime
      // 時間種別が必要な場合はその種別を使って日時文言を生成する
      const timeType = this.isShowTimeType
        ? this.transferConditions.timeType
        : ''
      return `${this.generateSearchDateTime(targetTime, timeType, true)}`
    },
    /**
     * X本前/X本後の文言を返却
     */
    beforeAfterTransferStr() {
      const zero = 0
      const slideCountAbs = Math.abs(this.slideCount)
      if (this.slideCount == zero) {
        // 何も表示しない
        return ''
      } else if (this.slideCount < zero) {
        // X本前を返却
        return `の${slideCountAbs}本前`
      } else {
        // X本後を返却
        return `の${slideCountAbs}本後`
      }
    },
    /**
     * 一本前ボタンのクラス追加
     */
    getOneLineBeforeButtonClass() {
      return this.baseSlideRouteNo + this.slideCount <= 1 ? 'opacity-40' : '' // eslint-disable-line no-magic-numbers
    },
    /**
     * 一本後ボタンのクラス追加
     */
    getOneLineAfterButtonClass() {
      return !this.transferSlideResult ||
        this.transferSlideResult.length <=
          this.baseSlideRouteNo + this.slideCount
        ? 'opacity-40'
        : ''
    },
    /**
     * Suicaの残高が不足しているか判定
     * @return {Boolean} Suicaの残高が不足しているか
     */
    isBalanceFallingShort() {
      return this.balance < this.showingRoute.summaryFare
    },
    /**
     * 出発時間を取得する
     * @return {String} 出発時間 (h:mm形式)
     */
    getStartTime() {
      return this.getFormatTime(
        getStartTimeFromSection(this.showingRoute.sections)
      )
    },
    /**
     * 到着時間を取得する
     * @return {String} 到着時間 (h:mm形式)
     */
    getGoalTime() {
      return this.getFormatTime(
        getGoalTimeFromSection(this.showingRoute.sections)
      )
    },
    /**
     * 移動時間を取得する
     * @return {string} 移動時間 + 単位
     */
    getMoveTime() {
      return transferMinuteToText(this.showingRoute.summaryTime)
    },
  },
  methods: {
    /**
     * 乗換不要判定を行い返却する
     * @param {Object} sections セクション一覧
     * @param {Number} pointIndex 該当セクション(地点)のインデックス
     * @return {Boolean} 乗換不要判定
     */
    isNoTransferRequired(sections, pointIndex) {
      const prevMoveSection = sections[pointIndex - 1] // eslint-disable-line no-magic-numbers
      const pointSection = sections[pointIndex]
      const nextMoveSection = sections[pointIndex + 1] // eslint-disable-line no-magic-numbers
      return isNoNextTransferRequired(
        prevMoveSection,
        pointSection,
        nextMoveSection
      )
    },
    /**
     * 前の画面に遷移
     */
    goToBackPage() {
      // 選択結果を初期化
      this.$store.commit('TransferStore/updateSelectedTransferResult', null)
      // 前後検索結果を初期化
      this.$store.commit('TransferStore/updateTransferSlideResult', null)
      // 最適ルート画面に遷移
      this.$router.push({name: 'TransferResult'})
    },
    /**
     * １本前ボタン押下処理
     */
    beforeTransfer() {
      if (this.transferSlideResult) {
        // 一本前のデータを探す
        const findResult = this.transferSlideResult.find((result) => {
          return (
            Number(result.routeNo) ==
            this.baseSlideRouteNo + this.slideCount - 1 // eslint-disable-line no-magic-numbers
          )
        })
        //データがあればカウントダウン&表示中ルートを差し替え
        if (findResult) {
          this.slideCount--
          this.showingRoute =
            this.slideCount == 0 // eslint-disable-line no-magic-numbers
              ? this.baseRoute
              : findResult
        }
      }
    },
    /**
     * 一本後ボタン押下処理
     */
    afterTransfer() {
      if (this.transferSlideResult) {
        // 一本後のデータを探す
        const findResult = this.transferSlideResult.find((result) => {
          return (
            Number(result.routeNo) ==
            this.baseSlideRouteNo + this.slideCount + 1 // eslint-disable-line no-magic-numbers
          )
        })
        //データがあればカウントアップ&表示中ルートを差し替え
        if (findResult) {
          this.slideCount++
          this.showingRoute =
            this.slideCount == 0 // eslint-disable-line no-magic-numbers
              ? this.baseRoute
              : findResult
        }
      }
    },
    /**
     * Suica残高を表示押下時の処理
     */
    onClickDisplaySuicaBalance() {
      // 連携済なら何もしない
      if (this.isSuicaLinked) return

      // 遷移元ソース保存
      this.$store.commit('SuicaStore/updateScreenTransitionSource', {
        displayName: this.$config.DISPLAY_TRANSFER_DETAIL,
        footerId: this.$store.state.selectedFooterTab,
      })

      // メニュータブに更新
      this.$store.commit('updateFooterTab', this.$config.FOOTER_ID_MENU)

      this.$router.push({name: this.$config.DISPLAY_SUICA_TOP})
    },
    /**
     * Suica残高取得処理
     */
    async getSuicaBalance() {
      this.isSuicaLinked = await suica.isLinked()
      if (this.isSuicaLinked) {
        try {
          const cardInfo = await suica.getCardInfo()
          this.balance = cardInfo.balance
          this.isReadCardFailed = false
        } catch (e) {
          this.isSuicaLinked = await suica.isLinked()
          this.isReadCardFailed = true
        } finally {
          this.isReadCardCompleted = true
        }
      }
    },
  },
}
export default TransferDetail
</script>
<style scoped>
.total-time-distance-suica-shadow-box {
  box-shadow: 0px 0px 10px #00000029;
}
.transfer-before-after {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  height: 52px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px #0000004d;
}

.ic-priority-display {
  font-family: Arial, 'Helvetica Neue', HelveticaNeue, 'Hiragino Sans',
    HiraginoSans, 'Noto Sans CJK JP Regular';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 2ex;
  color: #1a1c21;
}
</style>
